import Vue from 'vue';
import Vuetify from 'vuetify';
import {
  GREEN,
  INACTIVE_GREEN,
  GRAY,
  DARK_GRAY,
  LIGHT_GRAY,
  RED,
  BLUE,
  DARK_BLUE,
  LIGHT_BLUE,
  INACTIVE_BLUE,
  PURPLE,
  SKY_BLUE,
  LIGHT_PURPLE,
} from '@/constants/colors.json';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: DARK_BLUE,
        secondary: GREEN,
        light: GRAY,
        accent: BLUE,
        error: RED,
        purple: PURPLE,
        'light-blue': LIGHT_BLUE,
        'dark-grey': DARK_GRAY,
        'inactive-blue': INACTIVE_BLUE,
        'light-grey': LIGHT_GRAY,
        'inactive-green': INACTIVE_GREEN,
        'sky-blue': SKY_BLUE,
        'light-purple': LIGHT_PURPLE,
      },
    },
  },
});
