









import { Vue, Component, Watch } from 'vue-property-decorator';
import TYPES from '@/types';
import InternetStatusSubscription
  from '@/modules/internet-status/application/subscriptions/internet-status-subscription';
import GetInternetStatusService
  from '@/modules/internet-status/application/services/get-internet-status-service';
import Inject from '@/modules/shared/domain/di/inject';

@Component({ name: 'NoInternetConnection' })
export default class NoInternetConnection extends Vue {
  @Inject(TYPES.INTERNET_STATUS_SUBSCRIPTION)
  readonly internetStatusSubscription!: InternetStatusSubscription;

  @Inject(TYPES.GET_INTERNET_STATUS_SERVICE)
  readonly getInternetStatusService!: GetInternetStatusService;

  message = '';

  show = false;

  get internetStatus() {
    return this.getInternetStatusService.execute();
  }

  @Watch('internetStatus')
  handleInternetStatus(new_internet_status: boolean) {
    if (new_internet_status) {
      this.message = 'conexión a Internet restablecida.';
      setTimeout(() => {
        this.show = false;
      }, 2000);
    } else {
      this.show = true;
      this.message = 'Tu dispositivo ha perdido la conexión a Internet.';
      setTimeout(() => {
        this.message = 'Intentando reconectar...';
      }, 2000);
    }
  }

  created() {
    this.internetStatusSubscription.execute();
  }
}

