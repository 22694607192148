// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { AllianzEarningsReportRepository } from '../../../domain/repository';
import {
  AllianzEarningsReportEntity,
} from '../../../domain/entities/allianz-earnings-report-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class AllianzEarningsReportDummyRepository
implements AllianzEarningsReportRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(): Promise<Array<AllianzEarningsReportEntity>> {
    return Promise.resolve(fixtures);
  }
}
