// Domain
import {
  GoalDefinitionStepEntity,
} from '@/modules/flagship/goal-definition-step/domain/entities/goal-definition-step-entity';

export default class GoalDefinitionStepState {
  loaded = false;

  goal_step_entity: GoalDefinitionStepEntity = {
    main_item: '',
    secondary_item: '',
  };
}
