import Injectable from '@/modules/shared/domain/di/injectable';
import OnBoardingCreateStepRepository from '../../../domain/repositories/on-boarding-create-step-repository';
import { StepEntity } from '../../../domain/entities/step-entity';

@Injectable()
export default class OnBoardingCreateStepDummyRepository implements OnBoardingCreateStepRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(step: StepEntity): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const steps_data = JSON.parse(sessionStorage.getItem('steps_data')!) || [];

    sessionStorage.setItem('steps_data', JSON.stringify([...steps_data, step]));

    return Promise.resolve();
  }
}
