import emergencyFundLocationTypes from './emergency-fund-location/types';
import employmentSituationTypes from './employment-situation/types';
import exitPollOptionsTypes from './exit-poll-options/types';
import customGoalTypesTypes from './custom-goal-types/types';
import emergencyFundAvailabilityTypes from './emergency-fund-availability/types';
import investmentGoalsTypes from './investment-goals/types';
import investmentProductsTypes from './investment-products/types';
import retirementFundLocationTypes from './retirement-fund-location/types';
import incomeBehaviorInFollowingYear from './income-behavior-in-following-year/types';
import investmentProductFundTypeTypes from './investment-product-fund-type/types';

export default {
  ...emergencyFundLocationTypes,
  ...employmentSituationTypes,
  ...exitPollOptionsTypes,
  ...customGoalTypesTypes,
  ...emergencyFundAvailabilityTypes,
  ...investmentGoalsTypes,
  ...investmentProductsTypes,
  ...retirementFundLocationTypes,
  ...incomeBehaviorInFollowingYear,
  ...investmentProductFundTypeTypes,
};
