import TYPES from '../../types';

// Domain
import {
  DistributeBalanceDto,
} from '../../domain/dto/distribute-balance-dto';
import {
  DistributeBalanceRepository,
} from '../../domain/repositories/distribute-balance-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class DistributeBalanceCommand
  extends Command<DistributeBalanceDto> {
  @Inject(TYPES.DISTRIBUTE_BALANCE_REPOSITORY)
  private readonly repository!: DistributeBalanceRepository;

  internalExecute(distribute_balance_entity: DistributeBalanceDto):
    Promise<void> {
    return this.repository.distribute(distribute_balance_entity);
  }
}
