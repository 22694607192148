import Injectable from '@/modules/shared/domain/di/injectable';
import { FinancialInstitutionRepository } from '../../../domain/repository';
import { FinancialInstitutionEntity } from '../../../domain/entities/financial-institution-entity';
import fixtures from './fixtures.json';

@Injectable()
export default class FinancialInstitutionDummyRepository implements FinancialInstitutionRepository {
  // eslint-disable-next-line class-methods-use-this
  findAll(): Promise<Array<FinancialInstitutionEntity>> {
    return Promise.resolve(fixtures);
  }
}
