import Injectable from '@/modules/shared/domain/di/injectable';
import { OnBoardingSearchStepsRepository }
  from '../../../domain/repositories/on-boarding-search-steps-repository';

@Injectable()
class OnBoardingSearchStepsDummyRepository implements OnBoardingSearchStepsRepository {
  // eslint-disable-next-line class-methods-use-this
  execute() {
    const steps_data = sessionStorage.getItem('steps_data');

    return Promise.resolve(steps_data ? JSON.parse(steps_data) : []);
  }
}

export default OnBoardingSearchStepsDummyRepository;
