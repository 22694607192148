import TYPES from '../../types';

// Domain
import {
  InvestmentProductsEntity,
} from '@/modules/flagship/catalogs/investment-products/domain/entities/investment-products-entity';
import { InvestmentProductsRepository } from '@/modules/flagship/catalogs/investment-products/domain/repositories/investment-products-repository';
import {
  InvestmentProductsStateManager,
} from '@/modules/flagship/catalogs/investment-products/domain/state/investment-products-state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetInvestmentProductsQuery
  extends Query<Promise<Array<InvestmentProductsEntity>>> {
  @Inject(TYPES.INVESTMENT_PRODUCTS_REPOSITORY)
  private readonly repository!: InvestmentProductsRepository;

  @Inject(TYPES.INVESTMENT_PRODUCTS_STATE_MANAGER)
  private readonly state_manager!: InvestmentProductsStateManager;

  internalExecute(): Promise<Array<InvestmentProductsEntity>> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded) {
        resolve(this.state_manager.state.items);
      } else {
        this.repository.get()
          .then((items) => {
            this.state_manager.patch({ items, loaded: true });
            resolve(items);
          })
          .catch(reject);
      }
    });
  }
}
