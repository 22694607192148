import fixtures from './fixtures.json';

// Domain
import {
  InvestorGoalDetailRepository,
} from '@/modules/flagship/investor-goal/investor-goal-detail/domain/repositories/investor-goal-detail-repository';
import {
  InvestorGoalDetailEntity,
} from '@/modules/flagship/investor-goal/investor-goal-detail/domain/entities/investor-goal-detail-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class InvestorGoalDetailDummyRepository implements InvestorGoalDetailRepository {
  get_detail(): Promise<InvestorGoalDetailEntity> {
    return Promise.resolve(fixtures);
  }
}
