// Infrastructure
import fixtures from './fixtures.json';

// Domain
import {
  RecommendedInvestmentProductRepository,
} from '@/modules/flagship/investor-profile/recommended-investment-product/domain/repositories/recommended-investment-product-repository';
import {
  InvestmentProductEntity,
} from '@/modules/flagship/investor-profile/recommended-investment-product/domain/entities/investment-product-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class RecommendedInvestmentProductDummyRepository
implements RecommendedInvestmentProductRepository {
  execute(): Promise<InvestmentProductEntity> {
    return Promise.resolve(fixtures);
  }
}
