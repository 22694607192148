import Injectable from '@/modules/shared/domain/di/injectable';
import { SearchTransactionStatusRepository } from '../../../domain/repositories/search-transaction-status-repository';
import { TransactionStatusEntity } from '../../../domain/entities/transaction-status-entity';
import fixtures from './fixtures.json';

@Injectable()
export default class SearchCurrencyTypesDummyRepository implements
  SearchTransactionStatusRepository {
  execute(): Promise<Array<TransactionStatusEntity>> {
    return Promise.resolve(fixtures);
  }
}
