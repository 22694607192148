export default {
  ON_BOARDING_STEPS_STATE_MANAGER: Symbol.for('ON_BOARDING_STEPS_STATE_MANAGER'),
  ON_BOARDING_CREATE_STEP_REPOSITORY: Symbol.for('ON_BOARDING_CREATE_STEP_REPOSITORY'),
  CREATE_ON_BOARDING_STEP_COMMAND: Symbol.for('CREATE_ON_BOARDING_STEP_COMMAND'),
  ON_BOARDING_SEARCH_STEPS_REPOSITORY: Symbol.for('ON_BOARDING_SEARCH_STEPS_REPOSITORY'),
  SEARCH_ON_BOARDING_STEPS_QUERY: Symbol.for('SEARCH_ON_BOARDING_STEPS_QUERY'),
  ON_BOARDING_UPDATE_STEP_REPOSITORY: Symbol.for('ON_BOARDING_UPDATE_STEP_REPOSITORY'),
  UPDATE_ON_BOARDING_STEP_COMMAND: Symbol.for('UPDATE_ON_BOARDING_STEP_COMMAND'),
  SAVE_ON_BOARDING_STEPS_DATA_SERVICE: Symbol.for('SAVE_ON_BOARDING_STEPS_DATA_SERVICE'),
};
