import SHARED_TYPES from '@/modules/shared/types';

// Domain
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Subscription from '@/modules/shared/domain/use-cases/subscription';
import { WebSocket } from '@/modules/shared/domain/web-socket';
import { EventBus } from '@/modules/shared/domain/bus/event-bus';
import { Status } from '@/modules/my-investment/allianz/on-boarding-status/domain/dtos';

@Injectable()
class PublishReceivedStatusToEventBusOnKuspitAccountLinkingStatusWebSocketMessageSubscription
  extends Subscription<string> {
  @Inject(SHARED_TYPES.WEB_SOCKET)
  private readonly web_socket!: WebSocket;

  @Inject(SHARED_TYPES.EVENT_BUS)
  private readonly event_bus!: EventBus;

  private endpoint = `${process.env.VUE_APP_WEBSOCKET_URL}/app.investment.api_kuspit_step.web_socket.1.created/{USER_ID}/`;

  internalExecute(customer_id: string) {
    this.endpoint = this.endpoint.replace('{USER_ID}', customer_id);
    this.web_socket.connect(this.endpoint.replace('{USER_ID}', customer_id), (event) => {
      const data: Status = event.attributes;

      this.event_bus.publish({
        name: 'kuspit.my_investments.kuspit.account_linking.status_update',
        body: event.attributes,
      });

      if (data.percent === 100) {
        this.web_socket.disconnect(this.endpoint);
      }
    });
  }

  stop() {
    this.web_socket.disconnect(this.endpoint);
  }
}

// eslint-disable-next-line max-len
export default PublishReceivedStatusToEventBusOnKuspitAccountLinkingStatusWebSocketMessageSubscription;
