import POLICIES from '../security/policies';
import AuthenticatedLayout from '@/vue-app/layouts/AuthenticatedLayout.vue';
import UserProfile from '@/vue-app/views/UserProfile.vue';

export default {
  path: '/profile',
  component: AuthenticatedLayout,
  children: [
    {
      path: '/',
      name: 'Profile',
      component: UserProfile,
      meta: {
        access_policy: POLICIES.AUTHENTICATED,
      },
    },
  ],
};
