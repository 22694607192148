













































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { currencyFormat } from '@/vue-app/utils/currency';
import AccountBalanceViewModel
  from '../../view-models/components/kuspit-dashboard/account-balance-view-model';
import LoadingDataCard from '@/vue-app/components/home-dashboard/LoadingDataCard.vue';

@Component({
  name: 'AccountBalance',
  components: {
    LoadingDataCard,
    EditReminder: () => import('./edit-reminder/EditReminder.vue'),
  },
  filters: { currencyFormat },
})
export default class AccountBalance extends Vue {
  account_balance_view_model = Vue.observable(new AccountBalanceViewModel());

  @Watch('account_balance_view_model.show_modal_edit_reminder')
  handleIsModalEditReminderOpen(is_modal_edit_reminder_open: boolean) {
    if (is_modal_edit_reminder_open) {
      this.account_balance_view_model.addReloadMonthlyReminderInformationEventListener();
    } else {
      this.account_balance_view_model.removeReloadMonthlyReminderInformationEventListener();
    }
  }

  created() {
    this.account_balance_view_model.initialize();
  }
}
