import verifyIdentity from './verify-identity';

export default {
  'sign-up': {
    registry: 'Registro',
    new_registration: 'Nuevo registro',
    email: {
      label: 'Email',
      placeholder: 'Correo electrónico...',
    },
    password: {
      label: 'Contraseña',
      placeholder: 'Contraseña...',
      requirements: {
        characters_length: 'Al menos 8 caracteres',
        one_capital_and_lowercase_letter_one_number: 'Al menos 1 mayúscula, 1 minúscula y 1 número',
        no_sequence: 'No puede contener una secuencia (ej: 123, abc)',
        no_equal_consecutive_characters: 'No puede contener 3 caracteres iguales seguidos',
        blacklisted_words: 'No puede contener la palabra Sowos o tu email',
      },
    },
    confirm_password: {
      label: 'Confirmar contraseña',
      placeholder: 'Contraseña...',
    },
    password_must_include: 'La contraseña debe incluir:',
    accept_terms_and_conditions: 'Acepto Términos y Condiciones',
    accept_privacy_notice: 'Acepto el Aviso de Privacidad',
    sign_up: 'Registrarme',
    already_have_an_account: '¿Ya tienes una cuenta?',
    login: 'Iniciar sesión',
    errors: {
      cant_register: 'Hubo un error al registrarse',
      charge_agreement_types: 'Ocurrio un error al cargar la página',
    },
    ...verifyIdentity,
  },
};
