import editRetirementFundCurrentPlan from './edit-retirement-fund-current-plan.json';
import editRetirementFundCurrentAmount from './edit-retirement-fund-your-current-amount.json';
import editRetirementFundRetirementAge from './edit-retirement-fund-retirement-age.json';
import editRetirementFundDefineBy from './edit-retirement-fund-define-by.json';
import editRetirementFundNewFund from './edit-retirement-fund-new-fund.json';
import editRetirementFundSuccess from './edit-retirement-fund-success.json';

export default {
  'edit-retirement-fund-goal-plan': {
    ...editRetirementFundCurrentPlan,
    ...editRetirementFundCurrentAmount,
    ...editRetirementFundRetirementAge,
    ...editRetirementFundDefineBy,
    ...editRetirementFundNewFund,
    ...editRetirementFundSuccess,
  },
};
