import { SearchByCustomerEntity } from '../entities/search-by-customer-entity';

export default class SearchByCustomerState {
  loaded = false;

  items: Array<SearchByCustomerEntity> = [
    {
      recurrent_assigned_amount: 0.0,
      monthly_required_amount: 0.0,
      investment_goal_type: {
        id: '',
        description: '',
        label: '',
        name: '',
      },
      associated_product_id: '',
      investment_goal_type_id: '',
      retirement_investor_goal: null,
      investor_profile_id: '',
      updated_at: '',
      is_active: false,
      initial_investment_date: '',
      created_at: '',
      investor_goal_id: '',
      initial_amount: 0.0,
      final_investment_date: '',
      emergency_fund_investor_goal: {
        desired_amount: 0.0,
        created_at: '',
        id: '',
        fixed_time_adjusted: 0.0,
        updated_at: '',
        investor_goal_id: '',
      },
      accumulated_amount: 0.0,
      associated_contract: null,
      custom_investor_goal: null,
      active_goal_amount: 0.0,
      customer_id: '',
    },
  ]
}
