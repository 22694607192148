import TYPES from '@/types';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class SupportWidgetAnonymousDialogViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  i18n_namespace = 'components.support-widget.support_widget_anonymous_comment';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  is_open = false;

  current_step = 0;

  private readonly total_steps = 2;

  email = '';

  nextStep = () => {
    if (this.current_step === this.total_steps) {
      this.current_step = 0;
    } else {
      this.current_step += 1;
    }
  }

  successStep = () => {
    this.current_step = 1;
  }

  errorStep = () => {
    this.current_step = 2;
  }

  endProcess = () => {
    this.is_open = false;
    this.current_step = 0;
  }

  changeEmail = (email: string) => {
    this.email = email;
  }
}
