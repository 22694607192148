import { Container } from 'inversify-props';
import TYPES from './types';
import VueStateManager from './infrastructure/state/vue-state-manager';
import InternetStatusSubscription from './application/subscriptions/internet-status-subscription';
import GetInternetStatusService from './application/services/get-internet-status-service';
import { StateManager } from './domain/state/state-manager';

export default function setupInternetStatus(container: Container) {
  container
    .bind<StateManager>(TYPES.INTERNET_STATUS_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<InternetStatusSubscription>(TYPES.INTERNET_STATUS_SUBSCRIPTION)
    .to(InternetStatusSubscription)
    .inSingletonScope();

  container
    .bind<GetInternetStatusService>(TYPES.GET_INTERNET_STATUS_SERVICE)
    .to(GetInternetStatusService)
    .inSingletonScope();
}
