import TYPES from '../../types';

// Domain
import { InviteFriendDto } from '@/modules/home-dashboard/invite-friend/domain/dtos/invite-friend-dto';
import { CreateOnBoardingInviteFriendRepository }
  from '@/modules/home-dashboard/invite-friend/domain/repositories/create-on-boarding-invite-friend-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CreateOnBoardingInviteFriendCommand extends Command<InviteFriendDto> {
  @Inject(TYPES.CREATE_ON_BOARDING_INVITE_FRIEND_REPOSITORY)
  private readonly repository!: CreateOnBoardingInviteFriendRepository;

  internalExecute(invite_friend_dto: InviteFriendDto): Promise<void> {
    return this.repository.create(invite_friend_dto);
  }
}
