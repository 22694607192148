import initialContribution from './initial-contribution.json';
import scheduleContributions from './schedule-contributions.json';
import recurringContributions from './recurring-contributions.json';

export default {
  contributions: {
    ...initialContribution,
    ...scheduleContributions,
    ...recurringContributions,
  },
};
