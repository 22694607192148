import economicDependentsTypes from './economic-dependents/types';
import employmentSituationTypes from './employment-situation/types';
import emergencyFundTypes from './emergency-fund/types';
import personalInfoTypes from './personal-info/types';
import customGoalTypes from './custom-goal/types';
import recommendedInvestmentProductTypes from './recommended-investment-product/types';
import retirementBudgetTypes from './retirement-budget/types';
import flowCompletedTypes from './flow-completed/types';
import flowShowedTypes from './flow-showed/types';
import investorProfileTypes from './investor-profile/types';
import incompleteProfileTypes from './incomplete-profile/types';
import overallGoalsStatusTypes from './overall-goals-status/types';

export default {
  ...economicDependentsTypes,
  ...employmentSituationTypes,
  ...emergencyFundTypes,
  ...personalInfoTypes,
  ...customGoalTypes,
  ...recommendedInvestmentProductTypes,
  ...retirementBudgetTypes,
  ...flowCompletedTypes,
  ...flowShowedTypes,
  ...investorProfileTypes,
  ...incompleteProfileTypes,
  ...overallGoalsStatusTypes,
};
