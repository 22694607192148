import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import {
  GetCustomerKuspitPositionDetailsQuery,
} from '@/modules/my-investment/kuspit/customer-kuspit-position/application/queries';
import {
  GetHasKuspitAccountQuery,
} from '@/modules/my-investment/kuspit/customer-has-account/application/queries';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class PositionViewModel {
  @Inject(TYPES.GET_CUSTOMER_KUSPIT_POSITION_DETAILS_QUERY)
  private readonly get_customer_kuspit_position_details_query!:
    GetCustomerKuspitPositionDetailsQuery;

  @Inject(TYPES.GET_CUSTOMER_HAS_KUSPIT_ACCOUNT_QUERY)
  readonly get_has_kuspit_account_query!: GetHasKuspitAccountQuery;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.kuspit-dashboard.position';

  readonly hrefs = {
    dici_pocket: `${process.env.VUE_APP_CANVA_URL}/design/DAFmAWzAuM8/OnRnuYRH-hl0VQVhJhr1hg/view?utm_content=DAFmAWzAuM8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink#2`,
  }

  total_value = 0.0;

  investment_options = [
    {
      name: 'Fondo Barrido',
      amount: 0.00,
      color: '#4C5980',
      percent: '',
    },
    {
      name: 'SURREAL BD',
      amount: 0.00,
      color: '#64D2E0',
      percent: '',
    },
    {
      name: 'SURBONO BDF',
      amount: 0.00,
      color: '#0047BA',
      percent: '',
    },
  ];

  chart_options = {
    series: [0],
    labels: [''],
    colors: ['#0047BA', '#5BCFDE', '#132C54', '#E9E8ED', '#E65C61'],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
  };

  is_loading = true;

  aux_index_colors_array = 0;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get total_value_formatted() {
    return currencyFormat(this.total_value);
  }

  getAmountFormatted = (amount: number) => (currencyFormat(amount));

  initialize = async () => {
    await this.loadInformation();
    this.is_loading = false;
  }

  loadInformation = async () => {
    try {
      const has_kuspit_account = await this.get_has_kuspit_account_query.execute();
      if (has_kuspit_account.has_account) {
        await this.fillData();
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_has_kuspit_account_query'));
    }
  }

  async fillData() {
    try {
      const kuspit_position = await this.get_customer_kuspit_position_details_query.execute();
      if (kuspit_position) {
        this.aux_index_colors_array = 0;
        this.total_value = Number(kuspit_position.position);
        this.chart_options.series = kuspit_position.funds.map((fund) => Number(fund.amount));
        this.chart_options.labels = kuspit_position.funds.map((fund) => fund.name);
        this.investment_options = kuspit_position.funds.map((fund, index) => ({
          name: fund.name,
          amount: Number(fund.amount),
          percent: Number(fund.percent).toFixed(2),
          color: this.chart_options.colors[this.getCorrectPositionInColorsArray(index)],
        }));
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_customer_kuspit_position_details_query'));
    }
  }

  showDocSWSPocket = (strategy_name: string) => ((strategy_name === 'Fondo Barrido'))

  getCorrectPositionInColorsArray = (position: number) => {
    const array_colors_length = this.chart_options.colors.length - 1;
    let index: number;
    if (position >= array_colors_length) {
      index = this.aux_index_colors_array;
      this.aux_index_colors_array += 1;
    } else {
      index = position;
    }
    return index;
  }
}
