/* eslint-disable import/no-cycle */

import BaseLink from './base-link';
import Injectable from '../di/injectable';
import Context from './context';

@Injectable()
export default class ExecutorLink extends BaseLink {
  next(context: Context) {
    // eslint-disable-next-line no-param-reassign
    context.result = context.useCase.internalExecute(context.param);
    this.next_link.next(context);
  }
}
