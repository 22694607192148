import { CustomerInvestorGoalBalanceEntity } from '../entities/customer-investor-goal-balance-entity';

export default class CustomerInvestorGoalBalanceState {
  loaded = false;

  item: CustomerInvestorGoalBalanceEntity = {
    id: '',
    customer_id: '',
    investment_product_id: '',
    assigned_balance: 0,
    unassigned_balance: 0,
  }
}
