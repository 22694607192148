// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { CustomerDocumentsRepository } from '../../../domain/repository';
import { CustomerDocumentEntity } from '../../../domain/entities/customer-document-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CustomerDocumentsDummyRepository implements CustomerDocumentsRepository {
  execute(): Promise<Array<CustomerDocumentEntity>> {
    return Promise.resolve(fixtures);
  }
}
