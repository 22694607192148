import TYPES from '../../types';

// Domain
import { AllianzRequestForMovementsToPolicyEntity } from '../../domain/entities/allianz-request-for-movements-to-policy-entity';
import { AllianzRequestForMovementsToPolicyRepository } from '../../domain/repository/allianz-request-for-movements-to-policy-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class SearchAllianzRequestForMovementsToPolicyQuery
  extends Query<Promise<Array<AllianzRequestForMovementsToPolicyEntity>>> {
  @Inject(TYPES.ALLIANZ_REQUEST_FOR_MOVEMENTS_TO_POLICY_REPOSITORY)
  private readonly repository!: AllianzRequestForMovementsToPolicyRepository;

  internalExecute(query_params: URLSearchParams): Promise<Array<
    AllianzRequestForMovementsToPolicyEntity>> {
    return new Promise((resolve, reject) => {
      this.repository.search(query_params)
        .then((items) => {
          resolve(items);
        })
        .catch(reject);
    });
  }
}
