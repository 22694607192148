export default {
  SEARCH_ALLIANZ_TRANSACTIONS_QUERY: Symbol.for('SEARCH_ALLIANZ_TRANSACTIONS_QUERY'),
  UPDATE_ALLIANZ_TRANSACTIONS_COMMAND: Symbol.for('UPDATE_ALLIANZ_TRANSACTIONS_COMMAND'),
  ALLIANZ_TRANSACTION_REPOSITORY: Symbol.for('ALLIANZ_TRANSACTION_REPOSITORY'),
  CREATE_ALLIANZ_TRANSACTION_REPOSITORY: Symbol.for('CREATE_ALLIANZ_TRANSACTION_REPOSITORY'),
  CREATE_ALLIANZ_TRANSACTIONS_COMMAND: Symbol.for('CREATE_ALLIANZ_TRANSACTIONS_COMMAND'),
  ALLIANZ_TRANSACTION_STATE_MANAGER: Symbol.for('ALLIANZ_TRANSACTION_STATE_MANAGER'),
  SEARCH_ALLIANZ_TRANSACTIONS_BY_RANGE_QUERY: Symbol.for('SEARCH_ALLIANZ_TRANSACTIONS_BY_RANGE_QUERY'),
  WEALTH_FLEXIBLE_DEPOSIT_STATE_MANAGER: Symbol.for('WEALTH_FLEXIBLE_DEPOSIT_STATE_MANAGER'),
};
