export default {
  ON_BOARDING_IDENTITY_VERIFICATION_STATE_MANAGER:
    Symbol.for('ON_BOARDING_IDENTITY_VERIFICATION_STATE_MANAGER'),
  ON_BOARDING_IDENTITY_VERIFICATION_SERVICE_REPOSITORY:
    Symbol.for('ON_BOARDING_IDENTITY_VERIFICATION_SERVICE_REPOSITORY'),
  ON_BOARDING_IDENTITY_VERIFICATION_STATUS_SUBSCRIPTION:
    Symbol.for('ON_BOARDING_IDENTITY_VERIFICATION_STATUS_SUBSCRIPTION'),
  GET_ON_BOARDING_IDENTITY_VERIFICATION_SERVICE_QUERY:
    Symbol.for('GET_ON_BOARDING_IDENTITY_VERIFICATION_SERVICE_QUERY'),
  GET_ON_BOARDING_IDENTITY_VERIFICATION_STATUS_QUERY:
    Symbol.for('GET_ON_BOARDING_IDENTITY_VERIFICATION_STATUS_QUERY'),
  ON_BOARDING_RESET_IDENTITY_VERIFICATION_STATUS:
    Symbol.for('ON_BOARDING_RESET_IDENTITY_VERIFICATION_STATUS'),
  ON_BOARDING_MATI_VERIFICATION_REPOSITORY:
    Symbol.for('ON_BOARDING_MATI_VERIFICATION_REPOSITORY'),
  GET_ON_BOARDING_MATI_VERIFICATION_QUERY:
    Symbol.for('GET_ON_BOARDING_MATI_VERIFICATION_QUERY'),
  REQUEST_MANUAL_REVIEW_COMMAND:
    Symbol.for('REQUEST_MANUAL_REVIEW_COMMAND'),
};
