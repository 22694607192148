import { Person } from '../entities';

export default class PersonState {
  person: Person = {
    id: '',
    name: '',
    last_name: '',
  };

  loaded = false;
}
