import fixtures from './fixtures.json';

// Domain
import { CustomerDocumentRepository } from '../../../domain/repositories/customer-document-repository';
import { MagicLinkEntity } from '../../../domain/entities/magic-link-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CustomerDocumentDummyRepository implements CustomerDocumentRepository {
  verify_token(): Promise<MagicLinkEntity> {
    return Promise.resolve(fixtures);
  }

  upload_proof_of_tax_situation(): Promise<void> {
    return Promise.resolve();
  }
}
