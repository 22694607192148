import TYPES from '../../types';

// Domain
import {
  InvestorProfileOnEmploymentSituationRepository,
} from '@/modules/flagship/investor-profile/employment-situation/domain/repositories/investor-profile-on-employment-situation-repository';
import {
  InvestorProfileEntity,
} from '@/modules/flagship/investor-profile/investor-profile/domain/entities/investor-profile-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UpdateInvestorProfileOnEmploymentSituationCommand
  extends Command<InvestorProfileEntity> {
  @Inject(TYPES.INVESTOR_PROFILE_ON_EMPLOYMENT_SITUATION_REPOSITORY)
  private readonly repository!: InvestorProfileOnEmploymentSituationRepository;

  internalExecute(investor_profile_entity: InvestorProfileEntity) {
    return this.repository.update(investor_profile_entity);
  }
}
