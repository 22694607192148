import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import LinkSowosWealthModerateDummyRepository from './infrastructure/repositories/link-sowos-wealth-moderate-dummy-repository';
import LinkSowosWealthModerateHttpRepository from './infrastructure/repositories/link-sowos-wealth-moderate-http-repository';

// Application
import LinkSowosWealthModerateCommand from './application/commands/link-sowos-wealth-moderate-command';

// Domain
import { LinkSowosWealthModerateRepository } from './domain/repositories/link-sowos-wealth-moderate-repository';

export default function setupLinkSowosWealthModerate(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<LinkSowosWealthModerateRepository>(
      TYPES.LINK_SOWOS_WEALTH_MODERATE_REPOSITORY,
    ).to(
      withDummies
        ? LinkSowosWealthModerateDummyRepository
        : LinkSowosWealthModerateHttpRepository,
    ).inSingletonScope();

  container
    .bind<LinkSowosWealthModerateCommand>(
      TYPES.LINK_SOWOS_WEALTH_MODERATE_COMMAND,
    ).to(LinkSowosWealthModerateCommand)
    .inSingletonScope();
}
