// Domain
import { PersonalInfoEntity } from '../entities/personal-info-entity';

export default class PersonalInfoState {
  loaded = false;

  personal_info: PersonalInfoEntity = {
    id: '',
    customer_id: '',
    person: {
      name: '',
      last_name: '',
      second_last_name: '',
      current_age: 0,
    },
  };
}
