import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';
import SearchPersonRepository from '../../../domain/repositories/get-person-repository';
import { PersonEntity } from '../../../domain/entities/person-entity';
import TYPES from '@/modules/shared/types';

@Injectable()
export default class GetPersonHttpRepository implements SearchPersonRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/person/`;

  get(): Promise<PersonEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
