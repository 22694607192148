















































































































































































































import { Component, Vue } from 'vue-property-decorator';

import DistributeSpecialContributionAmountLinkedGoalsWealthViewModel
  from '@/vue-app/view-models/components/flagship/distribute-special-contribution-amount-linked-goals-wealth-view-model';
import ModalDistributeSpecialContributionSuccessWealth
  from '@/vue-app/components/flagship/ModalDistributeSpecialContributionSuccessWealth.vue';

@Component({
  name: 'DistributeSpecialContributionAmountLinkedGoalsWealth',
  components: {
    ModalDistributeSpecialContributionSuccessWealth,
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class DistributeSpecialContributionAmountLinkedGoalsWealth extends Vue {
  distribute_special_contribution_view_model = Vue.observable(
    new DistributeSpecialContributionAmountLinkedGoalsWealthViewModel(),
  );

  created() {
    this.distribute_special_contribution_view_model.initialize();
  }
}
