import TYPES from '@/modules/shared/types';

// Domain
import {
  PreferredMeansOfContactRepository,
} from '@/modules/on-boarding/preferred-means-of-contact/domain/repositories/preferred-means-of-contact-repository';
import {
  PreferredMeansOfContactDto,
} from '@/modules/on-boarding/preferred-means-of-contact/domain/dtos/preferred-means-of-contact-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class PreferredMeansOfContactHttpRepository
implements PreferredMeansOfContactRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/preferred-means-of-contact/`;

  update(preferred_means_of_contact: PreferredMeansOfContactDto): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint, 'PATCH', preferred_means_of_contact)
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => reject(new Error(message)),
            );
          }
        })
        .catch(reject);
    });
  }
}
