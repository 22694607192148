import TYPES from '../../types';

// Domain
import { RetirementAgeEntity } from '../../domain/entities/retirement-age-entity';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class EditRetirementFundGoalPlanRetirementAgeService {
  @Inject(TYPES.EDIT_RETIREMENT_FUND_GOAL_PLAN_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  setRetirementAgeInformation(retirement_age: RetirementAgeEntity): void {
    this.state_manager.state.retirement_age = { ...retirement_age };
  }

  getRetirementAgeInformation(): RetirementAgeEntity {
    return this.state_manager.state.retirement_age;
  }
}
