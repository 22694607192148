import TYPES from '../../types';

// Domain
import { OnBoardingStepEntity } from '../../domain/entities/on-boarding-step-entity';
import { StateManager } from '../../domain/state/state-manager';
import { SearchOnBoardingStepRepository } from '../../domain/repositories';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class SearchOnBoardingStepsQuery
  extends Query<Promise<Array<OnBoardingStepEntity>>> {
  @Inject(TYPES.MY_INVESTMENT_ON_BOARDING_STEP_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  private readonly repository!: SearchOnBoardingStepRepository;

  internalExecute(investment_provider_id: string): Promise<Array<OnBoardingStepEntity>> {
    return new Promise((resolve, reject) => {
      this.repository.execute(investment_provider_id)
        .then((items) => {
          this.state_manager.patch({ created_steps: items });
          resolve(items);
        })
        .catch(reject);
    });
  }
}
