import TYPES from '@/modules/shared/types';

// Domain
import { MunicipalityRepository } from '../../domain/repository';
import { MunicipalityEntity } from '../../domain/entities/municipality-entity';
import { SearchDto } from '../../domain/dtos/search-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class MunicipalityHttpRepository implements MunicipalityRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/allianz/municipalities/search?codpais={CODPAIS}&codestado={CODESTADO}`;

  execute(search: SearchDto): Promise<Array<MunicipalityEntity>> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(
        this.endpoint.replace('{CODPAIS}', search.codpais)
          .replace('{CODESTADO}', search.codestado),
      )
        .then((response) => {
          response.json()
            .then(({ data, success, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
