import { Container } from 'inversify-props';
import TYPES from './types';
import VueStateManager from './infrastructure/state/vue-state-manager';
import SearchGendersDummyRepository
  from './infrastructure/repositories/search-genders-repository/search-genders-dummy-repository';
import SearchGendersHttpRepository
  from './infrastructure/repositories/search-genders-repository/search-genders-http-repository';
import OnBoardingSearchGendersQuery from './application/queries/on-boarding-search-genders-query';
import StateManager from './domain/state/state-manager';
import SearchGendersRepository from './domain/repositories/search-genders-repository';

export default function setupGenders(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.ON_BOARDING_CATALOGS_GENDERS_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<SearchGendersRepository>(TYPES.ON_BOARDING_CATALOGS_SEARCH_GENDERS_REPOSITORY)
    .to(
      withDummies
        ? SearchGendersDummyRepository
        : SearchGendersHttpRepository,
    ).inSingletonScope();

  container
    .bind<OnBoardingSearchGendersQuery>(TYPES.ON_BOARDING_SEARCH_GENDERS_QUERY)
    .to(OnBoardingSearchGendersQuery)
    .inSingletonScope();
}
