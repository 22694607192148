import TYPES from '../../types';

// Domain
import {
  InvestorProfileOnCustomGoalRepository,
} from '@/modules/flagship/investor-profile/custom-goal/domain/repositories/investor-profile-on-custom-goal-repository';
import {
  InvestorProfileEntity,
} from '@/modules/flagship/investor-profile/investor-profile/domain/entities/investor-profile-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UpdateInvestorProfileOnCustomGoalCommand
  extends Command<InvestorProfileEntity> {
  @Inject(TYPES.INVESTOR_PROFILE_ON_CUSTOM_GOAL_REPOSITORY)
  private readonly repository!: InvestorProfileOnCustomGoalRepository;

  internalExecute(investor_profile_entity: InvestorProfileEntity) {
    return this.repository.update(investor_profile_entity);
  }
}
