import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import AvailabilityToHireProductVueStateManager from './infrastructure/state/availability-to-hire-product-vue-state-manager';
import {
  InvestmentProductHttpRepository, InvestmentProductDummyRepository,
} from './infrastructure/repository';

// Application
import { FindAvailabilityToHireProductQuery } from './application/queries';

// Domain
import { InvestmentProductRepository } from './domain/repository';
import { AvailabilityToHireProductStateManager } from './domain/state/availability-to-hire-product-state-manager';

export default function setupInvestmentProduct(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<AvailabilityToHireProductStateManager>(TYPES.AVAILABILITY_TO_HIRE_PRODUCT_STATE_MANAGER)
    .to(AvailabilityToHireProductVueStateManager)
    .inSingletonScope();

  container
    .bind<InvestmentProductRepository>(TYPES.INVESTMENT_PRODUCT_REPOSITORY)
    .to(
      withDummies
        ? InvestmentProductDummyRepository
        : InvestmentProductHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<FindAvailabilityToHireProductQuery>(TYPES.FIND_AVAILABILITY_TO_HIRE_PRODUCT_QUERY)
    .to(FindAvailabilityToHireProductQuery)
    .inSingletonScope();
}
