
























import { Component, Vue } from 'vue-property-decorator';

import InvestmentSuggestionFlagshipCardViewModel
  from '@/vue-app/view-models/components/investment-suggestion/investment-suggestion-flagship-card-view-model';

@Component({ name: 'InvestmentSuggestionFlagshipCard' })
export default class InvestmentSuggestionFlagshipCard extends Vue {
  flagship_card_model = Vue.observable(new InvestmentSuggestionFlagshipCardViewModel(this));

  created() {
    this.flagship_card_model.initialize();
  }
}
