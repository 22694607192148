import { IdentityVerificationStatusEntity } from '../entities/identity-verification-status-entity';
import { IdentityVerificationEntity } from '../entities/identity-verification-entity';

export default class State {
  loaded = false;

  credentials: IdentityVerificationEntity = {
    id: '',
    name: '',
    flow_id: '',
    client_id: '',
  };

  status: IdentityVerificationStatusEntity = {
    step: 0,
    description: '',
    percent: 0,
    user_id: '',
  };
}
