import TYPES from '@/types';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class ModalDistributeDifferentContributionsSuccessPocketViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.flagship.modal-distribute-different-contributions-success-pocket';

  is_modal_open = true;

  is_there_any_pending_distribution = false;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = (is_there_any_pending_distribution: boolean) => {
    this.is_there_any_pending_distribution = is_there_any_pending_distribution;
  }

  closeModal = () => {
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  }
}
