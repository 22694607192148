import TYPES from '../../types';

// Domain
import {
  OverallGoalsStatusRepository,
} from '@/modules/flagship/investor-profile/overall-goals-status/domain/repositories/overall-goals-status-repository';
import {
  OverallGoalsStatusEntity,
} from '@/modules/flagship/investor-profile/overall-goals-status/domain/entities/overall-goals-status-entity';
import {
  OverallGoalsStatusStateManager,
} from '@/modules/flagship/investor-profile/overall-goals-status/domain/state/overall-goals-status-state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetOverallGoalsStatusQuery
  extends Query<Promise<OverallGoalsStatusEntity>> {
  @Inject(TYPES.OVERALL_GOALS_STATUS_REPOSITORY)
  private readonly repository!: OverallGoalsStatusRepository;

  @Inject(TYPES.OVERALL_GOALS_STATUS_STATE_MANAGER)
  private readonly state_manager!: OverallGoalsStatusStateManager;

  internalExecute(loadStateManager = true): Promise<OverallGoalsStatusEntity> {
    return new Promise((resolve, reject) => {
      if (loadStateManager && this.state_manager.state.loaded) {
        resolve(this.state_manager.state.overall_goals_status);
      } else {
        this.repository.get()
          .then((overall_goals_status: OverallGoalsStatusEntity) => {
            this.state_manager.patch({ overall_goals_status, loaded: true });
            resolve(this.state_manager.state.overall_goals_status);
          })
          .catch(reject);
      }
    });
  }
}
