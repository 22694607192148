import TYPES from '../../types';

// Domain
import { MagicLinkRepository } from '../../domain/repositories/magic-link-repository';
import { MagicLinkEntity } from '../../domain/entities/magic-link-entity';
import { MagicLinkDto } from '../../domain/dtos/magic-link-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class VerifyTokenMagicLinkQuery extends Query<Promise<MagicLinkEntity>> {
  @Inject(TYPES.MAGIC_LINK_REPOSITORY)
  private readonly repository!: MagicLinkRepository;

  internalExecute(verify_token_dto: MagicLinkDto): Promise<MagicLinkEntity> {
    return new Promise((resolve, reject) => {
      this.repository.verify_token(verify_token_dto)
        .then((data) => {
          resolve(data);
        })
        .catch(reject);
    });
  }
}
