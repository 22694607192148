import TYPES from '../../types';

// Domain
import { InvestmentTypeHiringStepStateManager } from '../../domain/state/investment-type-hiring-step-state-manager';
import { InvestmentTypeHiringStepRepository } from '../../domain/repositories/investment-type-hiring-step-repository';
import { InvestmentTypeHiringStepEntity } from '../../domain/entities/investment-type-hiring-step-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CreateInvestmentTypeHiringStepCommand
  extends Command<InvestmentTypeHiringStepEntity> {
  @Inject(TYPES.INVESTMENT_TYPE_HIRING_STEP_STATE_MANAGER)
  private readonly state_manager!: InvestmentTypeHiringStepStateManager;

  @Inject(TYPES.INVESTMENT_TYPE_HIRING_STEP_REPOSITORY)
  private readonly repository!: InvestmentTypeHiringStepRepository;

  internalExecute(step: InvestmentTypeHiringStepEntity): Promise<void> {
    return new Promise((resolve, reject) => {
      this.repository.create(step)
        .then(() => {
          this.state_manager.patch({
            items: [step, ...this.state_manager.state.items],
          });
          resolve();
        })
        .catch(reject);
    });
  }
}
