// Domain
import {
  DistributeUnassignedBalanceRepository,
} from '@/modules/flagship/investor-goal/distribute-unassigned-balance/domain/repositories/distribute-unassigned-balance-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class DistributeUnassignedBalanceDummyRepository
implements DistributeUnassignedBalanceRepository {
  distribute(): Promise<void> {
    return Promise.resolve();
  }
}
