import SHARED_TYPES from '@/modules/shared/types';

// Domain
import { CustomerPersonalInformationRepository } from '../../../domain/repositories';
import { PersonalInformation } from '../../../domain/dtos';
import HttpConnector from '@/modules/shared/domain/connectors/http';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
class CustomerPersonalInformationRestRepository implements CustomerPersonalInformationRepository {
  @Inject(SHARED_TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  get(): Promise<PersonalInformation> {
    const endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/customer/personal-information/`;

    return new Promise((resolve, reject) => {
      this.http_connector.execute(endpoint)
        .then((response) => {
          response.json()
            .then(({ data, success, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}

export default CustomerPersonalInformationRestRepository;
