import TYPES from '../../types';

// Domain
import {
  InvestorProfileOnEconomicDependentsRepository,
} from '@/modules/flagship/investor-profile/economic-dependents/domain/repositories/investor-profile-on-economic-dependents-repository';
import { InvestorProfileEntity } from '../../../investor-profile/domain/entities/investor-profile-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UpdateInvestorProfileOnEconomicDependentsCommand
  extends Command<InvestorProfileEntity> {
  @Inject(TYPES.INVESTOR_PROFILE_ON_ECONOMIC_DEPENDENTS_REPOSITORY)
  private readonly repository!: InvestorProfileOnEconomicDependentsRepository;

  internalExecute(investor_profile_entity: InvestorProfileEntity): Promise<void> {
    return this.repository.update(investor_profile_entity);
  }
}
