// Domain
import {
  DistributeRecurrentBalanceRepository,
} from '../../../domain/repositories/distribute-recurrent-balance-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class DistributeRecurrentBalanceDummyRepository
implements DistributeRecurrentBalanceRepository {
  distribute(): Promise<void> {
    return Promise.resolve();
  }
}
