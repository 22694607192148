export default {
  CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND: Symbol.for('CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND'),
  CREATE_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY: Symbol.for('CREATE_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY'),
  SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_QUERY: Symbol.for('SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_QUERY'),
  SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY: Symbol.for('SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY'),
  UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND: Symbol.for('UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND'),
  UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY: Symbol.for('UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY'),
  MY_INVESTMENT_ON_BOARDING_STEP_STATE_MANAGER: Symbol.for('MY_INVESTMENT_ON_BOARDING_STEP_STATE_MANAGER'),
  GET_MY_INVESTMENT_ON_BOARDING_CURRENT_STEP_QUERY: Symbol.for('GET_MY_INVESTMENT_ON_BOARDING_CURRENT_STEP_QUERY'),
};
