






























import { Component, Vue } from 'vue-property-decorator';

import SupportWidgetModalViewModel
  from '@/vue-app/view-models/components/support-widget/support-widget-modal--view-model';

@Component({
  name: 'SupportWidgetModal',
  components: {
    SupportWidgetComment: () => import('@/vue-app/components/support-widget/SupportWidgetComment.vue'),
    SupportWidgetSuccess: () => import('@/vue-app/components/support-widget/SupportWidgetSuccess.vue'),
    SupportWidgetError: () => import('@/vue-app/components/support-widget/SupportWidgetError.vue'),
  },
})

export default class SupportWidgetModal extends Vue {
  support_modal_wiew_model = Vue.observable(
    new SupportWidgetModalViewModel(),
  );
}
