import { HasAccountEntity } from '../entities/has-account-entity';

export default class State {
  is_loading = false;

  has_account_loaded = false;

  has_account: HasAccountEntity = {
    id: '',
    has_account: false,
    created_at: '',
  };
}
