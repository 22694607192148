import TYPES from '../../types';

// Domain
import { UpdateAccountNumberDto }
  from '../../domain/dtos/update-account-number-dto';
import { AllianzAccountRepository }
  from '../../domain/repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UpdateAccountNumberCommand
  extends Command<UpdateAccountNumberDto> {
  @Inject(TYPES.ALLIANZ_ACCOUNT_REPOSITORY)
  private readonly repository!: AllianzAccountRepository;

  internalExecute(
    update_account_number_dto: UpdateAccountNumberDto,
  ): Promise<void> {
    return this.repository.updateAccountNumber(update_account_number_dto);
  }
}
