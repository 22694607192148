import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import { CustomerNotificationHttpRepository, CustomerNotificationDummyRepository }
  from './infrastructure/repositories/customer-notification-repository';

// Application
import { GetCustomerNotificationsQuery } from './application/queries';

// Domain
import { CustomerNotificationRepository } from './domain/repositories';

export default function setupCustomerNotificationsModule(container: Container) {
  const with_dummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<CustomerNotificationRepository>(TYPES.CUSTOMER_NOTIFICATION_REPOSITORY)
    .to(
      with_dummies
        ? CustomerNotificationDummyRepository
        : CustomerNotificationHttpRepository,
    )
    .inRequestScope();

  container
    .bind<GetCustomerNotificationsQuery>(TYPES.GET_CUSTOMER_NOTIFICATIONS_QUERY)
    .to(GetCustomerNotificationsQuery)
    .inRequestScope();
}
