import TYPES from '@/modules/shared/types';

// Domain
import {
  EmploymentSituationRepository,
} from '@/modules/flagship/catalogs/employment-situation/domain/repositories/employment-situation-repository';
import {
  EmploymentSituationEntity,
} from '@/modules/flagship/catalogs/employment-situation/domain/entities/employment-situation-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class EmploymentSituationHttpRepository implements EmploymentSituationRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/catalogs/employment-situations/`;

  execute(): Promise<Array<EmploymentSituationEntity>> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint)
        .then((response) => {
          response.json()
            .then(({ success, data, error }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(error));
              }
            });
        })
        .catch(reject);
    });
  }
}
