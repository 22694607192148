/* eslint-disable import/no-cycle */

import EmptyLink from './empty-link';
import Link from './link';
import Context from './context';

export default abstract class BaseLink {
  next_link: Link = new EmptyLink();

  setNext(link: Link): Link {
    this.next_link = link;

    return this;
  }

  abstract next(context: Context): void
}
