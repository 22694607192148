














import { Vue, Component } from 'vue-property-decorator';
import NavBar from '@/vue-app/components/NavBar.vue';
import SupportWidgetModal from '@/vue-app/components/support-widget/SupportWidgetModal.vue';
import InvestmentSuggestionModal
  from '@/vue-app/components/investment-suggestion/InvestmentSuggestionModal.vue';
import DistributeSpecialContributionAmongLinkedGoalsWealth
  from '@/vue-app/components/flagship/DistributeSpecialContributionAmountLinkedGoalsWealth.vue';
import DistributeDifferentContributionsAmongLinkedGoalsPocket
  from '@/vue-app/components/flagship/DistributeDifferentContributionsAmongLinkedGoalsPocket.vue';
import AuthenticatedLayoutViewModel
  from '@/vue-app/view-models/layouts/authenticated_layout_view_model';

@Component({
  name: 'AuthenticatedLayout',
  components: {
    NavBar,
    SupportWidgetModal,
    InvestmentSuggestionModal,
    DistributeSpecialContributionAmongLinkedGoalsWealth,
    DistributeDifferentContributionsAmongLinkedGoalsPocket,
    FlagshipProfilingIncompleteFlow: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingIncompleteFlow.vue'),
    StrategyModerateWealthDialog: () => import('@/vue-app/components/strategy-moderate-wealth/StrategyModerateWealthDialog.vue'),
  },
})
export default class AuthenticatedLayout extends Vue {
  authenticated_layout_view_model = Vue.observable(new AuthenticatedLayoutViewModel());

  created() {
    this.authenticated_layout_view_model.initialize();
  }
}

