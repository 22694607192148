import Injectable from '@/modules/shared/domain/di/injectable';
import { JwtRepository } from '../../../domain/repositories';

@Injectable()
export default class JwtDummyRepository implements JwtRepository {
  // eslint-disable-next-line class-methods-use-this
  create() {
    const auth_token = sessionStorage.getItem('authorization');

    return Promise.resolve(!!auth_token);
  }
}
