













import { Component, Vue } from 'vue-property-decorator';
import Loading from './views/Loading.vue';
import NoInternetConnection from '@/vue-app/components/NoInternetConnection.vue';
import NotificationsWrapper from '@/vue-app/components/notifications/notifications-wrapper.vue';
import ExpiredSession from '@/vue-app/components/ExpiredSession.vue';

@Component({
  name: 'App',
  components: {
    Loading,
    NoInternetConnection,
    NotificationsWrapper,
    ExpiredSession,
  },
})
export default class App extends Vue {

}

