import { Container } from 'inversify';
import TYPES from './types';

// Infrastructure
import DistributeBalanceDummyRepository
  from './infrastructure/repositories/distribute-balance-dummy-repository/distribute-balance-dummy-repository';
import DistributeBalanceHttpRepository
  from './infrastructure/repositories/distribute-balance-http-repository';

// Application
import DistributeBalanceCommand from './application/commands/distribute-balance-command';

// Domain
import {
  DistributeBalanceRepository,
} from './domain/repositories/distribute-balance-repository';

export default function setupDistributeUnassignedBalance(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<DistributeBalanceRepository>(TYPES.DISTRIBUTE_BALANCE_REPOSITORY)
    .to(
      withDummies
        ? DistributeBalanceDummyRepository
        : DistributeBalanceHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<DistributeBalanceCommand>(TYPES.DISTRIBUTE_BALANCE_COMMAND)
    .to(DistributeBalanceCommand)
    .inSingletonScope();
}
