// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { OccupationRepository } from '../../../domain/repository';
import { OccupationEntity } from '../../../domain/entities/occupation-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class OccupationDummyRepository implements OccupationRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(): Promise<Array<OccupationEntity>> {
    return Promise.resolve(fixtures);
  }
}
