import UnautheneticatedLayout from '@/vue-app/layouts/UnauthenticatedLayout.vue';
import SignUp from '@/vue-app/views/SignUp.vue';
import POLICIES from '../security/policies';

export default [
  {
    path: '/sign-up',
    component: UnautheneticatedLayout,
    children: [
      {
        path: '/',
        name: 'SignUp',
        component: SignUp,
        meta: {
          access_policy: POLICIES.UNAUTHENTICATED,
        },
      },
    ],
  },
];
