import TYPES from '../../types';

// Domain
import {
  GoalDefinitionStepEntity,
} from '@/modules/flagship/goal-definition-step/domain/entities/goal-definition-step-entity';
import {
  GoalDefinitionStepRepository,
} from '@/modules/flagship/goal-definition-step/domain/repositories/goal-definition-step-repository';
import {
  GoalDefinitionStepStateManager,
} from '@/modules/flagship/goal-definition-step/domain/state/goal-definition-step-state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetGoalDefinitionStepQuery extends Query<Promise<GoalDefinitionStepEntity>> {
  @Inject(TYPES.GOAL_DEFINITION_STEP_REPOSITORY)
  private readonly repository!: GoalDefinitionStepRepository;

  @Inject(TYPES.GOAL_DEFINITION_STEP_STATE_MANAGER)
  private readonly state_manager!: GoalDefinitionStepStateManager;

  internalExecute(loadStateManager = true): Promise<GoalDefinitionStepEntity> {
    return new Promise((resolve, reject) => {
      if (loadStateManager && this.state_manager.state.loaded) {
        resolve(this.state_manager.state.goal_step_entity);
      } else {
        this.repository.get()
          .then((goal_step_entity) => {
            this.state_manager.patch({ goal_step_entity, loaded: true });
            resolve(goal_step_entity);
          })
          .catch(reject);
      }
    });
  }
}
