import Vue from 'vue';

import {
  EmergencyFundLocationStateManager,
} from '@/modules/flagship/catalogs/emergency-fund-location/domain/state/emergency-fund-location-state-manager';
import EmergencyFundLocationState
  from '@/modules/flagship/catalogs/emergency-fund-location/domain/state/emergency-fund-location-state';
import Injectable from '@/modules/shared/domain/di/injectable';
import Observer from '@/modules/shared/domain/observer/observer';

@Injectable()
export default class EmergencyFundLocationVueStateManager implements
  EmergencyFundLocationStateManager {
  private _state: EmergencyFundLocationState = Vue.observable<EmergencyFundLocationState>(
    new EmergencyFundLocationState(),
  );

  private readonly observers: Observer[] = [];

  get state(): EmergencyFundLocationState {
    return this._state;
  }

  set state(value: EmergencyFundLocationState) {
    this._state = value;
    this.notifyAll();
  }

  patch(state: Partial<EmergencyFundLocationState>) {
    type Keys = keyof EmergencyFundLocationState;
    Object.entries(state).forEach(([key, value]) => {
      const accessor = key as Keys;

      if (value !== undefined) {
        // eslint-disable-next-line
        // @ts-ignore @typescript-eslint/ban-ts-ignore
        this.state[accessor] = value;
      }
    });
  }

  notifyAll(): void {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer): void {
    this.observers.push(observer);
  }
}
