import TYPES from '@/modules/shared/types';

// Domain
import {
  PendingInvestorGoalMovementsRepository,
} from '../../domain/repositories/pending-investor-goal-movements-repository';
import {
  PendingInvestorGoalMovementsEntity,
} from '../../domain/entities/pending-investor-goal-movements-entity';
import {
  PendingInvestorGoalMovementsQueryParamsDto,
} from '../../domain/dtos/pending-investor-goal-movements-query-params-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class PendingInvestorGoalMovementsHttpRepository
implements PendingInvestorGoalMovementsRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/pending-investor-goal-movements/?is_deposit={is_deposit}`;

  // eslint-disable-next-line max-len
  get(params: PendingInvestorGoalMovementsQueryParamsDto): Promise<Array<PendingInvestorGoalMovementsEntity>> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint.replace('{is_deposit}', String(params.is_deposit)))
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(JSON.stringify({
                  status_code: response.status,
                  message_error: message,
                }));
              }
            });
        })
        .catch(reject);
    });
  }
}
