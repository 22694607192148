import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import { SourceHttpRepository, SourceDummyRepository } from './infrastructure/repository';

// Application
import { SearchSourcesQuery } from './application/queries';

// Domain
import { SourceRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupSource(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.CATALOG_KUSPIT_SOURCE_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<SourceRepository>(TYPES.CATALOG_KUSPIT_SOURCE_REPOSITORY)
    .to(
      withDummies
        ? SourceDummyRepository
        : SourceHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchSourcesQuery>(TYPES.SEARCH_CATALOG_KUSPIT_SOURCE_QUERY)
    .to(SearchSourcesQuery)
    .inSingletonScope();
}
