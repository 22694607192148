import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import {
  CreateAllianzPolicyDummyRepository,
  CreateAllianzPolicyHttpRepository,
} from './infrastructure/repository';

// Application
import { CreateAllianzPolicyCommand } from './application/commands';

// Domain
import { CreateAllianzPolicyRepository } from './domain/repository';

export default function setupCustomerKuspitAccount(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<CreateAllianzPolicyRepository>(TYPES.CREATE_ALLIANZ_POLICY_REPOSITORY)
    .to(
      withDummies
        ? CreateAllianzPolicyDummyRepository
        : CreateAllianzPolicyHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<CreateAllianzPolicyCommand>(TYPES.CREATE_ALLIANZ_POLICY_COMMAND)
    .to(CreateAllianzPolicyCommand)
    .inSingletonScope();
}
