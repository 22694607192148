import Injectable from '@/modules/shared/domain/di/injectable';
import VerifyNipRepository from '../../domain/repositories/verify-nip-repository';
import { NipDto } from '../../domain/dtos/nip-dto';

@Injectable()
export default class VerifyNipDummyRepository implements VerifyNipRepository {
  // eslint-disable-next-line class-methods-use-this
  create(nip_dto: NipDto): Promise<void> {
    console.log('NIP: ', nip_dto);

    return Promise.resolve();
  }
}
