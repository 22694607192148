// Domain
import {
  InvestmentProductEntity,
} from '@/modules/flagship/investor-profile/recommended-investment-product/domain/entities/investment-product-entity';

export default class RecommendedInvestmentProductState {
  loaded = false;

  investment_product: InvestmentProductEntity = {
    investment_product: {
      id: '',
      name: '',
      label: '',
    },
  };
}
