import { Container } from 'inversify-props';
import setupAssociatedProduct from './associated-product/setup';
import setupLinkSowosPocket from './link-sowos-pocket/setup';
import setupLinkSowosWealth from './link-sowos-wealth/setup';
import setupInvestorGoalDates from './investor-goal-dates/setup';
import setupDistributeUnassignedBalance from './distribute-unassigned-balance/setup';
import setupDistributeBalance from './distribute-balance/setup';
import setupSowosWealthDistributeRecurrentBalance from './sowos-wealth/distribute-recurrent-balance/setup';
import setupSearchByCustomer from './search-by-customer/setup';
import setupGoalTracking from './goal-tracking/setup';
import setupGlobalTracking from './global-tracking/setup';
import setupLinkSowosWealthModerate from './link-sowos-wealth-moderate/setup';
import setupInvestorGoalDetail from './investor-goal-detail/setup';
import setupFinalInvestmentDate from './final-investment-date-calculator/setup';
import setupLinkedInvestorGoal from './linked-investor-goal/setup';

export default function setupInvestorGoalModule(container: Container) {
  setupAssociatedProduct(container);
  setupLinkSowosPocket(container);
  setupLinkSowosWealth(container);
  setupInvestorGoalDates(container);
  setupDistributeUnassignedBalance(container);
  setupDistributeBalance(container);
  setupSowosWealthDistributeRecurrentBalance(container);
  setupSearchByCustomer(container);
  setupGoalTracking(container);
  setupGlobalTracking(container);
  setupLinkSowosWealthModerate(container);
  setupInvestorGoalDetail(container);
  setupFinalInvestmentDate(container);
  setupLinkedInvestorGoal(container);
}
