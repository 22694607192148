// Domain
import { Person } from '../../domain/entities';

export default class PersonPresenter {
  private readonly person!: Person;

  constructor(person: Person) {
    this.person = person;
  }

  get full_name() {
    return `${this.person.name} ${this.person.last_name}`;
  }

  get initials() {
    return `${this.person.name[0]}${this.person.last_name[0]}`;
  }
}
