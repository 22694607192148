import TYPES from '@/types';

// Domain
import {
  UpdateOnBoardingStepRepository,
} from '../../../../domain/repositories';
import { OnBoardingStepEntity } from '../../../../domain/entities/on-boarding-step-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class UpdateOnBoardingStepHttpRepository
implements UpdateOnBoardingStepRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly path = `${process.env.VUE_APP_API_URL}/api/v1/app/investment/on-boarding-step/`;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  execute(on_boarding_step: OnBoardingStepEntity): Promise<any> {
    const { id, ...step_modified } = on_boarding_step;
    return new Promise((resolve, reject) => {
      this.http_connector.execute(
        this.path.concat(`${on_boarding_step.id}/`), 'PATCH', step_modified,
      )
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            reject(new Error('There was an error updating the OnBoardingStep.'));
          }
        })
        .catch(reject);
    });
  }
}
