import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import { ContactPointSowosModerateDummyRepository }
  from '@/modules/flagship/contact-points/sowos-moderate/infraestructure/repositories/contact-point-sowos-moderate-dummy-repository';
import ContactPointSowosModerateHttpRepository
  from '@/modules/flagship/contact-points/sowos-moderate/infraestructure/repositories/contact-point-sowos-moderate-http-repository';

// Application
import GetModerateInformationByEmailQuery
  from '@/modules/flagship/contact-points/sowos-moderate/application/queries/get-moderate-information-by-email-query';
import SearchAnswersInitialPollQuery
  from '@/modules/flagship/contact-points/sowos-moderate/application/queries/search-answers-initial-poll-query';
import SearchAnswersExitPollQuery
  from '@/modules/flagship/contact-points/sowos-moderate/application/queries/search-answers-exit-poll-query';
import CreateResponseNotReadyToContractCommand
  from '@/modules/flagship/contact-points/sowos-moderate/application/commands/create-response-not-ready-to-contract-command';
import CreateResponseUnlinkGoalFromModerateCommand
  from '@/modules/flagship/contact-points/sowos-moderate/application/commands/create-response-unlink-goal-from-moderate-command';

// Domain
import {
  ContactPointSowosModerateRepository,
} from '@/modules/flagship/contact-points/sowos-moderate/domain/repositories/contact-point-sowos-moderate-repository';

export default function setupContactPoiontsSowosModerate(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<ContactPointSowosModerateRepository>(
      TYPES.CONTACT_POINT_SOWOS_MODERATE_REPOSITORY,
    ).to(
      withDummies
        ? ContactPointSowosModerateDummyRepository
        : ContactPointSowosModerateHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetModerateInformationByEmailQuery>(
      TYPES.GET_MODERATE_INFORMATION_BY_EMAIL_QUERY,
    ).to(GetModerateInformationByEmailQuery)
    .inSingletonScope();

  container
    .bind<SearchAnswersInitialPollQuery>(
      TYPES.SEARCH_ANSWERS_INITIAL_POLL_QUERY,
    ).to(SearchAnswersInitialPollQuery)
    .inSingletonScope();

  container
    .bind<CreateResponseNotReadyToContractCommand>(
      TYPES.CREATE_RESPONSE_NOT_READY_TO_CONTRACT_MODERATE_COMMAND,
    ).to(CreateResponseNotReadyToContractCommand)
    .inSingletonScope();

  container
    .bind<SearchAnswersExitPollQuery>(
      TYPES.SEARCH_ANSWERS_EXIT_POLL_QUERY,
    ).to(SearchAnswersExitPollQuery)
    .inSingletonScope();

  container
    .bind<CreateResponseUnlinkGoalFromModerateCommand>(
      TYPES.CREATE_RESPONSE_UNLINK_GOAL_FROM_MODERATE_COMMAND,
    ).to(CreateResponseUnlinkGoalFromModerateCommand)
    .inSingletonScope();
}
