



































import {
  Component, Watch, Vue, Prop,
} from 'vue-property-decorator';
import RangePickerViewModel from '@/vue-app/view-models/components/range-picker-view-model';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';
import Inject from '@/modules/shared/domain/di/inject';
import TYPES from '@/types';

@Component({ name: 'RangePicker' })
export default class RangePicker extends Vue {
  @Prop({ required: true }) initial_dates!: Array<string>;

  @Prop({ default: true }) clearable?: boolean;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  range_picker_model = Vue.observable(
    new RangePickerViewModel(this),
  );

  @Watch('range_picker_model.dates')
  onDatesChange() {
    if (this.range_picker_model.dates.length === 1) {
      this.range_picker_model.start_date = this.date_formatter.formatDate(this.range_picker_model.dates[0], 'DD/MM/YYYY');
    }
    if (this.range_picker_model.dates.length === 2) {
      this.range_picker_model.start_date = this.date_formatter.formatDate(this.range_picker_model.dates[0], 'DD/MM/YYYY');
      this.range_picker_model.end_date = this.date_formatter.formatDate(this.range_picker_model.dates[1], 'DD/MM/YYYY');
      this.range_picker_model.menu = false;
      this.$emit('changeDates', this.range_picker_model.dates);
    }
    this.range_picker_model.dateRangeText = `${this.range_picker_model.start_date},
     ${this.range_picker_model.end_date}`;
  }

  created() {
    this.range_picker_model.dates = this.initial_dates;
  }
}
