import UnautheneticatedLayout from '@/vue-app/layouts/UnauthenticatedLayout.vue';
import RecoveryPassword from '@/vue-app/views/RecoveryPassword.vue';
import POLICIES from '../security/policies';
import validateQueryToken from '../security/recovery-password-token';

export default {
  path: '/recovery-password',
  component: UnautheneticatedLayout,
  children: [
    {
      path: '/',
      name: 'RecoveryPassword',
      component: RecoveryPassword,
      meta: {
        access_policy: POLICIES.UNAUTHENTICATED,
      },
      beforeEnter: validateQueryToken,
    },
  ],
};
