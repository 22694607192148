import TYPES from '../../types';

// Domain
import { MatiVerificationEntity }
  from '../../domain/entities/mati-verification-entity';
import { StateManager } from '../../domain/state-manager';
import {
  MatiVerificationRepository,
} from '../../domain/repositories/mati-verification-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
class GetMatiVerificationQuery extends Query<Promise<MatiVerificationEntity>> {
  @Inject(TYPES.ON_BOARDING_IDENTITY_VERIFICATION_STATE_MANAGER)
  private readonly state_manager!: StateManager

  @Inject(TYPES.ON_BOARDING_MATI_VERIFICATION_REPOSITORY)
  private readonly repository!: MatiVerificationRepository;

  internalExecute(reload: boolean): Promise<MatiVerificationEntity> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.mati_verification_state.loaded && !reload) {
        resolve(this.state_manager.mati_verification_state.mati_verification);
      } else {
        this.repository.get()
          .then((mati_verification) => {
            this.state_manager.patch_mati_verification_state({ mati_verification, loaded: true });
            resolve(mati_verification);
          })
          .catch(reject);
      }
    });
  }
}

export default GetMatiVerificationQuery;
