import change_bank_account from './change-bank-account.json';
import change_bank_account_success from './change-bank-account-success.json';
import change_bank_account_status from './change-bank-account-status.json';
import request_status from './status';

export default {
  ...change_bank_account,
  ...change_bank_account_success,
  ...change_bank_account_status,
  ...request_status,
};
