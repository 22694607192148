// Domain
import { RecoveryPasswordRepository } from '../../../domain/repositories';
import injectable from '@/modules/shared/domain/di/injectable';

@injectable()
export default class RecoveryPasswordDummyRepository implements RecoveryPasswordRepository {
  // eslint-disable-next-line class-methods-use-this
  restore(): Promise<void> {
    return Promise.resolve();
  }
}
