import fixtures from './fixtures.json';

// Domain
import { RetirementInvestorGoalCalculatorDto }
  from '../../../domain/dtos/retirement-investor-goal-calculator-dto';
import { RetirementInvestorGoalCalculatorEntity }
  from '../../../domain/entities/retirement-investor-goal-calculator-entity';
import { RetirementInvestorGoalCalculatorRepository }
  from '../../../domain/repositories/retirement-investor-goal-calculator-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class RetirementInvestorGoalCalculatorDummyRepository implements
  RetirementInvestorGoalCalculatorRepository {
  get_retirement_fund_calculation(retirement_fund_calculation_dto:
    RetirementInvestorGoalCalculatorDto): Promise<RetirementInvestorGoalCalculatorEntity> {
    return Promise.resolve(fixtures);
  }
}
