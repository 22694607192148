import header from './header';
import contractSavings from './contract-savings/index';
import onboardingKuspit from './onboarding-kuspit';
import allianzDashboard from './allianz-dashboard';
import kuspitDashboard from './kuspit-dashboard';
import homeDashboard from './home-dashboard';
import myInvestmentDashboard from './my-investment-dashboard';
import userProfile from './user-profile';
import sessionStatus from './session-status';
import common from './common';
import supportWidget from './support-widget';
import flagship from './flagship';
import investmentSuggestion from './investment-suggestion';
import goalsDashboard from './goals-dashboard';
import navBar from './nav-bar';
import footer from './footer';
import banners from './banners';
import strategyModerateWealth from './strategy-moderate-wealth';
import documentValidation from './document-validation';
import authentication from '@/locales/es/components/authentication';
import updateIdentityDocument from './update-identity-document';

export default {
  components: {
    ...header,
    ...common,
    ...contractSavings,
    ...onboardingKuspit,
    ...allianzDashboard,
    ...kuspitDashboard,
    ...homeDashboard,
    ...myInvestmentDashboard,
    ...userProfile,
    ...sessionStatus,
    ...supportWidget,
    ...flagship,
    ...investmentSuggestion,
    ...goalsDashboard,
    ...navBar,
    ...footer,
    ...banners,
    ...strategyModerateWealth,
    ...documentValidation,
    ...authentication,
    ...updateIdentityDocument,
  },
};
