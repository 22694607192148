import fixtures from './fixtures.json';

// Domain
import {
  PendingInvestorGoalMovementsRepository,
} from '../../../domain/repositories/pending-investor-goal-movements-repository';
import {
  PendingInvestorGoalMovementsEntity,
} from '../../../domain/entities/pending-investor-goal-movements-entity';
import {
  PendingInvestorGoalMovementsQueryParamsDto,
} from '../../../domain/dtos/pending-investor-goal-movements-query-params-dto';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class PendingInvestorGoalMovementsDummyRepository
implements PendingInvestorGoalMovementsRepository {
  get(params: PendingInvestorGoalMovementsQueryParamsDto):
    Promise<Array<PendingInvestorGoalMovementsEntity>> {
    return Promise.resolve(fixtures);
  }
}
