import Vue from 'vue';
import TYPES from '@/types';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';

export default class RangePickerViewModel {
  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  private readonly view!: Vue;

  dates: Array<string> = [];

  start_date = '';

  end_date = '';

  dateRangeText = '';

  menu = false;

  public constructor(view: Vue) {
    this.view = view;
  }

  clearDates() {
    this.dates = [];
    this.dateRangeText = '';
    this.view.$emit('changeDates', this.dates);
  }
}
