import TYPES from '../../types';

// Domain
import {
  EmergencyFundAvailabilityRepository,
} from '@/modules/flagship/catalogs/emergency-fund-availability/domain/repositories/emergency-fund-availability-repository';
import {
  EmergencyFundAvailabilityEntity,
} from '@/modules/flagship/catalogs/emergency-fund-availability/domain/entities/emergency-fund-availability-entity';
import {
  EmergencyFundAvailabilityStateManager,
} from '@/modules/flagship/catalogs/emergency-fund-availability/domain/state/emergency-fund-availability-state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetEmergencyFundAvailabilityFlagshipProfilingQuery
  extends Query<Promise<Array<EmergencyFundAvailabilityEntity>>> {
  @Inject(TYPES.EMERGENCY_FUND_AVAILABILITY_REPOSITORY)
  private readonly repository!: EmergencyFundAvailabilityRepository;

  @Inject(TYPES.EMERGENCY_FUND_STATE_MANAGER)
  private readonly state_manager!: EmergencyFundAvailabilityStateManager;

  internalExecute(): Promise<Array<EmergencyFundAvailabilityEntity>> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded) {
        resolve(this.state_manager.state.items);
      } else {
        this.repository.execute()
          .then((items) => {
            this.state_manager.patch({ items, loaded: true });
            resolve(items);
          })
          .catch(reject);
      }
    });
  }
}
