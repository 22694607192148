// Domain
import {
  InvestorGoalScheduledDistributionWealthFlexibleRepository,
} from '../../../domain/repositories/investor-goal-scheduled-distribution-wealth-flexible-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class InvestorGoalScheduledDistributionWealthFlexibleDummyRepository
implements InvestorGoalScheduledDistributionWealthFlexibleRepository {
  distribute(): Promise<void> {
    return Promise.resolve();
  }
}
