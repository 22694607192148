export default {
  RUNNER: Symbol.for('RUNNER'),
  EXECUTOR_LINK: Symbol.for('EXECUTOR_LINK'),
  HTTP_CONNECTOR: Symbol.for('HTTP_CONNECTOR'),
  DATETIME_VALUE: Symbol.for('DATETIME_VALUE'),
  WEB_SOCKET: Symbol.for('WebSocket'),
  NOTIFIER: Symbol.for('NOTIFIER'),
  UTIL_FUNCTIONS: Symbol.for('UTIL_FUNCTIONS'),
  GEOLOCATION: Symbol.for('GEOLOCATION'),
  PERMISSIONS: Symbol.for('PERMISSIONS'),
  GEOCODER: Symbol.for('GEOCODER'),
  ROUTER: Symbol.for('ROUTER'),
  EVENT_BUS: Symbol.for('EVENT_BUS'),
  DATE_FORMATTER: Symbol.for('DATE_FORMATTER'),
  I18N: Symbol.for('I18N'),
};
