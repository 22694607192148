import { Container } from 'inversify-props';
import TYPES from './types';
import VueStateManager from './infrastructure/state/vue-state-manager';
import SearchAgreementsDummyRepository
  from './infrastructure/repositories/search-agreements-dummy-repository';
import SearchAgreementsHttpRepository
  from './infrastructure/repositories/search-agreements-http-repository';
import SearchAgreementsQuery from './application/queries/search-agreements-query';
import SearchAgreementsAsyncQuery from './application/queries/search-agreements-async-query';
import StateManager from './domain/state/state-manager';
import { SearchAgreementsRepository } from './domain/repositories/search-agreements-repository';

export default function setupAgreements(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.AGREEMENTS_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<SearchAgreementsRepository>(TYPES.SEARCH_AGREEMENTS_REPOSITORY)
    .to(
      withDummies
        ? SearchAgreementsDummyRepository
        : SearchAgreementsHttpRepository,
    ).inSingletonScope();

  container
    .bind<SearchAgreementsQuery>(TYPES.SEARCH_AGREEMENTS_QUERY)
    .to(SearchAgreementsQuery)
    .inSingletonScope();

  container
    .bind<SearchAgreementsAsyncQuery>(TYPES.SEARCH_AGREEMENTS_ASYNC_QUERY)
    .to(SearchAgreementsAsyncQuery)
    .inSingletonScope();
}
