import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import {
  CustomerKuspitPositionDetailsHttpRepository,
  CustomerKuspitPositionDetailsDummyRepository,
  CustomerKuspitPositionDummyRepository,
  CustomerKuspitPositionHttpRepository,
} from './infrastructure/repository';

// Application
import {
  GetCustomerKuspitPositionDetailsQuery,
  GetCustomerKuspitPositionQuery,
} from './application/queries';

// Domain
import {
  CustomerKuspitPositionDetailsRepository,
  CustomerKuspitPositionRepository,
} from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupCustomerKuspitPosition(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.CUSTOMER_KUSPIT_POSITION_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<CustomerKuspitPositionDetailsRepository>(
      TYPES.CUSTOMER_KUSPIT_POSITION_DETAILS_REPOSITORY,
    )
    .to(
      withDummies
        ? CustomerKuspitPositionDetailsDummyRepository
        : CustomerKuspitPositionDetailsHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<GetCustomerKuspitPositionDetailsQuery>(TYPES.GET_CUSTOMER_KUSPIT_POSITION_DETAILS_QUERY)
    .to(GetCustomerKuspitPositionDetailsQuery)
    .inSingletonScope();

  container
    .bind<CustomerKuspitPositionRepository>(
      TYPES.CUSTOMER_KUSPIT_POSITION_REPOSITORY,
    )
    .to(
      withDummies
        ? CustomerKuspitPositionDummyRepository
        : CustomerKuspitPositionHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<GetCustomerKuspitPositionQuery>(TYPES.GET_CUSTOMER_KUSPIT_POSITION_QUERY)
    .to(GetCustomerKuspitPositionQuery)
    .inSingletonScope();
}
