import TYPES from '../../types';

// Domain
import { AllianzEarningsReportEntity } from '../../domain/entities/allianz-earnings-report-entity';
import {
  AllianzEarningsReportRepository,
} from '../../domain/repository/allianz-earnings-report-repository';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class SearchAllianzEarningsReportQuery
  extends Query<Promise<Array<AllianzEarningsReportEntity>>> {
  @Inject(TYPES.ALLIANZ_EARNINGS_REPORT_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.ALLIANZ_EARNINGS_REPORT_REPOSITORY)
  private readonly repository!: AllianzEarningsReportRepository;

  internalExecute(customer_id: string): Promise<Array<AllianzEarningsReportEntity>> {
    return new Promise((resolve, reject) => {
      if (!this.state_manager.state.loaded) {
        this.repository.execute(customer_id)
          .then((items) => {
            this.state_manager.patch({ items, loaded: true });
            resolve(items);
          })
          .catch(reject);
      } else {
        resolve(this.state_manager.state.items);
      }
    });
  }
}
