import TYPES from '../../types';

// Domain
import { StepEntity } from '../../domain/entities/step-entity';
import StateManager from '../../domain/state/state-manager';
import { OnBoardingSearchStepsRepository }
  from '../../domain/repositories/on-boarding-search-steps-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';
import { Sort } from '@/modules/shared/domain/dtos/sort_dto';

@Injectable()
export default class SearchOnBoardingStepsQuery extends Query<Promise<Array<StepEntity>>> {
  @Inject(TYPES.ON_BOARDING_STEPS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.ON_BOARDING_SEARCH_STEPS_REPOSITORY)
  private readonly repository!: OnBoardingSearchStepsRepository;

  internalExecute(sort: Sort): Promise<Array<StepEntity>> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded) {
        resolve(this.state_manager.state.items);
      } else {
        this.repository.execute(sort)
          .then((items) => {
            this.state_manager.patch({ items });
            resolve(items);
          })
          .catch(reject);
      }
    });
  }
}
