// Domain
import { LinkSowosWealthModerateRepository } from '../../domain/repositories/link-sowos-wealth-moderate-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class LinkSowosWealthModerateDummyRepository implements
  LinkSowosWealthModerateRepository {
  link(): Promise<void> {
    return Promise.resolve();
  }
}
