import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';
import TYPES from '@/types';
import OnBoardingCreateStepRepository from '../../../domain/repositories/on-boarding-create-step-repository';
import { StepEntity } from '../../../domain/entities/step-entity';

@Injectable()
export default class OnboardingCreateStepHttpRepository implements OnBoardingCreateStepRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly path = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/on-boarding-steps/`;

  execute(step: StepEntity): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.path, 'POST', step)
        .then((response) => {
          response.json().then(({ success, message }) => {
            if (success) {
              resolve();
            } else {
              reject(new Error(message));
            }
          });
        })
        .catch(reject);
    });
  }
}
