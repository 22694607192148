import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import {
  InvestmentTypeHttpRepository,
  InvestmentTypeDummyRepository,
} from './infrastructure/repository';

// Application
import { GetInvestmentTypeQuery } from './application/queries';

// Domain
import { InvestmentTypeRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupInvestmentType(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.ALLIANZ_INVESTMENT_TYPE_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<InvestmentTypeRepository>(TYPES.ALLIANZ_INVESTMENT_TYPE_REPOSITORY)
    .to(
      withDummies
        ? InvestmentTypeDummyRepository
        : InvestmentTypeHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<GetInvestmentTypeQuery>(TYPES.GET_ALLIANZ_INVESTMENT_TYPE_QUERY)
    .to(GetInvestmentTypeQuery)
    .inSingletonScope();
}
