// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { FinancialInstitutionRepository } from '../../../domain/repository';
import { FinancialInstitutionEntity } from '../../../domain/entities/financial-institution-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class FinancialInstitutionDummyRepository implements FinancialInstitutionRepository {
  execute(): Promise<Array<FinancialInstitutionEntity>> {
    return Promise.resolve(fixtures);
  }
}
