import Injectable from '@/modules/shared/domain/di/injectable';
import { OnBoardingStatusRepository } from '../domain/repositories/on-boarding-status-repository';
import { OnBoardingStatusEntity } from '../domain/entities/on-boarding-status-entity';

@Injectable()
export default class OnBoardingStatusDummyRepository implements OnBoardingStatusRepository {
  get(): Promise<OnBoardingStatusEntity> {
    return Promise.resolve({
      customer_id: '934eec2d-fe41-4121-9a30-f23f7b7f15a4',
      onboarding_completed: sessionStorage.getItem('on-boarding-completed') === 'true',
      onboarding_completed_at: '2022-03-10T01:38:35.088818+00:00',
    });
  }

  complete(): Promise<void> {
    sessionStorage.setItem('on-boarding-completed', 'true');

    return Promise.resolve();
  }
}
