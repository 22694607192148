import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';
import { IdentityVerificationEntity } from '../../domain/entities/identity-verification-entity';
import { IdentityVerificationServiceRepository }
  from '../../domain/repositories/identity-verification-service-repository';
import { StateManager } from '../../domain/state-manager';

@Injectable()
// eslint-disable-next-line max-len
class GetOnBoardingIdentityVerificationServiceQuery extends Query<IdentityVerificationEntity> {
  @Inject(TYPES.ON_BOARDING_IDENTITY_VERIFICATION_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.ON_BOARDING_IDENTITY_VERIFICATION_SERVICE_REPOSITORY)
  private readonly repository!: IdentityVerificationServiceRepository;

  internalExecute(): IdentityVerificationEntity {
    if (!this.state_manager.state.loaded) {
      this.repository.get('dab000f4-35e7-40b8-93c1-81fd9da426ba')
        .then((credentials) => {
          this.state_manager.patch({ credentials, loaded: true });
        });
    }

    return this.state_manager.state.credentials;
  }
}

export default GetOnBoardingIdentityVerificationServiceQuery;
