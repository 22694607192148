// Infrastructure
import fixtures from './fixtures.json';

// Domain
import {
  RiskToleranceQuestionsRepository,
} from '../../../domain/repositories/risk-tolerance-questions-repository';
import {
  RiskToleranceQuestionsEntity,
} from '../../../domain/entities/risk-tolerance-questions-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class RiskToleranceQuestionsDummyRepository
implements RiskToleranceQuestionsRepository {
  get(): Promise<Array<RiskToleranceQuestionsEntity>> {
    return Promise.resolve(fixtures);
  }
}
