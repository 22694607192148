import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';

// Infrastructure
import MovementPresenter
  from '@/modules/my-investment/kuspit/customer-kuspit-movement/infrastructure/presenters/movement-presenter';

// Application
import {
  SearchCustomerKuspitMovementsQuery,
} from '@/modules/my-investment/kuspit/customer-kuspit-movement/application/queries';
import {
  GetHasKuspitAccountQuery,
} from '@/modules/my-investment/kuspit/customer-has-account/application/queries';

// Domain
import {
  MovementEntity,
} from '@/modules/my-investment/kuspit/customer-kuspit-movement/domain/entities/movement-entity';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class MovementsViewModel {
  @Inject(TYPES.SEARCH_CUSTOMER_KUSPIT_MOVEMENTS_QUERY)
  readonly search_customer_kuspit_movements_query!: SearchCustomerKuspitMovementsQuery;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.GET_CUSTOMER_HAS_KUSPIT_ACCOUNT_QUERY)
  readonly get_has_kuspit_account_query!: GetHasKuspitAccountQuery;

  readonly i18n_namespace = 'components.kuspit-dashboard.movements';

  load_kuspit_finished = false;

  dates_selected = ['2022-02-15', '2022-03-25']

  movements_options = [
    {
      name: 'Todos los movimientos',
      type: 'all',
    },
    {
      name: 'Retiros',
      type: 'R',
    },
    {
      name: 'Depósitos',
      type: 'D',
    },
    {
      name: 'Transacciones',
      type: 'T',
    },
  ];

  movement_option_selected = this.movements_options[0];

  headers = [
    {
      text: 'icon',
      value: 'name',
    },
    {
      text: 'Tipo',
      value: 'type',
    },
    {
      text: 'Fecha',
      value: 'date',
    },
    {
      text: 'Monto',
      value: 'amount',
    },
  ];

  movements: Array<MovementEntity> = [];

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async () => {
    this.dates_selected = this.firstRangeOfDates;
    await this.verifyHasKuspitAccount();
  }

  get kuspitMovements() {
    return this.search_customer_kuspit_movements_query.execute({
      initial_date: this.dates_selected[0],
      final_date: this.dates_selected[1],
    });
  }

  get has_kuspit_account() {
    return this.get_has_kuspit_account_query.execute();
  }

  getAmountFormatted = (amount: number) => (currencyFormat(amount));

  createMovementPresenter = (movement: MovementEntity) => (new MovementPresenter(movement));

  verifyHasKuspitAccount = async () => {
    try {
      const has_kuspit_account = await this.get_has_kuspit_account_query.execute();
      if (has_kuspit_account.has_account) {
        await this.loadKuspitMovements();
        this.load_kuspit_finished = true;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.has_kuspit_account'));
    }
  }

  loadKuspitMovements = async () => {
    try {
      const movements = await this.search_customer_kuspit_movements_query.execute({
        initial_date: this.dates_selected[0],
        final_date: this.dates_selected[1],
      });
      this.fillData(movements);
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification('errors.kuspit_movements');
      }
    }
  }

  changeDates = async (range_of_dates: Array<string>) => {
    this.dates_selected = range_of_dates;
    if (this.dates_selected.length === 2) {
      this.fillData(
        await this.kuspitMovements,
        this.dates_selected[0],
        this.dates_selected[1],
        this.movement_option_selected.type,
      );
    } else {
      this.fillData(
        await this.kuspitMovements,
        undefined,
        undefined,
        this.movement_option_selected.type,
      );
    }
  }

  fillData(movements: Array<MovementEntity>,
    initial_date?: string,
    end_date?: string,
    movement_type?: string) {
    try {
      let filtered_movements = movements;
      if (movement_type !== undefined && movement_type !== 'all') {
        filtered_movements = this.filterByMovementType(movement_type, movements);
      }
      const sort_movements = filtered_movements.sort(
        (movement_one, movement_two) => {
          const fd = new Date(movement_one.payment_date);
          const sd = new Date(movement_two.payment_date);
          return fd > sd ? -1 : 1;
        },
      );
      this.movements = sort_movements;
    } catch {
      this.message_notifier.showErrorNotification(
        'Ha ocurrido un error al obtener tus movimientos, inténtalo nuevamente',
      );
      this.load_kuspit_finished = false;
    }
  }

  get firstRangeOfDates() {
    const date = new Date();
    date.setDate(date.getDate() - 90);
    return [
      date.toISOString().split('T')[0],
      new Date().toISOString().split('T')[0],
    ];
  }

  filterByMovementType(movement_type: string, movements: Array<MovementEntity>) {
    return movements.filter(
      (movement) => movement.movement_type === movement_type,
    );
  }

  async refreshMovements() {
    const movements = await this.search_customer_kuspit_movements_query
      .execute({
        initial_date: this.firstRangeOfDates[0],
        final_date: this.firstRangeOfDates[1],
        refetch: true,
      });
    this.fillData(movements);
  }
}
