import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import { OnBoardingStatusVueStateManager } from './infrastructure/state';

// Application
import { GetMyInvestmentAllianzOnBoardingStatusQuery } from './application/queries';
import { MyInvestmentAllianzOnBoardingStatusSubscription } from './application/subscriptions';

// Domain
import { OnBoardingStatusStateManager } from './domain/state';

export default function setupMyInvestmentAllianzOnBoardingStatusModule(container: Container) {
  container
    .bind<OnBoardingStatusStateManager>(
      TYPES.MY_INVESTMENT_ALLIANZ_ON_BOARDING_STATUS_STATE_MANAGER,
    ).to(OnBoardingStatusVueStateManager)
    .inSingletonScope();

  container
    .bind<GetMyInvestmentAllianzOnBoardingStatusQuery>(
      TYPES.GET_MY_INVESTMENT_ALLIANZ_ON_BOARDING_STATUS_QUERY,
    ).to(GetMyInvestmentAllianzOnBoardingStatusQuery)
    .inSingletonScope();

  container
    .bind<MyInvestmentAllianzOnBoardingStatusSubscription>(
      TYPES.MY_INVESTMENT_ALLIANZ_ON_BOARDING_STATUS_SUBSCRIPTION,
    ).to(MyInvestmentAllianzOnBoardingStatusSubscription)
    .inSingletonScope();
}
