import TYPES from '@/modules/shared/types';

// Domain
import {
  PersonalInfoRepository,
} from '@/modules/flagship/investor-profile/personal-info/domain/repositories/personal-info-repository';
import {
  PersonalInfoEntity,
} from '@/modules/flagship/investor-profile/personal-info/domain/entities/personal-info-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class PersonalInfoHttpRepository implements PersonalInfoRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/investor-profile/personal-info/{investor_profile_id}/`;

  execute(investor_profile_id: string): Promise<PersonalInfoEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint.replace('{investor_profile_id}', investor_profile_id))
        .then((response) => {
          response.json()
            .then(({ success, data, error }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(error));
              }
            });
        })
        .catch(reject);
    });
  }
}
