import Vue from 'vue';
// Domain
import { OverallGoalsStatusStateManager } from '@/modules/flagship/investor-profile/overall-goals-status/domain/state/overall-goals-status-state-manager';
import OverallGoalsStatusState from '@/modules/flagship/investor-profile/overall-goals-status/domain/state/overall-goals-status-state';

import Injectable from '@/modules/shared/domain/di/injectable';
import Observer from '@/modules/shared/domain/observer/observer';

@Injectable()
export default class OverallGoalsStateVueStateManager implements OverallGoalsStatusStateManager {
  // eslint-disable-next-line max-len
  private _state: OverallGoalsStatusState = Vue.observable<OverallGoalsStatusState>(new OverallGoalsStatusState());

  private readonly observers: Observer[] = [];

  get state(): OverallGoalsStatusState {
    return this._state;
  }

  set state(value: OverallGoalsStatusState) {
    this._state = value;
    this.notifyAll();
  }

  patch(state: Partial<OverallGoalsStatusState>) {
    type Keys = keyof OverallGoalsStatusState;
    Object.entries(state).forEach(([key, value]) => {
      const accessor = key as Keys;

      if (value !== undefined) {
        // eslint-disable-next-line
        // @ts-ignore @typescript-eslint/ban-ts-ignore
        this.state[accessor] = value;
      }
    });
  }

  notifyAll() {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer) {
    this.observers.push(observer);
  }
}
