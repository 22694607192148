import fixtures from './fixture.json';

// Domain
import Injectable from '@/modules/shared/domain/di/injectable';
import {
  MonthlyPaymentCalculationRepository,
} from '@/modules/flagship/emergency-fund-calculations/fixed-time-calculation/domain/repositories/monthly-payment-calculation-repository';
import {
  EmergencyFundCalculationResponse,
} from '@/modules/flagship/emergency-fund-calculations/fixed-time-calculation/domain/entities/emergency-fund-calculation-response';

@Injectable()
class MonthlyPaymentCalculationDummyRepository implements MonthlyPaymentCalculationRepository {
  calculate(): Promise<EmergencyFundCalculationResponse> {
    return Promise.resolve(fixtures);
  }
}

export default MonthlyPaymentCalculationDummyRepository;
