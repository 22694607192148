import { detect } from 'detect-browser';
import { BrowserModulesType } from '../types/browser-modules-type';

// eslint-disable-next-line import/prefer-default-export
export function setupAdapterAccordingToBrowser(modules: BrowserModulesType) {
  const browser = detect();

  switch (browser?.name) {
    case 'chrome':
      return modules.chrome || modules.default;
    case 'edge-chromium':
      return modules.edge_chromium || modules.default;
    default:
      return modules.default;
  }
}
