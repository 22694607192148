// Domain
import { CreateAllianzPolicyRepository } from '../../../../domain/repository';
import { AllianzPolicyEntity } from '../../../../domain/entities/allianz-policy-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CreateAllianzPolicyDummyRepository implements CreateAllianzPolicyRepository {
  create(allainz_policy_entity: AllianzPolicyEntity): Promise<void> {
    return Promise.resolve();
  }
}
