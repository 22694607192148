import TYPES from '../../types';

// Domain
import { AvailabilityToHireProductEntity } from '../../domain/entities/availability-to-hire-product-entity';
import {
  InvestmentProductRepository,
} from '../../domain/repository/investment-product-repository';
import { AvailabilityToHireProductStateManager } from '../../domain/state/availability-to-hire-product-state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class FindAvailabilityToHireProductQuery
  extends Query<Promise<AvailabilityToHireProductEntity>> {
  @Inject(TYPES.AVAILABILITY_TO_HIRE_PRODUCT_STATE_MANAGER)
  private readonly state_manager!: AvailabilityToHireProductStateManager;

  @Inject(TYPES.INVESTMENT_PRODUCT_REPOSITORY)
  private readonly repository!: InvestmentProductRepository;

  internalExecute(investment_product_id: string): Promise<AvailabilityToHireProductEntity> {
    return new Promise((resolve, reject) => {
      const availability = this.state_manager.state.items.find(
        (availability_by_product) => availability_by_product
          .investment_product_id === investment_product_id,
      );
      if (!availability) {
        this.repository.findAvailabilityToHireProduct(investment_product_id)
          .then((new_availability) => {
            this.state_manager.state.items.push(new_availability);
            resolve(new_availability);
          })
          .catch(reject);
      } else {
        resolve(availability);
      }
    });
  }
}
