// Domain
import {
  InvestorProfileOnFlowShowedRepository,
} from '@/modules/flagship/investor-profile/flow-showed/domain/repositories/investor-profile-on-flow-showed-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class InvestorProfileOnFlowShowedDummyRepository
implements InvestorProfileOnFlowShowedRepository {
  update(): Promise<any> {
    return Promise.resolve();
  }
}
