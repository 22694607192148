const PASSWORD_MIN_LENGTH = 8;

export const minLengthValidator = (password: string): boolean => {
  let is_valid_password = false;

  if (password.length >= PASSWORD_MIN_LENGTH) {
    is_valid_password = true;
  }

  return is_valid_password;
};
