/* eslint-disable import/no-cycle */

import Injectable from '../di/injectable';
import Inject from '../di/inject';
import TYPES from '../../types';
import ExecutorLink from './executor-link';
import UseCase from '../use-cases/base';

@Injectable()
export default class Runner {
  chain = this.executorLink;

  // eslint-disable-next-line no-useless-constructor
  constructor(
    @Inject(TYPES.EXECUTOR_LINK) private readonly executorLink: ExecutorLink,
  ) {}

  // eslint-disable-next-line class-methods-use-this
  run(useCase: UseCase<unknown, unknown>, param: unknown): unknown {
    const context = { useCase, result: undefined, param };
    this.chain.next(context);

    return context.result;
  }
}
