import TYPES from '../../types';

// Domain
import { StateManager } from '../../domain/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class ResetIdentityVerificationStatus {
  @Inject(TYPES.ON_BOARDING_IDENTITY_VERIFICATION_STATE_MANAGER)
  private readonly on_boarding_identity_verification_state_manager!: StateManager;

  execute = () => {
    this.on_boarding_identity_verification_state_manager.patch({
      status: {
        step: 0,
        description: '',
        percent: 0,
        user_id: '',
      },
    });
  }
}
