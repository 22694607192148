import deposit_recurring from './recurring_deposit.json';
import deposit_recurring_confirmation from './recurring_deposit_confirmation.json';
import deposit_recurring_result from './recurring_deposit_result.json';
import flexible_deposit from './flexible_deposit.json';
import deposit_flexible_confirmation from './flexible_deposit_confirmation.json';
import deposit_flexible_result from './flexible_deposit_result.json';
import special_deposit_information from './special-deposit-information.json';
import attach_your_proof from './attach-your-proof.json';
import suspend_contribution from './suspend_contribution.json';
import suspend_contribution_result from './suspend_contribution_result.json';
import recurring_deposit_goals_distribution from './recurring_deposit_goals_distribution.json';
import flexible_deposit_goals_distribution from './flexible_deposit_goals_distribution.json';
import flexible_deposit_modal from './flexible_deposit_modal.json';
import recurring_deposit_modal from './recurring_deposit_modal.json';
import special_deposit_success from './special-deposit-sucess.json';

export default {
  deposits: {
    contributions: 'Aportaciones.',
    policy_number: 'Póliza Allianz No',
    policy_administration_1: 'Administra tus aportaciones recurrentes, o realiza aportaciones flexibles',
    policy_administration_2: 'en el momento que quieras',
    types_of_contributions: 'Tipos de Aportaciones',
    recurring: 'Recurrentes',
    flexible: 'Flexibles',
    recurring_detail: 'Administra el monto y la fecha de tus aportaciones recurrentes.',
    flexible_detail: 'Realiza aportaciones libres  a través de tu Cuenta CLABE registrada.',
    continue: 'Continuar',
    cancel: 'Cancelar',
    error_on_create_transaction: 'Ocurrió un error al realizar la operación de depósito.',
    ...deposit_recurring,
    ...deposit_recurring_confirmation,
    ...deposit_recurring_result,
    ...flexible_deposit,
    ...deposit_flexible_confirmation,
    ...deposit_flexible_result,
    ...special_deposit_information,
    ...attach_your_proof,
    ...suspend_contribution,
    ...suspend_contribution_result,
    ...flexible_deposit_modal,
    ...recurring_deposit_modal,
    ...special_deposit_success,
    'recurring-deposit-goals-distribution': recurring_deposit_goals_distribution,
    'flexible-deposit-goals-distribution': flexible_deposit_goals_distribution,
  },
};
