import TYPES from '../../types';

// Domain
import { StateManager } from '../../domain/state/state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CloseSessionWhenTokenExpiresJob {
  @Inject(TYPES.AUTHENTICATION_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  private interval?: NodeJS.Timer;

  private interval_keep_session?: NodeJS.Timer;

  private readonly seconds_to_show_keep_session = 60;

  private readonly seconds_to_allow_keep_session = 5000;

  perform_later = () => {
    if (!this.interval) {
      const expiration_datetime = sessionStorage.getItem('session_til');
      const authentication_token = sessionStorage.getItem('authorization');
      if (expiration_datetime && authentication_token) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const expiration_parsed_datetime = new Date(expiration_datetime!);
        // eslint-disable-next-line
        // @ts-ignore
        const minutes_til_expiration = expiration_parsed_datetime - new Date();

        const milliseconds_til_keep_session = (
          minutes_til_expiration - this.seconds_to_show_keep_session * 1000
        );

        if (minutes_til_expiration >= 0) {
          if (minutes_til_expiration >= this.seconds_to_allow_keep_session) {
            this.interval_keep_session = setTimeout(() => {
              this.keepSession(true);
            }, milliseconds_til_keep_session);
          } else {
            this.endSession();
          }
        } else {
          this.endSession();
        }
      } else if (authentication_token) {
        this.endSession();
      }
    }
  }

  endSession() {
    this.state_manager.patch({ is_auth: false });
    this.keepSession(false);
    sessionStorage.clear();
  }

  keepSession(keep: boolean) {
    this.state_manager.patch({ keep_session: keep });
  }
}
