import TYPES from '../../types';

// Domain
import AccessMoreStrategiesState from '@/modules/my-investment/allianz/access-more-strategies/domain/state/access-more-strategies-state';
import { AccessMoreStrategiesStateManager } from '@/modules/my-investment/allianz/access-more-strategies/domain/state/access-more-strategies-state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class AccessMoreStrategiesStateManagerService extends AccessMoreStrategiesState {
  @Inject(TYPES.ACCESS_MORE_STRATEGIES_STATE_MANAGER)
  private readonly state_manager!: AccessMoreStrategiesStateManager;

  getAccessMoreStrategiesState(): AccessMoreStrategiesState {
    return this.state_manager.state;
  }
}
