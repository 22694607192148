import TYPES from '../../types';

// Domain
import { CustomerDocumentWealthDto } from '../../domain/dtos/customer-document-wealth-dto';
import {
  CustomerDocumentWealthRepository,
} from '../../domain/repositories/customer-document-wealth-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UpdateCustomerDocumentWealthBankStatementCommand
  extends Command<CustomerDocumentWealthDto> {
  @Inject(TYPES.CUSTOMER_DOCUMENT_WEALTH_REPOSITORY)
  private readonly repository!: CustomerDocumentWealthRepository;

  internalExecute(customer_document_dto: CustomerDocumentWealthDto): Promise<void> {
    return this.repository.update_bank_statement(customer_document_dto);
  }
}
