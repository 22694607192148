import TYPES from '../../../../../types';

// application
import SaveOnBoardingSectionBaseService from './save-on-boarding-section-base-service';
import CreateOnBoardingCustomerAgreementCommand
  from '../../../../../customer-agreements/application/commands/create-on-boarding-customer-agreement-command';
import { CreateCustomerCellphoneCommand } from '@/modules/on-boarding/customer-cellphone/application/commands/create-customer-cellphone-command';
import UpdateOnBoardingPersonCommand
  from '../../../../../person/application/command/update-on-boarding-person-command';
import UpdatePreferredMeansOfContactCommand
  from '@/modules/on-boarding/preferred-means-of-contact/application/commands/update-preferred-means-of-contact-command';

// Domain
import { SaveSectionResponseDto } from '../../../../domain/dtos/save-section-response-dto';
import { PromiseDto } from '../../../../domain/dtos/promise-dto';
import { StoredPromiseDto } from '../../../../domain/dtos/stored-promise-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
class SaveOnBoardingConfirmIdentityDataSectionService extends SaveOnBoardingSectionBaseService {
  @Inject(TYPES.CREATE_ON_BOARDING_CUSTOMER_AGREEMENT_COMMAND)
  // eslint-disable-next-line max-len
  private readonly createOnBoardingCustomerAgreementCommand!: CreateOnBoardingCustomerAgreementCommand;

  @Inject(TYPES.CREATE_CUSTOMER_CELLPHONE_COMMAND)
  private readonly createCustomerCellphoneCommand!: CreateCustomerCellphoneCommand;

  @Inject(TYPES.UPDATE_ON_BOARDING_PERSON_COMMAND)
  private readonly updateOnBoardingPersonCommand!: UpdateOnBoardingPersonCommand;

  @Inject(TYPES.PREFERRED_MEANS_OF_CONTACT_COMMAND)
  readonly update_preferred_means_of_contact!: UpdatePreferredMeansOfContactCommand;

  get promises(): Array<PromiseDto> {
    return [
      {
        id: 'on_boarding_create_customer_agreement_command',
        command: this.createOnBoardingCustomerAgreementCommand,
        data: this.state_manager.state.items.find((item) => item.current_step === 'confirm_identity_data')!.payload.customer_agreements[0],
      },
      {
        id: 'on_boarding_create_customer_phone_command',
        command: this.createCustomerCellphoneCommand,
        data: this.state_manager.state.items.find((item) => item.current_step === 'confirm_identity_data')!.payload.customer_cellphone,
      },
      {
        id: 'update_on_boarding_person_command',
        command: this.updateOnBoardingPersonCommand,
        data: this.state_manager.state.items.find((item) => item.current_step === 'confirm_identity_data')!.payload.person,
      },
      {
        id: 'update_on_boarding_referred_means_of_contact_command',
        command: this.update_preferred_means_of_contact,
        data: this.state_manager.state.items.find((item) => item.current_step === 'confirm_identity_data')!.payload.preferred_means_of_contact,
      },
    ];
  }

  execute(): SaveSectionResponseDto {
    const promises = this.filteredPromises(this.promises, 'confirm_identity_data').map(
      (item) => item.command.execute(item.data),
    );

    return new Promise((resolve) => {
      Promise.allSettled(promises)
        .then((results) => {
          const rejected_promises: Array<StoredPromiseDto> = [];

          results.forEach((item, index) => {
            if (item.status === 'rejected') {
              rejected_promises.push({
                id: this.promises[index].id,
                error: item.reason.message.toLowerCase().split(' ').join('_'),
              });
            }
          });

          resolve({ confirm_identity_data: rejected_promises });
        });
    });
  }
}

export default SaveOnBoardingConfirmIdentityDataSectionService;
