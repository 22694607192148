// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { ISOCountryRepository } from '../../../domain/repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class ISOCountryDummyRepository implements ISOCountryRepository {
  // eslint-disable-next-line class-methods-use-this
  findAll() {
    return Promise.resolve(fixtures);
  }
}
