import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import { JobTypeHttpRepository, JobTypeDummyRepository } from './infrastructure/repository';

// Application
import { SearchJobTypesQuery } from './application/queries';

// Domain
import { JobTypeRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupJobType(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.CATALOG_KUSPIT_JOB_TYPE_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<JobTypeRepository>(TYPES.CATALOG_KUSPIT_JOB_TYPE_REPOSITORY)
    .to(
      withDummies
        ? JobTypeDummyRepository
        : JobTypeHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchJobTypesQuery>(TYPES.SEARCH_CATALOG_KUSPIT_JOB_TYPE_QUERY)
    .to(SearchJobTypesQuery)
    .inSingletonScope();
}
