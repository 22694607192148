








































import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';

@Component({
  name: 'UnauthenticatedHeader',
  computed: {
    ...mapState('layout', ['header']),
  },
})
export default class Header extends Vue {}

