import format from 'date-fns/format';
import Injectable from '../../domain/di/injectable';
import { DatetimeValue } from '../../domain/value-objects/datetime-value';

@Injectable()
export default class DatetimeDateFnsValue implements DatetimeValue {
  private value: Date;

  constructor() {
    this.value = new Date();
  }

  // eslint-disable-next-line class-methods-use-this
  create() {
    return new DatetimeDateFnsValue();
  }

  get utcDate() {
    return this.value.toISOString();
  }

  toString(): string {
    return this.utcDate.replace('Z', '+00:00');
  }

  toJSON(): string {
    return format(this.utcDate, 'YYYY-MM-DDThh:mm:ss+00:00');
  }
}
