import { Container } from 'inversify-props';
import setupCatalogsModule from '@/modules/flagship/catalogs/setup';
import setupCustomGoalCalculations from '@/modules/flagship/custom-goal-calculations/setup';
import setupCustomInvestorGoal from '@/modules/flagship/custom-investor-goal/setup';
import setupEmergencyFundCalculations from '@/modules/flagship/emergency-fund-calculations/setup';
import setupEmergencyFundInvestorGoal from '@/modules/flagship/emergency-fund-investor-goal/setup';
import setupRetirementInvestorGoal from '@/modules/flagship/retirement-investor-goal/setup';
import setupHabitsAvailableBudgetCalculation from '@/modules/flagship/habits-available-budget-calculation/setup';
import setupHabitsRecommendedBudgetCalculation from '@/modules/flagship/habits-recommended-budget-calculation/setup';
import setupRetirementForecastCalculation from '@/modules/flagship/retirement-forecast-calculation/setup';
import setupInvestorProfileModule from '@/modules/flagship/investor-profile/setup';
import setupInvestmentProduct from './investment-product/setup';
import setupRetirementGoalCalculations from '@/modules/flagship/retirement-goal-calculations/setup';
import setupCustomerContractedInvestmentProducts from '@/modules/flagship/customer-contracted-investment-products/setup';
import setupOverallInvestmentAmountCalculation from '@/modules/flagship/overall-investment-amount-calculation/setup';
import setupPendingInvestorGoalMovements from '@/modules/flagship/pending-investor-goal-movements/setup';
import setupInvestorGoalModule from '@/modules/flagship/investor-goal/setup';
import setupInvestorGoalScheduledDistributionModule from '@/modules/flagship/investor-goal-scheduled-distribution/setup';
import setupCustomerInvestorGoalReminder from '@/modules/flagship/customer-investor-goal-reminder/setup';
import setupCustomerInvestorGoalBalance from '@/modules/flagship/customer-investor-goal-balance/setup';
import setupGoalDefinitionStep from '@/modules/flagship/goal-definition-step/setup';
import setupCustomerInvestorProfiling from '@/modules/flagship/customer-investor-profiling/setup';
import setupCustomerInvestmentProductFundType
  from '@/modules/flagship/customer-investment-product-fund-type/setup';
import setupContactPoints
  from '@/modules/flagship/contact-points/setup';
import setupStrategySowosModerate from '@/modules/flagship/strategy-moderate/setup';
import setupEditPlanGoals from '@/modules/flagship/edit-plan-goals/setup';
import setupEmergencyFundInvestorGoalCalculator from '@/modules/flagship/emergency-fund-investor-goal-calculator/setup';
import setupRetirementFundInvestorGoalCalculator from '@/modules/flagship/retirement-investor-goal-calculator/setup';
import setupCustomInvestorGoalCalculator from '@/modules/flagship/custom-investor-goal-calculator/setup';
import setupRiskToleranceQuestions from '@/modules/flagship/risk-tolerance-questions/setup';
import setupInvestorGoalRiskTolerance from '@/modules/flagship/investor-goal-risk-tolerance/setup';

export default function setupFlagshipModule(container: Container) {
  setupCatalogsModule(container);
  setupCustomGoalCalculations(container);
  setupCustomInvestorGoal(container);
  setupEmergencyFundCalculations(container);
  setupEmergencyFundInvestorGoal(container);
  setupRetirementInvestorGoal(container);
  setupHabitsAvailableBudgetCalculation(container);
  setupHabitsRecommendedBudgetCalculation(container);
  setupRetirementForecastCalculation(container);
  setupInvestorProfileModule(container);
  setupInvestmentProduct(container);
  setupRetirementGoalCalculations(container);
  setupCustomerContractedInvestmentProducts(container);
  setupOverallInvestmentAmountCalculation(container);
  setupPendingInvestorGoalMovements(container);
  setupInvestorGoalModule(container);
  setupInvestorGoalScheduledDistributionModule(container);
  setupCustomerInvestorGoalReminder(container);
  setupCustomerInvestorGoalBalance(container);
  setupGoalDefinitionStep(container);
  setupCustomerInvestorProfiling(container);
  setupCustomerInvestmentProductFundType(container);
  setupContactPoints(container);
  setupStrategySowosModerate(container);
  setupEditPlanGoals(container);
  setupEmergencyFundInvestorGoalCalculator(container);
  setupRetirementFundInvestorGoalCalculator(container);
  setupCustomInvestorGoalCalculator(container);
  setupRiskToleranceQuestions(container);
  setupInvestorGoalRiskTolerance(container);
}
