import fixtures from './fixtures.json';

// Domain
import { CustomGoalCalculationEntity } from '@/modules/flagship/custom-goal-calculations/domain/entities/custom-goal-calculation-entity';
import { CustomGoalFixedTimeCalculationRepository } from '@/modules/flagship/custom-goal-calculations/domain/repositories/custom-goal-fixed-time-calculation-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
class CustomGoalFixedTimeCalculationDummyRepository
implements CustomGoalFixedTimeCalculationRepository {
  calculate(): Promise<CustomGoalCalculationEntity> {
    return Promise.resolve(fixtures);
  }
}

export default CustomGoalFixedTimeCalculationDummyRepository;
