









































import { Component, Vue } from 'vue-property-decorator';
import DocumentSuccessfullySentViewModel
  from '@/vue-app/view-models/views/document-validation/document-successfully-sent-view-model';

@Component({ name: 'DocumentSuccessfullySent' })
export default class DocumentSuccessfullySent extends Vue {
  document_successfully_view_model = Vue.observable(new DocumentSuccessfullySentViewModel());
}

