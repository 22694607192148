import Vue from 'vue';

// Domain
import { StateManager } from '../../domain/state/state-manager';
import State from '../../domain/state/state';
import Injectable from '@/modules/shared/domain/di/injectable';
import Observer from '@/modules/shared/domain/observer/observer';

@Injectable()
export default class AllianzAccountStatementVueStateManager implements StateManager {
  private _state: State = Vue.observable<State>(new State());

  private readonly observers: Observer[] = [];

  get state(): State {
    return this._state;
  }

  set state(value: State) {
    this._state = value;
    this.notifyAll();
  }

  patch(state: Partial<State>) {
    type Keys = keyof State;
    Object.entries(state).forEach(([key, value]) => {
      const accessor = key as Keys;

      if (value !== undefined) {
        // eslint-disable-next-line
        // @ts-ignore @typescript-eslint/ban-ts-ignore
        this.state[accessor] = value;
      }
    });
  }

  notifyAll() {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer) {
    this.observers.push(observer);
  }
}
