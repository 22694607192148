import TYPES from '@/modules/shared/types';

// Domain
import { CreateTransactionRepository } from '../../domain/repository';
import { CreateTransactionDto } from '../../domain/dtos/create-transaction-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class CreateTransactionHttpRepository implements CreateTransactionRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  create(transaction: CreateTransactionDto): Promise<void> {
    const endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/allianz/transactions/`;

    return new Promise((resolve, reject) => {
      this.http_connector.execute(
        endpoint,
        'POST',
        transaction,
      )
        .then((response) => {
          response.json()
            .then(({ data, success, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
