import TYPES from '../../types';

// Domain
import { InitialAmountEntity } from '../../domain/entities/initial-amount-entity';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class EditCustomGoalPlanInitialAmountService {
  @Inject(TYPES.EDIT_CUSTOM_GOAL_PLAN_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  setInitialAmountInformation(initial_amount: InitialAmountEntity): void {
    this.state_manager.state.initial_amount = { ...initial_amount };
  }

  getInitialAmountInformation(): InitialAmountEntity {
    return this.state_manager.state.initial_amount;
  }
}
