import SHARED_TYPES from '@/modules/shared/types';

// Domain
import { PersonRepository } from '../../../domain/repositories';
import { Person } from '../../../domain/entities';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class PersonRestRepository implements PersonRepository {
  @Inject(SHARED_TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  get(): Promise<Person> {
    const endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/person/`;

    return new Promise((resolve, reject) => {
      this.http_connector.execute(endpoint)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              }

              reject(new Error(message));
            });
        })
        .catch(reject);
    });
  }
}
