import Vue from 'vue';

// Domain
import { AvailableToHireStrategyModerateStateManager } from '@/modules/flagship/strategy-moderate/hire/domain/state/available-to-hire-strategy-moderate-state-manager';
import { AvailableToHireStrategyModerateState } from '@/modules/flagship/strategy-moderate/hire/domain/state/available-to-hire-strategy-moderate-state';
import Injectable from '@/modules/shared/domain/di/injectable';
import Observer from '@/modules/shared/domain/observer/observer';

@Injectable()
export default class AvailableToHireStrategyModerateVueStateManager
implements AvailableToHireStrategyModerateStateManager {
  private _state: AvailableToHireStrategyModerateState = Vue.observable<
    AvailableToHireStrategyModerateState
  >(
    new AvailableToHireStrategyModerateState(),
  );

  private readonly observers: Observer[] = [];

  get state(): AvailableToHireStrategyModerateState {
    return this._state;
  }

  set state(value: AvailableToHireStrategyModerateState) {
    this._state = value;
  }

  patch(state: Partial<AvailableToHireStrategyModerateState>) {
    type Keys = keyof AvailableToHireStrategyModerateState;
    Object.entries(state).forEach(([key, value]) => {
      const accessor = key as Keys;

      if (value !== undefined) {
        // eslint-disable-next-line
        // @ts-ignore @typescript-eslint/ban-ts-ignore
        this.state[accessor] = value;
      }
    });
  }

  notifyAll(): void {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer): void {
    this.observers.push(observer);
  }
}
