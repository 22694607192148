import format from 'date-fns/format';
import es from 'date-fns/locale/es';

// Domain
import Injectable from '../../domain/di/injectable';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';

@Injectable()
export default class DateFnsFormatter implements DateFormatter {
  formatDate = (
    date: Date | string, date_format: string,
  ) => format(date, date_format, { locale: es })
}
