// Domain
import {
  CustomerInvestmentProductFundTypeEntity,
} from '@/modules/flagship/customer-investment-product-fund-type/domain/entities/customer-investment-product-fund-type-entity';

export default class CustomerInvestmentProductFundTypeState {
  loaded = false;

  items: Array<CustomerInvestmentProductFundTypeEntity> = [];
}
