// Domain
import { CancelAccountRepository } from '../../domain/repositories';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CancelAccountFakeRepository implements CancelAccountRepository {
  cancel(): Promise<void> {
    return Promise.resolve();
  }
}
