import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import SaveOnBoardingSignUpSectionService from './sections/save-on-boarding-sign-up-section-service';
import SaveOnBoardingConfirmIdentityDataSectionService
  from './sections/save-on-boarding-confirm-identity-data-section-service';
import UpdateOnBoardingStepCommand from '../../commands/update-on-boarding-step-command';
import { StoredPromiseDto } from '../../../domain/dtos/stored-promise-dto';
import TYPES from '@/modules/on-boarding/types';
import StateManager from '../../../domain/state/state-manager';

const saveOnBoardingSignUpSectionService = new SaveOnBoardingSignUpSectionService();
// eslint-disable-next-line max-len
const saveOnBoardingConfirmIdentityDataSectionService = new SaveOnBoardingConfirmIdentityDataSectionService();

@Injectable()
export default class SaveOnBoardingStepsDataService {
  @Inject(TYPES.ON_BOARDING_STEPS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.UPDATE_ON_BOARDING_STEP_COMMAND)
  private readonly updateOnBoardingStepCommand!: UpdateOnBoardingStepCommand;

  execute(): Promise<void> {
    return new Promise((resolve, reject) => {
      Promise.all([
        saveOnBoardingSignUpSectionService.execute(),
        saveOnBoardingConfirmIdentityDataSectionService.execute(),
      ])
        .then((errors) => {
          const merged_errors: Record<string, Array<StoredPromiseDto>> = {};
          errors.forEach((item) => {
            if (item) {
              merged_errors[Object.keys(item)[0]] = item[Object.keys(item)[0]];
            }
          });

          const error = Object.values(merged_errors).some((item) => item.length);

          sessionStorage.setItem('on-boarding-errors', JSON.stringify(merged_errors));
          const last_step = this.state_manager.state.items.find((item) => item.current_step === 'confirm_identity_data')!;
          this.updateOnBoardingStepCommand.execute({
            ...last_step,
            payload: {
              ...last_step.payload,
              errors: merged_errors,
              status: error ? last_step.payload.status : 'confirmed',
            },
          });

          if (error) {
            reject(new Error('There was an error saving your data'));
          } else {
            resolve();
          }
        })
        .catch(reject);
    });
  }
}
