// Domain
import {
  DistributeBalanceRepository,
} from '../../../domain/repositories/distribute-balance-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class DistributeBalanceDummyRepository
implements DistributeBalanceRepository {
  distribute(): Promise<void> {
    return Promise.resolve();
  }
}
