import accountLinkingProcess from './account-linking-process.json';
import accountLinkingInit from './account-linking-init.json';
import accountLinkingData from './account-linking-data.json';
import accountLinkingSuccess from './account-linking-success.json';
import accountLinkingError from './account-linking-error.json';
import accountLinkingForgetPassword from './account-linking-forget-password.json';
import accountLinkingWrongCredentials from './account-linking-wrong-credentials.json';
import accountLinkingAccountWithoutContract from './account-linking-account-without-contract.json';

export default {
  'kuspit-account-linking': {
    ...accountLinkingInit,
    ...accountLinkingData,
    ...accountLinkingProcess,
    ...accountLinkingSuccess,
    ...accountLinkingError,
    ...accountLinkingForgetPassword,
    ...accountLinkingWrongCredentials,
    ...accountLinkingAccountWithoutContract,
  },
};
