import fixtures from './fixtures.json';

// Domain
import { AssociatedProductRepository } from '../../../domain/repositories/associated-product-repository';
import { AssociatedProductEntity } from '../../../domain/entities/associated-product-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class AssociatedProductDummyRepository implements AssociatedProductRepository {
  get(): Promise<Array<AssociatedProductEntity>> {
    return Promise.resolve(fixtures);
  }
}
