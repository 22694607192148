import TYPES from '../../types';

// Domain
import { InvestorGoalsDto } from '../../domain/dtos/investor-goals-dto';
import { LinkSowosWealthRepository } from '../../domain/repositories/link-sowos-wealth-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CreateLinkSowosWealthCommand extends Command<InvestorGoalsDto> {
  @Inject(TYPES.LINK_SOWOS_WEALTH_REPOSITORY)
  private readonly repository!: LinkSowosWealthRepository;

  internalExecute(investor_goals_dto: InvestorGoalsDto): Promise<void> {
    return this.repository.create(investor_goals_dto);
  }
}
