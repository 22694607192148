import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';

// Application
import { GetCustomerWithdrawalDetailsQuery } from '@/modules/my-investment/kuspit/withdrawal/application/queries';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';

export default class WithdrawalConfirmInformationViewModel {
  @Inject(TYPES.GET_CUSTOMER_WITHDRAWAL_DETAILS_QUERY)
  private readonly get_customer_withdrawal_details_query!: GetCustomerWithdrawalDetailsQuery;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  private readonly i18n_namespace = 'components.kuspit-dashboard.withdrawals.confirm_information';

  readonly current_date = this.date_formatter.formatDate(new Date(), 'DD MMM YYYY');

  translate = (value: string) => (
    i18n.t(`${this.i18n_namespace}.${value}`) as string
  )
}
