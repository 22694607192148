import Vue from 'vue';

// Domain
import { PersonalInfoStateManager } from '@/modules/flagship/investor-profile/personal-info/domain/state/personal-info-state-manager';
import PersonalInfoState from '../../domain/state/personal-info-state';
import Injectable from '@/modules/shared/domain/di/injectable';
import Observer from '@/modules/shared/domain/observer/observer';

@Injectable()
export default class PersonalInfoVueStateManager implements PersonalInfoStateManager {
  private _state: PersonalInfoState = Vue.observable<PersonalInfoState>(new PersonalInfoState());

  private readonly observers: Observer[] = [];

  get state(): PersonalInfoState {
    return this._state;
  }

  set state(value: PersonalInfoState) {
    this._state = value;
    this.notifyAll();
  }

  patch(state: Partial<PersonalInfoState>) {
    type Keys = keyof PersonalInfoState;
    Object.entries(state).forEach(([key, value]) => {
      const accessor = key as Keys;

      if (value !== undefined) {
        // eslint-disable-next-line
        // @ts-ignore @typescript-eslint/ban-ts-ignore
        this.state[accessor] = value;
      }
    });
  }

  notifyAll() {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer) {
    this.observers.push(observer);
  }
}
