import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import { CustomerPersonalInformationVueStateManager } from './infrastructure/state';
import {
  CustomerPersonalInformationDummyRepository,
  CustomerPersonalInformationRestRepository,
} from './infrastructure/repositories/customer-personal-information-repository';

// Application
import { GetCustomerPersonalInformationQuery } from './application/queries';

// Domain
import { CustomerPersonalInformationStateManager } from './domain/state';
import { CustomerPersonalInformationRepository } from './domain/repositories';

export default function setupCustomerPersonalInformationModule(container: Container) {
  const with_dummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<CustomerPersonalInformationStateManager>(
      TYPES.CUSTOMER_PERSONAL_INFORMATION_STATE_MANAGER,
    )
    .to(CustomerPersonalInformationVueStateManager)
    .inSingletonScope();

  container
    .bind<CustomerPersonalInformationRepository>(TYPES.CUSTOMER_PERSONAL_INFORMATION_REPOSITORY)
    .to(
      with_dummies
        ? CustomerPersonalInformationDummyRepository
        : CustomerPersonalInformationRestRepository,
    ).inSingletonScope();

  container
    .bind<GetCustomerPersonalInformationQuery>(TYPES.GET_CUSTOMER_PERSONAL_INFORMATION_QUERY)
    .to(GetCustomerPersonalInformationQuery)
    .inSingletonScope();
}
