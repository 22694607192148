import TYPES from '../../types';

// Domain
import { CustomerNotification } from '../../domain/entities';
import { CustomerNotificationRepository } from '../../domain/repositories';
import Query from '@/modules/shared/domain/use-cases/query';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class GetCustomerNotificationsQuery
  extends Query<Promise<Array<CustomerNotification>>> {
  @Inject(TYPES.CUSTOMER_NOTIFICATION_REPOSITORY)
  private readonly customer_notification_repository!: CustomerNotificationRepository;

  internalExecute(): Promise<Array<CustomerNotification>> {
    return new Promise((resolve, reject) => {
      this.customer_notification_repository.get_notifications()
        .then((customer_notifications) => {
          resolve(customer_notifications);
        })
        .catch(reject);
    });
  }
}
