
























































































import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import OnboardingHeaderViewModel from '@/vue-app/view-models/components/header/onboarding-header-view-model';

@Component({
  name: 'OnBoardingHeader',
  computed: {
    ...mapState('layout', ['header']),
  },
})
export default class OnBoardingHeader extends Vue {
  onboarding_header_view_model = Vue.observable(new OnboardingHeaderViewModel());
}

