// Domain
import { GoalTrackingEntity } from '../entities/goal-tracking-entity';

export default class GoalTrackingState {
  loaded = false;

  item: GoalTrackingEntity = {
    current_goal_balance: 0,
    goal_tracking_percent: 0.0,
    goal_target_balance: 0,
    expected_goal_balance: 0,
  };
}
