import TYPES from '@/modules/shared/types';

// Domain
import { OverallInvestmentAmountRepository } from '@/modules/flagship/overall-investment-amount-calculation/domain/repositories/overall-investment-amount-repository';
import { OverallInvestmentAmountDto } from '@/modules/flagship/overall-investment-amount-calculation/domain/dto/overall-investment-amount-dto';
import { OverallInvestmentAmountEntity } from '@/modules/flagship/overall-investment-amount-calculation/domain/entities/overall-investment-amount-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
class OverallInvestmentAmountHttpRepository implements OverallInvestmentAmountRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/overall-investment-amount-calculation/`;

  calculate(payload: OverallInvestmentAmountDto): Promise<OverallInvestmentAmountEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector
        .execute(this.endpoint, 'POST', payload)
        .then((response) => {
          response.json().then(({ success, data, message }) => {
            if (success) {
              resolve(data);
            } else {
              reject(message);
            }
          });
        })
        .catch(reject);
    });
  }
}

export default OverallInvestmentAmountHttpRepository;
