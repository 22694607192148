import TYPES from '@/modules/shared/types';

// Domain
import {
  ProductInformationRepository,
} from '@/modules/flagship/investment-product/product-information/domain/repositories/product-information-repository';
import {
  ProductInformationDto,
} from '@/modules/flagship/investment-product/product-information/domain/dtos/product-information-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class CreateProductInformationHttpRepository
implements ProductInformationRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/investment-product/{investment_product_id}/product-information/`;

  create(product_info_dto: ProductInformationDto): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint.replace('{investment_product_id}', product_info_dto.investment_product_id), 'POST')
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
