import TYPES from '@/types';

// Domain
import { PatchAllianzPolicyIssuanceStatusRepository }
  from '../../../domain/repository';
import { PatchAllianzPolicyIssuanceStatusEntity }
  from '../../../domain/entities/patch-allianz-policy-issuance-status-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class PatchAllianzPolicyIssuanceStatusHttpRepository
implements PatchAllianzPolicyIssuanceStatusRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly path = `${process.env.VUE_APP_API_URL}/api/v1/app/allianz/customer-allianz-policy-issuance-status/{ID}/`;

  patch(allianz_policy_issuance_status: PatchAllianzPolicyIssuanceStatusEntity): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.path.replace('{ID}', allianz_policy_issuance_status.id), 'PATCH', {
        first_interaction_with_dashboard:
        allianz_policy_issuance_status.first_interaction_with_dashboard,
      })
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json()
              .then(({ message }) => {
                reject(new Error(message));
              });
          }
        })
        .catch(reject);
    });
  }
}
