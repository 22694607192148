import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';
import { UserCredentialsType, AuthenticationDataType } from '@/modules/authentication/domain/types';
import TYPES from '@/modules/shared/types';
import { AuthenticationDataRepository } from '../../../domain/repositories';

@Injectable()
export default class AuthenticationDataHttpRepository implements AuthenticationDataRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly path = `${process.env.VUE_APP_API_URL}/api/v2/app/auth/login/`;

  private readonly path_logout = `${process.env.VUE_APP_API_URL}/api/v1/app/auth/logout/`;

  create(user_credentials: UserCredentialsType): Promise<AuthenticationDataType> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.path, 'POST', user_credentials, 0, true)
        .then((response) => {
          response.json().then(({
            data,
            message,
            success,
          }) => {
            if (success) {
              resolve(data);
            } else {
              reject(new Error(message));
            }
          });
        })
        .catch(reject);
    });
  }

  signOut(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.path_logout, 'POST', {})
        .then((response) => {
          response.json().then(({
            data,
            message,
            success,
          }) => {
            if (success) {
              resolve(data);
            } else {
              reject(new Error(message));
            }
          });
        })
        .catch(reject);
    });
  }
}
