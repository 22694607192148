import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import MagicLinkDummyRepository
  from './infrastructure/repositories/magic-link-dummy-repository/magic-link-dummy-repository';
import MagicLinkHttpRepository
  from './infrastructure/repositories/magic-link-http-repository';

// Application
import VerifyTokenMagicLinkQuery from './application/queries/verify-token-magic-link-query';

// Domain
import { MagicLinkRepository } from './domain/repositories/magic-link-repository';

export default function setupMagicLink(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<MagicLinkRepository>(TYPES.MAGIC_LINK_REPOSITORY)
    .to(
      withDummies
        ? MagicLinkDummyRepository : MagicLinkHttpRepository,
    ).inSingletonScope();

  container
    .bind<VerifyTokenMagicLinkQuery>(TYPES.VERIFY_TOKEN_MAGIC_LINK_QUERY)
    .to(VerifyTokenMagicLinkQuery)
    .inSingletonScope();
}
