// Domain
import { CurrentPlanEntity } from '../entities/current-plan-entity';
import { DefineByEntity } from '../entities/define-by-entity';
import { InitialAmountEntity } from '../entities/initial-amount-entity';

export default class State {
  current_plan: CurrentPlanEntity = {
    target_amount: '',
    current_amount: '',
    monthly_required_amount: '',
    final_investment_date: '',
    custom_goal_id: '',
    associated_product_id: '',
    associated_product_name: '',
  }

  initial_amount: InitialAmountEntity = {
    additional_amount: 0,
    there_is_more_savings: '',
  }

  define_by: DefineByEntity = {
    new_target_amount: 0,
    is_defined_by_contribution: false,
    by_time: {
      wants_years: false,
      months_to_reach_goal: 0,
      you_must_save: 0,
      accumulated_amount: 0,
    },
    by_contribution: {
      contribution: 0,
      months_to_reach_goal: 0,
      accumulated_amount: 0,
    },
  }
}
