import TYPES from '../../types';

// Domain
import { MunicipalityEntity } from '../../domain/entities/municipality-entity';
import { MunicipalityRepository } from '../../domain/repository/municipality-repository';
import { SearchDto } from '../../domain/dtos/search-dto';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class SearchMunicipalitiesQuery extends Query<Promise<Array<MunicipalityEntity>>> {
  @Inject(TYPES.CATALOG_ALLIANZ_MUNICIPALITY_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.CATALOG_ALLIANZ_MUNICIPALITY_REPOSITORY)
  private readonly repository!: MunicipalityRepository;

  internalExecute(search: SearchDto): Promise<Array<MunicipalityEntity>> {
    return new Promise((resolve, reject) => {
      if (
        this.state_manager.state.current_search.codpais !== search.codpais
        || this.state_manager.state.current_search.codestado !== search.codestado
      ) {
        this.repository.execute(search)
          .then((items) => {
            this.state_manager.patch({ items, current_search: search });
            resolve(items);
          })
          .catch(reject);
      } else {
        resolve(this.state_manager.state.items);
      }
    });
  }
}
