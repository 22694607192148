// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { SettlementRepository } from '../../../domain/repository';
import { SettlementEntity } from '../../../domain/entities/settlement-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class SettlementDummyRepository implements SettlementRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(codigo_postal: string): Promise<Array<SettlementEntity>> {
    return Promise.resolve(fixtures);
  }
}
