import { StatisticsEntity } from '../entities/statistics-entity';

export default class State {
  loaded = false;

  invitations_statistics: StatisticsEntity = {
    sent: 0,
    completed: 0,
  };
}
