
































































import { Component, Vue } from 'vue-property-decorator';
import PositionViewModel from '../../view-models/components/kuspit-dashboard/position-view-model';
import LoadingDataCard from '@/vue-app/components/home-dashboard/LoadingDataCard.vue';

@Component({
  name: 'Position',
  components: { LoadingDataCard },
})
export default class Position extends Vue {
  position_view_model = Vue.observable(new PositionViewModel());

  created() {
    this.position_view_model.initialize();
  }
}
