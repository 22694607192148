import TYPES from '../../types';

// Domain
import { InvestorProfileOnEmergencyFundRepository } from '../../domain/repositories/investor-profile-on-emergency-fund-repository';
import { InvestorProfileEntity } from '../../../investor-profile/domain/entities/investor-profile-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UpdateInvestorProfileOnEmergencyFundCommand
  extends Command<InvestorProfileEntity> {
  @Inject(TYPES.INVESTOR_PROFILE_ON_EMERGENCY_FUND_REPOSITORY)
  private readonly repository!: InvestorProfileOnEmergencyFundRepository;

  internalExecute(investor_profile_entity: InvestorProfileEntity) {
    return this.repository.update(investor_profile_entity);
  }
}
