import TYPES from '../../types';

import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';
import {
  EmergencyFundInvestorGoalEntity,
} from '@/modules/flagship/emergency-fund-investor-goal/domain/entities/emergency-fund-investor-goal-entity';
import {
  EmergencyFundInvestorGoalRepository,
} from '@/modules/flagship/emergency-fund-investor-goal/domain/repositories/emergency-fund-investor-goal-repository';
import {
  EmergencyFundInvestorGoalStateManager,
} from '@/modules/flagship/emergency-fund-investor-goal/domain/state/emergency-fund-investor-goal-state-manager';

@Injectable()
class GetEmergencyFundInvestorGoalQuery extends Query<Promise<EmergencyFundInvestorGoalEntity>> {
  @Inject(TYPES.EMERGENCY_FUND_INVESTOR_GOAL_REPOSITORY)
  private readonly repository!: EmergencyFundInvestorGoalRepository;

  @Inject(TYPES.EMERGENCY_FUND_INVESTOR_GOAL_STATE_MANAGER)
  private readonly state_manager!: EmergencyFundInvestorGoalStateManager;

  internalExecute(): Promise<EmergencyFundInvestorGoalEntity> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded) {
        resolve(this.state_manager.state.item);
      } else {
        this.repository.get()
          .then((item) => {
            this.state_manager.patch({ item, loaded: false });
            resolve(item);
          })
          .catch(reject);
      }
    });
  }
}

export default GetEmergencyFundInvestorGoalQuery;
