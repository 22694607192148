import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import {
  CustomerMovementHttpRepository,
  CustomerMovementDummyRepository,
} from './infrastructure/repository';

// Application
import { SearchCustomerKuspitMovementsQuery } from './application/queries';

// Domain
import { CustomerKuspitMovementRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupActivity(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.CUSTOMER_KUSPIT_MOVEMENT_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<CustomerKuspitMovementRepository>(TYPES.CUSTOMER_KUSPIT_MOVEMENT_REPOSITORY)
    .to(
      withDummies
        ? CustomerMovementDummyRepository
        : CustomerMovementHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchCustomerKuspitMovementsQuery>(TYPES.SEARCH_CUSTOMER_KUSPIT_MOVEMENTS_QUERY)
    .to(SearchCustomerKuspitMovementsQuery)
    .inSingletonScope();
}
