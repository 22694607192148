import TYPES from '../../types';

// Domain
import { AllianzAccountReportEntity } from '../../domain/entities/allianz-account-report-entity';
import { AllianzAccountReportRepository } from '../../domain/repository/allianz-account-report-repository';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class SearchAllianzAccountReportQuery
  extends Query<Promise<Array<AllianzAccountReportEntity>>> {
  @Inject(TYPES.ALLIANZ_ACCOUNT_REPORT_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.ALLIANZ_ACCOUNT_REPORT_REPOSITORY)
  private readonly repository!: AllianzAccountReportRepository;

  internalExecute(customer_id: string): Promise<Array<AllianzAccountReportEntity>> {
    return new Promise((resolve, reject) => {
      if (!this.state_manager.state.loaded) {
        this.state_manager.patch({ is_loading: true });
        this.repository.execute(customer_id)
          .then((items) => {
            this.state_manager.patch({ items, loaded: true });
            resolve(items);
          })
          .catch(reject)
          .finally(() => {
            this.state_manager.patch({ is_loading: false });
          });
      } else {
        resolve(this.state_manager.state.items);
      }
    });
  }
}
