

















































import { Component, PropSync, Vue } from 'vue-property-decorator';

import ModalDistributeDifferentContributionsSuccessPocketViewModel
  from '@/vue-app/view-models/components/flagship/modal-distribute-different-contributions-success-pocket-view-model';

@Component({
  name: 'ModalDistributeDifferentContributionsSuccessPocket',
})
export default class ModalDistributeDifferentContributionsSuccessPocket extends Vue {
  @PropSync('isThereAnyPendingDistribution', { type: Boolean, required: true })
  is_there_any_pending_distribution!: boolean;

  distribute_special_contribution_success_view_model = Vue.observable(
    new ModalDistributeDifferentContributionsSuccessPocketViewModel(),
  );

  mounted() {
    this.distribute_special_contribution_success_view_model
      .initialize(this.is_there_any_pending_distribution);
  }
}
