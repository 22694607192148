import TYPES from '@/modules/shared/types';

// Domain
import { DistributeBalanceRepository } from '../../domain/repositories/distribute-balance-repository';
import { DistributeBalanceDto } from '../../domain/dto/distribute-balance-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class DistributeBalanceHttpRepository implements
  DistributeBalanceRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/investor-goal/distribute-balance/`;

  distribute(distribute_balance_dto: DistributeBalanceDto):
    Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint, 'POST', distribute_balance_dto)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
