import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';
import { CreateOnBoardingCustomerAgreementRepository }
  from '../../domain/repositories/create-on-boarding-customer-agreement-repository';
import { CustomerAgreementEntity } from '../../domain/entities/customer-agreement-entity';

@Injectable()
class CreateOnBoardingCustomerAgreementCommand extends Command<CustomerAgreementEntity> {
  @Inject(TYPES.CREATE_ON_BOARDING_CUSTOMER_AGREEMENT_REPOSITORY)
  private readonly repository!: CreateOnBoardingCustomerAgreementRepository;

  internalExecute(customer_agreement: CustomerAgreementEntity): Promise<unknown> {
    return this.repository.create(customer_agreement);
  }
}

export default CreateOnBoardingCustomerAgreementCommand;
