import TYPES from '../../types';

// Domain
import { RecoveryPassword } from '../../domain/dtos';
import { RecoveryPasswordRepository } from '../../domain/repositories';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class RecoveryPasswordCommand extends Command<RecoveryPassword> {
  @Inject(TYPES.RECOVERY_PASSWORD_REPOSITORY)
  private readonly recovery_password_repository!: RecoveryPasswordRepository;

  internalExecute(payload: RecoveryPassword): Promise<void> {
    return this.recovery_password_repository.restore(payload);
  }
}
