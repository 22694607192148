// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { AllianzAccountMovementReportRepository } from '../../../domain/repository';
import { MovementEntity } from '../../../domain/entities/movement-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class AllianzAccountMovementReportDummyRepository
implements AllianzAccountMovementReportRepository {
  execute(): Promise<Array<MovementEntity>> {
    return Promise.resolve(fixtures);
  }
}
