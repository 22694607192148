import { Container } from 'inversify-props';
import TYPES from './types';
import CreateCustomerAgreementDummyRepository
  from './infrastructure/repositories/create-customer-agreement-dummy-repository';
import CreateCustomerAgreementHttpRepository
  from './infrastructure/repositories/create-customer-agreement-http-repository';
import CreateOnBoardingCustomerAgreementCommand
  from './application/commands/create-on-boarding-customer-agreement-command';
import { CreateOnBoardingCustomerAgreementRepository }
  from './domain/repositories/create-on-boarding-customer-agreement-repository';

export default function setupCustomerAgreements(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    // eslint-disable-next-line max-len
    .bind<CreateOnBoardingCustomerAgreementRepository>(TYPES.CREATE_ON_BOARDING_CUSTOMER_AGREEMENT_REPOSITORY)
    .to(
      withDummies
        ? CreateCustomerAgreementDummyRepository
        : CreateCustomerAgreementHttpRepository,
    ).inSingletonScope();

  container
    // eslint-disable-next-line max-len
    .bind<CreateOnBoardingCustomerAgreementCommand>(TYPES.CREATE_ON_BOARDING_CUSTOMER_AGREEMENT_COMMAND)
    .to(CreateOnBoardingCustomerAgreementCommand)
    .inSingletonScope();
}
