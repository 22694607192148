import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import HabitsRecommendedBudgetCalculationDummyRepository
  from '@/modules/flagship/habits-recommended-budget-calculation/infrastructure/repositories/habits-recommended-budget-calculation-dummy-repository';
import HabitsRecommendedBudgetCalculationHttpRepository
  from '@/modules/flagship/habits-recommended-budget-calculation/infrastructure/repositories/habits-recommended-budget-calculation-http-repository';

// Application
import CreateHabitsRecommendedBudgetCalculationCommand
  from '@/modules/flagship/habits-recommended-budget-calculation/application/commands/create-habits-recommended-budget-calculation-command';

// Domain
import {
  HabitsRecommendedBudgetCalculationRepository,
} from '@/modules/flagship/habits-recommended-budget-calculation/domain/repositories/habits-recommended-budget-calculation-repository';

export default function setupHabitsRecommendedBudgetCalculation(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<HabitsRecommendedBudgetCalculationRepository>(
      TYPES.HABITS_RECOMMENDED_BUDGET_CALCULATION_REPOSITORY,
    ).to(
      withDummies
        ? HabitsRecommendedBudgetCalculationDummyRepository
        : HabitsRecommendedBudgetCalculationHttpRepository,
    ).inSingletonScope();

  container
    .bind<CreateHabitsRecommendedBudgetCalculationCommand>(
      TYPES.CREATE_HABITS_RECOMMENDED_BUDGET_CALCULATION_COMMAND,
    ).to(CreateHabitsRecommendedBudgetCalculationCommand)
    .inSingletonScope();
}
