export default {
  AUTHENTICATION_DATA_REPOSITORY: Symbol.for('AUTHENTICATION_DATA_REPOSITORY'),
  SIGN_IN_COMMAND: Symbol.for('SIGN_IN_COMMAND'),
  AUTHENTICATION_VALIDATE_JWT_COMMAND: Symbol.for('AUTHENTICATION_VALIDATE_JWT_COMMAND'),
  AUTHENTICATION_JWT_REPOSITORY: Symbol.for('AUTHENTICATION_JWT_REPOSITORY'),
  AUTHENTICATION_STATE_MANAGER: Symbol.for('AUTHENTICATION_STATE_MANAGER'),
  GET_AUTHENTICATION_STATE_SERVICE: Symbol.for('GET_AUTHENTICATION_STATE_SERVICE'),
  CLOSE_SESSION_WHEN_TOKEN_EXPIRES_JOB: Symbol.for('CLOSE_SESSION_WHEN_TOKEN_EXPIRES_JOB'),
  FORGOT_PASSWORD_COMMAND: Symbol.for('FORGOT_PASSWORD_COMMAND'),
  FORGOT_PASSWORD_REPOSITORY: Symbol.for('FORGOT_PASSWORD_REPOSITORY'),
  RECOVERY_PASSWORD_COMMAND: Symbol.for('RECOVERY_PASSWORD_COMMAND'),
  RECOVERY_PASSWORD_REPOSITORY: Symbol.for('RECOVERY_PASSWORD_REPOSITORY'),
  TOKEN_REFRESH_REPOSITORY: Symbol.for('TOKEN_REFRESH_REPOSITORY'),
  TOKEN_REFRESH_COMMAND: Symbol.for('RECOVERY_REFRESH_COMMAND'),
  SIGN_OUT_COMMAND: Symbol.for('SIGN_OUT_COMMAND'),
  SIGN_OUT_EMITTER: Symbol.for('SIGN_OUT_EMITTER'),
};
