import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import { SearchAddressHttpRepository, SearchAddressDummyRepository } from './infrastructure/repository';

// Application
import { SearchAddressQuery } from './application/queries';

// Domain
import { SearchAddressRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupActivity(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.MY_INVESTMENT_ADDRESS_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<SearchAddressRepository>(TYPES.MY_INVESTMENT_ADDRESS_REPOSITORY)
    .to(
      withDummies
        ? SearchAddressDummyRepository
        : SearchAddressHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchAddressQuery>(TYPES.SEARCH_MY_INVESTMENT_ADDRESS_QUERY)
    .to(SearchAddressQuery)
    .inSingletonScope();
}
