import TYPES from '@/types';

// Application
import { GetAllianzAccountIsOnCancellationProcessQuery }
  from '@/modules/my-investment/allianz/allianz-account/application/queries';
import GetIsAvailableToHireStrategyModerateQuery
  from '@/modules/flagship/strategy-moderate/hire/application/queries/get-is-available-to-hire-strategy-moderate-query';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class MyInvestmentDashboardViewModelV2 {
  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_IS_ON_CANCELLATION_PROCESS_QUERY)
  // eslint-disable-next-line max-len
  private readonly get_allianz_account_is_on_cancellation_process!: GetAllianzAccountIsOnCancellationProcessQuery;

  @Inject(TYPES.GET_IS_AVAILABLE_TO_HIRE_STRATEGY_MODERATE_QUERY)
  // eslint-disable-next-line max-len
  private readonly get_is_available_to_hire_strategy_moderate!: GetIsAvailableToHireStrategyModerateQuery;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.my-investment-dashboard';

  wealth_policy_in_process_of_cancellation = false;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  setTitleOfWindow = () => {
    const set_title_event = new CustomEvent('nav-bar.title', {
      detail: {
        title: [
          {
            label: this.translate('title'),
          },
        ],
      },
    });
    window.dispatchEvent(set_title_event);
  }

  validateIfWealthPolicyInProcessOfCancellation = async () => {
    try {
      const { is_on_cancellation_process } = await this
        .get_allianz_account_is_on_cancellation_process.execute();
      this.wealth_policy_in_process_of_cancellation = is_on_cancellation_process;
    } catch (error) {
      this.message_notifier.showErrorNotification(this.translate('errors.policy_in_process_of_cancellation'));
    }
  }

  initialize = async () => {
    this.setTitleOfWindow();
    await this.validateIfWealthPolicyInProcessOfCancellation();
  }
}
