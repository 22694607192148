import { Container } from 'inversify-props';
import setupEmails from './emails/setup';
import setupPersonModule from './person/setup';
import setupCatalogsModule from './catalogs/setup';
import setupSteps from './steps/setup';
import setupVerifyNip from './verify-nip/setup';
import setupResendVerifyNip from './renew-verify-nip/setup';
import setupCustomerAgreements from './customer-agreements/setup';
import setupIdentityVerification from './identity-verification/setup';
import setupStatus from './status/setup';
import setupCustomerCellphone from './customer-cellphone/setup';
import setupPreferredMeansOfContact from './preferred-means-of-contact/setup';
import setupCustomerDocument from './customer-document/setup';

export default function setupOnBoardingModule(container: Container) {
  setupEmails(container);
  setupPersonModule(container);
  setupCatalogsModule(container);
  setupSteps(container);
  setupVerifyNip(container);
  setupResendVerifyNip(container);
  setupCustomerAgreements(container);
  setupIdentityVerification(container);
  setupStatus(container);
  setupCustomerCellphone(container);
  setupPreferredMeansOfContact(container);
  setupCustomerDocument(container);
}
