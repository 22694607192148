import TYPES from '../../types';

// Domain
import { CustomerDocumentDto } from '../../domain/dtos/customer-document-dto';
import {
  CustomerDocumentRepository,
} from '../../domain/repositories/customer-document-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UploadProofOfTaxSituationCommand
  extends Command<CustomerDocumentDto> {
  @Inject(TYPES.KUSPIT_CUSTOMER_DOCUMENT_REPOSITORY)
  private readonly repository!: CustomerDocumentRepository;

  internalExecute(customer_document_dto: CustomerDocumentDto): Promise<void> {
    return this.repository.upload_proof_of_tax_situation(customer_document_dto);
  }
}
