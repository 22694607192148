import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import InvestmentProductsDummyRepository
  from '@/modules/flagship/catalogs/investment-products/infraestructure/repositories/investment-products-dummy-repository/investment-products-dummy-repository';
import InvestmentProductsHttpRepository
  from '@/modules/flagship/catalogs/investment-products/infraestructure/repositories/investment-products-http-repository';
import InvestmentProductsVueStateManager
  from '@/modules/flagship/catalogs/investment-products/infraestructure/state/investment-products-vue-state-manager';

// Application
import GetInvestmentProductsQuery
  from '@/modules/flagship/catalogs/investment-products/application/queries/get-investment-products-query';

// Domain
import {
  InvestmentProductsRepository,
} from '@/modules/flagship/catalogs/investment-products/domain/repositories/investment-products-repository';
import {
  InvestmentProductsStateManager,
} from '@/modules/flagship/catalogs/investment-products/domain/state/investment-products-state-manager';

export default function setupInvestmentProducts(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<InvestmentProductsStateManager>(TYPES.INVESTMENT_PRODUCTS_STATE_MANAGER)
    .to(InvestmentProductsVueStateManager)
    .inSingletonScope();

  container
    .bind<InvestmentProductsRepository>(
      TYPES.INVESTMENT_PRODUCTS_REPOSITORY,
    ).to(
      withDummies
        ? InvestmentProductsDummyRepository
        : InvestmentProductsHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetInvestmentProductsQuery>(
      TYPES.GET_INVESTMENT_PRODUCTS_QUERY,
    ).to(GetInvestmentProductsQuery)
    .inSingletonScope();
}
