import TYPES from '../../types';

// Domain
import { CurrentPlanEntity } from '../../domain/entities/current-plan-entity';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class EditEmergencyFundGoalPlanCurrentPlanService {
  @Inject(TYPES.EDIT_EMERGENCY_FUND_GOAL_PLAN_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  setCurrentPlanInformation(current_plan: CurrentPlanEntity): void {
    this.state_manager.state.current_plan = { ...current_plan };
  }

  getCurrentPlanInformation(): CurrentPlanEntity {
    return this.state_manager.state.current_plan;
  }
}
