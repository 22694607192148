import Vue from 'vue';
import Injectable from '../../../domain/di/injectable';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

@Injectable()
// eslint-disable-next-line import/prefer-default-export
export class VueNotifier implements MessageNotifier {
  // eslint-disable-next-line class-methods-use-this
  showSuccessNotification(message: string) {
    Vue.notify({
      group: 'notifications',
      type: 'success',
      title: message,
      duration: 10000,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  showErrorNotification(message: string) {
    Vue.notify({
      group: 'notifications',
      type: 'error',
      title: message,
      duration: 10000,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  showInfoNotification(message: string) {
    Vue.notify({
      group: 'notifications',
      type: 'info',
      title: message,
      duration: 10000,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  showWarningNotification(message: string) {
    Vue.notify({
      group: 'notifications',
      type: 'warning',
      title: message,
      duration: 10000,
    });
  }
}
