import TYPES from '@/modules/shared/types';

// Domain
import {
  HireModerateDto,
} from '@/modules/flagship/strategy-moderate/hire/domain/dtos/hire-moderate-dto';
import { AvailableToHireStrategyModerateEntity } from '@/modules/flagship/strategy-moderate/hire/domain/entities/availabe-to-hire-strategy-moderate-entity';
import {
  StrategySowosModerateRepository,
} from '@/modules/flagship/strategy-moderate/hire/domain/repositories/strategy-sowos-moderate-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class StrategySowosModerateHttpRepository
implements StrategySowosModerateRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/allianz/activate-sowos-moderate/`;

  private readonly endpoint_available_to_hire_strategy_moderate = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/customer-investment-product-fund-type/moderate-is-available-to-contract/`;

  hire(
    hire_moderate_dto: HireModerateDto,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}`, 'POST', hire_moderate_dto)
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => {
                reject(new Error(message));
              },
            );
          }
        })
        .catch(reject);
    });
  }

  get_is_available_to_hire_strategy_moderate(): Promise<AvailableToHireStrategyModerateEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint_available_to_hire_strategy_moderate)
        .then((response) => {
          response.json()
            .then(({ success, data, error }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(error));
              }
            });
        })
        .catch(reject);
    });
  }
}
