import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';
import TYPES from '@/modules/shared/types';
import { JwtRepository } from '../../../domain/repositories';

@Injectable()
export default class JwtHttpRepository implements JwtRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly path = `${process.env.VUE_APP_API_URL}/api/v1/app/auth/validate-jwt/`;

  create(token: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (token !== '' && token !== undefined) {
        const body = {
          jwt: token,
        };
        this.http_connector.execute(this.path, 'POST', body)
          .then((response) => {
            response.json().then(({ success, data, message }) => {
              if (success) {
                resolve(data.is_valid);
              } else {
                reject(new Error(message));
              }
            });
          })
          .catch(reject);
      } else {
        reject(new Error('Token not provided'));
      }
    });
  }
}
