import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import { MunicipalityHttpRepository, MunicipalityDummyRepository } from './infrastructure/repository';

// Application
import { SearchMunicipalitiesQuery } from './application/queries';

// Domain
import { MunicipalityRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupMunicipality(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.CATALOG_ALLIANZ_MUNICIPALITY_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<MunicipalityRepository>(TYPES.CATALOG_ALLIANZ_MUNICIPALITY_REPOSITORY)
    .to(
      withDummies
        ? MunicipalityDummyRepository
        : MunicipalityHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchMunicipalitiesQuery>(TYPES.SEARCH_CATALOG_ALLIANZ_MUNICIPALITY_QUERY)
    .to(SearchMunicipalitiesQuery)
    .inSingletonScope();
}
