// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { CustomerKuspitPositionRepository } from '../../../domain/repository';
import { PositionDto } from '../../../domain/dtos/position-dto';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CustomerKuspitPositionDummyRepository
implements CustomerKuspitPositionRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(): Promise<PositionDto> {
    return Promise.resolve(fixtures);
  }
}
