import FINANCIAL_INSTITUTION_TYPES from './financial-institution/types';
import STATE_TYPES from './state/types';
import COUNTRY_TYPES from './country/types';
import ADDRESS_TYPES from './address/types';
import INCOME_TYPES from './income/types';
import INVESTMENT_TYPES from './investment/types';
import OPERATION_TYPES from './operation/types';
import SOURCE_TYPES from './source/types';
import ORIGIN_TYPES from './origin/types';
import MARITAL_STATUS_TYPES from './marital-status/types';
import BUSINESS_ACTIVITY_TYPES from './business-activity/types';
import ACTIVITY_TYPES from './activity/types';
import JOB_TYPE_TYPES from './job-type/types';

export default {
  ...FINANCIAL_INSTITUTION_TYPES,
  ...STATE_TYPES,
  ...COUNTRY_TYPES,
  ...ADDRESS_TYPES,
  ...INCOME_TYPES,
  ...INVESTMENT_TYPES,
  ...OPERATION_TYPES,
  ...SOURCE_TYPES,
  ...ORIGIN_TYPES,
  ...MARITAL_STATUS_TYPES,
  ...BUSINESS_ACTIVITY_TYPES,
  ...ACTIVITY_TYPES,
  ...JOB_TYPE_TYPES,
};
