// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { AddressRepository } from '../../../domain/repository';
import { AddressEntity } from '../../../domain/entities/address-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class AddressDummyRepository implements AddressRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(postal_code: string): Promise<Array<AddressEntity>> {
    return Promise.resolve(fixtures);
  }
}
