import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import {
  HasAccountHttpRepository,
  HasAccountDummyRepository,
} from './infrastructure/repository';
import VueStateManager from './infrastructure/state-manager/vue-state-manager';

// Application
import { GetHasKuspitAccountQuery } from './application/queries';

// Domain
import { HasAccountRepository } from './domain/repository';
import { StateManager } from './domain/state-manager';

export default function setupCustomerHasKuspitAccount(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<StateManager>(TYPES.CUSTOMER_HAS_KUSPIT_ACCOUNT_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<HasAccountRepository>(TYPES.CUSTOMER_HAS_KUSPIT_ACCOUNT_REPOSITORY)
    .to(
      withDummies
        ? HasAccountDummyRepository
        : HasAccountHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<GetHasKuspitAccountQuery>(TYPES.GET_CUSTOMER_HAS_KUSPIT_ACCOUNT_QUERY)
    .to(GetHasKuspitAccountQuery)
    .inSingletonScope();
}
