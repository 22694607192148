// Domain
import {
  CreateOnBoardingStepRepository,
} from '../../../../domain/repositories';
import { OnBoardingStepEntity } from '../../../../domain/entities/on-boarding-step-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CreateOnBoardingStepDummyRepository
implements CreateOnBoardingStepRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(on_boarding_step: OnBoardingStepEntity): Promise<void> {
    const steps_data = JSON.parse(sessionStorage.getItem('steps_data')!) || [];
    sessionStorage.setItem('steps_data', JSON.stringify([on_boarding_step, ...steps_data]));

    return Promise.resolve();
  }
}
