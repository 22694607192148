







































































































import { Component, Vue } from 'vue-property-decorator';
import AccountBalance from '@/vue-app/components/kuspit-dashboard/AccountBalance.vue';
import Performance from '@/vue-app/components/kuspit-dashboard/Performance.vue';
import Movements from '@/vue-app/components/kuspit-dashboard/Movements.vue';
import Position from '@/vue-app/components/kuspit-dashboard/Position.vue';
import KuspitDashboardViewModel from '@/vue-app/view-models/views/kuspit-dashboard-view-model';
import Withdrawal from '@/vue-app/components/kuspit-dashboard/withdrawals/Withdrawal.vue';
import DepositAccountInfo
  from '@/vue-app/components/kuspit-dashboard/deposits/DepositsAccountInfo.vue';

@Component({
  name: 'KuspitDashboard',
  components: {
    DepositAccountInfo,
    AccountBalance,
    Performance,
    Movements,
    Position,
    LinkedGoals: () => import('@/vue-app/components/kuspit-dashboard/goals/LinkedGoals.vue'),
    Withdrawal,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$data.kuspit_dashboard_view_model.get_has_kuspit_account_query.execute()
        .then((response: any) => {
          // eslint-disable-next-line no-param-reassign
          vm.$data.kuspit_dashboard_view_model.kuspit_account_exists = response.has_account;
          if (response.has_account) {
            vm.$data.kuspit_dashboard_view_model.get_kuspit_position.execute()
              .then((position: any) => {
                // eslint-disable-next-line no-param-reassign
                vm.$data.kuspit_dashboard_view_model.kuspit_has_funds = position.has_funds;
                if (vm.$data.kuspit_dashboard_view_model.redirect_to_my_investment) {
                  vm.$router.push({ name: 'HomeDashboard' });
                }
              });
          } else {
            vm.$router.push({ name: 'HomeDashboard' });
          }
        });
    });
  },
})
export default class KuspitDashboard extends Vue {
  kuspit_dashboard_view_model = Vue.observable(new KuspitDashboardViewModel());

  $refs!: {
    modal_withdrawal: Withdrawal;
    modal_deposits: DepositAccountInfo;
    movements: Movements;
  }

  requestWithdrawal() {
    this.$refs.modal_withdrawal.withdrawal_view_model.openModal();
  }

  refreshMovements() {
    this.$refs.movements.movements_view_model.refreshMovements();
  }

  async created() {
    await this.kuspit_dashboard_view_model.initialize();
    const set_title_event = new CustomEvent('nav-bar.title', {
      detail: {
        title: [
          {
            label: this.kuspit_dashboard_view_model.translate('title'),
          },
        ],
      },
    });

    window.dispatchEvent(set_title_event);
  }
}

