// Domain
import {
  EmploymentSituationEntity,
} from '@/modules/flagship/catalogs/employment-situation/domain/entities/employment-situation-entity';

export default class EmploymentSituationState {
  loaded = false;

  items: Array<EmploymentSituationEntity> = [];
}
