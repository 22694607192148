import AuthenticatedLayout from '@/vue-app/layouts/AuthenticatedLayout.vue';
import UpdateIdentityDocumentAuthenticated from '@/vue-app/views/update-identity-document/UpdateIdentityDocumentAuthenticated.vue';
import POLICIES from '../security/policies';

export default {
  path: '/update-identity-document-authenticated',
  component: AuthenticatedLayout,
  children: [
    {
      path: '/',
      name: 'UpdateIdentityDocumentAuthenticated',
      component: UpdateIdentityDocumentAuthenticated,
      meta: {
        access_policy: POLICIES.AUTHENTICATED,
      },
    },
  ],
};
