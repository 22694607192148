import TYPES from '../../types';

// Domain
import { AllianzOperationDatesEntity } from '../../domain/entities/allianz-operation-dates-entity';
import { AllianzOperationDatesRepository } from '../../domain/repository';
import { StateManager } from '../../domain/state/state-manager';
import { CheckWithdrawalDeadLineHour } from '../../domain/services';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetAllianzWithdrawalOperationDatesQuery extends
  Query<Promise<AllianzOperationDatesEntity>> {
  @Inject(TYPES.ALLIANZ_OPERATION_DATES_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.ALLIANZ_OPERATION_DATES_REPOSITORY)
  private readonly repository!: AllianzOperationDatesRepository;

  internalExecute(transaction_type_id: string): Promise<AllianzOperationDatesEntity> {
    return new Promise((resolve, reject) => {
      if (!this.state_manager.state.loaded_withdrawal
        || CheckWithdrawalDeadLineHour.checkDeadline(
          this.state_manager.state.withdrawal_operation_dates.last_updated_hour,
        )
      ) {
        this.repository.get(transaction_type_id)
          .then((transaction_type: AllianzOperationDatesEntity) => {
            this.state_manager.patch({
              withdrawal_operation_dates: {
                ...transaction_type, last_updated_hour: new Date().getHours(),
              },
            });
            resolve(this.state_manager.state.withdrawal_operation_dates);
          })
          .catch(reject);
      } else {
        resolve(this.state_manager.state.withdrawal_operation_dates);
      }
    });
  }
}
