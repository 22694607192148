import TYPES from '../../types';

// Domain
import {
  DistributeUnassignedBalanceEntity,
} from '@/modules/flagship/investor-goal/distribute-unassigned-balance/domain/entities/distribute-unassigned-balance-entity';
import {
  DistributeUnassignedBalanceRepository,
} from '@/modules/flagship/investor-goal/distribute-unassigned-balance/domain/repositories/distribute-unassigned-balance-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class DistributeUnassignedBalanceCommand
  extends Command<DistributeUnassignedBalanceEntity> {
  @Inject(TYPES.DISTRIBUTE_UNASSIGNED_BALANCE_REPOSITORY)
  private readonly repository!: DistributeUnassignedBalanceRepository;

  internalExecute(distribute_unassigned_balance_entity: DistributeUnassignedBalanceEntity):
    Promise<void> {
    return this.repository.distribute(distribute_unassigned_balance_entity);
  }
}
