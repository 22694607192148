import TYPES from '../../types';

// Domain
import { CustomerKuspitCredentialsEntity } from '../../domain/entities/customer-kuspit-credentials-entity';
import { CustomerKuspitCredentialsRepository } from '../../domain/repository/customer-kuspit-credentials-repository';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetCustomerKuspitCredentialsQuery
  extends Query<CustomerKuspitCredentialsEntity> {
  @Inject(TYPES.CUSTOMER_KUSPIT_CREDENTIALS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.CUSTOMER_KUSPIT_CREDENTIALS_REPOSITORY)
  private readonly repository!: CustomerKuspitCredentialsRepository;

  internalExecute() {
    if (
      this.state_manager.state.credentials.password === ''
      || this.state_manager.state.credentials.password === null
    ) {
      this.repository.execute()
        .then((credentials) => {
          this.state_manager.patch({ credentials });
        });
    }

    return this.state_manager.state.credentials;
  }
}
