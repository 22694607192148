import TYPES from '../../types';

// Application
import SignInService from '../services/sign-in-service';

// Domain
import { UserCredentialsType } from '../../domain/types';
import { AuthenticationDataRepository } from '../../domain/repositories';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class SignInCommand extends Command<UserCredentialsType> {
  @Inject(TYPES.AUTHENTICATION_DATA_REPOSITORY)
  private readonly authenticationDataRepository!: AuthenticationDataRepository;

  internalExecute(credentials: UserCredentialsType) {
    return new Promise((resolve, reject) => {
      this.authenticationDataRepository.create(credentials)
        .then(({ token }) => {
          const sign_in_service = new SignInService();

          sign_in_service.login(token);
          resolve();
        })
        .catch(reject);
    });
  }
}
