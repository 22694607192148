// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { OperationRepository } from '../../../domain/repository';
import { OperationEntity } from '../../../domain/entities/operation-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class OperationDummyRepository implements OperationRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(): Promise<Array<OperationEntity>> {
    return Promise.resolve(fixtures);
  }
}
