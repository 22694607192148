// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { PeriodicityOfContributionRepository } from '../../../domain/repository';
import {
  PeriodicityOfContributionEntity,
} from '../../../domain/entities/periodicity-of-contribution-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class PeriodicityOfContributionDummyRepository
implements PeriodicityOfContributionRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(): Promise<Array<PeriodicityOfContributionEntity>> {
    return Promise.resolve(fixtures);
  }
}
