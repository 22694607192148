





















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({ name: 'UserProfileMenuItem' })
export default class UserProfileMenuItem extends Vue {
  @Prop({ type: String, required: true })
  label!: string;

  @Prop({ type: Boolean, required: false, default: false })
  active!: boolean;

  @Prop({ type: Function, required: true })
  onClick!: Function;
}
