import TYPES from '../../types';

// Domain
import { CustomGoalCalculationEntity } from '@/modules/flagship/custom-goal-calculations/domain/entities/custom-goal-calculation-entity';
import { CustomGoalFixedTimeCalculationRepository } from '@/modules/flagship/custom-goal-calculations/domain/repositories/custom-goal-fixed-time-calculation-repository';
import { CustomGoalFixedTimeCalculationDto } from '@/modules/flagship/custom-goal-calculations/domain/dtos/custom-goal-fixed-time-calculation-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
class CalculateCustomGoalFixedTimeCalculationQuery extends Query<
  Promise<CustomGoalCalculationEntity>
> {
  @Inject(TYPES.CUSTOM_GOAL_FIXED_TIME_CALCULATION_REPOSITORY)
  private readonly repository!: CustomGoalFixedTimeCalculationRepository;

  internalExecute(
    payload: CustomGoalFixedTimeCalculationDto,
  ): Promise<CustomGoalCalculationEntity> {
    return this.repository.calculate(payload);
  }
}

export default CalculateCustomGoalFixedTimeCalculationQuery;
