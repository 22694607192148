import fixtures from './fixtures.json';

// Domain
import {
  CustomerInvestorGoalReminderRepository,
} from '@/modules/flagship/customer-investor-goal-reminder/domain/repositories/customer-investor-goal-reminder-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CustomerInvestorGoalReminderDummyRepository
implements CustomerInvestorGoalReminderRepository {
  get(): Promise<any> {
    return Promise.resolve(fixtures);
  }

  create(): Promise<void> {
    return Promise.resolve();
  }

  update(): Promise<void> {
    return Promise.resolve();
  }
}
