import TYPES from '@/modules/shared/types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import { OnBoardingUpdateStepRepository } from '../../../domain/repositories/on-boarding-update-step-repository';
import { StepEntity } from '../../../domain/entities/step-entity';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class OnBoardingUpdateStepHttpRepository implements OnBoardingUpdateStepRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly path = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/on-boarding-steps/{STEP_ID}/`;

  execute(step: StepEntity): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.path.replace('{STEP_ID}', step.id), 'PATCH', {
        current_step: step.current_step,
        payload: step.payload,
      })
        .then((response) => {
          if (response.status === 204) {
            resolve();
          } else {
            reject(new Error('There was an error updating the step.'));
          }
        })
        .catch(reject);
    });
  }
}
