// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { CustomerCellphoneRepository }
  from '../../../domain/repository/customer-cellphone-respository';
import { CustomerCellphoneDto } from '../../../domain/dtos/customer-cellphone-dto';
import { CustomerCellphoneEntity } from '../../../domain/entities/customer-cellphone-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
class CustomerCellphoneDummyRepository implements CustomerCellphoneRepository {
  create(customer_cellphone: CustomerCellphoneDto): Promise<void> {
    console.log('CUSTOMER AGREEMENT CREATED: ', customer_cellphone);

    return Promise.resolve();
  }

  get(): Promise<Array<CustomerCellphoneEntity>> {
    return Promise.resolve(fixtures);
  }
}

export default CustomerCellphoneDummyRepository;
