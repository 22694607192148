import TYPES from '../../types';

// Domain
import {
  ContactPointSowosModerateRepository,
} from '@/modules/flagship/contact-points/sowos-moderate/domain/repositories/contact-point-sowos-moderate-repository';
import {
  PollResultEntity,
} from '@/modules/flagship/contact-points/sowos-moderate/domain/entities/poll-result-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class SearchAnswersExitPollQuery
  extends Query<Promise<Array<PollResultEntity>>> {
  @Inject(TYPES.CONTACT_POINT_SOWOS_MODERATE_REPOSITORY)
  private readonly repository!: ContactPointSowosModerateRepository;

  internalExecute(): Promise<Array<PollResultEntity>> {
    return new Promise((resolve, reject) => {
      this.repository.searchAnswersExitPoll()
        .then((data) => {
          resolve(data);
        })
        .catch(reject);
    });
  }
}
