import detailsEmergencyFundModal from './details-emergency-fund-modal.json';
import detailsRetirementFundModal from './details-retirement-fund-modal.json';
import detailsCustomModal from './details-custom-modal.json';
import customProgressBar from './custom-progress-bar.json';

export default {
  'details-goals': {
    ...detailsEmergencyFundModal,
    ...detailsRetirementFundModal,
    ...detailsCustomModal,
    ...customProgressBar,
  },
};
