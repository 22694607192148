import { MovementEntity } from '../../domain/entities/movement-entity';
import format from 'date-fns/format';
import esLocale from 'date-fns/locale/es';

type MovementStyle = {
  name: string;
  sign: string;
  text_color: string;
  icon: string;
  icon_color: string;
};

const MOVEMENT_TYPES: Record<string, MovementStyle> = {
  R: {
    name: 'Retiro',
    sign: '-',
    text_color: 'error',
    icon: 'mdi-close-thick',
    icon_color: 'error',
  },
  D: {
    name: 'Depósito',
    sign: '+',
    text_color: 'primary',
    icon: 'mdi-plus',
    icon_color: 'secondary',
  },
  T: {
    name: 'Transacción',
    sign: '',
    text_color: 'primary',
    icon: 'mdi-fast-forward',
    icon_color: 'purple',
  },
};

export default class MovementPresenter {
  private readonly movement: MovementEntity;

  constructor(movement: MovementEntity) {
    this.movement = movement;
  }

  get movement_type() {
    return MOVEMENT_TYPES[this.movement.movement_type];
  }

  get movement_date() {
    return format(this.movement.payment_date, 'dddd, DD [de] MMMM [de] YYYY', { locale: esLocale });
  }
}
