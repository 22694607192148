import { Container } from 'inversify-props';
import TYPES from './types';
import RenewVerifyNipDummyRepository
  from './infrastructure/repositories/renew-verify-nip-dummy-repository';
import RenewVerifyNipHttpRepository
  from './infrastructure/repositories/renew-verify-nip-http-repository';
import RenewOnBoardingVerifyNipCommand
  from './application/commands/renew-on-boarding-verify-nip-command';
import RenewVerifyNipRepository from './domain/repositories/renew-verify-nip-repository';

export default function setupResendVerifyNip(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<RenewVerifyNipRepository>(TYPES.ON_BOARDING_RENEW_VERIFY_NIP_REPOSITORY)
    .to(
      withDummies
        ? RenewVerifyNipDummyRepository
        : RenewVerifyNipHttpRepository,
    ).inSingletonScope();

  container
    .bind<RenewOnBoardingVerifyNipCommand>(TYPES.RENEW_ON_BOARDING_VERIFY_NIP_COMMAND)
    .to(RenewOnBoardingVerifyNipCommand)
    .inSingletonScope();
}
