import TYPES from '@/modules/shared/types';

// Domain
import { InvestorGoalScheduledDistributionWealthWithdrawalRepository } from '../../domain/repositories/investor-goal-scheduled-distribution-wealth-withdrawal-repository';
import { InvestorGoalScheduledDistributionWealthWithdrawalDto } from '../../domain/dto/investor-goal-scheduled-distribution-wealth-withdrawal-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class InvestorGoalScheduledDistributionWealthWithdrawalHttpRepository implements
  InvestorGoalScheduledDistributionWealthWithdrawalRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/investor-goal-scheduled-distribution/sowos-wealth/withdrawal/`;

  // eslint-disable-next-line max-len
  distribute(investor_goal_scheduled_distribution_wealth_withdrawal_dto: InvestorGoalScheduledDistributionWealthWithdrawalDto):
    Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint, 'POST', investor_goal_scheduled_distribution_wealth_withdrawal_dto)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
