import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';
import TYPES from '@/modules/shared/types';
import { FinancialInstitutionRepository } from '../../domain/repository';
import { FinancialInstitutionEntity } from '../../domain/entities/financial-institution-entity';

@Injectable()
export default class FinancialInstitutionFetchRepository implements FinancialInstitutionRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/kuspit/catalogs/banks/`;

  findAll(): Promise<Array<FinancialInstitutionEntity>> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
