import TYPES from '../../types';

// Domain
import { UserCredentialsType } from '../../domain/types';
import { AuthenticationDataRepository } from '../../domain/repositories';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class SignOutCommand extends Command<UserCredentialsType> {
  @Inject(TYPES.AUTHENTICATION_DATA_REPOSITORY)
  private readonly authenticationDataRepository!: AuthenticationDataRepository;

  internalExecute() {
    return new Promise((resolve, reject) => {
      this.authenticationDataRepository.signOut()
        .then(() => {
          resolve();
        })
        .catch(reject);
    });
  }
}
