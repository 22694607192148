import { Container } from 'inversify';
import TYPES from './types';

// Infrastructure
import CustomerDocumentWealthDummyRepository
  from './infrastructure/repositories/customer-document-wealth-dummy-repository/customer-document-wealth-dummy-repository';
import CustomerDocumentWealthHttpRepository
  from './infrastructure/repositories/customer-document-wealth-http-repository';

// Application
import UpdateCustomerDocumentWealthBankStatementCommand
  from './application/commands/update-customer-document-wealth-bank-statement-command';
import UpdateCustomerDocumentWealthProofAddressCommand
  from './application/commands/update-customer-document-wealth-proof-address-command';

// Domain
import {
  CustomerDocumentWealthRepository,
} from './domain/repositories/customer-document-wealth-repository';

export default function setupCustomerDocumentWealth(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<CustomerDocumentWealthRepository>(TYPES.CUSTOMER_DOCUMENT_WEALTH_REPOSITORY)
    .to(
      withDummies
        ? CustomerDocumentWealthDummyRepository
        : CustomerDocumentWealthHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<UpdateCustomerDocumentWealthProofAddressCommand>(TYPES
      .UPDATE_CUSTOMER_DOCUMENT_WEALTH_PROOF_ADDRESS_COMMAND)
    .to(UpdateCustomerDocumentWealthProofAddressCommand)
    .inSingletonScope();

  container
    .bind<UpdateCustomerDocumentWealthBankStatementCommand>(TYPES
      .UPDATE_CUSTOMER_DOCUMENT_WEALTH_BANK_STATEMENT_COMMAND)
    .to(UpdateCustomerDocumentWealthBankStatementCommand)
    .inSingletonScope();
}
