import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';
import { getGoalIconPath } from '@/vue-app/utils/goal-icon-path';

// Application
import GetSearchByCustomerQuery from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-search-by-customer-query';
import GetPendingInvestorGoalMovementsQuery from '@/modules/flagship/pending-investor-goal-movements/application/queries/get-pending-investor-goal-movements-query';
import DistributeBalanceCommand from '@/modules/flagship/investor-goal/distribute-balance/application/commands/distribute-balance-command';

// Domain
import {
  DistributeBalanceDto,
} from '@/modules/flagship/investor-goal/distribute-balance/domain/dto/distribute-balance-dto';
import {
  SearchByCustomerEntity,
} from '@/modules/flagship/investor-goal/search-by-customer/domain/entities/search-by-customer-entity';
import { SearchByCustomerDto } from '@/modules/flagship/investor-goal/search-by-customer/domain/dtos/search-by-customer-dto';
import {
  verifyIfInvestorGoalIsLinkedWithModerate,
} from '@/modules/flagship/customer-investment-product-fund-type/domain/services/verify-if-investor-goal-is-linked-with-an-investment-product';
import Inject from '@/modules/shared/domain/di/inject';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

type LinkedGoals = {
  investor_goal_id: string;
  name: string;
  label: string;
  icon: string;
  assigned_amount: number;
  recommend_amount: number;
  alt: string;
  assigned_amount_field: string;
  is_moderate: boolean;
  icon_path: string;
}

export default class DistributeSpecialContributionAmountLinkedGoalsWealthViewModel {
  @Inject(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetSearchByCustomerQuery;

  @Inject(TYPES.GET_PENDING_INVESTOR_GOAL_MOVEMENTS_QUERY)
  private readonly get_pending_investor_goal_movements_query!: GetPendingInvestorGoalMovementsQuery;

  @Inject(TYPES.DISTRIBUTE_BALANCE_COMMAND)
  private readonly distribute_balance_command!: DistributeBalanceCommand;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.flagship.distribute-special-contribution-among-linked-goals';

  readonly step = 50;

  linked_goals: Array<LinkedGoals> = [];

  deposit_amount = 0;

  is_disabled = false;

  is_checkbox_active = false;

  index_unassigned_amount = 0;

  is_modal_open = false;

  is_loading = true;

  deposit_date = '';

  is_success_modal_open = false;

  has_pending_movements_to_distribute = false;

  investor_goal_movement_id = '';

  payload_save_amount_distributed: DistributeBalanceDto = {
    total_distributed_goals_balance: '',
    investment_product_id: '',
    investor_goal_movement_id: '',
    unassigned_balance: '',
    investor_goals: [],
  };

  is_there_any_pending_distribution = false;

  private search_by_customer_dto: SearchByCustomerDto = {
    reload: true,
    associated_product_id: '',
    is_active: true,
  };

  private active_goals: Array<SearchByCustomerEntity> = [];

  constructor() {
    this.addReloadPendingContributionsEventListener();
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async () => {
    this.is_loading = true;
    this.linked_goals.length = 0;
    this.is_success_modal_open = false;
    this.is_there_any_pending_distribution = false;
    this.has_pending_movements_to_distribute = false;
    this.payload_save_amount_distributed.investor_goals.length = 0;
    await this.loadPendingMovementsToDistributeWealth();
    this.is_loading = false;
  }

  get deposit_amount_formatted() {
    return currencyFormat(this.deposit_amount);
  }

  getIconMaxWidth = (icon_goal_name: string) => ((icon_goal_name === 'icon-add.svg') ? '25px' : '21px');

  getAmountFormatted = (amount: number) => (currencyFormat(amount));

  getCustomizedModerateClassForMainVCol = (is_moderate: boolean) => ((is_moderate) ? 'white--text primary rounded-t-lg' : '');

  getCustomizedModerateBackgroundColorForVTextField = (is_moderate: boolean) => ((is_moderate) ? 'primary' : '');

  getCustomizedModerateClassForVTextField = (is_moderate: boolean) => ((is_moderate) ? 'white-font' : '');

  addReloadPendingContributionsEventListener = () => {
    window.addEventListener('reload.pending.contributions.wealth', this.initialize);
  }

  removeReloadPendingContributionsEventListener = () => {
    window.removeEventListener('reload.pending.contributions.wealth', this.initialize);
  }

  loadPendingMovementsToDistributeWealth = async () => {
    try {
      const params_get_pending_movements = {
        is_deposit: true,
      };
      const pending_movements = await this
        .get_pending_investor_goal_movements_query.execute(params_get_pending_movements);
      if (pending_movements.length) {
        const wealth_movements = pending_movements.filter(
          (movement) => movement.investment_product.name === 'sowos_wealth' && movement.is_pending && movement.investment_product_fund_type.label === 'SWSRFP',
        );
        if (wealth_movements.length) {
          this.is_there_any_pending_distribution = wealth_movements.length > 1;
          this.has_pending_movements_to_distribute = true;
          const date = wealth_movements[0].created_at.split('T')[0];
          this.deposit_date = this.date_formatter.formatDate(date, 'DD MMM YYYY');
          this.investor_goal_movement_id = wealth_movements[0].id;
          this.deposit_amount = wealth_movements[0].amount;

          this.setInvestmentProductId(wealth_movements[0].investment_product.id);
          await this.loadActiveGoals();
          this.setUnassignedAmount();
          this.is_modal_open = true;
        } else {
          this.removeReloadPendingContributionsEventListener();
        }
      }
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_pending_investor_goal_movements_query'));
      }
    }
  }

  setInvestmentProductId = (investment_product_id: string) => {
    this.search_by_customer_dto.associated_product_id = investment_product_id;
  }

  loadActiveGoals = async () => {
    try {
      // eslint-disable-next-line max-len
      this.active_goals = await this.get_search_by_customer_query.execute(this.search_by_customer_dto);
      this.active_goals.forEach((goal: any) => {
        this.linked_goals.push({
          investor_goal_id: goal.investor_goal_id,
          // eslint-disable-next-line max-len
          name: (goal.custom_investor_goal) ? goal.custom_investor_goal.goal_name : goal.investment_goal_type.name,
          // eslint-disable-next-line max-len
          label: (goal.custom_investor_goal) ? goal.custom_investor_goal.goal_name : goal.investment_goal_type.label,
          icon: (goal.custom_investor_goal) ? goal.custom_investor_goal.custom_goal_type.icon_name : 'icon-fund.svg',
          assigned_amount: 0,
          recommend_amount: goal.monthly_required_amount || 0,
          alt: (goal.custom_investor_goal) ? 'alts.custom' : 'alts.img_fund',
          assigned_amount_field: '0',
          is_moderate: verifyIfInvestorGoalIsLinkedWithModerate(
            goal.investment_product_fund_types.linked,
          ),
          icon_path: getGoalIconPath(
            goal.investor_goal_id,
            goal.custom_investor_goal?.custom_goal_type?.icon_name || 'icon-fund.svg',
            goal.custom_investor_goal?.updated_at || '',
          ),
        });
      });
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_search_by_customer_query'));
    }
  }

  setUnassignedAmount = () => {
    this.linked_goals.push({
      investor_goal_id: '',
      name: 'unassigned',
      label: 'Saldo sin asignar',
      icon: 'noun-coins.svg',
      assigned_amount: this.deposit_amount,
      recommend_amount: 0,
      alt: 'alts.unassigned',
      assigned_amount_field: '0',
      is_moderate: false,
      icon_path: getGoalIconPath('', 'noun-coins.svg', ''),
    });
    this.index_unassigned_amount = this.linked_goals.length - 1;
  }

  incrementAmount = (index: number) => {
    let current_amount = this.linked_goals[index].assigned_amount;
    current_amount += this.step;
    if (this.deposit_amount >= current_amount) {
      this.linked_goals[index].assigned_amount += this.step;
      this.linked_goals[index].assigned_amount_field = String(this.linked_goals[index]
        .assigned_amount);
      this.validateTotalAmounts(false);
    }
  }

  decrementAmount = (index: number) => {
    let current_amount = this.linked_goals[index].assigned_amount;
    current_amount -= this.step;
    if (this.linked_goals[index].assigned_amount > 0 && current_amount >= 0) {
      this.linked_goals[index].assigned_amount -= this.step;
      this.linked_goals[index].assigned_amount_field = String(this.linked_goals[index]
        .assigned_amount);
      this.validateTotalAmounts(true);
    }
  }

  addUnassignedAmount = (total_amount_goals: number) => {
    if (total_amount_goals < this.deposit_amount) {
      const res = this.deposit_amount - total_amount_goals;
      if (res >= 0) {
        this.linked_goals[this.index_unassigned_amount].assigned_amount = res;
      }
    }
  }

  subtractUnassignedAmount = (total_amount_goals: number) => {
    if (total_amount_goals <= this.deposit_amount) {
      this.linked_goals[this.index_unassigned_amount].assigned_amount -= this.step;
    }
  }

  validateTotalAmounts = (is_add: boolean, amount_was_edited_field = false) => {
    const linked_goals_assigned_amount = this.linked_goals.map(
      (obj, index) => ((index !== this.index_unassigned_amount) ? obj.assigned_amount : 0),
    );
    let total_amount_goals = linked_goals_assigned_amount.reduce(
      (total, currentValue) => total + currentValue, 0,
    );
    if (!amount_was_edited_field) {
      if (is_add) {
        this.addUnassignedAmount(total_amount_goals);
      } else {
        this.subtractUnassignedAmount(total_amount_goals);
      }
    }
    total_amount_goals += this.linked_goals[this.index_unassigned_amount].assigned_amount;
    this.is_disabled = total_amount_goals !== this.deposit_amount;
  }

  distributeDepositAmongGoals = () => {
    if (this.is_checkbox_active) {
      let total_distribute_amount = 0;
      const size_linked_goals = this.linked_goals.length - 1;
      const amount_equally = this.deposit_amount / size_linked_goals;
      this.linked_goals.forEach((item, index) => {
        if (index !== this.index_unassigned_amount) {
          // eslint-disable-next-line no-param-reassign
          item.assigned_amount = Math.floor(amount_equally);
          // eslint-disable-next-line no-param-reassign
          item.assigned_amount_field = String(item.assigned_amount);
          total_distribute_amount += item.assigned_amount;
        }
      });
      this.linked_goals[this.index_unassigned_amount]
        .assigned_amount = this.deposit_amount - total_distribute_amount;
      this.is_disabled = false;
    }
  }

  changeAssignedAmountField = (index: number) => {
    const parsed_assigned_amount_field = (this.linked_goals[index].assigned_amount_field)
      ? Number(parseFloat(this.linked_goals[index].assigned_amount_field.replaceAll(/[^\d.-]/g, '')))
      : 0;
    const is_add = (parsed_assigned_amount_field > this.linked_goals[index].assigned_amount);
    // eslint-disable-next-line max-len
    if (parsed_assigned_amount_field >= 0 && this.deposit_amount >= parsed_assigned_amount_field) {
      this.linked_goals[index].assigned_amount_field = String(parsed_assigned_amount_field);
      this.linked_goals[index].assigned_amount = parsed_assigned_amount_field;
    } else {
      this.linked_goals[index].assigned_amount_field = '0';
      this.linked_goals[index].assigned_amount = 0;
    }
    const linked_goals_assigned_amount = this.linked_goals.map(
      (obj, position) => ((position !== this.index_unassigned_amount) ? obj.assigned_amount : 0),
    );
    const total_amount_goals = linked_goals_assigned_amount.reduce(
      (total, currentValue) => total + currentValue, 0,
    );
    if (total_amount_goals <= this.deposit_amount) {
      this.linked_goals[this.index_unassigned_amount]
        .assigned_amount = this.deposit_amount - total_amount_goals;
    }
    this.validateTotalAmounts(!is_add, true);
  }

  saveAmountDistributed = async () => {
    try {
      this.is_loading = true;
      let unassigned_balance = 0;
      let total_distributed_balance = 0;
      this.linked_goals.forEach((goal) => {
        if (goal.investor_goal_id && goal.assigned_amount > 0) {
          this.payload_save_amount_distributed.investor_goals.push({
            investor_goal_id: goal.investor_goal_id,
            amount_to_distribute: goal.assigned_amount.toString(),
          });
          total_distributed_balance += goal.assigned_amount;
        } else {
          unassigned_balance = goal.assigned_amount;
        }
      });
      this.payload_save_amount_distributed
        .total_distributed_goals_balance = total_distributed_balance.toString();
      // eslint-disable-next-line max-len
      this.payload_save_amount_distributed.investment_product_id = this.search_by_customer_dto.associated_product_id;
      this.payload_save_amount_distributed.unassigned_balance = unassigned_balance.toString();
      this.payload_save_amount_distributed
        .investor_goal_movement_id = this.investor_goal_movement_id;
      await this.distribute_balance_command.execute(this.payload_save_amount_distributed);
      if (!this.is_there_any_pending_distribution) {
        this.removeReloadPendingContributionsEventListener();
      }
      this.is_loading = false;
      this.is_modal_open = false;
      this.is_success_modal_open = true;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.distribute_balance_command'));
    }
  }

  cancel = () => {
    this.is_modal_open = false;
  }
}
