import strategyModerateWealthDialog from './strategy-moderate-wealth-dialog.json';
import strategyModerateWealthExitPoll from './strategy-moderate-wealth-exit-poll.json';
import strategyComparisonPesosAndModerate from './strategy-comparison-pesos-and-moderate.json';
import modifySwsModerate from './modify-sws-moderate';
import strategyInformationVideo from './strategy-information-video.json';
import strategyHiring from './strategy-hiring.json';
import strategyRiskTolerance from './strategy-risk-tolerance.json';
import strategyTransfer from './strategy-transfer.json';
import strategyRequestSent from './strategy-request-sent.json';
import StrategyModerateChooseGoalLinkModerate from './strategy-moderate-choose-goal-link.json';
import strategyModerateWarningChangeStrategy from './strategy-moderate-warning-change-strategy.json';
import linkGoal from './link-goal';
import contractingStatusModerate from './contracting-status-moderate';
import linkStatusModerate from './link-status-moderate';

export default {
  'strategy-moderate-wealth': {
    'strategy-moderate-hiring-flow': {
      header_steps: [
        'Contratación',
        'Traspaso',
      ],
    },
    ...strategyModerateWealthDialog,
    ...strategyModerateWealthExitPoll,
    ...strategyComparisonPesosAndModerate,
    ...modifySwsModerate,
    ...strategyInformationVideo,
    ...strategyHiring,
    ...strategyRiskTolerance,
    ...strategyTransfer,
    ...strategyRequestSent,
    ...StrategyModerateChooseGoalLinkModerate,
    ...strategyModerateWarningChangeStrategy,
    ...linkGoal,
    ...contractingStatusModerate,
    ...linkStatusModerate,
  },
};
