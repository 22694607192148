import { Container } from 'inversify-props';

import TYPES from './types';

// Infrastructure
import kuspitAccountLinkingDummyRepository
  from './infrastructure/repositories/validate-kuspit-account-dummy-repository';
import ValidateKuspitAccountHttpRepository
  from './infrastructure/repositories/validate-kuspit-account-http-repository';
import UpdateKuspitOnBoardingStepDummyRepository
  from './infrastructure/repositories/update-kuspit-on-boarding-step-dummy-repository';
import UpdateKuspitOnBoardingStepHttpRepository
  from './infrastructure/repositories/update-kuspit-on-boarding-step-http-repository';

// Application
import ValidateKuspitAccountCommand from './application/commands/validate-kuspit-account-command';
import { PublishReceivedStatusToEventBusOnKuspitAccountLinkingStatusWebSocketMessageSubscription }
  from './application/subscriptions';
import UpdateKuspitOnBoardingStepCommand
  from './application/commands/update-kuspit-on-boarding-step-command';

// Domain
import { ValidateKuspitAccountRepository } from './domain/repositories/validate-kuspit-account-repository';
import { UpdateKuspitOnBoardingStepRepository } from './domain/repositories/update-kuspit-on-boarding-step-repository';

export default function setupKuspitAccountLinking(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<ValidateKuspitAccountRepository>(TYPES.VALIDATE_KUSPIT_ACCOUNT_REPOSITORY)
    .to(
      withDummies
        ? kuspitAccountLinkingDummyRepository
        : ValidateKuspitAccountHttpRepository,
    ).inSingletonScope();

  container
    .bind<ValidateKuspitAccountCommand>(TYPES.VALIDATE_KUSPIT_ACCOUNT_COMMAND)
    .to(ValidateKuspitAccountCommand)
    .inSingletonScope();

  container
    .bind<PublishReceivedStatusToEventBusOnKuspitAccountLinkingStatusWebSocketMessageSubscription>(
      // eslint-disable-next-line max-len
      TYPES.PUBLISH_RECEIVED_STATUS_TO_EVENT_BUS_ON_KUSPIT_ACCOUNT_LINKING_STATUS_WEB_SOCKET_MESSAGE_SUBSCRIPTION,
    ).to(PublishReceivedStatusToEventBusOnKuspitAccountLinkingStatusWebSocketMessageSubscription)
    .inSingletonScope();

  container
    .bind<UpdateKuspitOnBoardingStepRepository>(TYPES.UPDATE_KUSPIT_ON_BOARDING_STEP_REPOSITORY)
    .to(
      withDummies
        ? UpdateKuspitOnBoardingStepDummyRepository
        : UpdateKuspitOnBoardingStepHttpRepository,
    ).inSingletonScope();

  container
    .bind<UpdateKuspitOnBoardingStepCommand>(TYPES.UPDATE_KUSPIT_ON_BOARDING_STEP_COMMAND)
    .to(UpdateKuspitOnBoardingStepCommand)
    .inSingletonScope();
}
