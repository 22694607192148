// Domain
import {
  ActivateSowosModerateRepository,
} from '../../../domain/repositories/activate-sowos-moderate-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class ActivateSowosModerateDummyRepository
implements ActivateSowosModerateRepository {
  activate(): Promise<void> {
    return Promise.resolve();
  }
}
