import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';
import TYPES from '@/types';
import { ActivateRecurringContributionRepository }
  from '../../../domain/repository';
import { ActivateRecurringContributionEntity }
  from '../../../domain/entities/activate-recurring-contribution-entity';

@Injectable()
export default class ActivateRecurringContributionHttpRepository
implements ActivateRecurringContributionRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly path = `${process.env.VUE_APP_API_URL}/api/v1/app/allianz/activate-recurring-contribution/{ID}/`;

  activate(allianz_account: ActivateRecurringContributionEntity): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.path.replace('{ID}', allianz_account.id), 'PATCH', {
        monthly_collection_day: allianz_account.monthly_collection_day,
        amount: allianz_account.amount,
        periodicity: allianz_account.periodicity,
        confirm_periodicity: allianz_account.confirm_periodicity,
        home_desire: allianz_account.home_desire,
      })
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json()
              .then(({ message }) => {
                reject(new Error(message));
              });
          }
        })
        .catch(reject);
    });
  }
}
