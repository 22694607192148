import { v4 } from 'uuid';

import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';

// Application
import {
  CreateKuspitWithdrawalCommand,
} from '@/modules/my-investment/kuspit/withdrawal/application/commands';
import InvestorGoalScheduledDistributionPocketWithdrawalCommand
  from '@/modules/flagship/investor-goal-scheduled-distribution/sowos-pocket/withdrawal/application/command/investor-goal-scheduled-distribution-pocket-withdrawal-command';

// Domain
import { CreateKuspitWithdrawalDto } from
  '@/modules/my-investment/kuspit/withdrawal/domain/dtos/create-kuspit-withdrawal-dto';
import { ResultKuspitWithdrawalEntity } from
  '@/modules/my-investment/kuspit/withdrawal/domain/entities/result-kuspit-withdrawal-entity';
import { WithdrawalInformation } from '@/modules/my-investment/kuspit/withdrawal/domain/types';
import { ValidateWithdrawalWorkingHoursService } from '@/modules/my-investment/kuspit/withdrawal/domain/services';
import {
  InvestorGoalScheduledDistributionPocketWithdrawalDto,
} from '@/modules/flagship/investor-goal-scheduled-distribution/sowos-pocket/withdrawal/domain/dto/investor-goal-scheduled-distribution-pocket-withdrawal-dto';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class WithdrawalViewModel {
  @Inject(TYPES.CREATE_KUSPIT_WITHDRAWAL_COMMAND)
  private readonly create_kuspit_withdrawal_command!: CreateKuspitWithdrawalCommand;

  @Inject(TYPES.INVESTOR_GOAL_SCHEDULED_DISTRIBUTION_POCKET_WITHDRAWAL_COMMAND)
  // eslint-disable-next-line max-len
  private readonly investor_goal_scheduled_distribution_pocket_withdrawal_command!: InvestorGoalScheduledDistributionPocketWithdrawalCommand;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  readonly i18n_namespace = 'components.kuspit-dashboard.withdrawals';

  readonly status: Array<string> = ['in_process', 'successful', 'failed'];

  private readonly steps = [
    'WithdrawalReminder',
    'WithdrawalInformation',
    'WithdrawalGoalsInformation',
    'WithdrawalConfirmInformation',
    'WithdrawalsConfirmOperation',
  ];

  readonly sections = [0, 1, 2, 3, 4, 5, 6];

  is_loading = false;

  current_step = 0;

  current_component = this.steps[this.current_step];

  section_data = {
    title: '',
    step: null,
  }

  small_screen: null | boolean = null;

  operation_status = '';

  folio_number = '';

  modal_open = false;

  source_account = '';

  destination_account = '';

  has_linked_goals = false;

  show_goals_distribution = false;

  withdrawal_dto = {
    id: '',
    latitude: '',
    longitude: '',
    amount: '',
    concept: '',
    password: '',
  };

  payload_to_save_distribution: InvestorGoalScheduledDistributionPocketWithdrawalDto = {
    total_contribution: '0',
    unassigned_balance: '0',
    investor_goals: [],
  }

  is_there_any_linked_goal = false;

  // eslint-disable-next-line
  private readonly view: any;

  // eslint-disable-next-line
  constructor(view: any) {
    this.view = view;
  }

  translate = (value: string) => (
    i18n.t(`${this.i18n_namespace}.${value}`) as string
  )

  show_operation_status(status_index: number) {
    return this.operation_status === this.status[status_index];
  }

  updateLoadingInfo = (is_loading: boolean) => {
    this.is_loading = is_loading;
  }

  updateGeolocation = (latitude: string, longitude: string) => {
    this.withdrawal_dto.latitude = latitude;
    this.withdrawal_dto.longitude = longitude;
    this.view.$refs.withdrawal_information.withdrawal_information_view_model.initialize(
      latitude, longitude,
    );
    this.nextStep();
  }

  updateMovementDetails = (withdrawal_information: WithdrawalInformation) => {
    this.withdrawal_dto.amount = withdrawal_information.amount_to_withdraw;
    this.withdrawal_dto.concept = withdrawal_information.concept || '';
    this.source_account = withdrawal_information.source_account;
    this.destination_account = withdrawal_information.destination_account;
  }

  confirmOperation = async (password: string) => {
    this.withdrawal_dto.password = password;
    this.withdrawal_dto.id = v4();
    if (ValidateWithdrawalWorkingHoursService.validate()) {
      this.is_loading = true;
      [this.operation_status] = this.status;
      try {
        const kuspit_withdrawal: CreateKuspitWithdrawalDto = {
          ...this.withdrawal_dto,
          amount: parseFloat(this.withdrawal_dto.amount.replace(/[^0-9.]/g, '')),
        };
        const response: ResultKuspitWithdrawalEntity = await this.create_kuspit_withdrawal_command
          .execute(kuspit_withdrawal) as ResultKuspitWithdrawalEntity;
        if (response.completed) {
          if (this.is_there_any_linked_goal) {
            await this.saveDistributionAmongLinkedGoals();
          }
          this.folio_number = response.tracking_key;
          [, this.operation_status] = this.status;
        }
        if (!response.completed) {
          this.folio_number = response.tracking_key;
          [, , this.operation_status] = this.status;
        }
      } catch {
        [, , this.operation_status] = this.status;
      } finally {
        this.is_loading = false;
      }
    } else {
      this.messageNotifier.showErrorNotification(this.translate('error_time_out_service_error'));
      [, , this.operation_status] = this.status;
    }
  }

  // eslint-disable-next-line max-len
  setPayloadToSaveDistribution = async (payload: InvestorGoalScheduledDistributionPocketWithdrawalDto) => {
    this.payload_to_save_distribution = { ...payload };
  }

  saveDistributionAmongLinkedGoals = async () => {
    try {
      // eslint-disable-next-line max-len
      await this.investor_goal_scheduled_distribution_pocket_withdrawal_command.execute(this.payload_to_save_distribution);
      const linked_goals_pocket_event = new CustomEvent('pocket.linked.goal', { detail: { } });
      window.dispatchEvent(linked_goals_pocket_event);
    } catch {
      this.messageNotifier.showErrorNotification(this.translate('error_on_investor_goal_scheduled_distribution_pocket_withdrawal_command'));
    }
  }

  openModal = () => {
    this.modal_open = true;
  }

  nextStep = () => {
    if (this.current_step <= this.steps.length - 1) {
      this.current_step += 1;
    }
  }

  prevStep = () => {
    if (this.current_step === 0) {
      this.modal_open = false;
    }
    if (this.current_step > 0) {
      this.current_step -= 1;
    }
  }

  prevStepWithdrawalInformation = () => {
    if (this.has_linked_goals) {
      this.show_goals_distribution = false;
    }
    this.prevStep();
  }

  setCurrentComponent = (current_step: number) => {
    for (let index = 0; index < this.sections.length - 1; index += 1) {
      if (
        current_step >= this.sections[index]
        && current_step < this.sections[index + 1]
      ) {
        this.section_data.title = this.translate(`steps[${index}]`);
      }
    }
    this.current_component = this.steps[current_step];
  }

  updateWithdrawalInformation = (withdrawal_information: WithdrawalInformation) => {
    this.updateMovementDetails(withdrawal_information);
    this.has_linked_goals = this.view.$refs.withdrawal_goals_distribution
      .withdrawal_goals_distribution_view_model.userHasActiveGoals();
    if (this.has_linked_goals) {
      this.view.$refs.withdrawal_goals_distribution.withdrawal_goals_distribution_view_model
        .setRetirementAmount(withdrawal_information.amount_to_withdraw);
      this.show_goals_distribution = true;
    } else {
      this.nextStep();
    }
  }

  showModalWithdrawalInformation = () => {
    this.show_goals_distribution = false;
  }

  validateIfIsCurrentStep = (index: number) => this.sections[index] === this.current_step
                  && this.operation_status === '';

  validateIfStepWasCompleted = (index: number) => this.sections[index] < this.current_step
                  || this.operation_status !== '';

  reset = () => {
    this.current_step = 0;
    this.operation_status = '';
    this.folio_number = '';
    this.modal_open = false;
    this.show_goals_distribution = false;
  }
}
