import { Container } from 'inversify-props';

import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state-manager/vue-state-manager';
import IdentityVerificationServiceDummyRepository
  from './infrastructure/repositories/identity-verification-service-repository/identity-verification-service-dummy-repository';
import IdentityVerificationServiceHttpRepository
  from './infrastructure/repositories/identity-verification-service-repository/identity-verification-service-http-repository';
import { MatiVerificationDummyRepository, MatiVerificationHttpRepository }
  from './infrastructure/repositories/mati-verification-repository';

// Application
import GetOnBoardingIdentityVerificationServiceQuery
  from './application/queries/get-on-boarding-identity-verification-service-query';
import IdentityVerificationStatusSubscription
  from './application/subscriptions/identity-verification-status-subscription';
import GetOnBoardingIdentityVerificationStatusQuery
  from './application/queries/get-on-boarding-identity-verification-status-query';
import { ResetIdentityVerificationStatus } from './application/services';
import GetMatiVerificationQuery
  from '@/modules/on-boarding/identity-verification/application/queries/get-mati-verification-query';
import RequestManualReviewCommand
  from '@/modules/on-boarding/identity-verification/application/commands/request-manual-review-command';

// Domain
import { StateManager } from './domain/state-manager';
import { IdentityVerificationServiceRepository }
  from './domain/repositories/identity-verification-service-repository';
import { MatiVerificationRepository } from './domain/repositories/mati-verification-repository';

export default function setupIdentityVerification(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.ON_BOARDING_IDENTITY_VERIFICATION_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    // eslint-disable-next-line max-len
    .bind<IdentityVerificationServiceRepository>(TYPES.ON_BOARDING_IDENTITY_VERIFICATION_SERVICE_REPOSITORY)
    .to(
      withDummies
        ? IdentityVerificationServiceDummyRepository
        : IdentityVerificationServiceHttpRepository,
    ).inSingletonScope();

  container
    // eslint-disable-next-line max-len
    .bind<GetOnBoardingIdentityVerificationServiceQuery>(TYPES.GET_ON_BOARDING_IDENTITY_VERIFICATION_SERVICE_QUERY)
    .to(GetOnBoardingIdentityVerificationServiceQuery)
    .inSingletonScope();

  container
    // eslint-disable-next-line max-len
    .bind<IdentityVerificationStatusSubscription>(TYPES.ON_BOARDING_IDENTITY_VERIFICATION_STATUS_SUBSCRIPTION)
    .to(IdentityVerificationStatusSubscription)
    .inSingletonScope();

  container
    // eslint-disable-next-line max-len
    .bind<GetOnBoardingIdentityVerificationStatusQuery>(TYPES.GET_ON_BOARDING_IDENTITY_VERIFICATION_STATUS_QUERY)
    .to(GetOnBoardingIdentityVerificationStatusQuery)
    .inSingletonScope();

  container
    .bind<ResetIdentityVerificationStatus>(TYPES.ON_BOARDING_RESET_IDENTITY_VERIFICATION_STATUS)
    .to(ResetIdentityVerificationStatus)
    .inSingletonScope();

  container
    .bind<MatiVerificationRepository>(TYPES.ON_BOARDING_MATI_VERIFICATION_REPOSITORY)
    .to(
      withDummies
        ? MatiVerificationDummyRepository
        : MatiVerificationHttpRepository,
    ).inSingletonScope();

  container
    // eslint-disable-next-line max-len
    .bind<GetMatiVerificationQuery>(TYPES.GET_ON_BOARDING_MATI_VERIFICATION_QUERY)
    .to(GetMatiVerificationQuery)
    .inSingletonScope();

  container
    .bind<RequestManualReviewCommand>(TYPES.REQUEST_MANUAL_REVIEW_COMMAND)
    .to(RequestManualReviewCommand)
    .inSingletonScope();
}
