import Injectable from '@/modules/shared/domain/di/injectable';
import { OnBoardingUpdateStepRepository }
  from '../../../domain/repositories/on-boarding-update-step-repository';
import { StepEntity } from '../../../domain/entities/step-entity';

@Injectable()
export default class OnBoardingUpdateStepDummyRepository implements OnBoardingUpdateStepRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(step: StepEntity) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const steps_data = JSON.parse(sessionStorage.getItem('steps_data')!);
    const step_index = steps_data.findIndex((item: StepEntity) => item.id === step.id);
    steps_data[step_index] = step;
    sessionStorage.setItem('steps_data', JSON.stringify(steps_data));
    console.log('STEP UPDATED: ', step);

    return Promise.resolve();
  }
}
