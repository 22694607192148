import modal_new_investment from './modal_new_investment.json';
import modal_my_investment from './modal_my_investment.json';
import modal_marketplace from './modal_marketplace.json';
import policy_status_allianz from './policy_status_allianz.json';
import invite_friend_modal from './invite-friend-modal.json';
import invite_friend_success from './invite-friend-success.json';
import invite_friend_error from './invite-friend-error.json';

export default {
  'home-dashboard': {
    ...modal_new_investment,
    ...modal_my_investment,
    ...modal_marketplace,
    ...policy_status_allianz,
    ...invite_friend_modal,
    ...invite_friend_success,
    ...invite_friend_error,
  },
};
