import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import {
  CustomerKuspitCredentialsHttpRepository,
  CustomerKuspitCredentialsDummyRepository,
  CustomerKuspitCredentialsSenderHttpRepository,
  CustomerKuspitCredentialsSenderDummyRepository,
} from './infrastructure/repository';

// Application
import { GetCustomerKuspitCredentialsQuery } from './application/queries';
import { SendCustomerKuspitCredentialsCommand } from './application/commands';

// Domain
import {
  CustomerKuspitCredentialsRepository,
  CustomerKuspitCredentialsSenderRepository,
} from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupCustomerKuspitCredentials(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.CUSTOMER_KUSPIT_CREDENTIALS_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<CustomerKuspitCredentialsRepository>(TYPES.CUSTOMER_KUSPIT_CREDENTIALS_REPOSITORY)
    .to(
      withDummies
        ? CustomerKuspitCredentialsDummyRepository
        : CustomerKuspitCredentialsHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<GetCustomerKuspitCredentialsQuery>(TYPES.GET_CUSTOMER_KUSPIT_CREDENTIALS_QUERY)
    .to(GetCustomerKuspitCredentialsQuery)
    .inSingletonScope();

  container
    .bind<CustomerKuspitCredentialsSenderRepository>(
      TYPES.CUSTOMER_KUSPIT_CREDENTIALS_SENDER_REPOSITORY,
    )
    .to(
      withDummies
        ? CustomerKuspitCredentialsSenderDummyRepository
        : CustomerKuspitCredentialsSenderHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SendCustomerKuspitCredentialsCommand>(TYPES.SEND_CUSTOMER_KUSPIT_CREDENTIALS_COMMAND)
    .to(SendCustomerKuspitCredentialsCommand)
    .inSingletonScope();
}
