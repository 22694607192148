import { Container } from 'inversify-props';
import { SignUpCommand } from './application/commands';
import { RegisterRepository } from './domain/repository';
import { MemoryRegisterRepository } from './infrastructure/repository/memory';
import FetchRegisterRepository from './infrastructure/repository/fetch/FetchRegisterRepository';
import TYPES from './types';

export default function setupRegisterModule(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<SignUpCommand>(TYPES.SIGN_UP_COMMAND)
    .to(SignUpCommand)
    .inSingletonScope();

  container
    .bind<RegisterRepository>(TYPES.REGISTER_REPOSITORY)
    .to(
      withDummies
        ? MemoryRegisterRepository
        : FetchRegisterRepository,
    )
    .inSingletonScope();
}
