import TYPES from '../../types';

// Domain
import {
  CustomerInvestmentProductFundTypeRepository,
} from '@/modules/flagship/customer-investment-product-fund-type/domain/repositories/customer-investment-product-fund-type-repository';
import {
  ModerateIsContractedEntity,
} from '@/modules/flagship/customer-investment-product-fund-type/domain/entities/moderate-is-contracted-entity';
import { ModerateIsContractedStateManager } from '@/modules/flagship/customer-investment-product-fund-type/domain/state/moderate-is-contracted-state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetModerateIsContractedQuery
  extends Query<Promise<ModerateIsContractedEntity>> {
  @Inject(TYPES.CUSTOMER_INVESTMENT_PRODUCT_FUND_TYPE_REPOSITORY)
  private readonly repository!: CustomerInvestmentProductFundTypeRepository;

  @Inject(TYPES.MODERATE_IS_CONTRACTED_STATE_MANAGER)
  private readonly state_manager!: ModerateIsContractedStateManager;

  internalExecute(): Promise<ModerateIsContractedEntity> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.moderate_is_contracted.moderate_is_contracted) {
        resolve(this.state_manager.state.moderate_is_contracted);
      } else {
        this.repository.get_moderate_is_contracted()
          .then((moderate_is_contracted) => {
            this.state_manager.patch({ moderate_is_contracted });
            resolve(this.state_manager.state.moderate_is_contracted);
          })
          .catch(reject);
      }
    });
  }
}
