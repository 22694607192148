import TYPES from '../../types';

// Domain
import { AllianzPolicyIssuanceStatusEntity } from '../../domain/entities/allianz-policy-issuance-status-entity';
import { AllianzPolicyIssuanceStatusRepository } from '../../domain/repository/allianz-policy-issuance-status-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetAllianzPolicyIssuanceStatusQuery
  extends Query<Promise<AllianzPolicyIssuanceStatusEntity>> {
  @Inject(TYPES.ALLIANZ_POLICY_ISSUANCE_STATUS_REPOSITORY)
  private readonly repository!: AllianzPolicyIssuanceStatusRepository;

  internalExecute(): Promise<AllianzPolicyIssuanceStatusEntity> {
    return new Promise((resolve, reject) => {
      this.repository.get()
        .then((allianz_policy_issuance_status) => {
          resolve(allianz_policy_issuance_status);
        })
        .catch(reject);
    });
  }
}
