import TYPES from '../../types';

// Domain
import { LinkSowosWealthModerateEntity } from '../../domain/entities/link-sowos-wealth-moderate-entity';
import { LinkSowosWealthModerateRepository } from '../../domain/repositories/link-sowos-wealth-moderate-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class LinkSowosWealthModerateCommand extends Command<LinkSowosWealthModerateEntity> {
  @Inject(TYPES.LINK_SOWOS_WEALTH_MODERATE_REPOSITORY)
  private readonly repository!: LinkSowosWealthModerateRepository;

  internalExecute(link_sowos_wealth_moderate_entity: LinkSowosWealthModerateEntity): Promise<void> {
    return this.repository.link(link_sowos_wealth_moderate_entity);
  }
}
