import TYPES from '@/modules/shared/types';

// Domain
import {
  CustomInvestorGoalRepository,
} from '@/modules/flagship/custom-investor-goal/domain/repositories/custom-investor-goal-repository';
import {
  CustomInvestorGoalEntity,
} from '@/modules/flagship/custom-investor-goal/domain/entities/custom-investor-goal-entity';
import {
  CustomInvestorGoalDto,
} from '@/modules/flagship/custom-investor-goal/domain/dtos/custom-investor-goal-dto';
import {
  CustomInvestorGoalNameDto,
} from '@/modules/flagship/custom-investor-goal/domain/dtos/custom-investor-goal-name-dto';
import { UpdatePlanCustomGoalDto }
  from '@/modules/flagship/custom-investor-goal/domain/dtos/update-plan-custom-goal-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class CustomInvestorGoalHttpRepository implements CustomInvestorGoalRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/custom-investor-goal/`;

  get(): Promise<CustomInvestorGoalEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(JSON.stringify({
                  status_code: response.status,
                  message_error: message,
                }));
              }
            });
        })
        .catch(reject);
    });
  }

  search(): Promise<Array<CustomInvestorGoalEntity>> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}search-by-customer`)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(JSON.stringify({
                  status_code: response.status,
                  message_error: message,
                }));
              }
            });
        })
        .catch(reject);
    });
  }

  update(custom_investor_goal_dto: CustomInvestorGoalDto): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}create-or-patch/`, 'POST', custom_investor_goal_dto)
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => {
                reject(new Error(message));
              },
            );
          }
        })
        .catch(reject);
    });
  }

  updateName(custom_investor_goal_dto: CustomInvestorGoalNameDto): Promise<void> {
    const { id, ...payload } = custom_investor_goal_dto;
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}${id!}/`, 'PATCH', payload)
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => {
                reject(new Error(message));
              },
            );
          }
        })
        .catch(reject);
    });
  }

  create(custom_investor_goal_dto: CustomInvestorGoalDto): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}`, 'POST', custom_investor_goal_dto)
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => {
                reject(new Error(message));
              },
            );
          }
        })
        .catch(reject);
    });
  }

  delete(custom_investor_goal_id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint.replace('v1', 'v2')}${custom_investor_goal_id}/`, 'DELETE')
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => {
                reject(new Error(message));
              },
            );
          }
        })
        .catch(reject);
    });
  }

  transfer(customer_investor_goal_id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}unassigned-balance-transfer/`, 'POST', {
        custom_investor_goal_id: customer_investor_goal_id,
      })
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => {
                reject(new Error(message));
              },
            );
          }
        })
        .catch(reject);
    });
  }

  updatePlan(update_plan_dto: UpdatePlanCustomGoalDto): Promise<void> {
    return new Promise((resolve, reject) => {
      const { custom_investor_goal, ...payload } = update_plan_dto;
      this.http_connector.execute(`${this.endpoint}${custom_investor_goal}/investment-plan/`, 'PATCH', payload)
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => reject(new Error(message)),
            );
          }
        })
        .catch(reject);
    });
  }
}
