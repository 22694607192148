import TYPES from '../../types';

// Domain
import {
  SpecialContributionRepository,
} from '../../domain/repository/special-contribution-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetNotifySpecialContributionQuery extends Query<Promise<void>> {
  @Inject(TYPES.SPECIAL_CONTRIBUTION_REPOSITORY)
  private readonly repository!: SpecialContributionRepository;

  internalExecute(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.repository.notify()
        .then((data) => {
          resolve(data);
        })
        .catch(reject);
    });
  }
}
