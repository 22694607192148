import TYPES from '../../types';

// Domain
import {
  CustomerInvestmentProductFundTypeRepository,
} from '@/modules/flagship/customer-investment-product-fund-type/domain/repositories/customer-investment-product-fund-type-repository';
import {
  CustomerInvestmentProductFundTypeEntity,
} from '@/modules/flagship/customer-investment-product-fund-type/domain/entities/customer-investment-product-fund-type-entity';
import {
  QueryInvestmentProductsContractedEntity,
} from '@/modules/flagship/customer-investment-product-fund-type/domain/entities/query-investment-products-contracted-entity';
import { CustomerInvestmentProductFundTypeStateManager } from '@/modules/flagship/customer-investment-product-fund-type/domain/state/customer-investment-product-fund-type-state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class SearchInvestmentProductsContractedByCustomerQuery
  extends Query<Promise<Array<CustomerInvestmentProductFundTypeEntity>>> {
  @Inject(TYPES.CUSTOMER_INVESTMENT_PRODUCT_FUND_TYPE_REPOSITORY)
  private readonly repository!: CustomerInvestmentProductFundTypeRepository;

  @Inject(TYPES.CUSTOMER_INVESTMENT_PRODUCT_FUND_TYPE_STATE_MANAGER)
  private readonly state_manager!: CustomerInvestmentProductFundTypeStateManager;

  internalExecute(query: QueryInvestmentProductsContractedEntity):
    Promise<Array<CustomerInvestmentProductFundTypeEntity>> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded) {
        resolve(this.state_manager.state.items);
      } else {
        this.repository.search_investment_products_contracted_by_customer(query)
          .then((items) => {
            this.state_manager.patch({ items, loaded: true });
            resolve(items);
          })
          .catch(reject);
      }
    });
  }
}
