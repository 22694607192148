import fixtures from './fixtures.json';

// Domain
import { CustomerInvestorGoalBalanceRepository } from '../../../domain/repositories/customer-investor-goal-balance-repository';
import { CustomerInvestorGoalBalanceEntity } from '../../../domain/entities/customer-investor-goal-balance-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CustomerInvestorGoalBalanceDummyRepository
implements CustomerInvestorGoalBalanceRepository {
  get(investment_product_id: string): Promise<CustomerInvestorGoalBalanceEntity> {
    return Promise.resolve(fixtures);
  }
}
