import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import {
  SourceIncomeHttpRepository,
  SourceIncomeDummyRepository,
} from './infrastructure/repository';

// Application
import { SearchSourcesIncomesQuery } from './application/queries';

// Domain
import { SourceIncomeRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupSourceIncome(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.CATALOG_ALLIANZ_SOURCE_INCOME_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<SourceIncomeRepository>(TYPES.CATALOG_ALLIANZ_SOURCE_INCOME_REPOSITORY)
    .to(
      withDummies
        ? SourceIncomeDummyRepository
        : SourceIncomeHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchSourcesIncomesQuery>(TYPES.SEARCH_CATALOG_ALLIANZ_SOURCE_INCOME_QUERY)
    .to(SearchSourcesIncomesQuery)
    .inSingletonScope();
}
