// Domain
import {
  AccessMoreStrategiesRepository,
} from '@/modules/my-investment/allianz/access-more-strategies/domain/repositories/access-more-strategies-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class AccessMoreStrategiesDummyRepository
implements AccessMoreStrategiesRepository {
  create(): Promise<void> {
    return Promise.resolve();
  }
}
