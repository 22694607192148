import Vue from 'vue';
// Domain
import { RetirementInvestorGoalStateManager } from '@/modules/flagship/retirement-investor-goal/domain/state/retirement-investor-goal-state-manager';
import RetirementInvestorGoalState
  from '@/modules/flagship/retirement-investor-goal/domain/state/retirement-investor-goal-state';
import Injectable from '@/modules/shared/domain/di/injectable';
import Observer from '@/modules/shared/domain/observer/observer';

@Injectable()
export default class RetirementInvestorGoalVueStateManager
implements RetirementInvestorGoalStateManager {
  // eslint-disable-next-line max-len
  private _state: RetirementInvestorGoalState = Vue.observable<RetirementInvestorGoalState>(new RetirementInvestorGoalState());

  private readonly observers: Observer[] = [];

  get state(): RetirementInvestorGoalState {
    return this._state;
  }

  set state(value: RetirementInvestorGoalState) {
    this._state = value;
    this.notifyAll();
  }

  patch(state: Partial<RetirementInvestorGoalState>) {
    type Keys = keyof RetirementInvestorGoalState;
    Object.entries(state).forEach(([key, value]) => {
      const accessor = key as Keys;

      if (value !== undefined) {
        // eslint-disable-next-line
        // @ts-ignore @typescript-eslint/ban-ts-ignore
        this.state[accessor] = value;
      }
    });
  }

  notifyAll() {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer) {
    this.observers.push(observer);
  }
}
