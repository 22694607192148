import i18n from '@/vue-app/plugins/i18n';

import Injectable from '../../domain/di/injectable';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

@Injectable()
export class VueI18nTranslator implements Translator {
  translate(message: string, values?: Values): string {
    if (values) {
      return i18n.t(message, values) as string;
    }
    return i18n.t(message) as string;
  }
}
