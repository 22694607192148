import TYPES from '../../types';

// Domain
import { AllianzFundEntity } from '../../domain/entities/allianz-fund-entity';
import { AllianzFundRepository } from '../../domain/repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetAllianzFundQuery extends Query<Array<AllianzFundEntity>> {
  @Inject(TYPES.ALLIANZ_FUND_REPOSITORY)
  private readonly repository!: AllianzFundRepository;

  internalExecute() {
    return this.repository.get();
  }
}
