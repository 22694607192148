import {
  InvestmentTypeDto,
} from '@/modules/my-investment/allianz/investment-type/domain/dtos/investment-type-dto';

export default class State {
  current_score = '';

  investment_type: InvestmentTypeDto = {
    investment_type: '',
  }
}
