import TYPES from '../../types';

// Domain
import { AllianzAccountEntity } from '../../domain/entities/allianz-account-entity';
import { AllianzAccountQueryEntity } from '../../domain/entities/allianz-account-query-entity';
import { AllianzAccountRepository } from '../../domain/repository/allianz-account-repository';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetAllianzAccountQuery extends Query<Promise<AllianzAccountEntity>> {
  @Inject(TYPES.ALLIANZ_ACCOUNT_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.ALLIANZ_ACCOUNT_REPOSITORY)
  private readonly repository!: AllianzAccountRepository;

  internalExecute(account_query: AllianzAccountQueryEntity): Promise<AllianzAccountEntity> {
    return new Promise((resolve, reject) => {
      if (!this.state_manager.state.loaded || account_query.reload) {
        this.state_manager.patch({ is_loading: true });
        this.repository.execute(account_query.customer_id)
          .then((account) => {
            this.state_manager.patch({ account, loaded: true });
            resolve(account);
          })
          .catch(reject)
          .finally(() => {
            this.state_manager.patch({ is_loading: false });
          });
      } else {
        resolve(this.state_manager.state.account);
      }
    });
  }
}
