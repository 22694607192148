import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import { ActivityHttpRepository, ActivityDummyRepository } from './infrastructure/repository';

// Application
import { SearchActivitiesQuery } from './application/queries';

// Domain
import { ActivityRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupActivity(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.CATALOG_KUSPIT_ACTIVITY_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<ActivityRepository>(TYPES.CATALOG_KUSPIT_ACTIVITY_REPOSITORY)
    .to(
      withDummies
        ? ActivityDummyRepository
        : ActivityHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchActivitiesQuery>(TYPES.SEARCH_CATALOG_KUSPIT_ACTIVITY_QUERY)
    .to(SearchActivitiesQuery)
    .inSingletonScope();
}
