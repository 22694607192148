import i18n from '@/vue-app/plugins/i18n';
import TYPES from '@/types';
import { v4 } from 'uuid';

// Application
import InvestmentSuggestionQuery
  from '@/modules/investment-suggestion/application/queries/investment-suggestion-query';
import InvestmentSuggestionCommand
  from '@/modules/investment-suggestion/application/commands/investment-suggestion-command';

// Domain
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Inject from '@/modules/shared/domain/di/inject';
import {
  InvestmentSuggestionDto,
} from '@/modules/investment-suggestion/domain/dtos/investment-suggestion-dto';

export default class InvestmentSuggestionModalViewModel {
  @Inject(TYPES.GET_INVESTMENT_SUGGESTION_QUERY)
  private readonly get_investment_suggestion_query!: InvestmentSuggestionQuery;

  @Inject(TYPES.CREATE_INVESTMENT_SUGGESTION_COMMAND)
  private readonly create_investment_suggestion_command!: InvestmentSuggestionCommand;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.investment-suggestion';

  is_open = false;

  show_wealth_modal = false;

  show_pocket_modal = false;

  show_flagship_modal = false;

  show_pocket_is_on_maintenance_dialog = false;

  product_is_unavailable_to_hire = false;

  product_unavailable_to_hire = '';

  private investment_suggestion_dto: InvestmentSuggestionDto = {
    id: v4(),
    investment_suggestion_analytic_id: '',
    action: '',
  }

  available_pocket_services = 'available';

  translate = (value: string) => (
    i18n.t(`${this.i18n_namespace}.${value}`) as string
  )

  obtainInvestmentSuggestion = async () => {
    try {
      const investment_suggestion = await this.get_investment_suggestion_query.execute();

      if (investment_suggestion) {
        this.is_open = investment_suggestion.must_be_shown;
        this.investment_suggestion_dto.investment_suggestion_analytic_id = investment_suggestion.id;
      }
    } catch {
      this.message_notifier.showErrorNotification(
        `${i18n.tc(`${this.i18n_namespace}.message_error`)}`,
      );
    }
  }

  showModalWealth = () => {
    this.show_wealth_modal = true;
    this.is_open = false;
  }

  showModalPocket = () => {
    if (this.available_pocket_services === 'available') {
      this.show_pocket_modal = true;
    } else {
      this.show_pocket_is_on_maintenance_dialog = true;
    }
    this.is_open = false;
  }

  showModalFlagship = () => {
    this.show_flagship_modal = true;
    this.is_open = false;
  }

  showUnavailableProduct = (product: string) => {
    this.is_open = false;
    this.product_is_unavailable_to_hire = true;
    this.product_unavailable_to_hire = product;
  }

  createInvestmentSuggestionAction = async () => {
    try {
      this.investment_suggestion_dto.action = 'close';
      await this.create_investment_suggestion_command.execute(this.investment_suggestion_dto);
      return true;
    } catch {
      this.message_notifier.showErrorNotification(
        `${i18n.tc(`${this.i18n_namespace}.message_error`)}`,
      );
      return false;
    } finally {
      this.is_open = false;
    }
  }
}
