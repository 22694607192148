// Infrastructure
import fixture_allianz_account_statements from './fixture-allianz-account-statements.json';
import fixture_allianz_account_statement_downloaded from './fixture-allianz-account-statement-downloaded.json';

// Domain
import { AllianzAccountStatementRepository } from '@/modules/my-investment/allianz/allianz-account-statement/domain/repository';
import { AllianzAccountStatementEntity } from '@/modules/my-investment/allianz/allianz-account-statement/domain/entities/allianz-account-statement-entity';
import {
  AllianzDownloadAccountStatementDto,
} from '@/modules/my-investment/allianz/allianz-account-statement/domain/dtos/allianz-download-account-statement-dto';
import {
  AllianzAccountStatementDownloadedEntity,
} from '@/modules/my-investment/allianz/allianz-account-statement/domain/entities/allianz-account-statement-downloaded-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class AllianzAccountStatementDummyRepository implements
  AllianzAccountStatementRepository {
  search(): Promise<Array<AllianzAccountStatementEntity>> {
    return Promise.resolve(fixture_allianz_account_statements);
  }

  download(download_account_statement: AllianzDownloadAccountStatementDto):
    Promise<AllianzAccountStatementDownloadedEntity> {
    return Promise.resolve(fixture_allianz_account_statement_downloaded);
  }
}
