import TYPES from '@/types';

// DOMAIN
import { Values } from '@/modules/shared/domain/i18n/types';
import Translator from '@/modules/shared/domain/i18n/translator';
import Inject from '@/modules/shared/domain/di/inject';

export default class OnboardingHeaderViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace =
    'components.header.unauthenticated';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);
}
