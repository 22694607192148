import Injectable from '@/modules/shared/domain/di/injectable';
import { SearchCurrencyTypesRepository } from '../../../domain/repositories/search-currency-types-repository';
import { CurrencyTypeEntity } from '../../../domain/entities/currency-type-entity';
import fixtures from './fixtures.json';

@Injectable()
export default class SearchCurrencyTypesDummyRepository implements SearchCurrencyTypesRepository {
  execute(): Promise<Array<CurrencyTypeEntity>> {
    return Promise.resolve(fixtures);
  }
}
