import TYPES from '@/modules/shared/types';

// Domain
import {
  CustomerInvestorGoalBalanceRepository,
} from '../../domain/repositories/customer-investor-goal-balance-repository';
import {
  CustomerInvestorGoalBalanceEntity,
} from '../../domain/entities/customer-investor-goal-balance-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class CustomerInvestorGoalBalanceHttpRepository
implements CustomerInvestorGoalBalanceRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/customer-investor-goal-balance/investment-product/{investment_product_id}/`;

  get(investment_product_id: string): Promise<CustomerInvestorGoalBalanceEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint.replace('{investment_product_id}', investment_product_id))
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(JSON.stringify({
                  status_code: response.status,
                  message_error: message,
                }));
              }
            });
        })
        .catch(reject);
    });
  }
}
