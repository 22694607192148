import TYPES from '../../types';

// Domain
import {
  FinalInvestmentDateCalculatorDto,
} from '../../domain/dtos/final-investment-date-calculator-dto';
import {
  FinalInvestmentDateCalculatorEntity,
} from '../../domain/entities/final-investment-date-calculator-entity';
import {
  FinalInvestmentDateCalculatorRepository,
} from '../../domain/repositories/final-investment-date-calculator-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class GetFinalInvestmentDateCalculatorQuery extends
  Query<Promise<FinalInvestmentDateCalculatorEntity>> {
  @Inject(TYPES.FINAL_INVESTMENT_DATE_CALCULATOR_REPOSITORY)
  private readonly repository!: FinalInvestmentDateCalculatorRepository;

  internalExecute(final_investment_date_dto: FinalInvestmentDateCalculatorDto):
    Promise<FinalInvestmentDateCalculatorEntity> {
    return new Promise((resolve, reject) => {
      this.repository.get_final_investment_date(final_investment_date_dto)
        .then((data) => {
          resolve(data);
        })
        .catch(reject);
    });
  }
}
