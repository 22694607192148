import Vue from 'vue';

// Domain
import {
  IncompleteProfileStateManager,
} from '@/modules/flagship/investor-profile/incomplete-profile/domain/state/incomplete-profile-state-manager';
import IncompleteProfileState
  from '@/modules/flagship/investor-profile/incomplete-profile/domain/state/incomplete-profile-state';
import Injectable from '@/modules/shared/domain/di/injectable';
import Observer from '@/modules/shared/domain/observer/observer';

@Injectable()
export default class IncompleteProfileVueStateManager implements IncompleteProfileStateManager {
  // eslint-disable-next-line max-len
  private _state: IncompleteProfileState = Vue.observable<IncompleteProfileState>(new IncompleteProfileState());

  private readonly observers: Observer[] = [];

  get state(): IncompleteProfileState {
    return this._state;
  }

  set state(value: IncompleteProfileState) {
    this._state = value;
    this.notifyAll();
  }

  patch(state: Partial<IncompleteProfileState>) {
    type Keys = keyof IncompleteProfileState;
    Object.entries(state).forEach(([key, value]) => {
      const accessor = key as Keys;

      if (value !== undefined) {
        // eslint-disable-next-line
        // @ts-ignore @typescript-eslint/ban-ts-ignore
        this.state[accessor] = value;
      }
    });
  }

  notifyAll() {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer) {
    this.observers.push(observer);
  }
}
