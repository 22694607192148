import verifying from './verifying.json';
import confirm from './confirm.json';
import verifyNip from './verify-nip.json';

export default {
  'verify-identity': {
    title: 'Verifica tu identidad',
    title_update: 'Actualiza tu INE',
    check: 'Verificación',
    explanation: 'Para verificar tu identidad, utilizaremos la tecnología de META MAP. ',
    explanation_update: 'Para actualizar tu INE, utilizaremos la tecnología de META MAP. ',
    you_need: 'Por favor ten a la mano:',
    official_identification: 'Credencial para votar vigente.',
    phone_with_camera_access: 'Teléfono móvil con acceso a cámara.',
    when_ready_click_verify: 'Cuando te encuentres listo, haz click en “Verifícame”.',
    begin: 'Continuar',
    errors: {
      cant_update: 'Hubo un error al continuar',
      cant_get_mati_verification: 'No pudimos obtener información del estatus de verificación',
    },
    alts: {
      icon_check_alt: 'Icono de círculo con una palomita en medio.',
      icon_credential: 'Icono pequeño que simula una identificación oficial.',
      icon_phone: 'Icono pequeño que simula un teléfono celular.',
    },
    ...verifying,
    ...confirm,
    ...verifyNip,
  },
};
