import TYPES from '../../types';

// Domain
import { PositionEntity } from '../../domain/entities/position-entity';
import {
  CustomerKuspitPositionDetailsRepository,
} from '../../domain/repository/customer-kuspit-position-details-repository';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetCustomerKuspitPositionDetailsQuery extends Query<Promise<PositionEntity>> {
  @Inject(TYPES.CUSTOMER_KUSPIT_POSITION_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.CUSTOMER_KUSPIT_POSITION_DETAILS_REPOSITORY)
  private readonly repository!: CustomerKuspitPositionDetailsRepository;

  internalExecute(): Promise<PositionEntity> {
    return new Promise((resolve, reject) => {
      if (!this.state_manager.state.position_details_loaded) {
        this.repository.execute()
          .then((position_details) => {
            this.state_manager.patch({ position_details, position_details_loaded: true });
            resolve(position_details);
          })
          .catch(reject);
      } else {
        resolve(this.state_manager.state.position_details);
      }
    });
  }
}
