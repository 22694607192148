import { Container } from 'inversify';
import TYPES from './types';

// Infrastructure
import InvestorGoalScheduledDistributionWealthWithdrawalDummyRepository
  from './infrastructure/repositories/investor-goal-scheduled-distribution-wealth-withdrawal-dummy-repository/investor-goal-scheduled-distribution-wealth-withdrawal-dummy-repository';
import InvestorGoalScheduledDistributionWealthWithdrawalHttpRepository
  from './infrastructure/repositories/investor-goal-scheduled-distribution-wealth-withdrawal-http-repository';

// Application
import InvestorGoalScheduledDistributionWealthWithdrawalCommand
  from './application/command/investor-goal-scheduled-distribution-wealth-withdrawal-command';

// Domain
import {
  InvestorGoalScheduledDistributionWealthWithdrawalRepository,
} from './domain/repositories/investor-goal-scheduled-distribution-wealth-withdrawal-repository';

// eslint-disable-next-line max-len
export default function setupInvestorGoalScheduledDistributionWealthWithdrawal(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<InvestorGoalScheduledDistributionWealthWithdrawalRepository>(TYPES
      .INVESTOR_GOAL_SCHEDULED_DISTRIBUTION_WEALTH_WITHDRAWAL_REPOSITORY)
    .to(
      withDummies
        ? InvestorGoalScheduledDistributionWealthWithdrawalDummyRepository
        : InvestorGoalScheduledDistributionWealthWithdrawalHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<InvestorGoalScheduledDistributionWealthWithdrawalCommand>(TYPES
      .INVESTOR_GOAL_SCHEDULED_DISTRIBUTION_WEALTH_WITHDRAWAL_COMMAND)
    .to(InvestorGoalScheduledDistributionWealthWithdrawalCommand)
    .inSingletonScope();
}
