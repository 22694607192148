// Domain
import { UploadFileAccountStatusRepository } from '../../../domain/repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class UploadFileAccountStatusDummyRepository
implements UploadFileAccountStatusRepository {
  upload(): Promise<void> {
    return Promise.resolve();
  }
}
