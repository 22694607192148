import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';

// Application
import EditRetirementFundGoalPlanCurrentPlanService
  from './application/services/edit-retirement-fund-goal-plan-current-plan-service';
import EditRetirementFundGoalPlanCurrentAmountService
  from './application/services/edit-retirement-fund-goal-plan-current-amount-service';
import EditRetirementFundGoalPlanRetirementAgeService
  from './application/services/edit-retirement-fund-goal-plan-retirement-age-service';
import EditRetirementFundGoalPlanDefineByService
  from './application/services/edit-retirement-fund-goal-plan-define-by-service';

// Domain
import { StateManager } from './domain/state/state-manager';

export default function setupEditRetirementFundGoalPlan(container: Container) {
  container
    .bind<StateManager>(TYPES.EDIT_RETIREMENT_FUND_GOAL_PLAN_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<EditRetirementFundGoalPlanCurrentPlanService>(TYPES
      .EDIT_RETIREMENT_FUND_GOAL_PLAN_CURRENT_PLAN_SERVICE)
    .to(EditRetirementFundGoalPlanCurrentPlanService)
    .inSingletonScope();

  container
    .bind<EditRetirementFundGoalPlanCurrentAmountService>(TYPES
      .EDIT_RETIREMENT_FUND_GOAL_PLAN_CURRENT_AMOUNT_SERVICE)
    .to(EditRetirementFundGoalPlanCurrentAmountService)
    .inSingletonScope();

  container
    .bind<EditRetirementFundGoalPlanRetirementAgeService>(TYPES
      .EDIT_RETIREMENT_FUND_GOAL_PLAN_RETIREMENT_AGE_SERVICE)
    .to(EditRetirementFundGoalPlanRetirementAgeService)
    .inSingletonScope();

  container
    .bind<EditRetirementFundGoalPlanDefineByService>(TYPES
      .EDIT_RETIREMENT_FUND_GOAL_PLAN_DEFINE_BY_SERVICE)
    .to(EditRetirementFundGoalPlanDefineByService)
    .inSingletonScope();
}
