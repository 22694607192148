import { UnauthenticatedHeaderType } from '@/vue-app/types/layout';
import { StateType } from './types';

function setLayoutHeader(state: StateType, header: UnauthenticatedHeaderType) {
  state.header = header;
}

export default {
  setLayoutHeader,
};
