import TYPES from '../../types';

// Domain
import { UpdatePersonRepository } from '../../domain/repository/update-person-repository';
import { UpdatePersonDto } from '../../domain/dtos/update-person-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UpdateMyInvestmentPersonCommand extends Command<UpdatePersonDto> {
  @Inject(TYPES.UPDATE_INVESTMENT_PERSON_REPOSITORY)
  private readonly repository!: UpdatePersonRepository;

  internalExecute(person: UpdatePersonDto) {
    return this.repository.update(person);
  }
}
