import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import EmploymentSituationDummyRepository
  from '@/modules/flagship/catalogs/employment-situation/infraestructure/repositories/employment-situation-dummy-repository/employment-situation-dummy-repository';
import EmploymentSituationHttpRepository
  from '@/modules/flagship/catalogs/employment-situation/infraestructure/repositories/employment-situation-http-repository';
import EmploymentSituationVueStateManager
  from '@/modules/flagship/catalogs/employment-situation/infraestructure/state/employment-situation-vue-state-manager';

// Application
import GetEmploymentSituationQuery
  from '@/modules/flagship/catalogs/employment-situation/application/queries/get-employment-situation-query';

// Domain
import {
  EmploymentSituationRepository,
} from '@/modules/flagship/catalogs/employment-situation/domain/repositories/employment-situation-repository';
import {
  EmploymentSituationStateManager,
} from '@/modules/flagship/catalogs/employment-situation/domain/state/employment-situation-state-manager';

export default function setupEmploymentSituation(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<EmploymentSituationStateManager>(TYPES.EMPLOYMENT_SITUATION_STATE_MANAGER)
    .to(EmploymentSituationVueStateManager)
    .inSingletonScope();

  container
    .bind<EmploymentSituationRepository>(
      TYPES.EMPLOYMENT_SITUATION_REPOSITORY,
    )
    .to(
      withDummies
        ? EmploymentSituationDummyRepository
        : EmploymentSituationHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetEmploymentSituationQuery>(
      TYPES.GET_EMPLOYMENT_SITUATION_QUERY,
    )
    .to(GetEmploymentSituationQuery)
    .inSingletonScope();
}
