import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import RetirementInvestorGoalCalculatorDummyRepository
  from './infrastructure/repositories/retirement-investor-goal-calculator-dummy-repository/retirement-investor-goal-calculator-dummy-repository';
import RetirementInvestorGoalCalculatorHttpRepository
  from './infrastructure/repositories/retirement-investor-goal-calculator-http-repository';

// Application
import GetRetirementInvestorGoalCalculatorQuery
  from './application/queries/get-retirement-investor-goal-calculator-query';

// Domain
import { RetirementInvestorGoalCalculatorRepository }
  from './domain/repositories/retirement-investor-goal-calculator-repository';

export default function setupRetirementFundInvestorGoalCalculator(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<RetirementInvestorGoalCalculatorRepository>(TYPES
      .RETIREMENT_INVESTOR_GOAL_CALCULATOR_REPOSITORY)
    .to(withDummies
      ? RetirementInvestorGoalCalculatorDummyRepository
      : RetirementInvestorGoalCalculatorHttpRepository)
    .inSingletonScope();

  container.bind<GetRetirementInvestorGoalCalculatorQuery>(TYPES
    .GET_RETIREMENT_INVESTOR_GOAL_CALCULATOR_QUERY)
    .to(GetRetirementInvestorGoalCalculatorQuery)
    .inSingletonScope();
}
