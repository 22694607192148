import Vue from 'vue';

// Domain
import { requiredRule } from '@/vue-app/utils/form-rules';

export default class WithdrawalConfirmOperationViewModel {
  private readonly view: Vue;

  is_loading = false;

  is_valid_form = false;

  inputs = {
    password: '',
  };

  input_rules = {
    password: [requiredRule],
  };

  constructor(view: Vue) {
    this.view = view;
  }

  reset = () => {
    this.inputs.password = '';
  }

  cancel = () => {
    this.view.$emit('endProcess');
  }

  nextStep = () => {
    this.view.$emit('nextStep', this.inputs.password);
  }
}
