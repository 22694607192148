import fixtures from './fixture.json';

// Domain
import Injectable from '@/modules/shared/domain/di/injectable';
import {
  FixedTimeCalculationsRepository,
} from '@/modules/flagship/emergency-fund-calculations/fixed-time-calculation/domain/repositories/fixed-time-calculations-repository';

@Injectable()
export default class FixedTimeCalculationDummyRepository
implements FixedTimeCalculationsRepository {
  calculate(): Promise<any> {
    return Promise.resolve(fixtures);
  }
}
