import TYPES from '../../types';

// Domain
import { TransactionTypeEntity } from '../../domain/entities/transaction-type-entity';
import { SearchTransactionTypeRepository } from '../../domain/repositories/search-transaction-type-repository';
import { TransactionTypeStateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class SearchTransactionTypesQuery extends
  Query<Promise<Array<TransactionTypeEntity>>> {
  @Inject(TYPES.TRANSACTION_TYPES_STATE_MANAGER)
  private readonly state_manager!: TransactionTypeStateManager;

  @Inject(TYPES.SEARCH_TRANSACTION_TYPES_REPOSITORY)
  private readonly repository!: SearchTransactionTypeRepository;

  internalExecute(): Promise<Array<TransactionTypeEntity>> {
    return new Promise((resolve, reject) => {
      if (!this.state_manager.state.items.length) {
        this.repository.execute()
          .then((items) => {
            this.state_manager.patch({ items });
            resolve(items);
          })
          .catch(reject);
      } else {
        resolve(this.state_manager.state.items);
      }
    });
  }
}
