import TYPES from '../../types';

// Domain
import { Answer }
  from '@/modules/flagship/risk-tolerance-questions/domain/entities/risk-tolerance-questions-entity';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class InvestorGoalRiskToleranceAnswersService {
  @Inject(TYPES.INVESTOR_GOAL_RISK_TOLERANCE_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  setInvestorGoalRiskToleranceAnswers(answers: Array<Answer>) {
    this.state_manager.state.selected_answers = [...answers];
  }

  getInvestorGoalRiskToleranceAnswers(): Array<Answer> {
    return this.state_manager.state.selected_answers;
  }
}
