import TYPES from '@/modules/shared/types';

// Domain
import {
  UpdateKuspitOnBoardingStepRepository,
} from '@/modules/my-investment/kuspit/kuspit-account-linking/domain/repositories/update-kuspit-on-boarding-step-repository';
import {
  OnBoardingStepKuspitAccountEntity,
} from '@/modules/my-investment/kuspit/kuspit-account-linking/domain/entity/on-boarding-step-kuspit-account-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
class UpdateKuspitOnBoardingStepHttpRepository implements UpdateKuspitOnBoardingStepRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/investment/on-boarding-step/{STEP_ID}/`;

  update(on_boarding_step_entity: OnBoardingStepKuspitAccountEntity): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint.replace('{STEP_ID}', on_boarding_step_entity.id!), 'PATCH', {
        current_step: on_boarding_step_entity.current_step,
        investment_provider_id: on_boarding_step_entity.investment_provider_id,
        payload: on_boarding_step_entity.payload,
      })
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => {
                reject(new Error(message));
              },
            );
          }
        })
        .catch(reject);
    });
  }
}

export default UpdateKuspitOnBoardingStepHttpRepository;
