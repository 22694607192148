import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import CustomGoalTypesDummyRepository
  from '@/modules/flagship/catalogs/custom-goal-types/infraestructure/repositories/custom-goal-types-dummy-repository./custom-goal-types-dummy-repository';
import CustomGoalTypesHttpRepository
  from '@/modules/flagship/catalogs/custom-goal-types/infraestructure/repositories/custom-goal-types-http-repository';
import CustomGoalTypesVueStateManager
  from '@/modules/flagship/catalogs/custom-goal-types/infraestructure/state/custom-goal-types-vue-state-manager';

// Application
import GetCustomGoalTypesQuery
  from '@/modules/flagship/catalogs/custom-goal-types/application/queries/get-custom-goal-types-query';

// Domain
import {
  CustomGoalTypesRepository,
} from '@/modules/flagship/catalogs/custom-goal-types/domain/repositories/custom-goal-types-repository';
import { CustomGoalTypesStateManager } from '@/modules/flagship/catalogs/custom-goal-types/domain/state/custom-goal-types-state-manager';

export default function setupCustomGoalTypes(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<CustomGoalTypesStateManager>(TYPES.CUSTOM_GOAL_TYPES_STATE_MANAGER)
    .to(CustomGoalTypesVueStateManager)
    .inSingletonScope();
  container
    .bind<CustomGoalTypesRepository>(
      TYPES.CUSTOM_GOAL_TYPES_REPOSITORY,
    ).to(
      withDummies
        ? CustomGoalTypesDummyRepository
        : CustomGoalTypesHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetCustomGoalTypesQuery>(
      TYPES.GET_CUSTOM_GOAL_TYPES_QUERY,
    ).to(GetCustomGoalTypesQuery)
    .inSingletonScope();
}
