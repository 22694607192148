import TYPES from '../../types';

// Domain
import { CreateKuspitWithdrawalRepository } from '../../domain/repository/create-kuspit-withdrawal-repository';
import { CreateKuspitWithdrawalDto } from '../../domain/dtos/create-kuspit-withdrawal-dto';
import { ResultKuspitWithdrawalEntity } from '../../domain/entities/result-kuspit-withdrawal-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CreateKuspitWithdrawalCommand extends Command<CreateKuspitWithdrawalDto> {
  @Inject(TYPES.CREATE_KUSPIT_WITHDRAWAL_REPOSITORY)
  private readonly repository!: CreateKuspitWithdrawalRepository;

  internalExecute(kuspit_withdrawal: CreateKuspitWithdrawalDto):
    Promise<ResultKuspitWithdrawalEntity> {
    return this.repository.create(kuspit_withdrawal);
  }
}
