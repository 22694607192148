import { Container } from 'inversify-props';
import setupEditEmergencyFundGoalPlan from './edit-emergency-fund-goal-plan/setup';
import setupEditCustomGoalPlan from './edit-custom-goal-plan/setup';
import setupEditRetirementFundGoalPlan from './edit-retirement-fund-goal-plan/setup';

export default function setupEditPlanGoals(container: Container) {
  setupEditEmergencyFundGoalPlan(container);
  setupEditCustomGoalPlan(container);
  setupEditRetirementFundGoalPlan(container);
}
