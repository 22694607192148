import TYPES from '../../types';

// Domain
import { ResourceOriginEntity } from '../../domain/entities/resource-origin-entity';
import { ResourceOriginRepository } from '../../domain/repository/resource-origin-repository';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class SearchResourcesOriginsQuery extends Query<Array<ResourceOriginEntity>> {
  @Inject(TYPES.CATALOG_ALLIANZ_RESOURCE_ORIGIN_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.CATALOG_ALLIANZ_RESOURCE_ORIGIN_REPOSITORY)
  private readonly repository!: ResourceOriginRepository;

  internalExecute() {
    if (!this.state_manager.state.loaded) {
      this.repository.execute()
        .then((items) => {
          this.state_manager.patch({ items, loaded: true });
        });
    }

    return this.state_manager.state.items;
  }
}
