
















































import { Component, Vue } from 'vue-property-decorator';
import { currencyFormat } from '@/vue-app/utils/currency';
import PerformanceViewModel from '../../view-models/components/kuspit-dashboard/performance-view-model';

@Component({
  name: 'Performance',
  filters: { currencyFormat },
})
export default class Performance extends Vue {
  performance_view_model = Vue.observable(new PerformanceViewModel());
}
