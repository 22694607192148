import TYPES from '@/types';

// Application
import SignOutCommand
  from '@/modules/authentication/application/commands/sign-out-command';
import SignOutEmitter from '@/modules/authentication/application/emitters/sign-out-emitter';

// Domain
import { WebSocket } from '@/modules/shared/domain/web-socket';
import Inject from '@/modules/shared/domain/di/inject';
import SHARED_TYPES from '@/modules/shared/types';

export default class AuthenticatedLayoutViewModel {
  @Inject(TYPES.SIGN_OUT_COMMAND)
  private readonly sign_out!: SignOutCommand;

  @Inject(TYPES.SIGN_OUT_EMITTER)
  private readonly sign_out_emitter!: SignOutEmitter;

  @Inject(SHARED_TYPES.WEB_SOCKET)
  private readonly web_socket!: WebSocket;

  initialize = () => {
    this.initializeListeners();
  }

  beforeUnloadHandler = async () => {
    this.sign_out_emitter.sendMessage('close_session', true);
  }

  appAuthTokenUpdatedHandler = async () => {
    this.sign_out_emitter.refreshToken();
  }

  subscribe = () => {
    const user_id = sessionStorage.getItem('user_id') || '';
    this.sign_out_emitter.connect(user_id);
  }

  initializeListeners = () => {
    window.addEventListener('beforeunload', this.beforeUnloadHandler);
    window.addEventListener('app.auth.token.updated', this.appAuthTokenUpdatedHandler);
    this.subscribe();
  }
}
