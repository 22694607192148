import notificationsFixtures from '@/fixtures/customer/customer-notifications/fixtures.json';

// Domain
import { CustomerNotificationRepository } from '../../../domain/repositories';
import { CustomerNotification } from '../../../domain/entities';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CustomerNotificationDummyRepository implements CustomerNotificationRepository {
  get_notifications(): Promise<Array<CustomerNotification>> {
    return Promise.resolve(notificationsFixtures);
  }
}
