import TYPES from '../../types';

// Domain
import { DefineByEntity } from '../../domain/entities/define-by-entity';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class EditEmergencyFundGoalPlanDefineByService {
  @Inject(TYPES.EDIT_EMERGENCY_FUND_GOAL_PLAN_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  setDefineByInformation(define_by: DefineByEntity): void {
    this.state_manager.state.define_by = { ...define_by };
  }

  getDefineByInformation(): DefineByEntity {
    return this.state_manager.state.define_by;
  }
}
