import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';

// Application
import EditCustomGoalPlanCurrentPlanService
  from './application/services/edit-custom-goal-plan-current-plan-service';
import EditCustomGoalPlanDefineByService
  from './application/services/edit-custom-goal-plan-define-by-service';
import EditCustomGoalPlanInitialAmountService
  from './application/services/edit-custom-goal-plan-initial-amount-service';

// Domain
import { StateManager } from './domain/state/state-manager';

export default function setupEditCustomGoalPlan(container: Container) {
  container
    .bind<StateManager>(TYPES.EDIT_CUSTOM_GOAL_PLAN_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<EditCustomGoalPlanCurrentPlanService>(TYPES.EDIT_CUSTOM_GOAL_CURRENT_PLAN_SERVICE)
    .to(EditCustomGoalPlanCurrentPlanService)
    .inSingletonScope();

  container
    .bind<EditCustomGoalPlanDefineByService>(TYPES.EDIT_CUSTOM_GOAL_PLAN_DEFINE_BY_SERVICE)
    .to(EditCustomGoalPlanDefineByService)
    .inSingletonScope();

  container
    .bind<EditCustomGoalPlanInitialAmountService>(TYPES
      .EDIT_CUSTOM_GOAL_PLAN_INITIAL_AMOUNT_SERVICE)
    .to(EditCustomGoalPlanInitialAmountService)
    .inSingletonScope();
}
