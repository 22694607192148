import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import {
  FileHttpRepository,
  FileDummyRepository,
} from './infrastructure/repository';

// Application
import { GetFileQuery } from './application/queries';

// Domain
import { FileRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupFile(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.MY_INVESTMENT_FILE_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<FileRepository>(TYPES.MY_INVESTMENT_FILE_REPOSITORY)
    .to(
      withDummies
        ? FileDummyRepository
        : FileHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<GetFileQuery>(TYPES.MY_INVESTMENT_GET_FILE_QUERY)
    .to(GetFileQuery)
    .inSingletonScope();
}
