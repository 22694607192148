import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import { MaritalStatusDummyRepository } from './infrastructure/repository';

// Application
import { SearchMaritalStatusesQuery } from './application/queries';

// Domain
import { MaritalStatusRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupMaritalStatus(container: Container) {
  container
    .bind<StateManager>(TYPES.CATALOG_KUSPIT_MARITAL_STATUS_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<MaritalStatusRepository>(TYPES.CATALOG_KUSPIT_MARITAL_STATUS_REPOSITORY)
    .to(MaritalStatusDummyRepository)
    .inSingletonScope();

  container
    .bind<SearchMaritalStatusesQuery>(TYPES.SEARCH_CATALOG_KUSPIT_MARITAL_STATUS_QUERY)
    .to(SearchMaritalStatusesQuery)
    .inSingletonScope();
}
