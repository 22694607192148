// Domain
import { PatchAllianzPolicyIssuanceStatusRepository }
  from '../../../domain/repository/patch-allianz-policy-issuance-status-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class PatchAllianzPolicyIssuanceStatusDummyRepository
implements PatchAllianzPolicyIssuanceStatusRepository {
  patch(): Promise<void> {
    return Promise.resolve();
  }
}
