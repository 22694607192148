// Domain
import { CustomerDocumentPocketRepository } from '../../../domain/repositories/customer-document-pocket-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CustomerDocumentPocketDummyRepository
implements CustomerDocumentPocketRepository {
  update_bank_statement(): Promise<void> {
    return Promise.resolve();
  }

  update_proof_of_address(): Promise<void> {
    return Promise.resolve();
  }
}
