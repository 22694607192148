import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import CustomerDocumentDummyRepository
  from './infrastructure/repositories/magic-link-dummy-repository/customer-document-dummy-repository';
import CustomerDocumentHttpRepository
  from './infrastructure/repositories/customer-document-http-repository';

// Application
import VerifyTokenMagicLinkQuery from './application/queries/verify-token-magic-link-query';
import UploadProofOfTaxSituationCommand
  from '@/modules/my-investment/kuspit/customer-document/magic-link/application/commands/upload-proof-of-tax-situation-command';

// Domain
import { CustomerDocumentRepository } from './domain/repositories/customer-document-repository';

export default function setupMagicLink(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<CustomerDocumentRepository>(TYPES.KUSPIT_CUSTOMER_DOCUMENT_REPOSITORY)
    .to(
      withDummies
        ? CustomerDocumentDummyRepository : CustomerDocumentHttpRepository,
    ).inSingletonScope();

  container
    .bind<VerifyTokenMagicLinkQuery>(TYPES.KUSPIT_VERIFY_TOKEN_MAGIC_LINK_QUERY)
    .to(VerifyTokenMagicLinkQuery)
    .inSingletonScope();

  container
    .bind<UploadProofOfTaxSituationCommand>(TYPES.KUSPIT_UPLOAD_PROOF_OF_TAX_SITUATION_COMMAND)
    .to(UploadProofOfTaxSituationCommand)
    .inSingletonScope();
}
