// Views
import views from './views';
import loading from './views/loading.json';
import welcome from './views/welcome.json';
import signUp from './views/sign-up';
import login from './views/login.json';
import error_process_verification from './views/errors/error_process_verification.json';
import success from './views/errors/success.json';
import error_verification_mati from './views/errors/error_verification_mati.json';
import home_dashboard from './views/home_dashboard.json';
import not_found from './views/not-found.json';

// Components
import components from './components';

// Utils
import utils from './utils';

export default {
  ...views,
  ...loading,
  ...welcome,
  ...signUp,
  ...login,
  ...error_process_verification,
  ...success,
  ...error_verification_mati,
  ...components,
  ...utils,
  ...home_dashboard,
  ...not_found,
};
