import TYPES from '../../types';

// Domain
import {
  ExitPollOptionsEntity,
} from '@/modules/flagship/catalogs/exit-poll-options/domain/entities/exit-poll-options-entity';
import { ExitPollOptionsRepository } from '@/modules/flagship/catalogs/exit-poll-options/domain/repositories/exit-poll-options-repository';
import {
  ExitPollOptionsStateManager,
} from '@/modules/flagship/catalogs/exit-poll-options/domain/state/exit-poll-options-state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetExitPollOptionsQuery extends Query<Promise<Array<ExitPollOptionsEntity>>> {
  @Inject(TYPES.EXIT_POLL_OPTIONS_REPOSITORY)
  private readonly repository!: ExitPollOptionsRepository;

  @Inject(TYPES.EXIT_POLL_OPTIONS_STATE_MANAGER)
  private readonly state_manager!: ExitPollOptionsStateManager;

  internalExecute(): Promise<Array<ExitPollOptionsEntity>> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded) {
        resolve(this.state_manager.state.items);
      } else {
        this.repository.execute()
          .then((items) => {
            this.state_manager.patch({ items, loaded: true });
            resolve(items);
          })
          .catch(reject);
      }
    });
  }
}
