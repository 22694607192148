import TYPES from '../../types';

// Domain
import {
  InvestmentSuggestionEntity,
} from '@/modules/investment-suggestion/domain/entities/investment-suggestion-entity';
import {
  InvestmentSuggestionRepository,
} from '@/modules/investment-suggestion/domain/repositories/investment-suggestion-repository';
import {
  InvestmentSuggestionStateManager,
} from '@/modules/investment-suggestion/domain/state/investment-suggestion-state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class InvestmentSuggestionQuery extends Query<Promise<InvestmentSuggestionEntity>> {
  @Inject(TYPES.INVESTMENT_SUGGESTION_REPOSITORY)
  private readonly repository!: InvestmentSuggestionRepository;

  @Inject(TYPES.INVESTMENT_SUGGESTION_STATE_MANAGER)
  private readonly state_manager!: InvestmentSuggestionStateManager;

  internalExecute(): Promise<InvestmentSuggestionEntity> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded) {
        resolve(this.state_manager.state.item);
      } else {
        this.repository.get()
          .then((item) => {
            this.state_manager.patch({ item, loaded: true });
            resolve(item);
          })
          .catch(reject);
      }
    });
  }
}
