import Injectable from '@/modules/shared/domain/di/injectable';
import { CreateOnBoardingCustomerAgreementRepository }
  from '../../domain/repositories/create-on-boarding-customer-agreement-repository';
import { CustomerAgreementEntity } from '../../domain/entities/customer-agreement-entity';

@Injectable()
// eslint-disable-next-line max-len
class CreateCustomerAgreementDummyRepository implements CreateOnBoardingCustomerAgreementRepository {
  // eslint-disable-next-line class-methods-use-this
  create(customer_agreement: CustomerAgreementEntity): Promise<void> {
    console.log('CUSTOMER AGREEMENT CREATED: ', customer_agreement);

    return Promise.resolve();
  }
}

export default CreateCustomerAgreementDummyRepository;
