import UnautheneticatedLayout from '@/vue-app/layouts/UnauthenticatedLayout.vue';
import AttachedNewAccountStatement from '@/vue-app/views/document-validation/AttachedNewAccountStatement.vue';
import POLICIES from '../security/policies';

export default {
  path: '/attached-new-account-statement',
  component: UnautheneticatedLayout,
  children: [
    {
      path: '/',
      name: 'AttachedNewAccountStatement',
      component: AttachedNewAccountStatement,
      meta: {
        access_policy: POLICIES.UNAUTHENTICATED,
      },
    },
  ],
};
