import TYPES from '@/modules/shared/types';

// Domain
import { CustomerDocumentPocketRepository } from '../../domain/repositories/customer-document-pocket-repository';
import { CustomerDocumentPocketDto } from '../../domain/dtos/customer-document-pocket-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class CustomerDocumentPocketHttpRepository implements
  CustomerDocumentPocketRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/onboarding/customer-document/pocket/`;

  update_bank_statement(customer_document_dto: CustomerDocumentPocketDto): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}bank-statement/`, 'PUT', customer_document_dto)
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json()
              .then(({ message }) => {
                reject(new Error(message));
              });
          }
        })
        .catch(reject);
    });
  }

  update_proof_of_address(customer_document_dto: CustomerDocumentPocketDto): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}proof-of-address/`, 'PUT', customer_document_dto)
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json()
              .then(({ message }) => {
                reject(new Error(message));
              });
          }
        })
        .catch(reject);
    });
  }
}
