




























































































import { Vue, Component, PropSync } from 'vue-property-decorator';
import WithdrawalConfirmOperationViewModel
  from '@/vue-app/view-models/components/kuspit-dashboard/withdrawals/withdrawal-confirm-operation-view-model';

@Component({ name: 'WithdrawalsConfirmOperation' })
export default class WithdrawalsConfirmOperation extends Vue {
  @PropSync('amount', { type: String, required: true })
  synced_amount!: string;

  i18n_namespace = 'components.kuspit-dashboard.withdrawals.confirm_operation';

  withdrawal_confirm_op_view_model = Vue.observable(new WithdrawalConfirmOperationViewModel(this));
}

