import { MatiVerificationEntity } from '../entities/mati-verification-entity';

export default class MatiVerificationState {
  loaded = false;

  mati_verification: MatiVerificationEntity = {
    id: '',
    customer_id: '',
    status: '',
    attempt_counter: 0,
    created_at: '',
    updated_at: '',
  }
}
