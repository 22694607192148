











































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import DepositViewModel from '@/vue-app/view-models/components/kuspit-dashboard/deposits/deposit-view-model';

@Component({ name: 'DepositAccountInfo' })
export default class DepositAccountInfo extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  kuspit_deposit_view_model = Vue.observable(new DepositViewModel());

  small_screen: null | boolean = null;

  i18n_namespace = 'components.kuspit-dashboard.deposits.deposit_account_info';

  accept() {
    this.$emit('closeModal');
  }

  mounted() {
    this.small_screen = getScreenSizeVariant() === 'small';
  }

  created() {
    this.kuspit_deposit_view_model.initialize();
  }
}

