import linked_goals from './linked-goals.json';
import goals_undefined_card from './goals-undefined-card.json';
import link_goals_card from './link-goals-card.json';
import modal_distribute_unassigned_amount from './modal-distribute-unassigned-amount.json';

export default {
  goals: {
    ...linked_goals,
    ...goals_undefined_card,
    ...link_goals_card,
    ...modal_distribute_unassigned_amount,
  },
};
