// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { SearchAddressRepository } from '../../../domain/repository';
import { CustomerAddressEntity } from '../../../domain/entities/customer-address-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class SearchAddressDummyRepository implements SearchAddressRepository {
  // eslint-disable-next-line class-methods-use-this
  async execute(): Promise<Array<CustomerAddressEntity>> {
    return Promise.resolve(fixtures);
  }
}
