import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import CalendlyDummyRepository
  from './infrastructure/repositories/calendly-dummy-repository';
import CalendlyHttpRepository
  from './infrastructure/repositories/calendly-http-repository';

// Application
import GetSingleUseScheduleLinkQuery from './application/queries/get_single_use_schedule_link-query';

// Domain
import { CalendlyRepository } from './domain/repository/calendly-repository';

export default function setupCalendlyModule(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<CalendlyRepository>(TYPES.CALENDLY_REPOSITORY)
    .to(
      withDummies
        ? CalendlyDummyRepository
        : CalendlyHttpRepository,
    ).inSingletonScope();
  container
    .bind<GetSingleUseScheduleLinkQuery>(TYPES.GET_SINGLE_USE_SCHEDULE_LINK_QUERY)
    .to(GetSingleUseScheduleLinkQuery)
    .inSingletonScope();
}
