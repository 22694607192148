import TYPES from '../../types';

// Domain
import {
  HireModerateDto,
} from '@/modules/flagship/strategy-moderate/hire/domain/dtos/hire-moderate-dto';
import {
  StrategySowosModerateRepository,
} from '@/modules/flagship/strategy-moderate/hire/domain/repositories/strategy-sowos-moderate-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class HireStrategyModerateCommand
  extends Command<HireModerateDto> {
  @Inject(TYPES.STRATEGY_SOWOS_MODERATE_REPOSITORY)
  private readonly repository!: StrategySowosModerateRepository;

  internalExecute(
    hire_moderate_dto: HireModerateDto,
  ): Promise<void> {
    return this.repository.hire(
      hire_moderate_dto,
    );
  }
}
