// Domain
import { CustomInvestorGoalEntity } from '@/modules/flagship/custom-investor-goal/domain/entities/custom-investor-goal-entity';

export default class SearchByCustomerState {
  loaded = false;

  items: Array<CustomInvestorGoalEntity> = [
    {
      id: '',
      investor_goal_id: '',
      goal_name: '',
      custom_icon_file_id: '',
      desired_amount: 0,
      fixed_time_adjusted: 0,
      investor_goal: {
        recurrent_assigned_amount: 0,
        active_goal_amount: 0,
        is_active: false,
        investment_goal_type_id: '',
        investor_profile_id: '',
        monthly_required_amount: 0,
        associated_product_id: '',
        accumulated_amount: 0,
        final_investment_date: '',
        initial_investment_date: '',
        customer_id: '',
        id: '',
        created_at: '',
        updated_at: '',
        initial_amount: 0,
        associated_product: {
          interest_rate: 0,
          description: '',
          created_at: '',
          id: '',
          updated_at: '',
          investment_provider: {
            description: '',
            created_at: '',
            id: '',
            updated_at: '',
            label: '',
            name: '',
          },
          investment_provider_id: '',
          label: '',
          name: '',
        },
        associated_contract: {},
        investment_product_fund_types: {
          linked: [],
          available: [],
        },
      },
      custom_goal_type: {
        description: '',
        icon_name: '',
        id: '',
        label: '',
        name: '',
        created_at: '',
        updated_at: '',
      },
      first_initial_investment_date: '',
      tracking_percent: 0,
    },
  ];
}
