// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { MatiVerificationRepository } from '../../../../domain/repositories/mati-verification-repository';
import { MatiVerificationEntity } from '../../../../domain/entities/mati-verification-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class MatiVerificationDummyRepository implements MatiVerificationRepository {
  get(): Promise<MatiVerificationEntity> {
    return Promise.resolve(fixtures);
  }

  requestManualReview(): Promise<void> {
    console.log('REQUEST MANUAL REVIEW: ');
    return Promise.resolve();
  }
}
