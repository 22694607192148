import TYPES from '../../types';

// Domain
import { UploadFileAccountStatusRepository } from '../../domain/repository';
import { FileDto } from '../../domain/dtos/file-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UploadFileAccountStatusCommand extends Command<FileDto> {
  @Inject(TYPES.MY_INVESTMENT_UPLOAD_FILE_ACCOUNT_STATUS_REPOSITORY)
  private readonly repository!: UploadFileAccountStatusRepository;

  internalExecute(file_dto: FileDto) {
    return this.repository.upload(file_dto);
  }
}
