export class ValidateWithdrawalWorkingHoursService {
  static validate() {
    // Start or working hours at 9:00 a.m.
    const start_of_working = 15 * 60;
    // End of working hours at 1:10.p.m.
    const end_of_working = 19 * 60 + 10;
    const date = new Date();
    const currently_hour_as_minutes = date.getUTCHours() * 60 + date.getUTCMinutes();
    return date.getUTCDay() !== 0 && date.getUTCDay() !== 6
      && currently_hour_as_minutes < end_of_working
      && currently_hour_as_minutes >= start_of_working;
  }
}
