import TYPES from '@/modules/shared/types';

// Domain
import {
  CustomerInvestorGoalReminderRepository,
} from '@/modules/flagship/customer-investor-goal-reminder/domain/repositories/customer-investor-goal-reminder-repository';
import {
  CustomerInvestorGoalReminderEntity,
} from '@/modules/flagship/customer-investor-goal-reminder/domain/entities/customer-investor-goal-reminder-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class CustomerInvestorGoalReminderHttpRepository
implements CustomerInvestorGoalReminderRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/customer-investor-goal-reminder/`;

  get(associated_product_id: string): Promise<CustomerInvestorGoalReminderEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}investment-product/${associated_product_id}/`)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(JSON.stringify({
                  status_code: response.status,
                  message_error: message,
                }));
              }
            });
        })
        .catch(reject);
    });
  }

  create(customer_investor_goal_reminder_entity: CustomerInvestorGoalReminderEntity):
    Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint, 'POST', customer_investor_goal_reminder_entity)
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => {
                reject(new Error(message));
              },
            );
          }
        })
        .catch(reject);
    });
  }

  update(customer_investor_goal_reminder_entity: CustomerInvestorGoalReminderEntity):
    Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}${customer_investor_goal_reminder_entity.id}/`,
        'PATCH', {
          day_to_remind: customer_investor_goal_reminder_entity.day_to_remind,
          is_subscribed: customer_investor_goal_reminder_entity.is_subscribed,
        })
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => {
                reject(new Error(message));
              },
            );
          }
        })
        .catch(reject);
    });
  }
}
