import TYPES from '@/modules/shared/types';

// Domain
import { ValidateKuspitAccountDto }
  from '@/modules/my-investment/kuspit/kuspit-account-linking/domain/dtos/validate-kuspit-account-dto';
import { ValidateKuspitAccountRepository }
  from '@/modules/my-investment/kuspit/kuspit-account-linking/domain/repositories/validate-kuspit-account-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
class ValidateKuspitAccountHttpRepository implements ValidateKuspitAccountRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/kuspit/customer-account/link/`;

  create(validate_kuspit_account_dto: ValidateKuspitAccountDto): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint, 'POST', validate_kuspit_account_dto)
        .then((response) => {
          response.json()
            .then(({ success, message }) => {
              if (success) {
                resolve();
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}

export default ValidateKuspitAccountHttpRepository;
