import TYPES from '../../types';

// Domain
import { RecommendedInvestmentProductRepository } from '../../domain/repositories/recommended-investment-product-repository';
import {
  InvestmentProductEntity,
} from '@/modules/flagship/investor-profile/recommended-investment-product/domain/entities/investment-product-entity';
import { RecommendedInvestmentProductStateManager } from '@/modules/flagship/investor-profile/recommended-investment-product/domain/state/recommended-investment-product-state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetRecommendedInvestmentProductQuery
  extends Query<Promise<InvestmentProductEntity>> {
  @Inject(TYPES.RECOMMENDED_INVESTMENT_PRODUCT_REPOSITORY)
  private readonly repository!: RecommendedInvestmentProductRepository;

  @Inject(TYPES.RECOMMENDED_INVESTMENT_PRODUCT_STATE_MANAGER)
  private readonly state_manager!: RecommendedInvestmentProductStateManager;

  internalExecute(investor_profile_id: string): Promise<InvestmentProductEntity> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded) {
        resolve(this.state_manager.state.investment_product);
      } else {
        this.repository.execute(investor_profile_id)
          .then((investment_product) => {
            const is_loaded = Object.entries(investment_product).length === 0;
            this.state_manager.patch({ investment_product, loaded: is_loaded });
            resolve(investment_product);
          })
          .catch(reject);
      }
    });
  }
}
