// Domain
import Injectable from '@/modules/shared/domain/di/injectable';
import { InvestmentTypeHiringStepRepository } from '../../domain/repositories/investment-type-hiring-step-repository';
import { InvestmentTypeHiringStepEntity } from '../../domain/entities/investment-type-hiring-step-entity';

@Injectable()
export default class InvestmentTypeHiringStepDummyRepository
implements InvestmentTypeHiringStepRepository {
  create(step: InvestmentTypeHiringStepEntity): Promise<void> {
    const steps_data = JSON.parse(sessionStorage.getItem('steps_data')!) || [];

    sessionStorage.setItem('steps_data', JSON.stringify([...steps_data, step]));

    return Promise.resolve();
  }

  update(step: InvestmentTypeHiringStepEntity) {
    const steps_data = JSON.parse(sessionStorage.getItem('steps_data')!);
    const step_index = steps_data.findIndex(
      (item: InvestmentTypeHiringStepEntity) => item.id === step.id,
    );
    steps_data[step_index] = step;
    sessionStorage.setItem('steps_data', JSON.stringify(steps_data));
    return Promise.resolve();
  }

  search() {
    const steps_data = sessionStorage.getItem('steps_data');
    return Promise.resolve(steps_data ? JSON.parse(steps_data) : []);
  }
}
