import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import {
  TotalWithdrawalHttpRepository,
  TotalWithdrawalDummyRepository,
}
  from './infrastructure/repository';

// Application
import { CreateTotalWithdrawalCommand } from './application/commands';

// Domain
import { TotalWithdrawalRepository } from './domain/repository';

export default function setupTotalWithdrawal(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<TotalWithdrawalRepository>(TYPES.TOTAL_WITHDRAWAL_REPOSITORY)
    .to(
      withDummies
        ? TotalWithdrawalDummyRepository
        : TotalWithdrawalHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<CreateTotalWithdrawalCommand>(TYPES.CREATE_TOTAL_WITHDRAWAL_COMMAND)
    .to(CreateTotalWithdrawalCommand)
    .inSingletonScope();
}
