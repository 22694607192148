import TYPES from '@/modules/shared/types';

// Domain
import { CreateAllianzPolicyRepository } from '../../../domain/repository';
import { AllianzPolicyEntity } from '../../../domain/entities/allianz-policy-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class CreateAllianzPolicyHttpRepository
implements CreateAllianzPolicyRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/allianz/policies/`;

  create(allianz_policy_entity: AllianzPolicyEntity): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(
        this.endpoint,
        'POST',
        allianz_policy_entity,
      )
        .then((response) => {
          response.json()
            .then(({ success, message }) => {
              if (success) {
                resolve();
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
