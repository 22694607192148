import personFixtures from '@/fixtures/customer/person/fixtures.json';

// Domain
import { PersonRepository } from '../../../domain/repositories';
import { Person } from '../../../domain/entities';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class PersonDummyRepository implements PersonRepository {
  get(): Promise<Person> {
    return Promise.resolve(personFixtures);
  }
}
