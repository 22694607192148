








import { Vue, Component } from 'vue-property-decorator';
import OnBoardingHeader from '@/vue-app/components/header/OnBoardingHeader.vue';

@Component({
  name: 'OnBoardingLayout',
  components: { OnBoardingHeader },
})
export default class OnBoardingLayout extends Vue {}

