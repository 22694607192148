import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';
import TYPES from '../../types';
import GetPersonRepository from '../../domain/repositories/get-person-repository';
import { PersonEntity } from '../../domain/entities/person-entity';

@Injectable()
export default class GetOnBoardingPersonQuery extends Query<Promise<PersonEntity>> {
  @Inject(TYPES.ON_BOARDING_GET_PERSON_REPOSITORY)
  private readonly repository!: GetPersonRepository;

  internalExecute(): Promise<PersonEntity> {
    return this.repository.get();
  }
}
