import { Container } from 'inversify-props';
import TYPES from './types';
import OnBoardingStatusDummyRepository from './infrastructure/on-boarding-status-dummy-repository';
import OnBoardingStatusHttpRepository from './infrastructure/on-boarding-status-http-repository';
import GetOnBoardingStatusQuery from './application/queries/get-on-boarding-status-query';
import CompleteOnBoardingCommand from './application/commands/complete-on-boarding-command';
import { OnBoardingStatusRepository } from './domain/repositories/on-boarding-status-repository';

export default function setupStatus(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<OnBoardingStatusRepository>(TYPES.ON_BOARDING_STATUS_REPOSITORY)
    .to(
      withDummies
        ? OnBoardingStatusDummyRepository
        : OnBoardingStatusHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetOnBoardingStatusQuery>(TYPES.GET_ON_BOARDING_STATUS_QUERY)
    .to(GetOnBoardingStatusQuery)
    .inSingletonScope();

  container
    .bind<CompleteOnBoardingCommand>(TYPES.COMPLETE_ON_BOARDING_COMMAND)
    .to(CompleteOnBoardingCommand)
    .inSingletonScope();
}
