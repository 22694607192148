import TYPES from '@/modules/shared/types';

// Domain
import {
  InvestorProfileOnFinancialHabitsRepository,
} from '@/modules/my-investment/allianz/access-more-strategies/domain/repositories/investor-profile-on-financial-habits-repository';
import {
  FinancialHabitsDto,
} from '@/modules/my-investment/allianz/access-more-strategies/domain/dtos/financial-habits-dtos';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class InvestorProfileOnFinancialHabitsHttpRepository
implements InvestorProfileOnFinancialHabitsRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/investor-profile/financial-habits/{investor_profile_id}/`;

  update(financial_habits_dto: FinancialHabitsDto): Promise<void> {
    return new Promise((resolve, reject) => {
      const {
        investor_profile_id,
        ...payload
      } = financial_habits_dto;

      this.http_connector.execute(this.endpoint.replace('{investor_profile_id}',
        financial_habits_dto.investor_profile_id), 'PATCH', payload)
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => {
                reject(new Error(message));
              },
            );
          }
        })
        .catch(reject);
    });
  }
}
