// Domain
import { CustomerKuspitCredentialsSenderRepository } from '../../../../domain/repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CustomerKuspitCredentialsSenderHttpRepository
implements CustomerKuspitCredentialsSenderRepository {
  // eslint-disable-next-line class-methods-use-this
  send(): Promise<void> {
    return Promise.resolve();
  }
}
