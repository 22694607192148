import TYPES from '@/modules/shared/types';

// Domain
import { MatiVerificationRepository } from '../../../domain/repositories/mati-verification-repository';
import { MatiVerificationEntity } from '../../../domain/entities/mati-verification-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class MatiVerificationHttpRepository implements MatiVerificationRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/customer-mati-verification-status/`;

  private endpoint_request_mati_verification = `${process.env.VUE_APP_API_URL}/api/v1/app/identity/request-manual-review/`;

  get(): Promise<MatiVerificationEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint)
        .then((response) => {
          response.json()
            .then(({ data, success, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }

  requestManualReview(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint_request_mati_verification, 'POST', {})
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json()
              .then(({ message }) => {
                reject(new Error(message));
              });
          }
        })
        .catch(reject);
    });
  }
}
