import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import FinalInvestmentDateCalculatorDummyRepository
  from './infrastructure/repositories/final-investment-date-calculator-dummy-repository/final-investment-date-calculator-dummy-repository';
import FinalInvestmentDateCalculatorHttpRepository
  from './infrastructure/repositories/final-investment-date-calculator-http-repository';

// Application
import GetFinalInvestmentDateCalculatorQuery
  from './application/queries/get-final-investment-date-calculator-query';

// Domain
import { FinalInvestmentDateCalculatorRepository }
  from './domain/repositories/final-investment-date-calculator-repository';

export default function setupFinalInvestmentDate(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<FinalInvestmentDateCalculatorRepository>(TYPES
      .FINAL_INVESTMENT_DATE_CALCULATOR_REPOSITORY)
    .to(withDummies
      ? FinalInvestmentDateCalculatorDummyRepository
      : FinalInvestmentDateCalculatorHttpRepository)
    .inSingletonScope();

  container.bind<GetFinalInvestmentDateCalculatorQuery>(TYPES
    .GET_FINAL_INVESTMENT_DATE_CALCULATOR_QUERY)
    .to(GetFinalInvestmentDateCalculatorQuery)
    .inSingletonScope();
}
