import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import AllianzAccountIsOnCancellationProcessVueStateManager
  from './infrastructure/state/allianz-account-is-on-cancellation-process-vue-state-manager';
import {
  AllianzAccountHttpRepository,
  AllianzAccountDummyRepository,
  ActivateRecurringContributionDummyRepository,
  ActivateRecurringContributionHttpRepository,
} from './infrastructure/repository';

// Application
import { GetAllianzAccountQuery, GetAllianzAccountIsOnCancellationProcessQuery } from './application/queries';
import { ActivateRecurringContributionCommand, CancelRecurringContributionCommand, UpdateAccountNumberCommand } from './application/commands';

// Domain
import { AllianzAccountRepository, ActivateRecurringContributionRepository }
  from './domain/repository';
import { StateManager } from './domain/state/state-manager';
import { AllianzAccountIsOnCancellationProcessStateManager } from './domain/state/allianz-account-is-on-cancellation-process-state-manager';

export default function setupAllianzAccount(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.ALLIANZ_ACCOUNT_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<AllianzAccountIsOnCancellationProcessStateManager>(
      TYPES.ALLIANZ_ACCOUNT_IS_ON_CANCELLATION_PROCESS_STATE_MANAGER,
    )
    .to(AllianzAccountIsOnCancellationProcessVueStateManager)
    .inSingletonScope();

  container
    .bind<AllianzAccountRepository>(TYPES.ALLIANZ_ACCOUNT_REPOSITORY)
    .to(
      withDummies
        ? AllianzAccountDummyRepository
        : AllianzAccountHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<GetAllianzAccountQuery>(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
    .to(GetAllianzAccountQuery)
    .inSingletonScope();

  container
    .bind<ActivateRecurringContributionRepository>(TYPES.ACTIVATE_RECURRING_CONTRIBUTION_REPOSITORY)
    .to(
      withDummies
        ? ActivateRecurringContributionDummyRepository
        : ActivateRecurringContributionHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<ActivateRecurringContributionCommand>(TYPES.ACTIVATE_RECURRING_CONTRIBUTION_COMMAND)
    .to(ActivateRecurringContributionCommand)
    .inSingletonScope();

  container
    .bind<CancelRecurringContributionCommand>(TYPES.CANCEL_RECURRING_CONTRIBUTION_COMMAND)
    .to(CancelRecurringContributionCommand)
    .inSingletonScope();

  container
    .bind<GetAllianzAccountIsOnCancellationProcessQuery>(
      TYPES.GET_ALLIANZ_ACCOUNT_IS_ON_CANCELLATION_PROCESS_QUERY,
    )
    .to(GetAllianzAccountIsOnCancellationProcessQuery)
    .inSingletonScope();

  container
    .bind<UpdateAccountNumberCommand>(
      TYPES.UPDATE_ACCOUNT_NUMBER_COMMAND,
    )
    .to(UpdateAccountNumberCommand)
    .inSingletonScope();
}
