import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import {
  UploadFileProofOfAddressHttpRepository,
  UploadFileProofOfAddressDummyRepository,
} from './infrastructure/repository/upload-file-proof-of-address-repository';

import {
  UploadFileAccountStatusDummyRepository,
  UploadFileAccountStatusHttpRepository,
} from './infrastructure/repository/upload-file-account-status-repository';

// Application
import {
  UploadFileProofOfAddressCommand,
  UploadFileAccountStatusCommand,
} from './application/commands';

// Domain
import {
  UploadFileProofOfAddressRepository,
  UploadFileAccountStatusRepository,
} from './domain/repository';

export default function setupUploadFile(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<UploadFileProofOfAddressRepository>(
      TYPES.MY_INVESTMENT_UPLOAD_FILE_PROOF_OF_ADDRESS_REPOSITORY,
    )
    .to(
      withDummies
        ? UploadFileProofOfAddressDummyRepository
        : UploadFileProofOfAddressHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<UploadFileProofOfAddressCommand>(TYPES.MY_INVESTMENT_UPLOAD_FILE_PROOF_OF_ADDRESS_COMMAND)
    .to(UploadFileProofOfAddressCommand)
    .inSingletonScope();

  container
    .bind<UploadFileAccountStatusRepository>(
      TYPES.MY_INVESTMENT_UPLOAD_FILE_ACCOUNT_STATUS_REPOSITORY,
    )
    .to(
      withDummies
        ? UploadFileAccountStatusDummyRepository
        : UploadFileAccountStatusHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<UploadFileAccountStatusCommand>(TYPES.MY_INVESTMENT_UPLOAD_FILE_ACCOUNT_STATUS_COMMAND)
    .to(UploadFileAccountStatusCommand)
    .inSingletonScope();
}
