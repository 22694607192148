// Domain
import { InvestmentTypeRepository } from '../../domain/repository';
import { InvestmentTypeDto } from '../../domain/dtos/investment-type-dto';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class InvestmentTypeDummyRepository implements InvestmentTypeRepository {
  execute(customer_id: string): Promise<InvestmentTypeDto> {
    return Promise.resolve({ investment_type: 'Moderado' });
  }
}
