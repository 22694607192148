















import { Vue, Component } from 'vue-property-decorator';

import UpdateIdentityDocumentViewModel
  from '@/vue-app/view-models/views/update-identity-document/update-identity-document-view-model';

@Component({
  name: 'UpdateIdentityDocument',
  components: {
    UpdateIdentityDocumentVerifyIdentify: () => import('@/vue-app/components/update-identity-document/VerifyIdentity.vue'),
    IdentityDocumentUpdatedConfirmation: () => import('@/vue-app/components/update-identity-document/IdentityDocumentUpdatedConfirmation.vue'),
  },
})
export default class UpdateIdentityDocument extends Vue {
  view_model = new UpdateIdentityDocumentViewModel();

  created() {
    this.view_model.validateIdentityDocumentExpired();
  }
}

