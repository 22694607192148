import TYPES from '../../types';

// Domain
import {
  DeleteCustomInvestorGoalStateManager,
} from '@/modules/flagship/custom-investor-goal/domain/state/delete-custom-investor-goal-state-manager';
import DeleteCustomInvestorGoalState from '@/modules/flagship/custom-investor-goal/domain/state/delete-custom-investor-goal-state';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class DeleteCustomInvestorGoalStateManagerService {
  @Inject(TYPES.DELETE_CUSTOM_INVESTOR_GOAL_STATE_MANAGER)
  private readonly state_manager!: DeleteCustomInvestorGoalStateManager;

  get(): DeleteCustomInvestorGoalState {
    return this.state_manager.state;
  }

  restore() {
    const empty_state = {
      is_loading: false,
      custom_investor_goal: {
        active_goal_amount: 0,
        custom_investor_goal_id: '',
        goal_name: '',
        recurrent_assigned_amount: 0,
        associated_product_name: '',
        associated_product_id: '',
      },
    };

    this.state_manager.patch(empty_state);
  }
}
