import Injectable from '../../domain/di/injectable';
import { Permissions } from '../../domain/permissions';
import { PermissionsDto } from '../../domain/dtos/permissions-dto';

@Injectable()
export default class ChromePermissions implements Permissions {
  // eslint-disable-next-line class-methods-use-this
  async getCurrentPermissions(): Promise<PermissionsDto> {
    const geolocation_permission = (await navigator.permissions.query({ name: 'geolocation' })).state === 'granted';

    return {
      geolocation: geolocation_permission,
    };
  }
}
