import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';
import RegisterRepository from '../../../domain/repository/RegisterRepository';
import RegisterDTO from '@/modules/register/domain/dto/RegisterDTO';
import TYPES from '@/modules/shared/types';

@Injectable()
export default class UserFetchRepository implements RegisterRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly path = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/registry/`;

  create(register_dto: RegisterDTO): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.path, 'POST', register_dto)
        .then((response) => {
          response.json().then(({ success, message }) => {
            if (success) {
              resolve();
            } else {
              reject(new Error(message));
            }
          });
        })
        .catch(reject);
    });
  }
}
