import injectable from '@/modules/shared/domain/di/injectable';
import { AuthenticationDataRepository } from '../../../domain/repositories';
import { AuthenticationDataType } from '../../../domain/types';

@injectable()
export default class AuthenticationDataDummyRepository implements AuthenticationDataRepository {
  // eslint-disable-next-line class-methods-use-this
  create(): Promise<AuthenticationDataType> {
    return Promise.resolve({
      token: 'Test',
    });
  }

  signOut(): Promise<void> {
    return Promise.resolve();
  }
}
