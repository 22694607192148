



































































































































































































































































import { Component, Vue } from 'vue-property-decorator';

import SignUpViewModel from '@/vue-app/view-models/views/signup-view-model';

@Component({ name: 'SignUp' })
export default class SignUp extends Vue {
  signup_view_model = Vue.observable(new SignUpViewModel());

  async created() {
    if (this.$route?.query?.ref) {
      this.signup_view_model.setReferrer(this.$route?.query?.ref.toString());
    }
    await this.$store.dispatch('layout/setLayoutHeader', {
      title: this.signup_view_model.translate('registry'),
      right_action: {
        step: 1,
        label: this.signup_view_model.translate('already_have_an_account'),
        action: {
          type: 'link',
          href: '/login',
          label: this.signup_view_model.translate('login'),
        },
      },
    });
  }
}

