// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { GlobalTrackingRepository } from '../../../domain/repositories/global-tracking-repository';
import { GlobalTrackingEntity } from '../../../domain/entities/global-tracking-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class GlobalTrackingDummyRepository implements GlobalTrackingRepository {
  get(): Promise<GlobalTrackingEntity> {
    return Promise.resolve(fixtures);
  }
}
