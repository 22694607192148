// Domain
import {
  EmergencyFundAvailabilityEntity,
} from '@/modules/flagship/catalogs/emergency-fund-availability/domain/entities/emergency-fund-availability-entity';

export default class EmergencyFundAvailabilityState {
  loaded = false;

  items: Array<EmergencyFundAvailabilityEntity> = [];
}
