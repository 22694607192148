import TYPES from '@/modules/shared/types';

// Domain
import {
  AccessMoreStrategiesRepository,
} from '@/modules/my-investment/allianz/access-more-strategies/domain/repositories/access-more-strategies-repository';
import {
  AccesssMoreStrategiesDto,
} from '@/modules/my-investment/allianz/access-more-strategies/domain/dtos/access-more-strategies-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class AccessMoreStrategiesHttpRepository
implements AccessMoreStrategiesRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/allianz/access-more-strategies/`;

  create(access_more_strategies_dto: AccesssMoreStrategiesDto): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint, 'POST', access_more_strategies_dto)
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => {
                reject(new Error(message));
              },
            );
          }
        })
        .catch(reject);
    });
  }
}
