import TYPES from '@/types';

// Domain
import { Router } from '@/modules/shared/domain/router';
import Inject from '@/modules/shared/domain/di/inject';
import { Route } from '@/modules/shared/domain/dtos';

const SECTIONS: Record<string, string> = {
  personal_information: 'UserProfilePersonalInformationTab',
  security: 'UserProfileSecurityTab',
  promotional_code: 'UserProfilePromotionalCodeTab',
  personality_profile: 'UserProfilePersonalityProfileTab',
  delete_account: 'UserProfileDeleteAccountTab',
};

export default class UserProfileTabViewModel {
  @Inject(TYPES.ROUTER)
  private readonly router!: Router;

  current_section = '';

  initialize = () => {
    this.addUserProfileMenuActiveTabChangeEventListener();
    this.setCurrentSectionFromRoute();
  }

  removeUserProfileMenuActiveTabChangeEventListener = () => {
    window.removeEventListener('user-profile-menu.active_tab', this.updateCurrentSection);
    window.removeEventListener('user-profile', this.setCurrentSectionFromRoute);
  }

  private setCurrentSectionFromRoute = (event?: CustomEventInit) => {
    const route = event?.detail.route as Route;

    const pages = (event ? route?.hash : this.router.current_path.hash)?.replace('#', '').split('_').map(
      (item) => item.replaceAll('-', '_'),
    );

    if (pages?.length) {
      this.current_section = SECTIONS[pages[0]];
    } else {
      this.current_section = '';
    }
  }

  private addUserProfileMenuActiveTabChangeEventListener = () => {
    window.addEventListener('user-profile-menu.active_tab', this.updateCurrentSection);
    window.addEventListener('user-profile', this.setCurrentSectionFromRoute);
  }

  private updateCurrentSection = (event: CustomEventInit) => {
    this.current_section = SECTIONS[event.detail.active_tab];
  }
}
