import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import SupportTicketDummyRepository
  from '@/modules/zendesk/support-ticket/infrastructure/repositories/support-ticket-dummy-repository';
import SupportTicketHttpRepository
  from '@/modules/zendesk/support-ticket/infrastructure/repositories/support-ticket-http-repository';

// Application
import CreateSupportTicketCommand from '@/modules/zendesk/support-ticket/application/commands/create-support-ticket-command';

// Domain
import { SupportTicketRepository } from '@/modules/zendesk/support-ticket/domain/repositories/support-ticket-repository';

export default function setupSupportTicket(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<SupportTicketRepository>(TYPES.SUPPORT_TICKET_REPOSITORY)
    .to(
      withDummies
        ? SupportTicketDummyRepository
        : SupportTicketHttpRepository,
    ).inSingletonScope();

  container
    .bind<CreateSupportTicketCommand>(TYPES.CREATE_SUPPORT_TICKET_COMMAND)
    .to(CreateSupportTicketCommand)
    .inSingletonScope();
}
