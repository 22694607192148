import POLICIES from '../security/policies';
import OnBoardingLayout from '@/vue-app/layouts/OnBoardingLayout.vue';
import OnBoarding from '@/vue-app/views/OnBoarding.vue';
import dashboards_routes from './dashboards_routes';
import profileRoutes from './profile-routes';
import updateIdentityDocument from './update-identity-document';

export default [
  dashboards_routes,
  profileRoutes,
  {
    path: '/on-boarding',
    component: OnBoardingLayout,
    children: [
      {
        path: '/',
        name: 'OnBoarding',
        component: OnBoarding,
        meta: {
          access_policy: POLICIES.AUTHENTICATED,
        },
      },
    ],
  },
  updateIdentityDocument,
];
