import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import RecommendedInvestmentProductDummyRepository
  from '@/modules/flagship/investor-profile/recommended-investment-product/infraestructure/repositories/recommended-investment-product-dummy-repository/recommended-investment-product-dummy-repository';
import RecommendedInvestmentProductHttpRepository
  from '@/modules/flagship/investor-profile/recommended-investment-product/infraestructure/repositories/recommended-investment-product-http-repository';
import RecommendedInvestmentProductVueStateManager
  from '@/modules/flagship/investor-profile/recommended-investment-product/infraestructure/state/recommended-investment-product-vue-state-manager';

// Application
import GetRecommendedInvestmentProductQuery
  from '@/modules/flagship/investor-profile/recommended-investment-product/application/queries/get-recommended-investment-product-query';

// Domain
import {
  RecommendedInvestmentProductRepository,
} from '@/modules/flagship/investor-profile/recommended-investment-product/domain/repositories/recommended-investment-product-repository';
import {
  RecommendedInvestmentProductStateManager,
} from '@/modules/flagship/investor-profile/recommended-investment-product/domain/state/recommended-investment-product-state-manager';

export default function setupRecommendedProductFlagship(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<RecommendedInvestmentProductStateManager>(
      TYPES.RECOMMENDED_INVESTMENT_PRODUCT_STATE_MANAGER,
    ).to(RecommendedInvestmentProductVueStateManager)
    .inSingletonScope();

  container
    .bind<RecommendedInvestmentProductRepository>(
      TYPES.RECOMMENDED_INVESTMENT_PRODUCT_REPOSITORY,
    ).to(
      withDummies
        ? RecommendedInvestmentProductDummyRepository
        : RecommendedInvestmentProductHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetRecommendedInvestmentProductQuery>(
      TYPES.GET_RECOMMENDED_INVESTMENT_PRODUCT_QUERY,
    ).to(GetRecommendedInvestmentProductQuery)
    .inSingletonScope();
}
