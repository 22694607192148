import Vue from 'vue';

// Domain
import { CustomGoalTypesStateManager } from '@/modules/flagship/catalogs/custom-goal-types/domain/state/custom-goal-types-state-manager';
import CustomGoalTypesState from '@/modules/flagship/catalogs/custom-goal-types/domain/state/custom-goal-types-state';
import Injectable from '@/modules/shared/domain/di/injectable';
import Observer from '@/modules/shared/domain/observer/observer';

@Injectable()
export default class CustomGoalTypesVueStateManager implements CustomGoalTypesStateManager {
  // eslint-disable-next-line max-len
  private _state: CustomGoalTypesState = Vue.observable<CustomGoalTypesState>(new CustomGoalTypesState());

  private readonly observers: Observer[] = [];

  get state(): CustomGoalTypesState {
    return this._state;
  }

  set state(value: CustomGoalTypesState) {
    this._state = value;
    this.notifyAll();
  }

  patch(state: Partial<CustomGoalTypesState>) {
    type Keys = keyof CustomGoalTypesState;
    Object.entries(state).forEach(([key, value]) => {
      const accessor = key as Keys;

      if (value !== undefined) {
        // eslint-disable-next-line
        // @ts-ignore @typescript-eslint/ban-ts-ignore
        this.state[accessor] = value;
      }
    });
  }

  notifyAll() {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer) {
    this.observers.push(observer);
  }
}
