import goals_undefined from './goals-undefined.json';
import link_goals from './link_goals.json';
import distribute_balance_unallocated from './distribute-balance-unallocated.json';

export default {
  goals: {
    ...goals_undefined,
    ...link_goals,
    ...distribute_balance_unallocated,
    errors: {
      obtain_investor_profile: 'Ocurrio un error al cargar la información de metas',
    },
  },
};
