import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import { AuthenticationDataDummyRepository, AuthenticationDataHttpRepository }
  from './infrastructure/repositories/authentication-data-repository';
import { JwtDummyRepository, JwtHttpRepository }
  from './infrastructure/repositories/jwt-repository';
import { ForgotPasswordDummyRepository, ForgotPasswordHttpRepository }
  from './infrastructure/repositories/forgot-password-repository';
import { RecoveryPasswordDummyRepository, RecoveryPasswordHttpRepository }
  from './infrastructure/repositories/recovery-password-repository';
import { TokenRefreshDummyRepository, TokenRefreshHttpRepository }
  from './infrastructure/repositories/token-refresh-repository';
import VueStateManager from './infrastructure/state/vue-state-manager';

// Application
import {
  SignInCommand,
  ValidateJwtCommand,
  ForgotPasswordCommand,
  RecoveryPasswordCommand,
  TokenRefreshCommand,
  SignOutCommand,
}
  from './application/commands';
import GetAuthenticationStateService
  from './application/services/get-authentication-state-service';
import CloseSessionWhenTokenExpiresJob from './application/jobs/close-session-when-token-expires-job';
import SignOutEmitter from '@/modules/authentication/application/emitters/sign-out-emitter';

// Domain
import {
  AuthenticationDataRepository,
  JwtRepository,
  ForgotPasswordRepository,
  RecoveryPasswordRepository,
  TokenRefreshRepository,
} from './domain/repositories';
import { StateManager } from './domain/state/state-manager';

export default function setupAuthenticationModule(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.AUTHENTICATION_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<SignInCommand>(TYPES.SIGN_IN_COMMAND)
    .to(SignInCommand)
    .inSingletonScope();

  container
    .bind<AuthenticationDataRepository>(TYPES.AUTHENTICATION_DATA_REPOSITORY)
    .to(
      withDummies
        ? AuthenticationDataDummyRepository
        : AuthenticationDataHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<JwtRepository>(TYPES.AUTHENTICATION_JWT_REPOSITORY)
    .to(
      withDummies
        ? JwtDummyRepository
        : JwtHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<ValidateJwtCommand>(TYPES.AUTHENTICATION_VALIDATE_JWT_COMMAND)
    .to(ValidateJwtCommand)
    .inSingletonScope();

  container
    .bind<GetAuthenticationStateService>(TYPES.GET_AUTHENTICATION_STATE_SERVICE)
    .to(GetAuthenticationStateService)
    .inSingletonScope();

  container
    .bind<CloseSessionWhenTokenExpiresJob>(TYPES.CLOSE_SESSION_WHEN_TOKEN_EXPIRES_JOB)
    .to(CloseSessionWhenTokenExpiresJob)
    .inSingletonScope();

  container
    .bind<ForgotPasswordCommand>(TYPES.FORGOT_PASSWORD_COMMAND)
    .to(ForgotPasswordCommand)
    .inSingletonScope();

  container
    .bind<ForgotPasswordRepository>(TYPES.FORGOT_PASSWORD_REPOSITORY)
    .to(
      withDummies
        ? ForgotPasswordDummyRepository
        : ForgotPasswordHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<RecoveryPasswordCommand>(TYPES.RECOVERY_PASSWORD_COMMAND)
    .to(RecoveryPasswordCommand)
    .inSingletonScope();

  container
    .bind<RecoveryPasswordRepository>(TYPES.RECOVERY_PASSWORD_REPOSITORY)
    .to(
      withDummies
        ? RecoveryPasswordDummyRepository
        : RecoveryPasswordHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<TokenRefreshRepository>(TYPES.TOKEN_REFRESH_REPOSITORY)
    .to(
      withDummies
        ? TokenRefreshDummyRepository
        : TokenRefreshHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<TokenRefreshCommand>(TYPES.TOKEN_REFRESH_COMMAND)
    .to(TokenRefreshCommand)
    .inSingletonScope();

  container
    .bind<SignOutCommand>(TYPES.SIGN_OUT_COMMAND)
    .to(SignOutCommand)
    .inSingletonScope();

  container
    .bind<SignOutEmitter>(TYPES.SIGN_OUT_EMITTER)
    .to(SignOutEmitter)
    .inSingletonScope();
}
