// Domain
import { TotalWithdrawalRepository } from '../../../domain/repository';
import { CreateTotalWithdrawalDto } from '../../../domain/dtos/create-total-withdrawal-dto';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class TotalWithdrawalDummyRepository implements TotalWithdrawalRepository {
  create(total_withdrawal_dto: CreateTotalWithdrawalDto): Promise<void> {
    return Promise.resolve();
  }
}
