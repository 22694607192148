import TYPES from '../../types';

// Domain
import {
  RetirementInvestorGoalRepository,
} from '@/modules/flagship/retirement-investor-goal/domain/repositories/retirement-investor-goal-repository';
import {
  RetirementInvestorGoalDto,
} from '@/modules/flagship/retirement-investor-goal/domain/dtos/retirement-investor-goal-dto';
import { RetirementInvestorGoalStateManager } from '@/modules/flagship/retirement-investor-goal/domain/state/retirement-investor-goal-state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetRetirementInvestorGoalQuery
  extends Query<Promise<RetirementInvestorGoalDto>> {
  @Inject(TYPES.RETIREMENT_INVESTOR_GOAL_REPOSITORY)
  private readonly repository!: RetirementInvestorGoalRepository;

  @Inject(TYPES.RETIREMENT_INVESTOR_GOAL_STATE_MANAGER)
  private readonly state_manager!: RetirementInvestorGoalStateManager;

  internalExecute(reload = false): Promise<RetirementInvestorGoalDto> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded && !reload) {
        resolve(this.state_manager.state.retirement_investor_goal);
      } else {
        this.repository.get()
          .then((retirement_investor_goal) => {
            this.state_manager.patch({ retirement_investor_goal, loaded: true });
            resolve(retirement_investor_goal);
          })
          .catch(reject);
      }
    });
  }
}
