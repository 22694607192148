import TYPES from '../../types';

// Domain
import { CustomGoalMaximumInitialAmountCalculationEntity } from '@/modules/flagship/custom-investor-goal-calculator/domain/entities/custom-goal-maximum-initial-amount-calculation-entity';
import { CustomInvestorGoalCalculatorRepository } from '@/modules/flagship/custom-investor-goal-calculator/domain/repositories/custom-investor-goal-calculator-repository';
import { CustomGoalMaximumInitialAmountCalculationDto } from '@/modules/flagship/custom-investor-goal-calculator/domain/dtos/custom-goal-maximum-initial-amount-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
class CalculateCustomGoalMaximumInitialAmountQuery extends Query<
  Promise<CustomGoalMaximumInitialAmountCalculationEntity>
> {
  @Inject(TYPES.CUSTOM_INVESTOR_GOAL_CALCULATOR_REPOSITORY)
  private readonly repository!: CustomInvestorGoalCalculatorRepository;

  internalExecute(
    payload: CustomGoalMaximumInitialAmountCalculationDto,
  ): Promise<CustomGoalMaximumInitialAmountCalculationEntity> {
    return this.repository.calculateMaximumInitialAmount(payload);
  }
}

export default CalculateCustomGoalMaximumInitialAmountQuery;
