import {
  CustomerContractedInvestmentProductsEntity,
} from '@/modules/flagship/customer-contracted-investment-products/domain/entities/customer-contracted-investment-products-entity';

export default class CustomerContractedInvestmentProductsState {
  loaded = false;

  item: CustomerContractedInvestmentProductsEntity = {
    has_any_product_contracted: false,
    has_sowos_wealth_contracted: false,
    has_sowos_pocket_contracted: false,
  };
}
