import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import InvestorProfileOnEmergencyFundDummyRepository
  from '@/modules/flagship/investor-profile/emergency-fund/infraestructure/repositories/investor-profile-on-emergency-fund-dummy-repository';
import InvestorProfileOnEmergencyFundHttpRepository
  from '@/modules/flagship/investor-profile/emergency-fund/infraestructure/repositories/investor-profile-on-emergency-fund-http-repository';

// Application
import UpdateInvestorProfileOnEmergencyFundCommand
  from '@/modules/flagship/investor-profile/emergency-fund/application/commands/update-investor-profile-on-emergency-fund-command';

// Domain
import {
  InvestorProfileOnEmergencyFundRepository,
} from '@/modules/flagship/investor-profile/emergency-fund/domain/repositories/investor-profile-on-emergency-fund-repository';

export default function setupEmergencyFund(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<InvestorProfileOnEmergencyFundRepository>(
      TYPES.INVESTOR_PROFILE_ON_EMERGENCY_FUND_REPOSITORY,
    ).to(
      withDummies
        ? InvestorProfileOnEmergencyFundDummyRepository
        : InvestorProfileOnEmergencyFundHttpRepository,
    ).inSingletonScope();

  container
    .bind<UpdateInvestorProfileOnEmergencyFundCommand>(
      TYPES.UPDATE_INVESTOR_PROFILE_ON_EMERGENCY_FUND_COMMAND,
    ).to(UpdateInvestorProfileOnEmergencyFundCommand)
    .inSingletonScope();
}
