import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import LinkSowosPocketDummyRepository from './infrastructure/repositories/link-sowos-pocket-dummy-repository';
import LinkSowosPocketHttpRepository from './infrastructure/repositories/link-sowos-pocket-http-repository';

// Application
import CreateLinkSowosPocketCommand from './application/commands/create-link-sowos-pocket-command';

// Domain
import { LinkSowosPocketRepository } from './domain/repositories/link-sowos-pocket-repository';

export default function setupLinkSowosPocket(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<LinkSowosPocketRepository>(
      TYPES.LINK_SOWOS_POCKET_REPOSITORY,
    ).to(
      withDummies
        ? LinkSowosPocketDummyRepository
        : LinkSowosPocketHttpRepository,
    ).inSingletonScope();

  container
    .bind<CreateLinkSowosPocketCommand>(
      TYPES.CREATE_LINK_SOWOS_POCKET_COMMAND,
    ).to(CreateLinkSowosPocketCommand)
    .inSingletonScope();
}
