import TYPES from '../../types';

// Domain
import {
  RetirementGoalMonthlyPaymentCalculationEntity,
} from '@/modules/flagship/retirement-goal-calculations/domain/entities/retirement-goal-monthly-payment-calculation-entity';
import {
  RetirementGoalMonthlyPaymentCalculationRepository,
} from '@/modules/flagship/retirement-goal-calculations/domain/repositories/retirement-goal-monthly-payment-calculation-repository';
import {
  RetirementGoalMonthlyPaymentCalculationDto,
} from '@/modules/flagship/retirement-goal-calculations/domain/dtos/retirement-goal-monthly-payment-calculation-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
class CalculateRetirementGoalMonthlyPaymentCalculationQuery
  extends Query<Promise<RetirementGoalMonthlyPaymentCalculationEntity>> {
  @Inject(TYPES.RETIREMENT_GOAL_MONTHLY_PAYMENT_CALCULATION_REPOSITORY)
  private readonly repository!: RetirementGoalMonthlyPaymentCalculationRepository;

  internalExecute(
    payload: RetirementGoalMonthlyPaymentCalculationDto,
  ): Promise<RetirementGoalMonthlyPaymentCalculationEntity> {
    return this.repository.calculate(payload);
  }
}

export default CalculateRetirementGoalMonthlyPaymentCalculationQuery;
