import { Container } from 'inversify-props';
import setupCountry from './country/setup';
import setupState from './state/setup';
import setupISOCountry from './iso-country/setup';
import setupActivity from './activity/setup';
import setupRelationship from './relationship/setup';
import setupOccupation from './occupation/setup';
import setupResourceOrigin from './resource-origin/setup';
import setupDocumentType from './document-type/setup';
import setupFinancialInstitution from './financial-institution/setup';
import setupPeriodicityOfContribution from './periodicity-of-contribution/setup';
import setupMunicipality from './municipality/setup';
import setupSettlement from './settlement/setup';
import setupTemplateQuestionnaire from './template-questionnaire/setup';
import setupSourceIncome from './source-income/setup';

export default function setupAllianz(container: Container) {
  setupCountry(container);
  setupState(container);
  setupISOCountry(container);
  setupActivity(container);
  setupRelationship(container);
  setupOccupation(container);
  setupResourceOrigin(container);
  setupDocumentType(container);
  setupFinancialInstitution(container);
  setupPeriodicityOfContribution(container);
  setupMunicipality(container);
  setupSettlement(container);
  setupTemplateQuestionnaire(container);
  setupSourceIncome(container);
}
