import kuspitUser from './kuspit-user.json';
import linkAccount from './link-account.json';
import verifyNip from './verify-nip.json';

export default {
  'onboarding-kuspit': {
    ...kuspitUser,
    ...linkAccount,
    ...verifyNip,
  },
};
