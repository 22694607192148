// Domain
import { CreateTransactionRepository } from '../../../domain/repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CreateTransactionDummyRepository implements CreateTransactionRepository {
  create(): Promise<void> {
    return Promise.resolve();
  }
}
