import TYPES from '../../types';

// Domain
import { AvailableToHireStrategyModerateEntity } from '@/modules/flagship/strategy-moderate/hire/domain/entities/availabe-to-hire-strategy-moderate-entity';
import { AvailableToHireStrategyModerateStateManager } from '@/modules/flagship/strategy-moderate/hire/domain/state/available-to-hire-strategy-moderate-state-manager';
import { StrategySowosModerateRepository } from '@/modules/flagship/strategy-moderate/hire/domain/repositories/strategy-sowos-moderate-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class GetIsAvailableToHireStrategyModerateQuery extends
  Query<Promise<AvailableToHireStrategyModerateEntity>> {
  @Inject(TYPES.STRATEGY_SOWOS_MODERATE_REPOSITORY)
  private readonly repository!: StrategySowosModerateRepository;

  @Inject(TYPES.AVAILABLE_TO_HIRE_STRATEGY_MODERATE_STATE_MANAGER)
  private readonly state_manager!: AvailableToHireStrategyModerateStateManager;

  internalExecute(): Promise<AvailableToHireStrategyModerateEntity> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded) {
        resolve(this.state_manager.state.item);
      } else {
        this.repository.get_is_available_to_hire_strategy_moderate()
          .then((item) => {
            if (!item.moderate_is_available_to_contract) {
              this.state_manager.patch({ item, loaded: true });
            }
            resolve(item);
          })
          .catch(reject);
      }
    });
  }
}
