import TYPES from '../../types';

// Domain
import { MovementEntity } from '../../domain/entities/movement-entity';
import {
  AllianzAccountMovementReportRepository,
} from '../../domain/repository/allianz-account-movement-report-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';
import {
  SearchMovementDto,
} from '@/modules/my-investment/allianz/allianz-account-movement-report/domain/dtos/search-movement-dto';

@Injectable()
export default class SearchAllianzAccountMovementsReportQuery
  extends Query<Promise<Array<MovementEntity>>> {
  @Inject(TYPES.ALLIANZ_ACCOUNT_MOVEMENT_REPORT_REPOSITORY)
  private readonly repository!: AllianzAccountMovementReportRepository;

  internalExecute(search_movement_dto: SearchMovementDto): Promise<Array<MovementEntity>> {
    return new Promise((resolve, reject) => {
      this.repository.execute(search_movement_dto)
        .then((items) => {
          resolve(items);
        })
        .catch(reject);
    });
  }
}
