import attachedNewAccountStatement from './attached-new-account-statement.json';
import attachedNewProofOfAddress from './attached-new-proof-of-address.json';
import documentSuccessfullySent from './document-successfully-sent.json';
import attachedNewProofOfTaxSituation from './attached-new-proof-of-tax-situation.json';

export default {
  'document-validation': {
    ...attachedNewAccountStatement,
    ...attachedNewProofOfAddress,
    ...documentSuccessfullySent,
    ...attachedNewProofOfTaxSituation,
  },
};
