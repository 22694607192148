import fixtures from './fixtures.json';

// Domain
import { MagicLinkRepository } from '../../../domain/repositories/magic-link-repository';
import { MagicLinkEntity } from '../../../domain/entities/magic-link-entity';
import { MagicLinkDto } from '../../../domain/dtos/magic-link-dto';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class MagicLinkDummyRepository implements MagicLinkRepository {
  verify_token(magic_link_dto: MagicLinkDto): Promise<MagicLinkEntity> {
    return Promise.resolve(fixtures);
  }
}
