import UnautheneticatedLayout from '@/vue-app/layouts/UnauthenticatedLayout.vue';
import ForgotPassword from '@/vue-app/views/ForgotPassword.vue';
import POLICIES from '../security/policies';

export default {
  path: '/forgot-password',
  component: UnautheneticatedLayout,
  children: [
    {
      path: '/',
      name: 'ForgotPassword',
      component: ForgotPassword,
      meta: {
        access_policy: POLICIES.UNAUTHENTICATED,
      },
    },
  ],
};
