import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import AccessMoreStrategiesVueStateManager from '@/modules/my-investment/allianz/access-more-strategies/infrastructure/access-more-strategies-vue-state-manager';
import InvestorProfileOnFinancialHabitsHttpRepository from '@/modules/my-investment/allianz/access-more-strategies/infrastructure/repositories/investor-profile-on-financial-habits-http-repository';
import InvestorProfileOnFinancialHabitsDummyRepository from '@/modules/my-investment/allianz/access-more-strategies/infrastructure/repositories/investor-profile-on-financial-habits-dummy-repository';
import AccessMoreStrategiesHttpRepository from '@/modules/my-investment/allianz/access-more-strategies/infrastructure/repositories/access-more-strategies-http-repository';
import AccessMoreStrategiesDummyRepository from '@/modules/my-investment/allianz/access-more-strategies/infrastructure/repositories/access-more-strategies-dummy-repository';

// Application
import AccessMoreStrategiesStateManagerService from '@/modules/my-investment/allianz/access-more-strategies/application/services/access-more-strategies-state-manager-service';
import UpdateInvestorProfileOnFinancialHabitsCommand
  from '@/modules/my-investment/allianz/access-more-strategies/application/commands/update-investor-profile-on-financial-habits-command';
import CreateInvestmentFundHireCommand
  from '@/modules/my-investment/allianz/access-more-strategies/application/commands/create-investment-fund-hire-command';

// Domain
import { AccessMoreStrategiesStateManager } from '@/modules/my-investment/allianz/access-more-strategies/domain/state/access-more-strategies-state-manager';
import { InvestorProfileOnFinancialHabitsRepository } from '@/modules/my-investment/allianz/access-more-strategies/domain/repositories/investor-profile-on-financial-habits-repository';
import { AccessMoreStrategiesRepository } from '@/modules/my-investment/allianz/access-more-strategies/domain/repositories/access-more-strategies-repository';

export default function setupAccessMoreStrategies(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<InvestorProfileOnFinancialHabitsRepository>(
      TYPES.INVESTOR_PROFILE_ON_FINANCIAL_HABITS_REPOSITORY,
    ).to(
      withDummies
        ? InvestorProfileOnFinancialHabitsDummyRepository
        : InvestorProfileOnFinancialHabitsHttpRepository,
    ).inSingletonScope();

  container
    .bind<UpdateInvestorProfileOnFinancialHabitsCommand>(
      TYPES.UPDATE_INVESTOR_PROFILE_ON_FINANCIAL_HABITS_COMMAND,
    ).to(UpdateInvestorProfileOnFinancialHabitsCommand)
    .inSingletonScope();

  container
    .bind<AccessMoreStrategiesStateManager>(
      TYPES.ACCESS_MORE_STRATEGIES_STATE_MANAGER,
    )
    .to(AccessMoreStrategiesVueStateManager)
    .inSingletonScope();

  container
    .bind<AccessMoreStrategiesStateManagerService>(
      TYPES.ACCESS_MORE_STRATEGIES_STATE_MANAGER_SERVICE,
    ).to(AccessMoreStrategiesStateManagerService)
    .inSingletonScope();

  container
    .bind<AccessMoreStrategiesRepository>(
      TYPES.ACCESS_MORE_STRATEGIES_REPOSITORY,
    ).to(
      withDummies
        ? AccessMoreStrategiesDummyRepository
        : AccessMoreStrategiesHttpRepository,
    ).inSingletonScope();

  container
    .bind<CreateInvestmentFundHireCommand>(
      TYPES.CREATE_INVESTMENT_FUND_HIRE_COMMAND,
    ).to(CreateInvestmentFundHireCommand)
    .inSingletonScope();
}
