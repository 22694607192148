import TYPES from '../../types';

// Domain
import { ForgotPassword } from '../../domain/dtos';
import { ForgotPasswordRepository } from '../../domain/repositories';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class ForgotPasswordCommand extends Command<ForgotPassword> {
  @Inject(TYPES.FORGOT_PASSWORD_REPOSITORY)
  private readonly forgot_password_repository!: ForgotPasswordRepository;

  internalExecute(payload: ForgotPassword): Promise<void> {
    return this.forgot_password_repository.restore(payload);
  }
}
