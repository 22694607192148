import fixtures from './fixtures.json';
import Injectable from '@/modules/shared/domain/di/injectable';
import SearchPersonRepository from '../../../../domain/repositories/get-person-repository';

@Injectable()
export default class GetPersonDummyRepository implements SearchPersonRepository {
  // eslint-disable-next-line class-methods-use-this
  async get() {
    return Promise.resolve(fixtures);
  }
}
