import TYPES from '@/modules/shared/types';

// Domain
import {
  SearchByCustomerRepository,
} from '../../domain/repositories/search-by-customer-repository';
import {
  SearchByCustomerEntity,
} from '../../domain/entities/search-by-customer-entity';
import {
  SearchByCustomerDto,
} from '../../domain/dtos/search-by-customer-dto';
import {
  ActiveGoalAmountByFundTypeEntity,
} from '@/modules/flagship/investor-goal/search-by-customer/domain/entities/active-goal-amount-by-fund-type-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class SearchByCustomerHttpRepository implements SearchByCustomerRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/investor-goal/search-by-customer?associated_product_id={associated_product_id}&is_active={is_active}`;

  private readonly endpoint_active_goal_amount_by_fund_type = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/investor-goal/fund-type/{investment_product_fund_type_id}/amounts/`;

  // eslint-disable-next-line max-len
  get(search_by_customer_dto: SearchByCustomerDto): Promise<Array<SearchByCustomerEntity>> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint.replace('{is_active}', String(search_by_customer_dto.is_active)).replace('{associated_product_id}', search_by_customer_dto.associated_product_id))
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(JSON.stringify({
                  status_code: response.status,
                  message_error: message,
                }));
              }
            });
        })
        .catch(reject);
    });
  }

  get_active_goals_amounts_by_fund_type(investment_product_fund_type_id: string):
    Promise<Array<ActiveGoalAmountByFundTypeEntity>> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint_active_goal_amount_by_fund_type.replace('{investment_product_fund_type_id}', investment_product_fund_type_id))
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(JSON.stringify({
                  status_code: response.status,
                  message_error: message,
                }));
              }
            });
        })
        .catch(reject);
    });
  }
}
