import TYPES from '@/types';

// Application
import { ResetIdentityVerificationStatus } from '@/modules/on-boarding/identity-verification/application/services';
import GetOnBoardingPersonQuery from '@/modules/on-boarding/person/application/queries/get-on-boarding-person-query';
import SignOutService from '@/modules/authentication/application/services/sign-out-service';

// Domain
import { Values } from '@/modules/shared/domain/i18n/types';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Router } from '@/modules/shared/domain/router';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class UpdateIdentityDocumentViewModel {
  @Inject(TYPES.ON_BOARDING_RESET_IDENTITY_VERIFICATION_STATUS)
  readonly reset_identity_verification_status!: ResetIdentityVerificationStatus;

  @Inject(TYPES.GET_ON_BOARDING_PERSON_QUERY)
  private readonly get_onboarding_person!: GetOnBoardingPersonQuery;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.ROUTER)
  private readonly router!: Router;

  private readonly i18n_namespace = 'components.update-identity-document';

  identity_document_updated = false;

  allow_update_identity_document = false;

  authenticated_customer = false;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  goToLoginPage = () => {
    window.location.href = '/login';
  }

  deleteTokenFromStorage = () => {
    sessionStorage.clear();
  }

  setUserId = (token: string) => {
    const payload = JSON.parse(Buffer.from(token.split('.')[1], 'base64')
      .toString());
    sessionStorage.setItem('user_id', payload.customer_id);
  }

  setTokenToStorage = (token: string) => {
    sessionStorage.setItem('authorization', token);
  }

  showConfirmation = () => {
    this.identity_document_updated = true;
  }

  validateIdentityDocumentExpired = async () => {
    const data = await this.get_onboarding_person.execute();
    if (data.identity_document_expiration_date
      && new Date() < new Date(data.identity_document_expiration_date)) {
      this.message_notifier.showInfoNotification(this.translate('ine_no_expired'));
      setTimeout(async () => {
        await new SignOutService().logout('/');
      }, 5000);
    } else {
      this.allow_update_identity_document = true;
    }
  }

  initialize = async (token: string) => {
    this.setTokenToStorage(token);
    this.setUserId(token);
    await this.validateIdentityDocumentExpired();
  }
}
