import TYPES from '../../types';

// Domain
import {
  InvestorGoalScheduledDistributionWealthFlexibleDto,
} from '../../domain/dto/investor-goal-scheduled-distribution-wealth-flexible-dto';
import {
  InvestorGoalScheduledDistributionWealthFlexibleRepository,
} from '../../domain/repositories/investor-goal-scheduled-distribution-wealth-flexible-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class InvestorGoalScheduledDistributionWealthFlexibleCommand
  extends Command<InvestorGoalScheduledDistributionWealthFlexibleDto> {
  @Inject(TYPES.INVESTOR_GOAL_SCHEDULED_DISTRIBUTION_WEALTH_FLEXIBLE_REPOSITORY)
  private readonly repository!: InvestorGoalScheduledDistributionWealthFlexibleRepository;

  // eslint-disable-next-line max-len
  internalExecute(investor_goal_scheduled_distribution_wealth_flexible_dto: InvestorGoalScheduledDistributionWealthFlexibleDto):
    Promise<void> {
    return this.repository.distribute(investor_goal_scheduled_distribution_wealth_flexible_dto);
  }
}
