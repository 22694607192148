// Infrastructure
import fixtures from './fixtures.json';

// Domain
import {
  ExitPollOptionsRepository,
} from '@/modules/flagship/catalogs/exit-poll-options/domain/repositories/exit-poll-options-repository';
import {
  ExitPollOptionsEntity,
} from '@/modules/flagship/catalogs/exit-poll-options/domain/entities/exit-poll-options-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class ExitPollOptionsDummyRepository implements ExitPollOptionsRepository {
  execute(): Promise<Array<ExitPollOptionsEntity>> {
    return Promise.resolve(fixtures);
  }
}
