// Infrastructure
import available_to_hire_strategy_moderate_fixtures from './available_to_hire_strategy_moderate_fixtures.json';

// Domain
import {
  StrategySowosModerateRepository,
} from '@/modules/flagship/strategy-moderate/hire/domain/repositories/strategy-sowos-moderate-repository';
import { AvailableToHireStrategyModerateEntity } from '@/modules/flagship/strategy-moderate/hire/domain/entities/availabe-to-hire-strategy-moderate-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class StrategySowosModerateDummyRepository
implements StrategySowosModerateRepository {
  hire(): Promise<void> {
    return Promise.resolve();
  }

  get_is_available_to_hire_strategy_moderate(): Promise<AvailableToHireStrategyModerateEntity> {
    return Promise.resolve(available_to_hire_strategy_moderate_fixtures);
  }
}
