import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import InvestorProfileDummyRepository
  from '@/modules/flagship/investor-profile/investor-profile/infrastructure/repositories/investor-profile-dummy-repository/investor-profile-dummy-repository';
import InvestorProfileHttpRepository
  from '@/modules/flagship/investor-profile/investor-profile/infrastructure/repositories/investor-profile-http-repository';
import InvestorProfileVueStateManager
  from '@/modules/flagship/investor-profile/investor-profile/infrastructure/state/investor-profile-vue-state-manager';

// Application
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';
import UpdateInvestorProfileCommand
  from '@/modules/flagship/investor-profile/investor-profile/application/commands/update-investor-profile-command';

// Domain
import {
  InvestorProfileRepository,
} from '@/modules/flagship/investor-profile/investor-profile/domain/repositories/investor-profile-repository';
import {
  InvestorProfileStateManager,
} from '@/modules/flagship/investor-profile/investor-profile/domain/state/investor-profile-state-manager';

export default function setupInvestorProfile(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<InvestorProfileStateManager>(TYPES.INVESTOR_PROFILE_STATE_MANAGER)
    .to(InvestorProfileVueStateManager)
    .inSingletonScope();

  container
    .bind<InvestorProfileRepository>(
      TYPES.INVESTOR_PROFILE_REPOSITORY,
    ).to(
      withDummies
        ? InvestorProfileDummyRepository
        : InvestorProfileHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetInvestorProfileQuery>(
      TYPES.GET_INVESTOR_PROFILE_QUERY,
    ).to(GetInvestorProfileQuery)
    .inSingletonScope();

  container
    .bind<UpdateInvestorProfileCommand>(
      TYPES.UPDATE_INVESTOR_PROFILE_COMMAND,
    ).to(UpdateInvestorProfileCommand)
    .inSingletonScope();
}
