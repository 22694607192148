










































































































































import { Component, Vue } from 'vue-property-decorator';
import GoalsDashboardViewModel from '@/vue-app/view-models/views/goals-dashboard-view-model';

@Component({
  name: 'GoalsDashboard',
  components: {
    ProgressCard: () => import('@/vue-app/components/goals-dashboard/ProgressCard.vue'),
    DistributeUnassignedAmountCard: () => import('@/vue-app/components/goals-dashboard/DistributeUnassignedAmountCard.vue'),
    EmergencyFundCard: () => import('@/vue-app/components/goals-dashboard/EmergencyFundCard.vue'),
    RetirementFundCard: () => import('@/vue-app/components/goals-dashboard/RetirementFundCard.vue'),
    CustomInvestorGoalCard: () => import('@/vue-app/components/goals-dashboard/CustomInvestorGoalCard.vue'),
    LinkedEmergencyFundCard: () => import('@/vue-app/components/goals-dashboard/LinkedEmergencyFundCard.vue'),
    LinkedRetirementFundCard: () => import('@/vue-app/components/goals-dashboard/LinkedRetirementFundCard.vue'),
    LinkedCustomInvestorGoalCard: () => import('@/vue-app/components/goals-dashboard/LinkedCustomInvestorGoalCard.vue'),
    AddCustomInvestorGoalCard: () => import('@/vue-app/components/goals-dashboard/AddCustomInvestorGoalCard.vue'),
    ModifySwsModerateDialog: () => import('@/vue-app/components/strategy-moderate-wealth/modify-sws-moderate/ModifySwsModerateDialog.vue'),
    LinkStrategyModerateWealthDialog: () => import('@/vue-app/components/strategy-moderate-wealth/link-goal/LinkStrategyModerateWealthDialog.vue'),
    StrategyModerateWealthDialog: () => import('@/vue-app/components/strategy-moderate-wealth/StrategyModerateWealthDialog.vue'),
    StrategyModerateHiringFlowDialog: () => import('@/vue-app/components/strategy-moderate-wealth/hiring-flow/StrategyModerateHiringFlowDialog.vue'),
    StrategyModerateWealthExitPoll: () => import('@/vue-app/components/strategy-moderate-wealth/StrategyModerateWealthExitPoll.vue'),
    ContractingStatusModerateDialog: () => import('@/vue-app/components/strategy-moderate-wealth/contracting-status-moderate/ContractingStatusModerateDialog.vue'),
    LinkStatusModerateDialog: () => import('@/vue-app/components/strategy-moderate-wealth/link-status-moderate/LinkStatusModerateDialog.vue'),
  },
})
export default class GoalsDashboard extends Vue {
  goals_dashboard_view_model = Vue.observable(new GoalsDashboardViewModel());

  async created() {
    const set_title_event = new CustomEvent('nav-bar.title', {
      detail: {
        title: [
          {
            label: this.$i18n.t(`${this.goals_dashboard_view_model.i18n_namespace}.title`),
          },
        ],
      },
    });
    window.dispatchEvent(set_title_event);
    await this.goals_dashboard_view_model.initialize();
  }
}
