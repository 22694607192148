import { AllianzAccountEntity } from '../entities/allianz-account-entity';

export default class State {
  loaded = false;

  is_loading = false;

  account: AllianzAccountEntity = {
    investment_type_selected: '',
    next_payment_date: '',
    created_at: '',
    job: '',
    email: '',
    investment_currencies: false,
    investments_in_the_last_year: false,
    home_desire: false,
    home_phone_prefix: '',
    accept_investment_profile: false,
    additional_country_residence: '',
    nationality: '',
    is_not_politically_exposed_person: false,
    tax_residence_outside_of_mexico: false,
    investment_horizon: '',
    bank: '',
    amount_initial_contribution: 0,
    account: '',
    confirm_periodicity: false,
    main_source_of_income: '',
    amount: 0,
    derivatives: false,
    actions: false,
    labor_shift: '',
    policy_number_issued: '',
    policy_number: 0,
    authorize_account_statements: false,
    mobile_phone_prefix: '',
    country_of_birth: '',
    age_range: '',
    income_in_the_last_years: false,
    monthly_collection_day: 0,
    job_custom: '',
    country_of_residence: '',
    periodicity: '',
    beneficiaries: {
      beneficiaries: [],
    },
    investment_type_desired: '',
    estimated_deposit_amount: '',
    deb_instruments: false,
    home_phone: '',
    tax_id: '',
    id: '',
    updated_at: '',
    mobile_phone: '',
    has_minimum_balance_for_transfers: false,
    was_issued: false,
    customer_id: '',
    share_personal_information: false,
    investment_type_score: 0,
    estimated_withdrawal_transactions: '',
    resources_source: '',
    educational_level: '',
    investment_companies: false,
    previous_payment_date: '',
    labor_shift_custom: '',
    is_american_citizen: false,
    application_date: '',
    estimated_deposit_transactions: '',
    authorize_policy_performance: false,
    investment_type_from_score: '',
    estimated_withdrawal_amount: '',
    authorize_notifications: false,
    etfs: false,
  };
}
