import TYPES from '../../types';

// Domain
import { MovementEntity } from '../../domain/entities/movement-entity';
import {
  CustomerKuspitMovementRepository,
} from '../../domain/repository/customer-kuspit-movement-repository';
import { SearchMovementDto } from '../../domain/dtos/search-movement-dto';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class SearchCustomerKuspitMovementsQuery
  extends Query<Promise<Array<MovementEntity>>> {
  @Inject(TYPES.CUSTOMER_KUSPIT_MOVEMENT_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.CUSTOMER_KUSPIT_MOVEMENT_REPOSITORY)
  private readonly repository!: CustomerKuspitMovementRepository;

  internalExecute(search_movements_dto: SearchMovementDto): Promise<Array<MovementEntity>> {
    return new Promise((resolve, reject) => {
      if (
        this.state_manager.state.current_searcher.initial_date !== search_movements_dto.initial_date
        || this.state_manager.state.current_searcher.final_date !== search_movements_dto.final_date
        || search_movements_dto.refetch
      ) {
        this.repository.execute(search_movements_dto)
          .then((items) => {
            this.state_manager.patch({ items, current_searcher: search_movements_dto });
            resolve(items);
          })
          .catch(reject);
      } else {
        resolve(this.state_manager.state.items);
      }
    });
  }
}
