import TYPES from '../../types';

// Domain
import { UpdatePlanCustomGoalDto }
  from '@/modules/flagship/custom-investor-goal/domain/dtos/update-plan-custom-goal-dto';
import { CustomInvestorGoalRepository }
  from '@/modules/flagship/custom-investor-goal/domain/repositories/custom-investor-goal-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UpdatePlanCustomGoalCommand extends Command<UpdatePlanCustomGoalDto> {
  @Inject(TYPES.CUSTOM_INVESTOR_GOAL_REPOSITORY)
  private readonly repository!: CustomInvestorGoalRepository;

  internalExecute(update_plan_dto: UpdatePlanCustomGoalDto) {
    return this.repository.updatePlan(update_plan_dto);
  }
}
