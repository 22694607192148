import depositsAndWithdrawals from './deposits-and-withdrawals.json';
import work from './work.json';
import politicallyExposedPerson from './politically-exposed-person.json';
import politicallyExposedPersonError from './politically-exposed-person-error.json';
import investmentProfile from './investment-profile.json';
import results from './results.json';
import personalInformation from './personal-information.json';

export default {
  profiling: {
    ...depositsAndWithdrawals,
    ...work,
    ...politicallyExposedPerson,
    ...politicallyExposedPersonError,
    ...investmentProfile,
    ...results,
    ...personalInformation,
  },
};
