import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import IncomeBehaviorInFollowingYearDummyRepository
  from '@/modules/flagship/catalogs/income-behavior-in-following-year/infraestructure/repositories/income-behavior-in-following-year-dummy-repository/income-behavior-in-following-year-dummy-repository';
import IncomeBehaviorInFollowingYearHttpRepository
  from '@/modules/flagship/catalogs/income-behavior-in-following-year/infraestructure/repositories/income-behavior-in-following-year-http-repository';
import IncomeBehaviorInFollowingYearVueStateManager
  from '@/modules/flagship/catalogs/income-behavior-in-following-year/infraestructure/state/income-behavior-in-following-year-vue-state-manager';

// Application
import GetIncomeBehaviorInFollowingYearQuery
  from '@/modules/flagship/catalogs/income-behavior-in-following-year/application/queries/get-income-behavior-in-following-year-query';

// Domain
import {
  IncomeBehaviorInFollowingYearRepository,
} from '@/modules/flagship/catalogs/income-behavior-in-following-year/domain/repositories/income-behavior-in-following-year-repository';
import {
  IncomeBehaviorInFollowingYearStateManager,
} from '@/modules/flagship/catalogs/income-behavior-in-following-year/domain/state/income-behavior-in-following-year-state-manager';

export default function setupIncomeBehaviorInFollowingYear(
  container: Container,
) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<IncomeBehaviorInFollowingYearStateManager>(
      TYPES.INCOME_BEHAVIOR_IN_FOLLOWING_YEAR_STATE_MANAGER,
    )
    .to(IncomeBehaviorInFollowingYearVueStateManager)
    .inSingletonScope();

  container
    .bind<IncomeBehaviorInFollowingYearRepository>(
      TYPES.INCOME_BEHAVIOR_IN_FOLLOWING_YEAR_REPOSITORY,
    )
    .to(
      withDummies
        ? IncomeBehaviorInFollowingYearDummyRepository
        : IncomeBehaviorInFollowingYearHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetIncomeBehaviorInFollowingYearQuery>(
      TYPES.GET_INCOME_BEHAVIOR_IN_FOLLOWING_YEAR_QUERY,
    )
    .to(GetIncomeBehaviorInFollowingYearQuery)
    .inSingletonScope();
}
