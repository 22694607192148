import TYPES from '../../types';

// Domain
import {
  EmergencyFundInvestorGoalCalculatorDto,
} from '../../domain/dtos/emergency-fund-investor-goal-calculator-dto';
import {
  EmergencyFundInvestorGoalCalculatorEntity,
} from '../../domain/entities/emergency-fund-investor-goal-calculator-entity';
import {
  EmergencyFundInvestorGoalCalculatorRepository,
} from '../../domain/repositories/emergency-fund-investor-goal-calculator-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetEmergencyFundInvestorGoalCalculatorQuery extends
  Query<Promise<EmergencyFundInvestorGoalCalculatorEntity>> {
  @Inject(TYPES.EMERGENCY_FUND_INVESTOR_GOAL_CALCULATOR_REPOSITORY)
  private readonly repository!: EmergencyFundInvestorGoalCalculatorRepository;

  internalExecute(emergency_fund_calculation_dto: EmergencyFundInvestorGoalCalculatorDto):
    Promise<EmergencyFundInvestorGoalCalculatorEntity> {
    return new Promise((resolve, reject) => {
      this.repository.get_emergency_fund_calculation(emergency_fund_calculation_dto)
        .then((data) => {
          resolve(data);
        })
        .catch(reject);
    });
  }
}
