import associatedProductTypes from './associated-product/types';
import linkSowosPocketTypes from './link-sowos-pocket/types';
import linkSowosWealthTypes from './link-sowos-wealth/types';
import investorGoalDatesTypes from './investor-goal-dates/types';
import distributeUnassignedBalanceTypes from './distribute-unassigned-balance/types';
import distributeBalanceTypes from './distribute-balance/types';
import sowosWealthDistributeRecurrentBalanceTypes from './sowos-wealth/distribute-recurrent-balance/types';
import sowosSearchByCustomerTypes from './search-by-customer/types';
import sowosGoalTrackingTypes from './goal-tracking/types';
import sowosGlobalTrackingTypes from './global-tracking/types';
import linkSowosWealthModerateTypes from './link-sowos-wealth-moderate/types';
import investorGoalDetailTypes from './investor-goal-detail/types';
import finalInvestmentDateTypes from './final-investment-date-calculator/types';
import setupLinkedInvestorGoalTypes from './linked-investor-goal/types';

export default {
  ...associatedProductTypes,
  ...linkSowosPocketTypes,
  ...linkSowosWealthTypes,
  ...investorGoalDatesTypes,
  ...distributeUnassignedBalanceTypes,
  ...distributeBalanceTypes,
  ...sowosWealthDistributeRecurrentBalanceTypes,
  ...sowosSearchByCustomerTypes,
  ...sowosGoalTrackingTypes,
  ...sowosGlobalTrackingTypes,
  ...linkSowosWealthModerateTypes,
  ...investorGoalDetailTypes,
  ...finalInvestmentDateTypes,
  ...setupLinkedInvestorGoalTypes,
};
