import { Container } from 'inversify-props';
import setupSharedModule from './shared';
import setupOnBoardingModule from './on-boarding/setup';
import setupAuthenticationModule from './authentication';
import setupRegisterModule from './register';
import setupAgreements from './agreements/setup';
import setupInternetStatus from './internet-status/setup';
import setupCurrentUser from './current-user/setup';
import setupMyInvestmentModule from './my-investment/setup';
import setupCustomerModule from './customer/setup';
import setupCalendlyModule from '@/modules/calendly/setup';
import setupUserModule from './user/setup';
import setupZendeskModule from './zendesk/setup';
import setupFlagshipModule from './flagship/setup';
import setupHomeDashboardModule from './home-dashboard/setup';
import setupInvestmentSuggestionModule from './investment-suggestion/setup';

export default function setupModules(container: Container) {
  setupSharedModule(container);
  setupOnBoardingModule(container);
  setupAuthenticationModule(container);
  setupRegisterModule(container);
  setupAgreements(container);
  setupInternetStatus(container);
  setupCurrentUser(container);
  setupMyInvestmentModule(container);
  setupCustomerModule(container);
  setupCalendlyModule(container);
  setupUserModule(container);
  setupZendeskModule(container);
  setupFlagshipModule(container);
  setupHomeDashboardModule(container);
  setupInvestmentSuggestionModule(container);
}
