import TYPES from '../../types';

// Domain
import {
  LowToleranceNotificationDto,
} from '../../domain/dto/low-tolerance-notification-dto';
import {
  InvestorGoalRiskToleranceRepository,
} from '../../domain/repositories/investor-goal-risk-tolerance-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class NotifyInvestorGoalRiskToleranceCommand
  extends Command<LowToleranceNotificationDto> {
  @Inject(TYPES.INVESTOR_GOAL_RISK_TOLERANCE_REPOSITORY)
  private readonly repository!: InvestorGoalRiskToleranceRepository;

  internalExecute(low_tolerance_notification: LowToleranceNotificationDto): Promise<void> {
    return this.repository.notify(low_tolerance_notification);
  }
}
