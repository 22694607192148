import i18n from '@/vue-app/plugins/i18n';
import vuetify from '@/vue-app/plugins/vuetify';
// eslint-disable-next-line
import router from '@/vue-app/router';

export default class UserProfileViewModel {
  private readonly i18n_namespace = 'profile';

  profile_menu_is_visible = true;

  tab_is_visible = true;

  get profile_card_width() {
    if (vuetify.framework.breakpoint.lgAndUp) {
      return '90%';
    }

    return '100%';
  }

  translate = (identifier: string) => (
    i18n.t(`${this.i18n_namespace}.${identifier}`)
  )

  initialize = () => {
    this.addEventListenerForActiveTab();
    this.setNavBarTitle();
  }

  goBack = () => {
    setTimeout(() => {
      if (vuetify.framework.breakpoint.mdAndDown) {
        this.tab_is_visible = vuetify.framework.breakpoint.mdAndUp;
        this.profile_menu_is_visible = true;
      }
      this.publishGoBackEvent();
    }, 200);
  }

  private setNavBarTitle = () => {
    const set_title_event = new CustomEvent('nav-bar.title', {
      detail: {
        title: [
          {
            label: this.translate('title.main'),
            href: '/',
          },
          {
            label: this.translate('title.current'),
          },
        ],
      },
    });

    window.dispatchEvent(set_title_event);
  }

  private addEventListenerForActiveTab = () => {
    window.addEventListener('user-profile-menu.active_tab', this.updateActiveTabVisibility);
  }

  private updateActiveTabVisibility = (event: CustomEventInit) => {
    this.profile_menu_is_visible = vuetify.framework.breakpoint.mdAndUp;
    this.tab_is_visible = true;
  }

  private publishGoBackEvent() {
    const route = router.currentRoute;

    const go_back_event = new CustomEvent('user-profile', {
      detail: {
        route,
      },
    });

    window.dispatchEvent(go_back_event);
  }
}
