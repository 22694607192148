import i18n from '@/vue-app/plugins/i18n';
import { emailFormat, requiredRule } from '@/vue-app/utils/form-rules';
import TYPES from '@/types';

// Application
import { ForgotPasswordCommand } from '@/modules/authentication/application/commands';

// Domain
import { ForgotPassword } from '@/modules/authentication/domain/dtos';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { Router } from '@/modules/shared/domain/router';

export default class ForgotPasswordViewModel {
  @Inject(TYPES.FORGOT_PASSWORD_COMMAND)
  private readonly forgot_password_command!: ForgotPasswordCommand;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.ROUTER)
  private readonly router!: Router;

  private readonly i18n_namespace = 'forgot_password';

  valid_form = false;

  is_loading = false;

  inputs = {
    email: '',
  }

  input_rules = {
    email: [requiredRule, emailFormat],
  }

  get can_continue() {
    return !this.is_loading && this.valid_form;
  }

  translate = (identifier: string) => (
    i18n.t(`${this.i18n_namespace}.${identifier}`)
  )

  recoveryPassword = async () => {
    this.is_loading = true;
    try {
      const payload: ForgotPassword = {
        email_address: this.inputs.email,
      };
      await this.forgot_password_command.execute(payload);
      this.inputs.email = '';
      this.message_notifier.showSuccessNotification(
        this.translate('password_recovery_request_successful').toString(),
      );
      this.router.navigate('login');
    } catch {
      this.message_notifier.showErrorNotification(
        this.translate('password_recovery_request_error').toString(),
      );
    }
    this.is_loading = false;
  }
}
