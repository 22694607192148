import fixtures from './fixtures.json';
import Injectable from '@/modules/shared/domain/di/injectable';
import SearchEmailRepository from '../../../../domain/repository/search-email-repository';

@Injectable()
export default class SearchEmailDummyRepository implements SearchEmailRepository {
  // eslint-disable-next-line class-methods-use-this
  execute() {
    return Promise.resolve(fixtures);
  }
}
