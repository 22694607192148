import TYPES from '../../types';

// Domain
import { InvestmentTypeDto } from '../../domain/dtos/investment-type-dto';
import { InvestmentTypeRepository } from '../../domain/repository/investment-type-repository';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetInvestmentTypeQuery extends Query<Promise<InvestmentTypeDto>> {
  @Inject(TYPES.ALLIANZ_INVESTMENT_TYPE_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.ALLIANZ_INVESTMENT_TYPE_REPOSITORY)
  private readonly repository!: InvestmentTypeRepository;

  internalExecute(score: string): Promise<InvestmentTypeDto> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.current_score !== score) {
        this.repository.execute(score)
          .then((investment_type) => {
            this.state_manager.patch({ investment_type, current_score: score });
            resolve(investment_type);
          })
          .catch(reject);
      } else {
        resolve(this.state_manager.state.investment_type);
      }
    });
  }
}
