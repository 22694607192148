import SHARED_TYPES from '@/modules/shared/types';
import TYPES from '../../types';

// Domain
import {
  CreateKuspitAccountStatusEntity,
} from '../../domain/entities/create-kuspit-account-status-entity';
import { StateManager } from '../../domain/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Subscription from '@/modules/shared/domain/use-cases/subscription';
import { WebSocket } from '@/modules/shared/domain/web-socket';

@Injectable()
export default class CreateCustomerKuspitAccountSubscription extends Subscription<string> {
  @Inject(SHARED_TYPES.WEB_SOCKET)
  private readonly web_socket!: WebSocket;

  @Inject(TYPES.CUSTOMER_KUSPIT_ACCOUNT_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  private endpoint = `${process.env.VUE_APP_WEBSOCKET_URL}/app.investment.api_kuspit_step.web_socket.1.created/{USER_ID}/`;

  internalExecute(user_id: string) {
    this.endpoint = this.endpoint.replace('{USER_ID}', user_id);
    this.web_socket.connect(this.endpoint, (event) => {
      const status: CreateKuspitAccountStatusEntity = event.attributes;

      if (this.state_manager.state.status.percent < status.percent) {
        this.state_manager.patch({ status });
      }

      if (status.percent === 100) {
        this.web_socket.disconnect(this.endpoint);
      }
    });
  }

  stop() {
    this.web_socket.disconnect(this.endpoint);
  }
}
