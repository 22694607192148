// Domain
import {
  InvestorGoalScheduledDistributionPocketWithdrawalRepository,
} from '../../../domain/repositories/investor-goal-scheduled-distribution-pocket-withdrawal-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class InvestorGoalScheduledDistributionPocketWithdrawalDummyRepository
implements InvestorGoalScheduledDistributionPocketWithdrawalRepository {
  distribute(): Promise<void> {
    return Promise.resolve();
  }
}
