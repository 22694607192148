
















import { Vue, Component } from 'vue-property-decorator';
import UserProfileTabViewModel
  from '@/vue-app/view-models/components/user-profile/user-profile-tabs/user-profile-tab-view-model';

@Component({
  name: 'UserProfileTab',
  components: {
    UserProfilePersonalInformationTab:
      () => import('./user-profile-personal-information-tab/user-profile-personal-information-tab.vue'),
    UserProfileSecurityTab:
      () => import('./user-profile-security-tab.vue'),
    UserProfilePromotionalCodeTab:
      () => import('./user-profile-promotional-code-tab.vue'),
    UserProfilePersonalityProfileTab: () => import('./user-profile-personality-profile-tab.vue'),
    UserProfileDeleteAccountTab: () => import('./user-profile-delete-account-tab.vue'),
  },
})
export default class UserProfileTab extends Vue {
  user_profile_tab_view_model = Vue.observable(new UserProfileTabViewModel());

  created() {
    this.user_profile_tab_view_model.initialize();
  }

  destroyed() {
    this.user_profile_tab_view_model.removeUserProfileMenuActiveTabChangeEventListener();
  }
}
