















































































import { Vue, Component } from 'vue-property-decorator';
import UserProfileMenuViewModel
  from '@/vue-app/view-models/components/user-profile/user-profile-menu/user-profile-menu-view-model';
import UserProfileMenuItem from './user-profile-menu-item.vue';

@Component({
  name: 'UserProfileMenu',
  components: { UserProfileMenuItem },
})
export default class UserProfileMenu extends Vue {
  user_profile_menu_view_model = Vue.observable(new UserProfileMenuViewModel());

  created() {
    this.user_profile_menu_view_model.initialize();
  }
}
