import vuetify from '@/vue-app/plugins/vuetify';
import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';

// Domain
import { StateManager as PersonStateManager } from '@/modules/my-investment/person/domain/state/state-manager';
import Inject from '@/modules/shared/domain/di/inject';

export default class NavBarViewModel {
  @Inject(TYPES.INVESTMENT_PERSON_STATE_MANAGER)
  private readonly person_state_manager!: PersonStateManager;

  readonly i18n_namespace = 'home_dashboard';

  readonly links = [
    { to: '/', icon: 'menu_item.svg', text: 'HomeDashboard' },
    {
      to: vuetify.framework.breakpoint.mdAndUp ? '/profile#personal-information' : '/profile',
      icon: 'menu_item4.svg',
      text: 'Config',
    },
  ]

  menu_items = [
    {
      title: i18n.tc(`${this.i18n_namespace}.my_account`),
      route: '/profile#personal-information',
      action: 'to_my_profile',
    },
    {
      title: i18n.tc(`${this.i18n_namespace}.logout`),
      route: '/login',
      action: 'logout',
    },
  ];

  title = [];

  back_reference = '';

  back_reference_alt = i18n.tc(`${this.i18n_namespace}.back_reference_alt`);

  header_box_shadow_active = false;

  show_dialog_sign_out = false;

  show_notifications = false;

  active_notifications = false;

  get initials() {
    const person = this.person_state_manager.state;
    return `${person.name.charAt(0)}${person.last_name.charAt(0)}`;
  }

  addTitleEventListener = () => {
    window.addEventListener('nav-bar.title', this.updateTitle);
  }

  removeTitleEventListener = () => {
    window.removeEventListener('nav-bar.title', this.updateTitle);
  };

  actionMenu = (action: string, route: string) => {
    if (action === 'logout') {
      this.show_dialog_sign_out = true;
    } else {
      window.location.href = route;
    }
  }

  addScrollListener = () => {
    window.addEventListener('scroll', this.updateHeaderBoxShadowStatus);
  }

  removeScrollListener = () => {
    window.removeEventListener('scroll', this.updateHeaderBoxShadowStatus);
  }

  private updateTitle = (event: CustomEventInit) => {
    this.title = event.detail.title;
    this.back_reference = event.detail.back_reference;
  }

  private updateHeaderBoxShadowStatus = () => {
    this.header_box_shadow_active = window.scrollY > 20;
  }

  handleShowNotifications = () => {
    this.show_notifications = !this.show_notifications;
  }

  activeNotifications = () => {
    this.active_notifications = true;
  }

  hideNotifications = () => {
    this.show_notifications = false;
  }
}
