import access_more_strategies from './access-more-strategies.json';
import define_investment_term from './define-investment-term.json';
import investment_strategy_result from './investment-strategy-result.json';
import initial_balance from './initial-balance.json';
import investment_strategy_configure_contribution from './investment-strategy-configure-contribution.json';
import stay_with_current_strategy from './stay-with-current-strategy.json';
import access_more_strategy_successfully from './access-more-strategy-successfully.json';

export default {
  ...access_more_strategies,
  ...define_investment_term,
  ...investment_strategy_result,
  ...initial_balance,
  ...investment_strategy_configure_contribution,
  ...stay_with_current_strategy,
  ...access_more_strategy_successfully,
};
