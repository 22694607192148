import { CustomerPersonalityProfileEntity } from '../entities/customer-personality-profile-entity';

export default class CustomerPersonalityProfileState {
  loaded = false;

  item: CustomerPersonalityProfileEntity = {
    full_name: '',
    age: 0,
    child_dependent_count: 0,
    elder_dependent_count: 0,
    pet_dependent_count: 0,
    employment_situation: '',
    income_behavior_in_following_year: '',
    monthly_savings_capacity: 0,
    education_level: '',
    investment_experiences: '',
    personality_profile: '',
    investor_profile_completed: false,
    has_allianz_account: false,
    show_personality_profile: false,
    updated_at: '',
  }
}
