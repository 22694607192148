





















































































































import {
  Vue, Component,
} from 'vue-property-decorator';
import WithdrawalGoalsDistributionViewModel
  from '@/vue-app/view-models/components/kuspit-dashboard/withdrawals/withdrawal-goals-distribution-view-model';

@Component({ name: 'WithdrawalGoalsDistribution' })
export default class WithdrawalGoalsDistribution extends Vue {
  withdrawal_goals_distribution_view_model = Vue.observable(
    new WithdrawalGoalsDistributionViewModel(this),
  );

  created() {
    this.withdrawal_goals_distribution_view_model.initialize();
  }
}

