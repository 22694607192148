// Domain
import { CurrentPlanEntity } from '../entities/current-plan-entity';
import { CurrentAmountEntity } from '../entities/current-amount-entity';
import { RetirementAgeEntity } from '../entities/retirement-age-entity';
import { DefineByEntity } from '../entities/define-by-entity';

export default class State {
  current_plan: CurrentPlanEntity = {
    target_amount: '',
    current_amount: '',
    monthly_required_amount: '',
    retirement_age: '',
    monthly_pension: '',
    associated_product_id: '',
    retirement_fund_id: '',
  }

  current_amount: CurrentAmountEntity = {
    additional_amount: 0,
    there_is_more_savings: '',
    amount_added: 0,
  }

  retirement_age: RetirementAgeEntity = {
    retirement_age: 0,
    current_age: 0,
    retirement: 0,
    pension: 0,
    lifetime_expected: 0,
  }

  define_by: DefineByEntity = {
    is_defined_by_contribution: false,
    pension_range_adjusted: 0,
    by_pension: {
      contribution: 0,
      you_must_save: 0,
      accumulated_amount: 0,
      retirement_range_adjusted: 0,
    },
    by_contribution: {
      contribution: 0,
      you_must_save: 0,
      accumulated_amount: 0,
      retirement_range_adjusted: 0,
    },
  }
}
