import SHARED_TYPES from '@/modules/shared/types';
import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Subscription from '@/modules/shared/domain/use-cases/subscription';
import { WebSocket } from '@/modules/shared/domain/web-socket';
import { UpdateAccountNumberStateManager } from '../../domain/state-manager/update-account-number-state-manager';

@Injectable()
export class UpdateAccountNumberStatusSubscription extends Subscription<string> {
  @Inject(SHARED_TYPES.WEB_SOCKET)
  private readonly web_socket!: WebSocket;

  @Inject(TYPES.UPDATE_ACCOUNT_NUMBER_STATE_MANAGER)
  private readonly state_manager!: UpdateAccountNumberStateManager;

  private endpoint = `${process.env.VUE_APP_WEBSOCKET_URL}/app.allianz.bank_account.status.frontend.1.updated/{USER_ID}/`;

  internalExecute(user_id: string) {
    this.endpoint = this.endpoint.replace('{USER_ID}', user_id);
    this.web_socket.connect(this.endpoint, (event) => {
      const { bank_account_change_status } = event.attributes;

      this.state_manager.state.update_account_number.status = bank_account_change_status;

      if (bank_account_change_status === 'request_approved') {
        this.web_socket.disconnect(this.endpoint);
      }
    });
  }

  stop() {
    this.web_socket.disconnect(this.endpoint);
  }
}
