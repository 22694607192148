import TYPES from '@/modules/shared/types';

// Domain
import { TokenRefreshRepository } from '../../../domain/repositories';
import { TokenRefresh } from '../../../domain/dtos';
import { RefreshTokenEntity } from '../../../domain/entities';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class TokenRefreshHttpRepository implements TokenRefreshRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly path = `${process.env.VUE_APP_API_URL}/api/v1/app/auth/login/jwt/update`;

  update(token_update: TokenRefresh): Promise<RefreshTokenEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.path, 'POST', token_update, 1)
        .then((response) => {
          response.json().then(({
            data,
            message,
            success,
          }) => {
            if (success) {
              resolve(data);
            } else {
              reject(new Error(message));
            }
          });
        })
        .catch(reject);
    });
  }
}
