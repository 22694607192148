import TYPES from '../../types';

// Domain
import {
  PreferredMeansOfContactRepository,
} from '@/modules/on-boarding/preferred-means-of-contact/domain/repositories/preferred-means-of-contact-repository';
import {
  PreferredMeansOfContactDto,
} from '@/modules/on-boarding/preferred-means-of-contact/domain/dtos/preferred-means-of-contact-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UpdatePreferredMeansOfContactCommand
  extends Command<PreferredMeansOfContactDto> {
  @Inject(TYPES.PREFERRED_MEANS_OF_CONTACT_REPOSITORY)
  private readonly repository!: PreferredMeansOfContactRepository;

  internalExecute(preferred_means_of_contact: PreferredMeansOfContactDto):
    Promise<void> {
    return this.repository.update(preferred_means_of_contact);
  }
}
