import TYPES from '../../types';

// Domain
import {
  InvestorProfileOnRetirementBudgetRepository,
} from '@/modules/flagship/investor-profile/retirement-budget/domain/repositories/investor-profile-on-retirement-budget-repository';
import {
  RetirementBudgetDto,
} from '@/modules/flagship/investor-profile/retirement-budget/domain/dtos/retirement-budget-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UpdateInvestorProfileOnRetirementBudgetCommand
  extends Command<RetirementBudgetDto> {
  @Inject(TYPES.INVESTOR_PROFILE_ON_RETIREMENT_BUDGET_REPOSITORY)
  private readonly repository!: InvestorProfileOnRetirementBudgetRepository;

  internalExecute(retirement_budget_dto: RetirementBudgetDto): Promise<void> {
    return this.repository.update(retirement_budget_dto);
  }
}
