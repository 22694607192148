import TYPES from '../../types';

// Domain
import {
  CustomInvestorGoalCalculatorDto,
} from '../../domain/dtos/custom-investor-goal-calculator-dto';
import {
  CustomInvestorGoalCalculatorEntity,
} from '../../domain/entities/custom-investor-goal-calculator-entity';
import {
  CustomInvestorGoalCalculatorRepository,
} from '../../domain/repositories/custom-investor-goal-calculator-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetCustomInvestorGoalCalculatorQuery extends
  Query<Promise<CustomInvestorGoalCalculatorEntity>> {
  @Inject(TYPES.CUSTOM_INVESTOR_GOAL_CALCULATOR_REPOSITORY)
  private readonly repository!: CustomInvestorGoalCalculatorRepository;

  internalExecute(custom_calculation_dto: CustomInvestorGoalCalculatorDto):
    Promise<CustomInvestorGoalCalculatorEntity> {
    return new Promise((resolve, reject) => {
      this.repository.getCustomCalculation(custom_calculation_dto)
        .then((data) => {
          resolve(data);
        })
        .catch(reject);
    });
  }
}
