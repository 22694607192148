import { UnauthenticatedHeaderType } from '@/vue-app/types/layout';
import { ActionPayloadType } from '../types';

function setLayoutHeader({ commit }: ActionPayloadType, header: UnauthenticatedHeaderType) {
  commit('setLayoutHeader', header);
}

export default {
  setLayoutHeader,
};
