










import { Vue, Component } from 'vue-property-decorator';
import UnauthenticatedHeader from '@/vue-app/components/header/UnauthenticatedHeader.vue';
import SupportWidgetAnonymousDialog
  from '@/vue-app/components/support-widget/SupportWidgetAnonymousDialog.vue';
import UnauthenticatedFooter from '@/vue-app/components/footer/UnauthenticatedFooter.vue';

@Component({
  name: 'UnauthenticatedLayout',
  components: {
    UnauthenticatedFooter,
    UnauthenticatedHeader,
    SupportWidgetAnonymousDialog,
  },
})
export default class UnauthenticatedLayout extends Vue {}

