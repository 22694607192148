import TYPES from '../../types';

// Domain
import { ISOCountryEntity } from '../../domain/entities/iso-country-entity';
import { ISOCountryRepository } from '../../domain/repository/iso-country-repository';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class SearchISOCountriesQuery extends Query<Array<ISOCountryEntity>> {
  @Inject(TYPES.CATALOG_ALLIANZ_ISO_COUNTRY_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.CATALOG_ALLIANZ_ISO_COUNTRY_REPOSITORY)
  private readonly repository!: ISOCountryRepository;

  internalExecute() {
    if (!this.state_manager.state.loaded) {
      this.repository.findAll()
        .then((items) => {
          this.state_manager.patch({ items, loaded: true });
        });
    }

    return this.state_manager.state.items;
  }
}
