import TYPES from '../../types';

// Domain
import {
  CustomGoalCalculationEntity,
} from '@/modules/flagship/custom-goal-calculations/domain/entities/custom-goal-calculation-entity';
import {
  CustomGoalMonthlyPaymentCalculationRepository,
} from '@/modules/flagship/custom-goal-calculations/domain/repositories/custom-goal-monthly-payment-calculation-repository';
import {
  CustomGoalMonthlyPaymentCalculationDto,
} from '@/modules/flagship/custom-goal-calculations/domain/dtos/custom-goal-monthly-payment-calculation-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
class CalculateCustomGoalMonthlyPaymentCalculationQuery
  extends Query<Promise<CustomGoalCalculationEntity>> {
  @Inject(TYPES.CUSTOM_GOAL_MONTHLY_PAYMENT_CALCULATION_REPOSITORY)
  private readonly repository!: CustomGoalMonthlyPaymentCalculationRepository;

  internalExecute(
    payload: CustomGoalMonthlyPaymentCalculationDto,
  ): Promise<CustomGoalCalculationEntity> {
    return new Promise((resolve, reject) => {
      this.repository.calculate(payload)
        .then(resolve)
        .catch(reject);
    });
  }
}

export default CalculateCustomGoalMonthlyPaymentCalculationQuery;
