// Domain
import {
  CustomGoalTypesEntity,
} from '@/modules/flagship/catalogs/custom-goal-types/domain/entities/custom-goal-types-entity';

export default class CustomGoalTypesState {
  loaded = false;

  items: Array<CustomGoalTypesEntity> = [];
}
