import TYPES from '@/types';

import { emailFormat, requiredRule } from '@/vue-app/utils/form-rules';

// Application
import { SignInCommand } from '@/modules/authentication/application/commands';
import GetCurrentUserQuery from '@/modules/current-user/application/queries/get-current-user-query';
import GetOnBoardingStatusQuery
  from '@/modules/on-boarding/status/application/queries/get-on-boarding-status-query';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { Values } from '@/modules/shared/domain/i18n/types';
import Translator from '@/modules/shared/domain/i18n/translator';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class LoginViewModel {
  @Inject(TYPES.SIGN_IN_COMMAND)
  readonly signInCommand!: SignInCommand;

  @Inject(TYPES.GET_CURRENT_USER_QUERY)
  readonly getCurrentUserQuery!: GetCurrentUserQuery;

  @Inject(TYPES.GET_ON_BOARDING_STATUS_QUERY)
  readonly getOnBoardingStatusQuery!: GetOnBoardingStatusQuery;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'login';

  get canContinue() {
    return this.valid_form;
  }

  inputs = {
    username: '',
    password: '',
    device: 'website',
  };

  show_password = false;

  valid_form = false;

  loading = false;

  rules = {
    username: [requiredRule, emailFormat],
    password: [requiredRule],
  };

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  handleSubmit = async () => {
    this.loading = true;

    try {
      await this.signInCommand.execute(this.inputs);
      const current_user = await this.getCurrentUserQuery.execute();
      sessionStorage.setItem('user_id', current_user.id);
      const on_boarding_status = await this.getOnBoardingStatusQuery.execute();
      return on_boarding_status.onboarding_completed;
    } catch (error) {
      if (error.message === 'Active session already exists') {
        this.messageNotifier.showInfoNotification(this.translate('errors.session_already_active'));
      } else {
        this.messageNotifier.showErrorNotification(this.translate('errors.cant_login'));
      }
      return null;
    } finally {
      this.loading = false;
    }
  }
}
