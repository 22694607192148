import wealthPendingMovements from './wealth-pending-movements-banner.json';

export default {
  banners: {
    wealth_policy_in_process_of_cancellation: {
      description_1: 'Tu retiro total y cancelación de Póliza se encuentran en proceso. Podrás ver estos procesos reflejados el día viernes o hasta el siguiente día hábil.',
      description_2: 'Una vez efectuados, podrás volver al menú de configuraciones para poder eliminar tu cuenta SOWOS.',
    },
    more_investment_alternatives: {
      description_1: '¿Quieres acceder a las nuevas estrategias de inversión de tu cuenta SOWOS Wealth?',
      discover_how: 'Descubre cómo',
    },
    ...wealthPendingMovements,
  },
};
