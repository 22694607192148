

















































import { Component, Vue, PropSync } from 'vue-property-decorator';

import ModalDistributeSpecialContributionSuccessWealthViewModel
  from '@/vue-app/view-models/components/flagship/modal-distribute-special-contribution-success-wealth-view-model';

@Component({
  name: 'ModalDistributeSpecialContributionSuccessWealth',
})
export default class ModalDistributeSpecialContributionSuccessWealth extends Vue {
  @PropSync('isThereAnyPendingDistribution', { type: Boolean, required: true })
  is_there_any_pending_distribution!: boolean;

  distribute_special_contribution_success_view_model = Vue.observable(
    new ModalDistributeSpecialContributionSuccessWealthViewModel(),
  );

  mounted() {
    this.distribute_special_contribution_success_view_model
      .initialize(this.is_there_any_pending_distribution);
  }
}
