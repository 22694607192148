import TYPES from '../../types';

// Domain
import {
  RiskToleranceQuestionsRepository,
} from '../../domain/repositories/risk-tolerance-questions-repository';
import {
  RiskToleranceQuestionsEntity,
} from '../../domain/entities/risk-tolerance-questions-entity';
import {
  RiskToleranceQuestionsDto,
} from '../../domain/dtos/risk-tolerance-questions-dto';
import {
  RiskToleranceQuestionsStateManager,
} from '../../domain/state/risk-tolerance-questions-state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetRiskToleranceQuestionsQuery
  extends Query<Promise<Array<RiskToleranceQuestionsEntity>>> {
  @Inject(TYPES.RISK_TOLERANCE_QUESTIONS_REPOSITORY)
  private readonly repository!: RiskToleranceQuestionsRepository;

  @Inject(TYPES.RISK_TOLERANCE_QUESTIONS_STATE_MANAGER)
  private readonly state_manager!: RiskToleranceQuestionsStateManager;

  internalExecute(risk_tolerance_questions_dto: RiskToleranceQuestionsDto):
    Promise<Array<RiskToleranceQuestionsEntity>> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded) {
        resolve(this.state_manager.state.items);
      } else {
        this.repository.get(risk_tolerance_questions_dto)
          .then((items) => {
            this.state_manager.patch({ items, loaded: true });
            resolve(items);
          })
          .catch(reject);
      }
    });
  }
}
