import financialInformation from './financial-information.json';
import actualSupplierOfAssets from './actual-supplier-of-assets.json';
import actualOwnerOfResources from './actual-owner-of-resources.json';

export default {
  'additional-information': {
    ...financialInformation,
    ...actualSupplierOfAssets,
    ...actualOwnerOfResources,
  },
};
