export default class DeleteCustomInvestorGoalState {
  is_loading = false;

  custom_investor_goal = {
    active_goal_amount: 0,
    custom_investor_goal_id: '',
    goal_name: '',
    recurrent_assigned_amount: 0,
    associated_product_name: '',
    associated_product_id: '',
  };
}
