import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import AllianzAccountStatementVueStateManager from './infrastructure/state/allianz-account-statement-vue-state-manager';
import {
  AllianzAccountStatementHttpRepository,
  AllianzAccountStatementDummyRepository,
} from './infrastructure/repository';

// Application
import { SearchAllianzAccountStatementsQuery, DownloadAllianzAccountStatementQuery } from './application/queries';

// Domain
import { AllianzAccountStatementRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupAllianzAccountStatement(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.ALLIANZ_ACCOUNT_STATEMENT_STATE_MANAGER)
    .to(AllianzAccountStatementVueStateManager)
    .inSingletonScope();

  container
    .bind<AllianzAccountStatementRepository>(TYPES.ALLIANZ_ACCOUNT_STATEMENT_REPOSITORY)
    .to(
      withDummies
        ? AllianzAccountStatementDummyRepository
        : AllianzAccountStatementHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchAllianzAccountStatementsQuery>(TYPES.SEARCH_ALLIANZ_ACCOUNT_STATEMENTS_QUERY)
    .to(SearchAllianzAccountStatementsQuery)
    .inSingletonScope();

  container
    .bind<DownloadAllianzAccountStatementQuery>(TYPES.DOWNLOAD_ALLIANZ_ACCOUNT_STATEMENT_QUERY)
    .to(DownloadAllianzAccountStatementQuery)
    .inSingletonScope();
}
