import TYPES from '@/modules/shared/types';

// Domain
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';
import {
  MonthlyPaymentCalculationRepository,
} from '@/modules/flagship/emergency-fund-calculations/fixed-time-calculation/domain/repositories/monthly-payment-calculation-repository';
import {
  MonthlyPaymentCalculationDto,
} from '@/modules/flagship/emergency-fund-calculations/fixed-time-calculation/domain/dtos/monthly-payment-calculation-dto';
import {
  EmergencyFundCalculationResponse,
} from '@/modules/flagship/emergency-fund-calculations/fixed-time-calculation/domain/entities/emergency-fund-calculation-response';

@Injectable()
class MonthlyPaymentCalculationHttpRepository implements MonthlyPaymentCalculationRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/emergency-fund-investor-goal-monthly-payment-calculation/`;

  calculate(payload: MonthlyPaymentCalculationDto): Promise<EmergencyFundCalculationResponse> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint, 'POST', payload)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}

export default MonthlyPaymentCalculationHttpRepository;
