import pocketCard from './pocket-card.json';
import wealthCard from './wealth-card.json';
import flagshipCard from './flagship-card.json';

export default {
  'investment-suggestion': {
    title_1: '¡Bienvenido a SOWOS!',
    title_2: '¿Cómo te gustaría utilizar tu cuenta?',
    message_error: 'Ha ocurrido un error al obtener tu información, inténtalo nuevamente',
    ...pocketCard,
    ...wealthCard,
    ...flagshipCard,
  },
};
