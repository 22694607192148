import TYPES from '@/modules/shared/types';

// Domain
import { OnBoardingStatusRepository } from '../domain/repositories/on-boarding-status-repository';
import { OnBoardingStatusEntity } from '../domain/entities/on-boarding-status-entity';
import HttpConnector from '@/modules/shared/domain/connectors/http';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class OnBoardingStatusHttpRepository implements OnBoardingStatusRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoints = {
    get: `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/on-boarding-status/`,
    complete: `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/on-boarding-status/`,
  };

  get(): Promise<OnBoardingStatusEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoints.get)
        .then((response) => {
          response.json()
            .then(({ error, data, success }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(error));
              }
            });
        })
        .catch(reject);
    });
  }

  complete(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoints.complete, 'POST')
        .then((response) => {
          response.json()
            .then(({ error, success }) => {
              if (success) {
                resolve();
              } else {
                reject(new Error(error));
              }
            });
        })
        .catch(reject);
    });
  }
}
