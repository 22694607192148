// Infrastructure
import fixtures from './fixtures.json';

// Domain
import {
  InvestmentProductFundTypeRepository,
} from '../../../domain/repositories/investment-product-fund-type-repository';
import {
  InvestmentProductFundTypeEntity,
} from '../../../domain/entities/investment-product-fund-type-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class InvestmentProductFundTypeDummyRepository implements
  InvestmentProductFundTypeRepository {
  get(): Promise<Array<InvestmentProductFundTypeEntity>> {
    return Promise.resolve(fixtures);
  }
}
