import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import { StateDummyRepository, StateHttpRepository } from './infrastructure/repository';

// Application
import { SearchStatesQuery } from './application/queries';

// Domain
import { StateRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupState(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.CATALOG_ALLIANZ_STATE_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<StateRepository>(TYPES.CATALOG_ALLIANZ_STATE_REPOSITORY)
    .to(
      withDummies
        ? StateDummyRepository
        : StateHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchStatesQuery>(TYPES.SEARCH_CATALOG_ALLIANZ_STATE_QUERY)
    .to(SearchStatesQuery)
    .inSingletonScope();
}
