import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import OverallGoalsStatusDummyRepository
  from '@/modules/flagship/investor-profile/overall-goals-status/infrastructure/repositories/overall-goals-status-dummy-repository/overall-goals-status-dummy-repository';
import OverallGoalsStatusHttpRepository
  from '@/modules/flagship/investor-profile/overall-goals-status/infrastructure/repositories/overall-goals-status-http-repository';
import OverallGoalsStatusVueStateManager
  from '@/modules/flagship/investor-profile/overall-goals-status/infrastructure/state/overall-goals-state-vue-state-manager';

// Application
import GetOverallGoalsStatusQuery
  from '@/modules/flagship/investor-profile/overall-goals-status/application/queries/get-overall-goals-status-query';

// Domain
import {
  OverallGoalsStatusRepository,
} from '@/modules/flagship/investor-profile/overall-goals-status/domain/repositories/overall-goals-status-repository';
import {
  OverallGoalsStatusStateManager,
} from '@/modules/flagship/investor-profile/overall-goals-status/domain/state/overall-goals-status-state-manager';

export default function setupOverallGoalsStatus(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<OverallGoalsStatusStateManager>(TYPES.OVERALL_GOALS_STATUS_STATE_MANAGER)
    .to(OverallGoalsStatusVueStateManager)
    .inSingletonScope();

  container
    .bind<OverallGoalsStatusRepository>(
      TYPES.OVERALL_GOALS_STATUS_REPOSITORY,
    ).to(
      withDummies
        ? OverallGoalsStatusDummyRepository
        : OverallGoalsStatusHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetOverallGoalsStatusQuery>(
      TYPES.GET_OVERALL_GOALS_STATUS_QUERY,
    ).to(GetOverallGoalsStatusQuery)
    .inSingletonScope();
}
