// Infrastructure
import fixtures from './fixtures.json';

// Domain
import {
  RetirementForecastCalculationRepository,
} from '@/modules/flagship/retirement-forecast-calculation/domain/repositories/retirement-forecast-calculation-repository';
import {
  ForecastCalculationDto,
} from '@/modules/flagship/retirement-forecast-calculation/domain/dtos/forecast-calculation-dto';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class RetirementForecastCalculationDummyRepository
implements RetirementForecastCalculationRepository {
  get(): Promise<ForecastCalculationDto> {
    return Promise.resolve(fixtures);
  }

  update(): Promise<void> {
    return Promise.resolve();
  }

  create(): Promise<ForecastCalculationDto> {
    return Promise.resolve(fixtures);
  }
}
