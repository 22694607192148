// Domain
import {
  RetirementFundLocationEntity,
} from '@/modules/flagship/catalogs/retirement-fund-location/domain/entities/retirement-fund-location-entity';

export default class RetirementFundLocationState {
  loaded = false;

  items: Array<RetirementFundLocationEntity> = [];
}
