import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import { CustomerInvestmentProductFundTypeDummyRepository }
  from '@/modules/flagship/customer-investment-product-fund-type/infraestructure/repositories/customer-investment-product-fund-type-dummy-repository';
import CustomerInvestmentProductFundTypeHttpRepository
  from '@/modules/flagship/customer-investment-product-fund-type/infraestructure/repositories/customer-investment-product-fund-type-http-repository';
import ModerateIsContractedVueStateManager
  from '@/modules/flagship/customer-investment-product-fund-type/infraestructure/state/moderate-is-contracted-vue-state-manager';
import CustomerInvestmentProductFundTypeVueStateManager
  from '@/modules/flagship/customer-investment-product-fund-type/infraestructure/state/customer-investment-product-fund-type-vue-state-manager';

// Application
import GetModerateIsContractedQuery
  from '@/modules/flagship/customer-investment-product-fund-type/application/queries/get-moderate-is-contracted-query';
import SearchInvestmentProductsContractedByCustomerQuery
  from '@/modules/flagship/customer-investment-product-fund-type/application/queries/search-investment-products-contracted-by-customer-query';

// Domain
import {
  CustomerInvestmentProductFundTypeRepository,
} from '@/modules/flagship/customer-investment-product-fund-type/domain/repositories/customer-investment-product-fund-type-repository';
import { ModerateIsContractedStateManager } from '@/modules/flagship/customer-investment-product-fund-type/domain/state/moderate-is-contracted-state-manager';
import { CustomerInvestmentProductFundTypeStateManager } from '@/modules/flagship/customer-investment-product-fund-type/domain/state/customer-investment-product-fund-type-state-manager';

export default function setupCustomerInvestmentProductFundType(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<ModerateIsContractedStateManager>(
      TYPES.MODERATE_IS_CONTRACTED_STATE_MANAGER,
    )
    .to(ModerateIsContractedVueStateManager)
    .inSingletonScope();
  container
    .bind<CustomerInvestmentProductFundTypeRepository>(
      TYPES.CUSTOMER_INVESTMENT_PRODUCT_FUND_TYPE_REPOSITORY,
    ).to(
      withDummies
        ? CustomerInvestmentProductFundTypeDummyRepository
        : CustomerInvestmentProductFundTypeHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetModerateIsContractedQuery>(
      TYPES.GET_MODERATE_IS_CONTRACTED_QUERY,
    ).to(GetModerateIsContractedQuery)
    .inSingletonScope();

  container
    .bind<CustomerInvestmentProductFundTypeStateManager>(
      TYPES.CUSTOMER_INVESTMENT_PRODUCT_FUND_TYPE_STATE_MANAGER,
    )
    .to(CustomerInvestmentProductFundTypeVueStateManager);

  container
    .bind<SearchInvestmentProductsContractedByCustomerQuery>(
      TYPES.SEARCH_INVESTMENT_PRODUCTS_CONTRACTED_BY_CUSTOMER,
    ).to(SearchInvestmentProductsContractedByCustomerQuery)
    .inSingletonScope();
}
