import Vue from 'vue';

// Domain
import { PendingInvestorGoalMovementsStateManager } from '../../domain/state/pending-investor-goal-movements-state-manager';
import PendingInvestorGoalMovementsState from '../../domain/state/pending-investor-goal-movements-state';
import Injectable from '@/modules/shared/domain/di/injectable';
import Observer from '@/modules/shared/domain/observer/observer';

@Injectable()
export default class PendingInvestorGoalMovementsVueStateManager
implements PendingInvestorGoalMovementsStateManager {
  // eslint-disable-next-line max-len
    private _state: PendingInvestorGoalMovementsState = Vue.observable<PendingInvestorGoalMovementsState>(
      new PendingInvestorGoalMovementsState(),
    );

    private readonly observers: Observer[] = [];

    get state(): PendingInvestorGoalMovementsState {
      return this._state;
    }

    set state(value: PendingInvestorGoalMovementsState) {
      this._state = value;
    }

    patch(state: Partial<PendingInvestorGoalMovementsState>) {
      type Keys = keyof PendingInvestorGoalMovementsState;
      Object.entries(state).forEach(([key, value]) => {
        const accessor = key as Keys;
        if (value !== undefined) {
          // eslint-disable-next-line
          // @ts-ignore @typescript-eslint/ban-ts-ignore
          this.state[accessor] = value;
        }
      });
    }

    notifyAll(): void {
      this.observers.forEach((observer) => observer.notify());
    }

    register(observer: Observer): void {
      this.observers.push(observer);
    }
}
