import TYPES from '../../types';

// Domain
import { SupportTicketDto } from '@/modules/zendesk/support-ticket/domain/dtos/support-ticket-dto';
import { SupportTicketRepository } from '@/modules/zendesk/support-ticket/domain/repositories/support-ticket-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CreateSupportTicketCommand extends Command<SupportTicketDto> {
  @Inject(TYPES.SUPPORT_TICKET_REPOSITORY)
  private readonly repository!: SupportTicketRepository;

  internalExecute(support_ticket_dto: SupportTicketDto): Promise<void> {
    return this.repository.create(support_ticket_dto);
  }
}
