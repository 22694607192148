import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import {
  CustomerDocumentsHttpRepository,
  CustomerDocumentsDummyRepository,
} from './infrastructure/repository';

// Application
import { SearchCustomerDocumentsQuery } from './application/queries';

// Domain
import { CustomerDocumentsRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupCustomerDocuments(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.MY_INVESTMENT_CUSTOMER_DOCUMENTS_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<CustomerDocumentsRepository>(TYPES.MY_INVESTMENT_CUSTOMER_DOCUMENTS_REPOSITORY)
    .to(
      withDummies
        ? CustomerDocumentsDummyRepository
        : CustomerDocumentsHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchCustomerDocumentsQuery>(TYPES.SEARCH_MY_INVESTMENT_CUSTOMER_DOCUMENTS_QUERY)
    .to(SearchCustomerDocumentsQuery)
    .inSingletonScope();
}
