import Vue from 'vue';

// Domain
import CustomerInvestorGoalReminderState
  from '@/modules/flagship/customer-investor-goal-reminder/domain/state/customer-investor-goal-reminder-state';
import {
  CustomerInvestorGoalReminderStateManager,
} from '@/modules/flagship/customer-investor-goal-reminder/domain/state/customer-investor-goal-reminder-state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Observer from '@/modules/shared/domain/observer/observer';

@Injectable()
export default class CustomerInvestorGoalReminderVueStateManager
implements CustomerInvestorGoalReminderStateManager {
  // eslint-disable-next-line max-len
  private _state: CustomerInvestorGoalReminderState = Vue.observable<CustomerInvestorGoalReminderState>(
    new CustomerInvestorGoalReminderState(),
  );

  private readonly observers: Observer[] = [];

  get state(): CustomerInvestorGoalReminderState {
    return this._state;
  }

  set state(value: CustomerInvestorGoalReminderState) {
    this._state = value;
  }

  patch(state: Partial<CustomerInvestorGoalReminderState>) {
    type Keys = keyof CustomerInvestorGoalReminderState;
    Object.entries(state).forEach(([key, value]) => {
      const accessor = key as Keys;

      if (value !== undefined) {
        // eslint-disable-next-line
        // @ts-ignore @typescript-eslint/ban-ts-ignore
        this.state[accessor] = value;
      }
    });
  }

  notifyAll(): void {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer): void {
    this.observers.push(observer);
  }
}
