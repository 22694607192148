import TYPES from '../../types';

// Domain
import { FileEntity } from '../../domain/entities/file-entity';
import { FileRepository } from '../../domain/repository/file-repository';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetFileQuery extends Query<Promise<FileEntity>> {
  @Inject(TYPES.MY_INVESTMENT_FILE_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.MY_INVESTMENT_FILE_REPOSITORY)
  private readonly repository!: FileRepository;

  internalExecute(file_id: string): Promise<FileEntity> {
    return this.repository.get(file_id);
  }
}
