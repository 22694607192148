import customerPersonalInformationFixtures
  from '@/fixtures/customer/personal-information/fixtures.json';

// Domain
import { CustomerPersonalInformationRepository } from '../../../domain/repositories';
import { PersonalInformation } from '../../../domain/dtos';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
class CustomerPersonalInformationDummyRepository implements CustomerPersonalInformationRepository {
  get(): Promise<PersonalInformation> {
    return Promise.resolve(customerPersonalInformationFixtures);
  }
}

export default CustomerPersonalInformationDummyRepository;
