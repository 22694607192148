import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import GoalDefinitionStepDummyRepository
  from '@/modules/flagship/goal-definition-step/infrastructure/repositories/goal-definition-step-dummy-repository/goal-definition-step-dummy-repository';
import GoalDefinitionStepHttpRepository
  from '@/modules/flagship/goal-definition-step/infrastructure/repositories/goal-definition-step-http-repository';
import GoalDefinitionStepVueStateManager
  from '@/modules/flagship/goal-definition-step/infrastructure/state/goal-definition-step-vue-state-manager';

// Application
import GetGoalDefinitionStepQuery
  from '@/modules/flagship/goal-definition-step/application/queries/get-goal-definition-step-query';
import UpdateGoalDefinitionStepCommand
  from '@/modules/flagship/goal-definition-step/application/commands/update-goal-definition-step-command';

// Domain
import {
  GoalDefinitionStepRepository,
} from '@/modules/flagship/goal-definition-step/domain/repositories/goal-definition-step-repository';
import {
  GoalDefinitionStepStateManager,
} from '@/modules/flagship/goal-definition-step/domain/state/goal-definition-step-state-manager';

export default function setupGoalDefinitionStep(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<GoalDefinitionStepStateManager>(TYPES.GOAL_DEFINITION_STEP_STATE_MANAGER)
    .to(GoalDefinitionStepVueStateManager)
    .inSingletonScope();

  container
    .bind<GoalDefinitionStepRepository>(
      TYPES.GOAL_DEFINITION_STEP_REPOSITORY,
    ).to(
      withDummies
        ? GoalDefinitionStepDummyRepository
        : GoalDefinitionStepHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetGoalDefinitionStepQuery>(
      TYPES.GET_GOAL_DEFINITION_STEP_QUERY,
    ).to(GetGoalDefinitionStepQuery)
    .inSingletonScope();

  container
    .bind<UpdateGoalDefinitionStepCommand>(
      TYPES.UPDATE_GOAL_DEFINITION_STEP_COMMAND,
    ).to(UpdateGoalDefinitionStepCommand)
    .inSingletonScope();
}
