import TYPES from '../../types';

// Domain
import { InvestorGoalDatesDto } from '../../domain/dtos/investor-goal-dates-dto';
import { InvestorGoalDatesRepository } from '../../domain/repositories/investor-goal-dates-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UpdateInvestorGoalDatesCommand extends Command<InvestorGoalDatesDto> {
  @Inject(TYPES.INVESTOR_GOAL_DATES_REPOSITORY)
  private readonly repository!: InvestorGoalDatesRepository;

  internalExecute(investor_goal_dates_dto: InvestorGoalDatesDto) {
    return this.repository.update(investor_goal_dates_dto);
  }
}
