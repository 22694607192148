import TYPES from '../../types';

// Domain
import {
  InvestmentSuggestionDto,
} from '@/modules/investment-suggestion/domain/dtos/investment-suggestion-dto';
import {
  InvestmentSuggestionRepository,
} from '@/modules/investment-suggestion/domain/repositories/investment-suggestion-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class InvestmentSuggestionCommand extends Command<InvestmentSuggestionDto> {
  @Inject(TYPES.INVESTMENT_SUGGESTION_REPOSITORY)
  private readonly repository!: InvestmentSuggestionRepository;

  internalExecute(investment_suggestion_dto: InvestmentSuggestionDto): Promise<void> {
    return this.repository.create(investment_suggestion_dto);
  }
}
