import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';
import RegisterDTO from '../../domain/dto/RegisterDTO';
import RegisterRepository from '../../domain/repository/RegisterRepository';
import TYPES from '../../types';

@Injectable()
export default class SignUpCommand extends Command<RegisterDTO> {
  @Inject(TYPES.REGISTER_REPOSITORY)
  private readonly registerRepository!: RegisterRepository;

  internalExecute(registerDTO: RegisterDTO) {
    return new Promise((resolve, reject) => {
      this.registerRepository.create(registerDTO)
        .then(() => {
          sessionStorage.setItem('user_id', registerDTO.id);
          resolve();
        })
        .catch(reject);
    });
  }
}
