// Domain
import {
  InvestorProfileOnCustomGoalRepository,
} from '@/modules/flagship/investor-profile/custom-goal/domain/repositories/investor-profile-on-custom-goal-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class InvestorProfileOnCustomGoalDummyRepository
implements InvestorProfileOnCustomGoalRepository {
  update(): Promise<any> {
    return Promise.resolve();
  }
}
