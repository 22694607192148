import TYPES from '../../types';

// Domain
import { CreateTotalWithdrawalDto } from '../../domain/dtos/create-total-withdrawal-dto';
import { TotalWithdrawalRepository } from '../../domain/repository/total-withdrawal-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CreateTotalWithdrawalCommand extends Command<CreateTotalWithdrawalDto> {
  @Inject(TYPES.TOTAL_WITHDRAWAL_REPOSITORY)
  private readonly repository!: TotalWithdrawalRepository;

  internalExecute(total_withdrawal_dto: CreateTotalWithdrawalDto) {
    return this.repository.create(total_withdrawal_dto);
  }
}
