import TYPES from '../../types';

// Domain
import { SearchByRangeDto } from '../../domain/dtos/search-by-range-dto';
import { TransactionEntity } from '../../domain/entities/transaction-entity';
import { TransactionRepository } from '../../domain/repository/transaction-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class SearchTransactionsByRangeQuery
  extends Query<Promise<Array<TransactionEntity>>> {
  @Inject(TYPES.ALLIANZ_TRANSACTION_REPOSITORY)
  private readonly repository!: TransactionRepository;

  internalExecute(params: SearchByRangeDto): Promise<Array<TransactionEntity>> {
    return this.repository.searchByRange(params);
  }
}
