export default {
  GET_ALLIANZ_ACCOUNT_QUERY: Symbol.for('GET_ALLIANZ_ACCOUNT_QUERY'),
  ALLIANZ_ACCOUNT_REPOSITORY: Symbol.for('ALLIANZ_ACCOUNT_REPOSITORY'),
  ACTIVATE_RECURRING_CONTRIBUTION_COMMAND: Symbol.for('ACTIVATE_RECURRING_CONTRIBUTION_COMMAND'),
  ACTIVATE_RECURRING_CONTRIBUTION_REPOSITORY: Symbol.for('ACTIVATE_RECURRING_CONTRIBUTION_REPOSITORY'),
  ALLIANZ_ACCOUNT_STATE_MANAGER: Symbol.for('ALLIANZ_ACCOUNT_STATE_MANAGER'),
  CANCEL_RECURRING_CONTRIBUTION_COMMAND: Symbol.for('CANCEL_RECURRING_CONTRIBUTION_COMMAND'),
  ALLIANZ_ACCOUNT_IS_ON_CANCELLATION_PROCESS_STATE_MANAGER: Symbol.for('ALLIANZ_ACCOUNT_IS_ON_CANCELLATION_PROCESS_STATE_MANAGER'),
  GET_ALLIANZ_ACCOUNT_IS_ON_CANCELLATION_PROCESS_QUERY: Symbol.for('GET_ALLIANZ_ACCOUNT_IS_ON_CANCELLATION_PROCESS_QUERY'),
  UPDATE_ACCOUNT_NUMBER_COMMAND: Symbol.for('UPDATE_ACCOUNT_NUMBER_COMMAND'),
};
