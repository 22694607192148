import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';
import { EmailEntity } from '../../domain/entities/email-entity';
import StateManager from '../../domain/state/state-manager';
import SearchEmailRepository from '../../domain/repository/search-email-repository';

@Injectable()
export default class OnBoardingSearchEmailsQuery extends Query<Array<EmailEntity>> {
  @Inject(TYPES.ON_BOARDING_EMAIL_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.ON_BOARDING_SEARCH_EMAIL_REPOSITORY)
  private readonly repository!: SearchEmailRepository;

  internalExecute() {
    if (this.state_manager.state.items.length < 1) {
      this.repository.execute()
        .then((items) => {
          this.state_manager.patch({ items });
        });
    }

    return this.state_manager.state.items;
  }
}
