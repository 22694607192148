import TYPES from '../../types';

// Domain
import { CustomerDocumentEntity } from '../../domain/entities/customer-document-entity';
import { CustomerDocumentsRepository } from '../../domain/repository/customer-documents-repository';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class SearchCustomerDocumentsQuery
  extends Query<Promise<Array<CustomerDocumentEntity>>> {
  @Inject(TYPES.MY_INVESTMENT_CUSTOMER_DOCUMENTS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.MY_INVESTMENT_CUSTOMER_DOCUMENTS_REPOSITORY)
  private readonly repository!: CustomerDocumentsRepository;

  internalExecute(): Promise<Array<CustomerDocumentEntity>> {
    return new Promise((resolve, reject) => {
      this.repository.execute()
        .then((items) => {
          this.state_manager.patch({ items });
          resolve(items);
        })
        .catch(reject);
    });
  }
}
