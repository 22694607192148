// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { GetCustomerWithdrawalDetailsRepository } from '../../../domain/repository';
import { CustomerWithdrawalDetailsEntity } from '../../../domain/entities/customer-withdrawal-details-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CustomerWithdrawalDetailsDummyRepository
implements GetCustomerWithdrawalDetailsRepository {
  get(): Promise<CustomerWithdrawalDetailsEntity> {
    return Promise.resolve(fixtures);
  }
}
