import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import { OperationHttpRepository, OperationDummyRepository } from './infrastructure/repository';

// Application
import { SearchOperationsQuery } from './application/queries';

// Domain
import { OperationRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupOperation(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.CATALOG_KUSPIT_OPERATION_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<OperationRepository>(TYPES.CATALOG_KUSPIT_OPERATION_REPOSITORY)
    .to(
      withDummies
        ? OperationDummyRepository
        : OperationHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchOperationsQuery>(TYPES.SEARCH_CATALOG_KUSPIT_OPERATION_QUERY)
    .to(SearchOperationsQuery)
    .inSingletonScope();
}
