import TYPES from '../../types';

// Domain
import { ValidateKuspitAccountDto }
  from '@/modules/my-investment/kuspit/kuspit-account-linking/domain/dtos/validate-kuspit-account-dto';
import { ValidateKuspitAccountRepository }
  from '@/modules/my-investment/kuspit/kuspit-account-linking/domain/repositories/validate-kuspit-account-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
class ValidateKuspitAccountCommand extends Command<ValidateKuspitAccountDto> {
  @Inject(TYPES.VALIDATE_KUSPIT_ACCOUNT_REPOSITORY)
  private readonly repository!: ValidateKuspitAccountRepository;

  internalExecute(kuspit_account_linking_dto: ValidateKuspitAccountDto): Promise<void> {
    return this.repository.create(kuspit_account_linking_dto);
  }
}

export default ValidateKuspitAccountCommand;
