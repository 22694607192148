import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import InvestorProfileOnFlowShowedDummyRepository
  from '@/modules/flagship/investor-profile/flow-showed/infrastructure/repositories/investor-profile-on-flow-showed-dummy-repository';
import InvestorProfileOnFlowShowedHttpRepository
  from '@/modules/flagship/investor-profile/flow-showed/infrastructure/repositories/investor-profile-on-flow-showed-http-repository';

// Application
import UpdateInvestorProfileOnFlowShowedCommand
  from '@/modules/flagship/investor-profile/flow-showed/application/commands/update-investor-profile-on-flow-showed-command';

// Domain
import {
  InvestorProfileOnFlowShowedRepository,
} from '@/modules/flagship/investor-profile/flow-showed/domain/repositories/investor-profile-on-flow-showed-repository';

export default function setupFlowShowed(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<InvestorProfileOnFlowShowedRepository>(
      TYPES.INVESTOR_PROFILE_ON_FLOW_SHOWED_REPOSITORY,
    ).to(
      withDummies
        ? InvestorProfileOnFlowShowedDummyRepository
        : InvestorProfileOnFlowShowedHttpRepository,
    ).inSingletonScope();

  container
    .bind<UpdateInvestorProfileOnFlowShowedCommand>(
      TYPES.UPDATE_INVESTOR_PROFILE_ON_FLOW_SHOWED_COMMAND,
    ).to(UpdateInvestorProfileOnFlowShowedCommand)
    .inSingletonScope();
}
