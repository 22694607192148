import editCustomGoal from './edit-custom-goal.json';
import initialAmount from './initial-amount.json';
import defineBy from './define-by.json';
import newCustomPlan from './new-custom-plan.json';
import customGoalPlanSuccess from './success.json';

export default {
  'edit-custom-goal-plan': {
    ...editCustomGoal,
    ...initialAmount,
    ...defineBy,
    ...newCustomPlan,
    ...customGoalPlanSuccess,
  },
};
