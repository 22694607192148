























































import { Vue, Component } from 'vue-property-decorator';
// eslint-disable-next-line
import UserProfileViewModel from '@/vue-app/view-models/views/user-profile-view-model';
import UserProfileMenu from '@/vue-app/components/user-profile/user-profile-menu/user-profile-menu.vue';
import UserProfileTab
  from '@/vue-app/components/user-profile/user-profile-tabs/user-profile-tab.vue';

@Component({
  name: 'UserProfile',
  components: {
    UserProfileMenu,
    UserProfileTab,
  },
})
export default class UserProfile extends Vue {
  user_profile_view_model = Vue.observable(new UserProfileViewModel());

  created() {
    this.user_profile_view_model.initialize();
  }
}
