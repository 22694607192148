import TYPES from '../../types';

// Domain
import { GlobalTrackingEntity } from '../../domain/entities/global-tracking-entity';
import { GlobalTrackingStateManager } from '../../domain/state/global-tracking-state-manager';
import { GlobalTrackingRepository } from '../../domain/repositories/global-tracking-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class GetGlobalTrackingQuery extends Query<Promise<GlobalTrackingEntity>> {
  @Inject(TYPES.GLOBAL_TRACKING_REPOSITORY)
  private readonly repository!: GlobalTrackingRepository;

  @Inject(TYPES.GLOBAL_TRACKING_STATE_MANAGER)
  private readonly state_manager!: GlobalTrackingStateManager;

  internalExecute(): Promise<GlobalTrackingEntity> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded) {
        resolve(this.state_manager.state.item);
      } else {
        this.repository.get().then((item) => {
          this.state_manager.patch({ item, loaded: true });
          resolve(item);
        }).catch(reject);
      }
    });
  }
}
