

















































































import { Component, Vue } from 'vue-property-decorator';
import VerifyNipViewModel from '@/vue-app/view-models/components/on-boarding/verify-nip-view-model';

@Component({ name: 'VerifyNip' })
export default class VerifyNip extends Vue {
  readonly verify_nip_view_model = Vue.observable(new VerifyNipViewModel(this));

  async created() {
    await this.$store.dispatch('layout/setLayoutHeader', {
      title: this.$t('sign-up.verify-identity.verify-nip.check'),
      right_action: {
        step: 2,
      },
    });
  }
}

