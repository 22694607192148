import Vue from 'vue';

// Domain
import { AccessMoreStrategiesStateManager } from '@/modules/my-investment/allianz/access-more-strategies/domain/state/access-more-strategies-state-manager';
import AccessMoreStrategiesState from '@/modules/my-investment/allianz/access-more-strategies/domain/state/access-more-strategies-state';
import Injectable from '@/modules/shared/domain/di/injectable';
import Observer from '@/modules/shared/domain/observer/observer';

@Injectable()
export default class AccessMoreStrategiesVueStateManager
implements AccessMoreStrategiesStateManager {
  private _state: AccessMoreStrategiesState = Vue.observable<AccessMoreStrategiesState>(
    new AccessMoreStrategiesState(),
  );

  private readonly observers: Observer[] = [];

  get state(): AccessMoreStrategiesState {
    return this._state;
  }

  set state(value: AccessMoreStrategiesState) {
    this._state = value;
  }

  patch(state: Partial<AccessMoreStrategiesState>) {
    type Keys = keyof AccessMoreStrategiesState;
    Object.entries(state).forEach(([key, value]) => {
      const accessor = key as Keys;

      if (value !== undefined) {
        // eslint-disable-next-line
        // @ts-ignore @typescript-eslint/ban-ts-ignore
        this.state[accessor] = value;
      }
    });
  }

  notifyAll(): void {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer): void {
    this.observers.push(observer);
  }
}
