import TYPES from '@/modules/shared/types';

// Domain
import { RecoveryPasswordRepository } from '../../../domain/repositories';
import { RecoveryPassword } from '../../../domain/dtos';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class RecoveryPasswordHttpRepository implements RecoveryPasswordRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly path = `${process.env.VUE_APP_API_URL}/api/v1/app/auth/recovery-password/update/`;

  restore(recovery_password: RecoveryPassword): Promise<void> {
    return new Promise((resolve, reject) => {
      const headers = {
        Authorization: `Bearer ${recovery_password.auth_token}`,
        'Content-Type': 'application/json',
      };
      const body = {
        new_password: recovery_password.new_password,
        new_password_confirmation: recovery_password.new_password_confirmation,
      };
      this.http_connector.execute(this.path, 'PUT', body, 0, false, headers)
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json()
              .then(({ message }) => {
                reject(new Error(message));
              });
          }
        })
        .catch(reject);
    });
  }
}
