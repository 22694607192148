// Domain
import { UserPasswordRepository } from '../../domain/repositories';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class UserPasswordFakeRepository implements UserPasswordRepository {
  update(): Promise<void> {
    return Promise.resolve();
  }
}
