import SHARED_TYPES from '@/modules/shared/types';

// Domain
import { CancelAccountRepository } from '../../domain/repositories';
import { CancelAccount } from '@/modules/user/cancel-account/domain/dtos/cancel-account';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class CancelAccountRestRepository implements CancelAccountRepository {
  @Inject(SHARED_TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  cancel(payload: CancelAccount): Promise<void> {
    const endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/dashboard/users/cancel-account/`;

    return new Promise((resolve, reject) => {
      this.http_connector.execute(endpoint, 'PATCH', payload)
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json()
              .then(({ message }) => {
                reject(new Error(message));
              });
          }
        })
        .catch(() => reject(new Error('Fetch Error')));
    });
  }
}
