


















































import { Component, Vue } from 'vue-property-decorator';

import InvestmentSuggestionPocketCardViewModel
  from '@/vue-app/view-models/components/investment-suggestion/investment-suggestion-pocket-card-view-model';

@Component({ name: 'InvestmentSuggestionPocketCard' })
export default class InvestmentSuggestionPocketCard extends Vue {
  pocket_card_model = Vue.observable(new InvestmentSuggestionPocketCardViewModel(this));

  created() {
    this.pocket_card_model.obtainInvestmentSuggestion();
  }
}
