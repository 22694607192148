import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import { CountryDummyRepository, CountryHttpRepository } from './infrastructure/repository';

// Application
import { SearchCountriesQuery } from './application/queries';

// Domain
import { CountryRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupCountry(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.CATALOG_ALLIANZ_COUNTRY_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<CountryRepository>(TYPES.CATALOG_ALLIANZ_COUNTRY_REPOSITORY)
    .to(
      withDummies
        ? CountryDummyRepository
        : CountryHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchCountriesQuery>(TYPES.SEARCH_CATALOG_ALLIANZ_COUNTRY_QUERY)
    .to(SearchCountriesQuery)
    .inSingletonScope();
}
