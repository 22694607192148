import TYPES from '../../types';

// Domain
import {
  PersonalInfoRepository,
} from '@/modules/flagship/investor-profile/personal-info/domain/repositories/personal-info-repository';
import {
  PersonalInfoEntity,
} from '@/modules/flagship/investor-profile/personal-info/domain/entities/personal-info-entity';
import {
  PersonalInfoStateManager,
} from '@/modules/flagship/investor-profile/personal-info/domain/state/personal-info-state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetPersonalInfoFlagshipProfilingQuery extends
  Query<Promise<PersonalInfoEntity>> {
  @Inject(TYPES.PERSONAL_INFO_REPOSITORY)
  private readonly repository!: PersonalInfoRepository;

  @Inject(TYPES.PERSONAL_INFO_STATE_MANAGER)
  private readonly state_manager!: PersonalInfoStateManager;

  internalExecute(investor_profile_id: string): Promise<PersonalInfoEntity> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded) {
        resolve(this.state_manager.state.personal_info);
      } else {
        this.repository.execute(investor_profile_id)
          .then((personal_info) => {
            this.state_manager.patch({ personal_info, loaded: true });
            resolve(personal_info);
          })
          .catch(reject);
      }
    });
  }
}
