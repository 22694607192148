import finishingAllianzProcess from './finishing-allianz-process.json';
import successfulAllianzProcess from './successful-allianz-process.json';
import accountStatusErrorAllianzProcess from './account-status-error-allianz-process.json';
import allianzProcessManualReviewRequestSent from './allianz-process-manual-review-request-sent.json';
import errorAllianzProcess from './error-allianz-process.json';

export default {
  ...finishingAllianzProcess,
  ...successfulAllianzProcess,
  ...accountStatusErrorAllianzProcess,
  ...allianzProcessManualReviewRequestSent,
  ...errorAllianzProcess,
};
