import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';
import { OnBoardingStatusRepository } from '../../domain/repositories/on-boarding-status-repository';

@Injectable()
export default class CompleteOnBoardingCommand extends Command {
  @Inject(TYPES.ON_BOARDING_STATUS_REPOSITORY)
  private readonly repository!: OnBoardingStatusRepository;

  internalExecute(): Promise<void> {
    return this.repository.complete();
  }
}
