import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import { DocumentTypeHttpRepository, DocumentTypeDummyRepository } from './infrastructure/repository';

// Application
import { SearchDocumentTypesQuery } from './application/queries';

// Domain
import { DocumentTypeRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupDocumentType(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.CATALOG_ALLIANZ_DOCUMENT_TYPE_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<DocumentTypeRepository>(TYPES.CATALOG_ALLIANZ_DOCUMENT_TYPE_REPOSITORY)
    .to(
      withDummies
        ? DocumentTypeDummyRepository
        : DocumentTypeHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchDocumentTypesQuery>(TYPES.SEARCH_CATALOG_ALLIANZ_DOCUMENT_TYPE_QUERY)
    .to(SearchDocumentTypesQuery)
    .inSingletonScope();
}
