import { Container } from 'inversify-props';
import TYPES from './types';
import FinancialInstitutionFetchRepository
  from './infrastructure/repository/financial-institution-fetch-repository';
import FinancialInstitutionDummyRepository
  from './infrastructure/repository/financial-institution-dummy-repository';
import VueStateManager from './infrastructure/state/vue-state-manager';
import { SearchFinancialInstitutionsQuery } from './application/queries';
import { FinancialInstitutionRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupFinancialInstitution(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.CATALOG_KUSPIT_FINANCIAL_INSTITUTION_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<FinancialInstitutionRepository>(TYPES.CATALOG_KUSPIT_FINANCIAL_INSTITUTION_REPOSITORY)
    .to(
      withDummies
        ? FinancialInstitutionDummyRepository
        : FinancialInstitutionFetchRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchFinancialInstitutionsQuery>(
      TYPES.SEARCH_CATALOG_KUSPIT_FINANCIAL_INSTITUTIONS_QUERY,
    )
    .to(SearchFinancialInstitutionsQuery)
    .inSingletonScope();
}
