import TYPES from '../../types';

// Domain
import {
  RetirementInvestorGoalCalculatorDto,
} from '../../domain/dtos/retirement-investor-goal-calculator-dto';
import {
  RetirementInvestorGoalCalculatorEntity,
} from '../../domain/entities/retirement-investor-goal-calculator-entity';
import {
  RetirementInvestorGoalCalculatorRepository,
} from '../../domain/repositories/retirement-investor-goal-calculator-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetRetirementInvestorGoalCalculatorQuery extends
  Query<Promise<RetirementInvestorGoalCalculatorEntity>> {
  @Inject(TYPES.RETIREMENT_INVESTOR_GOAL_CALCULATOR_REPOSITORY)
  private readonly repository!: RetirementInvestorGoalCalculatorRepository;

  internalExecute(retirement_fund_calculation_dto: RetirementInvestorGoalCalculatorDto):
    Promise<RetirementInvestorGoalCalculatorEntity> {
    return new Promise((resolve, reject) => {
      this.repository.get_retirement_fund_calculation(retirement_fund_calculation_dto)
        .then((data) => {
          resolve(data);
        })
        .catch(reject);
    });
  }
}
