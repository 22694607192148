// Infrastructure
import fixtures from './fixtures.json';

// Domain
import {
  OverallGoalsStatusRepository,
} from '@/modules/flagship/investor-profile/overall-goals-status/domain/repositories/overall-goals-status-repository';
import {
  OverallGoalsStatusEntity,
} from '@/modules/flagship/investor-profile/overall-goals-status/domain/entities/overall-goals-status-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class OverallGoalsStatusDummyRepository
implements OverallGoalsStatusRepository {
  get(): Promise<OverallGoalsStatusEntity> {
    return Promise.resolve(fixtures);
  }
}
