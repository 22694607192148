import withdrawal from './withdrawal.json';
import withdrawal_schedule from './withdrawal_schedule.json';
import withdrawal_confirm from './withdrawal_confirm.json';
import withdrawal_result from './withdrawal_result.json';
import withdrawal_distribute_retirement from './withdrawal_distribute_retirement.json';
import withdrawal_moderate from './withdrawal_moderate.json';
import pending_requests from './withdrawal_pending_requests.json';

export default {
  withdrawals: {
    ...withdrawal_schedule,
    ...withdrawal,
    ...withdrawal_confirm,
    ...withdrawal_result,
    ...withdrawal_moderate,
    'withdrawal-distribute-retirement': withdrawal_distribute_retirement,
    steps_header: [
      'Info. bancaria',
      'Distribución',
      'Confirmación',
      'Resumen',
    ],
    error_on_create_transaction: 'Ocurrió un error al realizar la operación de retiro.',
    errors: {
      get_investment_products_query: 'Ha ocurrido un error al obtener el catálogo de los productos.',
      get_search_by_customer_query: 'Ha ocurrido un error al obtener sus metas activas.',
    },
    ...pending_requests,
  },
};
