import TYPES from '../../types';

// Domain
import {
  RetirementForecastCalculationRepository,
} from '@/modules/flagship/retirement-forecast-calculation/domain/repositories/retirement-forecast-calculation-repository';
import {
  RetirementForecastCalculationDto,
} from '@/modules/flagship/retirement-forecast-calculation/domain/dtos/retirement-forecast-calculation-dto';
import {
  ForecastCalculationDto,
} from '@/modules/flagship/retirement-forecast-calculation/domain/dtos/forecast-calculation-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CreateRetirementForecastCalculationCommand
  extends Command<RetirementForecastCalculationDto> {
  @Inject(TYPES.RETIREMENT_FORECAST_CALCULATION_REPOSITORY)
  private readonly repository!: RetirementForecastCalculationRepository;

  // eslint-disable-next-line max-len
  internalExecute(forecast_calculation_dto: RetirementForecastCalculationDto): Promise<ForecastCalculationDto> {
    return this.repository.create(forecast_calculation_dto);
  }
}
