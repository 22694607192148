









import { Vue, Component } from 'vue-property-decorator';

import TYPES from '@/types';
import VerifyNip from '@/vue-app/components/on-boarding/VerifyNip.vue';
import GetOnBoardingCurrentStepController
  from '@/modules/on-boarding/steps/ui/controllers/get-on-boarding-current-step-controller';

// Application
import UpdateOnBoardingStepCommand
  from '@/modules/on-boarding/steps/application/commands/update-on-boarding-step-command';
import SearchOnBoardingStepsQuery
  from '@/modules/on-boarding/steps/application/queries/search-on-boarding-steps-query';
import { ResetIdentityVerificationStatus } from '@/modules/on-boarding/identity-verification/application/services';

// Domain
import Inject from '@/modules/shared/domain/di/inject';

const STEPS = [
  'VerifyNip',
  'OnBoardingVerifyIdentity',
  'OnBoardingVerifyIdentityConfirm',
  'OnBoardingWelcome',
];

@Component({
  name: 'OnBoarding',
  components: {
    VerifyNip,
    OnBoardingVerifyIdentity: () => import('@/vue-app/components/on-boarding/verify-identity/VerifyIdentity.vue'),
    OnBoardingVerifyIdentityConfirm: () => import('@/vue-app/components/on-boarding/verify-identity/Confirm.vue'),
    OnBoardingWelcome: () => import('@/vue-app/components/on-boarding/Welcome.vue'),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const searchOnBoardingStepsQuery = new SearchOnBoardingStepsQuery();
      searchOnBoardingStepsQuery.execute({ sort_field: 'created_at' })
        .then((steps) => {
          const last_step = steps[0];
          const getOnBoardingCurrentStepController = new GetOnBoardingCurrentStepController();
          const current_step = getOnBoardingCurrentStepController.execute(last_step);
          // eslint-disable-next-line no-param-reassign
          vm.$data.current_step = current_step;
          // eslint-disable-next-line no-param-reassign
          vm.$data.current_component = STEPS[current_step];
        });
    });
  },
})
export default class OnBoarding extends Vue {
  @Inject(TYPES.SEARCH_ON_BOARDING_STEPS_QUERY)
  readonly searchOnBoardingStepsQuery!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.UPDATE_ON_BOARDING_STEP_COMMAND)
  readonly updateOnBoardingStepCommand!: UpdateOnBoardingStepCommand;

  @Inject(TYPES.ON_BOARDING_RESET_IDENTITY_VERIFICATION_STATUS)
  readonly reset_identity_verification_status!: ResetIdentityVerificationStatus;

  current_step = 0;

  current_component = STEPS[this.current_step];

  nextStep() {
    if (this.current_step < STEPS.length) {
      this.current_step += 1;
      this.current_component = STEPS[this.current_step];
    }
  }

  prevStep() {
    if (this.current_step > 0) {
      this.current_step -= 1;
      this.current_component = STEPS[this.current_step];
    }
  }
}

