








































































































import { Component, Vue } from 'vue-property-decorator';
import UnauthenticatedFooterViewModel from '@/vue-app/view-models/components/footer/unauthenticated-footer-view-model';

@Component({ name: 'UnauthenticatedFooter' })
export default class UnauthenticatedFooter extends Vue {
  unauthenticated_footer_view_model = new UnauthenticatedFooterViewModel();
}
