import TYPES from '../../types';

// Domain
import {
  InvestorGoalRiskToleranceDto,
} from '../../domain/dto/investor-goal-risk-tolerance-dto';
import {
  InvestorGoalRiskToleranceRepository,
} from '../../domain/repositories/investor-goal-risk-tolerance-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UpdateInvestorGoalRiskToleranceCommand
  extends Command<InvestorGoalRiskToleranceDto> {
  @Inject(TYPES.INVESTOR_GOAL_RISK_TOLERANCE_REPOSITORY)
  private readonly repository!: InvestorGoalRiskToleranceRepository;

  internalExecute(investor_goal_risk_tolerance_dto: InvestorGoalRiskToleranceDto): Promise<void> {
    return this.repository.update(investor_goal_risk_tolerance_dto);
  }
}
