import TYPES from '../../types';

// Domain
import { CancelTransactionDto } from '../../domain/dtos/cancel-transaction-dto';
import { TransactionRepository } from '../../domain/repository/transaction-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CancelTransactionCommand extends Command<CancelTransactionDto> {
  @Inject(TYPES.ALLIANZ_TRANSACTION_REPOSITORY)
  private readonly repository!: TransactionRepository;

  internalExecute(transaction_dto: CancelTransactionDto) {
    return this.repository.cancel(transaction_dto);
  }
}
