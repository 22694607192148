import { PendingInvestorGoalMovementsEntity } from '../entities/pending-investor-goal-movements-entity';

export default class PendingInvestorGoalMovementsState {
  loaded = false;

  items: Array<PendingInvestorGoalMovementsEntity> = [
    {
      id: '',
      customer_id: '',
      investment_product: {
        id: '',
        investment_provider_id: '',
        description: '',
        label: '',
        name: '',
      },
      is_deposit: true,
      created_at: '',
      transaction_type: {
        description: '',
        id: '',
        name: '',
      },
      amount: 0,
      distribution_status: {
        description: '',
        id: '',
        name: '',
        label: '',
      },
      is_pending: true,
      investment_product_fund_type: {
        id: '',
        name: '',
        label: '',
      },
    },
  ]
}
