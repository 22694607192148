import TYPES from '../../types';

// Domain
import { GetPersonRepository } from '../../domain/repository/get-person-repository';
import { PersonEntity } from '../../domain/entities/person-entity';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetPersonQueryService extends Query<PersonEntity> {
  @Inject(TYPES.INVESTMENT_PERSON_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.GET_INVESTMENT_PERSON_REPOSITORY)
  private readonly person_repository!: GetPersonRepository;

  internalExecute(): PersonEntity {
    if (this.state_manager.state.id === '') {
      this.person_repository.get()
        .then((person) => this.state_manager.patch(person));
    }

    return this.state_manager.state;
  }
}
