import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import InvestorProfileOnEconomicDependentsDummyRepository
  from '@/modules/flagship/investor-profile/economic-dependents/infraestructure/repositories/investor-profile-on-economic-dependents-dummy-repository';
import InvestorProfileOnEconomicDependentsHttpRepository
  from '@/modules/flagship/investor-profile/economic-dependents/infraestructure/repositories/investor-profile-on-economic-dependents-http-repository';

// Application
import UpdateInvestorProfileOnEconomicDependentsCommand
  from '@/modules/flagship/investor-profile/economic-dependents/application/commands/update-investor-profile-on-economic-dependents-command';

// Domain
import {
  InvestorProfileOnEconomicDependentsRepository,
} from '@/modules/flagship/investor-profile/economic-dependents/domain/repositories/investor-profile-on-economic-dependents-repository';

export default function setupEconomicDependents(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<InvestorProfileOnEconomicDependentsRepository>(
      TYPES.INVESTOR_PROFILE_ON_ECONOMIC_DEPENDENTS_REPOSITORY,
    ).to(
      withDummies
        ? InvestorProfileOnEconomicDependentsDummyRepository
        : InvestorProfileOnEconomicDependentsHttpRepository,
    ).inSingletonScope();

  container
    .bind<UpdateInvestorProfileOnEconomicDependentsCommand>(
      TYPES.UPDATE_INVESTOR_PROFILE_ON_ECONOMIC_DEPENDENTS_COMMAND,
    ).to(UpdateInvestorProfileOnEconomicDependentsCommand)
    .inSingletonScope();
}
