import TYPES from '../../types';

// Domain
import { AllianzCustomerAvailableBalanceEntity } from '../../domain/entities/allianz-customer-available-balance-entity';
import { AllianzCustomerAvailableBalanceRepository } from '../../domain/repository/allianz-customer-available-balance-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class SearchAllianzCustomerAvailableBalanceQuery
  extends Query<Promise<AllianzCustomerAvailableBalanceEntity>> {
  @Inject(TYPES.ALLIANZ_CUSTOMER_AVAILABLE_BALANCE_REPOSITORY)
  private readonly repository!: AllianzCustomerAvailableBalanceRepository;

  internalExecute(fund_code: string):
    Promise<AllianzCustomerAvailableBalanceEntity> {
    return new Promise((resolve, reject) => {
      this.repository.execute(fund_code)
        .then((allianz_customer_available_balance) => {
          resolve(allianz_customer_available_balance);
        })
        .catch(reject);
    });
  }
}
