// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { DocumentTypeRepository } from '../../../domain/repository';
import { DocumentTypeEntity } from '../../../domain/entities/document-type-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class DocumentTypeDummyRepository implements DocumentTypeRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(): Promise<Array<DocumentTypeEntity>> {
    return Promise.resolve(fixtures);
  }
}
