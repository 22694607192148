import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import {
  UserPasswordRestRepository,
  UserPasswordFakeRepository,
} from './infrastructure/repositories';

// Application
import { UpdateUserPasswordCommand } from './application/commands';

// Domain
import { UserPasswordRepository } from './domain/repositories';

export default function setupUserPasswordModule(container: Container) {
  const with_dummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<UserPasswordRepository>(TYPES.USER_PASSWORD_REPOSITORY)
    .to(
      with_dummies
        ? UserPasswordFakeRepository
        : UserPasswordRestRepository,
    )
    .inSingletonScope();

  container
    .bind<UpdateUserPasswordCommand>(TYPES.UPDATE_USER_PASSWORD_COMMAND)
    .to(UpdateUserPasswordCommand)
    .inSingletonScope();
}
