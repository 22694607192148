import i18n from '@/vue-app/plugins/i18n';
import { getCurpCheckDigit } from '@/vue-app/utils/curp';
import { verifyRfcCheckDigit } from '@/vue-app/utils/rfc';
import parsePhoneNumber from 'libphonenumber-js';
import { clabe } from 'clabe-validator';

export type RuleResponseType = string | true;

const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const REGEX_CURP = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
export const REGEX_RFC = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
export const REGEX_HOMOCLAVE_RFC = /^([A-Z\d]{2})([A\d])$/;
const REGEX_ELECTOR_KEY = /^[A-Z]{6}[0-9]{8}[A-Z][0-9]{3}$/;
const REGEX_PASSWORD = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
const REGEX_PERCENTAGE = /^[1-9][0-9]?$|^100$/;

export const requiredRule = (value: string): RuleResponseType => !!value || i18n.t('utils.form-rules.required').toString();
export const requiredRuleByKey = (value: any, key: string): RuleResponseType => (value && value[key] !== null && value[key].trim() !== '') || i18n.t('utils.form-rules.required').toString();
export const percentageFormat = (value: string): RuleResponseType => REGEX_PERCENTAGE.test(value) || i18n.t('utils.form-rules.percentage_format').toString();
export const maxLengthRule = (max_length: number, value: string): RuleResponseType => value.length < max_length || i18n.t('utils.form-rules.max_length_error', { max_length }).toString();
export const minValueRule = (amount: string, value: string, min = 1): RuleResponseType => Number(amount) >= min || i18n.t('utils.form-rules.minimum_error', { value }).toString();
export const maxValueRule = (amount: string, value: string, max: number): RuleResponseType => Number(amount) <= max || i18n.t('utils.form-rules.maximum_error', { value }).toString();
export const maxLengthGoalNameRule = (max_length: number, value: string): RuleResponseType => value.length < max_length || i18n.t('utils.form-rules.max_length_goal_name_error', { max_length }).toString();
export const emailFormat = (value: string): RuleResponseType => {
  if (!value) {
    return true;
  }

  return REGEX_EMAIL.test(value) || i18n.t('utils.form-rules.email_format').toString();
};
export const passwordFormat = (value: string): RuleResponseType => {
  if (!value) {
    return true;
  }

  return REGEX_PASSWORD.test(value) || i18n.t('utils.form-rules.password_format').toString();
};
export const curpFormat = (value: string): RuleResponseType => {
  if (!value) {
    return true;
  }

  const validatedCurp = value.match(REGEX_CURP);

  if (!validatedCurp) {
    return i18n.t('utils.form-rules.curp_format').toString();
  }

  if (validatedCurp[2] !== getCurpCheckDigit(value)) {
    return i18n.t('utils.form-rules.curp_format').toString();
  }

  return true;
};
export const rfcFormat = (value: string): RuleResponseType => {
  if (!value) {
    return true;
  }

  const validatedRfc = value.match(REGEX_RFC);

  if (!validatedRfc) {
    return i18n.t('utils.form-rules.rfc_format').toString();
  }

  if (!verifyRfcCheckDigit(validatedRfc)) {
    return i18n.t('utils.form-rules.rfc_format').toString();
  }

  return true;
};

export const rfcHomoclaveFormat = (value: string): RuleResponseType => {
  if (!value) {
    return true;
  }

  const validatedRfcHomoclave = value.match(REGEX_HOMOCLAVE_RFC);

  if (!validatedRfcHomoclave) {
    return i18n.t('utils.form-rules.rfc_format').toString();
  }

  return true;
};

export const electorKeyFormat = (value: string): RuleResponseType => {
  if (!value) {
    return true;
  }

  return REGEX_ELECTOR_KEY.test(value) || i18n.t('utils.form-rules.elector_key_format').toString();
};
export const postalCodeFormat = (value: string): RuleResponseType => {
  if (!value) {
    return true;
  }

  return value.length === 5 || i18n.t('utils.form-rules.postal_code_format').toString();
};
export const phoneFormat = (country_code: string, value: string): RuleResponseType => {
  if (!value) {
    return true;
  }

  const cleaned_value = value.replace(/[^0-9]/g, '');

  if (cleaned_value.length < 10) {
    return i18n.t('utils.form-rules.phone_format').toString();
  }

  return parsePhoneNumber(`${country_code}${cleaned_value}`)?.isPossible() || i18n.t('utils.form-rules.phone_format').toString();
};
export const clabeFormat = (value: string): RuleResponseType => {
  if (!value) {
    return true;
  }

  return clabe.validate(value.replace(/\s/g, '')).ok || i18n.t('utils.form-rules.clabe_format').toString();
};
