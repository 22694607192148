// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { FileRepository } from '../../../domain/repository';
import { FileEntity } from '../../../domain/entities/file-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class FileDummyRepository implements FileRepository {
  get(file_id: string): Promise<FileEntity> {
    return Promise.resolve(fixtures);
  }
}
