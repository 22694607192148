import editEmergencyFund from './edit-emergency-fund.json';
import yourBudget from './your-budget.json';
import yourCurrentAmount from './your-current-amount.json';
import defineBy from './define-by.json';
import newEmergencyFund from './new-emergency-fund.json';
import success from './success.json';

export default {
  'edit-emergency-fund-goal-plan': {
    ...editEmergencyFund,
    ...yourBudget,
    ...yourCurrentAmount,
    ...defineBy,
    ...newEmergencyFund,
    ...success,
  },
};
