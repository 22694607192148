import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';
import { UpdatePersonDto } from '../../domain/dtos/update-person-dto';
import UpdatePersonRepository from '../../domain/repositories/update-person-repository';
import TYPES from '../../types';

@Injectable()
export default class UpdateOnBoardingPersonCommand extends Command<UpdatePersonDto> {
  @Inject(TYPES.ON_BOARDING_UPDATE_PERSON_REPOSITORY)
  private readonly repository!: UpdatePersonRepository;

  internalExecute(person: UpdatePersonDto) {
    return this.repository.update(person);
  }
}
