// Infrastructure
import fixtures from './fixtures.json';
import recurring_contribution_cancelled_fixtures from './recurring-contribution-cancelled-fixtures.json';
import allianz_account_is_on_cancellation_process_fixtures from './allianz-account-is-on-cancellation-process-fixtures.json';

// Domain
import { AllianzAccountRepository } from '../../../domain/repository';
import { AllianzAccountEntity } from '../../../domain/entities/allianz-account-entity';
import { RecurringContributionCancelledEntity } from '../../../domain/entities/recurring-contribution-cancelled-entity';
import { RecurringContributionCancellerEntity } from '../../../domain/entities/recurring-contribution-canceller-entity';
import { AllianzAccountIsOnCancellationProcessEntity } from '../../../domain/entities/allianz-account-is-on-cancellation-process-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class AllianzAccountDummyRepository implements AllianzAccountRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(customer_id: string): Promise<AllianzAccountEntity> {
    return Promise.resolve(fixtures);
  }

  cancel(recurring_contribution_canceller_entity: RecurringContributionCancellerEntity):
    Promise<RecurringContributionCancelledEntity> {
    return Promise.resolve(recurring_contribution_cancelled_fixtures);
  }

  get_allianz_account_is_on_cancellation_process():
  Promise<AllianzAccountIsOnCancellationProcessEntity> {
    return Promise.resolve(allianz_account_is_on_cancellation_process_fixtures);
  }

  updateAccountNumber(): Promise<void> {
    return Promise.resolve();
  }
}
