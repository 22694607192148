import TYPES from '../../types';

// Domain
import {
  ActivateSowosModerateDto,
} from '../../domain/dto/activate-sowos-moderate-dto';
import {
  ActivateSowosModerateRepository,
} from '../../domain/repositories/activate-sowos-moderate-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class ActivateSowosModerateCommand extends Command<ActivateSowosModerateDto> {
  @Inject(TYPES.ACTIVATE_SOWOS_MODERATE_REPOSITORY)
  private readonly repository!: ActivateSowosModerateRepository;

  internalExecute(activate_sowos_moderate_dto: ActivateSowosModerateDto): Promise<void> {
    return this.repository.activate(activate_sowos_moderate_dto);
  }
}
