import { Container } from 'inversify-props';
import TYPES from './types';
import VueStateManager from './infrastructure/state/vue-state-manager';
import PermissionsStatusSubscription
  from './application/subscriptions/permissions-status-subscription';
import GetPermissionsStatusService from './application/services/get-permissions-status-service';
import { StateManager } from './domain/state/state-manager';

export default function setupStatus(container: Container) {
  container
    .bind<StateManager>(TYPES.PERMISSIONS_STATUS_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<PermissionsStatusSubscription>(TYPES.PERMISSIONS_STATUS_SUBSCRIPTION)
    .to(PermissionsStatusSubscription)
    .inSingletonScope();

  container
    .bind<GetPermissionsStatusService>(TYPES.GET_PERMISSIONS_STATUS_SERVICE)
    .to(GetPermissionsStatusService)
    .inSingletonScope();
}
