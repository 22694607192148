import TYPES from '@/modules/shared/types';

// Domain
import {
  RetirementForecastCalculationRepository,
} from '@/modules/flagship/retirement-forecast-calculation/domain/repositories/retirement-forecast-calculation-repository';
import {
  RetirementForecastCalculationDto,
} from '@/modules/flagship/retirement-forecast-calculation/domain/dtos/retirement-forecast-calculation-dto';
import {
  ForecastCalculationDto,
} from '@/modules/flagship/retirement-forecast-calculation/domain/dtos/forecast-calculation-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class RetirementForecastCalculationHttpRepository
implements RetirementForecastCalculationRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/retirement-forecast-calculation/`;

  get(): Promise<ForecastCalculationDto> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint)
        .then((response) => {
          response.json()
            .then(({ success, data, error }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(error));
              }
            });
        })
        .catch(reject);
    });
  }

  update(retirement_forecast_dto: RetirementForecastCalculationDto): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}${retirement_forecast_dto.investor_goal_id}/`,
        'PATCH', {
          initial_amount: retirement_forecast_dto.initial_amount,
          monthly_required_amount: retirement_forecast_dto.monthly_required_amount,
        })
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => {
                reject(new Error(message));
              },
            );
          }
        })
        .catch(reject);
    });
  }

  // eslint-disable-next-line max-len
  create(forecast_calculation_dto: RetirementForecastCalculationDto): Promise<ForecastCalculationDto> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint, 'POST', {
        initial_amount: forecast_calculation_dto.initial_amount,
        monthly_required_amount: forecast_calculation_dto.monthly_required_amount,
        retirement_age_adjusted: forecast_calculation_dto.retirement_age_adjusted,
      })
        .then((response) => {
          response.json()
            .then(({ success, data, error }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(error));
              }
            });
        })
        .catch(reject);
    });
  }
}
