// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { RelationshipRepository } from '../../../domain/repository';
import { RelationshipEntity } from '../../../domain/entities/relationship-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class RelationshipDummyRepository implements RelationshipRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(): Promise<Array<RelationshipEntity>> {
    return Promise.resolve(fixtures);
  }
}
