import TYPES from '../../types';

// Domain
import {
  UploadFileProofOfAddressRepository,
} from '../../domain/repository/upload-file-proof-of-address-repository';
import { FileDto } from '../../domain/dtos/file-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UploadFileProofOfAddressCommand extends Command<FileDto> {
  @Inject(TYPES.MY_INVESTMENT_UPLOAD_FILE_PROOF_OF_ADDRESS_REPOSITORY)
  private readonly repository!: UploadFileProofOfAddressRepository;

  internalExecute(file_dto: FileDto) {
    return this.repository.upload(file_dto);
  }
}
