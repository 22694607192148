// Infrastructure
import fixtures from './fixtures.json';

// Domain
import {
  InvestmentSuggestionEntity,
} from '@/modules/investment-suggestion/domain/entities/investment-suggestion-entity';
import {
  InvestmentSuggestionRepository,
} from '@/modules/investment-suggestion/domain/repositories/investment-suggestion-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class InvestmentSuggestionDummyRepository implements InvestmentSuggestionRepository {
  get(): Promise<InvestmentSuggestionEntity> {
    return Promise.resolve(fixtures);
  }

  create(): Promise<void> {
    return Promise.resolve();
  }
}
