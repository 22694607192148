













































































































































































































import { Component, Vue } from 'vue-property-decorator';
import RecoveryPasswordViewModel from '@/vue-app/view-models/views/recovery-password-view-model';

@Component({
  name: 'RecoveryPassword',
  components: {
    SuccessfullyPasswordRecovered: () => import('@/vue-app/components/recovery-password/SuccessfullyPasswordRecovered.vue'),
  },
})
export default class RecoveryPassword extends Vue {
  recovery_password_view_model = Vue.observable(new RecoveryPasswordViewModel());

  created() {
    if (this.$route?.query?.token) {
      this.recovery_password_view_model.setToken(this.$route?.query?.token.toString());
    }
  }
}

