import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import CustomerPersonalityProfileDummyRepository
  from './infrastructure/repositories/customer-personality-profile-dummy-repository/customer-personality-profile-dummy-repository';
import CustomerPersonalityProfileHttpRepository
  from './infrastructure/repositories/customer-personality-profile-http-repository';
import CustomerPersonalityProfileVueStateManager
  from './infrastructure/state/customer-personality-profile-vue-state-manager';

// Application
import GetCustomerPersonalityProfileQuery
  from './application/query/get-customer-personality-profile-query';

// Domain
import { CustomerPersonalityProfileRepository } from './domain/repositories/customer-personality-profile-repository';
import { CustomerPersonalityProfileStateManager } from './domain/state/customer-personality-profile-state-manager';

export default function setupCustomerInvestorGoalBalance(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    // eslint-disable-next-line max-len
    .bind<CustomerPersonalityProfileStateManager>(TYPES.CUSTOMER_PERSONALITY_PROFILE_STATE_MANAGER)
    .to(CustomerPersonalityProfileVueStateManager)
    .inSingletonScope();

  container
    .bind<CustomerPersonalityProfileRepository>(TYPES.CUSTOMER_PERSONALITY_PROFILE_REPOSITORY)
    .to(
      withDummies
        ? CustomerPersonalityProfileDummyRepository : CustomerPersonalityProfileHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetCustomerPersonalityProfileQuery>(TYPES.GET_CUSTOMER_PERSONALITY_PROFILE_QUERY)
    .to(GetCustomerPersonalityProfileQuery)
    .inSingletonScope();
}
