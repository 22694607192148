import Vue from 'vue';

// Domain
import { EmergencyFundAvailabilityStateManager } from '@/modules/flagship/catalogs/emergency-fund-availability/domain/state/emergency-fund-availability-state-manager';
import EmergencyFundAvailabilityState
  from '@/modules/flagship/catalogs/emergency-fund-availability/domain/state/emergency-fund-availability-state';
import Injectable from '@/modules/shared/domain/di/injectable';
import Observer from '@/modules/shared/domain/observer/observer';

@Injectable()
export default class EmergencyFundAvailabilityVueStateManager
implements EmergencyFundAvailabilityStateManager {
  // eslint-disable-next-line max-len
  private _state: EmergencyFundAvailabilityState = Vue.observable<EmergencyFundAvailabilityState>(new EmergencyFundAvailabilityState());

  private readonly observers: Observer[] = [];

  get state(): EmergencyFundAvailabilityState {
    return this._state;
  }

  set state(value: EmergencyFundAvailabilityState) {
    this._state = value;
    this.notifyAll();
  }

  patch(state: Partial<EmergencyFundAvailabilityState>) {
    type Keys = keyof EmergencyFundAvailabilityState;
    Object.entries(state).forEach(([key, value]) => {
      const accessor = key as Keys;

      if (value !== undefined) {
        // eslint-disable-next-line
        // @ts-ignore @typescript-eslint/ban-ts-ignore
        this.state[accessor] = value;
      }
    });
  }

  notifyAll() {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer) {
    this.observers.push(observer);
  }
}
