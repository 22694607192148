import TYPES from '../../types';

// Domain
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class InvestorGoalRiskToleranceChoseWarningAnswerService {
  @Inject(TYPES.INVESTOR_GOAL_RISK_TOLERANCE_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  setInvestorGoalRiskToleranceChoseWarningAnswer(user_chose_a_warning_answer: boolean) {
    this.state_manager.state.user_chose_a_warning_answer = user_chose_a_warning_answer;
  }

  getInvestorGoalRiskToleranceChoseWarningAnswer(): boolean {
    return this.state_manager.state.user_chose_a_warning_answer;
  }
}
