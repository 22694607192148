import { AllianzPolicyIssuanceStatusEntity }
  from '../entities/allianz-policy-issuance-status-entity';

export default class State {
  loaded = false;

  allianz_policy_issuance_status: AllianzPolicyIssuanceStatusEntity = {
    id: '',
    customer_id: '',
    account_status_validated: '',
    stored_questionnaire_transactional_profile: false,
    stored_questionnaire_investment_profile: false,
    validated_policy: false,
    issued_policy: false,
    generated_documents: false,
    sent_documents: false,
    first_interaction_with_dashboard: false,
    created_at: '',
    updated_at: '',
  };
}
