import TYPES from '../../types';

// Domain
import { CreateAllianzPolicyRepository } from '../../domain/repository';
import { AllianzPolicyEntity } from '../../domain/entities/allianz-policy-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CreateAllianzPolicyCommand extends Command<AllianzPolicyEntity> {
  @Inject(TYPES.CREATE_ALLIANZ_POLICY_REPOSITORY)
  private readonly repository!: CreateAllianzPolicyRepository;

  internalExecute(allianz_policy_entity: AllianzPolicyEntity): Promise<unknown> {
    return this.repository.create(allianz_policy_entity);
  }
}
