// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { OnBoardingInvitationsStatisticsRepository } from '../../../domain/repository';
import { StatisticsDto } from '../../../domain/dtos/statistics-dto';
import { StatisticsEntity } from '../../../domain/entities/statistics-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class OnBoardingInvitationsStatisticsDummyRepository
implements OnBoardingInvitationsStatisticsRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(statistics_dto: StatisticsDto): Promise<StatisticsEntity> {
    return Promise.resolve(fixtures);
  }
}
