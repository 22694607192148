import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import InvestmentGoalsDummyRepository
  from '@/modules/flagship/catalogs/investment-goals/infraestructure/repositories/investment-goals-dummy-repository/investment-goals-dummy-repository';
import InvestmentGoalsHttpRepository
  from '@/modules/flagship/catalogs/investment-goals/infraestructure/repositories/investment-goals-http-repository';
import InvestmentGoalsVueStateManager
  from '@/modules/flagship/catalogs/investment-goals/infraestructure/state/investment-goals-vue-state-manager';

// Application
import GetInvestmentGoalsQuery
  from '@/modules/flagship/catalogs/investment-goals/application/queries/get-investment-goals-query';

// Domain
import {
  InvestmentGoalsRepository,
} from '@/modules/flagship/catalogs/investment-goals/domain/repositories/investment-goals-repository';
import {
  InvestmentGoalsStateManager,
} from '@/modules/flagship/catalogs/investment-goals/domain/state/investment-goals-state-manager';

export default function setupInvestmentGoals(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<InvestmentGoalsStateManager>(TYPES.INVESTMENT_GOALS_STATE_MANAGER)
    .to(InvestmentGoalsVueStateManager)
    .inSingletonScope();

  container
    .bind<InvestmentGoalsRepository>(
      TYPES.INVESTMENT_GOALS_REPOSITORY,
    ).to(
      withDummies
        ? InvestmentGoalsDummyRepository
        : InvestmentGoalsHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetInvestmentGoalsQuery>(
      TYPES.GET_INVESTMENT_GOALS_QUERY,
    ).to(GetInvestmentGoalsQuery)
    .inSingletonScope();
}
