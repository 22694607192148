import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import { IncomeDummyRepository, IncomeHttpRepository } from './infrastructure/repository';

// Application
import { SearchIncomesQuery } from './application/queries';

// Domain
import { IncomeRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupIncome(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.CATALOG_KUSPIT_INCOME_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<IncomeRepository>(TYPES.CATALOG_KUSPIT_INCOME_REPOSITORY)
    .to(
      withDummies
        ? IncomeDummyRepository
        : IncomeHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchIncomesQuery>(TYPES.SEARCH_CATALOG_KUSPIT_INCOME_QUERY)
    .to(SearchIncomesQuery)
    .inSingletonScope();
}
