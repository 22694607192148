import Vue from 'vue';

// Domain
import { CustomerPersonalityProfileStateManager } from '../../domain/state/customer-personality-profile-state-manager';
import CustomerPersonalityProfileState from '../../domain/state/customer-personality-profile-state';
import Injectable from '@/modules/shared/domain/di/injectable';
import Observer from '@/modules/shared/domain/observer/observer';

@Injectable()
export default class CustomerPersonalityProfileVueStateManager
implements CustomerPersonalityProfileStateManager {
  // eslint-disable-next-line max-len
    private _state: CustomerPersonalityProfileState = Vue.observable<CustomerPersonalityProfileState>(
      new CustomerPersonalityProfileState(),
    );

    private readonly observers: Observer[] = [];

    get state(): CustomerPersonalityProfileState {
      return this._state;
    }

    set state(value: CustomerPersonalityProfileState) {
      this._state = value;
    }

    patch(state: Partial<CustomerPersonalityProfileState>) {
      type Keys = keyof CustomerPersonalityProfileState;
      Object.entries(state).forEach(([key, value]) => {
        const accessor = key as Keys;
        if (value !== undefined) {
          // eslint-disable-next-line
          // @ts-ignore @typescript-eslint/ban-ts-ignore
          this.state[accessor] = value;
        }
      });
    }

    notifyAll(): void {
      this.observers.forEach((observer) => observer.notify());
    }

    register(observer: Observer): void {
      this.observers.push(observer);
    }
}
