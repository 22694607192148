import Vue from 'vue';

// Domain
import {
  CustomerContractedInvestmentProductsStateManager,
} from '@/modules/flagship/customer-contracted-investment-products/domain/state/customer-contracted-investment-products-state-manager';
import CustomerContractedInvestmentProductsState
  from '@/modules/flagship/customer-contracted-investment-products/domain/state/customer-contracted-investment-products-state';
import Observer from '@/modules/shared/domain/observer/observer';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CustomerContractedInvestmentProductsVueStateManager
implements CustomerContractedInvestmentProductsStateManager {
  // eslint-disable-next-line max-len
  private _state: CustomerContractedInvestmentProductsState = Vue.observable<CustomerContractedInvestmentProductsState>(
    new CustomerContractedInvestmentProductsState(),
  );

  private readonly observers: Observer[] = [];

  get state(): CustomerContractedInvestmentProductsState {
    return this._state;
  }

  set state(value: CustomerContractedInvestmentProductsState) {
    this._state = value;
  }

  patch(state: Partial<CustomerContractedInvestmentProductsState>) {
    type Keys = keyof CustomerContractedInvestmentProductsState;
    Object.entries(state).forEach(([key, value]) => {
      const accessor = key as Keys;

      if (value !== undefined) {
        // eslint-disable-next-line
        // @ts-ignore @typescript-eslint/ban-ts-ignore
        this.state[accessor] = value;
      }
    });
  }

  notifyAll(): void {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer): void {
    this.observers.push(observer);
  }
}
