import TYPES from '@/modules/shared/types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';
import RenewVerifyNipRepository from '../../domain/repositories/renew-verify-nip-repository';
import { RenewOnBoardingVerifyNipDto } from '@/modules/on-boarding/renew-verify-nip/domain/dtos/renew-on-boarding-verify-nip-dto';

@Injectable()
export default class RenewVerifyNipHttpRepository implements RenewVerifyNipRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly path = `${process.env.VUE_APP_API_URL}/api/v1/app/on-boarding/renew-customer-nip/`;

  execute(payload: RenewOnBoardingVerifyNipDto): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector
        .execute(this.path, 'POST', payload)
        .then((response) => {
          response.json().then(({ message, success }) => {
            if (success) {
              resolve();
            } else {
              reject(new Error(message));
            }
          });
        })
        .catch(reject);
    });
  }
}
