// Infrastructure
import fixtures from './fixtures.json';

// Domain
import {
  PersonalInfoRepository,
} from '@/modules/flagship/investor-profile/personal-info/domain/repositories/personal-info-repository';
import {
  PersonalInfoEntity,
} from '@/modules/flagship/investor-profile/personal-info/domain/entities/personal-info-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class PersonalInfoDummyRepository implements PersonalInfoRepository {
  execute(): Promise<PersonalInfoEntity> {
    return Promise.resolve(fixtures);
  }
}
