import recovery_password from './recovery-password.json';
import forgot_password from './forgot_password.json';
import successfully_password_recovered from './successfully_password_recovered.json';

export default {
  recovery_password: {
    ...recovery_password,
  },
  forgot_password: {
    ...forgot_password,
  },
  successfully_password_recovered: {
    ...successfully_password_recovered,
  },
};
