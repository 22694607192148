import fixtures from './fixtures.json';
import Injectable from '@/modules/shared/domain/di/injectable';
import { CalendlyRepository } from '../../../domain/repository/calendly-repository';
import { SingleUseScheduleLinkEntity } from '../../../domain/entities/single-use-schedule-link-entity';

@Injectable()
export default class CalendlyDummyRepository implements CalendlyRepository {
  // eslint-disable-next-line class-methods-use-this
  get(): Promise<SingleUseScheduleLinkEntity> {
    return Promise.resolve(fixtures);
  }
}
