import AGREEMENT_TYPES from './agreements/types';
import PERSON_TYPES from './person/types';
import CATALOGS_TYPES from './catalogs/types';
import EMAILS_TYPES from './emails/types';
import INVESTMENT_PROVIDER_TYPES from './investment-provider/types';
import ON_BOARDING_STEP_TYPES from './on-boarding-steps/types';
import ADDRESS_TYPES from './address/types';
import PERMISSIONS_TYPES from './permissions/types';
import KUSPIT_TYPES from './kuspit/types';
import ALLIANZ_TYPES from './allianz/types';
import UPLOAD_FILE_TYPES from './upload-file/types';
import ON_BOARDING_INVITATIONS_TYPES from './on-boarding-invitations/types';
import CUSTOMER_DOCUMENTS_TYPES from './customer-documents/types';
import FILE_TYPES from './file/types';
import INVESTMENT_PRODUCT_TYPES from './investment-product/types';

export default {
  ...AGREEMENT_TYPES,
  ...PERSON_TYPES,
  ...CATALOGS_TYPES,
  ...EMAILS_TYPES,
  ...INVESTMENT_PROVIDER_TYPES,
  ...ON_BOARDING_STEP_TYPES,
  ...ADDRESS_TYPES,
  ...PERMISSIONS_TYPES,
  ...KUSPIT_TYPES,
  ...ALLIANZ_TYPES,
  ...UPLOAD_FILE_TYPES,
  ...ON_BOARDING_INVITATIONS_TYPES,
  ...CUSTOMER_DOCUMENTS_TYPES,
  ...FILE_TYPES,
  ...INVESTMENT_PRODUCT_TYPES,
};
