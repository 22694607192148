import TYPES from '../../types';

// Domain
import { Person } from '../../domain/entities';
import { PersonRepository } from '../../domain/repositories';
import { StateManager } from '../../domain/state';
import Query from '@/modules/shared/domain/use-cases/query';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class GetCustomerPersonQuery extends Query<Promise<Person>> {
  @Inject(TYPES.CUSTOMER_PERSON_REPOSITORY)
  private readonly person_repository!: PersonRepository;

  @Inject(TYPES.CUSTOMER_PERSON_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  internalExecute(): Promise<Person> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded) {
        resolve(this.state_manager.state.person);
      } else {
        this.person_repository.get()
          .then((person) => {
            this.state_manager.patch({ person, loaded: true });
            resolve(person);
          })
          .catch(reject);
      }
    });
  }
}
