import Vue from 'vue';

// Domain
import { WealthFlexibleDepositStateManager } from '../../domain/state/wealth-flexible-deposit-state-manager';
import { WealthFlexibleDepositState } from '../../domain/state/wealth-flexible-deposit-state';
import Injectable from '@/modules/shared/domain/di/injectable';
import Observer from '@/modules/shared/domain/observer/observer';

@Injectable()
export default class WealthFlexibleDepositVueStateManager
implements WealthFlexibleDepositStateManager {
  private _state: WealthFlexibleDepositState = Vue.observable<WealthFlexibleDepositState>(
    new WealthFlexibleDepositState(),
  );

  private readonly observers: Observer[] = [];

  get state(): WealthFlexibleDepositState {
    return this._state;
  }

  set state(value: WealthFlexibleDepositState) {
    this._state = value;
    this.notifyAll();
  }

  patch(state: Partial<WealthFlexibleDepositState>) {
    type Keys = keyof WealthFlexibleDepositState;
    Object.entries(state).forEach(([key, value]) => {
      const accessor = key as Keys;

      if (value !== undefined) {
        // eslint-disable-next-line
        // @ts-ignore @typescript-eslint/ban-ts-ignore
        this.state[accessor] = value;
      }
    });
  }

  notifyAll() {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer) {
    this.observers.push(observer);
  }

  restore() {
    this._state.deposit = {
      source_account: '',
      destination_account: {
        id: '',
        customer_id: '',
        allianz_account_id: '',
        transaction_id: '',
        request_number: '',
        product: '',
        policy_number: 0,
        client_code: '',
        client_name: '',
        activation_date: '',
        beginning_of_validity_date: '',
        agent_office: 0,
        agent_code: '',
        agent_name: '',
        promoter_code: '',
        promoter_name: '',
        grouper_code: '',
        grouper_name: '',
        advisor_code: '',
        advisor_name: '',
        item: '',
        found_code: '',
        found_name: '',
        amount_contributed: 0,
        withdrawal_amount: 0,
        charge_amount: 0,
        units: 0,
        units_value: 0,
        final_balance: 0,
        final_balance_mxn: 0,
        last_payment_date: '',
        updated_at: '',
        created_at: '',
      },
      amount: '',
    };

    this._state.goal_distribution = {
      total_contribution: 0,
      unassigned_balance: 0,
      is_deposit: true,
      investor_goal_scheduled_distributions: [],
    };

    this._state.accreditation_date = '';
  }
}
