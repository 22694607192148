// Domain
import {
  UpdateOnBoardingStepRepository,
} from '../../../../domain/repositories';
import { OnBoardingStepEntity } from '../../../../domain/entities/on-boarding-step-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class UpdateOnBoardingStepDummyRepository
implements UpdateOnBoardingStepRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(on_boarding_step: OnBoardingStepEntity): Promise<void> {
    const on_boarding_steps_data = JSON.parse(sessionStorage.getItem('steps_data')!);
    const on_boarding_step_index = on_boarding_steps_data.findIndex(
      (item: OnBoardingStepEntity) => item.id === on_boarding_step.id,
    );
    on_boarding_steps_data[on_boarding_step_index] = on_boarding_step;
    sessionStorage.setItem(
      'steps_data', JSON.stringify(on_boarding_steps_data),
    );

    return Promise.resolve();
  }
}
