import REGISTER_TYPES from '@/modules/register/types';
import ON_BOARDING_TYPES from '@/modules/on-boarding/types';
import AUTHENTICATION_TYPES from '@/modules/authentication/types';
import SHARED_TYPES from '@/modules/shared/types';
import AGREEMENTS_TYPES from '@/modules/agreements/types';
import INTERNET_STATUS_TYPES from '@/modules/internet-status/types';
import CURRENT_USER_TYPES from '@/modules/current-user/types';
import MY_INVESTMENT_TYPES from '@/modules/my-investment/types';
import CUSTOMER_TYPES from '@/modules/customer/types';
import CALENDLY_TYPES from '@/modules/calendly/types';
import USER_TYPES from '@/modules/user/types';
import ZENDESK_TYPES from '@/modules/zendesk/types';
import FLAGSHIP_TYPES from '@/modules/flagship/types';
import HOME_DASHBOARD_TYPES from '@/modules/home-dashboard/types';
import INVESTMENT_SUGGESTION_TYPES from '@/modules/investment-suggestion/types';

export default {
  ...REGISTER_TYPES,
  ...ON_BOARDING_TYPES,
  ...AUTHENTICATION_TYPES,
  ...SHARED_TYPES,
  ...AGREEMENTS_TYPES,
  ...INTERNET_STATUS_TYPES,
  ...CURRENT_USER_TYPES,
  ...MY_INVESTMENT_TYPES,
  ...CUSTOMER_TYPES,
  ...CALENDLY_TYPES,
  ...USER_TYPES,
  ...ZENDESK_TYPES,
  ...FLAGSHIP_TYPES,
  ...HOME_DASHBOARD_TYPES,
  ...INVESTMENT_SUGGESTION_TYPES,
  VUE: Symbol.for('VUE'),
  APPLICATION: Symbol.for('APPLICATION'),
  WINDOW: Symbol.for('WINDOW'),
};
