




































































import { Component, Vue } from 'vue-property-decorator';
import ForgotPasswordViewModel from '@/vue-app/view-models/views/forgot-password-view-model';

@Component({ name: 'ForgotPassword' })
export default class ForgotPassword extends Vue {
  forgot_password_view_model = Vue.observable(new ForgotPasswordViewModel());
}

