import TYPES from '../../types';

// Domain
import { PositionDto } from '../../domain/dtos/position-dto';
import {
  CustomerKuspitPositionRepository,
} from '../../domain/repository/customer-kuspit-position-repository';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetCustomerKuspitPositionQuery extends Query<Promise<PositionDto>> {
  @Inject(TYPES.CUSTOMER_KUSPIT_POSITION_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.CUSTOMER_KUSPIT_POSITION_REPOSITORY)
  private readonly repository!: CustomerKuspitPositionRepository;

  internalExecute(): Promise<PositionDto> {
    return new Promise((resolve, reject) => {
      if (!this.state_manager.state.position_loaded) {
        this.state_manager.patch({ is_loading: true });
        this.repository.execute()
          .then((position) => {
            this.state_manager.patch({ position, position_loaded: true });
            resolve(position);
          })
          .catch(reject)
          .finally(() => {
            this.state_manager.patch({ is_loading: false });
          });
      } else {
        resolve(this.state_manager.state.position);
      }
    });
  }
}
