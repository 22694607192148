import { Container } from 'inversify-props';
import TYPES from './types';
import VerifyNipDummyRepository
  from './infrastructure/repositories/verify-nip-dummy-repository';
import VerifyNipHttpRepository
  from './infrastructure/repositories/verify-nip-http-repository';
import VerifyOnBoardingNipCommand
  from './application/commands/verify-on-boarding-nip-command';
import VerifyNipRepository from './domain/repositories/verify-nip-repository';

export default function setupVerifyNip(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<VerifyNipRepository>(TYPES.ON_BOARDING_VERIFY_NIP_REPOSITORY)
    .to(
      withDummies
        ? VerifyNipDummyRepository
        : VerifyNipHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<VerifyOnBoardingNipCommand>(TYPES.VERIFY_ON_BOARDING_NIP_COMMAND)
    .to(VerifyOnBoardingNipCommand)
    .inSingletonScope();
}
