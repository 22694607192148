import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';
import RenewVerifyNipRepository from '../../domain/repositories/renew-verify-nip-repository';
import { RenewOnBoardingVerifyNipDto } from '@/modules/on-boarding/renew-verify-nip/domain/dtos/renew-on-boarding-verify-nip-dto';

@Injectable()
export default class RenewOnBoardingVerifyNipCommand extends Command<RenewOnBoardingVerifyNipDto> {
  @Inject(TYPES.ON_BOARDING_RENEW_VERIFY_NIP_REPOSITORY)
  private readonly repository!: RenewVerifyNipRepository;

  internalExecute(payload: RenewOnBoardingVerifyNipDto): Promise<void> {
    return this.repository.execute(payload);
  }
}
