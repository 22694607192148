import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import GetPersonDummyRepository
  from './infrastructure/repository/get-person-repository/get-person-dummy-repository';
import GetPersonHttpRepository
  from './infrastructure/repository/get-person-repository/get-person-http-repository';
import UpdatePersonDummyRepository
  from './infrastructure/repository/update-person-repository/update-person-dummy-repository';
import UpdatePersonHttpRepository
  from './infrastructure/repository/update-person-repository/update-person-http-repository';
import VueStateManager from './infrastructure/state/vue-state-manager';

// Application
import GetMyInvestmentPersonQuery from './application/queries/get-my-investment-person-query';
import GetPersonAsyncQuery from './application/queries/get-person-async-query';
import UpdateMyInvestmentPersonCommand from './application/command/update-my-investment-person-command';

// Domain
import { GetPersonRepository } from './domain/repository/get-person-repository';
import { UpdatePersonRepository } from './domain/repository/update-person-repository';
import { StateManager } from './domain/state/state-manager';

export default function setupPersonModule(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.INVESTMENT_PERSON_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();
  container
    .bind<GetPersonRepository>(TYPES.GET_INVESTMENT_PERSON_REPOSITORY)
    .to(
      withDummies
        ? GetPersonDummyRepository
        : GetPersonHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetMyInvestmentPersonQuery>(TYPES.GET_INVESTMENT_PERSON_QUERY)
    .to(GetMyInvestmentPersonQuery)
    .inSingletonScope();

  container
    .bind<GetPersonAsyncQuery>(TYPES.GET_PERSON_ASYNC_QUERY)
    .to(GetPersonAsyncQuery)
    .inSingletonScope();

  container
    .bind<UpdatePersonRepository>(TYPES.UPDATE_INVESTMENT_PERSON_REPOSITORY)
    .to(
      withDummies
        ? UpdatePersonDummyRepository
        : UpdatePersonHttpRepository,
    ).inSingletonScope();

  container
    .bind<UpdateMyInvestmentPersonCommand>(TYPES.UPDATE_INVESTMENT_PERSON_COMMAND)
    .to(UpdateMyInvestmentPersonCommand)
    .inSingletonScope();
}
