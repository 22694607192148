import i18n from '@/vue-app/plugins/i18n';
import TYPES from '@/types';

// Application
import GetPersonQueryService
  from '@/modules/my-investment/person/application/queries/get-my-investment-person-query';
import { GetFundingDetailsQuery } from '@/modules/my-investment/kuspit/funding-details/application/queries';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class DepositViewModel {
  @Inject(TYPES.GET_INVESTMENT_PERSON_QUERY)
  private readonly get_person_query!: GetPersonQueryService;

  @Inject(TYPES.GET_KUSPIT_FUNDING_DETAILS_QUERY)
  private readonly get_funding_details_query!: GetFundingDetailsQuery;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  readonly i18n_namespace = 'components.kuspit-dashboard.deposits.deposit_account_info';

  bank_name= '';

  account_number = '';

  loadFundingDetails = async () => {
    try {
      const funding_details = await this.get_funding_details_query.execute();
      this.bank_name = funding_details.bank;
      this.account_number = funding_details.kuspit_clabe;
    } catch (e) {
      this.messageNotifier.showErrorNotification(
        `${i18n.t(`${this.i18n_namespace}.error_on_load_funding_details`)}`,
      );
    }
  }

  initialize = async () => {
    await this.loadFundingDetails();
  }
}
