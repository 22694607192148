import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import RetirementGoalMonthlyPaymentCalculationDummyRepository from '@/modules/flagship/retirement-goal-calculations/infrastructure/repositories/retirement-goal-monthly-payment-calculation-dummy-repository';
import RetirementGoalMonthlyPaymentCalculationHttpRepository from '@/modules/flagship/retirement-goal-calculations/infrastructure/repositories/retirement-goal-monthly-payment-calculation-http-repository';
import RetirementGoalMonthlyPensionAdjustedCalculationDummyRepository from '@/modules/flagship/retirement-goal-calculations/infrastructure/repositories/retirement-goal-monthly-pension-adjusted-calculation-dummy-repository';
import RetirementGoalMonthlyPensionAdjustedCalculationHttpRepository from '@/modules/flagship/retirement-goal-calculations/infrastructure/repositories/retirement-goal-monthly-pension-adjusted-calculation-http-repository';

// Application
import CalculateRetirementGoalMonthlyPaymentCalculationQuery from '@/modules/flagship/retirement-goal-calculations/application/queries/calculate-retirement-goal-monthly-payment-calculation-query';
import CalculateRetirementGoalMonthlyPensionAdjustedCalculationQuery from '@/modules/flagship/retirement-goal-calculations/application/queries/calculate-retirement-goal-monthly-pension-adjusted-calculation-query';

// Domain
import { RetirementGoalMonthlyPaymentCalculationRepository } from '@/modules/flagship/retirement-goal-calculations/domain/repositories/retirement-goal-monthly-payment-calculation-repository';
import { RetirementGoalMonthlyPensionAdjustedCalculationRepository } from '@/modules/flagship/retirement-goal-calculations/domain/repositories/retirement-goal-monthly-pension-adjusted-calculation-repository';

export default function setupRetirementGoalCalculations(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<RetirementGoalMonthlyPaymentCalculationRepository>(
      TYPES.RETIREMENT_GOAL_MONTHLY_PAYMENT_CALCULATION_REPOSITORY,
    )
    .to(
      withDummies
        ? RetirementGoalMonthlyPaymentCalculationDummyRepository
        : RetirementGoalMonthlyPaymentCalculationHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<CalculateRetirementGoalMonthlyPaymentCalculationQuery>(
      TYPES.CALCULATE_RETIREMENT_GOAL_MONTHLY_PAYMENT_CALCULATION_QUERY,
    )
    .to(CalculateRetirementGoalMonthlyPaymentCalculationQuery)
    .inSingletonScope();

  container
    .bind<RetirementGoalMonthlyPensionAdjustedCalculationRepository>(
      TYPES.RETIREMENT_GOAL_MONTHLY_PENSION_ADJUSTED_CALCULATION_REPOSITORY,
    )
    .to(
      withDummies
        ? RetirementGoalMonthlyPensionAdjustedCalculationDummyRepository
        : RetirementGoalMonthlyPensionAdjustedCalculationHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<CalculateRetirementGoalMonthlyPensionAdjustedCalculationQuery>(
      TYPES.CALCULATE_RETIREMENT_GOAL_MONTHLY_PENSION_ADJUSTED_CALCULATION_QUERY,
    )
    .to(CalculateRetirementGoalMonthlyPensionAdjustedCalculationQuery)
    .inSingletonScope();
}
