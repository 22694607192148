// Infrastructure
import fixtures from './fixtures.json';

// Domain
import {
  InvestorProfileRepository,
} from '@/modules/flagship/investor-profile/investor-profile/domain/repositories/investor-profile-repository';
import {
  InvestorProfileEntity,
} from '@/modules/flagship/investor-profile/investor-profile/domain/entities/investor-profile-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class InvestorProfileDummyRepository
implements InvestorProfileRepository {
  get(): Promise<InvestorProfileEntity> {
    return Promise.resolve(fixtures);
  }

  update(): Promise<any> {
    return Promise.resolve();
  }
}
