// Domain
import {
  ModerateIsContractedEntity,
} from '@/modules/flagship/customer-investment-product-fund-type/domain/entities/moderate-is-contracted-entity';

export default class ModerateIsContractedState {
  loaded = false;

  moderate_is_contracted: ModerateIsContractedEntity = {
    customer_investment_product_fund_type_id: '',
    moderate_is_contracted: false,
    status: '',
    opening_goal_id: '',
    opening_goal_name: '',
  };
}
