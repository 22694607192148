import userProfilePersonalInformationTab from './user-profile-personal-information-tab';
import userProfileSecurityTab from './user-profile-security-tab.json';
import userProfilePromotionalCodeTab from './user-profile-promotional-code-tab.json';
import userProfilePersonalityTab from './user-profile-personality-tab.json';
import userProfileDeleteAccountTab from './user-profile-delete-account-tab.json';
import userProfileDeleteAccountDialog from './user-profile-delete-account-dialog.json';
import userProfileDeleteAccount from './user-profile-delete-account';

export default {
  'user-profile-tabs': {
    ...userProfilePersonalInformationTab,
    ...userProfileDeleteAccount,
    'user-profile-security-tab': userProfileSecurityTab,
    'user-profile-promotional-code-tab': userProfilePromotionalCodeTab,
    'user-profile-personality-tab': userProfilePersonalityTab,
    'user-profile-delete-account-tab': userProfileDeleteAccountTab,
    'user-profile-delete-account-dialog': userProfileDeleteAccountDialog,
  },
};
