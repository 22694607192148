import Injectable from '@/modules/shared/domain/di/injectable';
import { ActivateRecurringContributionRepository }
  from '../../../domain/repository/activate-recurring-contribution-repository';

@Injectable()
export default class ActivateRecurringContributionDummyRepository
implements ActivateRecurringContributionRepository {
  activate(): Promise<void> {
    return Promise.resolve();
  }
}
