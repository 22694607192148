// Domain
import {
  ProductInformationRepository,
} from '@/modules/flagship/investment-product/product-information/domain/repositories/product-information-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class ProductInformationDummyRepository
implements ProductInformationRepository {
  create(): Promise<void> {
    return Promise.resolve();
  }
}
