import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import { OriginHttpRepository, OriginDummyRepository } from './infrastructure/repository';

// Application
import { SearchOriginsQuery } from './application/queries';

// Domain
import { OriginRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupOrigin(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.CATALOG_KUSPIT_ORIGIN_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<OriginRepository>(TYPES.CATALOG_KUSPIT_ORIGIN_REPOSITORY)
    .to(
      withDummies
        ? OriginDummyRepository
        : OriginHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchOriginsQuery>(TYPES.SEARCH_CATALOG_KUSPIT_ORIGIN_QUERY)
    .to(SearchOriginsQuery)
    .inSingletonScope();
}
