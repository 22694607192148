import TYPES from '../../types';

// Domain
import { PeriodicityOfContributionEntity } from '../../domain/entities/periodicity-of-contribution-entity';
import { PeriodicityOfContributionRepository } from '../../domain/repository/periodicity-of-contribution-repository';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class SearchPeriodicityOfContributionsQuery
  extends Query<Promise<Array<PeriodicityOfContributionEntity>>> {
  @Inject(TYPES.CATALOG_ALLIANZ_PERIODICITY_OF_CONTRIBUTION_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.CATALOG_ALLIANZ_PERIODICITY_OF_CONTRIBUTION_REPOSITORY)
  private readonly repository!: PeriodicityOfContributionRepository;

  internalExecute(): Promise<Array<PeriodicityOfContributionEntity>> {
    return new Promise((resolve, reject) => {
      if (!this.state_manager.state.loaded) {
        this.repository.execute()
          .then((items) => {
            this.state_manager.patch({ items, loaded: true });
            resolve(items);
          })
          .catch(reject);
      } else {
        resolve(this.state_manager.state.items);
      }
    });
  }
}
