// Domain
import {
  InvestorProfileOnFinancialHabitsRepository,
} from '@/modules/my-investment/allianz/access-more-strategies/domain/repositories/investor-profile-on-financial-habits-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class InvestorProfileOnFinancialHabitsDummyRepository
implements InvestorProfileOnFinancialHabitsRepository {
  update(): Promise<void> {
    return Promise.resolve();
  }
}
