import TYPES from '../../types';

// Domain
import {
  InvestorProfileDto,
} from '@/modules/flagship/investor-profile/investor-profile/domain/dtos/investor-profile-dto';
import {
  InvestorProfileRepository,
} from '@/modules/flagship/investor-profile/investor-profile/domain/repositories/investor-profile-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UpdateInvestorProfileCommand extends Command<InvestorProfileDto> {
  @Inject(TYPES.INVESTOR_PROFILE_REPOSITORY)
  private readonly repository!: InvestorProfileRepository;

  internalExecute(investor_profile_dto: InvestorProfileDto) {
    return this.repository.update(investor_profile_dto);
  }
}
