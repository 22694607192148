import { Container } from 'inversify-props';
import TYPES from './types';

// INFRASTRUCTURE
import VueStateManager from './infrastructure/state/vue-state-manager';
import { CreateKuspitWithdrawalDummyRepository }
  from './infrastructure/repositories/create-kuspit-withdrawal-dummy-repository';
import CreateKuspitWithdrawalHttpRepository
  from './infrastructure/repositories/create-kuspit-withdrawal-http-repository';
import GetCustomerWithdrawalDetailsDummyRepository
  from '@/modules/my-investment/kuspit/withdrawal/infrastructure/repositories/get-customer-withdrawal-details-dummy-repository';
import GetCustomerWithdrawalDetailsHttpRepository
  from '@/modules/my-investment/kuspit/withdrawal/infrastructure/repositories/get-customer-withdrawal-details-http-repository';

// APPLICATION
import CreateKuspitWithdrawalCommand
  from './application/commands/create-kuspit-withdrawal-command';
import {
  GetCustomerWithdrawalDetailsQuery,
} from '@/modules/my-investment/kuspit/withdrawal/application/queries';

// DOMAIN
import { CreateKuspitWithdrawalRepository } from './domain/repository/create-kuspit-withdrawal-repository';
import {
  StateManager,
} from './domain/state/state_manager';
import {
  GetCustomerWithdrawalDetailsRepository,
} from '@/modules/my-investment/kuspit/withdrawal/domain/repository';

export default function setupKuspitWithdrawals(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.CUSTOMER_WITHDRAWAL_DETAILS_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<GetCustomerWithdrawalDetailsRepository>(TYPES.CUSTOMER_WITHDRAWAL_DETAILS_REPOSITORY)
    .to(
      withDummies
        ? GetCustomerWithdrawalDetailsDummyRepository
        : GetCustomerWithdrawalDetailsHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<GetCustomerWithdrawalDetailsQuery>(TYPES.GET_CUSTOMER_WITHDRAWAL_DETAILS_QUERY)
    .to(GetCustomerWithdrawalDetailsQuery)
    .inSingletonScope();

  container
    .bind<CreateKuspitWithdrawalRepository>(TYPES.CREATE_KUSPIT_WITHDRAWAL_REPOSITORY)
    .to(
      withDummies
        ? CreateKuspitWithdrawalDummyRepository
        : CreateKuspitWithdrawalHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<CreateKuspitWithdrawalCommand>(TYPES.CREATE_KUSPIT_WITHDRAWAL_COMMAND)
    .to(CreateKuspitWithdrawalCommand)
    .inSingletonScope();
}
