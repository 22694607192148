import flagshipGoals from './flagship-goals';
import flagshipProfiling from './flagship-profiling';
import flagshipLinkGoal from './flagship-link-goals';
import distributeSpecialContributionAmongLinkedGoals from './distribute-special-contribution-among-linked-goals-wealth.json';
import modalDistributeSpecialContributionSuccessWealth from './modal-distribute-special-contribution-success-wealth.json';
import distributeDifferentContributionsAmongLinkedGoals from './distribute-different-contributions-among-linked-goals-pocket.json';
import modalDistributeDifferentContributionsSuccessWealth from './modal-distribute-different-contributions-success-pocket.json';

export default {
  flagship: {
    ...flagshipGoals,
    ...flagshipProfiling,
    ...flagshipLinkGoal,
    ...distributeSpecialContributionAmongLinkedGoals,
    ...modalDistributeSpecialContributionSuccessWealth,
    ...distributeDifferentContributionsAmongLinkedGoals,
    ...modalDistributeDifferentContributionsSuccessWealth,
  },
};
