// Domain
import {
  IncompleteProfileEntity,
} from '@/modules/flagship/investor-profile/incomplete-profile/domain/entities/incomplete-profile-entity';

export default class IncompleteProfileState {
  loaded = false;

  incomplete_profile: IncompleteProfileEntity = {
    customer_id: '',
    is_incomplete: false,
  };
}
