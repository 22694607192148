














































































import { Component, Vue } from 'vue-property-decorator';
import AttachedNewProofOfAddressViewModel
  from '@/vue-app/view-models/views/document-validation/attached-new-proof-of-address-view-model';

@Component({ name: 'AttachedNewProofOfAddress' })
export default class AttachedNewProofOfAddress extends Vue {
  attached_proof_address_view_model = Vue.observable(new AttachedNewProofOfAddressViewModel());

  async mounted() {
    if (this.$route?.query?.token) {
      await this.attached_proof_address_view_model
        .initialize(this.$route?.query?.token.toString());
    } else {
      this.attached_proof_address_view_model.goToLoginPage();
    }
  }

  destroyed() {
    this.attached_proof_address_view_model.deleteTokenFromStorage();
  }
}

