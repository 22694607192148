import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';
import { IdentityVerificationStatusEntity }
  from '../../domain/entities/identity-verification-status-entity';
import { StateManager } from '../../domain/state-manager';

@Injectable()
class GetOnBoardingIdentityVerificationStatusQuery extends Query<IdentityVerificationStatusEntity> {
  @Inject(TYPES.ON_BOARDING_IDENTITY_VERIFICATION_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  internalExecute(): IdentityVerificationStatusEntity {
    return this.state_manager.state.status;
  }
}

export default GetOnBoardingIdentityVerificationStatusQuery;
