import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import AllianzFundInMemoryRepository from './infrastructure/repositories/allianz-fund-in-memory-repository';

// Application
import GetAllianzFundQuery from './application/queries/get-allianz-fund-query';

// Domain
import { AllianzFundRepository } from './domain/repository';

export default function setupAllianzFund(container: Container) {
  container
    .bind<AllianzFundRepository>(TYPES.ALLIANZ_FUND_REPOSITORY)
    .to(AllianzFundInMemoryRepository)
    .inSingletonScope();

  container
    .bind<GetAllianzFundQuery>(TYPES.GET_ALLIANZ_FUND_QUERY)
    .to(GetAllianzFundQuery)
    .inSingletonScope();
}
