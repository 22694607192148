import UnautheneticatedLayout from '@/vue-app/layouts/UnauthenticatedLayout.vue';
import AttachedNewProofOfAddress from '@/vue-app/views/document-validation/AttachedNewProofOfAddress.vue';
import POLICIES from '../security/policies';

export default {
  path: '/attached-new-proof-of-address',
  component: UnautheneticatedLayout,
  children: [
    {
      path: '/',
      name: 'AttachedNewProofOfAddress',
      component: AttachedNewProofOfAddress,
      meta: {
        access_policy: POLICIES.UNAUTHENTICATED,
      },
    },
  ],
};
