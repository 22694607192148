import progressCard from '@/locales/es/components/goals-dashboard/progress-card.json';
import emergencyFundCard from '@/locales/es/components/goals-dashboard/emergency-fund-card.json';
import retirementFundCard from '@/locales/es/components/goals-dashboard/retirement-fund-card.json';
import addCustomInvestorGoalCard from '@/locales/es/components/goals-dashboard/add-custom-investor-goal-card.json';
import customInvestorGoalCard from '@/locales/es/components/goals-dashboard/custom-investor-goal-card.json';
import linkedEmergencyFundCard from '@/locales/es/components/goals-dashboard/linked-emergency-fund-card.json';
import linkedRetirementFundCard from '@/locales/es/components/goals-dashboard/linked-retirement-fund-card.json';
import linkedCustomInvestorGoalCard from '@/locales/es/components/goals-dashboard/linked-custom-investor-goal-card.json';
import editNameCustomizedGoal from '@/locales/es/components/goals-dashboard/edit-goals/edit-name-customized-goal.json';
import distributeUnassignedAmountCard from './distribute-unassigned-amount-card.json';
import editUploadedImageModal from '@/locales/es/components/goals-dashboard/edit-goals/edit-uploaded-image-modal.json';
import chooseActionEditGoalDialog from '@/locales/es/components/goals-dashboard/edit-goals/choose-action-edit-goal-dialog.json';
import addCustomInvestorGoal from './add-goals';
import deleteCustomInvestorGoal from './delete-goals';
import detailsGoals from './details-goals';
import editPlanGoals from './edit-plan-goals';
import goalTrackingTag from './goal-tracking-tag.json';
import contributeGoals from './contribute-goals';

export default {
  'goals-dashboard': {
    title: 'Principal > Mis metas',
    back: 'Regresar',
    my_goals: 'Mis Metas',
    unlinked_goals: 'Metas sin vincular:',
    linked_goals: 'Metas vinculadas:',
    alt_img: 'Imagen de una persona sosteniendo una bandera que simboliza lograr las metas de ahorro',
    'progress-card': progressCard,
    'distribute-unassigned-amount-card': distributeUnassignedAmountCard,
    'emergency-fund-card': emergencyFundCard,
    'retirement-fund-card': retirementFundCard,
    'add-custom-investor-goal-card': addCustomInvestorGoalCard,
    'custom-investor-goal-card': customInvestorGoalCard,
    'linked-emergency-fund-card': linkedEmergencyFundCard,
    'linked-retirement-fund-card': linkedRetirementFundCard,
    'linked-custom-investor-goal-card': linkedCustomInvestorGoalCard,
    'edit-name-customized-goal': editNameCustomizedGoal,
    'edit-uploaded-image-modal': editUploadedImageModal,
    'choose-action-edit-goal-dialog': chooseActionEditGoalDialog,
    ...addCustomInvestorGoal,
    ...deleteCustomInvestorGoal,
    ...detailsGoals,
    ...editPlanGoals,
    errors: {
      obtain_investor_profile: 'Ha ocurrido un error al obtener la información del perfil inversor.',
      load_custom_investor_goal_information: 'Ocurrió un error al cargar la información de su meta personalizada',
      load_emergency_fund_information: 'Ocurrió un error al cargar la información de su meta fondo de emergencia',
      load_retirement_investor_goal_information: 'Ocurrió un error al cargar la información de su meta para el retiro',
      load_customer_investor_goal_balance: 'Ocurrió un error al obtener su saldo sin asignar a ninguna meta',
      load_all_custom_investor_goal_information: 'Ocurrió un error al obtener la información de las metas personalizadas.',
      load_customer_contracted_products: 'Ha ocurrido un error al obtener el estatus de los productos contratados.',
      obtain_overall_goals: 'Ha ocurrido un error al obtener las metas activas e inactivas',
    },
    ...goalTrackingTag,
    ...contributeGoals,
  },
};
