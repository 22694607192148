// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { AllianzAccountReportRepository } from '../../../domain/repository';
import { AllianzAccountReportEntity } from '../../../domain/entities/allianz-account-report-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class AllianzAccountReportDummyRepository implements AllianzAccountReportRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(): Promise<Array<AllianzAccountReportEntity>> {
    return Promise.resolve(fixtures);
  }
}
