import Vue from 'vue';

// Domain
import Injectable from '@/modules/shared/domain/di/injectable';
import Observer from '@/modules/shared/domain/observer/observer';
import {
  EmergencyFundInvestorGoalStateManager,
} from '@/modules/flagship/emergency-fund-investor-goal/domain/state/emergency-fund-investor-goal-state-manager';
import EmergencyFundInvestorGoalState
  from '@/modules/flagship/emergency-fund-investor-goal/domain/state/emergency-fund-investor-goal-state';

@Injectable()
class EmergencyFundInvestorGoalVueStateManager implements EmergencyFundInvestorGoalStateManager {
  private _state: EmergencyFundInvestorGoalState = Vue.observable<EmergencyFundInvestorGoalState>(
    new EmergencyFundInvestorGoalState(),
  );

  private readonly observers: Observer[] = [];

  get state(): EmergencyFundInvestorGoalState {
    return this._state;
  }

  set state(value: EmergencyFundInvestorGoalState) {
    this._state = value;
  }

  patch(state: Partial<EmergencyFundInvestorGoalState>) {
    type Keys = keyof EmergencyFundInvestorGoalState;
    Object.entries(state).forEach(([key, value]) => {
      const accessor = key as Keys;

      if (value !== undefined) {
        // eslint-disable-next-line
        // @ts-ignore @typescript-eslint/ban-ts-ignore
        this.state[accessor] = value;
      }
    });
  }

  notifyAll() {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer) {
    this.observers.push(observer);
  }
}

export default EmergencyFundInvestorGoalVueStateManager;
