import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Subscription from '@/modules/shared/domain/use-cases/subscription';
import { StateManager } from '../../domain/state/state-manager';

@Injectable()
export default class InternetStatusSubscription extends Subscription {
  @Inject(TYPES.INTERNET_STATUS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  setInternetStatus() {
    this.state_manager.patch({ online: navigator.onLine });
  }

  internalExecute() {
    window.addEventListener('online', this.setInternetStatus.bind(this));
    window.addEventListener('offline', this.setInternetStatus.bind(this));
  }

  stop() {
    window.removeEventListener('online', this.setInternetStatus.bind(this));
    window.removeEventListener('offline', this.setInternetStatus.bind(this));
  }
}
