import Vue from 'vue';
import TYPES from '@/types';
import { requiredRule, RuleResponseType } from '@/vue-app/utils/form-rules';
import { v4 } from 'uuid';

// Application
import OnBoardingSearchEmailsQuery from '@/modules/on-boarding/emails/application/queries/on-boarding-search-emails-query';
import GetInternetStatusService from '@/modules/internet-status/application/services/get-internet-status-service';
import CreateOnBoardingStepCommand from '@/modules/on-boarding/steps/application/commands/create-on-boarding-step-command';
import VerifyOnBoardingNipCommand from '@/modules/on-boarding/verify-nip/application/commands/verify-on-boarding-nip-command';
import RenewOnBoardingVerifyNipCommand from '@/modules/on-boarding/renew-verify-nip/application/commands/renew-on-boarding-verify-nip-command';

// Domain
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { Values } from '@/modules/shared/domain/i18n/types';
import Translator from '@/modules/shared/domain/i18n/translator';
import Inject from '@/modules/shared/domain/di/inject';

export default class VerifyNipViewModel {
  @Inject(TYPES.ON_BOARDING_SEARCH_EMAILS_QUERY)
  readonly onBoardingSearchEmailsQuery!: OnBoardingSearchEmailsQuery;

  @Inject(TYPES.CREATE_ON_BOARDING_STEP_COMMAND)
  readonly createOnBoardingStepCommand!: CreateOnBoardingStepCommand;

  @Inject(TYPES.VERIFY_ON_BOARDING_NIP_COMMAND)
  readonly verifyOnBoardingNipCommand!: VerifyOnBoardingNipCommand;

  @Inject(TYPES.RENEW_ON_BOARDING_VERIFY_NIP_COMMAND)
  readonly renewOnBoardingVerifyNipCommand!: RenewOnBoardingVerifyNipCommand;

  @Inject(TYPES.GET_INTERNET_STATUS_SERVICE)
  readonly getInternetStatusService!: GetInternetStatusService;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'sign-up.verify-identity.verify-nip';

  readonly view: Vue;

  constructor(view: Vue) {
    this.view = view;
  }

  nip = '';

  nip_rules = [
    requiredRule,
    (value: string): RuleResponseType => value.length === 7 || this.translate('errors.invalid_nip'),
  ];

  valid_nip = false;

  loading = false;

  is_nip_field_full = false;

  get internetStatus() {
    return this.getInternetStatusService.execute();
  }

  get canContinue() {
    return this.valid_nip
      && this.internetStatus
      && this.is_nip_field_full;
  }

  get email() {
    return this.onBoardingSearchEmailsQuery.execute().find(
      (email) => email.customer_email_default,
    ) || { email: { email_address: 'con el que ingresaste' } };
  }

  get is_resend_button_disabled() {
    return !this.internetStatus || this.loading;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  onNipOtpfinish = () => {
    this.is_nip_field_full = true;
  }

  resendNip = async () => {
    try {
      await this.renewOnBoardingVerifyNipCommand.execute({ id: v4() });
      this.message_notifier.showSuccessNotification(this.translate('successful-resend'));
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.invalid_nip'));
    }
  };

  handleSubmit = async () => {
    this.loading = true;

    try {
      await this.verifyOnBoardingNipCommand.execute({
        nip: this.nip.replace('-', ''),
      });
      await this.createOnBoardingStepCommand.execute({
        id: v4(),
        current_step: 'identity_verification',
        payload: {
          status: 'pending',
        },
      });
      this.view.$emit('nextStep');
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.invalid_nip'));
    } finally {
      this.loading = false;
    }
  }
}
