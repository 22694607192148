// Domain
import { OnBoardingStepEntity } from '../../../../domain/entities/on-boarding-step-entity';
import { SearchOnBoardingStepRepository } from '../../../../domain/repositories';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class SearchOnBoardingStepDummyRepository implements SearchOnBoardingStepRepository {
  execute(investment_provider_id: string): Promise<Array<OnBoardingStepEntity>> {
    const step_data = sessionStorage.getItem('steps_data');

    return Promise.resolve(step_data ? JSON.parse(step_data) : []);
  }
}
