import TYPES from '../../types';

// Domain
import {
  ProductInformationDto,
} from '@/modules/flagship/investment-product/product-information/domain/dtos/product-information-dto';
import {
  ProductInformationRepository,
} from '@/modules/flagship/investment-product/product-information/domain/repositories/product-information-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CreateProductInformationCommand
  extends Command<ProductInformationDto> {
  @Inject(TYPES.PRODUCT_INFORMATION_REPOSITORY)
  private readonly repository!: ProductInformationRepository;

  internalExecute(product_info_dto: ProductInformationDto): Promise<void> {
    return this.repository.create(product_info_dto);
  }
}
