import TYPES from '../../types';

// Domain
import { OverallInvestmentAmountEntity } from '@/modules/flagship/overall-investment-amount-calculation/domain/entities/overall-investment-amount-entity';
import { OverallInvestmentAmountRepository } from '@/modules/flagship/overall-investment-amount-calculation/domain/repositories/overall-investment-amount-repository';
import { OverallInvestmentAmountDto } from '@/modules/flagship/overall-investment-amount-calculation/domain/dto/overall-investment-amount-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
class CalculateOverallInvestmentAmountQuery extends Query<Promise<OverallInvestmentAmountEntity>> {
  @Inject(TYPES.OVERALL_INVESTMENT_AMOUNT_REPOSITORY)
  private readonly repository!: OverallInvestmentAmountRepository;

  internalExecute(payload: OverallInvestmentAmountDto): Promise<OverallInvestmentAmountEntity> {
    return this.repository.calculate(payload);
  }
}

export default CalculateOverallInvestmentAmountQuery;
