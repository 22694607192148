import { v4 as uuid } from 'uuid';

import TYPES from '../../types';

// Domain
import { OnBoardingStepEntity } from '../../domain/entities/on-boarding-step-entity';
import { StateManager } from '../../domain/state/state-manager';
import { CreateOnBoardingStepRepository } from '../../domain/repositories';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CreateOnBoardingStepCommand extends Command<OnBoardingStepEntity> {
  @Inject(TYPES.MY_INVESTMENT_ON_BOARDING_STEP_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  private readonly repository!: CreateOnBoardingStepRepository;

  internalExecute(on_boarding_step: OnBoardingStepEntity): Promise<void> {
    return new Promise((resolve, reject) => {
      const existent_on_boarding_step = this.state_manager.state.created_steps.find(
        (item) => item.current_step === on_boarding_step.current_step,
      );
      if (!existent_on_boarding_step) {
        const on_boarding_step_with_id = {
          ...on_boarding_step,
          id: on_boarding_step.id ? on_boarding_step.id : uuid(),
        };
        this.repository.execute(on_boarding_step_with_id)
          .then(() => {
            this.state_manager.patch({
              created_steps: [
                ...this.state_manager.state.created_steps, on_boarding_step_with_id,
              ],
            });
            resolve();
          });
      } else {
        reject(new Error('OnBoardingStep has already been created'));
      }
    });
  }
}
