import accountBalance from './account-balance.json';
import performance from './performance.json';
import movements from './movements.json';
import position from './position.json';
import deposits from './deposits';
import withdrawals from './withdrawals';
import goals from './goals';
import editReminder from './edit-reminder';

export default {
  'kuspit-dashboard': {
    title: 'Principal > Mi inversión > Kuspit Casa de Bolsa',
    back: 'Regresar',
    request_withdrawal: 'Solicitar Retiro',
    make_contribution: 'Depositar',
    my_balance: 'Saldo en cuenta',
    my_performance: 'Mis Rendimientos',
    my_movements: 'Mis movimientos',
    my_position: 'Composición de mi cartera',
    my_linked_goals: 'Mis Metas vinculadas',
    ...accountBalance,
    ...performance,
    ...movements,
    ...position,
    ...deposits,
    ...withdrawals,
    ...goals,
    ...editReminder,
  },
};
