import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';
import { SingleUseScheduleLinkEntity } from '../../domain/entities/single-use-schedule-link-entity';
import { CalendlyRepository } from '../../domain/repository/calendly-repository';

@Injectable()
export default class GetSingleUseScheduleLinkQuery
  extends Query<Promise<SingleUseScheduleLinkEntity>> {
  @Inject(TYPES.CALENDLY_REPOSITORY)
  private readonly repository!: CalendlyRepository;

  internalExecute(): Promise<SingleUseScheduleLinkEntity> {
    return this.repository.get();
  }
}
