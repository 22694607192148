// Domain
import { ValidateKuspitAccountRepository }
  from '@/modules/my-investment/kuspit/kuspit-account-linking/domain/repositories/validate-kuspit-account-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
class ValidateKuspitAccountDummyRepository implements ValidateKuspitAccountRepository {
  create(): Promise<void> {
    return Promise.resolve();
  }
}

export default ValidateKuspitAccountDummyRepository;
