import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';
import { CreateKuspitAccountStatusEntity }
  from '../../domain/entities/create-kuspit-account-status-entity';
import { StateManager } from '../../domain/state-manager';

@Injectable()
export default class GetCreateCustomerKuspitAccountStatusQuery
  extends Query<CreateKuspitAccountStatusEntity> {
  @Inject(TYPES.CUSTOMER_KUSPIT_ACCOUNT_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  internalExecute(): CreateKuspitAccountStatusEntity {
    return this.state_manager.state.status;
  }
}
