import TYPES from '@/modules/shared/types';

// Domain
import {
  ExitPollOptionsRepository,
} from '@/modules/flagship/catalogs/exit-poll-options/domain/repositories/exit-poll-options-repository';
import {
  ExitPollOptionsEntity,
} from '@/modules/flagship/catalogs/exit-poll-options/domain/entities/exit-poll-options-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class ExitPollOptionsHttpRepository implements ExitPollOptionsRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/catalogs/exit-poll-options/`;

  execute(): Promise<Array<ExitPollOptionsEntity>> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint)
        .then((response) => {
          response.json()
            .then(({ success, data, error }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(error));
              }
            });
        })
        .catch(reject);
    });
  }
}
