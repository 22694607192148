import { Container } from 'inversify';
import TYPES from './types';

// Infrastructure
import PreferredMeansOfContactHttpRepository
  from '@/modules/on-boarding/preferred-means-of-contact/infrastructure/repositories/preferred-means-of-contact-http-repository';
import PreferredMeansOfContactDummyRepository
  from '@/modules/on-boarding/preferred-means-of-contact/infrastructure/repositories/preferred-means-of-contact-dummy-repository';
// Application
import UpdatePreferredMeansOfContactCommand
  from '@/modules/on-boarding/preferred-means-of-contact/application/commands/update-preferred-means-of-contact-command';

// Domain
import {
  PreferredMeansOfContactRepository,
} from '@/modules/on-boarding/preferred-means-of-contact/domain/repositories/preferred-means-of-contact-repository';

export default function setupPreferredMeansOfContact(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<PreferredMeansOfContactRepository>(TYPES.PREFERRED_MEANS_OF_CONTACT_REPOSITORY)
    .to(
      withDummies
        ? PreferredMeansOfContactDummyRepository
        : PreferredMeansOfContactHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<UpdatePreferredMeansOfContactCommand>(TYPES.PREFERRED_MEANS_OF_CONTACT_COMMAND)
    .to(UpdatePreferredMeansOfContactCommand)
    .inSingletonScope();
}
