import { Container } from 'inversify-props';
import setupCustomerKuspitCredentials from './customer-kuspit-credentials/setup';
import setupCustomerKuspitAccount from './customer-kuspit-account/setup';
import setupCustomerKuspitPosition from './customer-kuspit-position/setup';
import setupCustomerKuspitMovement from './customer-kuspit-movement/setup';
import setupCustomerHasKuspitAccount from './customer-has-account/setup';
import setupKuspitWithdrawals from './withdrawal/setup';
import setupFundingDetails from './funding-details/setup';
import setupKuspitAccountLinking from '@/modules/my-investment/kuspit/kuspit-account-linking/setup';
import setupKuspitCustomerDocument from '@/modules/my-investment/kuspit/customer-document/setup';

export default function setupKuspit(container: Container) {
  setupCustomerKuspitCredentials(container);
  setupCustomerKuspitAccount(container);
  setupCustomerKuspitPosition(container);
  setupCustomerKuspitMovement(container);
  setupCustomerHasKuspitAccount(container);
  setupKuspitWithdrawals(container);
  setupFundingDetails(container);
  setupKuspitAccountLinking(container);
  setupKuspitCustomerDocument(container);
}
