





































































































































import {
  Vue, Component, PropSync,
} from 'vue-property-decorator';
import WithdrawalConfirmInformationViewModel
  from '@/vue-app/view-models/components/kuspit-dashboard/withdrawals/withdrawal-confirm-information-view-model';

@Component({ name: 'WithdrawalsConfirmInformation' })
export default class WithdrawalsConfirmInformation extends Vue {
  @PropSync('sourceAccount', { type: String, required: true })
  synced_source_account!: string;

  @PropSync('destinationAccount', { type: String, required: true })
  synced_destination_account!: string;

  @PropSync('amount', { type: String, required: true })
  synced_amount!: string;

  @PropSync('concept', { type: String, required: true })
  synced_concept!: string;

  withdrawal_confirm_view_model = Vue.observable(
    new WithdrawalConfirmInformationViewModel(),
  );

  edit() {
    this.$emit('prevStep');
  }

  cancel() {
    this.$emit('endProcess');
  }

  nextStep() {
    this.$emit('nextStep');
  }
}

