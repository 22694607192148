// Infrastructure
import fixtures_investment from './fixtures_investment.json';
import fixtures_transaction from './fixtures_transaction.json';

// Domain
import { TemplateQuestionnaireRepository } from '../../../domain/repository';
import {
  TemplateQuestionnaireEntity,
} from '../../../domain/entities/template-questionnaire-entity';
import { FilterDto } from '../../../domain/dtos/filter-dto';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class TemplateQuestionnaireDummyRepository
implements TemplateQuestionnaireRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(filter_questionnaire: FilterDto): Promise<Array<TemplateQuestionnaireEntity>> {
    return Promise.resolve(
      [...fixtures_transaction, ...fixtures_investment]
        .filter(
          (questionnaire) => questionnaire.tipoCuestionario
          === filter_questionnaire.tipoCuestionario,
        ),
    );
  }
}
