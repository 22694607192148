import router from '@/vue-app/router';

// Domain
import { Router } from '../../domain/router';
import { Route, NavigationRoute } from '../../domain/dtos';
import Injectable from '../../domain/di/injectable';

@Injectable()
export default class VueRouter implements Router {
  get current_path(): Route {
    const { fullPath, hash, path } = router.currentRoute;

    return {
      full_path: fullPath,
      path,
      hash,
    };
  }

  navigate(path: string | NavigationRoute) {
    router.push(path);
  }
}
