import Injectable from '@/modules/shared/domain/di/injectable';
import RegisterRepository from '../../../domain/repository/RegisterRepository';

@Injectable()
export default class MemoryRegisterRepository implements RegisterRepository {
  // eslint-disable-next-line class-methods-use-this
  async create() {
    return Promise.resolve();
  }
}
