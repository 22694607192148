import TYPES from '../../types';

// Domain
import { TokenRefresh } from '../../domain/dtos';
import { RefreshTokenEntity } from '../../domain/entities';
import { TokenRefreshRepository } from '../../domain/repositories';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class TokenRefreshCommand extends Command<TokenRefresh> {
  @Inject(TYPES.TOKEN_REFRESH_REPOSITORY)
  private readonly token_refresh_repository!: TokenRefreshRepository;

  internalExecute(payload: TokenRefresh): Promise<RefreshTokenEntity> {
    return this.token_refresh_repository.update(payload);
  }
}
