import { Container } from 'inversify-props';
import setupMagicLink from './magic-link/setup';
import setupWealth from './wealth/setup';
import setupPocket from './pocket/setup';

export default function setupCustomerDocument(container: Container) {
  setupMagicLink(container);
  setupWealth(container);
  setupPocket(container);
}
