import TYPES from '@/modules/shared/types';

// Domain
import {
  HabitsAvailableBudgetCalculationRepository,
} from '@/modules/flagship/habits-available-budget-calculation/domain/repositories/habits-available-budget-calculation-repository';
import {
  HabitsAvailableBudgetCalculationDto,
} from '@/modules/flagship/habits-available-budget-calculation/domain/dtos/habits-available-budget-calculation-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class HabitsAvailableBudgetCalculationHttpRepository
implements HabitsAvailableBudgetCalculationRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/habits-available-budget-calculation/`;

  create(available_budget_dto: HabitsAvailableBudgetCalculationDto): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint, 'POST', available_budget_dto)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
