import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import InvestorProfileOnEmploymentSituationDummyRepository
  from '@/modules/flagship/investor-profile/employment-situation/infraestructure/repositories/investor-profile-on-employment-situation-dummy-repository';
import InvestorProfileOnEmploymentSituationHttpRepository
  from '@/modules/flagship/investor-profile/employment-situation/infraestructure/repositories/investor-profile-on-employment-situation-http-repository';

// Application
import UpdateInvestorProfileOnEmploymentSituationCommand
  from '@/modules/flagship/investor-profile/employment-situation/application/commands/update-investor-profile-on-employment-situation-command';

// Domain
import {
  InvestorProfileOnEmploymentSituationRepository,
} from '@/modules/flagship/investor-profile/employment-situation/domain/repositories/investor-profile-on-employment-situation-repository';

export default function setupEmploymentSituationFlagship(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<InvestorProfileOnEmploymentSituationRepository>(
      TYPES.INVESTOR_PROFILE_ON_EMPLOYMENT_SITUATION_REPOSITORY,
    )
    .to(
      withDummies
        ? InvestorProfileOnEmploymentSituationDummyRepository
        : InvestorProfileOnEmploymentSituationHttpRepository,
    ).inSingletonScope();

  container
    .bind<UpdateInvestorProfileOnEmploymentSituationCommand>(
      TYPES.UPDATE_INVESTOR_PROFILE_ON_EMPLOYMENT_SITUATION_COMMAND,
    )
    .to(UpdateInvestorProfileOnEmploymentSituationCommand)
    .inSingletonScope();
}
