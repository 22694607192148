import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';
import VerifyNipRepository from '../../domain/repositories/verify-nip-repository';
import { NipDto } from '../../domain/dtos/nip-dto';

@Injectable()
export default class VerifyOnBoardingNipCommand extends Command<NipDto> {
  @Inject(TYPES.ON_BOARDING_VERIFY_NIP_REPOSITORY)
  private readonly repository!: VerifyNipRepository;

  internalExecute(nip_dto: NipDto) {
    return this.repository.create(nip_dto);
  }
}
