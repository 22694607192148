// Domain
import { CreateKuspitWithdrawalRepository } from '../../../domain/repository/create-kuspit-withdrawal-repository';
import { ResultKuspitWithdrawalEntity } from '../../../domain/entities/result-kuspit-withdrawal-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

import fixtures from './fixtures.json';

@Injectable()
export default class CreateKuspitWithdrawalDummyRepository
implements CreateKuspitWithdrawalRepository {
  // eslint-disable-next-line class-methods-use-this
  create(): Promise<ResultKuspitWithdrawalEntity> {
    return Promise.resolve(fixtures);
  }
}
