import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import RetirementFundLocationDummyRepository
  from '@/modules/flagship/catalogs/retirement-fund-location/infraestructure/repositories/retirement-fund-location-dummy-repository/retirement-fund-location-dummy-repository';
import RetirementFundLocationHttpRepository
  from '@/modules/flagship/catalogs/retirement-fund-location/infraestructure/repositories/retirement-fund-location-http-repository';
import RetirementFundLocationVueStateManager
  from '@/modules/flagship/catalogs/retirement-fund-location/infraestructure/state/retirement-fund-location-vue-state-manager';

// Application
import GetRetirementFundLocationQuery
  from '@/modules/flagship/catalogs/retirement-fund-location/application/queries/get-retirement-fund-location-query';

// Domain
import {
  RetirementFundLocationRepository,
} from '@/modules/flagship/catalogs/retirement-fund-location/domain/repositories/retirement-fund-location-repository';
import {
  RetirementFundLocationStateManager,
} from '@/modules/flagship/catalogs/retirement-fund-location/domain/state/retirement-fund-location-state-manager';

export default function setupRetirementFundLocation(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<RetirementFundLocationStateManager>(TYPES.RETIREMENT_FUND_LOCATION_STATE_MANAGER)
    .to(RetirementFundLocationVueStateManager)
    .inSingletonScope();

  container
    .bind<RetirementFundLocationRepository>(
      TYPES.RETIREMENT_FUND_LOCATION_REPOSITORY,
    ).to(
      withDummies
        ? RetirementFundLocationDummyRepository
        : RetirementFundLocationHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetRetirementFundLocationQuery>(
      TYPES.GET_RETIREMENT_FUND_LOCATION_QUERY,
    ).to(GetRetirementFundLocationQuery)
    .inSingletonScope();
}
