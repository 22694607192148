// Domain
import {
  InvestorGoalScheduledDistributionWealthWithdrawalRepository,
} from '../../../domain/repositories/investor-goal-scheduled-distribution-wealth-withdrawal-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class InvestorGoalScheduledDistributionWealthWithdrawalDummyRepository
implements InvestorGoalScheduledDistributionWealthWithdrawalRepository {
  distribute(): Promise<void> {
    return Promise.resolve();
  }
}
