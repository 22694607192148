import TYPES from '../../types';

// Domain
import { CustomerCellphoneEntity } from '../../domain/entities/customer-cellphone-entity';
import CustomerCellphoneStateManager from '../../domain/state/customer-cellphone-state-manager';
import { CustomerCellphoneRepository } from '../../domain/repository/customer-cellphone-respository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export class GetCustomerCellphoneQuery extends Query<Array<CustomerCellphoneEntity>> {
  @Inject(TYPES.CUSTOMER_CELLPHONE_STATE_MANAGER)
  private readonly state_manager!: CustomerCellphoneStateManager;

  @Inject(TYPES.CUSTOMER_CELLPHONE_REPOSITORY)
  private readonly repository!: CustomerCellphoneRepository;

  internalExecute() {
    if (this.state_manager.state.loaded) {
      return this.state_manager.state.items;
    }

    this.repository.get()
      .then((items) => {
        this.state_manager.patch({ items, loaded: true });
      });

    return this.state_manager.state.items;
  }
}
