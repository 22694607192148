import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import EmergencyFundInvestorGoalCalculatorDummyRepository
  from './infrastructure/repositories/emergency-fund-investor-goal-calculator-dummy-repository/emergency-fund-investor-goal-calculator-dummy-repository';
import EmergencyFundInvestorGoalCalculatorHttpRepository
  from './infrastructure/repositories/emergency-fund-investor-goal-calculator-http-repository';

// Application
import GetEmergencyFundInvestorGoalCalculatorQuery
  from './application/queries/get-emergency-fund-investor-goal-calculator-query';

// Domain
import { EmergencyFundInvestorGoalCalculatorRepository }
  from './domain/repositories/emergency-fund-investor-goal-calculator-repository';

export default function setupEmergencyFundInvestorGoalCalculator(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<EmergencyFundInvestorGoalCalculatorRepository>(TYPES
      .EMERGENCY_FUND_INVESTOR_GOAL_CALCULATOR_REPOSITORY)
    .to(withDummies
      ? EmergencyFundInvestorGoalCalculatorDummyRepository
      : EmergencyFundInvestorGoalCalculatorHttpRepository)
    .inSingletonScope();

  container.bind<GetEmergencyFundInvestorGoalCalculatorQuery>(TYPES
    .GET_EMERGENCY_FUND_INVESTOR_GOAL_CALCULATOR_QUERY)
    .to(GetEmergencyFundInvestorGoalCalculatorQuery)
    .inSingletonScope();
}
