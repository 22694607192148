import fixtures from './fixtures.json';

// Domain
import { EmergencyFundInvestorGoalCalculatorDto }
  from '../../../domain/dtos/emergency-fund-investor-goal-calculator-dto';
import { EmergencyFundInvestorGoalCalculatorEntity }
  from '../../../domain/entities/emergency-fund-investor-goal-calculator-entity';
import { EmergencyFundInvestorGoalCalculatorRepository }
  from '../../../domain/repositories/emergency-fund-investor-goal-calculator-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class EmergencyFundInvestorGoalCalculatorDummyRepository implements
  EmergencyFundInvestorGoalCalculatorRepository {
  get_emergency_fund_calculation(emergency_fund_calculation_dto:
    EmergencyFundInvestorGoalCalculatorDto): Promise<EmergencyFundInvestorGoalCalculatorEntity> {
    return Promise.resolve(fixtures);
  }
}
