import fixtures from './fixtures.json';

import Injectable from '@/modules/shared/domain/di/injectable';
import {
  EmergencyFundInvestorGoalRepository,
} from '@/modules/flagship/emergency-fund-investor-goal/domain/repositories/emergency-fund-investor-goal-repository';

@Injectable()
class EmergencyFundInvestorGoalDummyRepository implements EmergencyFundInvestorGoalRepository {
  get(): Promise<any> {
    return Promise.resolve(fixtures);
  }

  update(): Promise<void> {
    return Promise.resolve();
  }

  updatePlan(): Promise<void> {
    return Promise.resolve();
  }
}

export default EmergencyFundInvestorGoalDummyRepository;
