import linkStrategyModerateWealthDialog from './link-strategy-moderate-wealth-dialog.json';
import linkStrategyModerateLinkedGoals from './link-strategy-moderate-linked-goals.json';
import linkStrategyModerateComparison from './link-strategy-moderate-comparison-pesos-and-moderate.json';
import linkStrategyModerateHiring from './link-strategy-moderate-hiring.json';
import linkStrategyModerateTransfer from './link-strategy-moderate-transfer.json';
import linkStrategyModerateRequestSent from './link-strategy-moderate-request-sent.json';

export default {
  'link-sws-moderate': {
    ...linkStrategyModerateWealthDialog,
    ...linkStrategyModerateLinkedGoals,
    ...linkStrategyModerateComparison,
    ...linkStrategyModerateHiring,
    ...linkStrategyModerateTransfer,
    ...linkStrategyModerateRequestSent,
  },
};
