// Infrastructure
import fixtures from '@/modules/my-investment/catalogs/kuspit/state/infrastructure/repository/state-dummy-repository/fixtures.json';

// Domain
import { StateRepository } from '../../../domain/repository';
import { StateEntity } from '../../../domain/entities/state-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class StateDummyRepository implements StateRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(country_id: string): Promise<Array<StateEntity>> {
    return Promise.resolve(fixtures);
  }
}
