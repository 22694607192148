import TYPES from '../../types';

// Domain
import {
  CustomerInvestorGoalReminderDto,
} from '@/modules/flagship/customer-investor-goal-reminder/domain/dto/customer-investor-goal-reminder-dto';
import {
  CustomerInvestorGoalReminderEntity,
} from '@/modules/flagship/customer-investor-goal-reminder/domain/entities/customer-investor-goal-reminder-entity';
import {
  CustomerInvestorGoalReminderRepository,
} from '@/modules/flagship/customer-investor-goal-reminder/domain/repositories/customer-investor-goal-reminder-repository';
import {
  CustomerInvestorGoalReminderStateManager,
} from '@/modules/flagship/customer-investor-goal-reminder/domain/state/customer-investor-goal-reminder-state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class GetCustomerInvestorGoalReminderQuery
  extends Query<Promise<CustomerInvestorGoalReminderEntity>> {
  @Inject(TYPES.CUSTOMER_INVESTOR_GOAL_REMINDER_REPOSITORY)
  private readonly repository!: CustomerInvestorGoalReminderRepository;

  @Inject(TYPES.CUSTOMER_INVESTOR_GOAL_REMINDER_STATE_MANAGER)
  private readonly state_manager!: CustomerInvestorGoalReminderStateManager;

  // eslint-disable-next-line max-len
  internalExecute(customer_investor_goal_reminder: CustomerInvestorGoalReminderDto): Promise<CustomerInvestorGoalReminderEntity> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded && !customer_investor_goal_reminder.reload) {
        resolve(this.state_manager.state.items);
      } else {
        this.repository.get(customer_investor_goal_reminder.associated_product_id)
          .then((items) => {
            this.state_manager.patch({ items, loaded: true });
            resolve(items);
          })
          .catch(reject);
      }
    });
  }
}
