import POLICIES from '../security/policies';
import AuthenticatedLayout from '@/vue-app/layouts/AuthenticatedLayout.vue';
// import HomeDashboard from '@/vue-app/views/HomeDashboard.vue';
import KuspitDashboard from '@/vue-app/views/KuspitDashboard.vue';
import AllianzDashboard from '@/vue-app/views/AllianzDashboard.vue';
import MyInvestmentDashboardV2 from '@/vue-app/views/MyInvestmentDashboardV2.vue';
import GoalsDashboard from '@/vue-app/views/GoalsDashboard.vue';

export default {
  path: '/',
  component: AuthenticatedLayout,
  children: [
    {
      path: '/',
      name: 'HomeDashboard',
      component: MyInvestmentDashboardV2,
      meta: {
        access_policy: POLICIES.AUTHENTICATED,
      },
    },
    {
      path: '/sowos-pocket',
      name: 'KuspitDashboard',
      component: KuspitDashboard,
      meta: {
        access_policy: POLICIES.AUTHENTICATED,
      },
    },
    {
      path: '/sowos-wealth',
      name: 'AllianzDashboard',
      component: AllianzDashboard,
      meta: {
        access_policy: POLICIES.AUTHENTICATED,
      },
    },
    {
      path: '/goals-dashboard',
      name: 'GoalsDashboard',
      component: GoalsDashboard,
      meta: {
        access_policy: POLICIES.AUTHENTICATED,
      },
    },
  ],
};
