import TYPES from '../../types';

// Domain
import { InvestmentProviderEntity } from '../../domain/entities/investment-provider-entity';
import {
  InvestmentProviderRepository,
} from '../../domain/repository/investment-provider-repository';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class SearchInvestmentProvidersQuery
  extends Query<Promise<Array<InvestmentProviderEntity>>> {
  @Inject(TYPES.INVESTMENT_PROVIDER_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.INVESTMENT_PROVIDER_REPOSITORY)
  private readonly repository!: InvestmentProviderRepository;

  internalExecute(): Promise<Array<InvestmentProviderEntity>> {
    return new Promise((resolve, reject) => {
      if (!this.state_manager.state.loaded) {
        this.repository.execute()
          .then((items) => {
            this.state_manager.patch({ items, loaded: true });
            resolve(items);
          })
          .catch(reject);
      } else {
        resolve(this.state_manager.state.items);
      }
    });
  }
}
