import Injectable from '../../domain/di/injectable';
import Functions from '../../domain/utils/functions';

@Injectable()
export default class UtilFunctions extends Functions {
  convert_file_to_base_64(input_file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(input_file);
    });
  }

  async convert_base_64_to_file(base64: string, mime_type: string, filename: string):
    Promise<File> {
    const base_64_format = `data:${mime_type};base64,`;
    const final_base64 = !base64.startsWith(base_64_format)
      ? base_64_format.concat(base64) : base64;
    const response = await fetch(final_base64);
    const buffer = await response.arrayBuffer();

    return new File([buffer], filename, { type: mime_type });
  }
}
