import TYPES from '../../types';

// Domain
import { StateManager } from '../../domain/state/state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class GetAuthenticationState {
  @Inject(TYPES.AUTHENTICATION_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  get() {
    return this.state_manager.state;
  }
}
