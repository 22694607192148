import TYPES from '../../types';

// Domain
import {
  AccessMoreStrategiesRepository,
} from '@/modules/my-investment/allianz/access-more-strategies/domain/repositories/access-more-strategies-repository';
import {
  AccesssMoreStrategiesDto,
} from '@/modules/my-investment/allianz/access-more-strategies/domain/dtos/access-more-strategies-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CreateInvestmentFundHireCommand
  extends Command<AccesssMoreStrategiesDto> {
  @Inject(TYPES.ACCESS_MORE_STRATEGIES_REPOSITORY)
  private readonly repository!: AccessMoreStrategiesRepository;

  internalExecute(access_more_strategies_dto: AccesssMoreStrategiesDto): Promise<void> {
    return this.repository.create(access_more_strategies_dto);
  }
}
