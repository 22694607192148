import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import {
  TemplateQuestionnaireDummyRepository,
  TemplateQuestionnaireHttpRepository,
} from './infrastructure/repository';

// Application
import { SearchTemplateQuestionnairesQuery } from './application/queries';

// Domain
import { TemplateQuestionnaireRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupTemplateQuestionnaire(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.CATALOG_ALLIANZ_QUESTIONNAIRE_TEMPLATE_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<TemplateQuestionnaireRepository>(TYPES.CATALOG_ALLIANZ_QUESTIONNAIRE_TEMPLATE_REPOSITORY)
    .to(
      withDummies
        ? TemplateQuestionnaireDummyRepository
        : TemplateQuestionnaireHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchTemplateQuestionnairesQuery>(
      TYPES.SEARCH_CATALOG_ALLIANZ_QUESTIONNAIRE_TEMPLATE_QUERY,
    )
    .to(SearchTemplateQuestionnairesQuery)
    .inSingletonScope();
}
