import UnautheneticatedLayout from '@/vue-app/layouts/UnauthenticatedLayout.vue';
import DocumentSuccessfullySent from '@/vue-app/views/document-validation/DocumentSuccessfullySent.vue';
import POLICIES from '../security/policies';

export default {
  path: '/document-successfully-sent',
  component: UnautheneticatedLayout,
  children: [
    {
      path: '/',
      name: 'DocumentSuccessfullySent',
      component: DocumentSuccessfullySent,
      meta: {
        access_policy: POLICIES.UNAUTHENTICATED,
      },
    },
  ],
};
