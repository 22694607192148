import { v4 as uuid } from 'uuid';
import i18n from '@/vue-app/plugins/i18n';

import TYPES from '@/types';
import {
  requiredRule,
  emailFormat,
  passwordFormat,
  RuleResponseType,
} from '@/vue-app/utils/form-rules';

// Application
import { SignInCommand } from '@/modules/authentication/application/commands';
import { SignUpCommand } from '@/modules/register/application/commands';
import CreateOnBoardingStepCommand
  from '@/modules/on-boarding/steps/application/commands/create-on-boarding-step-command';
import SearchAgreementsAsyncQuery
  from '@/modules/agreements/application/queries/search-agreements-async-query';
import GetInternetStatusService
  from '@/modules/internet-status/application/services/get-internet-status-service';

// Domain
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { DatetimeValue } from '@/modules/shared/domain/value-objects/datetime-value';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { Router } from '@/modules/shared/domain/router';
import Inject from '@/modules/shared/domain/di/inject';
import {
  blacklistValidator,
  containsDigitsLowerCaseAndUpperCaseValidator,
  minLengthValidator, noConsecutiveCharactersValidator, noConsecutiveRepeatedCharactersValidator,
} from '@/modules/register/domain/services/password-validators';

export default class UserProfileViewModel {
  @Inject(TYPES.SIGN_UP_COMMAND)
  readonly signUpCommand!: SignUpCommand;

  @Inject(TYPES.SIGN_IN_COMMAND)
  readonly signInCommand!: SignInCommand;

  @Inject(TYPES.CREATE_ON_BOARDING_STEP_COMMAND)
  readonly createOnBoardingStepCommand!: CreateOnBoardingStepCommand;

  @Inject(TYPES.SEARCH_AGREEMENTS_ASYNC_QUERY)
  readonly searchAgreementsAsyncQuery!: SearchAgreementsAsyncQuery;

  @Inject(TYPES.DATETIME_VALUE)
  datetimeValue!: DatetimeValue;

  @Inject(TYPES.GET_INTERNET_STATUS_SERVICE)
  private readonly getInternetStatusService!: GetInternetStatusService;

  @Inject(TYPES.I18N)
  readonly translator!: Translator

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  @Inject(TYPES.ROUTER)
  private readonly router!: Router;

  private readonly i18n_namespace = 'sign-up';

  readonly link_privacy_notice = `${process.env.VUE_APP_S3_LEGAL_URL}/AVISO_DE_PRIVACIDAD_SOWOS_io.pdf`;

  readonly link_terms_and_conditions = `${process.env.VUE_APP_S3_LEGAL_URL}/TYC_SOWOS_io.pdf`;

  inputs = {
    username: '',
    password: '',
    password_confirmation: '',
    device: 'website',
  };

  rules = {
    username: [requiredRule, emailFormat],
    password: [requiredRule, passwordFormat],
    password_confirmation: [
      (value: string): RuleResponseType => value === this.inputs.password
        || i18n.t('utils.form-rules.confirm_password_match').toString(),
    ],
  };

  loading = false;

  show = {
    password: false,
    password_confirmation: false,
  };

  accept_terms_and_conditions = false;

  accept_privacy_notice = false;

  agreement_terms_and_conditions_accepted_on = this.datetimeValue.create();

  agreement_privacy_notice_accepted_on = this.datetimeValue.create();

  valid_form = false;

  referrer = '';

  get is_password_min_length_valid() {
    return minLengthValidator(this.inputs.password);
  }

  get one_capital_and_lowercase_letter_one_number() {
    return containsDigitsLowerCaseAndUpperCaseValidator(this.inputs.password);
  }

  get password_not_contains_blacklisted_words() {
    const email_id = this.inputs.username.split('@')[0];
    return blacklistValidator(this.inputs.password, [email_id]);
  }

  get password_has_no_consecutive_characters() {
    return noConsecutiveCharactersValidator(this.inputs.password);
  }

  get password_has_no_consecutive_repeated_characters() {
    return noConsecutiveRepeatedCharactersValidator(this.inputs.password);
  }

  get internetStatus() {
    return this.getInternetStatusService.execute();
  }

  get canContinue() {
    return (
      this.valid_form
      && this.accept_terms_and_conditions
      && this.accept_privacy_notice
      && this.internetStatus
      && this.is_password_min_length_valid
      && this.one_capital_and_lowercase_letter_one_number
      && this.password_not_contains_blacklisted_words
      && this.password_has_no_consecutive_characters
      && this.password_has_no_consecutive_repeated_characters
    );
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  handleSubmit = async () => {
    this.loading = true;

    try {
      await this.signUpCommand.execute({
        invitation_token: this.referrer || null,
        id: uuid(),
        group_id: '6d48b0d7-f570-4689-a518-1ab1c6bb17e7',
        ...this.inputs,
        agreement_terms_and_conditions_accepted_on: this.agreement_terms_and_conditions_accepted_on,
        agreement_privacy_notice_accepted_on: this.agreement_privacy_notice_accepted_on,
      });
      await this.signInCommand.execute({
        password: this.inputs.password,
        username: this.inputs.username,
        device: this.inputs.device,
      });
      // Agreements are initialized here because authentication token is required.
      await this.router.navigate('/on-boarding');
    } catch {
      this.messageNotifier.showErrorNotification(this.translate('errors.cant_register').toString());
    } finally {
      this.loading = false;
    }
  }

  handleAcceptTermsAndConditions = () => {
    this.agreement_terms_and_conditions_accepted_on = this.datetimeValue.create();
  }

  handleAcceptPrivacyNotice = () => {
    this.agreement_privacy_notice_accepted_on = this.datetimeValue.create();
  }

  setReferrer = (referrer: string) => {
    this.referrer = referrer;
  }

  toLowerCaseWithoutAccents = (value: string) => {
    const normalized = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    this.inputs.username = normalized.toLowerCase();
  }
}
