import TYPES from '../../types';
import SHARED_TYPES from '@/modules/shared/types';

// Domain
import { Status } from '../../domain/dtos';
import { OnBoardingStatusStateManager } from '../../domain/state';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Subscription from '@/modules/shared/domain/use-cases/subscription';
import { WebSocket } from '@/modules/shared/domain/web-socket';

@Injectable()
export default class MyInvestmentAllianzOnBoardingStatusSubscription extends Subscription<string> {
  @Inject(SHARED_TYPES.WEB_SOCKET)
  private readonly web_socket!: WebSocket;

  @Inject(TYPES.MY_INVESTMENT_ALLIANZ_ON_BOARDING_STATUS_STATE_MANAGER)
  private readonly on_boarding_status_state_manager!: OnBoardingStatusStateManager;

  private endpoint = `${process.env.VUE_APP_WEBSOCKET_URL}/app.investment.allianz.on_boarding_status_updated.1.event_publisher/{USER_ID}/`;

  internalExecute(user_id: string) {
    this.endpoint = this.endpoint.replace('{USER_ID}', user_id);
    this.web_socket.connect(this.endpoint, (event) => {
      const data: Status = event.attributes;

      if (this.on_boarding_status_state_manager.state.data.percent < data.percent) {
        this.on_boarding_status_state_manager.put({ data });
      }

      if (data.percent === 100) {
        this.web_socket.disconnect(this.endpoint);
      }
    });
  }

  stop() {
    this.web_socket.disconnect(this.endpoint);
  }
}
