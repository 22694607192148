import fixtures from './fixtures.json';

// Domain
import { OverallInvestmentAmountRepository } from '@/modules/flagship/overall-investment-amount-calculation/domain/repositories/overall-investment-amount-repository';
import { OverallInvestmentAmountEntity } from '@/modules/flagship/overall-investment-amount-calculation/domain/entities/overall-investment-amount-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
class OverallInvestmentAmountDummyRepository implements OverallInvestmentAmountRepository {
  calculate(): Promise<OverallInvestmentAmountEntity> {
    return Promise.resolve(fixtures);
  }
}

export default OverallInvestmentAmountDummyRepository;
