import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import { StrategySowosModerateDummyRepository }
  from '@/modules/flagship/strategy-moderate/hire/infraestructure/repositories/strategy-sowos-moderate-dummy-repository';
import StrategySowosModerateHttpRepository
  from '@/modules/flagship//strategy-moderate/hire/infraestructure/repositories/strategy-sowos-moderate-http-repository';
import AvailableToHireStrategyModerateVueStateManager
  from '@/modules/flagship//strategy-moderate/hire/infraestructure/state/available-to-hire-strategy-moderate-vue-state-manager';

// Application
import HireStrategyModerateCommand
  from '@/modules/flagship/strategy-moderate/hire/application/commands/hire-strategy-moderate-command';
import GetIsAvailableToHireStrategyModerateQuery
  from '@/modules/flagship/strategy-moderate/hire/application/queries/get-is-available-to-hire-strategy-moderate-query';

// Domain
import {
  StrategySowosModerateRepository,
} from '@/modules/flagship/strategy-moderate/hire/domain/repositories/strategy-sowos-moderate-repository';
import {
  AvailableToHireStrategyModerateStateManager,
} from '@/modules/flagship/strategy-moderate/hire/domain/state/available-to-hire-strategy-moderate-state-manager';

export default function setupStrategySowosModerate(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<AvailableToHireStrategyModerateStateManager>(
      TYPES.AVAILABLE_TO_HIRE_STRATEGY_MODERATE_STATE_MANAGER,
    ).to(AvailableToHireStrategyModerateVueStateManager)
    .inSingletonScope();

  container
    .bind<StrategySowosModerateRepository>(
      TYPES.STRATEGY_SOWOS_MODERATE_REPOSITORY,
    ).to(
      withDummies
        ? StrategySowosModerateDummyRepository
        : StrategySowosModerateHttpRepository,
    ).inSingletonScope();

  container
    .bind<HireStrategyModerateCommand>(
      TYPES.HIRE_STRATEGY_MODERATE_COMMAND,
    ).to(HireStrategyModerateCommand)
    .inSingletonScope();

  container
    .bind<GetIsAvailableToHireStrategyModerateQuery>(
      TYPES.GET_IS_AVAILABLE_TO_HIRE_STRATEGY_MODERATE_QUERY,
    ).to(GetIsAvailableToHireStrategyModerateQuery)
    .inSingletonScope();
}
