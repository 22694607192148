import TYPES from '@/types';

// DOMAIN
import { Values } from '@/modules/shared/domain/i18n/types';
import Translator from '@/modules/shared/domain/i18n/translator';
import Inject from '@/modules/shared/domain/di/inject';

export default class UnauthenticatedFooterViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace =
    'components.footer.unauthenticated_footer';

  readonly hrefs = {
    privacy_notice: `${process.env.VUE_APP_S3_LEGAL_URL}/AVISO_DE_PRIVACIDAD_SOWOS_io.pdf`,
    terms_and_conditions: `${process.env.VUE_APP_S3_LEGAL_URL}/TYC_SOWOS_io.pdf`,
    instagram: 'https://www.instagram.com/sowos.io/',
    facebook: 'https://www.facebook.com/SowosMX/',
    twitter: 'https://twitter.com/SOWOSMX',
    linkedin: 'https://www.linkedin.com/company/sowosmx/',
  };

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);
}
