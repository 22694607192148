import { Container } from 'inversify';
import TYPES from './types';

// Infrastructure
import CustomerInvestorGoalReminderDummyRepository
  from '@/modules/flagship/customer-investor-goal-reminder/infrastructure/repositories/customer-investor-goal-reminder-dummy-repository/customer-investor-goal-reminder-dummy-repository';
import CustomerInvestorGoalReminderHttpRepository
  from '@/modules/flagship/customer-investor-goal-reminder/infrastructure/repositories/customer-investor-goal-reminder-http-repository';
import CustomerInvestorGoalReminderVueStateManager
  from '@/modules/flagship/customer-investor-goal-reminder/infrastructure/state/customer-investor-goal-reminder-vue-state-manager';

// Application
import CreateCustomerInvestorGoalReminderCommand
  from '@/modules/flagship/customer-investor-goal-reminder/application/commands/create-customer-investor-goal-reminder-command';
import GetCustomerInvestorGoalReminderQuery
  from '@/modules/flagship/customer-investor-goal-reminder/application/queries/get-customer-investor-goal-reminder-query';
import UpdateCustomerInvestorGoalReminderCommand
  from '@/modules/flagship/customer-investor-goal-reminder/application/commands/update-customer-investor-goal-reminder-command';

// Domain
import {
  CustomerInvestorGoalReminderRepository,
} from '@/modules/flagship/customer-investor-goal-reminder/domain/repositories/customer-investor-goal-reminder-repository';
import {
  CustomerInvestorGoalReminderStateManager,
} from '@/modules/flagship/customer-investor-goal-reminder/domain/state/customer-investor-goal-reminder-state-manager';

export default function setupCustomerInvestorGoalReminder(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    // eslint-disable-next-line max-len
    .bind<CustomerInvestorGoalReminderStateManager>(TYPES.CUSTOMER_INVESTOR_GOAL_REMINDER_STATE_MANAGER)
    .to(CustomerInvestorGoalReminderVueStateManager)
    .inSingletonScope();
  container
    .bind<CustomerInvestorGoalReminderRepository>(TYPES.CUSTOMER_INVESTOR_GOAL_REMINDER_REPOSITORY)
    .to(
      withDummies
        ? CustomerInvestorGoalReminderDummyRepository
        : CustomerInvestorGoalReminderHttpRepository,
    )
    .inSingletonScope();
  container
    .bind<GetCustomerInvestorGoalReminderQuery>(TYPES.GET_CUSTOMER_INVESTOR_GOAL_REMINDER_QUERY)
    .to(GetCustomerInvestorGoalReminderQuery)
    .inSingletonScope();
  container
    // eslint-disable-next-line max-len
    .bind<CreateCustomerInvestorGoalReminderCommand>(TYPES.CREATE_CUSTOMER_INVESTOR_GOAL_REMINDER_COMMAND)
    .to(CreateCustomerInvestorGoalReminderCommand)
    .inSingletonScope();
  container
    // eslint-disable-next-line max-len
    .bind<UpdateCustomerInvestorGoalReminderCommand>(TYPES.UPDATE_CUSTOMER_INVESTOR_GOAL_REMINDER_COMMAND)
    .to(UpdateCustomerInvestorGoalReminderCommand)
    .inSingletonScope();
}
