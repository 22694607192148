import TYPES from '@/types';

// Domain
import { InvestmentTypeHiringStepRepository } from '../../domain/repositories/investment-type-hiring-step-repository';
import { InvestmentTypeHiringStepEntity } from '../../domain/entities/investment-type-hiring-step-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';
import { Sort } from '@/modules/shared/domain/dtos/sort_dto';

@Injectable()
export default class InvestmentTypeHiringStepHttpRepository
implements InvestmentTypeHiringStepRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly path = `${process.env.VUE_APP_API_URL}/api/v1/app/investment/allianz/investment-fund-hiring-step/`;

  create(step: InvestmentTypeHiringStepEntity): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.path, 'POST', step)
        .then((response) => {
          response.json().then(({ success, message }) => {
            if (success) {
              resolve();
            } else {
              reject(new Error(message));
            }
          });
        })
        .catch(reject);
    });
  }

  update(step: InvestmentTypeHiringStepEntity): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.path}${step.id}/`, 'PATCH', {
        current_step: step.current_step,
        payload: step.payload,
      })
        .then((response) => {
          if (response.status === 204) {
            resolve();
          } else {
            reject(new Error('There was an error updating the step.'));
          }
        })
        .catch(reject);
    });
  }

  search(sort: Sort): Promise<Array<InvestmentTypeHiringStepEntity>> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.path}?sort_field=${sort.sort_field}`)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            })
            .catch(reject);
        });
    });
  }
}
