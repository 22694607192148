import editEmergencyFundGoalPlan from './edit-emergency-fund-goal-plan';
import editCustomGoalPlan from './edit-custom-goal-plan';
import editRetirementFundGoalPlan from './edit-retirement-fund-goal-plan';

export default {
  'edit-plan-goals': {
    ...editEmergencyFundGoalPlan,
    ...editCustomGoalPlan,
    ...editRetirementFundGoalPlan,
  },
};
