import TYPES from '../../types';

// Domain
import {
  OnBoardingStepKuspitAccountEntity,
} from '@/modules/my-investment/kuspit/kuspit-account-linking/domain/entity/on-boarding-step-kuspit-account-entity';
import { UpdateKuspitOnBoardingStepRepository } from '@/modules/my-investment/kuspit/kuspit-account-linking/domain/repositories/update-kuspit-on-boarding-step-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
class UpdateKuspitOnBoardingStepCommand extends Command<OnBoardingStepKuspitAccountEntity> {
  @Inject(TYPES.UPDATE_KUSPIT_ON_BOARDING_STEP_REPOSITORY)
  private readonly repository!: UpdateKuspitOnBoardingStepRepository;

  internalExecute(on_boarding_step_entity: OnBoardingStepKuspitAccountEntity): Promise<void> {
    return this.repository.update(on_boarding_step_entity);
  }
}

export default UpdateKuspitOnBoardingStepCommand;
