// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { CustomerKuspitMovementRepository } from '../../../domain/repository';
import { MovementEntity } from '../../../domain/entities/movement-entity';
import { SearchMovementDto } from '../../../domain/dtos/search-movement-dto';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CustomerKuspitMovementDummyRepository
implements CustomerKuspitMovementRepository {
  execute(dates: SearchMovementDto): Promise<Array<MovementEntity>> {
    return Promise.resolve(fixtures);
  }
}
