import TYPES from '../../types';

// Domain
import {
  InvestorGoalScheduledDistributionWealthWithdrawalDto,
} from '../../domain/dto/investor-goal-scheduled-distribution-wealth-withdrawal-dto';
import {
  InvestorGoalScheduledDistributionWealthWithdrawalRepository,
} from '../../domain/repositories/investor-goal-scheduled-distribution-wealth-withdrawal-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class InvestorGoalScheduledDistributionWealthWithdrawalCommand
  extends Command<InvestorGoalScheduledDistributionWealthWithdrawalDto> {
  @Inject(TYPES.INVESTOR_GOAL_SCHEDULED_DISTRIBUTION_WEALTH_WITHDRAWAL_REPOSITORY)
  private readonly repository!: InvestorGoalScheduledDistributionWealthWithdrawalRepository;

  // eslint-disable-next-line max-len
  internalExecute(investor_goal_scheduled_distribution_wealth_withdrawal_dto: InvestorGoalScheduledDistributionWealthWithdrawalDto):
    Promise<void> {
    return this.repository.distribute(investor_goal_scheduled_distribution_wealth_withdrawal_dto);
  }
}
