// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { CustomerKuspitPositionDetailsRepository } from '../../../domain/repository';
import { PositionEntity } from '../../../domain/entities/position-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CustomerKuspitPositionDetailsDummyRepository
implements CustomerKuspitPositionDetailsRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(): Promise<PositionEntity> {
    return Promise.resolve(fixtures);
  }
}
