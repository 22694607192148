// Domain
import { BudgetEntity } from '../entities/budget-entity';
import { CurrentAmountEntity } from '../entities/current-amount-entity';
import { CurrentPlanEntity } from '../entities/current-plan-entity';
import { DefineByEntity } from '../entities/define-by-entity';

export default class State {
  budget: BudgetEntity = {
    monthly_income: 0,
    monthly_expenses: 0,
    monthly_savings_capacity: 0,
    new_emergency_fund: 0,
  }

  current_amount: CurrentAmountEntity = {
    additional_amount: 0,
    there_is_more_savings: '',
    strategy_selected: '',
  }

  current_plan: CurrentPlanEntity = {
    target_amount: '',
    current_amount: '',
    monthly_required_amount: '',
    final_investment_date: '',
    associated_product_id: '',
    emergency_fund_id: '',
  }

  define_by: DefineByEntity = {
    is_defined_by_contribution: false,
    by_time: {
      months_to_reach_goal: 0,
      you_must_save: 0,
      accumulated_amount: 0,
    },
    by_contribution: {
      contribution: 0,
      months_to_reach_goal: 0,
      accumulated_amount: 0,
    },
  }
}
