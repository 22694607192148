// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { BusinessActivityRepository } from '../../../domain/repository';
import { BusinessActivityEntity } from '../../../domain/entities/business-activity-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class BusinessActivityDummyRepository implements BusinessActivityRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(): Promise<Array<BusinessActivityEntity>> {
    return Promise.resolve(fixtures);
  }
}
