import TYPES from '../../types';

// Domain
import {
  PendingInvestorGoalMovementsRepository,
} from '../../domain/repositories/pending-investor-goal-movements-repository';
import {
  PendingInvestorGoalMovementsStateManager,
} from '../../domain/state/pending-investor-goal-movements-state-manager';
import {
  PendingInvestorGoalMovementsEntity,
} from '../../domain/entities/pending-investor-goal-movements-entity';
import { PendingInvestorGoalMovementsQueryParamsDto } from '../../domain/dtos/pending-investor-goal-movements-query-params-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
// eslint-disable-next-line max-len
export default class GetPendingInvestorGoalMovementsQuery extends Query<Promise<Array<PendingInvestorGoalMovementsEntity>>> {
  @Inject(TYPES.PENDING_INVESTOR_GOAL_MOVEMENTS_REPOSITORY)
  private readonly repository!: PendingInvestorGoalMovementsRepository;

  @Inject(TYPES.PENDING_INVESTOR_GOAL_MOVEMENTS_STATE_MANAGER)
  private readonly state_manager!: PendingInvestorGoalMovementsStateManager;

  internalExecute(params: PendingInvestorGoalMovementsQueryParamsDto):
    Promise<Array<PendingInvestorGoalMovementsEntity>> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded) {
        resolve(this.state_manager.state.items);
      } else {
        this.repository.get(params)
          .then((items) => {
            this.state_manager.patch({ items, loaded: false });
            resolve(items);
          })
          .catch(reject);
      }
    });
  }
}
