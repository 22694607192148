import TYPES from '../../types';

// Domain
import { InvestmentProductFundTypeEntity } from '../../domain/entities/investment-product-fund-type-entity';
import { InvestmentProductFundTypeRepository } from '../../domain/repositories/investment-product-fund-type-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class SearchInvestmentProductFundTypeByCustomerQuery extends
  Query<Promise<Array<InvestmentProductFundTypeEntity>>> {
  @Inject(TYPES.ALLIANZ_INVESTMENT_PRODUCT_FUND_TYPE_REPOSITORY)
  private readonly repository!: InvestmentProductFundTypeRepository;

  internalExecute(): Promise<Array<InvestmentProductFundTypeEntity>> {
    return new Promise((resolve, reject) => {
      this.repository.search_available()
        .then((items) => {
          resolve(items);
        })
        .catch(reject);
    });
  }
}
