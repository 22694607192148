import fixtures from './fixture.json';

// Domain
import {
  CustomGoalCalculationEntity,
} from '@/modules/flagship/custom-goal-calculations/domain/entities/custom-goal-calculation-entity';
import {
  CustomGoalMonthlyPaymentCalculationRepository,
} from '@/modules/flagship/custom-goal-calculations/domain/repositories/custom-goal-monthly-payment-calculation-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
class CustomGoalMonthlyPaymentCalculationDummyRepository implements
  CustomGoalMonthlyPaymentCalculationRepository {
  calculate(): Promise<CustomGoalCalculationEntity> {
    return Promise.resolve(fixtures);
  }
}

export default CustomGoalMonthlyPaymentCalculationDummyRepository;
