import i18n from '@/vue-app/plugins/i18n';
import TYPES from '@/types';

// Infrastructure
import PersonPresenter from '@/modules/customer/person/infrastructure/presenters/person-presenter';

// Application
import GetCustomerPersonalityProfileQuery
  from '@/modules/flagship/customer-investor-profiling/application/query/get-customer-personality-profile-query';
import SignOutService
  from '@/modules/authentication/application/services/sign-out-service';

// Domain
import { GetCustomerPersonQuery } from '@/modules/customer/person/application/queries';
import { Router } from '@/modules/shared/domain/router';
import Inject from '@/modules/shared/domain/di/inject';

export default class UserProfileMenuViewModel {
  @Inject(TYPES.ROUTER)
  private readonly router!: Router;

  @Inject(TYPES.GET_CUSTOMER_PERSON_QUERY)
  private readonly get_customer_person_query!: GetCustomerPersonQuery;

  @Inject(TYPES.GET_CUSTOMER_PERSONALITY_PROFILE_QUERY)
  private readonly get_customer_personality_profile!: GetCustomerPersonalityProfileQuery;

  private readonly i18n_namespace = 'components.user-profile.user-profile-menu';

  active_tab = '';

  loading = true;

  person_presenter?: PersonPresenter;

  translate = (identifier: string) => (
    i18n.t(`${this.i18n_namespace}.${identifier}`) as string
  )

  show_personality_profile_section = false;

  setShowPersonalityProfileSection = async () => {
    try {
      const personality_profile = await this.get_customer_personality_profile.execute();
      this.show_personality_profile_section = personality_profile.show_personality_profile;
    } catch {
      this.show_personality_profile_section = false;
    }
  }

  initialize = async () => {
    this.setActiveTabFromRoute();

    const person = await this.get_customer_person_query.execute();
    this.person_presenter = new PersonPresenter(person);
    await this.setShowPersonalityProfileSection();
    this.loading = false;
  }

  changeTab = (tab: string) => {
    if (this.active_tab !== tab) {
      this.router.navigate({ hash: tab.replaceAll('_', '-') });
      this.active_tab = tab;
    }
    this.publishNewActiveTab(tab);
  }

  openExternalLink = (url: string) => {
    window.open(url, '_blank');
  }

  signOut = async () => {
    await new SignOutService().logout('/login');
  }

  private setActiveTabFromRoute() {
    const pages = this.router.current_path.hash?.replace('#', '').split('_').map(
      (item) => item.replaceAll('-', '_'),
    );

    if (pages?.length) {
      this.publishNewActiveTab(pages[0]);
      [this.active_tab] = pages;
    }
  }

  private publishNewActiveTab(new_tab: string) {
    const update_active_tab = new CustomEvent('user-profile-menu.active_tab', {
      detail: {
        active_tab: new_tab,
      },
    });

    window.dispatchEvent(update_active_tab);
  }
}
