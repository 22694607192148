import TYPES from '../../types';

// Domain
import {
  AnonymousTicketDto,
} from '@/modules/zendesk/anonymous-ticket/domain/dtos/anonymous-ticket-dto';
import {
  AnonymousTicketRepository,
} from '@/modules/zendesk/anonymous-ticket/domain/repositories/anonymous-ticket-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CreateAnonymousTicketCommand extends Command<AnonymousTicketDto> {
  @Inject(TYPES.ANONYMOUS_TICKET_REPOSITORY)
  private readonly repository!: AnonymousTicketRepository;

  internalExecute(anonymous_ticket_dto: AnonymousTicketDto): Promise<void> {
    return this.repository.create(anonymous_ticket_dto);
  }
}
