// Domain
import {
  PreferredMeansOfContactRepository,
} from '@/modules/on-boarding/preferred-means-of-contact/domain/repositories/preferred-means-of-contact-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class PreferredMeansOfContactDummyRepository
implements PreferredMeansOfContactRepository {
  update(): Promise<void> {
    return Promise.resolve();
  }
}
