// Infrastructure
import fixtures from './fixtures.json';

// Domain
import {
  IncomeBehaviorInFollowingYearEntity,
} from '@/modules/flagship/catalogs/income-behavior-in-following-year/domain/entities/income-behavior-in-following-year-entity';
import {
  IncomeBehaviorInFollowingYearRepository,
} from '@/modules/flagship/catalogs/income-behavior-in-following-year/domain/repositories/income-behavior-in-following-year-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class IncomeBehaviorInFollowingYearDummyRepository
implements IncomeBehaviorInFollowingYearRepository {
  execute(): Promise<Array<IncomeBehaviorInFollowingYearEntity>> {
    return Promise.resolve(fixtures);
  }
}
