import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';

// Application
import { GetAuthenticationState, SignInService, SignOutService } from '@/modules/authentication/application/services';
import { CloseSessionWhenTokenExpiresJob } from '@/modules/authentication/application/jobs';
import { TokenRefreshCommand } from '@/modules/authentication/application/commands';

// Domain
import { RefreshTokenEntity } from '@/modules/authentication/domain/entities';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { Router } from '@/modules/shared/domain/router';

export default class ExpiredSessionViewModel {
  @Inject(TYPES.GET_AUTHENTICATION_STATE_SERVICE)
  private readonly get_authentication_state_service!: GetAuthenticationState;

  @Inject(TYPES.CLOSE_SESSION_WHEN_TOKEN_EXPIRES_JOB)
  readonly close_session_when_token_expires_job!: CloseSessionWhenTokenExpiresJob;

  @Inject(TYPES.TOKEN_REFRESH_COMMAND)
  readonly token_refresh_command!: TokenRefreshCommand;

  @Inject(TYPES.ROUTER)
  private readonly router!: Router;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  readonly i18n_namespace = 'components.session-status.expired_session';

  private interval?: NodeJS.Timer;

  is_open = false;

  milliseconds_til_expiration = 0;

  time_to_expire = '';

  is_loading = false;

  get is_auth() {
    return this.get_authentication_state_service.get().is_auth;
  }

  get keep_session() {
    return this.get_authentication_state_service.get().keep_session;
  }

  get can_refresh_token() {
    return this.milliseconds_til_expiration > 0 && !this.is_loading;
  }

  formatTimeToExpire = (milliseconds: number) => {
    const minutes = Math.floor(milliseconds / 60000);
    const seconds = parseInt(((milliseconds % 60000) / 1000).toFixed(0), 10);
    const prefix_minutes = minutes < 10 ? '0' : '';
    const prefix_seconds = seconds < 10 ? '0' : '';
    return `${prefix_minutes}${minutes}:${prefix_seconds}${seconds}`;
  }

  setTimeToExpire = () => {
    this.milliseconds_til_expiration -= 1000;
    this.time_to_expire = this.formatTimeToExpire(this.milliseconds_til_expiration);
    if (this.milliseconds_til_expiration <= 0 && this.interval) {
      this.closeSession();
      clearInterval(this.interval);
    }
  }

  setIntervalTimeToExpire = async () => {
    const expiration_datetime = sessionStorage.getItem('session_til');
    const expiration_parsed_datetime = new Date(expiration_datetime!);
    const current_time = new Date();
    // eslint-disable-next-line
    // @ts-ignore
    this.milliseconds_til_expiration = expiration_parsed_datetime - current_time;
    if (this.milliseconds_til_expiration >= 0) {
      this.interval = await setInterval(() => {
        this.setTimeToExpire();
      }, 1000);
      this.is_open = true;
    }
  }

  closeSession = async () => {
    this.is_open = false;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    clearInterval(<NodeJS.Timer> this.interval);
    await new SignOutService().logout('/login');
    this.close_session_when_token_expires_job.endSession();
  }

  extendSession = () => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    clearInterval(<NodeJS.Timer> this.interval);
    this.close_session_when_token_expires_job.keepSession(false);
    this.close_session_when_token_expires_job.perform_later();
    this.is_open = false;
    this.time_to_expire = '';
  }

  notifyExtension = () => {
    const transaction_event = new CustomEvent('app.auth.token.updated', {});
    window.dispatchEvent(transaction_event);
  }

  refreshToken = async () => {
    try {
      this.is_loading = true;
      const sign_in_service = new SignInService();
      const response = await this.token_refresh_command.execute({}) as RefreshTokenEntity;
      const { token } = response;
      sign_in_service.login(token);
      this.notifyExtension();
      this.extendSession();
    } catch {
      this.messageNotifier.showErrorNotification(`${i18n.t(`${this.i18n_namespace}.message_failed_refresh_token`)}`);
    } finally {
      this.is_loading = false;
    }
  }

  initialize = async () => {
    const authentication_token = sessionStorage.getItem('authorization');
    if (authentication_token) {
      this.close_session_when_token_expires_job.perform_later();
    }
  }
}
