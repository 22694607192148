import {
  DepositFlexibleDto,
} from '@/modules/my-investment/allianz/transaction/domain/dtos/deposit-flexible-dto';
import {
  InvestorGoalScheduledDistributionWealthFlexibleDto,
} from '@/modules/flagship/investor-goal-scheduled-distribution/sowos-wealth/flexible/domain/dto/investor-goal-scheduled-distribution-wealth-flexible-dto';

export class WealthFlexibleDepositState {
  deposit: DepositFlexibleDto = {
    source_account: '',
    destination_account: {
      id: '',
      customer_id: '',
      allianz_account_id: '',
      transaction_id: '',
      request_number: '',
      product: '',
      policy_number: 0,
      client_code: '',
      client_name: '',
      activation_date: '',
      beginning_of_validity_date: '',
      agent_office: 0,
      agent_code: '',
      agent_name: '',
      promoter_code: '',
      promoter_name: '',
      grouper_code: '',
      grouper_name: '',
      advisor_code: '',
      advisor_name: '',
      item: '',
      found_code: '',
      found_name: '',
      amount_contributed: 0,
      withdrawal_amount: 0,
      charge_amount: 0,
      units: 0,
      units_value: 0,
      final_balance: 0,
      final_balance_mxn: 0,
      last_payment_date: '',
      updated_at: '',
      created_at: '',
    },
    amount: '',
  };

  goal_distribution: InvestorGoalScheduledDistributionWealthFlexibleDto = {
    total_contribution: 0,
    unassigned_balance: 0,
    is_deposit: true,
    investor_goal_scheduled_distributions: [],
  };

  application_date = '';

  accreditation_date = '';

  estimated_collection_date = '';

  estimated_reflected_date = '';
}
