import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';
import StateManager from '../../domain/state/state-manager';
import OnBoardingCreateStepRepository from '../../domain/repositories/on-boarding-create-step-repository';
import { StepEntity } from '../../domain/entities/step-entity';

@Injectable()
export default class CreateOnBoardingStepCommand extends Command<StepEntity> {
  @Inject(TYPES.ON_BOARDING_STEPS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.ON_BOARDING_CREATE_STEP_REPOSITORY)
  private readonly repository!: OnBoardingCreateStepRepository;

  internalExecute(step: StepEntity): Promise<void> {
    return new Promise((resolve, reject) => {
      this.repository.execute(step)
        .then(() => {
          this.state_manager.patch({
            items: [step, ...this.state_manager.state.items],
          });

          resolve();
        })
        .catch(reject);
    });
  }
}
