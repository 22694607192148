import TYPES from '../../types';

// Domain
import {
  UpdatePlanEmergencyFundDto,
} from '@/modules/flagship/emergency-fund-investor-goal/domain/dtos/update-plan-emergency-fund-dto';
import {
  EmergencyFundInvestorGoalRepository,
} from '@/modules/flagship/emergency-fund-investor-goal/domain/repositories/emergency-fund-investor-goal-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UpdatePlanEmergencyFundInvestorGoalCommand
  extends Command<UpdatePlanEmergencyFundDto> {
  @Inject(TYPES.EMERGENCY_FUND_INVESTOR_GOAL_REPOSITORY)
  private readonly repository!: EmergencyFundInvestorGoalRepository;

  internalExecute(update_plan_emergency_fund_dto: UpdatePlanEmergencyFundDto) {
    return this.repository.updatePlan(update_plan_emergency_fund_dto);
  }
}
