// Domain
import {
  HabitsAvailableBudgetCalculationRepository,
} from '@/modules/flagship/habits-available-budget-calculation/domain/repositories/habits-available-budget-calculation-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class HabitsAvailableBudgetCalculationDummyRepository
implements HabitsAvailableBudgetCalculationRepository {
  create(): Promise<void> {
    return Promise.resolve();
  }
}
