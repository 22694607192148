import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import LinkSowosWealthDummyRepository from './infrastructure/repositories/link-sowos-wealth-dummy-repository';
import LinkSowosWealthHttpRepository from './infrastructure/repositories/link-sowos-wealth-http-repository';

// Application
import CreateLinkSowosWealthCommand from './application/commands/create-link-sowos-wealth-command';

// Domain
import { LinkSowosWealthRepository } from './domain/repositories/link-sowos-wealth-repository';

export default function setupLinkSowosWealth(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<LinkSowosWealthRepository>(
      TYPES.LINK_SOWOS_WEALTH_REPOSITORY,
    ).to(
      withDummies
        ? LinkSowosWealthDummyRepository
        : LinkSowosWealthHttpRepository,
    ).inSingletonScope();

  container
    .bind<CreateLinkSowosWealthCommand>(
      TYPES.CREATE_LINK_SOWOS_WEALTH_COMMAND,
    ).to(CreateLinkSowosWealthCommand)
    .inSingletonScope();
}
