import TYPES from '@/modules/shared/types';

// Domain
import {
  CustomerInvestmentProductFundTypeRepository,
} from '@/modules/flagship/customer-investment-product-fund-type/domain/repositories/customer-investment-product-fund-type-repository';
import {
  ModerateIsContractedEntity,
} from '@/modules/flagship/customer-investment-product-fund-type/domain/entities/moderate-is-contracted-entity';
import {
  QueryInvestmentProductsContractedEntity,
} from '@/modules/flagship/customer-investment-product-fund-type/domain/entities/query-investment-products-contracted-entity';
import {
  CustomerInvestmentProductFundTypeEntity,
} from '@/modules/flagship/customer-investment-product-fund-type/domain/entities/customer-investment-product-fund-type-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class CustomerInvestmentProductFundTypeHttpRepository
implements CustomerInvestmentProductFundTypeRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/customer-investment-product-fund-type`;

  get_moderate_is_contracted(): Promise<ModerateIsContractedEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}/moderate-is-contracted-by-customer/`)
        .then((response) => {
          response.json()
            .then(({ success, data, error }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(error));
              }
            });
        })
        .catch(reject);
    });
  }

  search_investment_products_contracted_by_customer(query: QueryInvestmentProductsContractedEntity):
    Promise<Array<CustomerInvestmentProductFundTypeEntity>> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}/search-by-customer/?is_active=${query.is_active}`)
        .then((response) => {
          response.json()
            .then(({ success, data, error }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(error));
              }
            });
        })
        .catch(reject);
    });
  }
}
