// Infrastructure
import fixtures from './fixtures.json';
import customer_contracted_investment_products_fixtures from './customer-contracted-investmen-products-fixtures.json';

// Domain
import {
  CustomerInvestmentProductFundTypeRepository,
} from '@/modules/flagship/customer-investment-product-fund-type/domain/repositories/customer-investment-product-fund-type-repository';
import {
  ModerateIsContractedEntity,
} from '@/modules/flagship/customer-investment-product-fund-type/domain/entities/moderate-is-contracted-entity';
import {
  CustomerInvestmentProductFundTypeEntity,
} from '@/modules/flagship/customer-investment-product-fund-type/domain/entities/customer-investment-product-fund-type-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CustomerInvestmentProductFundTypeDummyRepository
implements CustomerInvestmentProductFundTypeRepository {
  get_moderate_is_contracted(): Promise<ModerateIsContractedEntity> {
    return Promise.resolve(fixtures);
  }

  search_investment_products_contracted_by_customer(): Promise<Array<
    CustomerInvestmentProductFundTypeEntity
  >> {
    return Promise.resolve(customer_contracted_investment_products_fixtures);
  }
}
