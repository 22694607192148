import { Container } from 'inversify-props';
import TYPES from './types';
import CurrentUserDummyRepository
  from './infrastructure/repositories/current-user-dummy-repository';
import CurrentUserHttpRepository from './infrastructure/repositories/current-user-http-repository';
import GetCurrentUserQuery from './application/queries/get-current-user-query';
import { CurrentUserRepository } from './domain/repositories/current-user-repository';

export default function setupCurrentUser(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<CurrentUserRepository>(TYPES.CURRENT_USER_REPOSITORY)
    .to(
      withDummies
        ? CurrentUserDummyRepository
        : CurrentUserHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetCurrentUserQuery>(TYPES.GET_CURRENT_USER_QUERY)
    .to(GetCurrentUserQuery)
    .inSingletonScope();
}
