import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import GlobalTrackingDummyRepository from './infrastructure/repositories/global-tracking-dummy-repository/global-tracking-dummy-repository';
import GlobalTrackingHttpRepository from './infrastructure/repositories/global-tracking-http-repository';
import GlobalTrackingVueStateManager from './infrastructure/state/global-tracking-vue-state-manager';

// Application
import GetGlobalTrackingQuery from './application/queries/get-global-tracking-query';

// Domain
import { GlobalTrackingRepository } from './domain/repositories/global-tracking-repository';
import { GlobalTrackingStateManager } from './domain/state/global-tracking-state-manager';

export default function setupGlobalTracking(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<GlobalTrackingStateManager>(TYPES.GLOBAL_TRACKING_STATE_MANAGER)
    .to(GlobalTrackingVueStateManager)
    .inSingletonScope();
  container
    .bind<GlobalTrackingRepository>(
      TYPES.GLOBAL_TRACKING_REPOSITORY,
    ).to(
      withDummies
        ? GlobalTrackingDummyRepository
        : GlobalTrackingHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetGlobalTrackingQuery>(
      TYPES.GET_GLOBAL_TRACKING_QUERY,
    ).to(GetGlobalTrackingQuery)
    .inSingletonScope();
}
