// Infrastructure
import fixtures from './fixtures.json';

// Domain
import {
  EmergencyFundAvailabilityRepository,
} from '@/modules/flagship/catalogs/emergency-fund-availability/domain/repositories/emergency-fund-availability-repository';
import {
  EmergencyFundAvailabilityEntity,
} from '@/modules/flagship/catalogs/emergency-fund-availability/domain/entities/emergency-fund-availability-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class EmergencyFundAvailabilityDummyRepository
implements EmergencyFundAvailabilityRepository {
  execute(): Promise<Array<EmergencyFundAvailabilityEntity>> {
    return Promise.resolve(fixtures);
  }
}
