export default class CustomerPersonalInformationState {
  data = {
    cellphone_country_code: '',
    cellphone: '',
    phone_country_code: '',
    phone: '',
    email: '',
    address: '',
    gender: '',
    date_of_birth: '',
    identity_document_expiration_date: '',
  };

  loaded = false;
}
