// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { TransactionRepository } from '../../../domain/repository';
import { TransactionEntity } from '../../../domain/entities/transaction-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class TransactionDummyRepository implements TransactionRepository {
  findAll(): Promise<Array<TransactionEntity>> {
    return Promise.resolve(fixtures);
  }

  update(): Promise<void> {
    return Promise.resolve();
  }

  cancel(): Promise<void> {
    return Promise.resolve();
  }

  searchByRange(): Promise<Array<TransactionEntity>> {
    return Promise.resolve(fixtures);
  }
}
