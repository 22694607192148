import TYPES from '../../types';

// Domain
import { InvestorGoalsDto } from '../../domain/dtos/investor-goals-dto';
import { LinkSowosPocketRepository } from '../../domain/repositories/link-sowos-pocket-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CreateLinkSowosPocketCommand extends Command<InvestorGoalsDto> {
  @Inject(TYPES.LINK_SOWOS_POCKET_REPOSITORY)
  private readonly repository!: LinkSowosPocketRepository;

  internalExecute(investor_goals_dto: InvestorGoalsDto): Promise<void> {
    return this.repository.create(investor_goals_dto);
  }
}
