// Domain
import { AllianzFundRepository } from '../../domain/repository';
import { AllianzFundEntity } from '../../domain/entities/allianz-fund-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class AllianzFundInMemoryRepository implements AllianzFundRepository {
  get(): Array<AllianzFundEntity> {
    return [
      {
        fund_name: 'SWS - PESOS',
        fund_code: 'SWSRFP',
      },
      {
        fund_name: 'SWS - DÓLARES',
        fund_code: 'SWSRFD',
      },
    ];
  }
}
