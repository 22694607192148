import TYPES from '@/modules/shared/types';

// Domain
import {
  RetirementInvestorGoalRepository,
} from '@/modules/flagship/retirement-investor-goal/domain/repositories/retirement-investor-goal-repository';
import {
  RetirementInvestorGoalEntity,
} from '@/modules/flagship/retirement-investor-goal/domain/entities/retirement-investor-goal-entity';
import {
  RetirementInvestorGoalDto,
} from '@/modules/flagship/retirement-investor-goal/domain/dtos/retirement-investor-goal-dto';
import {
  UpdatePlanRetirementFundDto,
} from '@/modules/flagship/retirement-investor-goal/domain/dtos/update-plan-retirement-fund-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class RetirementInvestorGoalHttpRepository
implements RetirementInvestorGoalRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/retirement-investor-goal/`;

  get(): Promise<RetirementInvestorGoalDto> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(JSON.stringify({
                  status_code: response.status,
                  message_error: message,
                }));
              }
            });
        })
        .catch(reject);
    });
  }

  create(retirement_investor_entity: RetirementInvestorGoalEntity): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}create-or-patch/`, 'POST', retirement_investor_entity)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }

  updatePlan(update_plan_retirement_dto: UpdatePlanRetirementFundDto): Promise<void> {
    return new Promise((resolve, reject) => {
      const { retirement_fund_investor_goal, ...payload } = update_plan_retirement_dto;
      this.http_connector.execute(`${this.endpoint}${retirement_fund_investor_goal}/investment-plan/`, 'PATCH', payload)
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => reject(new Error(message)),
            );
          }
        })
        .catch(reject);
    });
  }
}
