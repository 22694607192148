// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { MaritalStatusRepository } from '../../../domain/repository';
import { MaritalStatusEntity } from '../../../domain/entities/marital-status-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class MaritalStatusDummyRepository implements MaritalStatusRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(): Promise<Array<MaritalStatusEntity>> {
    return Promise.resolve(fixtures);
  }
}
