import TYPES from '../../types';

// Domain
import { ActivateRecurringContributionEntity }
  from '../../domain/entities/activate-recurring-contribution-entity';
import { ActivateRecurringContributionRepository }
  from '../../domain/repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class ActivateRecurringContributionCommand
  extends Command<ActivateRecurringContributionEntity> {
  @Inject(TYPES.ACTIVATE_RECURRING_CONTRIBUTION_REPOSITORY)
  private readonly repository!: ActivateRecurringContributionRepository;

  internalExecute(recurring_contribution: ActivateRecurringContributionEntity) {
    return this.repository.activate(recurring_contribution);
  }
}
