import TYPES from '@/modules/shared/types';

// Domain
import {
  InvestorGoalDetailRepository,
} from '@/modules/flagship/investor-goal/investor-goal-detail/domain/repositories/investor-goal-detail-repository';
import {
  InvestorGoalDetailEntity,
} from '@/modules/flagship/investor-goal/investor-goal-detail/domain/entities/investor-goal-detail-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class InvestorGoalDetailHttpRepository implements InvestorGoalDetailRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/investor-goal/sowos-wealth/{investor_goal_id}/`;

  get_detail(investor_goal_id: string): Promise<InvestorGoalDetailEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint.replace('{investor_goal_id}', investor_goal_id))
        .then((response) => {
          response.json()
            .then(({ success, data, error }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(error));
              }
            });
        })
        .catch(reject);
    });
  }
}
