// Domain
import { InvestorProfileOnEmergencyFundRepository } from '../../domain/repositories/investor-profile-on-emergency-fund-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class InvestorProfileOnEmergencyFundDummyRepository
implements InvestorProfileOnEmergencyFundRepository {
  update(): Promise<any> {
    return Promise.resolve();
  }
}
