import EMAILS_TYPES from './emails/types';
import PERSON_TYPES from './person/types';
import CATALOGS_TYPES from './catalogs/types';
import STEPS_TYPES from './steps/types';
import VERIFY_NIP_TYPES from './verify-nip/types';
import RESEND_VERIFY_NIP_TYPES from './renew-verify-nip/types';
import CUSTOMER_AGREEMENTS from './customer-agreements/types';
import IDENTITY_VERIFICATION_TYPES from './identity-verification/types';
import STATUS_TYPES from './status/types';
import CUSTOMER_CELLPHONE_TYPES from './customer-cellphone/types';
import PREFERRED_MEANS_OF_CONTACT_TYPES from './preferred-means-of-contact/types';
import CUSTOMER_DOCUMENT_TYPES from './customer-document/types';

export default {
  ...EMAILS_TYPES,
  ...PERSON_TYPES,
  ...CATALOGS_TYPES,
  ...STEPS_TYPES,
  ...VERIFY_NIP_TYPES,
  ...RESEND_VERIFY_NIP_TYPES,
  ...CUSTOMER_AGREEMENTS,
  ...IDENTITY_VERIFICATION_TYPES,
  ...STATUS_TYPES,
  ...CUSTOMER_CELLPHONE_TYPES,
  ...PREFERRED_MEANS_OF_CONTACT_TYPES,
  ...CUSTOMER_DOCUMENT_TYPES,
};
