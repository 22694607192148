import { Container } from 'inversify-props';
import setupEconomicDependents from './economic-dependents/setup';
import setupEmploymentSituation from './employment-situation/setup';
import setupEmergencyFund from './emergency-fund/setup';
import setupPersonalInfo from './personal-info/setup';
import setupCustomGoal from './custom-goal/setup';
import setupRecommendedInvestmentProduct from './recommended-investment-product/setup';
import setupRetirementBudget from './retirement-budget/setup';
import setupInvestorProfile from './investor-profile/setup';
import setupFlowCompleted from './flow-completed/setup';
import setupFlowShowed from './flow-showed/setup';
import setupIncompleteProfile from './incomplete-profile/setup';
import SetupOverallGoalsStatus from './overall-goals-status/setup';

export default function setupInvestorProfileModule(container: Container) {
  setupEconomicDependents(container);
  setupEmploymentSituation(container);
  setupEmergencyFund(container);
  setupPersonalInfo(container);
  setupCustomGoal(container);
  setupRecommendedInvestmentProduct(container);
  setupRetirementBudget(container);
  setupFlowCompleted(container);
  setupFlowShowed(container);
  setupInvestorProfile(container);
  setupIncompleteProfile(container);
  SetupOverallGoalsStatus(container);
}
