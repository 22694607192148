import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import IncompleteProfileVueStateManager
  from '@/modules/flagship/investor-profile/incomplete-profile/infraestructure/state/incomplete-profile-vue-state-manager';
import IncompleteProfileDummyRepository
  from '@/modules/flagship/investor-profile/incomplete-profile/infraestructure/repositories/incomplete-profile-dummy-repository/incomplete-profile-dummy-repository';
import IncompleteProfileHttpRepository
  from '@/modules/flagship/investor-profile/incomplete-profile/infraestructure/repositories/incomplete-profile-http-repository';

// Application
import GetIncompleteProfileQuery
  from '@/modules/flagship/investor-profile/incomplete-profile/application/queries/get-incomplete-profile-query';
import UpdateIncompleteProfileCommand
  from '@/modules/flagship/investor-profile/incomplete-profile/application/commands/update-incomplete-profile-command';

// Domain
import {
  IncompleteProfileRepository,
} from '@/modules/flagship/investor-profile/incomplete-profile/domain/repositories/incomplete-profile-repository';
import {
  IncompleteProfileStateManager,
} from '@/modules/flagship/investor-profile/incomplete-profile/domain/state/incomplete-profile-state-manager';

export default function setupIncompleteProfile(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<IncompleteProfileStateManager>(TYPES.INCOMPLETE_PROFILE_STATE_MANAGER)
    .to(IncompleteProfileVueStateManager)
    .inSingletonScope();

  container
    .bind<IncompleteProfileRepository>(TYPES.INCOMPLETE_PROFILE_REPOSITORY)
    .to(
      withDummies
        ? IncompleteProfileDummyRepository
        : IncompleteProfileHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetIncompleteProfileQuery>(TYPES.GET_INCOMPLETE_PROFILE_QUERY)
    .to(GetIncompleteProfileQuery)
    .inSingletonScope();

  container
    .bind<UpdateIncompleteProfileCommand>(
      TYPES.UPDATE_INCOMPLETE_PROFILE_COMMAND,
    ).to(UpdateIncompleteProfileCommand)
    .inSingletonScope();
}
