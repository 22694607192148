// Domain
import {
  InvestmentGoalsEntity,
} from '@/modules/flagship/catalogs/investment-goals/domain/entities/investment-goals-entity';

export default class InvestmentGoalsState {
  loaded = false;

  items: Array<InvestmentGoalsEntity> = [];
}
