import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import { CustomerCellphoneHttpRepository, CustomerCellphoneDummyRepository }
  from './infrastructure/repositories';

// Application
import { CreateCustomerCellphoneCommand }
  from './application/commands/create-customer-cellphone-command';
import { GetCustomerCellphoneQuery }
  from './application/queries/get-customer-cellphone-query';

// Domain
import { CustomerCellphoneRepository }
  from './domain/repository/customer-cellphone-respository';
import CustomerCellphoneStateManager from './domain/state/customer-cellphone-state-manager';

export default function setupCustomerCellphone(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<CustomerCellphoneStateManager>(TYPES.CUSTOMER_CELLPHONE_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<CustomerCellphoneRepository>(TYPES.CUSTOMER_CELLPHONE_REPOSITORY)
    .to(
      withDummies
        ? CustomerCellphoneDummyRepository
        : CustomerCellphoneHttpRepository,
    ).inSingletonScope();

  container
    .bind<CreateCustomerCellphoneCommand>(TYPES.CREATE_CUSTOMER_CELLPHONE_COMMAND)
    .to(CreateCustomerCellphoneCommand)
    .inSingletonScope();

  container
    .bind<GetCustomerCellphoneQuery>(TYPES.GET_CUSTOMER_CELLPHONE_QUERY)
    .to(GetCustomerCellphoneQuery)
    .inSingletonScope();
}
