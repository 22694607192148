// Domain
import {
  CustomerInvestorGoalReminderEntity,
} from '@/modules/flagship/customer-investor-goal-reminder/domain/entities/customer-investor-goal-reminder-entity';

export default class CustomerInvestorGoalReminderState {
  loaded = false;

  items: CustomerInvestorGoalReminderEntity = {
    id: '',
    customer_id: '',
    investment_product_id: '',
    day_to_remind: 0,
    is_subscribed: false,
  };
}
