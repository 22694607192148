import TYPES from '../../types';

// Domain
import { SourceIncomeEntity } from '../../domain/entities/source-income-entity';
import { SourceIncomeRepository } from '../../domain/repository/source-income-repository';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class SearchSourcesIncomesQuery extends Query<Array<SourceIncomeEntity>> {
  @Inject(TYPES.CATALOG_ALLIANZ_SOURCE_INCOME_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.CATALOG_ALLIANZ_SOURCE_INCOME_REPOSITORY)
  private readonly repository!: SourceIncomeRepository;

  internalExecute() {
    if (!this.state_manager.state.loaded) {
      this.repository.execute()
        .then((items) => {
          this.state_manager.patch({ items, loaded: true });
        });
    }

    return this.state_manager.state.items;
  }
}
