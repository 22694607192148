import { Container } from 'inversify-props';
import TYPES from './types';
import VueStateManager from './infrastructure/state/vue-state-manager';
import SearchEmailDummyRepository
  from './infrastructure/repository/search-email-repository/search-email-dummy-repository';
import SearchEmailHttpRepository
  from './infrastructure/repository/search-email-repository/search-email-http-repository';
import OnBoardingSearchEmailsQuery from './application/queries/on-boarding-search-emails-query';
import StateManager from './domain/state/state-manager';
import SearchEmailRepository from './domain/repository/search-email-repository';

export default function setupEmails(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.ON_BOARDING_EMAIL_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<SearchEmailRepository>(TYPES.ON_BOARDING_SEARCH_EMAIL_REPOSITORY)
    .to(
      withDummies
        ? SearchEmailDummyRepository
        : SearchEmailHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<OnBoardingSearchEmailsQuery>(TYPES.ON_BOARDING_SEARCH_EMAILS_QUERY)
    .to(OnBoardingSearchEmailsQuery)
    .inSingletonScope();
}
