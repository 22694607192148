import answers_initial_poll_fixtures from './answers-initial-poll-fixtures.json';
import answers_exit_poll_fixtures from './answers-exit-poll-fixtures.json';

// Domain
import {
  PollResultEntity,
} from '@/modules/flagship/contact-points/sowos-moderate/domain/entities/poll-result-entity';
import {
  ContactPointSowosModerateRepository,
} from '@/modules/flagship/contact-points/sowos-moderate/domain/repositories/contact-point-sowos-moderate-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class ContactPointSowosModerateDummyRepository
implements ContactPointSowosModerateRepository {
  getModerateInformation(): Promise<void> {
    return Promise.resolve();
  }

  createSurveyNotReadyToContractModerate(): Promise<void> {
    return Promise.resolve();
  }

  searchAnswersInitialPoll(): Promise<Array<PollResultEntity>> {
    return Promise.resolve(answers_initial_poll_fixtures.data);
  }

  searchAnswersExitPoll(): Promise<Array<PollResultEntity>> {
    return Promise.resolve(answers_exit_poll_fixtures.data);
  }

  createResponseUnlinkGoalFromModerate(): Promise<void> {
    return Promise.resolve();
  }
}
