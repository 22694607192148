import TYPES from '../../types';

// Domain
import {
  InvestorProfileEntity,
} from '@/modules/flagship/investor-profile/investor-profile/domain/entities/investor-profile-entity';
import {
  InvestorProfileOnFlowShowedRepository,
} from '@/modules/flagship/investor-profile/flow-showed/domain/repositories/investor-profile-on-flow-showed-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UpdateInvestorProfileOnFlowShowedCommand
  extends Command<InvestorProfileEntity> {
  @Inject(TYPES.INVESTOR_PROFILE_ON_FLOW_SHOWED_REPOSITORY)
  private readonly repository!: InvestorProfileOnFlowShowedRepository;

  internalExecute(investor_profile_entity: InvestorProfileEntity) {
    return this.repository.update(investor_profile_entity);
  }
}
