import { AllianzOperationDatesEntity } from '../entities/allianz-operation-dates-entity';

export default class State {
  loaded_withdrawal = false;

  loaded_deposit = false;

  withdrawal_operation_dates: AllianzOperationDatesEntity = {
    application_operation_date: '',
    accreditation_operation_date: '',
    estimated_collection_date: '',
    estimated_reflected_date: '',
    last_updated_hour: undefined,
  };

  deposit_operation_dates: AllianzOperationDatesEntity = {
    application_operation_date: '',
    accreditation_operation_date: '',
    estimated_collection_date: '',
    estimated_reflected_date: '',
    last_updated_hour: undefined,
  };
}
