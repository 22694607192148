import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import InvestmentProductFundTypeDummyRepository
  from './infrastructure/repositories/investment-product-fund-type-dummy-repository/investment-product-fund-type-dummy-repository';
import InvestmentProductFundTypeHttpRepository
  from './infrastructure/repositories/investment-product-fund-type-http-repository';

// Application
import SearchInvestmentProductFundTypeByCustomerQuery from './application/queries/search-investment-product-fund-type-by-customer-query';

// Domain
import {
  InvestmentProductFundTypeRepository,
} from './domain/repositories/investment-product-fund-type-repository';

export default function setupInvestmentProductFundType(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<InvestmentProductFundTypeRepository>(
      TYPES.ALLIANZ_INVESTMENT_PRODUCT_FUND_TYPE_REPOSITORY,
    ).to(
      withDummies
        ? InvestmentProductFundTypeDummyRepository
        : InvestmentProductFundTypeHttpRepository,
    ).inSingletonScope();

  container
    .bind<SearchInvestmentProductFundTypeByCustomerQuery>(
      TYPES.SEARCH_INVESTMENT_PRODUCT_FUND_TYPE_BY_CUSTOMER_QUERY,
    ).to(SearchInvestmentProductFundTypeByCustomerQuery)
    .inSingletonScope();
}
