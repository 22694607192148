export default class LinkedGoalState {
  is_loading = false;

  goal = {
    investor_goal_id: '',
    associated_product_id: '',
    associated_product_name: '',
    goal_name: '',
    goal_icon: '',
    custom_icon_file_id: '',
    goal_icon_alt: '',
    contributed_amount: '',
  };
}
