import Vue from 'vue';

// Domain
import {
  InvestmentSuggestionStateManager,
} from '@/modules/investment-suggestion/domain/state/investment-suggestion-state-manager';
import InvestmentSuggestionState
  from '@/modules/investment-suggestion/domain/state/investment-suggestion-state';
import Injectable from '@/modules/shared/domain/di/injectable';
import Observer from '@/modules/shared/domain/observer/observer';

@Injectable()
export default class InvestmentSuggestionVueStateManager
implements InvestmentSuggestionStateManager {
  // eslint-disable-next-line max-len
  private _state: InvestmentSuggestionState = Vue.observable<InvestmentSuggestionState>(new InvestmentSuggestionState());

  private readonly observers: Observer[] = [];

  get state(): InvestmentSuggestionState {
    return this._state;
  }

  set state(value: InvestmentSuggestionState) {
    this._state = value;
    this.notifyAll();
  }

  patch(state: Partial<InvestmentSuggestionState>) {
    type Keys = keyof InvestmentSuggestionState;
    Object.entries(state).forEach(([key, value]) => {
      const accessor = key as Keys;

      if (value !== undefined) {
        // eslint-disable-next-line
        // @ts-ignore @typescript-eslint/ban-ts-ignore
        this.state[accessor] = value;
      }
    });
  }

  notifyAll() {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer) {
    this.observers.push(observer);
  }
}
