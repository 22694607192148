import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import OverallInvestmentAmountDummyRepository from '@/modules/flagship/overall-investment-amount-calculation/infrastructure/overall-investment-amount-dummy-repository';
import OverallInvestmentAmountHttpRepository from '@/modules/flagship/overall-investment-amount-calculation/infrastructure/overall-investment-amount-http-repository';

// Application
import CalculateOverallInvestmentAmountQuery from '@/modules/flagship/overall-investment-amount-calculation/application/queries/calculate-overall-investment-amount-query';

// Domain
import { OverallInvestmentAmountRepository } from '@/modules/flagship/overall-investment-amount-calculation/domain/repositories/overall-investment-amount-repository';

export default function setupOverallInvestmentAmountCalculation(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<OverallInvestmentAmountRepository>(TYPES.OVERALL_INVESTMENT_AMOUNT_REPOSITORY)
    .to(
      withDummies ? OverallInvestmentAmountDummyRepository : OverallInvestmentAmountHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<CalculateOverallInvestmentAmountQuery>(TYPES.CALCULATE_OVERALL_INVESTMENT_AMOUNT_QUERY)
    .to(CalculateOverallInvestmentAmountQuery)
    .inSingletonScope();
}
