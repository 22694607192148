import Vue from 'vue';

// Domain
import {
  CustomInvestorGoalStateManager,
} from '@/modules/flagship/custom-investor-goal/domain/state/custom-investor-goal-state-manager';
import CustomInvestorGoalState
  from '@/modules/flagship/custom-investor-goal/domain/state/custom-investor-goal-state';
import Observer from '@/modules/shared/domain/observer/observer';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CustomInvestorGoalVueStateManager implements CustomInvestorGoalStateManager {
  private _state: CustomInvestorGoalState = Vue.observable<CustomInvestorGoalState>(
    new CustomInvestorGoalState(),
  );

  private readonly observers: Observer[] = [];

  get state(): CustomInvestorGoalState {
    return this._state;
  }

  set state(value: CustomInvestorGoalState) {
    this._state = value;
  }

  patch(state: Partial<CustomInvestorGoalState>) {
    type Keys = keyof CustomInvestorGoalState;
    Object.entries(state).forEach(([key, value]) => {
      const accessor = key as Keys;

      if (value !== undefined) {
        // eslint-disable-next-line
        // @ts-ignore @typescript-eslint/ban-ts-ignore
        this.state[accessor] = value;
      }
    });
  }

  notifyAll(): void {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer): void {
    this.observers.push(observer);
  }
}
