import TYPES from '../../types';

// Domain
import { CreateTransactionDto } from '../../domain/dtos/create-transaction-dto';
import { CreateTransactionRepository } from '../../domain/repository/create-transaction-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CreateTransactionCommand extends Command<CreateTransactionDto> {
  @Inject(TYPES.CREATE_ALLIANZ_TRANSACTION_REPOSITORY)
  private readonly repository!: CreateTransactionRepository;

  internalExecute(transaction_dto: CreateTransactionDto) {
    return this.repository.create(transaction_dto);
  }
}
