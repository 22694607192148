import TYPES from '../../types';

// Domain
import { Status } from '../../domain/dtos';
import { OnBoardingStatusStateManager } from '../../domain/state';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetMyInvestmentAllianzOnBoardingStatusQuery extends Query<Status> {
  @Inject(TYPES.MY_INVESTMENT_ALLIANZ_ON_BOARDING_STATUS_STATE_MANAGER)
  private readonly on_boarding_status_state_manager!: OnBoardingStatusStateManager;

  internalExecute() {
    return this.on_boarding_status_state_manager.state.data;
  }
}
