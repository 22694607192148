import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import { VueStateManager } from './infrastructure/state';
import { PersonRestRepository, PersonDummyRepository }
  from './infrastructure/repositories/person-repository';

// Application
import { GetCustomerPersonQuery } from './application/queries';

// Domain
import { StateManager } from './domain/state';
import { PersonRepository } from './domain/repositories';

export default function setupCustomerPersonModule(container: Container) {
  const with_dummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<StateManager>(TYPES.CUSTOMER_PERSON_STATE_MANAGER)
    .to(VueStateManager)
    .inRequestScope();

  container
    .bind<PersonRepository>(TYPES.CUSTOMER_PERSON_REPOSITORY)
    .to(
      with_dummies
        ? PersonDummyRepository
        : PersonRestRepository,
    ).inRequestScope();

  container
    .bind<GetCustomerPersonQuery>(TYPES.GET_CUSTOMER_PERSON_QUERY)
    .to(GetCustomerPersonQuery)
    .inRequestScope();
}
