import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import ProductInformationHttpRepository
  from '@/modules/flagship/investment-product/product-information/infraestructure/repositories/product-information-http-repository';
import ProductInformationDummyRepository
  from '@/modules/flagship/investment-product/product-information/infraestructure/repositories/product-information-dummy-repository';

// Application
import CreateProductInformationCommand
  from '@/modules/flagship/investment-product/product-information/application/commands/create-product-information-command';

// Domain
import {
  ProductInformationRepository,
} from '@/modules/flagship/investment-product/product-information/domain/repositories/product-information-repository';

export default function setupRecommendedProductFlagship(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<ProductInformationRepository>(
      TYPES.PRODUCT_INFORMATION_REPOSITORY,
    ).to(
      withDummies
        ? ProductInformationDummyRepository
        : ProductInformationHttpRepository,
    ).inSingletonScope();

  container
    .bind<CreateProductInformationCommand>(
      TYPES.CREATE_PRODUCT_INFORMATION_COMMAND,
    ).to(CreateProductInformationCommand)
    .inSingletonScope();
}
