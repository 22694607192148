import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import CreateOnBoardingInviteFriendDummyRepository
  from '@/modules/home-dashboard/invite-friend/infrastructure/repositories/create-on-boarding-invite-friend-dummy-repository';
import CreateOnBoardingInviteFriendHttpRepository
  from '@/modules/home-dashboard/invite-friend/infrastructure/repositories/create-on-boarding-invite-friend-http-repository';

// Application
import CreateOnBoardingInviteFriendCommand
  from '@/modules/home-dashboard/invite-friend/application/commands/create-on-boarding-invite-friend-command';

// Domain
import { CreateOnBoardingInviteFriendRepository }
  from '@/modules/home-dashboard/invite-friend/domain/repositories/create-on-boarding-invite-friend-repository';

export default function setupInviteFriend(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<CreateOnBoardingInviteFriendRepository>(TYPES.CREATE_ON_BOARDING_INVITE_FRIEND_REPOSITORY)
    .to(
      withDummies
        ? CreateOnBoardingInviteFriendDummyRepository
        : CreateOnBoardingInviteFriendHttpRepository,
    ).inSingletonScope();

  container
    .bind<CreateOnBoardingInviteFriendCommand>(TYPES.CREATE_ON_BOARDING_INVITE_FRIEND_COMMAND)
    .to(CreateOnBoardingInviteFriendCommand)
    .inSingletonScope();
}
