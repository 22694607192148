import TYPES from '@/modules/shared/types';

// Domain
import { MagicLinkRepository } from '../../domain/repositories/magic-link-repository';
import { MagicLinkEntity } from '../../domain/entities/magic-link-entity';
import { MagicLinkDto } from '../../domain/dtos/magic-link-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class MagicLinkHttpRepository implements MagicLinkRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/onboarding/customer-document/magic-link/verify/?token={token}`;

  verify_token(search_by_customer_dto: MagicLinkDto): Promise<MagicLinkEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint.replace('{token}', search_by_customer_dto.token))
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
