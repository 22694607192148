import TYPES from '@/modules/shared/types';

// Domain
import { GetCustomerWithdrawalDetailsRepository } from '../../domain/repository';
import { CustomerWithdrawalDetailsEntity } from '../../domain/entities/customer-withdrawal-details-entity';
import { CustomerWithdrawalDetailsQueryParamsDto } from '../../domain/dtos/customer-withdrawal-details-query-params-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class GetCustomerWithdrawalDetailsHttpRepository
implements GetCustomerWithdrawalDetailsRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/kuspit/account/withdrawal-details/`;

  get(params: CustomerWithdrawalDetailsQueryParamsDto): Promise<CustomerWithdrawalDetailsEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}${params.latitude}/${params.longitude}/`)
        .then((response) => {
          response.json()
            .then(({ data, success, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
