// Infrastructure
import fixtures from './fixtures.json';

// Domain
import {
  GoalDefinitionStepRepository,
} from '@/modules/flagship/goal-definition-step/domain/repositories/goal-definition-step-repository';
import {
  GoalDefinitionStepEntity,
} from '@/modules/flagship/goal-definition-step/domain/entities/goal-definition-step-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class GoalDefinitionStepDummyRepository implements GoalDefinitionStepRepository {
  get(): Promise<GoalDefinitionStepEntity> {
    return Promise.resolve(fixtures);
  }

  update(): Promise<any> {
    return Promise.resolve();
  }
}
