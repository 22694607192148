import Vue from 'vue';

// Domain
import { CustomerInvestmentProductFundTypeStateManager } from '@/modules/flagship/customer-investment-product-fund-type/domain/state/customer-investment-product-fund-type-state-manager';
import CustomerInvestmentProductFundTypeState from '@/modules/flagship/customer-investment-product-fund-type/domain/state/customer-investment-product-fund-type-state';
import Injectable from '@/modules/shared/domain/di/injectable';
import Observer from '@/modules/shared/domain/observer/observer';

@Injectable()
export default class CustomerInvestmentProductFundTypeVueStateManager implements
  CustomerInvestmentProductFundTypeStateManager {
  // eslint-disable-next-line max-len
  private _state: CustomerInvestmentProductFundTypeState = Vue.observable<CustomerInvestmentProductFundTypeState>(new CustomerInvestmentProductFundTypeState());

  private readonly observers: Observer[] = [];

  get state(): CustomerInvestmentProductFundTypeState {
    return this._state;
  }

  set state(value: CustomerInvestmentProductFundTypeState) {
    this._state = value;
    this.notifyAll();
  }

  patch(state: Partial<CustomerInvestmentProductFundTypeState>) {
    type Keys = keyof CustomerInvestmentProductFundTypeState;
    Object.entries(state).forEach(([key, value]) => {
      const accessor = key as Keys;

      if (value !== undefined) {
        // eslint-disable-next-line
        // @ts-ignore @typescript-eslint/ban-ts-ignore
        this.state[accessor] = value;
      }
    });
  }

  notifyAll() {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer) {
    this.observers.push(observer);
  }
}
