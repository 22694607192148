import TYPES from '../../types';

// Domain
import { Geocoder } from '../../domain/geocoder';
import { CoordinatesDto, GeocodeResponse } from '../../domain/dtos';
import HttpConnector from '../../domain/connectors/http';
import Injectable from '../../domain/di/injectable';
import Inject from '../../domain/di/inject';

@Injectable()
export default class GoogleMapsGeocoder implements Geocoder {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly api_key: string;

  public constructor(api_key = undefined) {
    this.api_key = api_key || process.env.VUE_APP_GOOGLE_GEOCODE_API_KEY as string;
  }

  reverse_geocode(coordinates: CoordinatesDto): Promise<GeocodeResponse> {
    const endpoint = 'https://maps.googleapis.com/maps/api/geocode/json?latlng={LAT},{LNG}&key={API_KEY}'
      .replace('{LAT}', coordinates.latitude.toString())
      .replace('{LNG}', coordinates.longitude.toString())
      .replace('{API_KEY}', this.api_key);

    return new Promise((resolve, reject) => {
      this.http_connector.execute(
        endpoint,
        'GET',
        undefined,
        3,
        false,
        {},
      )
        .then((response) => {
          response.json()
            .then(({ results }) => {
              resolve(results[0]);
            });
        })
        .catch(reject);
    });
  }

  geocode(address: string): Promise<GeocodeResponse> {
    const endpoint = 'https://maps.googleapis.com/maps/api/geocode/json?address={ADDRESS},{LNG}&key={API_KEY}'
      .replace('{ADDRESS}', encodeURI(address))
      .replace('{API_KEY}', this.api_key);

    return new Promise((resolve, reject) => {
      this.http_connector.execute(
        endpoint,
        'GET',
        undefined,
        3,
        false,
        {},
      )
        .then((response) => {
          response.json()
            .then(({ results }) => {
              resolve({
                ...results[0],
                geometry: {
                  ...results[0].geometry,
                  location: {
                    latitude: results[0].geometry.location.lat,
                    longitude: results[0].geometry.location.lng,
                  },
                },
              });
            });
        })
        .catch(reject);
    });
  }
}
