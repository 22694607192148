// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { InvestmentRepository } from '../../../domain/repository';
import { InvestmentEntity } from '../../../domain/entities/investment-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class InvestmentDummyRepository implements InvestmentRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(): Promise<Array<InvestmentEntity>> {
    return Promise.resolve(fixtures);
  }
}
