import { Container } from 'inversify-props';
import TYPES from './types';
import VueStateManager from './infrastructure/state/vue-state-manager';
import SearchNationalitiesDummyRepository
  from './infrastructure/repositories/search-nationalities-repository/search-nationalities-dummy-repository';
import SearchNationalitiesHttpRepository
  from './infrastructure/repositories/search-nationalities-repository/search-nationalities-http-repository';
import OnBoardingSearchNationalitiesQuery from './application/queries/on-boarding-search-nationalities-query';
import StateManager from './domain/state/state-manager';
import SearchNationalitiesRepository from './domain/repositories/search-nationalities-repository';

export default function setupNationalities(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.ON_BOARDING_CATALOGS_NATIONALITIES_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<SearchNationalitiesRepository>(TYPES.ON_BOARDING_CATALOGS_SEARCH_NATIONALITIES_REPOSITORY)
    .to(
      withDummies
        ? SearchNationalitiesDummyRepository
        : SearchNationalitiesHttpRepository,
    ).inSingletonScope();

  container
    .bind<OnBoardingSearchNationalitiesQuery>(TYPES.ON_BOARDING_SEARCH_NATIONALITIES_QUERY)
    .to(OnBoardingSearchNationalitiesQuery)
    .inSingletonScope();
}
