import TYPES from '../../types';

// Domain
import {
  SurveyResponseNotReadyToContractModerateDto,
} from '@/modules/flagship/contact-points/sowos-moderate/domain/dtos/survey-response-not-ready-to-contract-moderate-dto';
import {
  ContactPointSowosModerateRepository,
} from '@/modules/flagship/contact-points/sowos-moderate/domain/repositories/contact-point-sowos-moderate-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CreateResponseNotReadyToContractCommand
  extends Command<SurveyResponseNotReadyToContractModerateDto> {
  @Inject(TYPES.CONTACT_POINT_SOWOS_MODERATE_REPOSITORY)
  private readonly repository!: ContactPointSowosModerateRepository;

  internalExecute(
    survey_response_not_ready_to_contract_moderate: SurveyResponseNotReadyToContractModerateDto,
  ): Promise<void> {
    return this.repository.createSurveyNotReadyToContractModerate(
      survey_response_not_ready_to_contract_moderate,
    );
  }
}
