import TYPES from '../../types';

// Domain
import {
  SearchByCustomerRepository,
} from '../../domain/repositories/search-by-customer-repository';
import {
  ActiveGoalAmountByFundTypeEntity,
} from '@/modules/flagship/investor-goal/search-by-customer/domain/entities/active-goal-amount-by-fund-type-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class GetActiveGoalAmountByFundTypeQuery extends
  Query<Promise<Array<ActiveGoalAmountByFundTypeEntity>>> {
  @Inject(TYPES.SEARCH_BY_CUSTOMER_REPOSITORY)
  private readonly repository!: SearchByCustomerRepository;

  internalExecute(investment_product_fund_type_id: string):
    Promise<Array<ActiveGoalAmountByFundTypeEntity>> {
    return new Promise((resolve, reject) => {
      this.repository.get_active_goals_amounts_by_fund_type(investment_product_fund_type_id)
        .then((items) => {
          resolve(items);
        })
        .catch(reject);
    });
  }
}
