import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import {
  GetCustomerKuspitPositionDetailsQuery,
} from '@/modules/my-investment/kuspit/customer-kuspit-position/application/queries';
import {
  GetHasKuspitAccountQuery,
} from '@/modules/my-investment/kuspit/customer-has-account/application/queries';
import GetCustomerInvestorGoalReminderQuery
  from '@/modules/flagship/customer-investor-goal-reminder/application/queries/get-customer-investor-goal-reminder-query';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class AccountBalanceViewModel {
  @Inject(TYPES.GET_CUSTOMER_KUSPIT_POSITION_DETAILS_QUERY)
  private readonly get_customer_kuspit_position_details_query!:
    GetCustomerKuspitPositionDetailsQuery;

  @Inject(TYPES.GET_CUSTOMER_INVESTOR_GOAL_REMINDER_QUERY)
  private readonly get_customer_investor_goal_reminder_query!: GetCustomerInvestorGoalReminderQuery;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.GET_CUSTOMER_HAS_KUSPIT_ACCOUNT_QUERY)
  readonly get_has_kuspit_account_query!: GetHasKuspitAccountQuery;

  readonly i18n_namespace = 'components.kuspit-dashboard.account_balance';

  contract_number = '';

  total_value = 0;

  available_balance = 0;

  name = '';

  account_number = '';

  load_kuspit_finished = false;

  // TODO - Get this id from an endpoint when backend develops it
  associated_product_id = 'd08f3b22-af12-4ed2-86f2-141d7d62ca60';

  user_has_monthly_reminder = false;

  show_modal_edit_reminder = false;

  day_to_remind = 0;

  is_subscribed = false;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async () => {
    await this.loadKuspitAccount();
    await this.loadCustomerInvestorGoalReminder();
  }

  get position_kuspit() {
    return this.get_customer_kuspit_position_details_query.execute();
  }

  get has_kuspit_account() {
    return this.get_has_kuspit_account_query.execute();
  }

  get total_value_formatted() {
    return currencyFormat(this.total_value);
  }

  get available_balance_formatted() {
    return currencyFormat(this.available_balance);
  }

  loadKuspitAccount = async () => {
    try {
      const has_kuspit_account = await this.get_has_kuspit_account_query.execute();
      if (has_kuspit_account.has_account) {
        await this.fillData();
        this.load_kuspit_finished = true;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_kuspit_account'));
    }
  }

  fillData = async () => {
    try {
      const kuspit_position = await this.get_customer_kuspit_position_details_query.execute();
      if (kuspit_position) {
        this.contract_number = kuspit_position.contract_id;
        this.total_value = Number(kuspit_position.position);
        this.available_balance = Number(kuspit_position.cash);
        this.name = kuspit_position.customer_name;
        this.account_number = kuspit_position.clabe;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.fill_data'));
    }
  }

  loadCustomerInvestorGoalReminder = async (reload = false) => {
    try {
      // eslint-disable-next-line max-len
      const customer_investor_goal_reminder = await this.get_customer_investor_goal_reminder_query.execute({
        associated_product_id: this.associated_product_id,
        reload,
      });
      if (customer_investor_goal_reminder) {
        this.day_to_remind = customer_investor_goal_reminder.day_to_remind;
        this.user_has_monthly_reminder = true;
        this.is_subscribed = customer_investor_goal_reminder.is_subscribed || false;
      }
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_customer_investor_goal_reminder'));
      }
    }
  };

  showModalEditReminder = () => {
    this.show_modal_edit_reminder = true;
  }

  addReloadMonthlyReminderInformationEventListener = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    window.addEventListener('reload.pocket.monthly.reminder.information', this.loadCustomerInvestorGoalReminder(true));
  }

  removeReloadMonthlyReminderInformationEventListener = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    window.removeEventListener('reload.pocket.monthly.reminder.information', this.loadCustomerInvestorGoalReminder(true));
  }
}
