import fixtures from './fixtures.json';
import Injectable from '@/modules/shared/domain/di/injectable';
import SearchNationalitiesRepository
  from '../../../../domain/repositories/search-nationalities-repository';

@Injectable()
export default class SearchNationalitiesDummyRepository implements SearchNationalitiesRepository {
  // eslint-disable-next-line class-methods-use-this
  findAll() {
    return Promise.resolve(fixtures);
  }
}
