import { NavigationGuardNext, Route } from 'vue-router';

// Application
import { ValidateJwtCommand } from '@/modules/authentication/application/commands';

const validateJwtCommand = new ValidateJwtCommand();

export default async function validateQueryToken(
  to: Route, from: Route, next: NavigationGuardNext,
) {
  try {
    const auth_token = to.query?.token;

    if (!auth_token) {
      return next({ path: '/login' });
    }
    await validateJwtCommand.execute(auth_token.toString());
    return next();
  } catch {
    return next({ path: '/login' });
  }
}
