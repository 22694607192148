import TYPES from '../../types';

// Domain
import { CurrentAmountEntity } from '../../domain/entities/current-amount-entity';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class EditEmergencyFundGoalPlanCurrentAmountService {
  @Inject(TYPES.EDIT_EMERGENCY_FUND_GOAL_PLAN_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  setCurrentAmountInformation(current_amount: CurrentAmountEntity): void {
    this.state_manager.state.current_amount = { ...current_amount };
  }

  getCurrentAmountInformation(): CurrentAmountEntity {
    return this.state_manager.state.current_amount;
  }
}
