// Domain
import { UpdatePersonRepository } from '../../../domain/repository/update-person-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class UpdatePersonDummyRepository implements UpdatePersonRepository {
  // eslint-disable-next-line
  update(): Promise<any> {
    return Promise.resolve();
  }
}
