import modifySwsModerateDialog from './modify-sws-moderate-dialog.json';
import ModifySwsModerateWealthExitPoll from './modify-sws-moderate-wealth-exit-poll.json';
import modifySwsModerateScheduleCall from './modify-sws-moderate-schedule-call.json';

export default {
  'modify-sws-moderate': {
    ...modifySwsModerateDialog,
    ...ModifySwsModerateWealthExitPoll,
    ...modifySwsModerateScheduleCall,
  },
};
