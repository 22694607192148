import TYPES from '../../types';

// Domain
import { AllianzDownloadAccountStatementDto } from '../../domain/dtos/allianz-download-account-statement-dto';
import { AllianzAccountStatementDownloadedEntity } from '../../domain/entities/allianz-account-statement-downloaded-entity';
import { AllianzAccountStatementRepository } from '../../domain/repository/allianz-account-statement-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class DownloadAllianzAccountStatementQuery
  extends Query<Promise<AllianzAccountStatementDownloadedEntity>> {
  @Inject(TYPES.ALLIANZ_ACCOUNT_STATEMENT_REPOSITORY)
  private readonly repository!: AllianzAccountStatementRepository;

  internalExecute(download_account_statement: AllianzDownloadAccountStatementDto):
    Promise<AllianzAccountStatementDownloadedEntity> {
    return new Promise((resolve, reject) => {
      this.repository.download(download_account_statement)
        .then((allianz_account_statement_downloaded) => {
          resolve(allianz_account_statement_downloaded);
        })
        .catch(reject);
    });
  }
}
