import TYPES from '../../types';

// Domain
import { FundingDetailsDto } from '../../domain/dtos/funding-details-dto';
import { FundingDetailsRepository } from '../../domain/repository/funding-details-repository';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetFundingDetailsQuery extends Query<Promise<FundingDetailsDto>> {
  @Inject(TYPES.KUSPIT_FUNDING_DETAILS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.KUSPIT_FUNDING_DETAILS_REPOSITORY)
  private readonly repository!: FundingDetailsRepository;

  internalExecute(): Promise<FundingDetailsDto> {
    return new Promise((resolve, reject) => {
      if (!this.state_manager.state.loaded) {
        this.repository.get()
          .then((funding_details) => {
            this.state_manager.patch({ funding_details, loaded: true });
            resolve(funding_details);
          })
          .catch(reject);
      } else {
        resolve(this.state_manager.state.funding_details);
      }
    });
  }
}
