import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import AssociatedProductDummyRepository from './infrastructure/repositories/associated-product-dummy-repository/associated-product-dummy-repository';
import AssociatedProductHttpRepository from './infrastructure/repositories/associated-product-http-repository';
import AssociatedProductVueStateManager from './infrastructure/state/associated-product-vue-state-manager';

// Application
import GetAssociatedProductQuery from './application/queries/get-associated-product-query';

// Domain
import { AssociatedProductRepository } from './domain/repositories/associated-product-repository';
import { AssociatedProductStateManager } from './domain/state/associated-product-state-manager';
import { GetInterestRateByFixedTimeService } from './domain/service/get-interest-rate-by-fixed-time-service';

export default function setupAssociatedProduct(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<AssociatedProductStateManager>(TYPES.ASSOCIATED_PRODUCT_STATE_MANAGER)
    .to(AssociatedProductVueStateManager)
    .inSingletonScope();
  container
    .bind<AssociatedProductRepository>(
      TYPES.ASSOCIATED_PRODUCT_REPOSITORY,
    ).to(
      withDummies
        ? AssociatedProductDummyRepository
        : AssociatedProductHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetAssociatedProductQuery>(
      TYPES.GET_ASSOCIATED_PRODUCT_QUERY,
    ).to(GetAssociatedProductQuery)
    .inSingletonScope();

  container
    .bind<GetInterestRateByFixedTimeService>(
      TYPES.GET_INTEREST_RATE_BY_FIXED_TYME_SERVICE,
    ).to(GetInterestRateByFixedTimeService)
    .inSingletonScope();
}
