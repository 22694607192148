import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import HabitsAvailableBudgetCalculationDummyRepository
  from '@/modules/flagship/habits-available-budget-calculation/infrastructure/repositories/habits-available-budget-calculation-dummy-repository';
import HabitsAvailableBudgetCalculationHttpRepository
  from '@/modules/flagship/habits-available-budget-calculation/infrastructure/repositories/habits-available-budget-calculation-http-repository';

// Application
import CreateHabitsAvailableBudgetCalculationCommand
  from '@/modules/flagship/habits-available-budget-calculation/application/commands/create-habits-available-budget-calculation-command';

// Domain
import {
  HabitsAvailableBudgetCalculationRepository,
} from '@/modules/flagship/habits-available-budget-calculation/domain/repositories/habits-available-budget-calculation-repository';

export default function setupHabitsAvailableBudgetCalculation(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<HabitsAvailableBudgetCalculationRepository>(
      TYPES.HABITS_AVAILABLE_BUDGET_CALCULATION_REPOSITORY,
    ).to(
      withDummies
        ? HabitsAvailableBudgetCalculationDummyRepository
        : HabitsAvailableBudgetCalculationHttpRepository,
    ).inSingletonScope();

  container
    .bind<CreateHabitsAvailableBudgetCalculationCommand>(
      TYPES.CREATE_HABITS_AVAILABLE_BUDGET_CALCULATION_COMMAND,
    ).to(CreateHabitsAvailableBudgetCalculationCommand)
    .inSingletonScope();
}
