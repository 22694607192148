// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { GetPersonRepository } from '../../../../domain/repository/get-person-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class SearchPersonDummyRepository implements GetPersonRepository {
  // eslint-disable-next-line class-methods-use-this
  async get() {
    return Promise.resolve(fixtures);
  }
}
