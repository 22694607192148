import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import ExitPollOptionsDummyRepository
  from '@/modules/flagship/catalogs/exit-poll-options/infraestructure/repositories/exit-poll-options-dummy-repository/exit-poll-options-dummy-repository';
import ExitPollOptionsHttpRepository
  from '@/modules/flagship/catalogs/exit-poll-options/infraestructure/repositories/exit-poll-options-http-repository';
import ExitPollOptionsVueStateManager
  from '@/modules/flagship/catalogs/exit-poll-options/infraestructure/state/exit-poll-options-vue-state-manager';

// Application
import GetExitPollOptionsQuery
  from '@/modules/flagship/catalogs/exit-poll-options/application/queries/get-exit-poll-options-query';

// Domain
import {
  ExitPollOptionsRepository,
} from '@/modules/flagship/catalogs/exit-poll-options/domain/repositories/exit-poll-options-repository';
import {
  ExitPollOptionsStateManager,
} from '@/modules/flagship/catalogs/exit-poll-options/domain/state/exit-poll-options-state-manager';

export default function setupExitPollOptions(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<ExitPollOptionsStateManager>(TYPES.EXIT_POLL_OPTIONS_STATE_MANAGER)
    .to(ExitPollOptionsVueStateManager)
    .inSingletonScope();

  container
    .bind<ExitPollOptionsRepository>(
      TYPES.EXIT_POLL_OPTIONS_REPOSITORY,
    ).to(
      withDummies
        ? ExitPollOptionsDummyRepository
        : ExitPollOptionsHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetExitPollOptionsQuery>(
      TYPES.GET_EXIT_POLL_OPTIONS_QUERY,
    ).to(GetExitPollOptionsQuery)
    .inSingletonScope();
}
