import TYPES from './types';
import { Container } from 'inversify-props';

// Infrastructure
import CreateOnBoardingStepDummyRepository
  from './infrastructure/repositories/create-on-boarding-step-repository/create-on-boarding-steps-dummy-repository';
import CreateOnBoardingStepHttpRepository
  from './infrastructure/repositories/create-on-boarding-step-repository/create-on-boarding-steps-http-repository';
import SearchOnBoardingStepDummyRepository
  from './infrastructure/repositories/search-on-boarding-step-repository/search-on-boarding-step-dummy-repository';
import SearchOnBoardingStepHttpRepository
  from './infrastructure/repositories/search-on-boarding-step-repository/search-on-boarding-step-http-repository';
import UpdateOnBoardingStepDummyRepository
  from './infrastructure/repositories/update-on-boarding-step-repository/update-on-boarding-step-dummy-repository';
import UpdateOnBoardingStepHttpRepository
  from './infrastructure/repositories/update-on-boarding-step-repository/update-on-boarding-step-http-repository';
import VueStateManager from './infrastructure/state/vue-state-manager';

// Application
import { CreateOnBoardingStepCommand, UpdateOnBoardingStepCommand } from './application/commands';
import {
  SearchOnBoardingStepsQuery,
  GetOnBoardingCurrentStepQuery,
} from './application/queries';

// Domain
import { StateManager } from './domain/state/state-manager';
import {
  CreateOnBoardingStepRepository,
} from './domain/repositories/create-on-boarding-step-repository';
import {
  SearchOnBoardingStepRepository,
} from './domain/repositories/search-on-boarding-step-repository';
import {
  UpdateOnBoardingStepRepository,
} from './domain/repositories/update-on-boarding-step-repository';

export default function setupOnBoardingStep(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.MY_INVESTMENT_ON_BOARDING_STEP_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<CreateOnBoardingStepRepository>(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
    .to(
      withDummies
        ? CreateOnBoardingStepDummyRepository
        : CreateOnBoardingStepHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<CreateOnBoardingStepCommand>(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
    .to(CreateOnBoardingStepCommand)
    .inSingletonScope();

  container
    .bind<SearchOnBoardingStepRepository>(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
    .to(
      withDummies
        ? SearchOnBoardingStepDummyRepository
        : SearchOnBoardingStepHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchOnBoardingStepsQuery>(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_QUERY)
    .to(SearchOnBoardingStepsQuery)
    .inSingletonScope();

  container
    .bind<UpdateOnBoardingStepRepository>(TYPES.UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
    .to(
      withDummies
        ? UpdateOnBoardingStepDummyRepository
        : UpdateOnBoardingStepHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<UpdateOnBoardingStepCommand>(TYPES.UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
    .to(UpdateOnBoardingStepCommand)
    .inSingletonScope();

  container
    .bind<GetOnBoardingCurrentStepQuery>(TYPES.GET_MY_INVESTMENT_ON_BOARDING_CURRENT_STEP_QUERY)
    .to(GetOnBoardingCurrentStepQuery)
    .inSingletonScope();
}
