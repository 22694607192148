import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import GoalTrackingDummyRepository from './infrastructure/repositories/goal-tracking-dummy-repository/goal-tracking-dummy-repository';
import GoalTrackingHttpRepository from './infrastructure/repositories/goal-tracking-http-repository';
import GoalTrackingVueStateManager from './infrastructure/state/goal-tracking-vue-state-manager';

// Application
import GetGoalTrackingQuery from './application/queries/get-goal-tracking-query';

// Domain
import { GoalTrackingRepository } from './domain/repositories/goal-tracking-repository';
import { GoalTrackingStateManager } from './domain/state/goal-tracking-state-manager';

export default function setupGoalTracking(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<GoalTrackingStateManager>(TYPES.GOAL_TRACKING_STATE_MANAGER)
    .to(GoalTrackingVueStateManager)
    .inSingletonScope();
  container
    .bind<GoalTrackingRepository>(
      TYPES.GOAL_TRACKING_REPOSITORY,
    ).to(
      withDummies
        ? GoalTrackingDummyRepository
        : GoalTrackingHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetGoalTrackingQuery>(
      TYPES.GET_GOAL_TRACKING_QUERY,
    ).to(GetGoalTrackingQuery)
    .inSingletonScope();
}
