import TYPES from '@/types';

// Application
import { GetHasKuspitAccountQuery }
  from '@/modules/my-investment/kuspit/customer-has-account/application/queries';
import { GetCustomerKuspitPositionQuery }
  from '@/modules/my-investment/kuspit/customer-kuspit-position/application/queries';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class KuspitDashboardViewModel {
  @Inject(TYPES.GET_CUSTOMER_HAS_KUSPIT_ACCOUNT_QUERY)
  readonly get_has_kuspit_account_query!: GetHasKuspitAccountQuery;

  @Inject(TYPES.GET_CUSTOMER_KUSPIT_POSITION_QUERY)
  private readonly get_kuspit_position!: GetCustomerKuspitPositionQuery;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.kuspit-dashboard';

  kuspit_account_exists = false;

  kuspit_has_funds = false;

  show_deposit_account_info = false;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get has_kuspit_account() {
    return this.get_has_kuspit_account_query.execute();
  }

  get kuspit_position() {
    return this.get_kuspit_position.execute();
  }

  get redirect_to_my_investment() {
    return !this.kuspit_account_exists || (this.kuspit_account_exists && !this.kuspit_has_funds);
  }

  requestDeposit = () => {
    this.show_deposit_account_info = true;
  }

  closeModalDepositAccountInfo = () => {
    this.show_deposit_account_info = false;
  }

  initialize = async () => {
    const verify_kuspit_account = await this.has_kuspit_account;
    this.kuspit_account_exists = verify_kuspit_account.has_account;

    if (this.kuspit_account_exists) {
      const kuspit_position = await this.kuspit_position;
      this.kuspit_has_funds = kuspit_position.has_funds;
    }
  }
}
