import TYPES from '../../types';

// Domain
import { OnBoardingStepEntity } from '../../domain/entities/on-boarding-step-entity';
import { StateManager } from '../../domain/state/state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/services/query';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class GetOnBoardingCurrentStepQuery extends Query<Promise<OnBoardingStepEntity>> {
  @Inject(TYPES.MY_INVESTMENT_ON_BOARDING_STEP_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  internalExecute(): Promise<OnBoardingStepEntity> {
    const current_step = this.state_manager.state.created_steps[0];

    return Promise.resolve(current_step);
  }
}
