import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import WealthFlexibleDepositVueStateManager
  from './infrastructure/state/wealth-flexible-deposit-vue-state-manager';
import {
  TransactionHttpRepository,
  TransactionDummyRepository,
  CreateTransactionHttpRepository,
  CreateTransactionDummyRepository,
}
  from './infrastructure/repository';

// Application
import { SearchTransactionsQuery, SearchTransactionsByRangeQuery } from './application/queries';
import { CancelTransactionCommand, CreateTransactionCommand } from './application/commands';

// Domain
import { TransactionRepository, CreateTransactionRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';
import {
  WealthFlexibleDepositStateManager,
} from './domain/state/wealth-flexible-deposit-state-manager';

export default function setupTransaction(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.ALLIANZ_TRANSACTION_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<WealthFlexibleDepositStateManager>(TYPES.WEALTH_FLEXIBLE_DEPOSIT_STATE_MANAGER)
    .to(WealthFlexibleDepositVueStateManager)
    .inSingletonScope();

  container
    .bind<TransactionRepository>(TYPES.ALLIANZ_TRANSACTION_REPOSITORY)
    .to(
      withDummies
        ? TransactionDummyRepository
        : TransactionHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchTransactionsQuery>(TYPES.SEARCH_ALLIANZ_TRANSACTIONS_QUERY)
    .to(SearchTransactionsQuery)
    .inSingletonScope();

  container
    .bind<CancelTransactionCommand>(TYPES.UPDATE_ALLIANZ_TRANSACTIONS_COMMAND)
    .to(CancelTransactionCommand)
    .inSingletonScope();

  container
    .bind<CreateTransactionRepository>(TYPES.CREATE_ALLIANZ_TRANSACTION_REPOSITORY)
    .to(
      withDummies
        ? CreateTransactionDummyRepository
        : CreateTransactionHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<CreateTransactionCommand>(TYPES.CREATE_ALLIANZ_TRANSACTIONS_COMMAND)
    .to(CreateTransactionCommand)
    .inSingletonScope();

  container
    .bind<SearchTransactionsByRangeQuery>(TYPES.SEARCH_ALLIANZ_TRANSACTIONS_BY_RANGE_QUERY)
    .to(SearchTransactionsByRangeQuery)
    .inSingletonScope();
}
