import TYPES from '../../types';

// Domain
import { InvestmentTypeHiringStepEntity } from '../../domain/entities/investment-type-hiring-step-entity';
import { InvestmentTypeHiringStepStateManager } from '../../domain/state/investment-type-hiring-step-state-manager';
import { InvestmentTypeHiringStepRepository }
  from '../../domain/repositories/investment-type-hiring-step-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';
import { Sort } from '@/modules/shared/domain/dtos/sort_dto';

@Injectable()
export default class SearchInvestmentTypeHiringStepsQuery
  extends Query<Promise<Array<InvestmentTypeHiringStepEntity>>> {
  @Inject(TYPES.INVESTMENT_TYPE_HIRING_STEP_STATE_MANAGER)
  private readonly state_manager!: InvestmentTypeHiringStepStateManager;

  @Inject(TYPES.INVESTMENT_TYPE_HIRING_STEP_REPOSITORY)
  private readonly repository!: InvestmentTypeHiringStepRepository;

  internalExecute(sort: Sort): Promise<Array<InvestmentTypeHiringStepEntity>> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded) {
        resolve(this.state_manager.state.items);
      } else {
        this.repository.search(sort)
          .then((items) => {
            this.state_manager.patch({ items });
            resolve(this.state_manager.state.items);
          })
          .catch(reject);
      }
    });
  }
}
