import TYPES from '../../types';

// Domain
import { AssociatedProductEntity } from '../../domain/entities/associated-product-entity';
import { AssociatedProductStateManager } from '../../domain/state/associated-product-state-manager';
import { AssociatedProductRepository } from '../../domain/repositories/associated-product-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class GetAssociatedProductQuery
  extends Query<Promise<Array<AssociatedProductEntity>>> {
  @Inject(TYPES.ASSOCIATED_PRODUCT_REPOSITORY)
  private readonly repository!: AssociatedProductRepository;

  @Inject(TYPES.ASSOCIATED_PRODUCT_STATE_MANAGER)
  private readonly state_manager!: AssociatedProductStateManager;

  internalExecute(associated_product_id: string): Promise<Array<AssociatedProductEntity>> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded) {
        resolve(this.state_manager.state.items);
      } else {
        this.repository.get(associated_product_id)
          .then((items) => {
            this.state_manager.patch({ items, loaded: false });
            resolve(items);
          })
          .catch(reject);
      }
    });
  }
}
