


















































import { Component, Vue } from 'vue-property-decorator';

import InvestmentSuggestionWealthCardViewModel
  from '@/vue-app/view-models/components/investment-suggestion/investment-suggestion-wealth-card-view-model';

@Component({ name: 'InvestmentSuggestionWealthCard' })
export default class InvestmentSuggestionWealthCard extends Vue {
  wealth_card_modal = Vue.observable(new InvestmentSuggestionWealthCardViewModel(this));

  created() {
    this.wealth_card_modal.obtainInvestmentSuggestion();
  }
}
