import TYPES from '../../types';

// Domain
import {
  InvestorProfileOnFinancialHabitsRepository,
} from '@/modules/my-investment/allianz/access-more-strategies/domain/repositories/investor-profile-on-financial-habits-repository';
import {
  FinancialHabitsDto,
} from '@/modules/my-investment/allianz/access-more-strategies/domain/dtos/financial-habits-dtos';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UpdateInvestorProfileOnFinancialHabitsCommand
  extends Command<FinancialHabitsDto> {
  @Inject(TYPES.INVESTOR_PROFILE_ON_FINANCIAL_HABITS_REPOSITORY)
  private readonly repository!: InvestorProfileOnFinancialHabitsRepository;

  internalExecute(financial_habits_dto: FinancialHabitsDto): Promise<void> {
    return this.repository.update(financial_habits_dto);
  }
}
