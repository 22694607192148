import fixtures from './fixtures.json';

// Domain
import { CustomerPersonalityProfileRepository } from '../../../domain/repositories/customer-personality-profile-repository';
import { CustomerPersonalityProfileEntity } from '../../../domain/entities/customer-personality-profile-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CustomerPersonalityProfileDummyRepository
implements CustomerPersonalityProfileRepository {
  get(): Promise<CustomerPersonalityProfileEntity> {
    return Promise.resolve(fixtures);
  }
}
