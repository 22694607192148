import { Container } from 'inversify';
import TYPES from './types';

// Infrastructure
import DistributeRecurrentBalanceDummyRepository
  from './infrastructure/repositories/distribute-recurrent-balance-dummy-repository/distribute-recurrent-balance-dummy-repository';
import DistributeRecurrentBalanceHttpRepository
  from './infrastructure/repositories/distribute-recurrent-balance-http-repository';

// Application
import DistributeRecurrentBalanceCommand
  from './application/command/distribute-recurrent-balance-command';

// Domain
import {
  DistributeRecurrentBalanceRepository,
} from './domain/repositories/distribute-recurrent-balance-repository';

export default function setupDistributeRecurrentBalance(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<DistributeRecurrentBalanceRepository>(TYPES
      .DISTRIBUTE_RECURRENT_BALANCE_REPOSITORY)
    .to(
      withDummies
        ? DistributeRecurrentBalanceDummyRepository
        : DistributeRecurrentBalanceHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<DistributeRecurrentBalanceCommand>(TYPES.DISTRIBUTE_RECURRENT_BALANCE_COMMAND)
    .to(DistributeRecurrentBalanceCommand)
    .inSingletonScope();
}
