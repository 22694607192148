import TYPES from '@/modules/shared/types';

// Domain
import {
  GoalDefinitionStepEntity,
} from '@/modules/flagship/goal-definition-step/domain/entities/goal-definition-step-entity';
import {
  GoalDefinitionStepRepository,
} from '@/modules/flagship/goal-definition-step/domain/repositories/goal-definition-step-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class GoalDefinitionStepHttpRepository implements GoalDefinitionStepRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/goal-definition-step/`;

  get(): Promise<GoalDefinitionStepEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(JSON.stringify({
                  status_code: response.status,
                  message_error: message,
                }));
              }
            });
        })
        .catch(reject);
    });
  }

  update(goal_definition_step_entity: GoalDefinitionStepEntity): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint, 'PATCH', goal_definition_step_entity)
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => {
                reject(new Error(message));
              },
            );
          }
        })
        .catch(reject);
    });
  }
}
