// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { ActivityRepository } from '../../../domain/repository';
import { ActivityEntity } from '../../../domain/entities/activity-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class ActivityDummyRepository implements ActivityRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(job_type_id: string): Promise<Array<ActivityEntity>> {
    return Promise.resolve(fixtures);
  }
}
