// Domain
import {
  InvestorProfileOnEconomicDependentsRepository,
} from '@/modules/flagship/investor-profile/economic-dependents/domain/repositories/investor-profile-on-economic-dependents-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class InvestorProfileOnEconomicDependentsDummyRepository
implements InvestorProfileOnEconomicDependentsRepository {
  update(): Promise<void> {
    return Promise.resolve();
  }
}
