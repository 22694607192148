import { CustomerWithdrawalDetailsEntity } from '../entities/customer-withdrawal-details-entity';

export default class State {
  withdrawal_details: CustomerWithdrawalDetailsEntity = {
    purchasing_power: {
      amount: '',
    },
    destination_account: {
      clabe: '',
      bank: '',
    },
    source_account: {
      clabe: '',
      bank: '',
    },
  };
}
