import fixtures from './fixtures.json';

// Domain
import {
  CustomerContractedInvestmentProductsRepository,
} from '@/modules/flagship/customer-contracted-investment-products/domain/repositories/customer-contracted-investment-products-repository';
import {
  CustomerContractedInvestmentProductsEntity,
} from '@/modules/flagship/customer-contracted-investment-products/domain/entities/customer-contracted-investment-products-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CustomerContractedInvestmentProductsDummyRepository
implements CustomerContractedInvestmentProductsRepository {
  get(): Promise<CustomerContractedInvestmentProductsEntity> {
    return Promise.resolve(fixtures);
  }
}
