// Infrastructure
import fixtures from './fixtures.json';

// Domain
import {
  InvestmentProductsRepository,
} from '@/modules/flagship/catalogs/investment-products/domain/repositories/investment-products-repository';
import {
  InvestmentProductsEntity,
} from '@/modules/flagship/catalogs/investment-products/domain/entities/investment-products-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class InvestmentProductsDummyRepository implements InvestmentProductsRepository {
  get(): Promise<Array<InvestmentProductsEntity>> {
    return Promise.resolve(fixtures);
  }
}
