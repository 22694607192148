import { MovementEntity } from '../entities/movement-entity';
import { SearchMovementDto } from '../dtos/search-movement-dto';

export default class State {
  current_searcher: SearchMovementDto = {
    initial_date: '',
    final_date: '',
  }

  items: Array<MovementEntity> = [];
}
