// Domain
import { LinkSowosPocketRepository } from '../../domain/repositories/link-sowos-pocket-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class LinkSowosPocketDummyRepository implements LinkSowosPocketRepository {
  create(): Promise<void> {
    return Promise.resolve();
  }
}
