// Domain
import {
  UpdateKuspitOnBoardingStepRepository,
} from '@/modules/my-investment/kuspit/kuspit-account-linking/domain/repositories/update-kuspit-on-boarding-step-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
class UpdateKuspitOnBoardingStepDummyRepository implements UpdateKuspitOnBoardingStepRepository {
  update(): Promise<void> {
    return Promise.resolve();
  }
}

export default UpdateKuspitOnBoardingStepDummyRepository;
