import TYPES from '../../types';

// Domain
import { TemplateQuestionnaireEntity } from '../../domain/entities/template-questionnaire-entity';
import { FilterDto } from '../../domain/dtos/filter-dto';
import {
  TemplateQuestionnaireRepository,
} from '../../domain/repository/template-questionnaire-repository';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class SearchTemplateQuestionnairesQuery
  extends Query<Promise<Array<TemplateQuestionnaireEntity>>> {
  @Inject(TYPES.CATALOG_ALLIANZ_QUESTIONNAIRE_TEMPLATE_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.CATALOG_ALLIANZ_QUESTIONNAIRE_TEMPLATE_REPOSITORY)
  private readonly repository!: TemplateQuestionnaireRepository;

  internalExecute(filter_questionnaire: FilterDto): Promise<Array<TemplateQuestionnaireEntity>> {
    return new Promise((resolve, reject) => {
      if (
        this.state_manager.state.current_filter.tipoCuestionario
        !== filter_questionnaire.tipoCuestionario
        || this.state_manager.state.current_filter.versionCuestionario
        !== filter_questionnaire.versionCuestionario) {
        this.repository.execute(filter_questionnaire)
          .then((items) => {
            this.state_manager.patch({ items, current_filter: filter_questionnaire });
            resolve(items);
          })
          .catch(reject);
      } else {
        resolve(this.state_manager.state.items);
      }
    });
  }
}
