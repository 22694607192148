import { Container } from 'inversify';
import TYPES from './types';

// Infrastructure
import InvestorGoalScheduledDistributionWealthFlexibleDummyRepository
  from './infrastructure/repositories/investor-goal-scheduled-distribution-wealth-flexible-dummy-repository/investor-goal-scheduled-distribution-wealth-flexible-dummy-repository';
import InvestorGoalScheduledDistributionWealthFlexibleHttpRepository
  from './infrastructure/repositories/investor-goal-scheduled-distribution-wealth-flexible-http-repository';

// Application
import InvestorGoalScheduledDistributionWealthFlexibleCommand
  from './application/commands/investor-goal-scheduled-distribution-wealth-flexible-command';

// Domain
import {
  InvestorGoalScheduledDistributionWealthFlexibleRepository,
} from './domain/repositories/investor-goal-scheduled-distribution-wealth-flexible-repository';

export default function setupInvestorGoalScheduledDistributionWealthFlexible(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<InvestorGoalScheduledDistributionWealthFlexibleRepository>(TYPES
      .INVESTOR_GOAL_SCHEDULED_DISTRIBUTION_WEALTH_FLEXIBLE_REPOSITORY)
    .to(
      withDummies
        ? InvestorGoalScheduledDistributionWealthFlexibleDummyRepository
        : InvestorGoalScheduledDistributionWealthFlexibleHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<InvestorGoalScheduledDistributionWealthFlexibleCommand>(TYPES
      .INVESTOR_GOAL_SCHEDULED_DISTRIBUTION_WEALTH_FLEXIBLE_COMMAND)
    .to(InvestorGoalScheduledDistributionWealthFlexibleCommand)
    .inSingletonScope();
}
