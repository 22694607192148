// Domain
import {
  InvestorProfileOnRetirementBudgetRepository,
} from '@/modules/flagship/investor-profile/retirement-budget/domain/repositories/investor-profile-on-retirement-budget-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class InvestorProfileOnRetirementBudgetDummyRepository
implements InvestorProfileOnRetirementBudgetRepository {
  update(): Promise<void> {
    return Promise.resolve();
  }
}
