import Vue from 'vue';

// Domain
import { GlobalTrackingStateManager } from '../../domain/state/global-tracking-state-manager';
import GlobalTrackingState from '../../domain/state/global-tracking-state';
import Injectable from '@/modules/shared/domain/di/injectable';
import Observer from '@/modules/shared/domain/observer/observer';

@Injectable()
export default class GlobalTrackingVueStateManager implements GlobalTrackingStateManager {
  private _state: GlobalTrackingState = Vue.observable<GlobalTrackingState>(
    new GlobalTrackingState(),
  );

  private readonly observers: Observer[] = [];

  get state(): GlobalTrackingState {
    return this._state;
  }

  set state(value: GlobalTrackingState) {
    this._state = value;
  }

  patch(state: Partial<GlobalTrackingState>) {
    type Keys = keyof GlobalTrackingState;
    Object.entries(state).forEach(([key, value]) => {
      const accessor = key as Keys;

      if (value !== undefined) {
        // eslint-disable-next-line
        // @ts-ignore @typescript-eslint/ban-ts-ignore
        this.state[accessor] = value;
      }
    });
  }

  notifyAll(): void {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer): void {
    this.observers.push(observer);
  }
}
