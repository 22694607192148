import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import SearchTransactionTypesDummyRepository
  from './infrastructure/repositories/search-transaction-types-dummy-repository';
import SearchTransactionTypesHttpRepository
  from './infrastructure/repositories/search-transaction-types-http-repository';
import SearchTransactionTypeQuery from './application/queries/search-transaction-types-query';

// Domain
import { TransactionTypeStateManager } from './domain/state/state-manager';
import { SearchTransactionTypeRepository } from './domain/repositories/search-transaction-type-repository';

export default function setupAgreements(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<TransactionTypeStateManager>(TYPES.TRANSACTION_TYPES_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<SearchTransactionTypeRepository>(TYPES.SEARCH_TRANSACTION_TYPES_REPOSITORY)
    .to(
      withDummies
        ? SearchTransactionTypesDummyRepository
        : SearchTransactionTypesHttpRepository,
    ).inSingletonScope();

  container
    .bind<SearchTransactionTypeQuery>(TYPES.SEARCH_TRANSACTION_TYPES_QUERY)
    .to(SearchTransactionTypeQuery)
    .inSingletonScope();
}
