import TYPES from '../../types';

// Domain
import {
  GoalDefinitionStepEntity,
} from '@/modules/flagship/goal-definition-step/domain/entities/goal-definition-step-entity';
import {
  GoalDefinitionStepRepository,
} from '@/modules/flagship/goal-definition-step/domain/repositories/goal-definition-step-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UpdateGoalDefinitionStepCommand extends Command<GoalDefinitionStepEntity> {
  @Inject(TYPES.GOAL_DEFINITION_STEP_REPOSITORY)
  private readonly repository!: GoalDefinitionStepRepository;

  internalExecute(goal_definition_step_entity: GoalDefinitionStepEntity) {
    return this.repository.update(goal_definition_step_entity);
  }
}
