import { Route, NavigationGuardNext } from 'vue-router';
import store from '@/vue-app/store';
import { ValidateJwtCommand } from '@/modules/authentication/application/commands';
import GetOnBoardingStatusQuery
  from '@/modules/on-boarding/status/application/queries/get-on-boarding-status-query';
import POLICIES from './policies';

const validateJwtCommand = new ValidateJwtCommand();
const getOnBoardingStatusQuery = new GetOnBoardingStatusQuery();

// eslint-disable-next-line consistent-return
export default function authorizeAccess(to: Route, from: Route, next: NavigationGuardNext) {
  const matched_route_with_access_policy = to.matched.find(
    (route) => route.meta.access_policy,
  );

  if (!matched_route_with_access_policy) {
    store.dispatch('application/setLoading', false);
    return next();
  }

  const { access_policy } = matched_route_with_access_policy.meta;

  const auth_token = sessionStorage.getItem('authorization') || '';

  validateJwtCommand.execute(auth_token)
    .then(async () => {
      const { onboarding_completed } = await getOnBoardingStatusQuery.execute();

      if (access_policy === POLICIES.AUTHENTICATED) {
        if (!onboarding_completed && to.name !== 'OnBoarding') {
          return next({ path: '/on-boarding' });
        }
        if (onboarding_completed && to.name === 'OnBoarding') {
          return next({ path: '/' });
        }

        return next();
      }

      return next({ path: onboarding_completed ? '/' : '/on-boarding' });
    })
    .catch(() => {
      if (access_policy === POLICIES.UNAUTHENTICATED) return next();

      return next({ path: '/login' });
    })
    .finally(async () => {
      await store.dispatch('application/setLoading', false);
    });
}
