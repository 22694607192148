import {
  InvestmentProductFundTypeEntity,
} from '@/modules/flagship/catalogs/investment-product-fund-type/domain/entities/investment-product-fund-type-entity';
import { AnswerDto } from '@/modules/my-investment/allianz/access-more-strategies/domain/dtos/answer-dto';

export default class AccessMoreStrategiesState {
  is_loading = false;

  investor_goal = {
    id: '',
    term: 10,
  };

  strategy: InvestmentProductFundTypeEntity = {
    id: '',
    name: '',
    label: '',
    investment_product_id: '',
    description: '',
    investment_product: {
      id: '',
      name: '',
      label: '',
      investment_provider_id: '',
      interest_rate: 0,
      description: '',
    },
  };

  available_balance= 0;

  risk_tolerance_step_id = '';

  answers_dto: Array<AnswerDto> = [];

  user_selected_a_warning_answer = false;

  customer_agreement = {
    id: '',
    customer_id: '',
    agreement_type_id: '',
    was_accepted: false,
    accepted_on: '',
  }

  initial_balance = '0';

  price_by_unit = 0;

  current_recurrent_contribution = 0;

  goal_contribution = 0;

  define_contribution_later = false;

  recurring_contribution = {
    id: '',
    monthly_collection_day: 0,
    amount: '0',
    periodicity: 'MENSUAL',
    confirm_periodicity: true,
    home_desire: true,
  }
}
