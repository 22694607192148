import deleteCustomInvestorGoalCriterion from './delete-custom-investor-goal-criterion.json';
import deleteCustomInvestorGoalDistributeBalance from './delete-custom-investor-goal-distribute-balance.json';
import deleteCustomInvestorGoalConfirm from './delete-custom-investor-goal-confirm.json';
import deleteCustomInvestorGoalSuccess from './delete-custom-investor-goal-success.json';

export default {
  ...deleteCustomInvestorGoalCriterion,
  ...deleteCustomInvestorGoalDistributeBalance,
  ...deleteCustomInvestorGoalConfirm,
  ...deleteCustomInvestorGoalSuccess,
  errors: {
    get_custom_investor_goal: 'Ocurrió un error al obtener la información de meta personalizada.',
  },
};
