import TYPES from '../../types';

// Domain
import { HasAccountEntity } from '../../domain/entities/has-account-entity';
import { HasAccountRepository } from '../../domain/repository/has-account-repository';
import { StateManager } from '../../domain/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetHasKuspitAccountQuery extends Query<Promise<HasAccountEntity>> {
  @Inject(TYPES.CUSTOMER_HAS_KUSPIT_ACCOUNT_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.CUSTOMER_HAS_KUSPIT_ACCOUNT_REPOSITORY)
  private readonly repository!: HasAccountRepository;

  internalExecute(): Promise<HasAccountEntity> {
    return new Promise((resolve, reject) => {
      if (!this.state_manager.state.has_account_loaded) {
        this.state_manager.patch({ is_loading: true });
        this.repository.execute()
          .then((has_account) => {
            this.state_manager.patch({ has_account, has_account_loaded: true });
            resolve(has_account);
          })
          .catch(reject)
          .finally(() => {
            this.state_manager.patch({ is_loading: false });
          });
      } else {
        resolve(this.state_manager.state.has_account);
      }
    });
  }
}
