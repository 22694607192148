import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import AnonymousTicketDummyRepository
  from '@/modules/zendesk/anonymous-ticket/infrastructure/repositories/anonymous-ticket-dummy-repository';
import AnonymousTicketHttpRepository
  from '@/modules/zendesk/anonymous-ticket/infrastructure/repositories/anonymous-ticket-http-repository';

// Application
import CreateAnonymousTicketCommand
  from '@/modules/zendesk/anonymous-ticket/application/commands/create-anonymous-ticket-command';

// Domain
import {
  AnonymousTicketRepository,
} from '@/modules/zendesk/anonymous-ticket/domain/repositories/anonymous-ticket-repository';

export default function setupAnonymousTicket(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<AnonymousTicketRepository>(TYPES.ANONYMOUS_TICKET_REPOSITORY)
    .to(
      withDummies
        ? AnonymousTicketDummyRepository
        : AnonymousTicketHttpRepository,
    ).inSingletonScope();

  container
    .bind<CreateAnonymousTicketCommand>(TYPES.CREATE_ANONYMOUS_TICKET_COMMAND)
    .to(CreateAnonymousTicketCommand)
    .inSingletonScope();
}
