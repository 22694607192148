import reminder from './reminder.json';
import information from './information.json';
import confirm_information from './confirm_information.json';
import confirm_operation from './confirm_operation.json';
import operation_in_process from './operation_in_process.json';
import successful_operation from './sucessful_operation.json';
import goals_distribution from './goals_distribution.json';

export default {
  withdrawals: {
    withdrawal_request: 'Solicitud de Retiro - Kuspit',
    steps: [
      'Recordatorio',
      '1. Completa la información',
      '2. Confirma la información',
      '3. Confirma la operación',
    ],
    step_in_process: 'Operación en proceso',
    step_successful: 'Operación exitosa',
    error_time_out_service_error: 'Recuerda que el horario de retiro es de 9:00 a 13:10 hrs.',
    error_on_investor_goal_scheduled_distribution_pocket_withdrawal_command: 'Ocurrió un error al distribuir el monto de retiro entre sus metas',
    ...reminder,
    ...information,
    'goals-distribution': goals_distribution,
    ...confirm_information,
    ...confirm_operation,
    ...operation_in_process,
    ...successful_operation,
  },
};
