import CUSTOMER_KUSPIT_CREDENTIALS from './customer-kuspit-credentials/types';
import KUSPIT_ACCOUNT_TYPES from './customer-kuspit-account/types';
import CUSTOMER_KUSPIT_POSITION_TYPES from './customer-kuspit-position/types';
import CUSTOMER_KUSPIT_MOVEMENT_TYPES from './customer-kuspit-movement/types';
import CUSTMER_HAS_KUSPIT_ACCOUNT_TYPES from './customer-has-account/types';
import KUSPIT_WITHDRAWAL_TYPES from './withdrawal/types';
import FUNDING_DETAILS_TYPES from './funding-details/types';
import KUSPIT_ACCOUNT_LINKING from './kuspit-account-linking/types';
import KUSPIT_CUSTOMER_DOCUMENT from './customer-document/types';

export default {
  ...CUSTOMER_KUSPIT_CREDENTIALS,
  ...KUSPIT_ACCOUNT_TYPES,
  ...CUSTOMER_KUSPIT_POSITION_TYPES,
  ...CUSTOMER_KUSPIT_MOVEMENT_TYPES,
  ...KUSPIT_WITHDRAWAL_TYPES,
  ...CUSTMER_HAS_KUSPIT_ACCOUNT_TYPES,
  ...FUNDING_DETAILS_TYPES,
  ...KUSPIT_ACCOUNT_LINKING,
  ...KUSPIT_CUSTOMER_DOCUMENT,
};
