import TYPES from '@/modules/shared/types';

// Domain
import { TransactionRepository } from '../../domain/repository';
import { TransactionEntity } from '../../domain/entities/transaction-entity';
import { UpdateTransactionDto } from '../../domain/dtos/update-transaction-dto';
import { CancelTransactionDto } from '../../domain/dtos/cancel-transaction-dto';
import { SearchByRangeDto } from '../../domain/dtos/search-by-range-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class TransactionHttpRepository implements TransactionRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  findAll(customer_id: string): Promise<Array<TransactionEntity>> {
    const endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/allianz/transactions-pending/{CUSTOMER_ID}/`;

    return new Promise((resolve, reject) => {
      this.http_connector.execute(endpoint.replace('{CUSTOMER_ID}', customer_id))
        .then((response) => {
          response.json()
            .then(({ data, success, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }

  update(transaction: UpdateTransactionDto): Promise<void> {
    const endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/allianz/transactions/{TRANSACTION_ID}/`;

    return new Promise((resolve, reject) => {
      const transaction_patch = { ...transaction };
      delete transaction_patch.transaction_id;
      this.http_connector.execute(
        endpoint.replace('{TRANSACTION_ID}', transaction.transaction_id),
        'PATCH',
        transaction_patch,
      )
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json()
              .then(({ message }) => {
                reject(new Error(message));
              });
          }
        })
        .catch(reject);
    });
  }

  cancel(transaction: CancelTransactionDto): Promise<void> {
    const endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/allianz/transactions/${transaction.transaction_id}/cancel/`;

    return new Promise((resolve, reject) => {
      const transaction_patch = { transaction_status_id: transaction.transaction_status_id };
      this.http_connector.execute(
        endpoint,
        'PATCH',
        transaction_patch,
      )
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json()
              .then(({ message }) => {
                reject(new Error(message));
              });
          }
        })
        .catch(reject);
    });
  }

  searchByRange(params: SearchByRangeDto): Promise<Array<TransactionEntity>> {
    const endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/allianz/transactions/search/?`;
    const query_params = new URLSearchParams(params);
    return new Promise((resolve, reject) => {
      this.http_connector.execute(endpoint + query_params, 'GET')
        .then((response) => {
          response.json()
            .then(({ data, success, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
