import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import SearchCurrencyTypesDummyRepository
  from './infrastructure/repositories/search-currency-types-dummy-repository';
import SearchCurrencyTypesHttpRepository
  from './infrastructure/repositories/search-currency-types-http-repository';
import SearchCurrencyTypesQuery from './application/queries/search-currency-types-query';

// Domain
import { CurrencyTypeStateManager } from './domain/state/state-manager';
import { SearchCurrencyTypesRepository } from './domain/repositories/search-currency-types-repository';

export default function setupAgreements(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<CurrencyTypeStateManager>(TYPES.CURRENCY_TYPES_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<SearchCurrencyTypesRepository>(TYPES.SEARCH_CURRENCY_TYPES_REPOSITORY)
    .to(
      withDummies
        ? SearchCurrencyTypesDummyRepository
        : SearchCurrencyTypesHttpRepository,
    ).inSingletonScope();

  container
    .bind<SearchCurrencyTypesQuery>(TYPES.SEARCH_CURRENCY_TYPES_QUERY)
    .to(SearchCurrencyTypesQuery)
    .inSingletonScope();
}
