import TYPES from '../../types';

// Domain
import { UploadVoucherDto } from '../../domain/dto/upload-voucher-dto';
import {
  VoucherExtractedDataEntity,
} from '@/modules/my-investment/allianz/special-contribution/domain/dto/voucher-extracted-data-dto';
import { SpecialContributionRepository } from '../../domain/repository/special-contribution-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UploadVoucherSpecialContributionCommand extends Command<UploadVoucherDto> {
  @Inject(TYPES.SPECIAL_CONTRIBUTION_REPOSITORY)
  private readonly repository!: SpecialContributionRepository;

  internalExecute(upload_voucher_dto: UploadVoucherDto): Promise<VoucherExtractedDataEntity> {
    return this.repository.uploadVoucher(upload_voucher_dto);
  }
}
