import TYPES from '../../types';

// Domain
import {
  InvestorGoalScheduledDistributionPocketWithdrawalDto,
} from '../../domain/dto/investor-goal-scheduled-distribution-pocket-withdrawal-dto';
import {
  InvestorGoalScheduledDistributionPocketWithdrawalRepository,
} from '../../domain/repositories/investor-goal-scheduled-distribution-pocket-withdrawal-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class InvestorGoalScheduledDistributionPocketWithdrawalCommand
  extends Command<InvestorGoalScheduledDistributionPocketWithdrawalDto> {
  @Inject(TYPES.INVESTOR_GOAL_SCHEDULED_DISTRIBUTION_POCKET_WITHDRAWAL_REPOSITORY)
  private readonly repository!: InvestorGoalScheduledDistributionPocketWithdrawalRepository;

  // eslint-disable-next-line max-len
  internalExecute(investor_goal_scheduled_distribution_pocket_withdrawal_dto: InvestorGoalScheduledDistributionPocketWithdrawalDto):
    Promise<void> {
    return this.repository.distribute(investor_goal_scheduled_distribution_pocket_withdrawal_dto);
  }
}
