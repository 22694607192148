import i18n from '@/vue-app/plugins/i18n';
import TYPES from '@/types';
import { v4 } from 'uuid';
import Vue from 'vue';

// Application
import InvestmentSuggestionQuery
  from '@/modules/investment-suggestion/application/queries/investment-suggestion-query';
import InvestmentSuggestionCommand
  from '@/modules/investment-suggestion/application/commands/investment-suggestion-command';

// Domain
import {
  InvestmentSuggestionDto,
} from '@/modules/investment-suggestion/domain/dtos/investment-suggestion-dto';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Inject from '@/modules/shared/domain/di/inject';

export default class InvestmentSuggestionWealthCardViewModel {
  @Inject(TYPES.GET_INVESTMENT_SUGGESTION_QUERY)
  private readonly get_investment_suggestion_query!: InvestmentSuggestionQuery;

  @Inject(TYPES.CREATE_INVESTMENT_SUGGESTION_COMMAND)
  private readonly create_investment_suggestion_command!: InvestmentSuggestionCommand;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  readonly view!: Vue;

  readonly i18n_namespace = 'components.investment-suggestion.wealth_card';

  private investment_suggestion_dto: InvestmentSuggestionDto = {
    id: v4(),
    investment_suggestion_analytic_id: '',
    action: '',
  }

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (value: string) => (
    i18n.t(`${this.i18n_namespace}.${value}`) as string
  )

  obtainInvestmentSuggestion = async () => {
    try {
      const investment_suggestion = await this.get_investment_suggestion_query.execute();

      if (investment_suggestion) {
        this.investment_suggestion_dto.investment_suggestion_analytic_id = investment_suggestion.id;
      }
    } catch {
      this.message_notifier.showErrorNotification(
        `${i18n.tc(`${this.i18n_namespace}.message_error`)}`,
      );
    }
  }

  createInvestmentSuggestionAction = async () => {
    try {
      this.investment_suggestion_dto.action = 'allianz';
      await this.create_investment_suggestion_command.execute(this.investment_suggestion_dto);
      this.view.$emit('showModalFlagship');
      return true;
    } catch {
      this.message_notifier.showErrorNotification(
        `${i18n.tc(`${this.i18n_namespace}.message_error`)}`,
      );
      return false;
    }
  }
}
