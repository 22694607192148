import SHARED_TYPES from '@/modules/shared/types';

// Domain
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import { WebSocket } from '@/modules/shared/domain/web-socket';

@Injectable()
export default class SignOutEmitter {
  @Inject(SHARED_TYPES.WEB_SOCKET)
  private readonly web_socket!: WebSocket;

  private endpoint = `${process.env.VUE_APP_WEBSOCKET_URL}/{USER_ID}/`;

  connect(user_id: string) {
    this.endpoint = this.endpoint.replace('{USER_ID}', user_id);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.web_socket.connect(this.endpoint, () => {});
  }

  sendMessage(tag: string, message: boolean) {
    this.web_socket.sendMessage(this.endpoint, tag, message);
  }

  stop() {
    this.web_socket.disconnect(this.endpoint);
  }

  refreshToken() {
    this.web_socket.refreshToken();
  }
}
