import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import PendingInvestorGoalMovementsDummyRepository
  from './infrastructure/repositories/pending-investor-goal-movements-dummy-repository/pending-investor-goal-movements-dummy-repository';
import PendingInvestorGoalMovementsHttpRepository
  from './infrastructure/repositories/pending-investor-goal-movements-http-repository';
import PendingInvestorGoalMovementsVueStateManager
  from './infrastructure/state/pending-investor-goal-movements-vue-state-manager';

// Application
import GetPendingInvestorGoalMovementsQuery
  from './application/queries/get-pending-investor-goal-movements-query';

// Domain
import { PendingInvestorGoalMovementsRepository } from './domain/repositories/pending-investor-goal-movements-repository';
import { PendingInvestorGoalMovementsStateManager } from './domain/state/pending-investor-goal-movements-state-manager';

export default function setupCustomerInvestorGoalBalance(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    // eslint-disable-next-line max-len
    .bind<PendingInvestorGoalMovementsStateManager>(TYPES.PENDING_INVESTOR_GOAL_MOVEMENTS_STATE_MANAGER)
    .to(PendingInvestorGoalMovementsVueStateManager)
    .inSingletonScope();

  container
    .bind<PendingInvestorGoalMovementsRepository>(TYPES.PENDING_INVESTOR_GOAL_MOVEMENTS_REPOSITORY)
    .to(
      withDummies
        ? PendingInvestorGoalMovementsDummyRepository : PendingInvestorGoalMovementsHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetPendingInvestorGoalMovementsQuery>(TYPES.GET_PENDING_INVESTOR_GOAL_MOVEMENTS_QUERY)
    .to(GetPendingInvestorGoalMovementsQuery)
    .inSingletonScope();
}
