import TYPES from '../../types';

// Domain
import { AllianzAccountRepository }
  from '../../domain/repository';
import { RecurringContributionCancelledEntity } from '../../domain/entities/recurring-contribution-cancelled-entity';
import { RecurringContributionCancellerEntity } from '../../domain/entities/recurring-contribution-canceller-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CancelRecurringContributionCommand
  extends Command<RecurringContributionCancellerEntity> {
  @Inject(TYPES.ALLIANZ_ACCOUNT_REPOSITORY)
  private readonly repository!: AllianzAccountRepository;

  internalExecute(recurring_contribution_canceller_entity: RecurringContributionCancellerEntity):
    Promise<RecurringContributionCancelledEntity> {
    return this.repository.cancel(recurring_contribution_canceller_entity);
  }
}
