import TYPES from '@/modules/shared/types';

// Domain
import {
  InvestorProfileOnEconomicDependentsRepository,
} from '@/modules/flagship/investor-profile/economic-dependents/domain/repositories/investor-profile-on-economic-dependents-repository';
import { InvestorProfileEntity } from '../../../investor-profile/domain/entities/investor-profile-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class InvestorProfileOnEconomicDependentsHttpRepository
implements InvestorProfileOnEconomicDependentsRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/investor-profile/economic-dependents/{investor_profile_id}/`;

  update(investor_profile_entity: InvestorProfileEntity): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint.replace('{investor_profile_id}', investor_profile_entity.id!), 'PATCH', {
        child_dependent_count: investor_profile_entity.child_dependent_count,
        elder_dependent_count: investor_profile_entity.elder_dependent_count,
        pet_dependent_count: investor_profile_entity.pet_dependent_count,
      })
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => {
                reject(new Error(message));
              },
            );
          }
        })
        .catch(reject);
    });
  }
}
