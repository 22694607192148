import Vue from 'vue';

// Domain
import {
  ExitPollOptionsStateManager,
} from '@/modules/flagship/catalogs/exit-poll-options/domain/state/exit-poll-options-state-manager';
import ExitPollOptionsState
  from '@/modules/flagship/catalogs/exit-poll-options/domain/state/exit-poll-options-state';
import Injectable from '@/modules/shared/domain/di/injectable';
import Observer from '@/modules/shared/domain/observer/observer';

@Injectable()
export default class ExitPollOptionsVueStateManager implements ExitPollOptionsStateManager {
  // eslint-disable-next-line max-len
  private _state: ExitPollOptionsState = Vue.observable<ExitPollOptionsState>(new ExitPollOptionsState());

  private readonly observers: Observer[] = [];

  get state(): ExitPollOptionsState {
    return this._state;
  }

  set state(value: ExitPollOptionsState) {
    this._state = value;
    this.notifyAll();
  }

  patch(state: Partial<ExitPollOptionsState>) {
    type Keys = keyof ExitPollOptionsState;
    Object.entries(state).forEach(([key, value]) => {
      const accessor = key as Keys;

      if (value !== undefined) {
        // eslint-disable-next-line
        // @ts-ignore @typescript-eslint/ban-ts-ignore
        this.state[accessor] = value;
      }
    });
  }

  notifyAll() {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer) {
    this.observers.push(observer);
  }
}
