import supportWidgetComment from './support-widget-comment.json';
import supportWidgetSuccess from './support-widget-success.json';
import supportWidgetError from './support-widget-error.json';
import supportWidgetAnonymousComment from './support-widget-anonymous-comment.json';

export default {
  'support-widget': {
    ...supportWidgetComment,
    ...supportWidgetSuccess,
    ...supportWidgetError,
    ...supportWidgetAnonymousComment,
  },
};
