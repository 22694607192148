import TYPES from '../../types';

// Domain
import { CustomerDocumentPocketDto } from '../../domain/dtos/customer-document-pocket-dto';
import {
  CustomerDocumentPocketRepository,
} from '../../domain/repositories/customer-document-pocket-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UpdateCustomerDocumentPocketBankStatementCommand
  extends Command<CustomerDocumentPocketDto> {
  @Inject(TYPES.CUSTOMER_DOCUMENT_POCKET_REPOSITORY)
  private readonly repository!: CustomerDocumentPocketRepository;

  internalExecute(customer_document_dto: CustomerDocumentPocketDto): Promise<void> {
    return this.repository.update_bank_statement(customer_document_dto);
  }
}
