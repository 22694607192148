import TYPES from '../../types';

// Domain
import { PatchAllianzPolicyIssuanceStatusEntity }
  from '../../domain/entities/patch-allianz-policy-issuance-status-entity';
import { PatchAllianzPolicyIssuanceStatusRepository }
  from '../../domain/repository';

import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class PatchAllianzPolicyIssuanceStatusCommand
  extends Command<PatchAllianzPolicyIssuanceStatusEntity> {
  @Inject(TYPES.PATCH_ALLIANZ_POLICY_ISSUANCE_STATUS_REPOSITORY)
  private readonly repository!: PatchAllianzPolicyIssuanceStatusRepository;

  internalExecute(
    allianz_policy_issuance_status: PatchAllianzPolicyIssuanceStatusEntity,
  ): Promise<void> {
    return this.repository.patch(allianz_policy_issuance_status);
  }
}
