import TYPES from '../../types';

// Domain
import { CustomerCellphoneRepository }
  from '../../domain/repository/customer-cellphone-respository';
import { CustomerCellphoneDto } from '../../domain/dtos/customer-cellphone-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export class CreateCustomerCellphoneCommand extends Command<CustomerCellphoneDto> {
  @Inject(TYPES.CUSTOMER_CELLPHONE_REPOSITORY)
  private readonly repository!: CustomerCellphoneRepository;

  internalExecute(customer_cellphone: CustomerCellphoneDto): Promise<unknown> {
    return this.repository.create(customer_cellphone);
  }
}
