
































import { Component, Vue } from 'vue-property-decorator';

import SupportWidgetAnonymousDialogViewModel
  from '@/vue-app/view-models/components/support-widget/support-widget-anonymous-dialog-view-model';

@Component({
  name: 'SupportWidgetAnonymousDialog',
  components: {
    SupportWidgetAnonymousComment: () => import('@/vue-app/components/support-widget/SupportWidgetAnonymousComment.vue'),
    SupportWidgetAnonymousSuccess: () => import('@/vue-app/components/support-widget/SupportWidgetAnonymousSuccess.vue'),
    SupportWidgetAnonymousError: () => import('@/vue-app/components/support-widget/SupportWidgetAnonymousError.vue'),
  },
})

export default class SupportWidgetAnonymousDialog extends Vue {
  support_anonymous_dialog_view_model = Vue.observable(
    new SupportWidgetAnonymousDialogViewModel(),
  );
}
