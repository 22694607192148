// Domain
import { AvailableToHireStrategyModerateEntity } from '@/modules/flagship/strategy-moderate/hire/domain/entities/availabe-to-hire-strategy-moderate-entity';

export class AvailableToHireStrategyModerateState {
  loaded = false;

  item: AvailableToHireStrategyModerateEntity = {
    moderate_is_available_to_contract: false,
    investor_goals_with_moderate_investment_product_available: [],
  }
}
