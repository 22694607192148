import Injectable from '@/modules/shared/domain/di/injectable';
import { IdentityVerificationServiceRepository }
  from '../../../domain/repositories/identity-verification-service-repository';
import { IdentityVerificationEntity } from '../../../domain/entities/identity-verification-entity';

@Injectable()
class IdentityVerificationServiceDummyRepository implements IdentityVerificationServiceRepository {
  get(): Promise<IdentityVerificationEntity> {
    return Promise.resolve({
      id: '',
      name: '',
      flow_id: '',
      client_id: '',
    });
  }
}

export default IdentityVerificationServiceDummyRepository;
