import { Status } from '../dtos';

export default class OnBoardingStatusState {
  data: Status = {
    allianz_account_id: '',
    errors: '',
    has_problem: false,
    percent: 0,
    policy_number: null,
    request_number: null,
    step_name: '',
    step_number: 0,
    user_id: '',
  };
}
