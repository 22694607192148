import TYPES from '@/types';

// Domain
import {
  CreateOnBoardingStepRepository,
} from '../../../../domain/repositories';
import { OnBoardingStepEntity } from '../../../../domain/entities/on-boarding-step-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class CreateOnBoardingStepHttpRepository
implements CreateOnBoardingStepRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly path = `${process.env.VUE_APP_API_URL}/api/v1/app/investment/on-boarding-step/`;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  execute(on_boarding_step: OnBoardingStepEntity): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.path, 'POST', on_boarding_step)
        .then((response) => {
          response.json()
            .then(({ success, message }) => {
              if (success) {
                resolve(success);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
