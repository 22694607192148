import { Container } from 'inversify-props';
import TYPES from './types';
import VueStateManager from './infrastructure/state/vue-state-manager';
import OnBoardingCreateStepDummyRepository
  from './infrastructure/repositories/on-boarding-create-step-repository/on-boarding-create-step-dummy-repository';
import OnBoardingCreateStepHttpRepository
  from './infrastructure/repositories/on-boarding-create-step-repository/on-boarding-create-step-http-repository';
import OnBoardingSearchStepsDummyRepository
  from './infrastructure/repositories/on-boarding-search-steps-repository/on-boarding-search-steps-dummy-repository';
import OnBoardingSearchStepsHttpRepository
  from './infrastructure/repositories/on-boarding-search-steps-repository/on-boarding-search-steps-http-repository';
import OnBoardingUpdateStepDummyRepository
  from './infrastructure/repositories/on-boarding-update-step-repository/on-boarding-update-step-dummy-repository';
import OnBoardingUpdateStepHttpRepository
  from './infrastructure/repositories/on-boarding-update-step-repository/on-boarding-update-step-http-repository';
import CreateOnBoardingStepCommand from './application/commands/create-on-boarding-step-command';
import SearchOnBoardingStepsQuery from './application/queries/search-on-boarding-steps-query';
import UpdateOnBoardingStepCommand from './application/commands/update-on-boarding-step-command';
import SaveOnBoardingStepsDataService from './application/services/save-on-boarding-steps-data-service';
import StateManager from './domain/state/state-manager';
import OnBoardingCreateStepRepository
  from './domain/repositories/on-boarding-create-step-repository';
import { OnBoardingSearchStepsRepository }
  from './domain/repositories/on-boarding-search-steps-repository';
import { OnBoardingUpdateStepRepository }
  from './domain/repositories/on-boarding-update-step-repository';

export default function setupSteps(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.ON_BOARDING_STEPS_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<OnBoardingCreateStepRepository>(TYPES.ON_BOARDING_CREATE_STEP_REPOSITORY)
    .to(
      withDummies
        ? OnBoardingCreateStepDummyRepository
        : OnBoardingCreateStepHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<CreateOnBoardingStepCommand>(TYPES.CREATE_ON_BOARDING_STEP_COMMAND)
    .to(CreateOnBoardingStepCommand)
    .inSingletonScope();

  container
    .bind<OnBoardingSearchStepsRepository>(TYPES.ON_BOARDING_SEARCH_STEPS_REPOSITORY)
    .to(
      withDummies
        ? OnBoardingSearchStepsDummyRepository
        : OnBoardingSearchStepsHttpRepository,
    ).inSingletonScope();

  container
    .bind<SearchOnBoardingStepsQuery>(TYPES.SEARCH_ON_BOARDING_STEPS_QUERY)
    .to(SearchOnBoardingStepsQuery)
    .inSingletonScope();

  container
    .bind<OnBoardingUpdateStepRepository>(TYPES.ON_BOARDING_UPDATE_STEP_REPOSITORY)
    .to(
      withDummies
        ? OnBoardingUpdateStepDummyRepository
        : OnBoardingUpdateStepHttpRepository,
    ).inSingletonScope();

  container
    .bind<UpdateOnBoardingStepCommand>(TYPES.UPDATE_ON_BOARDING_STEP_COMMAND)
    .to(UpdateOnBoardingStepCommand)
    .inSingletonScope();

  container
    .bind<SaveOnBoardingStepsDataService>(TYPES.SAVE_ON_BOARDING_STEPS_DATA_SERVICE)
    .to(SaveOnBoardingStepsDataService)
    .inSingletonScope();
}
