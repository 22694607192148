import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import CustomGoalMonthlyPaymentCalculationDummyRepository
  from '@/modules/flagship/custom-goal-calculations/infrastructure/repositories/custom-goal-monthly-payment-calculation-dummy-repository';
import CustomGoalMonthlyPaymentCalculationHttpRepository
  from '@/modules/flagship/custom-goal-calculations/infrastructure/repositories/custom-goal-monthly-payment-calculation-http-repository';
import CustomGoalFixedTimeCalculationDummyRepository
  from '@/modules/flagship/custom-goal-calculations/infrastructure/repositories/custom-goal-fixed-time-calculation-dummy-repository';
import CustomGoalFixedTimeCalculationHttpRepository
  from '@/modules/flagship/custom-goal-calculations/infrastructure/repositories/custom-goal-fixed-time-calculation-http-repository';

// Application
import CalculateCustomGoalMonthlyPaymentCalculationQuery
  from '@/modules/flagship/custom-goal-calculations/application/querys/calculate-custom-goal-monthly-payment-calculation-query';
import CalculateCustomGoalFixedTimeCalculationQuery
  from '@/modules/flagship/custom-goal-calculations/application/querys/calculate-custom-goal-fixed-time-calculation-query';

// Domain
import {
  CustomGoalMonthlyPaymentCalculationRepository,
} from '@/modules/flagship/custom-goal-calculations/domain/repositories/custom-goal-monthly-payment-calculation-repository';
import {
  CustomGoalFixedTimeCalculationRepository,
} from '@/modules/flagship/custom-goal-calculations/domain/repositories/custom-goal-fixed-time-calculation-repository';

export default function setupCustomGoalCalculations(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<CustomGoalMonthlyPaymentCalculationRepository>(
      TYPES.CUSTOM_GOAL_MONTHLY_PAYMENT_CALCULATION_REPOSITORY,
    )
    .to(
      withDummies
        ? CustomGoalMonthlyPaymentCalculationDummyRepository
        : CustomGoalMonthlyPaymentCalculationHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<CalculateCustomGoalMonthlyPaymentCalculationQuery>(
      TYPES.CALCULATE_CUSTOM_GOAL_MONTHLY_PAYMENT_CALCULATION_QUERY,
    )
    .to(CalculateCustomGoalMonthlyPaymentCalculationQuery)
    .inSingletonScope();

  container
    .bind<CustomGoalFixedTimeCalculationRepository>(
      TYPES.CUSTOM_GOAL_FIXED_TIME_CALCULATION_REPOSITORY,
    )
    .to(
      withDummies
        ? CustomGoalFixedTimeCalculationDummyRepository
        : CustomGoalFixedTimeCalculationHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<CalculateCustomGoalFixedTimeCalculationQuery>(
      TYPES.CALCULATE_CUSTOM_GOAL_FIXED_TIME_CALCULATION_QUERY,
    )
    .to(CalculateCustomGoalFixedTimeCalculationQuery)
    .inSingletonScope();
}
