import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import InvestorProfileOnRetirementBudgetDummyRepository
  from '@/modules/flagship/investor-profile/retirement-budget/infraestructure/repositories/investor-profile-on-retirement-budget-dummy-repository';
import InvestorProfileOnRetirementBudgetHttpRepository
  from '@/modules/flagship/investor-profile/retirement-budget/infraestructure/repositories/investor-profile-on-retirement-budget-http-repository';

// Application
import UpdateInvestorProfileOnRetirementBudgetCommand
  from '@/modules/flagship/investor-profile/retirement-budget/application/commands/update-investor-profile-on-retirement-budget-command';

// Domain
import {
  InvestorProfileOnRetirementBudgetRepository,
} from '@/modules/flagship/investor-profile/retirement-budget/domain/repositories/investor-profile-on-retirement-budget-repository';

export default function setupRetirementBudgetFlagship(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<InvestorProfileOnRetirementBudgetRepository>(
      TYPES.INVESTOR_PROFILE_ON_RETIREMENT_BUDGET_REPOSITORY,
    ).to(
      withDummies
        ? InvestorProfileOnRetirementBudgetDummyRepository
        : InvestorProfileOnRetirementBudgetHttpRepository,
    ).inSingletonScope();

  container
    .bind<UpdateInvestorProfileOnRetirementBudgetCommand>(
      TYPES.UPDATE_INVESTOR_PROFILE_ON_RETIREMENT_BUDGET_COMMAND,
    ).to(UpdateInvestorProfileOnRetirementBudgetCommand)
    .inSingletonScope();
}
