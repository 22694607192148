import TYPES from '../../types';

// Domain
import { CustomerPersonalityProfileEntity } from '../../domain/entities/customer-personality-profile-entity';
import { CustomerPersonalityProfileStateManager } from '../../domain/state/customer-personality-profile-state-manager';
import { CustomerPersonalityProfileRepository } from '../../domain/repositories/customer-personality-profile-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class GetCustomerPersonalityProfileQuery
  extends Query<Promise<CustomerPersonalityProfileEntity>> {
  @Inject(TYPES.CUSTOMER_PERSONALITY_PROFILE_REPOSITORY)
  private readonly repository!: CustomerPersonalityProfileRepository;

  @Inject(TYPES.CUSTOMER_PERSONALITY_PROFILE_STATE_MANAGER)
  private readonly state_manager!: CustomerPersonalityProfileStateManager;

  internalExecute():
    Promise<CustomerPersonalityProfileEntity> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded) {
        resolve(this.state_manager.state.item);
      } else {
        this.repository.get()
          .then((item) => {
            this.state_manager.patch({ item, loaded: true });
            resolve(item);
          })
          .catch(reject);
      }
    });
  }
}
