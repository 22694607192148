// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { AllianzPolicyIssuanceStatusRepository } from '../../../domain/repository';
import { AllianzPolicyIssuanceStatusEntity } from '../../../domain/entities/allianz-policy-issuance-status-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class AllianzPolicyIssuanceStatusDummyRepository
implements AllianzPolicyIssuanceStatusRepository {
  get(): Promise<AllianzPolicyIssuanceStatusEntity> {
    return Promise.resolve(fixtures);
  }
}
