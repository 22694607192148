import TYPES from '../../types';

// Domain
import { PersonalInformation } from '../../domain/dtos';
import { CustomerPersonalInformationStateManager } from '../../domain/state';
import { CustomerPersonalInformationRepository } from '../../domain/repositories';
import Query from '@/modules/shared/domain/use-cases/query';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
class GetCustomerPersonalInformationQuery extends Query<Promise<PersonalInformation>> {
  @Inject(TYPES.CUSTOMER_PERSONAL_INFORMATION_STATE_MANAGER)
  private readonly customer_personal_information_state_manager!:
    CustomerPersonalInformationStateManager;

  @Inject(TYPES.CUSTOMER_PERSONAL_INFORMATION_REPOSITORY)
  private readonly customer_personal_information_repository!: CustomerPersonalInformationRepository;

  internalExecute(): Promise<PersonalInformation> {
    return new Promise((resolve, reject) => {
      if (this.customer_personal_information_state_manager.state.loaded) {
        resolve(this.customer_personal_information_state_manager.state.data);
      } else {
        this.customer_personal_information_repository.get()
          .then((data) => {
            this.customer_personal_information_state_manager.put({
              data,
              loaded: true,
            });
            resolve(data);
          })
          .catch(reject);
      }
    });
  }
}

export default GetCustomerPersonalInformationQuery;
