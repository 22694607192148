import TYPES from '../../types';

// Domain
import { SourceEntity } from '../../domain/entities/source-entity';
import { SourceRepository } from '../../domain/repository/source-repository';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class SearchSourcesQuery extends Query<Promise<Array<SourceEntity>>> {
  @Inject(TYPES.CATALOG_KUSPIT_SOURCE_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.CATALOG_KUSPIT_SOURCE_REPOSITORY)
  private readonly repository!: SourceRepository

  internalExecute(): Promise<Array<SourceEntity>> {
    return new Promise((resolve, reject) => {
      if (!this.state_manager.state.loaded) {
        this.repository.execute()
          .then((items) => {
            this.state_manager.patch({ items, loaded: true });
            resolve(items);
          })
          .catch(reject);
      } else {
        resolve(this.state_manager.state.items);
      }
    });
  }
}
