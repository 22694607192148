// Domain
import { CreateCustomerKuspitAccountRepository } from '../../../../domain/repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CreateCustomerKuspitAccountDummyRepository
implements CreateCustomerKuspitAccountRepository {
  // eslint-disable-next-line class-methods-use-this
  create(): Promise<void> {
    return Promise.resolve();
  }
}
