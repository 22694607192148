import Injectable from '@/modules/shared/domain/di/injectable';
import UpdatePersonRepository from '../../../domain/repositories/update-person-repository';
import { UpdatePersonDto } from '../../../domain/dtos/update-person-dto';

@Injectable()
export default class UpdatePersonDummyRepository implements UpdatePersonRepository {
  // eslint-disable-next-line class-methods-use-this
  update(person_dto: UpdatePersonDto): Promise<void> {
    console.log('PERSON UPDATED: ', person_dto);

    return Promise.resolve();
  }
}
