// Infrastructure
import fixtures from './fixtures.json';

// Domain
import {
  RetirementFundLocationRepository,
} from '@/modules/flagship/catalogs/retirement-fund-location/domain/repositories/retirement-fund-location-repository';
import {
  RetirementFundLocationEntity,
} from '@/modules/flagship/catalogs/retirement-fund-location/domain/entities/retirement-fund-location-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class RetirementFundLocationDummyRepository
implements RetirementFundLocationRepository {
  get(): Promise<Array<RetirementFundLocationEntity>> {
    return Promise.resolve(fixtures);
  }
}
