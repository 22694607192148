// Domain
import {
  IncomeBehaviorInFollowingYearEntity,
} from '@/modules/flagship/catalogs/income-behavior-in-following-year/domain/entities/income-behavior-in-following-year-entity';

export default class IncomeBehaviorInFollowingYearState {
  loaded = false;

  items: Array<IncomeBehaviorInFollowingYearEntity> = [];
}
