import { FundingDetailsDto } from '../dtos/funding-details-dto';

export default class State {
  loaded = false;

  funding_details: FundingDetailsDto = {
    bank: '',
    kuspit_clabe: '',
  };
}
