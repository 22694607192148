import addCustomInvestorGoalModalInit from './add-custom-investor-goal-modal-init.json';
import addCustomInvestorGoalWizardModal from './add-custom-investor-goal-wizard-modal.json';
import addCustomInvestorGoalWizardPersonalGoal from './add-custom-investor-goal-wizard-personal-goal.json';
import addCustomInvestorGoalWizardCustomizeGoal from './add-custom-investor-goal-wizard-customize-goal.json';
import addCustomInvestorGoalWizardBeforeAdjustGoals from './add-custom-investor-goal-wizard-before-adjust-goals.json';
import addCustomInvestorGoalWizardAdjustGoals from './add-custom-investor-goal-wizard-adjust-goals.json';
import addCustomInvestorGoalWizardProcess from './add-custom-investor-goal-wizard-process.json';
import addCustomInvestorGoalModalSuccess from './add-custom-investor-goal-modal-success.json';
import addCustomInvestorGoalWizardLinkGoal from './link';

export default {
  'add-goals': {
    ...addCustomInvestorGoalModalInit,
    ...addCustomInvestorGoalWizardModal,
    ...addCustomInvestorGoalWizardPersonalGoal,
    ...addCustomInvestorGoalWizardCustomizeGoal,
    ...addCustomInvestorGoalWizardBeforeAdjustGoals,
    ...addCustomInvestorGoalWizardAdjustGoals,
    ...addCustomInvestorGoalWizardProcess,
    ...addCustomInvestorGoalWizardLinkGoal,
    ...addCustomInvestorGoalModalSuccess,
  },
};
