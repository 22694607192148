import contractingStatusModerateSignYourContract from './contracting-status-moderate-sign-your-contract.json';
import contractingStatusModerateSignatureReceived from './contracting-status-moderate-signature-received.json';
import contractingStatusModerateRequestReceived from './contracting-status-moderate-request-received.json';

export default {
  'contracting-status-moderate': {
    ...contractingStatusModerateSignYourContract,
    ...contractingStatusModerateSignatureReceived,
    ...contractingStatusModerateRequestReceived,
  },
};
