import TYPES from '@/modules/shared/types';

// Domain
import {
  CustomInvestorGoalCalculatorRepository,
} from '../../domain/repositories/custom-investor-goal-calculator-repository';
import { CustomInvestorGoalCalculatorDto }
  from '../../domain/dtos/custom-investor-goal-calculator-dto';
import {
  CustomGoalMaximumInitialAmountCalculationDto,
} from '@/modules/flagship/custom-investor-goal-calculator/domain/dtos/custom-goal-maximum-initial-amount-dto';
import {
  CustomGoalMinimumAccumulatedAmountCalculationDto,
} from '@/modules/flagship/custom-investor-goal-calculator/domain/dtos/custom-goal-minimum-accumulated-amount-dto';
import { CustomInvestorGoalCalculatorEntity }
  from '../../domain/entities/custom-investor-goal-calculator-entity';
import { CustomGoalMaximumInitialAmountCalculationEntity }
  from '../../domain/entities/custom-goal-maximum-initial-amount-calculation-entity';
import { CustomGoalMinimumAccumulatedAmountCalculationEntity }
  from '../../domain/entities/custom-goal-minimum-accumulated-amount-calculation-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class CustomInvestorGoalCalculatorHttpRepository implements
  CustomInvestorGoalCalculatorRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/custom-investor-goal-calculator/`;

  getCustomCalculation(custom_calculation_dto: CustomInvestorGoalCalculatorDto):
    Promise<CustomInvestorGoalCalculatorEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}by-definition/`, 'POST', custom_calculation_dto)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }

  calculateMaximumInitialAmount(
    maximum_initial_amount_calculation_dto: CustomGoalMaximumInitialAmountCalculationDto,
  ):
    Promise<CustomGoalMaximumInitialAmountCalculationEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}maximum-initial-amount/`, 'POST',
        maximum_initial_amount_calculation_dto)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }

  calculateMinimumAccumulatedAmount(
    minimum_accumulated_amount_calculation_dto: CustomGoalMinimumAccumulatedAmountCalculationDto,
  ):
    Promise<CustomGoalMinimumAccumulatedAmountCalculationEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}minimum-accumulated-amount/`, 'POST',
        minimum_accumulated_amount_calculation_dto)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
