














































































import { Component, Vue } from 'vue-property-decorator';
import LoginViewModel from '@/vue-app/view-models/views/login-view-model';

@Component({ name: 'Login' })
export default class Login extends Vue {
  login_view_model = Vue.observable(new LoginViewModel())

  async handleSubmit(event: Event) {
    event.preventDefault();
    const onboarding_completed = await this.login_view_model.handleSubmit();
    if (onboarding_completed !== null) {
      await this.$router.push(onboarding_completed ? '/' : '/on-boarding');
    }
  }

  async mounted() {
    await this.$store.dispatch('layout/setLayoutHeader', {
      title: this.login_view_model.translate('log_in'),
      right_action: {
        step: 0,
        label: this.login_view_model.translate('dont_have_an_account'),
        action: {
          type: 'link',
          href: '/sign-up',
          label: this.login_view_model.translate('signup'),
        },
      },
    });
  }
}

