import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import {
  AllianzPolicyIssuanceStatusHttpRepository,
  AllianzPolicyIssuanceStatusDummyRepository,
  PatchAllianzPolicyIssuanceStatusHttpRepository,
  PatchAllianzPolicyIssuanceStatusDummyRepository,
} from './infrastructure/repository';
import VueStateManager from './infrastructure/state/vue-state-manager';

// Application
import { GetAllianzPolicyIssuanceStatusQuery } from './application/queries';
import { PatchAllianzPolicyIssuanceStatusCommand } from './application/commands';

// Domain
import {
  AllianzPolicyIssuanceStatusRepository,
  PatchAllianzPolicyIssuanceStatusRepository,
} from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupAllianzPolicyIssuanceStatusAccount(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<StateManager>(TYPES.ALLIANZ_POLICY_ISSUANCE_STATUS_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<AllianzPolicyIssuanceStatusRepository>(TYPES.ALLIANZ_POLICY_ISSUANCE_STATUS_REPOSITORY)
    .to(
      withDummies
        ? AllianzPolicyIssuanceStatusDummyRepository
        : AllianzPolicyIssuanceStatusHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<GetAllianzPolicyIssuanceStatusQuery>(TYPES.GET_ALLIANZ_POLICY_ISSUANCE_STATUS_QUERY)
    .to(GetAllianzPolicyIssuanceStatusQuery)
    .inSingletonScope();

  container
    .bind<PatchAllianzPolicyIssuanceStatusRepository>(
      TYPES.PATCH_ALLIANZ_POLICY_ISSUANCE_STATUS_REPOSITORY,
    )
    .to(
      withDummies
        ? PatchAllianzPolicyIssuanceStatusDummyRepository
        : PatchAllianzPolicyIssuanceStatusHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<PatchAllianzPolicyIssuanceStatusCommand>(
      TYPES.PATCH_ALLIANZ_POLICY_ISSUANCE_STATUS_COMMAND,
    )
    .to(PatchAllianzPolicyIssuanceStatusCommand)
    .inSingletonScope();
}
