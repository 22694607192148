import TYPES from '../../types';

// Domain
import {
  RetirementInvestorGoalEntity,
} from '@/modules/flagship/retirement-investor-goal/domain/entities/retirement-investor-goal-entity';
import {
  RetirementInvestorGoalRepository,
} from '@/modules/flagship/retirement-investor-goal/domain/repositories/retirement-investor-goal-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class RetirementInvestorGoalCommand
  extends Command<RetirementInvestorGoalEntity> {
  @Inject(TYPES.RETIREMENT_INVESTOR_GOAL_REPOSITORY)
  private readonly repository!: RetirementInvestorGoalRepository;

  internalExecute(retirement_investor_entity: RetirementInvestorGoalEntity): Promise<void> {
    return this.repository.create(retirement_investor_entity);
  }
}
