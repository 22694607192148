





















































































































































import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import WithdrawalViewModel
  from '@/vue-app/view-models/components/kuspit-dashboard/withdrawals/withdrawal-view-model';
import WithdrawalInformation
  from '@/vue-app/components/kuspit-dashboard/withdrawals/information/WithdrawalInformation.vue';
import WithdrawalGoalsDistribution
  from '@/vue-app/components/kuspit-dashboard/withdrawals/information/WithdrawalGoalsDistribution.vue';
import WithdrawalsConfirmOperation
  from '@/vue-app/components/kuspit-dashboard/withdrawals/confirm_operation/WithdrawalConfirmOperation.vue';
import WithdrawalConfirmInformation
  from '@/vue-app/components/kuspit-dashboard/withdrawals/confirm_information/WithdrawalConfirmInformation.vue';
import WithdrawalSuccessfulOperation
  from '@/vue-app/components/kuspit-dashboard/withdrawals/sucessful_operation/WithdrawalSuccessfulOperation.vue';

@Component({
  name: 'ContractSavings',
  components: {
    WithdrawalReminder: () => import('@/vue-app/components/kuspit-dashboard/withdrawals/reminder/WithdrawalReminder.vue'),
    WithdrawalInformation,
    WithdrawalConfirmInformation,
    WithdrawalGoalsDistribution,
    WithdrawalsConfirmOperation,
    WithdrawalSuccessfulOperation,
    WithdrawalOperationInProcess: () => import('@/vue-app/components/kuspit-dashboard/withdrawals/operation_in_process/WithdrawalOperationInProcess.vue'),
    WithdrawalFailedOperation: () => import('@/vue-app/components/kuspit-dashboard/withdrawals/failed_operation/WithdrawalFailedOperation.vue'),
    WithdrawalStep: () => import('@/vue-app/components/kuspit-dashboard/withdrawals/WithdrawalStep.vue'),
  },
})
export default class Withdrawal extends Vue {
  @Prop(String)
  readonly service!: Withdrawal;

  withdrawal_view_model = Vue.observable(
    new WithdrawalViewModel(this),
  );

  $refs!: {
    withdrawal_information: WithdrawalInformation;
    withdrawal_goals_distribution: WithdrawalGoalsDistribution;
    withdrawal_confirm_information: WithdrawalConfirmInformation;
    withdrawal_confirm_operation: WithdrawalsConfirmOperation;
    withdrawal_successful_operation: WithdrawalSuccessfulOperation;
  }

  showModalConfirmInformation = (payload: any) => {
    this.withdrawal_view_model.is_there_any_linked_goal = true;
    this.withdrawal_view_model.setPayloadToSaveDistribution(payload);
    this.withdrawal_view_model.nextStep();
  }

  endProcess() {
    this.withdrawal_view_model.reset();
    this.$emit('refreshMovements');
  }

  @Watch('withdrawal_view_model.current_step')
  handleStepChange(new_current_step: number) {
    this.withdrawal_view_model.setCurrentComponent(new_current_step);
  }
}

