// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { AllianzRequestForMovementsToPolicyRepository } from '../../../domain/repository';
import { AllianzRequestForMovementsToPolicyEntity } from '../../../domain/entities/allianz-request-for-movements-to-policy-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class AllianzRequestForMovementsToPolicyDummyRepository implements
  AllianzRequestForMovementsToPolicyRepository {
  // eslint-disable-next-line class-methods-use-this
  search(): Promise<Array<AllianzRequestForMovementsToPolicyEntity>> {
    return Promise.resolve(fixtures);
  }
}
