import TYPES from '@/modules/shared/types';

// Domain
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';
import {
  FixedTimeCalculationsRepository,
} from '@/modules/flagship/emergency-fund-calculations/fixed-time-calculation/domain/repositories/fixed-time-calculations-repository';
import {
  EmergencyFundCalculationResponse,
} from '@/modules/flagship/emergency-fund-calculations/fixed-time-calculation/domain/entities/emergency-fund-calculation-response';
import {
  FixedTimeCalculationDto,
} from '@/modules/flagship/emergency-fund-calculations/fixed-time-calculation/domain/dtos/fixed-time-calculation-dto';

@Injectable()
export default class FixedTimeCalculationHttpRepository implements FixedTimeCalculationsRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/emergency-fund-investor-goal-fixed-time-calculation/`;

  calculate(monthly_required_amount: FixedTimeCalculationDto):
    Promise<EmergencyFundCalculationResponse> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint, 'POST', monthly_required_amount)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
