import fixtures from './fixtures.json';

// Domain
import {
  EmergencyFundLocationRepository,
} from '@/modules/flagship/catalogs/emergency-fund-location/domain/repositories/emergency-fund-location-repository';
import {
  EmergencyFundLocationEntity,
} from '@/modules/flagship/catalogs/emergency-fund-location/domain/entities/emergency-fund-location-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class EmergencyFundLocationDummyRepository implements
  EmergencyFundLocationRepository {
  get(): Promise<Array<EmergencyFundLocationEntity>> {
    return Promise.resolve(fixtures);
  }
}
