import Vue from 'vue';

// Domain
import {
  RetirementFundLocationStateManager,
} from '@/modules/flagship/catalogs/retirement-fund-location/domain/state/retirement-fund-location-state-manager';
import RetirementFundLocationState from '@/modules/flagship/catalogs/retirement-fund-location/domain/state/retirement-fund-location-state';
import Injectable from '@/modules/shared/domain/di/injectable';
import Observer from '@/modules/shared/domain/observer/observer';

@Injectable()
export default class RetirementFundLocationVueStateManager
implements RetirementFundLocationStateManager {
  // eslint-disable-next-line max-len
  private _state: RetirementFundLocationState = Vue.observable<RetirementFundLocationState>(new RetirementFundLocationState());

  private readonly observers: Observer[] = [];

  get state(): RetirementFundLocationState {
    return this._state;
  }

  set state(value: RetirementFundLocationState) {
    this._state = value;
    this.notifyAll();
  }

  patch(state: Partial<RetirementFundLocationState>) {
    type Keys = keyof RetirementFundLocationState;
    Object.entries(state).forEach(([key, value]) => {
      const accessor = key as Keys;

      if (value !== undefined) {
        // eslint-disable-next-line
        // @ts-ignore @typescript-eslint/ban-ts-ignore
        this.state[accessor] = value;
      }
    });
  }

  notifyAll() {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer) {
    this.observers.push(observer);
  }
}
