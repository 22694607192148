import TYPES from '../../types';

// Domain
import {
  CustomInvestorGoalRepository,
} from '@/modules/flagship/custom-investor-goal/domain/repositories/custom-investor-goal-repository';
import {
  SearchByCustomerStateManager,
} from '@/modules/flagship/custom-investor-goal/domain/state/search-by-customer-state-manager';
import {
  CustomInvestorGoalEntity,
} from '@/modules/flagship/custom-investor-goal/domain/entities/custom-investor-goal-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class GetCustomInvestorGoalSearchByCustomerQuery
  extends Query<Promise<Array<CustomInvestorGoalEntity>>> {
  @Inject(TYPES.CUSTOM_INVESTOR_GOAL_REPOSITORY)
  private readonly repository!: CustomInvestorGoalRepository;

  @Inject(TYPES.CUSTOM_INVESTOR_GOAL_SEARCH_BY_CUSTOMER_STATE_MANAGER)
  private readonly state_manager!: SearchByCustomerStateManager;

  internalExecute(reload: boolean): Promise<Array<CustomInvestorGoalEntity>> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded && !reload) {
        resolve(this.state_manager.state.items);
      } else {
        this.repository.search()
          .then((items) => {
            this.state_manager.patch({ items, loaded: true });
            resolve(items);
          })
          .catch(reject);
      }
    });
  }
}
