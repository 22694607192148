// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { AllianzCustomerAvailableBalanceRepository } from '../../../domain/repository';
import { AllianzCustomerAvailableBalanceEntity } from '../../../domain/entities/allianz-customer-available-balance-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class AllianzCustomerAvailableBalanceDummyRepository implements
AllianzCustomerAvailableBalanceRepository {
  execute(): Promise<AllianzCustomerAvailableBalanceEntity> {
    return Promise.resolve(fixtures);
  }
}
