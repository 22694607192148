import TYPES from '../../types';

// Domain
import {
  DistributeRecurrentBalanceDto,
} from '../../domain/dto/distribute-recurrent-balance-dto';
import {
  DistributeRecurrentBalanceRepository,
} from '../../domain/repositories/distribute-recurrent-balance-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class DistributeRecurrentBalanceCommand
  extends Command<DistributeRecurrentBalanceDto> {
  @Inject(TYPES.DISTRIBUTE_RECURRENT_BALANCE_REPOSITORY)
  private readonly repository!: DistributeRecurrentBalanceRepository;

  internalExecute(distribute_recurrent_balance_dto: DistributeRecurrentBalanceDto):
    Promise<void> {
    return this.repository.distribute(distribute_recurrent_balance_dto);
  }
}
