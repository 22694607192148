import TYPES from '@/modules/shared/types';

// Domain
import { AllianzAccountRepository } from '../../domain/repository';
import { AllianzAccountEntity } from '../../domain/entities/allianz-account-entity';
import { AllianzAccountIsOnCancellationProcessEntity } from '../../domain/entities/allianz-account-is-on-cancellation-process-entity';
import {
  RecurringContributionCancellerEntity,
} from '../../domain/entities/recurring-contribution-canceller-entity';
import {
  RecurringContributionCancelledEntity,
} from '../../domain/entities/recurring-contribution-cancelled-entity';
import { UpdateAccountNumberDto } from '@/modules/my-investment/allianz/allianz-account/domain/dtos/update-account-number-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class AllianzAccountHttpRepository implements AllianzAccountRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/allianz/account-by-customer-id/{CUSTOMER_ID}/`;

  private endpoint_suspend_recurring_contributions = `${process.env.VUE_APP_API_URL}/api/v1/app/allianz/cancel-recurring-contribution/`;

  private endpoint_account_is_on_cancellation_process = `${process.env.VUE_APP_API_URL}/api/v1/app/allianz/account-by-customer-id/is-on-cancellation-process/`;

  private endpoint_update_account_number = `${process.env.VUE_APP_API_URL}/api/v1/app/allianz/bank-account/{ALLIANZ_ACCOUNT_ID}/`;

  execute(customer_id: string): Promise<AllianzAccountEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint.replace('{CUSTOMER_ID}', customer_id), 'GET', undefined, 0)
        .then((response) => {
          response.json()
            .then(({ data, success, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }

  cancel(recurring_contribution_canceller_entity: RecurringContributionCancellerEntity):
    Promise<RecurringContributionCancelledEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint_suspend_recurring_contributions, 'POST', recurring_contribution_canceller_entity, 0)
        .then((response) => {
          response.json()
            .then(({ data, success, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }

  get_allianz_account_is_on_cancellation_process(): Promise<
    AllianzAccountIsOnCancellationProcessEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint_account_is_on_cancellation_process, 'GET', undefined, 0)
        .then((response) => {
          response.json()
            .then(({ data, success, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }

  updateAccountNumber(
    update_account_number_dto: UpdateAccountNumberDto,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const { allianz_account_id, ...payload } = update_account_number_dto;
      this.http_connector.execute(
        this.endpoint_update_account_number.replace('{ALLIANZ_ACCOUNT_ID}', allianz_account_id),
        'PATCH',
        payload,
      )
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => {
                reject(new Error(message));
              },
            );
          }
        })
        .catch(reject);
    });
  }
}
