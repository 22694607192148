import TYPES from '@/modules/shared/types';

// Domain
import { CustomGoalFixedTimeCalculationRepository } from '@/modules/flagship/custom-goal-calculations/domain/repositories/custom-goal-fixed-time-calculation-repository';
import { CustomGoalCalculationEntity } from '@/modules/flagship/custom-goal-calculations/domain/entities/custom-goal-calculation-entity';
import { CustomGoalFixedTimeCalculationDto } from '@/modules/flagship/custom-goal-calculations/domain/dtos/custom-goal-fixed-time-calculation-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
class CustomGoalFixedTimeCalculationHttpRepository
implements CustomGoalFixedTimeCalculationRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/custom-investor-goal-fixed-time-calculation/{custom_investor_goal_id}/`;

  calculate(custom_goal_fixed_time_calculation_dto: CustomGoalFixedTimeCalculationDto):
    Promise<CustomGoalCalculationEntity> {
    return new Promise((resolve, reject) => {
      const { custom_investor_goal_id, ...payload } = custom_goal_fixed_time_calculation_dto;
      this.http_connector
        .execute(this.endpoint.replace('{custom_investor_goal_id}', custom_investor_goal_id), 'POST', payload)
        .then((response) => {
          response.json().then(({ success, data, message }) => {
            if (success) {
              resolve(data);
            } else {
              reject(new Error(message));
            }
          });
        })
        .catch(reject);
    });
  }
}

export default CustomGoalFixedTimeCalculationHttpRepository;
