import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import SpecialContributionDummyRepository
  from './infrastructure/repository/special-contribution-dummy-repository/special-contribution-dummy-repository';
import SpecialContributionHttpRepository
  from './infrastructure/repository/special-contribution-http-repository';

// Application
import UploadVoucherSpecialContributionCommand
  from './application/commands/upload-voucher-special-contribution-command';
import GetNotifySpecialContributionQuery
  from './application/queries/get-notify-special-contribution-query';

// Domain
import {
  SpecialContributionRepository,
} from './domain/repository/special-contribution-repository';

export default function setupSpecialContribution(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<SpecialContributionRepository>(
      TYPES.SPECIAL_CONTRIBUTION_REPOSITORY,
    ).to(
      withDummies
        ? SpecialContributionDummyRepository
        : SpecialContributionHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetNotifySpecialContributionQuery>(
      TYPES.GET_NOTIFY_SPECIAL_CONTRIBUTION_QUERY,
    ).to(GetNotifySpecialContributionQuery)
    .inSingletonScope();

  container
    .bind<UploadVoucherSpecialContributionCommand>(
      TYPES.UPLOAD_VOUCHER_SPECIAL_CONTRIBUTION_COMMAND,
    ).to(UploadVoucherSpecialContributionCommand)
    .inSingletonScope();
}
