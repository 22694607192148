import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import EmergencyFundLocationVueStateManager
  from '@/modules/flagship/catalogs/emergency-fund-location/infrastructure/state/emergency-fund-location-vue-state-manager';
import EmergencyFundLocationHttpRepository
  from '@/modules/flagship/catalogs/emergency-fund-location/infrastructure/repositories/emergency-fund-location-http-repository';
import EmergencyFundLocationDummyRepository
  from '@/modules/flagship/catalogs/emergency-fund-location/infrastructure/repositories/emergency-fund-location-dummy-repository/emergency-fund-location-dummy-repository';

// Application
import GetEmergencyFundLocationQuery
  from '@/modules/flagship/catalogs/emergency-fund-location/application/query/get-emergency-fund-location-query';

// Domain
import {
  EmergencyFundLocationRepository,
} from '@/modules/flagship/catalogs/emergency-fund-location/domain/repositories/emergency-fund-location-repository';
import {
  EmergencyFundLocationStateManager,
} from '@/modules/flagship/catalogs/emergency-fund-location/domain/state/emergency-fund-location-state-manager';

export default function setupEmergencyFundLocation(container: Container) {
  const with_dummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<EmergencyFundLocationStateManager>(TYPES.EMERGENCY_FUND_LOCATION_STATE_MANAGER)
    .to(EmergencyFundLocationVueStateManager)
    .inRequestScope();

  container
    .bind<EmergencyFundLocationRepository>(TYPES.EMERGENCY_FUND_LOCATION_REPOSITORY)
    .to(
      with_dummies
        ? EmergencyFundLocationDummyRepository
        : EmergencyFundLocationHttpRepository,
    ).inRequestScope();

  container
    .bind<GetEmergencyFundLocationQuery>(TYPES.GET_EMERGENCY_FUND_LOCATION_QUERY)
    .to(GetEmergencyFundLocationQuery)
    .inRequestScope();
}
