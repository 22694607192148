import TYPES from '../../types';

// Domain
import { CustomGoalMinimumAccumulatedAmountCalculationEntity } from '@/modules/flagship/custom-investor-goal-calculator/domain/entities/custom-goal-minimum-accumulated-amount-calculation-entity';
import { CustomInvestorGoalCalculatorRepository } from '@/modules/flagship/custom-investor-goal-calculator/domain/repositories/custom-investor-goal-calculator-repository';
import { CustomGoalMinimumAccumulatedAmountCalculationDto } from '@/modules/flagship/custom-investor-goal-calculator/domain/dtos/custom-goal-minimum-accumulated-amount-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
class CalculateCustomGoalMinimumAccumulatedAmountQuery extends Query<
  Promise<CustomGoalMinimumAccumulatedAmountCalculationEntity>
> {
  @Inject(TYPES.CUSTOM_INVESTOR_GOAL_CALCULATOR_REPOSITORY)
  private readonly repository!: CustomInvestorGoalCalculatorRepository;

  internalExecute(
    payload: CustomGoalMinimumAccumulatedAmountCalculationDto,
  ): Promise<CustomGoalMinimumAccumulatedAmountCalculationEntity> {
    return this.repository.calculateMinimumAccumulatedAmount(payload);
  }
}

export default CalculateCustomGoalMinimumAccumulatedAmountQuery;
