import { Container } from 'inversify';
import TYPES from './types';

// Infrastructure
import ActivateSowosModerateDummyRepository
  from './infrastructure/repositories/activate-sowos-moderate-dummy-repository/activate-sowos-moderate-dummy-repository';
import ActivateSowosModerateHttpRepository
  from './infrastructure/repositories/activate-sowos-moderate-http-repository';

// Application
import ActivateSowosModerateCommand
  from './application/command/activate-sowos-moderate-command';

// Domain
import {
  ActivateSowosModerateRepository,
} from './domain/repositories/activate-sowos-moderate-repository';

export default function setupActivateSowosModerate(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<ActivateSowosModerateRepository>(TYPES.ACTIVATE_SOWOS_MODERATE_REPOSITORY)
    .to(
      withDummies
        ? ActivateSowosModerateDummyRepository
        : ActivateSowosModerateHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<ActivateSowosModerateCommand>(TYPES.ACTIVATE_SOWOS_MODERATE_COMMAND)
    .to(ActivateSowosModerateCommand)
    .inSingletonScope();
}
