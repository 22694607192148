// Domain
import { InvestorGoalRiskToleranceDto }
  from '../dto/investor-goal-risk-tolerance-dto';
import { Answer }
  from '@/modules/flagship/risk-tolerance-questions/domain/entities/risk-tolerance-questions-entity';

export default class State {
  investor_goal_risk_tolerance_dto: InvestorGoalRiskToleranceDto = {
    customer_id: '',
    investor_goal_id: '',
    investment_product_fund_type_id: '',
    answers: [],
  };

  selected_answers: Array<Answer> = [];

  user_chose_a_warning_answer = false;
}
