// Domain
import {
  AnonymousTicketRepository,
} from '@/modules/zendesk/anonymous-ticket/domain/repositories/anonymous-ticket-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class AnonymousTicketDummyRepository implements AnonymousTicketRepository {
  create(): Promise<void> {
    return Promise.resolve();
  }
}
