import TYPES from '../../types';

// Domain
import {
  InvestorProfileRepository,
} from '@/modules/flagship/investor-profile/investor-profile/domain/repositories/investor-profile-repository';
import {
  InvestorProfileEntity,
} from '@/modules/flagship/investor-profile/investor-profile/domain/entities/investor-profile-entity';
import {
  InvestorProfileStateManager,
} from '@/modules/flagship/investor-profile/investor-profile/domain/state/investor-profile-state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetInvestorProfileFlagshipProfilingQuery
  extends Query<Promise<InvestorProfileEntity>> {
  @Inject(TYPES.INVESTOR_PROFILE_REPOSITORY)
  private readonly repository!: InvestorProfileRepository;

  @Inject(TYPES.INVESTOR_PROFILE_STATE_MANAGER)
  private readonly state_manager!: InvestorProfileStateManager;

  internalExecute(loadStateManager = true): Promise<InvestorProfileEntity> {
    return new Promise((resolve, reject) => {
      if (loadStateManager && this.state_manager.state.loaded) {
        resolve(this.state_manager.state.investor_profile);
      } else {
        this.repository.get()
          .then((investor_profile) => {
            this.state_manager.patch({ investor_profile, loaded: true });
            resolve(investor_profile);
          })
          .catch(reject);
      }
    });
  }
}
