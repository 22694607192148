import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';

// Application
import EditEmergencyFundGoalPlanBudgetService
  from './application/services/edit-emergency-fund-goal-plan-budget-service';
import EditEmergencyFundGoalPlanCurrentAmountService
  from './application/services/edit-emergency-fund-goal-plan-current-amount-service';
import EditEmergencyFundGoalPlanCurrentPlanService
  from './application/services/edit-emergency-fund-goal-plan-current-plan-service';
import EditEmergencyFundGoalPlanDefineByService
  from './application/services/edit-emergency-fund-goal-plan-define-by-service';

// Domain
import { StateManager } from './domain/state/state-manager';

export default function setupEditEmergencyFundGoalPlan(container: Container) {
  container
    .bind<StateManager>(TYPES.EDIT_EMERGENCY_FUND_GOAL_PLAN_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<EditEmergencyFundGoalPlanBudgetService>(TYPES
      .EDIT_EMERGENCY_FUND_GOAL_PLAN_BUDGET_SERVICE)
    .to(EditEmergencyFundGoalPlanBudgetService)
    .inSingletonScope();

  container
    .bind<EditEmergencyFundGoalPlanCurrentAmountService>(TYPES
      .EDIT_EMERGENCY_FUND_GOAL_PLAN_CURRENT_AMOUNT_SERVICE)
    .to(EditEmergencyFundGoalPlanCurrentAmountService)
    .inSingletonScope();

  container
    .bind<EditEmergencyFundGoalPlanCurrentPlanService>(TYPES
      .EDIT_EMERGENCY_FUND_GOAL_PLAN_CURRENT_PLAN_SERVICE)
    .to(EditEmergencyFundGoalPlanCurrentPlanService)
    .inSingletonScope();

  container
    .bind<EditEmergencyFundGoalPlanDefineByService>(TYPES
      .EDIT_EMERGENCY_FUND_GOAL_PLAN_DEFINE_BY_SERVICE)
    .to(EditEmergencyFundGoalPlanDefineByService)
    .inSingletonScope();
}
