// Infrastructure
import fixtures from './fixtures.json';

// Domain
import {
  IncompleteProfileRepository,
} from '@/modules/flagship/investor-profile/incomplete-profile/domain/repositories/incomplete-profile-repository';
import {
  IncompleteProfileEntity,
} from '@/modules/flagship/investor-profile/incomplete-profile/domain/entities/incomplete-profile-entity';

import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class IncompleteProfileDummyRepository implements IncompleteProfileRepository {
  get(): Promise<IncompleteProfileEntity> {
    return Promise.resolve(fixtures);
  }

  update(): Promise<any> {
    return Promise.resolve();
  }
}
