














































































import { Component, Vue } from 'vue-property-decorator';
import AttachedNewAccountStatementViewModel
  from '@/vue-app/view-models/views/document-validation/attached-new-account-statement-view-model';

@Component({ name: 'AttachedNewAccountStatement' })
export default class AttachedNewAccountStatement extends Vue {
  attached_account_statement_view_model = Vue
    .observable(new AttachedNewAccountStatementViewModel());

  async mounted() {
    if (this.$route?.query?.token) {
      await this.attached_account_statement_view_model
        .initialize(this.$route?.query?.token.toString());
    } else {
      this.attached_account_statement_view_model.goToLoginPage();
    }
  }

  destroyed() {
    this.attached_account_statement_view_model.deleteTokenFromStorage();
  }
}

