import { MunicipalityEntity } from '../entities/municipality-entity';
import { SearchDto } from '../dtos/search-dto';

export default class State {
  current_search: SearchDto = {
    codpais: '',
    codestado: '',
  };

  items: Array<MunicipalityEntity> = [];
}
