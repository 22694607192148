import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import LinkedGoalVueStateManager from './infrastructure/state/linked-goal-vue-state-manager';

// Application
import LinkedGoalStateManagerService from './application/services/linked-goal-state-manager-service';

// Domain
import { LinkedGoalStateManager } from './domain/state/linked-goal-state-manager';

export default function setupLinkedInvestorGoal(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<LinkedGoalStateManager>(TYPES.LINKED_INVESTOR_GOAL_STATE_MANAGER)
    .to(LinkedGoalVueStateManager)
    .inSingletonScope();

  container
    .bind<LinkedGoalStateManagerService>(
      TYPES.LINKED_GOAL_STATE_MANAGER_SERVICE,
    ).to(LinkedGoalStateManagerService)
    .inSingletonScope();
}
