// Infrastructure
import fixtures from './fixtures.json';

// Domain
import {
  CustomGoalTypesRepository,
} from '@/modules/flagship/catalogs/custom-goal-types/domain/repositories/custom-goal-types-repository';
import {
  CustomGoalTypesEntity,
} from '@/modules/flagship/catalogs/custom-goal-types/domain/entities/custom-goal-types-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CustomGoalTypesDummyRepository
implements CustomGoalTypesRepository {
  get(): Promise<Array<CustomGoalTypesEntity>> {
    return Promise.resolve(fixtures);
  }
}
