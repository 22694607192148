import Controller from '@/modules/shared/domain/use-cases/controller';
import { StepEntity } from '../../domain/entities/step-entity';

export default class GetOnBoardingCurrentStepController extends Controller<number, StepEntity> {
  internalExecute(step: StepEntity): number {
    switch (step.current_step) {
      case 'identity_verification':
        return 1;
      case 'confirm_identity_data':
        if (step.payload.status === 'confirmed') {
          return 3;
        }

        return 2;
      default:
        return 0;
    }
  }
}
