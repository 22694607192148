import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';
import { requiredRule } from '@/vue-app/utils/form-rules';
import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import { GetCustomerWithdrawalDetailsQuery } from '@/modules/my-investment/kuspit/withdrawal/application/queries';
import { GetCustomerKuspitPositionDetailsQuery }
  from '@/modules/my-investment/kuspit/customer-kuspit-position/application/queries';

// Domain
import {
  CustomerWithdrawalDetailsEntity,
} from '@/modules/my-investment/kuspit/withdrawal/domain/entities/customer-withdrawal-details-entity';
import Inject from '@/modules/shared/domain/di/inject';

export default class WithdrawalInformationViewModel {
  @Inject(TYPES.GET_CUSTOMER_WITHDRAWAL_DETAILS_QUERY)
  private readonly get_customer_withdrawal_details_query!: GetCustomerWithdrawalDetailsQuery;

  @Inject(TYPES.GET_CUSTOMER_KUSPIT_POSITION_DETAILS_QUERY)
  private readonly get_customer_position_details_query!: GetCustomerKuspitPositionDetailsQuery;

  private readonly i18n_namespace = 'components.kuspit-dashboard.withdrawals.information';

  private readonly view!: any;

  is_loading = false;

  is_valid_form = false;

  available_balance = 0;

  inputs = {
    source_account: '',
    destination_account: '',
    amount_to_withdraw: '',
    concept: '',
  };

  input_rules = {
    amount_to_withdraw: [
      requiredRule,
      (value: string) => (
        parseFloat(value.replace(/[^0-9.-]/g, '')) >= 1
        || i18n.tc('utils.form-rules.minimum_error', 0, { value: '$1.00 MXN' }).toString()
      ),
      (value: string) => (
        parseFloat(value.replace(/[^0-9.-]/g, '')) <= this.available_balance
        || i18n.tc(
          'utils.form-rules.maximum_error',
          0,
          { value: `${currencyFormat(this.available_balance)} MXN` },
        ).toString()
      ),
    ],
    concept: [
      requiredRule,
      (value: string) => (
        value.length <= 20
        || i18n.tc('utils.form-rules.max_length_error', 0, { value: '20' }).toString()
      ),
    ],
  };

  get withdrawal_details() {
    return this.get_customer_withdrawal_details_query.execute();
  }

  public constructor(view: any) {
    this.view = view;
  }

  translate = (value: string) => (
    i18n.t(`${this.i18n_namespace}.${value}`) as string
  )

  reset = () => {
    this.inputs.amount_to_withdraw = '';
    this.inputs.concept = '';
  }

  formatSourceAccount = (withdrawal_details: CustomerWithdrawalDetailsEntity): string => `${withdrawal_details.source_account.bank}
    - ${withdrawal_details.source_account.clabe}
    - ${currencyFormat(this.available_balance)} MXN`;

  formatDestinationAccount = (withdrawal_details: CustomerWithdrawalDetailsEntity): string => {
    const key = withdrawal_details.destination_account.clabe;
    if (key.length > 5) {
      return `${withdrawal_details.destination_account.bank}
      **** ${key.substr(key.length - 5)}`;
    }
    return '';
  }

  nextStep = () => {
    this.view.$emit('nextStep', this.inputs);
  }

  initialize = async (latitude: string, longitude: string) => {
    this.is_loading = true;
    const withdrawal_details = await this.get_customer_withdrawal_details_query.execute({
      latitude,
      longitude,
    });
    this.available_balance = parseFloat(withdrawal_details.purchasing_power.amount);
    this.inputs.source_account = this.formatSourceAccount(withdrawal_details);
    this.inputs.destination_account = this.formatDestinationAccount(withdrawal_details);
    this.is_loading = false;
  }

  cancel = () => {
    this.view.$emit('endProcess');
  }

  emitLoading = (is_loading: boolean) => {
    this.view.$emit('loadingInfo', is_loading);
  }
}
