import TYPES from '../../types';
import Injectable from '../../domain/di/injectable';
import Inject from '../../domain/di/inject';
import { Permissions } from '../../domain/permissions';
import { Geolocation } from '../../domain/geolocation';
import { PermissionsDto } from '../../domain/dtos/permissions-dto';

@Injectable()
export default class DefaultPermissions implements Permissions {
  @Inject(TYPES.GEOLOCATION)
  private readonly geolocation!: Geolocation;

  async getCurrentPermissions(): Promise<PermissionsDto> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const current_permissions: PermissionsDto = JSON.parse(sessionStorage.getItem('permissions')!);
    const permissions: PermissionsDto = {
      geolocation: current_permissions?.geolocation || false,
    };

    if (current_permissions) {
      if (!current_permissions.geolocation) {
        try {
          await this.geolocation.getCurrentPosition();
          permissions.geolocation = true;
        } catch {
          permissions.geolocation = false;
        }
      }
    }

    sessionStorage.setItem('permissions', JSON.stringify(permissions));

    return permissions;
  }
}
