import UnautheneticatedLayout from '@/vue-app/layouts/UnauthenticatedLayout.vue';
import UpdateIdentityDocument from '@/vue-app/views/update-identity-document/UpdateIdentityDocument.vue';
import POLICIES from '../security/policies';

export default {
  path: '/update-identity-document',
  component: UnautheneticatedLayout,
  children: [
    {
      path: '/',
      name: 'UpdateIdentityDocument',
      component: UpdateIdentityDocument,
      meta: {
        access_policy: POLICIES.UNAUTHENTICATED,
      },
    },
  ],
};
