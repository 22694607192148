import TYPES from '../../types';

// Domain
import {
  HabitsAvailableBudgetCalculationDto,
} from '@/modules/flagship/habits-available-budget-calculation/domain/dtos/habits-available-budget-calculation-dto';
import {
  HabitsAvailableBudgetCalculationRepository,
} from '@/modules/flagship/habits-available-budget-calculation/domain/repositories/habits-available-budget-calculation-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CreateHabitsAvailableBudgetCalculationCommand
  extends Command<HabitsAvailableBudgetCalculationDto> {
  @Inject(TYPES.HABITS_AVAILABLE_BUDGET_CALCULATION_REPOSITORY)
  private readonly repository!: HabitsAvailableBudgetCalculationRepository;

  internalExecute(available_budget_dto: HabitsAvailableBudgetCalculationDto): Promise<void> {
    return this.repository.create(available_budget_dto);
  }
}
