import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import RiskToleranceQuestionsDummyRepository
  from './infrastructure/repositories/risk-tolerance-questions-dummy-repository/risk-tolerance-questions-dummy-repository';
import RiskToleranceQuestionsHttpRepository
  from './infrastructure/repositories/risk-tolerance-questions-http-repository';
import RiskToleranceQuestionsVueStateManager
  from './infrastructure/state/risk-tolerance-questions-vue-state-manager';

// Application
import GetRiskToleranceQuestionsQuery
  from './application/queries/get-risk-tolerance-questions-query';

// Domain
import {
  RiskToleranceQuestionsRepository,
} from './domain/repositories/risk-tolerance-questions-repository';
import {
  RiskToleranceQuestionsStateManager,
} from './domain/state/risk-tolerance-questions-state-manager';

export default function setupRiskToleranceQuestions(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<RiskToleranceQuestionsStateManager>(TYPES.RISK_TOLERANCE_QUESTIONS_STATE_MANAGER)
    .to(RiskToleranceQuestionsVueStateManager)
    .inSingletonScope();

  container
    .bind<RiskToleranceQuestionsRepository>(
      TYPES.RISK_TOLERANCE_QUESTIONS_REPOSITORY,
    ).to(
      withDummies
        ? RiskToleranceQuestionsDummyRepository
        : RiskToleranceQuestionsHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetRiskToleranceQuestionsQuery>(TYPES.GET_RISK_TOLERANCE_QUESTIONS_QUERY)
    .to(GetRiskToleranceQuestionsQuery)
    .inSingletonScope();
}
