// Domain
import { TokenRefreshRepository } from '../../../domain/repositories';
import { RefreshTokenEntity } from '../../../domain/entities';
import injectable from '@/modules/shared/domain/di/injectable';

@injectable()
export default class TokenRefreshDummyRepository implements TokenRefreshRepository {
  // eslint-disable-next-line class-methods-use-this
  update(): Promise<RefreshTokenEntity> {
    return Promise.resolve({ token: '' });
  }
}
