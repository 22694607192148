import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';
import { NationalityEntity } from '../../domain/entities/nationality-entity';
import StateManager from '../../domain/state/state-manager';
import SearchNationalitiesRepository
  from '../../domain/repositories/search-nationalities-repository';

@Injectable()
class OnBoardingSearchNationalitiesQuery extends Query<Array<NationalityEntity>> {
  @Inject(TYPES.ON_BOARDING_CATALOGS_NATIONALITIES_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.ON_BOARDING_CATALOGS_SEARCH_NATIONALITIES_REPOSITORY)
  private readonly repository!: SearchNationalitiesRepository;

  internalExecute() {
    if (!this.state_manager.state.loaded) {
      this.repository.findAll()
        .then((items) => {
          this.state_manager.patch({ items, loaded: true });
        });
    }

    return this.state_manager.state.items;
  }
}

export default OnBoardingSearchNationalitiesQuery;
