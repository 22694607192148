import { Container } from 'inversify-props';
import setupCustomerPersonModule from './person/setup';
import setupCustomerPersonalInformationModule from './personal-information/setup';
import setupCustomerNotificationsModule from './customer-notification/setup';

export default function setupCustomerModule(container: Container) {
  setupCustomerPersonModule(container);
  setupCustomerPersonalInformationModule(container);
  setupCustomerNotificationsModule(container);
}
