import { CreateKuspitAccountStatusEntity } from '../entities/create-kuspit-account-status-entity';

export default class State {
  loaded = false;

  status: CreateKuspitAccountStatusEntity = {
    id: '',
    has_problem: false,
    message: '',
    percent: 0,
    step_name: '',
    step_number: 0,
    user_id: '',
  };
}
