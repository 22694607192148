import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import InvestmentSuggestionDummyRepository
  from '@/modules/investment-suggestion/infraestructure/repositories/investment-suggestion-dummy-repository/investment-suggestion-dummy-repository';
import InvestmentSuggestionHttpRepository
  from '@/modules/investment-suggestion/infraestructure/repositories/investment-suggestion-http-repository';
import InvestmentSuggestionVueStateManager
  from '@/modules/investment-suggestion/infraestructure/state/investment-suggestion-vue-state-manager';

// Application
import InvestmentSuggestionQuery
  from '@/modules/investment-suggestion/application/queries/investment-suggestion-query';
import InvestmentSuggestionCommand
  from '@/modules/investment-suggestion/application/commands/investment-suggestion-command';

// Domain
import {
  InvestmentSuggestionStateManager,
} from '@/modules/investment-suggestion/domain/state/investment-suggestion-state-manager';
import {
  InvestmentSuggestionRepository,
} from '@/modules/investment-suggestion/domain/repositories/investment-suggestion-repository';

export default function setupInvestmentSuggestion(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<InvestmentSuggestionStateManager>(TYPES.INVESTMENT_SUGGESTION_STATE_MANAGER)
    .to(InvestmentSuggestionVueStateManager)
    .inSingletonScope();

  container
    .bind<InvestmentSuggestionRepository>(TYPES.INVESTMENT_SUGGESTION_REPOSITORY)
    .to(
      withDummies
        ? InvestmentSuggestionDummyRepository
        : InvestmentSuggestionHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<InvestmentSuggestionQuery>(TYPES.GET_INVESTMENT_SUGGESTION_QUERY)
    .to(InvestmentSuggestionQuery)
    .inSingletonScope();

  container
    .bind<InvestmentSuggestionCommand>(TYPES.CREATE_INVESTMENT_SUGGESTION_COMMAND)
    .to(InvestmentSuggestionCommand)
    .inSingletonScope();
}
