import TYPES from '../../types';

// Domain
import { CustomerInvestorGoalBalanceEntity } from '../../domain/entities/customer-investor-goal-balance-entity';
import { CustomerInvestorGoalBalanceQueryEntity } from '../../domain/entities/customer-investor-goal-balance-query-entity';
import { CustomerInvestorGoalBalanceStateManager } from '../../domain/state/customer-investor-goal-balance-state-manager';
import { CustomerInvestorGoalBalanceRepository } from '../../domain/repositories/customer-investor-goal-balance-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class GetCustomerInvestorGoalBalanceQuery
  extends Query<Promise<CustomerInvestorGoalBalanceEntity>> {
  @Inject(TYPES.CUSTOMER_INVESTOR_GOAL_BALANCE_REPOSITORY)
  private readonly repository!: CustomerInvestorGoalBalanceRepository;

  @Inject(TYPES.CUSTOMER_INVESTOR_GOAL_BALANCE_STATE_MANAGER)
  private readonly state_manager!: CustomerInvestorGoalBalanceStateManager;

  internalExecute(customer_investor_goal_balance_query: CustomerInvestorGoalBalanceQueryEntity):
    Promise<CustomerInvestorGoalBalanceEntity> {
    return new Promise((resolve, reject) => {
      this.repository.get(customer_investor_goal_balance_query.investment_product_id)
        .then((item) => {
          this.state_manager.patch({ item, loaded: true });
          resolve(item);
        })
        .catch(reject);
    });
  }
}
