import TYPES from '../../types';

// Domain
import {
  InvestorGoalDetailEntity,
} from '@/modules/flagship/investor-goal/investor-goal-detail/domain/entities/investor-goal-detail-entity';
import {
  InvestorGoalDetailRepository,
} from '@/modules/flagship/investor-goal/investor-goal-detail/domain/repositories/investor-goal-detail-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class GetInvestorGoalDetailQuery extends Query<Promise<InvestorGoalDetailEntity>> {
  @Inject(TYPES.INVESTOR_GOAL_DETAIL_REPOSITORY)
  private readonly repository!: InvestorGoalDetailRepository;

  internalExecute(investor_goal_id: string): Promise<InvestorGoalDetailEntity> {
    return new Promise((resolve, reject) => {
      this.repository.get_detail(investor_goal_id)
        .then((item) => {
          resolve(item);
        })
        .catch(reject);
    });
  }
}
