import TYPES from '../../types';

// Domain
import {
  SurveyResponseUnlinkGoalFromModerateDto,
} from '@/modules/flagship/contact-points/sowos-moderate/domain/dtos/survey-response-unlink-goal-from-moderate-dto';
import {
  ContactPointSowosModerateRepository,
} from '@/modules/flagship/contact-points/sowos-moderate/domain/repositories/contact-point-sowos-moderate-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CreateResponseUnlinkGoalFromModerateCommand
  extends Command<SurveyResponseUnlinkGoalFromModerateDto> {
  @Inject(TYPES.CONTACT_POINT_SOWOS_MODERATE_REPOSITORY)
  private readonly repository!: ContactPointSowosModerateRepository;

  internalExecute(
    survey_response_unlink_goal_from_moderate: SurveyResponseUnlinkGoalFromModerateDto,
  ): Promise<void> {
    return this.repository.createResponseUnlinkGoalFromModerate(
      survey_response_unlink_goal_from_moderate,
    );
  }
}
