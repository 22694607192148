// Application

// Domain

export default class PerformanceViewModel {
  i18n_namespace = 'components.kuspit-dashboard.performance';

  performance_value = 0.0;

  performance_percentage = 0.0;

  series = [];

  chart_options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      parentHeightOffset: 0,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0,
        },
      },
    }],
    colors: ['#0047BA', '#64D2E0'],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        columnWidth: '40%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['Jun.', 'Jul.', 'Ago.', 'Sept.', 'Oct.', 'Nov.', 'Dic.'],
      labels: {
        style: {
          colors: '#75A4FE',
        },
      },
    },
    legend: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
  };
}
