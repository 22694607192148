import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import {
  CreateCustomerKuspitAccountDummyRepository,
  CreateCustomerKuspitAccountHttpRepository,
} from './infrastructure/repository';
import VueStateManager from './infrastructure/state-manager/vue-state-manager';

// Application
import { CreateCustomerKuspitAccountCommand } from './application/commands';
import { CreateCustomerKuspitAccountSubscription } from './application/subscriptions';
import { GetCreateCustomerKuspitAccountStatusQuery } from './application/queries';

// Domain
import {
  CreateCustomerKuspitAccountRepository,
} from './domain/repository';
import { StateManager } from './domain/state-manager';

export default function setupCustomerKuspitAccount(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<StateManager>(TYPES.CUSTOMER_KUSPIT_ACCOUNT_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<CreateCustomerKuspitAccountRepository>(TYPES.CREATE_CUSTOMER_KUSPIT_ACCOUNT_REPOSITORY)
    .to(
      withDummies
        ? CreateCustomerKuspitAccountDummyRepository
        : CreateCustomerKuspitAccountHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<CreateCustomerKuspitAccountCommand>(TYPES.CREATE_CUSTOMER_KUSPIT_ACCOUNT_COMMAND)
    .to(CreateCustomerKuspitAccountCommand)
    .inSingletonScope();

  container
    .bind<CreateCustomerKuspitAccountSubscription>(
      TYPES.CREATE_CUSTOMER_KUSPIT_ACCOUNT_STATUS_SUBSCRIPTION,
    )
    .to(CreateCustomerKuspitAccountSubscription)
    .inSingletonScope();

  container
    .bind<GetCreateCustomerKuspitAccountStatusQuery>(
      TYPES.GET_CREATE_CUSTOMER_KUSPIT_ACCOUNT_STATUS_QUERY,
    )
    .to(GetCreateCustomerKuspitAccountStatusQuery)
    .inSingletonScope();
}
