import { FileEntity } from '../entities/file-entity';

export default class State {
  current_file_id = '';

  file: FileEntity = {
    id: '',
    name: '',
    mime_type: '',
    storage_source: '',
    config: {
      bucket_name: '',
    },
    content: '',
    created_at: '',
    updated_at: '',
  };
}
