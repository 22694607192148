// Domain
import {
  ExitPollOptionsEntity,
} from '@/modules/flagship/catalogs/exit-poll-options/domain/entities/exit-poll-options-entity';

export default class ExitPollOptionsState {
  loaded = false;

  items: Array<ExitPollOptionsEntity> = [];
}
