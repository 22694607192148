// Domain
import {
  InvestmentSuggestionEntity,
} from '@/modules/investment-suggestion/domain/entities/investment-suggestion-entity';

export default class InvestmentSuggestionState {
  loaded = false;

  item: InvestmentSuggestionEntity = {
    id: '',
    customer_id: '',
    times_shown: 0,
    times_closed: 0,
    must_be_shown: false,
    created_at: '',
    updated_at: '',
  };
}
