// Domain
import { UploadFileProofOfAddressRepository } from '../../../domain/repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class UploadFileProofOfAddressDummyRepository
implements UploadFileProofOfAddressRepository {
  // eslint-disable-next-line class-methods-use-this
  upload(): Promise<void> {
    return Promise.resolve();
  }
}
