// Domain
import { EventBus, EventMessage } from '../../../domain/bus/event-bus';
import Injectable from '../../../domain/di/injectable';

@Injectable()
export default class WindowEventBus implements EventBus {
  publish(message: EventMessage) {
    const event_message = new CustomEvent(message.name, {
      detail: message.body,
    });

    window.dispatchEvent(event_message);
  }
}
