import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';
import { UserEntity } from '../../domain/entities/user-entity';
import { CurrentUserRepository } from '../../domain/repositories/current-user-repository';

@Injectable()
export default class GetCurrentUserQuery extends Query<Promise<UserEntity>> {
  @Inject(TYPES.CURRENT_USER_REPOSITORY)
  private readonly repository!: CurrentUserRepository;

  internalExecute(): Promise<UserEntity> {
    return this.repository.get();
  }
}
