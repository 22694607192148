// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { InvestmentProviderRepository } from '../../../domain/repository';
import { InvestmentProviderEntity } from '../../../domain/entities/investment-provider-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class InvestmentProviderDummyRepository implements InvestmentProviderRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(): Promise<Array<InvestmentProviderEntity>> {
    return Promise.resolve(fixtures);
  }
}
