// Infrastructure
import fixtures from './fixtures.json';

// Domain
import {
  InvestmentGoalsRepository,
} from '@/modules/flagship/catalogs/investment-goals/domain/repositories/investment-goals-repository';
import {
  InvestmentGoalsEntity,
} from '@/modules/flagship/catalogs/investment-goals/domain/entities/investment-goals-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class InvestmentGoalsDummyRepository implements InvestmentGoalsRepository {
  get(): Promise<Array<InvestmentGoalsEntity>> {
    return Promise.resolve(fixtures);
  }
}
