import TYPES from '@/modules/shared/types';

// Domain
import {
  ContactPointSowosModerateRepository,
} from '@/modules/flagship/contact-points/sowos-moderate/domain/repositories/contact-point-sowos-moderate-repository';
import {
  SurveyResponseNotReadyToContractModerateDto,
} from '@/modules/flagship/contact-points/sowos-moderate/domain/dtos/survey-response-not-ready-to-contract-moderate-dto';
import {
  SurveyResponseUnlinkGoalFromModerateDto,
} from '@/modules/flagship/contact-points/sowos-moderate/domain/dtos/survey-response-unlink-goal-from-moderate-dto';
import {
  PollResultEntity,
} from '@/modules/flagship/contact-points/sowos-moderate/domain/entities/poll-result-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class ContactPointSowosModerateHttpRepository
implements ContactPointSowosModerateRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/investment-product-fund-type-contract-proposal/`;

  private readonly endpoint_survey = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/investment-product-fund-type-contract-status-poll/`;

  getModerateInformation(investment_product_fund_type_id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}${investment_product_fund_type_id}/`)
        .then((response) => {
          response.json()
            .then(({ success, error }) => {
              if (success) {
                resolve();
              } else {
                reject(new Error(error));
              }
            });
        })
        .catch(reject);
    });
  }

  createSurveyNotReadyToContractModerate(
    survey_response_not_ready_to_contract_moderate: SurveyResponseNotReadyToContractModerateDto,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint_survey}initial-poll-status/`, 'POST', survey_response_not_ready_to_contract_moderate)
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => {
                reject(new Error(message));
              },
            );
          }
        })
        .catch(reject);
    });
  }

  searchAnswersInitialPoll(): Promise<Array<PollResultEntity>> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint_survey}initial-poll-status/`)
        .then((response) => {
          response.json()
            .then(({ success, error, data }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(error));
              }
            });
        })
        .catch(reject);
    });
  }

  searchAnswersExitPoll(): Promise<Array<PollResultEntity>> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint_survey}exit-poll-status/`)
        .then((response) => {
          response.json()
            .then(({ success, error, data }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(error));
              }
            });
        })
        .catch(reject);
    });
  }

  createResponseUnlinkGoalFromModerate(
    survey_response_unlink_goal_from_moderate: SurveyResponseUnlinkGoalFromModerateDto,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint_survey}exit-poll-status/`, 'POST', survey_response_unlink_goal_from_moderate)
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => {
                reject(new Error(message));
              },
            );
          }
        })
        .catch(reject);
    });
  }
}
