import TYPES from '../../types';

// Domain
import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';
import {
  FixedTimeCalculationDto,
} from '@/modules/flagship/emergency-fund-calculations/fixed-time-calculation/domain/dtos/fixed-time-calculation-dto';
import {
  FixedTimeCalculationsRepository,
} from '@/modules/flagship/emergency-fund-calculations/fixed-time-calculation/domain/repositories/fixed-time-calculations-repository';
import {
  EmergencyFundCalculationResponse,
} from '@/modules/flagship/emergency-fund-calculations/fixed-time-calculation/domain/entities/emergency-fund-calculation-response';

@Injectable()
export default class CalculateFixedTimeCalculationQuery extends
  Query<Promise<EmergencyFundCalculationResponse>> {
  @Inject(TYPES.EMERGENCY_FUND_FIXED_TIME_CALCULATION_REPOSITORY)
  private readonly repository!: FixedTimeCalculationsRepository;

  internalExecute(monthly_required_amount: FixedTimeCalculationDto):
    Promise<EmergencyFundCalculationResponse> {
    return new Promise((resolve, reject) => {
      this.repository.calculate(monthly_required_amount)
        .then(resolve)
        .catch(reject);
    });
  }
}
