import TYPES from '../../types';

// Domain
import { CustomerKuspitCredentialsSenderRepository } from '../../domain/repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class SendCustomerKuspitCredentialsCommand extends Command {
  @Inject(TYPES.CUSTOMER_KUSPIT_CREDENTIALS_SENDER_REPOSITORY)
  private readonly repository!: CustomerKuspitCredentialsSenderRepository;

  internalExecute(): Promise<unknown> {
    return this.repository.send();
  }
}
