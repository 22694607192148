// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { MunicipalityRepository } from '../../../domain/repository';
import { MunicipalityEntity } from '../../../domain/entities/municipality-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import { SearchDto } from '../../../domain/dtos/search-dto';

@Injectable()
export default class MunicipalityDummyRepository implements MunicipalityRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(search: SearchDto): Promise<Array<MunicipalityEntity>> {
    return Promise.resolve(fixtures);
  }
}
