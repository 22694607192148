import TYPES from '../../types';

// Domain
import {
  IncompleteProfileEntity,
} from '@/modules/flagship/investor-profile/incomplete-profile/domain/entities/incomplete-profile-entity';
import {
  IncompleteProfileRepository,
} from '@/modules/flagship/investor-profile/incomplete-profile/domain/repositories/incomplete-profile-repository';
import {
  IncompleteProfileStateManager,
} from '@/modules/flagship/investor-profile/incomplete-profile/domain/state/incomplete-profile-state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetIncompleteProfileQuery extends Query<Promise<IncompleteProfileEntity>> {
  @Inject(TYPES.INCOMPLETE_PROFILE_REPOSITORY)
  private readonly repository!: IncompleteProfileRepository;

  @Inject(TYPES.INCOMPLETE_PROFILE_STATE_MANAGER)
  private readonly state_manager!: IncompleteProfileStateManager;

  internalExecute(): Promise<IncompleteProfileEntity> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded) {
        resolve(this.state_manager.state.incomplete_profile);
      } else {
        this.repository.get()
          .then((incomplete_profile) => {
            this.state_manager.patch({ incomplete_profile, loaded: true });
            resolve(incomplete_profile);
          })
          .catch(reject);
      }
    });
  }
}
