import TYPES from '../../types';

// Domain
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';
import {
  EmergencyFundInvestorGoalDto,
} from '@/modules/flagship/emergency-fund-investor-goal/domain/dtos/emergency-fund-investor-goal-dto';
import {
  EmergencyFundInvestorGoalRepository,
} from '@/modules/flagship/emergency-fund-investor-goal/domain/repositories/emergency-fund-investor-goal-repository';

@Injectable()
class UpdateEmergencyFundInvestorGoalCommand extends Command<EmergencyFundInvestorGoalDto> {
  @Inject(TYPES.EMERGENCY_FUND_INVESTOR_GOAL_REPOSITORY)
  private readonly repository!: EmergencyFundInvestorGoalRepository;

  internalExecute(emergency_fund_investor_goal_dto: EmergencyFundInvestorGoalDto) {
    return this.repository.update(emergency_fund_investor_goal_dto);
  }
}

export default UpdateEmergencyFundInvestorGoalCommand;
