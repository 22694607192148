import UnautheneticatedLayout from '@/vue-app/layouts/UnauthenticatedLayout.vue';
import AttachedNewProofOfTaxSituation from '@/vue-app/views/document-validation/AttachedNewProofOfTaxSituation.vue';
import POLICIES from '../security/policies';

export default {
  path: '/attached-new-proof-of-tax-situation',
  component: UnautheneticatedLayout,
  children: [
    {
      path: '/',
      name: 'AttachedNewProofOfTaxSituation',
      component: AttachedNewProofOfTaxSituation,
      meta: {
        access_policy: POLICIES.UNAUTHENTICATED,
      },
    },
  ],
};
