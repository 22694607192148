import TYPES from '../../types';

// Domain
import { IncomeEntity } from '../../domain/entities/income-entity';
import { IncomeRepository } from '../../domain/repository/income-repository';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class SearchIncomesQuery extends Query<Promise<Array<IncomeEntity>>> {
  @Inject(TYPES.CATALOG_KUSPIT_INCOME_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.CATALOG_KUSPIT_INCOME_REPOSITORY)
  private readonly repository!: IncomeRepository

  internalExecute(): Promise<Array<IncomeEntity>> {
    return new Promise((resolve, reject) => {
      if (!this.state_manager.state.loaded) {
        this.repository.execute()
          .then((items) => {
            this.state_manager.patch({ items, loaded: true });
            resolve(items);
          })
          .catch(reject);
      } else {
        resolve(this.state_manager.state.items);
      }
    });
  }
}
