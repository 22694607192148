import TYPES from '../../types';

// Domain
import { TransactionEntity } from '../../domain/entities/transaction-entity';
import { TransactionRepository } from '../../domain/repository/transaction-repository';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class SearchTransactionsQuery extends Query<Promise<Array<TransactionEntity>>> {
  @Inject(TYPES.ALLIANZ_TRANSACTION_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.ALLIANZ_TRANSACTION_REPOSITORY)
  private readonly repository!: TransactionRepository;

  internalExecute(customer_id: string): Promise<Array<TransactionEntity>> {
    return this.repository.findAll(customer_id);
  }
}
