import Injectable from '@/modules/shared/domain/di/injectable';
import RenewVerifyNipRepository from '../../domain/repositories/renew-verify-nip-repository';

@Injectable()
export default class RenewVerifyNipDummyRepository implements RenewVerifyNipRepository {
  // eslint-disable-next-line class-methods-use-this
  execute() {
    console.log('VERIFY NIP RESENDED');

    return Promise.resolve();
  }
}
