import TYPES from '../../types';

// Domain
import { UpdatePlanRetirementFundDto }
  from '../../domain/dtos/update-plan-retirement-fund-dto';
import { RetirementInvestorGoalRepository }
  from '../../domain/repositories/retirement-investor-goal-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UpdatePlanRetirementFundInvestorGoalCommand
  extends Command<UpdatePlanRetirementFundDto> {
  @Inject(TYPES.RETIREMENT_INVESTOR_GOAL_REPOSITORY)
  private readonly repository!: RetirementInvestorGoalRepository;

  internalExecute(update_plan_retirement_fund_dto: UpdatePlanRetirementFundDto): Promise<void> {
    return this.repository.updatePlan(update_plan_retirement_fund_dto);
  }
}
