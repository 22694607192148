import TYPES from '../../types';

// Domain
import {
  SearchByCustomerRepository,
} from '../../domain/repositories/search-by-customer-repository';
import {
  SearchByCustomerStateManager,
} from '../../domain/state/search-by-customer-state-manager';
import {
  SearchByCustomerEntity,
} from '../../domain/entities/search-by-customer-entity';
import { SearchByCustomerDto } from '../../domain/dtos/search-by-customer-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
// eslint-disable-next-line max-len
export default class GetSearchByCustomerQuery extends Query<Promise<Array<SearchByCustomerEntity>>> {
  @Inject(TYPES.SEARCH_BY_CUSTOMER_REPOSITORY)
  private readonly repository!: SearchByCustomerRepository;

  @Inject(TYPES.SEARCH_BY_CUSTOMER_STATE_MANAGER)
  private readonly state_manager!: SearchByCustomerStateManager;

  internalExecute(search_by_customer_dto: SearchByCustomerDto):
    Promise<Array<SearchByCustomerEntity>> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded && !search_by_customer_dto.reload) {
        resolve(this.state_manager.state.items);
      } else {
        this.repository.get(search_by_customer_dto)
          .then((items) => {
            this.state_manager.patch({ items, loaded: true });
            resolve(items);
          })
          .catch(reject);
      }
    });
  }
}
