import TYPES from '@/modules/shared/types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';
import { IdentityVerificationServiceRepository }
  from '../../../domain/repositories/identity-verification-service-repository';
import { IdentityVerificationEntity } from '../../../domain/entities/identity-verification-entity';

@Injectable()
class IdentityVerificationServiceHttpRepository implements IdentityVerificationServiceRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/identity/mati-environment/{ID}/`;

  get(id: string): Promise<IdentityVerificationEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint.replace('{ID}', id))
        .then((response) => {
          response.json()
            .then(({ data, error, success }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(error));
              }
            });
        });
    });
  }
}

export default IdentityVerificationServiceHttpRepository;
