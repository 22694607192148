import TYPES from '@/modules/shared/types';

// Domain
import { DistributeRecurrentBalanceRepository } from '../../domain/repositories/distribute-recurrent-balance-repository';
import { DistributeRecurrentBalanceDto } from '../../domain/dto/distribute-recurrent-balance-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class DistributeRecurrentBalanceHttpRepository implements
  DistributeRecurrentBalanceRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/investor-goal/sowos-wealth/distribute-recurrent-balance/`;

  distribute(distribute_recurrent_balance_dto: DistributeRecurrentBalanceDto):
    Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint, 'PATCH', distribute_recurrent_balance_dto)
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json()
              .then(({ success, data, message }) => {
                if (success) {
                  resolve(data);
                } else {
                  reject(new Error(message));
                }
              });
          }
        })
        .catch(reject);
    });
  }
}
