import fixtures from './fixtures.json';
import active_goals_amount_by_fund_type_fixtures from './active-goals-amount-by-fund-type-fixtures.json';

// Domain
import {
  SearchByCustomerRepository,
} from '../../../domain/repositories/search-by-customer-repository';
import {
  SearchByCustomerEntity,
} from '@/modules/flagship/investor-goal/search-by-customer/domain/entities/search-by-customer-entity';
import {
  SearchByCustomerDto,
} from '../../../domain/dtos/search-by-customer-dto';
import {
  ActiveGoalAmountByFundTypeEntity,
} from '@/modules/flagship/investor-goal/search-by-customer/domain/entities/active-goal-amount-by-fund-type-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class SearchByCustomerDummyRepository implements SearchByCustomerRepository {
  get(search_by_customer_dto: SearchByCustomerDto):
    Promise<Array<SearchByCustomerEntity>> {
    return Promise.resolve(fixtures);
  }

  get_active_goals_amounts_by_fund_type(investment_product_fund_type_id: string):
    Promise<Array<ActiveGoalAmountByFundTypeEntity>> {
    return Promise.resolve(active_goals_amount_by_fund_type_fixtures);
  }
}
