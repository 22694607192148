import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import InvestorGoalDatesDummyRepository
  from './infrastructure/repositories/investor-goal-dates-dummy-repository/investor-goal-dates-dummy-repository';
import InvestorGoalDatesHttpRepository
  from './infrastructure/repositories/investor-goal-dates-http-repository';

// Application
import UpdateInvestorGoalDatesCommand from './application/commands/update-investor-goal-dates-command';

// Domain
import { InvestorGoalDatesRepository } from './domain/repositories/investor-goal-dates-repository';

export default function setupInvestorGoalDates(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<InvestorGoalDatesRepository>(TYPES.INVESTOR_GOAL_DATES_REPOSITORY)
    .to(withDummies
      ? InvestorGoalDatesDummyRepository
      : InvestorGoalDatesHttpRepository)
    .inSingletonScope();

  container.bind<UpdateInvestorGoalDatesCommand>(TYPES.UPDATE_INVESTOR_GOAL_DATES_COMMAND)
    .to(UpdateInvestorGoalDatesCommand)
    .inSingletonScope();
}
