import { Container } from 'inversify';
import TYPES from './types';

// Infrastructure
import CustomerContractedInvestmentProductsVueStateManager
  from '@/modules/flagship/customer-contracted-investment-products/infraestructure/state/customer-contracted-investment-products-vue-state-manager';
import CustomerContractedInvestmentProductsDummyRepository
  from '@/modules/flagship/customer-contracted-investment-products/infraestructure/repositories/customer-contracted-investment-products-dummy-repository/customer-contracted-investment-products-dummy-repository';
import CustomerContractedInvestmentProductsHttpRepository
  from '@/modules/flagship/customer-contracted-investment-products/infraestructure/repositories/customer-contracted-investment-products-http-repository';

// Application
import GetCustomerContractedInvestmentProductsQuery
  from '@/modules/flagship/customer-contracted-investment-products/application/queries/get-customer-contracted-investment-products-query';

// Domain
import {
  CustomerContractedInvestmentProductsStateManager,
} from '@/modules/flagship/customer-contracted-investment-products/domain/state/customer-contracted-investment-products-state-manager';
import {
  CustomerContractedInvestmentProductsRepository,
} from '@/modules/flagship/customer-contracted-investment-products/domain/repositories/customer-contracted-investment-products-repository';

export default function setupCustomerContractedInvestmentProducts(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    // eslint-disable-next-line max-len
    .bind<CustomerContractedInvestmentProductsStateManager>(TYPES.CUSTOMER_CONTRACTED_INVESTMENT_PRODUCTS_STATE_MANAGER)
    .to(CustomerContractedInvestmentProductsVueStateManager)
    .inSingletonScope();

  container
    // eslint-disable-next-line max-len
    .bind<CustomerContractedInvestmentProductsRepository>(TYPES.CUSTOMER_CONTRACTED_INVESTMENT_PRODUCTS_REPOSITORY)
    .to(
      withDummies
        ? CustomerContractedInvestmentProductsDummyRepository
        : CustomerContractedInvestmentProductsHttpRepository,
    )
    .inSingletonScope();

  container
    // eslint-disable-next-line max-len
    .bind<GetCustomerContractedInvestmentProductsQuery>(TYPES.GET_CUSTOMER_CONTRACTED_INVESTMENT_PRODUCTS_QUERY)
    .to(GetCustomerContractedInvestmentProductsQuery)
    .inSingletonScope();
}
