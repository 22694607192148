import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import InvestorGoalDetailDummyRepository
  from '@/modules/flagship/investor-goal/investor-goal-detail/infrastructure/repositories/investor-goal-detail-dummy-repository/investor-goal-detail-dummy-repository';
import InvestorGoalDetailHttpRepository
  from '@/modules/flagship/investor-goal/investor-goal-detail/infrastructure/repositories/investor-goal-detail-http-repository';

// Application
import GetInvestorGoalDetailQuery
  from '@/modules/flagship/investor-goal/investor-goal-detail/application/queries/get-investor-goal-detail-query';

// Domain
import {
  InvestorGoalDetailRepository,
} from '@/modules/flagship/investor-goal/investor-goal-detail/domain/repositories/investor-goal-detail-repository';

export default function setupInvestorGoalDetail(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<InvestorGoalDetailRepository>(
      TYPES.INVESTOR_GOAL_DETAIL_REPOSITORY,
    ).to(
      withDummies
        ? InvestorGoalDetailDummyRepository
        : InvestorGoalDetailHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetInvestorGoalDetailQuery>(
      TYPES.GET_INVESTOR_GOAL_DETAIL_QUERY,
    ).to(GetInvestorGoalDetailQuery)
    .inSingletonScope();
}
