import TYPES from '@/modules/shared/types';

// Domain
import { AllianzRequestForMovementsToPolicyRepository } from '../../domain/repository';
import { AllianzRequestForMovementsToPolicyEntity } from '../../domain/entities/allianz-request-for-movements-to-policy-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class AllianzRequestForMovementsToPolicyHttpRepository
implements AllianzRequestForMovementsToPolicyRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/allianz/request-for-movement-to-policy/search/`;

  search(query_params: URLSearchParams): Promise<Array<AllianzRequestForMovementsToPolicyEntity>> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}?${new URLSearchParams(query_params)}`)
        .then((response) => {
          response.json()
            .then(({ data, success, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
