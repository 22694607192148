import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import InvestorProfileOnCustomGoalDummyRepository
  from '@/modules/flagship/investor-profile/custom-goal/infraestructure/repositories/investor-profile-on-custom-goal-dummy-repository';
import InvestorProfileOnCustomGoalHttpRepository
  from '@/modules/flagship/investor-profile/custom-goal/infraestructure/repositories/investor-profile-on-custom-goal-http-repository';

// Application
import UpdateInvestorProfileOnCustomGoalCommand
  from '@/modules/flagship/investor-profile/custom-goal/application/commands/update-investor-profile-on-custom-goal-command';

// Domain
import {
  InvestorProfileOnCustomGoalRepository,
} from '@/modules/flagship/investor-profile/custom-goal/domain/repositories/investor-profile-on-custom-goal-repository';

export default function setupCustomGoal(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<InvestorProfileOnCustomGoalRepository>(
      TYPES.INVESTOR_PROFILE_ON_CUSTOM_GOAL_REPOSITORY,
    ).to(
      withDummies
        ? InvestorProfileOnCustomGoalDummyRepository
        : InvestorProfileOnCustomGoalHttpRepository,
    ).inSingletonScope();

  container
    .bind<UpdateInvestorProfileOnCustomGoalCommand>(
      TYPES.UPDATE_INVESTOR_PROFILE_ON_CUSTOM_GOAL_COMMAND,
    ).to(UpdateInvestorProfileOnCustomGoalCommand)
    .inSingletonScope();
}
