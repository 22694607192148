import Vue from 'vue';

// Domain
import { ModerateIsContractedStateManager } from '@/modules/flagship/customer-investment-product-fund-type/domain/state/moderate-is-contracted-state-manager';
import ModerateIsContractedState from '@/modules/flagship/customer-investment-product-fund-type/domain/state/moderate-is-contracted-state';
import Injectable from '@/modules/shared/domain/di/injectable';
import Observer from '@/modules/shared/domain/observer/observer';

@Injectable()
export default class ModerateIsContractedVueStateManager implements
  ModerateIsContractedStateManager {
  // eslint-disable-next-line max-len
  private _state: ModerateIsContractedState = Vue.observable<ModerateIsContractedState>(new ModerateIsContractedState());

  private readonly observers: Observer[] = [];

  get state(): ModerateIsContractedState {
    return this._state;
  }

  set state(value: ModerateIsContractedState) {
    this._state = value;
    this.notifyAll();
  }

  patch(state: Partial<ModerateIsContractedState>) {
    type Keys = keyof ModerateIsContractedState;
    Object.entries(state).forEach(([key, value]) => {
      const accessor = key as Keys;

      if (value !== undefined) {
        // eslint-disable-next-line
        // @ts-ignore @typescript-eslint/ban-ts-ignore
        this.state[accessor] = value;
      }
    });
  }

  notifyAll() {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer) {
    this.observers.push(observer);
  }
}
