import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import {
  FundingDetailsHttpRepository,
  FundingDetailsDummyRepository,
} from './infrastructure/repository';

// Application
import { GetFundingDetailsQuery } from './application/queries';

// Domain
import { FundingDetailsRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupFundingDetails(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.KUSPIT_FUNDING_DETAILS_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<FundingDetailsRepository>(TYPES.KUSPIT_FUNDING_DETAILS_REPOSITORY)
    .to(
      withDummies
        ? FundingDetailsDummyRepository
        : FundingDetailsHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<GetFundingDetailsQuery>(TYPES.GET_KUSPIT_FUNDING_DETAILS_QUERY)
    .to(GetFundingDetailsQuery)
    .inSingletonScope();
}
