import TYPES from '@/modules/shared/types';

// Domain
import {
  InvestorProfileOnRetirementBudgetRepository,
} from '@/modules/flagship/investor-profile/retirement-budget/domain/repositories/investor-profile-on-retirement-budget-repository';
import {
  RetirementBudgetDto,
} from '@/modules/flagship/investor-profile/retirement-budget/domain/dtos/retirement-budget-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class InvestorProfileOnRetirementBudgetHttpRepository
implements InvestorProfileOnRetirementBudgetRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/investor-profile/retirement-budget/{investor_goal_id}/`;

  update(retirement_budget_dto: RetirementBudgetDto): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint.replace('{investor_goal_id}',
        retirement_budget_dto.investor_goal_id), 'PATCH', {
        net_monthly_income: retirement_budget_dto.net_monthly_income,
        net_monthly_expenses: retirement_budget_dto.net_monthly_expenses,
        monthly_required_amount: retirement_budget_dto.monthly_required_amount,
        income_behavior_in_following_year_id:
        retirement_budget_dto.income_behavior_in_following_year_id,
      })
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => {
                reject(new Error(message));
              },
            );
          }
        })
        .catch(reject);
    });
  }
}
