import TYPES from '../../types';

// Domain
import { ChangePassword } from '../../domain/dtos';
import { UserPasswordRepository } from '../../domain/repositories';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UpdateUserPasswordCommand extends Command<ChangePassword> {
  @Inject(TYPES.USER_PASSWORD_REPOSITORY)
  private readonly user_password_repository!: UserPasswordRepository;

  internalExecute(payload: ChangePassword): Promise<void> {
    return this.user_password_repository.update(payload);
  }
}
