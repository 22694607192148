import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';
import { StepEntity } from '../../domain/entities/step-entity';
import StateManager from '../../domain/state/state-manager';
import { OnBoardingUpdateStepRepository }
  from '../../domain/repositories/on-boarding-update-step-repository';

@Injectable()
export default class UpdateOnBoardingStepCommand extends Command<StepEntity> {
  @Inject(TYPES.ON_BOARDING_STEPS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.ON_BOARDING_UPDATE_STEP_REPOSITORY)
  private readonly repository!: OnBoardingUpdateStepRepository;

  internalExecute(step: StepEntity) {
    return new Promise((resolve, reject) => {
      this.repository.execute(step)
        .then(() => {
          const steps = this.state_manager.state.items;
          const stepIndex = steps.findIndex((item) => item.id === step.id);
          steps[stepIndex] = step;
          this.state_manager.patch({ items: steps });
          resolve();
        })
        .catch(reject);
    });
  }
}
