import TYPES from '@/modules/shared/types';

// Domain
import { FileDto } from '@/modules/my-investment/upload-file/domain/dtos/file-dto';
import { UploadFileProofOfAddressRepository } from '../../../domain/repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class UploadFileProofOfAddressHttpRepository
implements UploadFileProofOfAddressRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/allianz/on-boarding/proof-of-address/`;

  upload(file_dto: FileDto): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint, 'POST', file_dto)
        .then((response) => {
          response.json()
            .then(({ success, message }) => {
              if (success) {
                resolve();
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
