
















import { Component, Vue } from 'vue-property-decorator';

@Component({ name: 'NotificationsWrapper' })
export default class NotificationsWrapper extends Vue {}

