import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import {
  AllianzAccountReportHttpRepository,
  AllianzAccountReportDummyRepository,
} from './infrastructure/repository';

// Application
import { SearchAllianzAccountReportQuery } from './application/queries';

// Domain
import { AllianzAccountReportRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupAllianzAccountReport(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.ALLIANZ_ACCOUNT_REPORT_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<AllianzAccountReportRepository>(TYPES.ALLIANZ_ACCOUNT_REPORT_REPOSITORY)
    .to(
      withDummies
        ? AllianzAccountReportDummyRepository
        : AllianzAccountReportHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchAllianzAccountReportQuery>(TYPES.SEARCH_ALLIANZ_ACCOUNT_REPORT_QUERY)
    .to(SearchAllianzAccountReportQuery)
    .inSingletonScope();
}
