// Domain
import {
  OverallGoalsStatusEntity,
} from '@/modules/flagship/investor-profile/overall-goals-status/domain/entities/overall-goals-status-entity';

export default class OverallGoalsStatusState {
  loaded = false;

  overall_goals_status: OverallGoalsStatusEntity = {
    id: '',
    customer_id: '',
    active_goals_count: 0,
    inactive_goals_count: 0,
    total_goals_count: 0,
  };
}
