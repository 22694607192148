import TYPES from '../../types';

// Domain
import {
  IncompleteProfileDto,
} from '@/modules/flagship/investor-profile/incomplete-profile/domain/dtos/incomplete-profile-dto';
import {
  IncompleteProfileRepository,
} from '@/modules/flagship/investor-profile/incomplete-profile/domain/repositories/incomplete-profile-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UpdateIncompleteProfileCommand extends Command<IncompleteProfileDto> {
  @Inject(TYPES.INCOMPLETE_PROFILE_REPOSITORY)
  private readonly repository!: IncompleteProfileRepository;

  internalExecute(incomplete_profile_dto: IncompleteProfileDto) {
    return this.repository.update(incomplete_profile_dto);
  }
}
