import TYPES from '../../types';

// Domain
import {
  InvestorProfileEntity,
} from '@/modules/flagship/investor-profile/investor-profile/domain/entities/investor-profile-entity';
import {
  InvestorProfileOnFlowCompleteRepository,
} from '@/modules/flagship/investor-profile/flow-completed/domain/repositories/investor-profile-on-flow-complete-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UpdateInvestorProfileOnFlowCompleteCommand
  extends Command<InvestorProfileEntity> {
  @Inject(TYPES.INVESTOR_PROFILE_ON_FLOW_COMPLETE_REPOSITORY)
  private readonly repository!: InvestorProfileOnFlowCompleteRepository;

  internalExecute(investor_profile_entity: InvestorProfileEntity) {
    return this.repository.update(investor_profile_entity);
  }
}
