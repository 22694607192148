import fixtures from './fixtures.json';

// Domain
import Injectable from '@/modules/shared/domain/di/injectable';
import {
  RetirementGoalMonthlyPensionAdjustedCalculationRepository,
} from '@/modules/flagship/retirement-goal-calculations/domain/repositories/retirement-goal-monthly-pension-adjusted-calculation-repository';
import {
  RetirementGoalCalculationsEntity,
} from '@/modules/flagship/retirement-goal-calculations/domain/entities/retirement-goal-calculations-entity';

@Injectable()
class RetirementGoalMonthlyPensionAdjustedCalculationDummyRepository
implements RetirementGoalMonthlyPensionAdjustedCalculationRepository {
  calculate(): Promise<RetirementGoalCalculationsEntity> {
    return Promise.resolve(fixtures);
  }
}

export default RetirementGoalMonthlyPensionAdjustedCalculationDummyRepository;
