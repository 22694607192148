// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { JobTypeRepository } from '../../../domain/repository';
import { JobTypeEntity } from '../../../domain/entities/job-type-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class JobTypeDummyRepository implements JobTypeRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(): Promise<Array<JobTypeEntity>> {
    return Promise.resolve(fixtures);
  }
}
