// Domain
import {
  HabitsRecommendedBudgetCalculationRepository,
} from '@/modules/flagship/habits-recommended-budget-calculation/domain/repositories/habits-recommended-budget-calculation-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class HabitsRecommendedBudgetCalculationDummyRepository
implements HabitsRecommendedBudgetCalculationRepository {
  create(): Promise<void> {
    return Promise.resolve();
  }
}
