import { Container } from 'inversify-props';
import TYPES from './types';
import { setupAdapterAccordingToBrowser } from '@/utils/browser';

// Infrastructure
import SocketIoWebSocket from './infrastructure/web-socket/socket-io-web-socket';
import { FetchHttpConnector } from './infrastructure/connectors/http';
import DatetimeDateFnsValue
  from './infrastructure/value-objects/datetime-date-fns-value';
import { VueNotifier } from './infrastructure/notifiers/message_notifier/vue-notifier';
import UtilFunctions from './infrastructure/utils/functions';
import ChromePermissions from './infrastructure/permissions/chrome-permissions';
import DefaultPermissions from './infrastructure/permissions/default-permissions';
import { Geolocation } from '@/modules/shared/domain/geolocation';
import NavigatorGeolocation
  from '@/modules/shared/infrastructure/geolocation/navigator-geolocation';
import { GoogleMapsGeocoder } from './infrastructure/geocoder';
import { VueRouter } from './infrastructure/router';
import { WindowEventBus } from './infrastructure/bus/event-bus';
import { DateFnsFormatter } from './infrastructure/date-formatters';
import { VueI18nTranslator } from './infrastructure/i18n/vue-i18n';

// Domain
import Runner from './domain/runner';
import ExecutorLink from './domain/runner/executor-link';
import { WebSocket } from './domain/web-socket';
import HttpConnector from './domain/connectors/http';
import { DatetimeValue } from './domain/value-objects/datetime-value';
import { MessageNotifier } from './domain/notifiers/message_notifier';
import { Geocoder } from './domain/geocoder';
import { Router } from './domain/router';
import { EventBus } from './domain/bus/event-bus';
import { DateFormatter } from './domain/date-formatters';
import Translator from './domain/i18n/translator';

export default function setupSharedModule(container: Container) {
  container
    .bind<Runner>(TYPES.RUNNER)
    .to(Runner)
    .inSingletonScope();

  container
    .bind<ExecutorLink>(TYPES.EXECUTOR_LINK)
    .to(ExecutorLink)
    .inSingletonScope();

  container
    .bind<WebSocket>(TYPES.WEB_SOCKET)
    .to(SocketIoWebSocket)
    .inSingletonScope();

  container
    .bind<HttpConnector>(TYPES.HTTP_CONNECTOR)
    .to(FetchHttpConnector)
    .inSingletonScope();

  container
    .bind<DatetimeValue>(TYPES.DATETIME_VALUE)
    .to(DatetimeDateFnsValue)
    .inSingletonScope();

  container
    .bind<MessageNotifier>(TYPES.NOTIFIER)
    .to(VueNotifier)
    .inSingletonScope();

  container
    .bind<UtilFunctions>(TYPES.UTIL_FUNCTIONS)
    .to(UtilFunctions)
    .inSingletonScope();

  container
    .bind<Permissions>(TYPES.PERMISSIONS)
    .to(setupAdapterAccordingToBrowser({
      chrome: ChromePermissions,
      edge_chromium: ChromePermissions,
      default: DefaultPermissions,
    }))
    .inSingletonScope();

  container
    .bind<Geolocation>(TYPES.GEOLOCATION)
    .to(NavigatorGeolocation)
    .inSingletonScope();

  container
    .bind<Geocoder>(TYPES.GEOCODER)
    .to(GoogleMapsGeocoder)
    .inSingletonScope();

  container
    .bind<Router>(TYPES.ROUTER)
    .to(VueRouter)
    .inSingletonScope();

  container
    .bind<EventBus>(TYPES.EVENT_BUS)
    .to(WindowEventBus)
    .inSingletonScope();

  container
    .bind<DateFormatter>(TYPES.DATE_FORMATTER)
    .to(DateFnsFormatter)
    .inSingletonScope();

  container
    .bind<Translator>(TYPES.I18N)
    .to(VueI18nTranslator)
    .inSingletonScope();
}
