import TYPES from '../../types';

// Domain
import { RetirementGoalCalculationsEntity } from '@/modules/flagship/retirement-goal-calculations/domain/entities/retirement-goal-calculations-entity';
import { RetirementGoalMonthlyPensionAdjustedCalculationRepository } from '@/modules/flagship/retirement-goal-calculations/domain/repositories/retirement-goal-monthly-pension-adjusted-calculation-repository';
import { RetirementGoalMonthlyPensionAdjustedCalculationDto } from '@/modules/flagship/retirement-goal-calculations/domain/dtos/retirement-goal-monthly-pension-adjusted-calculation-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
class CalculateRetirementGoalMonthlyPensionAdjustedCalculationQuery extends Query<
  Promise<RetirementGoalCalculationsEntity>
> {
  @Inject(TYPES.RETIREMENT_GOAL_MONTHLY_PENSION_ADJUSTED_CALCULATION_REPOSITORY)
  private readonly repository!: RetirementGoalMonthlyPensionAdjustedCalculationRepository;

  internalExecute(
    payload: RetirementGoalMonthlyPensionAdjustedCalculationDto,
  ): Promise<RetirementGoalCalculationsEntity> {
    return this.repository.calculate(payload);
  }
}

export default CalculateRetirementGoalMonthlyPensionAdjustedCalculationQuery;
