import TYPES from '@/modules/shared/types';

// Domain
import { TotalWithdrawalRepository } from '../../domain/repository';
import { CreateTotalWithdrawalDto } from '../../domain/dtos/create-total-withdrawal-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class TotalWithdrawalHttpRepository implements TotalWithdrawalRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  create(total_withdrawal_dto: CreateTotalWithdrawalDto): Promise<void> {
    const endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/allianz/profile/total-withdrawal/`;

    return new Promise((resolve, reject) => {
      this.http_connector.execute(
        endpoint,
        'POST',
        total_withdrawal_dto,
      )
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json()
              .then(({ message }) => {
                reject(new Error(message));
              });
          }
        })
        .catch(reject);
    });
  }
}
