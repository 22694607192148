import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import SearchTransactionStatusDummyRepository
  from './infrastructure/repositories/search-transaction-status-dummy-repository';
import SearchTransactionStatusHttpRepository
  from './infrastructure/repositories/search-transaction-status-http-repository';
import SearchTransactionStatusQuery from './application/queries/search-transaction-status-query';

// Domain
import { TransactionStatusStateManager } from './domain/state/state-manager';
import { SearchTransactionStatusRepository } from './domain/repositories/search-transaction-status-repository';

export default function setupAgreements(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<TransactionStatusStateManager>(TYPES.TRANSACTION_STATUS_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<SearchTransactionStatusRepository>(TYPES.SEARCH_TRANSACTION_STATUS_REPOSITORY)
    .to(
      withDummies
        ? SearchTransactionStatusDummyRepository
        : SearchTransactionStatusHttpRepository,
    ).inSingletonScope();

  container
    .bind<SearchTransactionStatusQuery>(TYPES.SEARCH_TRANSACTION_STATUS_QUERY)
    .to(SearchTransactionStatusQuery)
    .inSingletonScope();
}
