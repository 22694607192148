import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import {
  AllianzRequestForMovementsToPolicyHttpRepository,
  AllianzRequestForMovementsToPolicyDummyRepository,
} from './infrastructure/repository';
import {
  UpdateAccountNumberVueStateManager,
} from './infrastructure/state/update-account-number-vue-state-manager';

// Application
import { SearchAllianzRequestForMovementsToPolicyQuery } from './application/queries';
import { UpdateAccountNumberStatusSubscription } from './application/subscriptions';

// Domain
import { AllianzRequestForMovementsToPolicyRepository } from './domain/repository';
import {
  UpdateAccountNumberStateManager,
} from '@/modules/my-investment/allianz/allianz-request-for-movments-to-policy/domain/state-manager/update-account-number-state-manager';

export default function setupAllianzAccountReport(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<UpdateAccountNumberStateManager>(
      TYPES.UPDATE_ACCOUNT_NUMBER_STATE_MANAGER,
    )
    .to(UpdateAccountNumberVueStateManager)
    .inSingletonScope();

  container
    .bind<AllianzRequestForMovementsToPolicyRepository>(
      TYPES.ALLIANZ_REQUEST_FOR_MOVEMENTS_TO_POLICY_REPOSITORY,
    )
    .to(
      withDummies
        ? AllianzRequestForMovementsToPolicyDummyRepository
        : AllianzRequestForMovementsToPolicyHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchAllianzRequestForMovementsToPolicyQuery>(
      TYPES.SEARCH_ALLIANZ_REQUEST_FOR_MOVEMENTS_TO_POLICY_QUERY,
    )
    .to(SearchAllianzRequestForMovementsToPolicyQuery)
    .inSingletonScope();

  container
    .bind<UpdateAccountNumberStatusSubscription>(
      TYPES.UPDATE_ACCOUNT_NUMBER_STATUS_SUBSCRIPTION,
    )
    .to(UpdateAccountNumberStatusSubscription)
    .inSingletonScope();
}
