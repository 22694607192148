import { PositionEntity } from '../entities/position-entity';
import {
  PositionDto,
} from '@/modules/my-investment/kuspit/customer-kuspit-position/domain/dtos/position-dto';

export default class State {
  is_loading = false;

  position_details_loaded = false;

  position_loaded = false;

  position_details: PositionEntity = {
    contract_id: '',
    position: '',
    cash: '',
    customer_name: '',
    clabe: '',
    funds: [],
  };

  position: PositionDto = {
    position: '',
    update_date: '',
    update_data: '',
    has_funds: false,
  };
}
