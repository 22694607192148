import { Container } from 'inversify-props';
import setupFinancialInstitution from './financial-institution/setup';
import setupState from './state/setup';
import setupCountry from './country/setup';
import setupAddress from './address/setup';
import setupIncome from './income/setup';
import setupInvestment from './investment/setup';
import setupOrigin from './origin/setup';
import setupSource from './source/setup';
import setupOperation from './operation/setup';
import setupMaritalStatus from './marital-status/setup';
import setupBusinessActivity from './business-activity/setup';
import setupActivity from '@/modules/my-investment/catalogs/kuspit/activity/setup';
import setupJobType from '@/modules/my-investment/catalogs/kuspit/job-type/setup';

export default function setupKuspit(container: Container) {
  setupFinancialInstitution(container);
  setupState(container);
  setupCountry(container);
  setupAddress(container);
  setupIncome(container);
  setupInvestment(container);
  setupOrigin(container);
  setupSource(container);
  setupOperation(container);
  setupMaritalStatus(container);
  setupBusinessActivity(container);
  setupActivity(container);
  setupJobType(container);
}
