import TYPES from '@/modules/shared/types';

// Domain
import {
  InvestorGoalDatesRepository,
} from '../../domain/repositories/investor-goal-dates-repository';
import { InvestorGoalDatesDto } from '../../domain/dtos/investor-goal-dates-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class InvestorGoalDatesHttpRepository implements InvestorGoalDatesRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/investor-goal/investor-goal-dates/{investor_goal_id}/`;

  update(investor_goal_dates_dto: InvestorGoalDatesDto): Promise<void> {
    const { investor_goal_id, ...payload } = investor_goal_dates_dto;
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint.replace('{investor_goal_id}', investor_goal_id!), 'PATCH', payload)
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => {
                reject(new Error(message));
              },
            );
          }
        })
        .catch(reject);
    });
  }
}
