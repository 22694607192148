// Domain
import { LinkSowosWealthRepository } from '../../domain/repositories/link-sowos-wealth-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class LinkSowosWealthDummyRepository implements LinkSowosWealthRepository {
  create(): Promise<void> {
    return Promise.resolve();
  }
}
