import TYPES from '@/modules/shared/types';

// Domain
import { StateRepository } from '../../domain/repository';
import { StateEntity } from '../../domain/entities/state-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class StateHttpRepository implements StateRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/kuspit/catalogs/states/{COUNTRY_ID}/`;

  execute(country_id: string): Promise<Array<StateEntity>> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint.replace('{COUNTRY_ID}', country_id))
        .then((response) => {
          response.json()
            .then(({ data, success, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
