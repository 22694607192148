import TYPES from '../../types';

// Domain
import { SettlementEntity } from '../../domain/entities/settlement-entity';
import { SettlementRepository } from '../../domain/repository/settlement-repository';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class SearchSettlementsQuery extends Query<Promise<Array<SettlementEntity>>> {
  @Inject(TYPES.CATALOG_ALLIANZ_SETTLEMENT_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.CATALOG_ALLIANZ_SETTLEMENT_REPOSITORY)
  private readonly repository!: SettlementRepository;

  internalExecute(codigo_postal: string): Promise<Array<SettlementEntity>> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.current_codigo_postal !== codigo_postal) {
        this.repository.execute(codigo_postal)
          .then((items) => {
            this.state_manager.patch({
              items,
              current_codigo_postal: codigo_postal,
            });
            resolve(items);
          })
          .catch(reject);
      } else {
        resolve(this.state_manager.state.items);
      }
    });
  }
}
