import accountBalance from './account-balance.json';
import performance from './performance.json';
import position from './position.json';
import movements from './movements.json';
import operations from './extras.json';
import inquiries from './inquiries.json';
import information from './information.json';
import allianz_requests from './allianz-requests.json';
import clarifications from './clarifications.json';
import policy_details from './policy-details.json';
import account_statements from './account-statements.json';
import beneficiaries from './beneficiaries.json';
import withdrawals from './withdrawals';
import deposits from './deposits';
import errors from './errors';
import allianz_contact from './allianz-contact.json';
import bank_account from './bank-account.json';
import goals from './goals';
import recurrentContributionDialog from './recurrent-contribution-dialog.json';
import more_investments_alternatives from './more-investment-alternatives';
import change_bank_account from './change-bank-account';
import access_more_strategies from './access-more-strategies';

export default {
  'allianz-dashboard': {
    title: 'Principal > Mi inversión > Seguro Inversión Allianz',
    back: 'Regresar',
    request_withdrawal: 'Solicitar Retiro',
    make_contribution: 'Realizar Aportación',
    my_account: 'Mi cuenta',
    my_balance: 'Mi Saldo en Cuenta',
    my_performance: 'Rendimientos',
    my_movements: 'Movimientos',
    my_position: 'Composición de mi cartera',
    allianz_operations: 'Operaciones',
    allianz_inquiries: 'Consultas',
    allianz_extras: 'Extras',
    linked_goals: 'Metas Vinculadas',
    ...accountBalance,
    ...performance,
    ...position,
    ...movements,
    ...operations,
    ...inquiries,
    ...information,
    ...allianz_requests,
    ...clarifications,
    ...policy_details,
    ...account_statements,
    ...beneficiaries,
    ...withdrawals,
    ...errors,
    ...deposits,
    ...allianz_contact,
    ...bank_account,
    ...goals,
    ...recurrentContributionDialog,
    ...more_investments_alternatives,
    ...change_bank_account,
    ...access_more_strategies,
  },
};
