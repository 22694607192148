// eslint-disable-next-line import/no-extraneous-dependencies
import 'reflect-metadata';
import Application from './vue-app/application';
import Container from './container';
import TYPES from './types';
import App from './vue-app/App.vue';
import './styles/style.css';

Container.instance()
  .get<Application>(TYPES.APPLICATION)
  .create(App);
