import TYPES from '@/modules/shared/types';

// Domain
import { RetirementGoalMonthlyPensionAdjustedCalculationRepository } from '@/modules/flagship/retirement-goal-calculations/domain/repositories/retirement-goal-monthly-pension-adjusted-calculation-repository';
import { RetirementGoalMonthlyPensionAdjustedCalculationDto } from '@/modules/flagship/retirement-goal-calculations/domain/dtos/retirement-goal-monthly-pension-adjusted-calculation-dto';
import { RetirementGoalCalculationsEntity } from '@/modules/flagship/retirement-goal-calculations/domain/entities/retirement-goal-calculations-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
class RetirementGoalMonthlyPensionAdjustedCalculationHttpRepository
implements RetirementGoalMonthlyPensionAdjustedCalculationRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/retirement-investor-goal-monthly-pension-adjusted-calculation/`;

  calculate(
    payload: RetirementGoalMonthlyPensionAdjustedCalculationDto,
  ): Promise<RetirementGoalCalculationsEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector
        .execute(this.endpoint, 'POST', payload)
        .then((response) => {
          response.json().then(({ success, data, message }) => {
            if (success) {
              resolve(data);
            } else {
              reject(message);
            }
          });
        })
        .catch(reject);
    });
  }
}

export default RetirementGoalMonthlyPensionAdjustedCalculationHttpRepository;
