/* eslint-disable max-len */
/* eslint-disable no-plusplus */

const LIMIT_OF_CONSECUTIVE_CHARACTERS = 3;

export const noConsecutiveCharactersValidator = (password: string): boolean => {
  let is_valid_password = false;
  if (password.length > LIMIT_OF_CONSECUTIVE_CHARACTERS) {
    const normalized_password = password.toLowerCase();
    const password_check_iterations = normalized_password.length - (LIMIT_OF_CONSECUTIVE_CHARACTERS - 1);
    const substring_check_iterations = LIMIT_OF_CONSECUTIVE_CHARACTERS - 1;

    for (let index = 0; index < password_check_iterations; index++) {
      const substring = normalized_password.substring(index, LIMIT_OF_CONSECUTIVE_CHARACTERS + index);
      let not_consecutive_ascendant_characters = false;
      let not_consecutive_descendant_characters = false;

      for (let sub_index = 0; sub_index < substring_check_iterations; sub_index++) {
        const ascii_for_a = substring.charCodeAt(sub_index);
        const ascii_for_b = substring.charCodeAt(sub_index + 1);
        const ascii_for_y = substring.charCodeAt(substring.length - 2 - sub_index);
        const ascii_for_z = substring.charCodeAt(substring.length - 1 - sub_index);

        const consecutive_ascendant_characters = (ascii_for_a + 1) === ascii_for_b;
        const consecutive_descendant_characters = (ascii_for_y - 1) === ascii_for_z;

        if (!consecutive_ascendant_characters) {
          not_consecutive_ascendant_characters = true;
        }
        if (!consecutive_descendant_characters) {
          not_consecutive_descendant_characters = true;
        }
      }

      is_valid_password = !(!not_consecutive_ascendant_characters || !not_consecutive_descendant_characters);
      if (!is_valid_password) return false;
    }
  }

  return is_valid_password;
};
