import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import { ISOCountryDummyRepository, ISOCountryHttpRepository } from './infrastructure/repository';

// Application
import { SearchISOCountriesQuery } from './application/queries';

// Domain
import { ISOCountryRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupISOCountry(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.CATALOG_ALLIANZ_ISO_COUNTRY_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<ISOCountryRepository>(TYPES.CATALOG_ALLIANZ_ISO_COUNTRY_REPOSITORY)
    .to(
      withDummies
        ? ISOCountryDummyRepository
        : ISOCountryHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchISOCountriesQuery>(TYPES.SEARCH_CATALOG_ALLIANZ_ISO_COUNTRY_QUERY)
    .to(SearchISOCountriesQuery)
    .inSingletonScope();
}
