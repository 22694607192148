var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-app-bar',{style:({
      flexDirection: _vm.$vuetify.breakpoint.smAndDown ? 'row-reverse' : 'row',
      backgroundColor: 'white',
      // eslint-disable-next-line max-len
      boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    }),attrs:{"app":"","dense":""}},[_c('a',{attrs:{"href":"/"}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('img',{staticStyle:{"height":"1em"},attrs:{"draggable":"false","src":require('@/assets/images/logo-medium.png'),"alt":"Sowos"}}):_vm._e()]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"d-inline-block"},[(_vm.nav_bar_view_model.back_reference)?_c('router-link',{attrs:{"to":_vm.nav_bar_view_model.back_reference}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('img',{staticClass:"mt-3",attrs:{"draggable":"false","src":require('@/assets/icons/nav-bar/back.svg'),"alt":_vm.nav_bar_view_model.back_reference_alt}}):_vm._e()]):_vm._e()],1):_vm._e(),_vm._l((_vm.nav_bar_view_model.title),function(item,index){return _c('div',{key:index,staticClass:"d-inline-block"},[(index > 0)?_c('div',{key:index,staticClass:"d-inline-block mx-5"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('h3',{staticClass:"font-weight-medium primary--text"},[_vm._v(" > ")]):_vm._e()]):_vm._e(),(item.href)?_c('router-link',{key:item.href,staticStyle:{"text-decoration":"none"},attrs:{"to":item.href}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('h3',{staticClass:"font-weight-medium primary--text"},[_vm._v(" "+_vm._s(item.label)+" ")]):_vm._e()]):_c('div',{key:item.label,staticClass:"d-inline-block"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('h3',{staticClass:"font-weight-medium primary--text"},[_vm._v(" "+_vm._s(item.label)+" ")]):_vm._e()])],1)}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","disabled":!_vm.nav_bar_view_model.active_notifications},on:{"click":_vm.nav_bar_view_model.handleShowNotifications}},[(_vm.nav_bar_view_model.active_notifications)?_c('v-img',{attrs:{"src":require("@/assets/icons/nav-bar/notifications_on.svg"),"max-width":"30px"}}):_c('v-img',{attrs:{"src":require("@/assets/icons/nav-bar/notifications_off.svg"),"max-width":"30px"}})],1),_c('v-menu',{attrs:{"absolute":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('div',{staticClass:"rounded-circle secondary white--text pa-2"},[_vm._v(" "+_vm._s(_vm.nav_bar_view_model.initials)+" ")])])]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.nav_bar_view_model.menu_items),function(item,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.nav_bar_view_model.actionMenu(item.action, item.route)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],2),_c('v-navigation-drawer',{attrs:{"color":"accent","app":"","dark":"","mini-variant":"","mini-variant-width":"68"}},[_c('v-divider'),_c('v-list',{attrs:{"nav":""}},[_c('v-list-item',{attrs:{"link":"","href":"/"}},[_c('v-img',{attrs:{"src":require('@/assets/icons/logo_sowos.svg'),"srcset":("" + (require('@/assets/icons/logo_sowos.svg'))),"max-height":"33"}})],1),_vm._l((_vm.nav_bar_view_model.links),function(link){return _c('v-list-item',{key:link.text,staticClass:"my-3",attrs:{"link":""},on:{"click":function($event){return _vm.router(link.to)}}},[_c('v-img',{attrs:{"src":require(("@/assets/icons/" + (link.icon))),"height":"64"}})],1)})],2)],1),(_vm.nav_bar_view_model.show_dialog_sign_out)?_c('sign-out-dialog',{attrs:{"is-open":_vm.nav_bar_view_model.show_dialog_sign_out},on:{"update:isOpen":function($event){return _vm.$set(_vm.nav_bar_view_model, "show_dialog_sign_out", $event)},"update:is-open":function($event){return _vm.$set(_vm.nav_bar_view_model, "show_dialog_sign_out", $event)}}}):_vm._e(),_c('notifications',{directives:[{name:"show",rawName:"v-show",value:(_vm.nav_bar_view_model.show_notifications),expression:"nav_bar_view_model.show_notifications"}],on:{"hideNotifications":_vm.nav_bar_view_model.hideNotifications,"activateNotifications":_vm.nav_bar_view_model.activeNotifications}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }