
















































































































import {
  Vue,
  Component, Watch,
} from 'vue-property-decorator';
import WithdrawalInformationViewModel
  from '@/vue-app/view-models/components/kuspit-dashboard/withdrawals/withdrawal-information-view-model';

@Component({ name: 'WithdrawalsInformation' })
export default class WithdrawalsInformation extends Vue {
  withdrawal_information_view_model = Vue.observable(new WithdrawalInformationViewModel(this));

  @Watch('withdrawal_information_view_model.is_loading')
  onLoadChange(is_loading: boolean) {
    this.withdrawal_information_view_model.emitLoading(is_loading);
  }
}

