import { Container } from 'inversify';
import TYPES from './types';

// Infrastructure
import DistributeUnassignedBalanceDummyRepository
  from '@/modules/flagship/investor-goal/distribute-unassigned-balance/infrastructure/repositories/distribute-unassigned-balance-dummy-repository';
import DistributeUnassignedBalanceHttpRepository
  from '@/modules/flagship/investor-goal/distribute-unassigned-balance/infrastructure/repositories/distribute-unassigned-balance-http-repository';

// Application
import DistributeUnassignedBalanceCommand
  from '@/modules/flagship/investor-goal/distribute-unassigned-balance/application/commands/distribute-unassigned-balance-command';

// Domain
import {
  DistributeUnassignedBalanceRepository,
} from '@/modules/flagship/investor-goal/distribute-unassigned-balance/domain/repositories/distribute-unassigned-balance-repository';

export default function setupDistributeUnassignedBalance(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<DistributeUnassignedBalanceRepository>(TYPES.DISTRIBUTE_UNASSIGNED_BALANCE_REPOSITORY)
    .to(
      withDummies
        ? DistributeUnassignedBalanceDummyRepository
        : DistributeUnassignedBalanceHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<DistributeUnassignedBalanceCommand>(TYPES.DISTRIBUTE_UNASSIGNED_BALANCE_COMMAND)
    .to(DistributeUnassignedBalanceCommand)
    .inSingletonScope();
}
