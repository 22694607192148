import { Container } from 'inversify';
import TYPES from './types';

// Infrastructure
import InvestorGoalRiskToleranceDummyRepository
  from './infrastructure/repositories/investor-goal-risk-tolerance-dummy-repository/investor-goal-risk-tolerance-dummy-repository';
import InvestorGoalRiskToleranceHttpRepository
  from './infrastructure/repositories/investor-goal-risk-tolerance-http-repository';
import VueStateManager from './infrastructure/state/vue-state-manager';

// Application
import UpdateInvestorGoalRiskToleranceCommand
  from './application/commands/update-investor-goal-risk-tolerance-command';
import NotifyInvestorGoalRiskToleranceCommand
  from './application/commands/notify-investor-goal-risk-tolerance-command';
import InvestorGoalRiskToleranceDtoService
  from './application/services/investor-goal-risk-tolerance-dto-service';
import InvestorGoalRiskToleranceAnswersService
  from './application/services/investor-goal-risk-tolerance-answers-service';
import InvestorGoalRiskToleranceChoseWarningAnswerService
  from './application/services/investor-goal-risk-tolerance-chose-warning-answer-service';

// Domain
import {
  InvestorGoalRiskToleranceRepository,
} from './domain/repositories/investor-goal-risk-tolerance-repository';
// Domain
import { StateManager } from './domain/state/state-manager';

export default function setupInvestorGoalRiskTolerance(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<StateManager>(TYPES.INVESTOR_GOAL_RISK_TOLERANCE_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<InvestorGoalRiskToleranceRepository>(TYPES.INVESTOR_GOAL_RISK_TOLERANCE_REPOSITORY)
    .to(
      withDummies
        ? InvestorGoalRiskToleranceDummyRepository
        : InvestorGoalRiskToleranceHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<UpdateInvestorGoalRiskToleranceCommand>(TYPES.UPDATE_INVESTOR_GOAL_RISK_TOLERANCE_COMMAND)
    .to(UpdateInvestorGoalRiskToleranceCommand)
    .inSingletonScope();

  container
    .bind<NotifyInvestorGoalRiskToleranceCommand>(TYPES.NOTIFY_INVESTOR_GOAL_RISK_TOLERANCE_COMMAND)
    .to(NotifyInvestorGoalRiskToleranceCommand)
    .inSingletonScope();

  container
    .bind<InvestorGoalRiskToleranceDtoService>(TYPES.INVESTOR_GOAL_RISK_TOLERANCE_DTO_SERVICE)
    .to(InvestorGoalRiskToleranceDtoService)
    .inSingletonScope();

  container
    .bind<InvestorGoalRiskToleranceAnswersService>(TYPES
      .INVESTOR_GOAL_RISK_TOLERANCE_ANSWERS_SERVICE)
    .to(InvestorGoalRiskToleranceAnswersService)
    .inSingletonScope();

  container
    .bind<InvestorGoalRiskToleranceChoseWarningAnswerService>(TYPES
      .INVESTOR_GOAL_RISK_TOLERANCE_CHOSE_WARNING_ANSWERS_SERVICE)
    .to(InvestorGoalRiskToleranceChoseWarningAnswerService)
    .inSingletonScope();
}
