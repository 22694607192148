import Injectable from '@/modules/shared/domain/di/injectable';
import SearchGendersRepository from '../../../../domain/repositories/search-genders-repository';
import fixtures from './fixtures.json';

@Injectable()
export default class SearchGendersDummyRepository implements SearchGendersRepository {
  // eslint-disable-next-line class-methods-use-this
  findAll() {
    return Promise.resolve(fixtures);
  }
}
