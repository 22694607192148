import TYPES from '../../../../../types';
import { SaveSectionResponseDto } from '../../../../domain/dtos/save-section-response-dto';
import Inject from '@/modules/shared/domain/di/inject';
import StateManager from '../../../../domain/state/state-manager';
import { PromiseDto } from '../../../../domain/dtos/promise-dto';
import { StoredPromiseDto } from '../../../../domain/dtos/stored-promise-dto';

export default abstract class SaveOnBoardingSectionBaseService {
  @Inject(TYPES.ON_BOARDING_STEPS_STATE_MANAGER)
  readonly state_manager!: StateManager;

  abstract execute(): SaveSectionResponseDto;

  // eslint-disable-next-line class-methods-use-this
  filteredPromises(promises: Array<PromiseDto>, section: string): Array<PromiseDto> {
    const errors = JSON.parse(sessionStorage.getItem('on-boarding-errors') as string);

    try {
      Object.keys(errors);
    } catch {
      return promises;
    }

    // eslint-disable-next-line
    // @ts-ignore
    const errors_in_section: Array<StoredPromiseDto> = errors[section];

    if (!errors_in_section.length) {
      return [];
    }

    const filtered_promises: Array<PromiseDto> = [];

    errors_in_section.forEach((item) => {
      const found_promise = promises.find((promise) => promise.id === item.id);

      if (found_promise) {
        filtered_promises.push(found_promise);
      }
    });

    return filtered_promises;
  }
}
