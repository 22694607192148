import { Container } from 'inversify';
import TYPES from './types';

// Infrastructure
import CustomerDocumentPocketDummyRepository
  from './infrastructure/repositories/customer-document-pocket-dummy-repository/customer-document-pocket-dummy-repository';
import CustomerDocumentPocketHttpRepository
  from './infrastructure/repositories/customer-document-pocket-http-repository';

// Application
import UpdateCustomerDocumentPocketBankStatementCommand
  from './application/commands/update-customer-document-pocket-bank-statement-command';
import UpdateCustomerDocumentPocketProofAddressCommand
  from './application/commands/update-customer-document-pocket-proof-address-command';

// Domain
import {
  CustomerDocumentPocketRepository,
} from './domain/repositories/customer-document-pocket-repository';

export default function setupCustomerDocumentPocket(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<CustomerDocumentPocketRepository>(TYPES.CUSTOMER_DOCUMENT_POCKET_REPOSITORY)
    .to(
      withDummies
        ? CustomerDocumentPocketDummyRepository
        : CustomerDocumentPocketHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<UpdateCustomerDocumentPocketProofAddressCommand>(TYPES
      .UPDATE_CUSTOMER_DOCUMENT_POCKET_PROOF_ADDRESS_COMMAND)
    .to(UpdateCustomerDocumentPocketProofAddressCommand)
    .inSingletonScope();

  container
    .bind<UpdateCustomerDocumentPocketBankStatementCommand>(TYPES
      .UPDATE_CUSTOMER_DOCUMENT_POCKET_BANK_STATEMENT_COMMAND)
    .to(UpdateCustomerDocumentPocketBankStatementCommand)
    .inSingletonScope();
}
