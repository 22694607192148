import Vue from 'vue';

// Domain
import { SearchByCustomerStateManager } from '../../domain/state/search-by-customer-state-manager';
import SearchByCustomerState from '../../domain/state/search-by-customer-state';
import Injectable from '@/modules/shared/domain/di/injectable';
import Observer from '@/modules/shared/domain/observer/observer';

@Injectable()
export default class SearchByCustomerVueStateManager implements SearchByCustomerStateManager {
  // eslint-disable-next-line max-len
    private _state: SearchByCustomerState = Vue.observable<SearchByCustomerState>(
      new SearchByCustomerState(),
    );

    private readonly observers: Observer[] = [];

    get state(): SearchByCustomerState {
      return this._state;
    }

    set state(value: SearchByCustomerState) {
      this._state = value;
    }

    patch(state: Partial<SearchByCustomerState>) {
      type Keys = keyof SearchByCustomerState;
      Object.entries(state).forEach(([key, value]) => {
        const accessor = key as Keys;
        if (value !== undefined) {
          // eslint-disable-next-line
          // @ts-ignore @typescript-eslint/ban-ts-ignore
          this.state[accessor] = value;
        }
      });
    }

    notifyAll(): void {
      this.observers.forEach((observer) => observer.notify());
    }

    register(observer: Observer): void {
      this.observers.push(observer);
    }
}
