import TYPES from '../../types';

// Domain
import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';
import {
  MonthlyPaymentCalculationDto,
} from '@/modules/flagship/emergency-fund-calculations/fixed-time-calculation/domain/dtos/monthly-payment-calculation-dto';
import {
  MonthlyPaymentCalculationRepository,
} from '@/modules/flagship/emergency-fund-calculations/fixed-time-calculation/domain/repositories/monthly-payment-calculation-repository';
import {
  EmergencyFundCalculationResponse,
} from '@/modules/flagship/emergency-fund-calculations/fixed-time-calculation/domain/entities/emergency-fund-calculation-response';

@Injectable()
export default class CalculateMonthlyPaymentCalculationQuery
  extends Query<Promise<EmergencyFundCalculationResponse>> {
  @Inject(TYPES.EMERGENCY_FUND_MONTHLY_PAYMENT_CALCULATION_REPOSITORY)
  private readonly repository!: MonthlyPaymentCalculationRepository;

  internalExecute(payload: MonthlyPaymentCalculationDto):
    Promise<EmergencyFundCalculationResponse> {
    return new Promise((resolve, reject) => {
      this.repository.calculate(payload)
        .then((items) => {
          resolve(items);
        })
        .catch(reject);
    });
  }
}
