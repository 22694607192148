import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';
import { JwtRepository } from '../../domain/repositories';
import TYPES from '../../types';

export default class ValidateJwtCommand extends Command<string> {
  @Inject(TYPES.AUTHENTICATION_JWT_REPOSITORY)
  private readonly repository!: JwtRepository;

  internalExecute(token: string) {
    return new Promise((resolve, reject) => {
      this.repository.create(token)
        .then((auth) => {
          if (!auth) {
            reject(Error('JWT is not valid.'));
          } else {
            resolve();
          }
        })
        .catch(reject);
    });
  }
}
