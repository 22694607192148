import { Container } from 'inversify-props';
import TYPES from './types';

// Domain
import {
  FixedTimeCalculationsRepository,
} from '@/modules/flagship/emergency-fund-calculations/fixed-time-calculation/domain/repositories/fixed-time-calculations-repository';
import {
  MonthlyPaymentCalculationRepository,
} from '@/modules/flagship/emergency-fund-calculations/fixed-time-calculation/domain/repositories/monthly-payment-calculation-repository';

// Infrastructure
import FixedTimeCalculationDummyRepository
  from '@/modules/flagship/emergency-fund-calculations/fixed-time-calculation/infrastructure/repositories/fixed-time-calculation-dummy-repository/fixed-time-calculation-dummy-repository';
import FixedTimeCalculationHttpRepository
  from '@/modules/flagship/emergency-fund-calculations/fixed-time-calculation/infrastructure/repositories/fixed-time-calculation-http-repository';
import MonthlyPaymentCalculationDummyRepository
  from '@/modules/flagship/emergency-fund-calculations/fixed-time-calculation/infrastructure/repositories/monthly-payment-calculation-dummy-repository/monthly-payment-calculation-dummy-repository';
import MonthlyPaymentCalculationHttpRepository
  from '@/modules/flagship/emergency-fund-calculations/fixed-time-calculation/infrastructure/repositories/monthly-payment-calculation-http-repository';

// Application
import CalculateFixedTimeCalculationQuery
  from '@/modules/flagship/emergency-fund-calculations/fixed-time-calculation/application/querys/calculate-fixed-time-calculation-query';
import CalculateMonthlyPaymentCalculationQuery
  from '@/modules/flagship/emergency-fund-calculations/fixed-time-calculation/application/querys/calculate-monthly-payment-calculation-query';

export default function setupFixedTimeCalculation(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<FixedTimeCalculationsRepository>(
      TYPES.EMERGENCY_FUND_FIXED_TIME_CALCULATION_REPOSITORY,
    )
    .to(
      withDummies
        ? FixedTimeCalculationDummyRepository
        : FixedTimeCalculationHttpRepository,
    ).inSingletonScope();

  container
    .bind<CalculateFixedTimeCalculationQuery>(
      TYPES.CALCULATE_EMERGENCY_FUND_FIXED_TIME_CALCULATION_QUERY,
    )
    .to(CalculateFixedTimeCalculationQuery)
    .inSingletonScope();

  container
    .bind<MonthlyPaymentCalculationRepository>(
      TYPES.EMERGENCY_FUND_MONTHLY_PAYMENT_CALCULATION_REPOSITORY,
    )
    .to(
      withDummies
        ? MonthlyPaymentCalculationDummyRepository
        : MonthlyPaymentCalculationHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<CalculateMonthlyPaymentCalculationQuery>(
      TYPES.CALCULATE_EMERGENCY_FUND_MONTHLY_PAYMENT_CALCULATION_QUERY,
    )
    .to(CalculateMonthlyPaymentCalculationQuery)
    .inSingletonScope();
}
