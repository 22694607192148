import TYPES from '../../types';

// Domain
import {
  HabitsRecommendedBudgetCalculatorDto,
} from '@/modules/flagship/habits-recommended-budget-calculation/domain/dtos/habits-recommended-budget-calculator-dto';
import {
  HabitsRecommendedBudgetCalculationRepository,
} from '@/modules/flagship/habits-recommended-budget-calculation/domain/repositories/habits-recommended-budget-calculation-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CreateHabitsRecommendedBudgetCalculationCommand
  extends Command<HabitsRecommendedBudgetCalculatorDto> {
  @Inject(TYPES.HABITS_RECOMMENDED_BUDGET_CALCULATION_REPOSITORY)
  private readonly repository!: HabitsRecommendedBudgetCalculationRepository;

  internalExecute(recommended_budget_dto: HabitsRecommendedBudgetCalculatorDto): Promise<void> {
    return this.repository.create(recommended_budget_dto);
  }
}
