import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import EmergencyFundAvailabilityDummyRepository
  from '@/modules/flagship/catalogs/emergency-fund-availability/infraestructure/repositories/emergency-fund-availability-dummy-repository/emergency-fund-availability-dummy-repository';
import EmergencyFundAvailabilityHttpRepository
  from '@/modules/flagship/catalogs/emergency-fund-availability/infraestructure/repositories/emergency-fund-availability-http-repository';
import EmergencyFundAvailabilityVueStateManager
  from '@/modules/flagship/catalogs/emergency-fund-availability/infraestructure/state/emergency-fund-availability-vue-state-manager';

// Application
import GetEmergencyFundAvailabilityQuery
  from '@/modules/flagship/catalogs/emergency-fund-availability/application/queries/get-emergency-fund-availability-query';

// Domain
import {
  EmergencyFundAvailabilityRepository,
} from '@/modules/flagship/catalogs/emergency-fund-availability/domain/repositories/emergency-fund-availability-repository';
import {
  EmergencyFundAvailabilityStateManager,
} from '@/modules/flagship/catalogs/emergency-fund-availability/domain/state/emergency-fund-availability-state-manager';

export default function setupEmergencyFundAvailability(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<EmergencyFundAvailabilityStateManager>(TYPES.EMERGENCY_FUND_STATE_MANAGER)
    .to(EmergencyFundAvailabilityVueStateManager)
    .inSingletonScope();

  container
    .bind<EmergencyFundAvailabilityRepository>(
      TYPES.EMERGENCY_FUND_AVAILABILITY_REPOSITORY,
    ).to(
      withDummies
        ? EmergencyFundAvailabilityDummyRepository
        : EmergencyFundAvailabilityHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetEmergencyFundAvailabilityQuery>(
      TYPES.GET_EMERGENCY_FUND_AVAILABILITY_QUERY,
    ).to(GetEmergencyFundAvailabilityQuery)
    .inSingletonScope();
}
