// Domain
import { CreateOnBoardingInviteFriendRepository }
  from '@/modules/home-dashboard/invite-friend/domain/repositories/create-on-boarding-invite-friend-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
class CreateOnBoardingInviteFriendDummyRepository implements
  CreateOnBoardingInviteFriendRepository {
  create(): Promise<void> {
    return Promise.resolve();
  }
}

export default CreateOnBoardingInviteFriendDummyRepository;
