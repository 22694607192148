import Vue from 'vue';

// Domain
import {
  EmploymentSituationStateManager,
} from '@/modules/flagship/catalogs/employment-situation/domain/state/employment-situation-state-manager';
import EmploymentSituationState
  from '@/modules/flagship/catalogs/employment-situation/domain/state/employment-situation-state';
import Injectable from '@/modules/shared/domain/di/injectable';
import Observer from '@/modules/shared/domain/observer/observer';

@Injectable()
export default class EmploymentSituationVueStateManager implements EmploymentSituationStateManager {
  // eslint-disable-next-line max-len
  private _state: EmploymentSituationState = Vue.observable<EmploymentSituationState>(new EmploymentSituationState());

  private readonly observers: Observer[] = [];

  get state(): EmploymentSituationState {
    return this._state;
  }

  set state(value: EmploymentSituationState) {
    this._state = value;
    this.notifyAll();
  }

  patch(state: Partial<EmploymentSituationState>) {
    type Keys = keyof EmploymentSituationState;
    Object.entries(state).forEach(([key, value]) => {
      const accessor = key as Keys;

      if (value !== undefined) {
        // eslint-disable-next-line
        // @ts-ignore @typescript-eslint/ban-ts-ignore
        this.state[accessor] = value;
      }
    });
  }

  notifyAll() {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer) {
    this.observers.push(observer);
  }
}
