
































































































import {
  Vue, Component, PropSync,
} from 'vue-property-decorator';

import {
  CreateKuspitWithdrawalDto,
} from '@/modules/my-investment/kuspit/withdrawal/domain/dtos/create-kuspit-withdrawal-dto';

@Component({ name: 'WithdrawalSuccessfulOperation' })
export default class WithdrawalSuccessfulOperation extends Vue {
  i18n_namespace = 'components.kuspit-dashboard.withdrawals.successful_operation';

  @PropSync('sourceAccount', { type: String, required: true })
  synced_source_account!: string;

  @PropSync('destinationAccount', { type: String, required: true })
  synced_destination_account!: string;

  @PropSync('withdrawalDto', { type: Object, required: true })
  synced_withdrawal_dto!: CreateKuspitWithdrawalDto;

  @PropSync('folioNumber', { type: String, required: true })
  synced_folio_number!: string;

  accept() {
    this.$emit('endProcess');
  }
}

