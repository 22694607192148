import { io } from 'socket.io-client';
import Injectable from '../../domain/di/injectable';
import { WebSocket } from '../../domain/web-socket';
import { WebSocketCallback } from '../../domain/web-socket/web-socket-callback';
import { WebSocketConnection } from '@/modules/shared/domain/web-socket/types/web-socket-connection';

@Injectable()
export default class SocketIoWebSocket implements WebSocket {
  private connections: Array<WebSocketConnection> = [];

  connect(path: string, callback: WebSocketCallback) {
    const connection = io(path, {
      auth: {
        token: sessionStorage.getItem('authorization'),
      },
    });
    connection.on('message', callback);

    this.connections.push({
      path,
      socket: connection,
    });
  }

  disconnect(path: string) {
    const index = this.connections.findIndex(
      (web_socket_connection) => web_socket_connection.path === path,
    );
    if (index) {
      const connection = this.connections[index];
      connection.socket.disconnect();
      this.connections.splice(index, 1);
    }
  }

  refreshToken() {
    this.connections.forEach((web_socket_connection) => {
      if (web_socket_connection.socket && web_socket_connection.socket.auth) {
        if ('token' in web_socket_connection.socket.auth) {
          web_socket_connection.socket.disconnect();
          // eslint-disable-next-line no-param-reassign
          web_socket_connection.socket.auth.token = sessionStorage.getItem('authorization');
          web_socket_connection.socket.connect();
        }
      }
    });
  }

  sendMessage(path: string, tag: string, message: any) {
    const connection = this.connections.find(
      (web_socket_connection) => web_socket_connection.path === path,
    );

    // eslint-disable-next-line no-unused-expressions
    connection?.socket?.emit(tag, true, message);
  }
}
