// Domain
import {
  InvestorProfileOnEmploymentSituationRepository,
} from '@/modules/flagship/investor-profile/employment-situation/domain/repositories/investor-profile-on-employment-situation-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class InvestorProfileOnEmploymentSituationDummyRepository
implements InvestorProfileOnEmploymentSituationRepository {
  update(): Promise<any> {
    return Promise.resolve();
  }
}
