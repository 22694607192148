// Domain
import {
  InvestmentProductsEntity,
} from '@/modules/flagship/catalogs/investment-products/domain/entities/investment-products-entity';

export default class InvestmentProductsState {
  loaded = false;

  items: Array<InvestmentProductsEntity> = [];
}
