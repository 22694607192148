// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { HasAccountRepository } from '../../../domain/repository';
import { HasAccountEntity } from '../../../domain/entities/has-account-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class HasAccountDummyRepository implements HasAccountRepository {
  async execute(): Promise<HasAccountEntity> {
    return Promise.resolve(fixtures);
  }
}
