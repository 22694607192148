import { Container } from 'inversify';
import TYPES from './types';

// Infrastructure
import InvestorGoalScheduledDistributionPocketWithdrawalDummyRepository
  from './infrastructure/repositories/investor-goal-scheduled-distribution-pocket-withdrawal-dummy-repository/investor-goal-scheduled-distribution-pocket-withdrawal-dummy-repository';
import InvestorGoalScheduledDistributionPocketWithdrawalHttpRepository
  from './infrastructure/repositories/investor-goal-scheduled-distribution-pocket-withdrawal-http-repository';

// Application
import InvestorGoalScheduledDistributionPocketWithdrawalCommand
  from './application/command/investor-goal-scheduled-distribution-pocket-withdrawal-command';

// Domain
import {
  InvestorGoalScheduledDistributionPocketWithdrawalRepository,
} from './domain/repositories/investor-goal-scheduled-distribution-pocket-withdrawal-repository';

// eslint-disable-next-line max-len
export default function setupInvestorGoalScheduledDistributionPocketWithdrawal(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<InvestorGoalScheduledDistributionPocketWithdrawalRepository>(TYPES
      .INVESTOR_GOAL_SCHEDULED_DISTRIBUTION_POCKET_WITHDRAWAL_REPOSITORY)
    .to(
      withDummies
        ? InvestorGoalScheduledDistributionPocketWithdrawalDummyRepository
        : InvestorGoalScheduledDistributionPocketWithdrawalHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<InvestorGoalScheduledDistributionPocketWithdrawalCommand>(TYPES
      .INVESTOR_GOAL_SCHEDULED_DISTRIBUTION_POCKET_WITHDRAWAL_COMMAND)
    .to(InvestorGoalScheduledDistributionPocketWithdrawalCommand)
    .inSingletonScope();
}
