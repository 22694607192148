// Infrastructure
import fixtures from './fixtures.json';

// Domain
import {
  EmploymentSituationRepository,
} from '@/modules/flagship/catalogs/employment-situation/domain/repositories/employment-situation-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import {
  EmploymentSituationEntity,
} from '@/modules/flagship/catalogs/employment-situation/domain/entities/employment-situation-entity';

@Injectable()
export default class EmploymentSituationDummyRepository implements EmploymentSituationRepository {
  execute(): Promise<Array<EmploymentSituationEntity>> {
    return Promise.resolve(fixtures);
  }
}
