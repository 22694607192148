import TYPES from '@/types';

// Application
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';
import GetInvestmentProductsQuery from '@/modules/flagship/catalogs/investment-products/application/queries/get-investment-products-query';
import GetSearchByCustomerQuery from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-search-by-customer-query';
import GetCustomInvestorGoalSearchByCustomerQuery from '@/modules/flagship/custom-investor-goal/application/query/get-custom-investor-goal-search-by-customer-query';
import GetCustomerContractedInvestmentProductsQuery
  from '@/modules/flagship/customer-contracted-investment-products/application/queries/get-customer-contracted-investment-products-query';
import GetOverallGoalsStatusQuery
  from '@/modules/flagship/investor-profile/overall-goals-status/application/queries/get-overall-goals-status-query';
import GetModerateIsContractedQuery
  from '@/modules/flagship/customer-investment-product-fund-type/application/queries/get-moderate-is-contracted-query';

// Domain
import {
  CustomInvestorGoalEntity,
} from '@/modules/flagship/custom-investor-goal/domain/entities/custom-investor-goal-entity';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { Router } from '@/modules/shared/domain/router';

type InvestorGoalSelected = {
  investor_goal_id: string;
  investor_goal_name: string;
  status: string;
}

export default class GoalsDashboardViewModel {
  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.GET_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_investment_products_query!: GetInvestmentProductsQuery;

  @Inject(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetSearchByCustomerQuery;

  @Inject(TYPES.GET_CUSTOM_INVESTOR_GOAL_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_custom_investor_goal_search_by_customer!:
    GetCustomInvestorGoalSearchByCustomerQuery;

  @Inject(TYPES.GET_CUSTOMER_CONTRACTED_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_contracted_products_query!: GetCustomerContractedInvestmentProductsQuery;

  @Inject(TYPES.GET_OVERALL_GOALS_STATUS_QUERY)
  private readonly get_overall_goals_status_query!: GetOverallGoalsStatusQuery;

  @Inject(TYPES.GET_MODERATE_IS_CONTRACTED_QUERY)
  private readonly get_moderate_is_contracted_query!: GetModerateIsContractedQuery;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.ROUTER)
  private readonly router!: Router;

  readonly i18n_namespace = 'components.goals-dashboard';

  readonly contracting_status = ['contracting_started', 'endorsement_sent_to_sign', 'uploaded_endorsement_on_sftp', 'balance_transfer_in_process'];

  is_linked = false;

  is_unlinked = false;

  custom_investor_goal: Array<CustomInvestorGoalEntity> = [];

  custom_investor_goal_linked: Array<CustomInvestorGoalEntity> = [];

  products_information_complete = false;

  emergency_fund_goal_linked = false;

  retirement_goal_linked = false;

  show_modify_moderate_dialog = false;

  show_unlink_status_dialog = false;

  unlink_status = '';

  show_link_moderate_dialog = false;

  show_contract_moderate_dialog = false;

  show_hiring_flow_dialog = false;

  show_exit_poll_dialog = false;

  show_contracting_status_moderate_dialog = false;

  contracting_status_moderate = '';

  investor_goal_selected = {
    investor_goal_id: '',
    investor_goal_name: '',
  }

  expansion_panel = 1;

  goals_loaded = false;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async () => {
    await this.obtainInvestorProfile();
    await this.loadCustomerContractedProductsInformation();
    await this.loadGoalsByInvestmentProductProduct();
    await this.loadAllCustomInvestorGoalInformation();
    this.displayUnlinkedGoalsByDefaultIfUserHasNotActiveGoals();
  }

  obtainInvestorProfile = async () => {
    try {
      const investor_profile = await this.get_investor_profile_query.execute();
      if (investor_profile) {
        await this.loadOverallGoals();
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.obtain_investor_profile'));
    }
  }

  loadGoalsByInvestmentProductProduct = async () => {
    const associated_products = await this.get_investment_products_query.execute();
    const search_by_customer_dto = {
      reload: true,
      associated_product_id: '',
      is_active: true,
    };
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const associated_product of associated_products) {
      search_by_customer_dto.associated_product_id = associated_product.id;
      // eslint-disable-next-line no-await-in-loop
      const goals = await this.get_search_by_customer_query.execute(
        search_by_customer_dto,
      );
      if (goals.length > 0) {
        goals.forEach((goal) => {
          if (goal.emergency_fund_investor_goal) {
            this.emergency_fund_goal_linked = true;
          }
          if (goal.retirement_investor_goal) {
            this.retirement_goal_linked = true;
          }
        });
      }
    }
    this.goals_loaded = true;
  }

  loadOverallGoals = async () => {
    try {
      const overall_goals_status = await this.get_overall_goals_status_query.execute();
      if (overall_goals_status) {
        this.is_linked = (overall_goals_status.active_goals_count > 0);
        this.is_unlinked = (overall_goals_status.inactive_goals_count > 0);
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.obtain_overall_goals'));
    }
  }

  loadAllCustomInvestorGoalInformation = async () => {
    try {
      const custom_investor_goals = await
      this.get_custom_investor_goal_search_by_customer.execute(true);

      this.custom_investor_goal_linked = custom_investor_goals.filter(
        (goal) => goal.investor_goal.is_active,
      );

      this.custom_investor_goal = custom_investor_goals.filter(
        (goal) => !goal.investor_goal.is_active,
      );

      this.custom_investor_goal_linked = this.custom_investor_goal_linked.map(
        (custom_investor_goal) => (
          {
            ...custom_investor_goal,
            on_upgrade_process: custom_investor_goal
              .investor_goal_id === this.investor_goal_selected.investor_goal_id,
          }),
      );
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_all_custom_investor_goal_information'));
      }
    }
  }

  loadCustomerContractedProductsInformation = async () => {
    try {
      const { has_sowos_wealth_contracted } = await this.get_contracted_products_query.execute();
      if (has_sowos_wealth_contracted) {
        await this.validateIfHasModerateContracted();
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_customer_contracted_products'));
    } finally {
      this.products_information_complete = true;
    }
  }

  validateIfHasModerateContracted = async () => {
    const {
      moderate_is_contracted, status, opening_goal_id, opening_goal_name,
    } = await this.get_moderate_is_contracted_query.execute();
    if (!moderate_is_contracted && status && this.contracting_status.indexOf(status) > -1) {
      this.show_contracting_status_moderate_dialog = true;
      this.contracting_status_moderate = status;
      this.investor_goal_selected = {
        investor_goal_id: opening_goal_id,
        investor_goal_name: opening_goal_name,
      };
    }
    return moderate_is_contracted;
  }

  modifyStrategy = (investor_goal_selected: InvestorGoalSelected) => {
    this.investor_goal_selected = investor_goal_selected;
    if (investor_goal_selected.status) {
      this.show_unlink_status_dialog = true;
      this.unlink_status = investor_goal_selected.status;
    }
    if (!investor_goal_selected.status) {
      this.show_modify_moderate_dialog = true;
    }
  }

  upgradeToModerate = async (investor_goal_selected: InvestorGoalSelected) => {
    try {
      this.investor_goal_selected = investor_goal_selected;
      const moderate_is_contracted = await this.validateIfHasModerateContracted();
      if (moderate_is_contracted) {
        this.show_link_moderate_dialog = true;
      } else {
        this.show_contract_moderate_dialog = true;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_is_moderate_contracted'));
    }
  }

  showTransferStatusModerate = (investor_goal_selected: InvestorGoalSelected) => {
    this.investor_goal_selected = investor_goal_selected;
    this.show_contracting_status_moderate_dialog = true;
  }

  showExitPollDialog = () => {
    this.show_exit_poll_dialog = true;
  }

  showHiringFlowDialog = () => {
    this.show_hiring_flow_dialog = true;
  }

  backToProductInformation = () => {
    this.show_hiring_flow_dialog = false;
    this.show_contract_moderate_dialog = true;
  }

  showUnlinkStatus = () => {
    this.unlink_status = 'unlinked_in_progress';
    this.show_unlink_status_dialog = true;
  }

  displayUnlinkedGoalsByDefaultIfUserHasNotActiveGoals = () => {
    if (!this.is_linked) this.expansion_panel = 0;
  }

  goToMainDashboard = () => {
    this.router.navigate('/');
  }
}
