import COUNTRY_TYPES from './country/types';
import STATE_TYPES from './state/types';
import ISO_COUNTRY_TYPES from './iso-country/types';
import ACTIVITY_TYPES from './activity/types';
import RELATIONSHIP_TYPES from './relationship/types';
import OCCUPATION_TYPES from './occupation/types';
import RESOURCE_ORIGIN_TYPES from './resource-origin/types';
import DOCUMENT_TYPE_TYPES from './document-type/types';
import FINANCIAL_INSTITUTION_TYPES from './financial-institution/types';
import PERIODICITY_OF_CONTRIBUTION_TYPES from './periodicity-of-contribution/types';
import MUNICIPALITY_TYPES from './municipality/types';
import SETTLEMENT_TYPES from './settlement/types';
import TEMPLATE_QUESTIONNAIRE_TYPES from './template-questionnaire/types';
import SOURCE_INCOME_TYPES from './source-income/types';

export default {
  ...COUNTRY_TYPES,
  ...STATE_TYPES,
  ...ISO_COUNTRY_TYPES,
  ...ACTIVITY_TYPES,
  ...RELATIONSHIP_TYPES,
  ...OCCUPATION_TYPES,
  ...RESOURCE_ORIGIN_TYPES,
  ...DOCUMENT_TYPE_TYPES,
  ...FINANCIAL_INSTITUTION_TYPES,
  ...PERIODICITY_OF_CONTRIBUTION_TYPES,
  ...MUNICIPALITY_TYPES,
  ...SETTLEMENT_TYPES,
  ...TEMPLATE_QUESTIONNAIRE_TYPES,
  ...SOURCE_INCOME_TYPES,
};
