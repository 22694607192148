// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { AllianzOperationDatesRepository } from '../../../domain/repository';
import { AllianzOperationDatesEntity } from '../../../domain/entities/allianz-operation-dates-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class AllianzOperationDatesDummyRepository implements
  AllianzOperationDatesRepository {
  get(): Promise<AllianzOperationDatesEntity> {
    return Promise.resolve(fixtures);
  }
}
