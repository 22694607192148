



































































import { Vue, Component, Watch } from 'vue-property-decorator';
import ExpiredSessionViewModel from '@/vue-app/view-models/components/expired-session-view-model';

@Component({ name: 'ExpiredSession' })
export default class ExpiredSession extends Vue {
  expired_session_view_model = Vue.observable(new ExpiredSessionViewModel())

  @Watch('expired_session_view_model.is_auth')
  onAuthenticationStateIsAuthChange(new_is_auth: boolean) {
    if (new_is_auth) {
      this.expired_session_view_model.close_session_when_token_expires_job.perform_later();
    }
  }

  @Watch('expired_session_view_model.keep_session')
  onAuthenticationStateKeepSessionChange(keep_session: boolean) {
    if (keep_session) {
      this.expired_session_view_model.setIntervalTimeToExpire();
    } else {
      this.expired_session_view_model.close_session_when_token_expires_job.perform_later();
    }
  }

  created() {
    this.expired_session_view_model.initialize();
  }
}
