import TYPES from '@/modules/shared/types';

// Domain
import {
  CustomerPersonalityProfileRepository,
} from '../../domain/repositories/customer-personality-profile-repository';
import {
  CustomerPersonalityProfileEntity,
} from '../../domain/entities/customer-personality-profile-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class CustomerPersonalityProfileHttpRepository
implements CustomerPersonalityProfileRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/customer-personality-profile/`;

  get(): Promise<CustomerPersonalityProfileEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(JSON.stringify({
                  status_code: response.status,
                  message_error: message,
                }));
              }
            });
        })
        .catch(reject);
    });
  }
}
