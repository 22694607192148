
















































































import { Component, Vue } from 'vue-property-decorator';
import AttachedNewProofOfTaxSituationViewModel
  from
  '@/vue-app/view-models/views/document-validation/attached-new-proof-of-tax-situation-view-model';

@Component({
  name: 'AttachedNewProofOfTaxSituation',
  components: {
    AttachedNewProofOfTaxSituationSuccessfully: () => import('@/vue-app/components/document-validation/AttachedNewProofOfTaxSituationSuccessfully.vue'),
  },
})
export default class AttachedNewProofOfTaxSituation extends Vue {
  attached_account_statement_view_model = Vue
    .observable(new AttachedNewProofOfTaxSituationViewModel());

  async mounted() {
    if (this.$route?.query?.token) {
      await this.attached_account_statement_view_model
        .initialize(this.$route?.query?.token.toString());
    } else {
      this.attached_account_statement_view_model.goToLoginPage();
    }
  }

  destroyed() {
    this.attached_account_statement_view_model.deleteTokenFromStorage();
  }
}

