import Vue from 'vue';

// Domain
import DeleteCustomInvestorGoalState
  from '@/modules/flagship/custom-investor-goal/domain/state/delete-custom-investor-goal-state';
import {
  DeleteCustomInvestorGoalStateManager,
} from '@/modules/flagship/custom-investor-goal/domain/state/delete-custom-investor-goal-state-manager';
import Observer from '@/modules/shared/domain/observer/observer';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class DeleteCustomInvestorGoalVueStateManager
implements DeleteCustomInvestorGoalStateManager {
  private _state: DeleteCustomInvestorGoalState = Vue.observable<DeleteCustomInvestorGoalState>(
    new DeleteCustomInvestorGoalState(),
  );

  private readonly observers: Observer[] = [];

  get state(): DeleteCustomInvestorGoalState {
    return this._state;
  }

  set state(value: DeleteCustomInvestorGoalState) {
    this._state = value;
  }

  patch(state: Partial<DeleteCustomInvestorGoalState>) {
    type Keys = keyof DeleteCustomInvestorGoalState;
    Object.entries(state).forEach(([key, value]) => {
      const accessor = key as Keys;

      if (value !== undefined) {
        // eslint-disable-next-line
        // @ts-ignore @typescript-eslint/ban-ts-ignore
        this.state[accessor] = value;
      }
    });
  }

  notifyAll(): void {
    this.observers.forEach((observer) => observer.notify());
  }

  register(observer: Observer): void {
    this.observers.push(observer);
  }
}
