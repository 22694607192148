import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Service from '@/modules/shared/domain/services/service';
import State from '../../domain/state/state';
import { StateManager } from '../../domain/state/state-manager';

@Injectable()
export default class GetPermissionsStatusService extends Service<State> {
  readonly = true;

  @Inject(TYPES.PERMISSIONS_STATUS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  internalExecute(): State {
    return this.state_manager.state;
  }
}
