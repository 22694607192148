import Injectable from '@/modules/shared/domain/di/injectable';
import { SearchTransactionTypeRepository } from '../../../domain/repositories/search-transaction-type-repository';
import { TransactionTypeEntity } from '../../../domain/entities/transaction-type-entity';
import fixtures from './fixtures.json';

@Injectable()
export default class SearchTransactionTypesDummyRepository implements
  SearchTransactionTypeRepository {
  execute(): Promise<Array<TransactionTypeEntity>> {
    return Promise.resolve(fixtures);
  }
}
