import TYPES from '@/types';
import { convertToLargerPossibleUnit } from '@/vue-app/utils/files';

// Infrastructure
import Functions from '@/modules/shared/infrastructure/utils/functions';

// Application
import VerifyTokenMagicLinkQuery
  from '@/modules/my-investment/kuspit/customer-document/magic-link/application/queries/verify-token-magic-link-query';
import UploadProofOfTaxSituationCommand
  from '@/modules/my-investment/kuspit/customer-document/magic-link/application/commands/upload-proof-of-tax-situation-command';

// Domain
import { MagicLinkDto }
  from '@/modules/on-boarding/customer-document/magic-link/domain/dtos/magic-link-dto';
import { CustomerDocumentWealthDto }
  from '@/modules/on-boarding/customer-document/wealth/domain/dtos/customer-document-wealth-dto';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class AttachedNewProofOfTaxSituationViewModel {
  @Inject(TYPES.KUSPIT_VERIFY_TOKEN_MAGIC_LINK_QUERY)
  private readonly verify_token_magic_link_query!: VerifyTokenMagicLinkQuery;

  @Inject(TYPES.KUSPIT_UPLOAD_PROOF_OF_TAX_SITUATION_COMMAND)
  private readonly upload_proof_of_tax_situation!:
    UploadProofOfTaxSituationCommand;

  @Inject(TYPES.UTIL_FUNCTIONS)
  readonly functions!: Functions;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'document-validation.attached-new-proof-of-tax-situation';

  readonly limit_file_size = 4 * 1024 * 1024;

  address_proof: File | null = null;

  invalid_file = true;

  is_loading = false;

  minimum_file_size = 0.1;

  allowed_extensions = ['image/jpeg', 'image/jpg', 'image/png'];

  address_proof_accept = 'image/png, image/jpeg, image/jpg';

  customer_document_dto: CustomerDocumentWealthDto = {
    token: '',
    file: {
      name: '',
      mime_type: '',
      file_data: '',
    },
  }

  magic_link_dto: MagicLinkDto = {
    token: '',
  }

  file_id = '';

  attached_successfully = false;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async (token: string) => {
    this.magic_link_dto.token = token;
    this.setTokenToStorage();
    await this.verifyToken();
  }

  get styledFileName(): string {
    if (this.address_proof) {
      return `${this.address_proof.name.slice(0, 30)}${this.address_proof.name.length > 35 ? '...' : ''}`;
    }

    return this.translate('attach_placeholder');
  }

  get styledFileSize(): string {
    if (this.address_proof) {
      return convertToLargerPossibleUnit(this.address_proof.size);
    }

    return '0 kb';
  }

  setTokenToStorage = () => {
    sessionStorage.setItem('authorization', this.magic_link_dto.token);
  }

  setCustomFileDtoData = async () => {
    if (this.address_proof) {
      const base_64_file = await this.convertFileToBase64(this.address_proof);
      if (base_64_file) {
        this.customer_document_dto = {
          token: this.magic_link_dto.token,
          file: {
            name: this.file_id,
            mime_type: this.address_proof.type,
            file_data: base_64_file.toString(),
          },
        };
      }
    }
  }

  convertFileToBase64 = async (selected_file: File) => (
    this.functions.convert_file_to_base_64(selected_file)
  );

  handleSelectedFile = (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target.files) {
      const [file] = target.files;
      if (file.size <= this.limit_file_size && file.size >= this.minimum_file_size
        && this.allowed_extensions.indexOf(file.type) > -1) {
        this.address_proof = file;
        this.invalid_file = false;
      } else {
        this.address_proof = null;
        this.invalid_file = true;
        this.message_notifier.showErrorNotification(this.translate('errors.invalid_file'));
      }
    }
  }

  showValidationError = () => {
    this.message_notifier.showErrorNotification(this.translate('errors.verify_token'));
    setTimeout(() => {
      this.goToLoginPage();
    }, 3000);
  }

  verifyToken = async () => {
    try {
      const { file_id, valid } = await this
        .verify_token_magic_link_query.execute(this.magic_link_dto);
      if (valid) {
        this.file_id = file_id;
      } else {
        this.showValidationError();
      }
    } catch {
      this.showValidationError();
    }
  }

  updateAccountStatementDocument = async () => {
    try {
      this.is_loading = true;
      await this.setCustomFileDtoData();
      if (this.customer_document_dto.file.file_data) {
        await this.upload_proof_of_tax_situation.execute(this.customer_document_dto);
        this.goToSuccessView();
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_bank_statement_command'));
    } finally {
      this.is_loading = false;
    }
  }

  goToLoginPage = () => {
    window.location.href = '/login';
  }

  deleteTokenFromStorage = () => {
    sessionStorage.clear();
  }

  goToSuccessView = () => {
    this.deleteTokenFromStorage();
    this.attached_successfully = true;
  }
}
