import TYPES from '../../types';

// Domain
import LinkedGoalState from '../../domain/state/linked-goal-state';
import { LinkedGoalStateManager } from '../../domain/state/linked-goal-state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class LinkedGoalStateManagerService extends LinkedGoalState {
  @Inject(TYPES.LINKED_INVESTOR_GOAL_STATE_MANAGER)
  private readonly state_manager!: LinkedGoalStateManager;

  getLinkedGoalState(): LinkedGoalState {
    return this.state_manager.state;
  }
}
