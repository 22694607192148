import { Container } from 'inversify-props';
import TYPES from './types';
import GetPersonDummyRepository
  from './infrastructure/repositories/search-person-repository/get-person-dummy-repository';
import GetPersonHttpRepository
  from './infrastructure/repositories/search-person-repository/get-person-http-repository';
import UpdatePersonDummyRepository
  from './infrastructure/repositories/update-person-repository/update-person-dummy-repository';
import UpdatePersonHttpRepository
  from './infrastructure/repositories/update-person-repository/update-person-http-repository';
import GetOnBoardingPersonQuery from './application/queries/get-on-boarding-person-query';
import UpdateOnBoardingPersonCommand from './application/command/update-on-boarding-person-command';
import GetPersonRepository from './domain/repositories/get-person-repository';
import UpdatePersonRepository from './domain/repositories/update-person-repository';

export default function setupPersonModule(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<GetPersonRepository>(TYPES.ON_BOARDING_GET_PERSON_REPOSITORY)
    .to(
      withDummies
        ? GetPersonDummyRepository
        : GetPersonHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetOnBoardingPersonQuery>(TYPES.GET_ON_BOARDING_PERSON_QUERY)
    .to(GetOnBoardingPersonQuery)
    .inSingletonScope();

  container
    .bind<UpdatePersonRepository>(TYPES.ON_BOARDING_UPDATE_PERSON_REPOSITORY)
    .to(
      withDummies
        ? UpdatePersonDummyRepository
        : UpdatePersonHttpRepository,
    ).inSingletonScope();

  container
    .bind<UpdateOnBoardingPersonCommand>(TYPES.UPDATE_ON_BOARDING_PERSON_COMMAND)
    .to(UpdateOnBoardingPersonCommand)
    .inSingletonScope();
}
