import TYPES from '../../types';

// Domain
import {
  CustomerInvestorGoalReminderEntity,
} from '@/modules/flagship/customer-investor-goal-reminder/domain/entities/customer-investor-goal-reminder-entity';
import {
  CustomerInvestorGoalReminderRepository,
} from '@/modules/flagship/customer-investor-goal-reminder/domain/repositories/customer-investor-goal-reminder-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UpdateCustomerInvestorGoalReminderCommand
  extends Command<CustomerInvestorGoalReminderEntity> {
  @Inject(TYPES.CUSTOMER_INVESTOR_GOAL_REMINDER_REPOSITORY)
  private readonly repository!: CustomerInvestorGoalReminderRepository;

  internalExecute(customer_investor_goal_reminder_entity: CustomerInvestorGoalReminderEntity):
    Promise<void> {
    return this.repository.update(customer_investor_goal_reminder_entity);
  }
}
