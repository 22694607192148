import TYPES from '../../types';

// Domain
import { TransactionStatusEntity } from '../../domain/entities/transaction-status-entity';
import { SearchTransactionStatusRepository } from '../../domain/repositories/search-transaction-status-repository';
import { TransactionStatusStateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class SearchTransactionStatusQuery extends
  Query<Promise<Array<TransactionStatusEntity>>> {
  @Inject(TYPES.TRANSACTION_STATUS_STATE_MANAGER)
  private readonly state_manager!: TransactionStatusStateManager;

  @Inject(TYPES.SEARCH_TRANSACTION_STATUS_REPOSITORY)
  private readonly repository!: SearchTransactionStatusRepository;

  internalExecute(): Promise<Array<TransactionStatusEntity>> {
    return new Promise((resolve, reject) => {
      if (!this.state_manager.state.items.length) {
        this.repository.execute()
          .then((items) => {
            this.state_manager.patch({ items });
            resolve(items);
          })
          .catch(reject);
      } else {
        resolve(this.state_manager.state.items);
      }
    });
  }
}
