import TYPES from '../../types';

// Domain
import { AllianzAccountIsOnCancellationProcessEntity } from '../../domain/entities/allianz-account-is-on-cancellation-process-entity';
import { AllianzAccountRepository } from '../../domain/repository/allianz-account-repository';
import { AllianzAccountIsOnCancellationProcessStateManager } from '../../domain/state/allianz-account-is-on-cancellation-process-state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetAllianzAccountIsOnCancellationProcessQuery extends Query<Promise<
  AllianzAccountIsOnCancellationProcessEntity>> {
  @Inject(TYPES.ALLIANZ_ACCOUNT_IS_ON_CANCELLATION_PROCESS_STATE_MANAGER)
  private readonly state_manager!: AllianzAccountIsOnCancellationProcessStateManager;

  @Inject(TYPES.ALLIANZ_ACCOUNT_REPOSITORY)
  private readonly repository!: AllianzAccountRepository;

  internalExecute(): Promise<AllianzAccountIsOnCancellationProcessEntity> {
    return new Promise((resolve, reject) => {
      if (!this.state_manager.state.loaded) {
        this.repository.get_allianz_account_is_on_cancellation_process()
          .then((allianz_account_is_on_cancellation_process) => {
            this.state_manager.patch({ allianz_account_is_on_cancellation_process, loaded: true });
            resolve(allianz_account_is_on_cancellation_process);
          })
          .catch(reject);
      } else {
        resolve(this.state_manager.state.allianz_account_is_on_cancellation_process);
      }
    });
  }
}
