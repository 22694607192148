import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import InvestorProfileOnFlowCompleteDummyRepository
  from '@/modules/flagship/investor-profile/flow-completed/infrastructure/repositories/investor-profile-on-flow-complete-dummy-repository';
import InvestorProfileOnFlowCompleteHttpRepository
  from '@/modules/flagship/investor-profile/flow-completed/infrastructure/repositories/investor-profile-on-flow-complete-http-repository';

// Application
import UpdateInvestorProfileOnFlowCompleteCommand
  from '@/modules/flagship/investor-profile/flow-completed/application/commands/update-investor-profile-on-flow-complete-command';

// Domain
import {
  InvestorProfileOnFlowCompleteRepository,
} from '@/modules/flagship/investor-profile/flow-completed/domain/repositories/investor-profile-on-flow-complete-repository';

export default function setupFlowCompleted(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<InvestorProfileOnFlowCompleteRepository>(
      TYPES.INVESTOR_PROFILE_ON_FLOW_COMPLETE_REPOSITORY,
    ).to(
      withDummies
        ? InvestorProfileOnFlowCompleteDummyRepository
        : InvestorProfileOnFlowCompleteHttpRepository,
    ).inSingletonScope();

  container
    .bind<UpdateInvestorProfileOnFlowCompleteCommand>(
      TYPES.UPDATE_INVESTOR_PROFILE_ON_FLOW_COMPLETE_COMMAND,
    ).to(UpdateInvestorProfileOnFlowCompleteCommand)
    .inSingletonScope();
}
