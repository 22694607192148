














import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'LoadingDataCard',
})
export default class LoadingDataCard extends Vue {
}
