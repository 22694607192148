import TYPES from '../../types';

// Domain
import {
  CustomInvestorGoalRepository,
} from '@/modules/flagship/custom-investor-goal/domain/repositories/custom-investor-goal-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class DeleteCustomInvestorGoalCommand extends Command<string> {
  @Inject(TYPES.CUSTOM_INVESTOR_GOAL_REPOSITORY)
  private readonly repository!: CustomInvestorGoalRepository;

  internalExecute(custom_investor_goal_id: string) {
    return this.repository.delete(custom_investor_goal_id);
  }
}
