import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import CustomerInvestorGoalBalanceDummyRepository
  from './infrastructure/repositories/customer-investor-goal-balance-dummy-repository/customer-investor-goal-balance-dummy-repository';
import CustomerInvestorGoalBalanceHttpRepository
  from './infrastructure/repositories/customer-investor-goal-balance-http-repository';
import CustomerInvestorGoalBalanceVueStateManager
  from './infrastructure/state/customer-investor-goal-balance-vue-state-manager';

// Application
import GetCustomerInvestorGoalBalanceQuery
  from './application/query/get-customer-investor-goal-balance';

// Domain
import { CustomerInvestorGoalBalanceRepository } from './domain/repositories/customer-investor-goal-balance-repository';
import { CustomerInvestorGoalBalanceStateManager } from './domain/state/customer-investor-goal-balance-state-manager';

export default function setupCustomerInvestorGoalBalance(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    // eslint-disable-next-line max-len
    .bind<CustomerInvestorGoalBalanceStateManager>(TYPES.CUSTOMER_INVESTOR_GOAL_BALANCE_STATE_MANAGER)
    .to(CustomerInvestorGoalBalanceVueStateManager)
    .inSingletonScope();

  container
    .bind<CustomerInvestorGoalBalanceRepository>(TYPES.CUSTOMER_INVESTOR_GOAL_BALANCE_REPOSITORY)
    .to(
      withDummies
        ? CustomerInvestorGoalBalanceDummyRepository : CustomerInvestorGoalBalanceHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetCustomerInvestorGoalBalanceQuery>(TYPES.GET_CUSTOMER_INVESTOR_GOAL_BALANCE_QUERY)
    .to(GetCustomerInvestorGoalBalanceQuery)
    .inSingletonScope();
}
