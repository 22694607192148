import TYPES from '../../types';

// Domain
import {
  ForecastCalculationDto,
} from '@/modules/flagship/retirement-forecast-calculation/domain/dtos/forecast-calculation-dto';
import {
  RetirementForecastCalculationRepository,
} from '@/modules/flagship/retirement-forecast-calculation/domain/repositories/retirement-forecast-calculation-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetRetirementForecastCalculationQuery
  extends Query<Promise<ForecastCalculationDto>> {
  @Inject(TYPES.RETIREMENT_FORECAST_CALCULATION_REPOSITORY)
  private readonly repository!: RetirementForecastCalculationRepository;

  internalExecute(): Promise<ForecastCalculationDto> {
    return this.repository.get();
  }
}
