import TYPES from '@/modules/shared/types';

// Domain
import {
  CustomGoalMonthlyPaymentCalculationRepository,
} from '@/modules/flagship/custom-goal-calculations/domain/repositories/custom-goal-monthly-payment-calculation-repository';
import {
  CustomGoalCalculationEntity,
} from '@/modules/flagship/custom-goal-calculations/domain/entities/custom-goal-calculation-entity';
import {
  CustomGoalMonthlyPaymentCalculationDto,
} from '@/modules/flagship/custom-goal-calculations/domain/dtos/custom-goal-monthly-payment-calculation-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
class CustomGoalMonthlyPaymentCalculationHttpRepository
implements CustomGoalMonthlyPaymentCalculationRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/custom-investor-goal-monthly-payment-calculation/`;

  calculate(payload: CustomGoalMonthlyPaymentCalculationDto):
    Promise<CustomGoalCalculationEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint, 'POST', payload)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}

export default CustomGoalMonthlyPaymentCalculationHttpRepository;
