import { Container } from 'inversify-props';
import setupAgreements from './agreements/setup';
import setupPersonModule from './person/setup';
import setupCatalogs from './catalogs/setup';
import setupEmails from './emails/setup';
import setupInvestmentProvider from './investment-provider/setup';
import setupOnBoardingStep from './on-boarding-steps/setup';
import setupAddress from './address/setup';
import setupPermissions from './permissions/setup';
import setupKuspit from './kuspit/setup';
import setupAllianz from './allianz/setup';
import setupUploadFile from './upload-file/setup';
import setupOnBoardingInvitations from './on-boarding-invitations/setup';
import setupCustomerDocuments from './customer-documents/setup';
import setupFile from './file/setup';
import setupInvestmentProduct from './investment-product/setup';

export default function setupMyInvestmentModule(container: Container) {
  setupAgreements(container);
  setupPersonModule(container);
  setupCatalogs(container);
  setupEmails(container);
  setupInvestmentProvider(container);
  setupOnBoardingStep(container);
  setupAddress(container);
  setupPermissions(container);
  setupKuspit(container);
  setupAllianz(container);
  setupUploadFile(container);
  setupOnBoardingInvitations(container);
  setupCustomerDocuments(container);
  setupFile(container);
  setupInvestmentProduct(container);
}
