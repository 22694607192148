import TYPES from './types';

import { Container } from 'inversify-props';

// Infrastructure
import InvestmentTypeHiringStepVueStateManager from './infrastructure/state/investment-type-hiring-step-vue-state-manager';
import InvestmentTypeHiringStepDummyRepository
  from './infrastructure/repositories/investment-type-hiring-step-dummy-repository';
import InvestmentTypeHiringStepHttpRepository
  from './infrastructure/repositories/investment-type-hiring-step-http-repository';

// Application
import CreateInvestmentTypeHiringStepCommand from './application/commands/create-investment-type-hiring-step-command';
import SearchInvestmentTypeHiringStepsQuery from './application/queries/search-investment-type-hiring-steps-query';
import UpdateInvestmentTypeHiringStepCommand from './application/commands/update-investment-type-hiring-step-command';

// Domain
import { InvestmentTypeHiringStepStateManager } from './domain/state/investment-type-hiring-step-state-manager';
import { InvestmentTypeHiringStepRepository }
  from './domain/repositories/investment-type-hiring-step-repository';

export default function setupSteps(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<InvestmentTypeHiringStepStateManager>(TYPES.INVESTMENT_TYPE_HIRING_STEP_STATE_MANAGER)
    .to(InvestmentTypeHiringStepVueStateManager)
    .inSingletonScope();

  container
    .bind<InvestmentTypeHiringStepRepository>(TYPES.INVESTMENT_TYPE_HIRING_STEP_REPOSITORY)
    .to(
      withDummies
        ? InvestmentTypeHiringStepDummyRepository
        : InvestmentTypeHiringStepHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<CreateInvestmentTypeHiringStepCommand>(TYPES.CREATE_INVESTMENT_TYPE_HIRING_STEP_COMMAND)
    .to(CreateInvestmentTypeHiringStepCommand)
    .inSingletonScope();

  container
    .bind<SearchInvestmentTypeHiringStepsQuery>(TYPES.SEARCH_INVESTMENT_TYPE_HIRING_STEP_QUERY)
    .to(SearchInvestmentTypeHiringStepsQuery)
    .inSingletonScope();

  container
    .bind<UpdateInvestmentTypeHiringStepCommand>(TYPES.UPDATE_INVESTMENT_TYPE_HIRING_STEP__COMMAND)
    .to(UpdateInvestmentTypeHiringStepCommand)
    .inSingletonScope();
}
