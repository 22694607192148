import TYPES from '../../types';

// Domain
import { BudgetEntity } from '../../domain/entities/budget-entity';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class EditEmergencyFundGoalPlanBudgetService {
  @Inject(TYPES.EDIT_EMERGENCY_FUND_GOAL_PLAN_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  setBudgetInformation(budget: BudgetEntity): void {
    this.state_manager.state.budget = { ...budget };
  }

  getBudgetInformation(): BudgetEntity {
    return this.state_manager.state.budget;
  }
}
