import TYPES from '@/types';

// Application
import SignOutCommand
  from '@/modules/authentication/application/commands/sign-out-command';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class SignOutService {
  @Inject(TYPES.SIGN_OUT_COMMAND)
  readonly sign_out!: SignOutCommand;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.authentication';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  logout = async (route: string) => {
    try {
      await this.sign_out.execute();
      sessionStorage.clear();
      window.location.href = route;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.sign_out'));
    }
  }
}
