







































































































































































import { Component, Vue } from 'vue-property-decorator';

import DistributeDifferentContributionsAmongLinkedGoalsPocketViewModel
  from '@/vue-app/view-models/components/flagship/distribute-different-contributions-among-linked-goals-pocket-view-model';
import ModalDistributeDifferentContributionsSuccessPocket
  from '@/vue-app/components/flagship/ModalDistributeDifferentContributionsSuccessPocket.vue';

@Component({
  name: 'DistributeDifferentContributionsAmongLinkedGoalsPocket',
  components: { ModalDistributeDifferentContributionsSuccessPocket },
})
export default class DistributeDifferentContributionsAmongLinkedGoalsPocket extends Vue {
  distribute_special_contribution_among_linked_goals_view_model = Vue.observable(
    new DistributeDifferentContributionsAmongLinkedGoalsPocketViewModel(),
  );

  created() {
    this.distribute_special_contribution_among_linked_goals_view_model.initialize();
  }
}
