import TYPES from '../../types';

// Domain
import { CreateCustomerKuspitAccountRepository } from '../../domain/repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CreateCustomerKuspitAccountCommand extends Command<string> {
  @Inject(TYPES.CREATE_CUSTOMER_KUSPIT_ACCOUNT_REPOSITORY)
  private readonly repository!: CreateCustomerKuspitAccountRepository;

  internalExecute(customer_id: string): Promise<unknown> {
    return this.repository.create(customer_id);
  }
}
