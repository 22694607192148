import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import {
  AllianzCustomerAvailableBalanceHttpRepository,
  AllianzCustomerAvailableBalanceDummyRepository,
} from './infrastructure/repository';

// Application
import { SearchAllianzCustomerAvailableBalanceQuery } from './application/queries';

// Domain
import { AllianzCustomerAvailableBalanceRepository } from './domain/repository';

export default function setupAllianzCustomerAvailableBalance(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<AllianzCustomerAvailableBalanceRepository>(
      TYPES.ALLIANZ_CUSTOMER_AVAILABLE_BALANCE_REPOSITORY,
    )
    .to(
      withDummies
        ? AllianzCustomerAvailableBalanceDummyRepository
        : AllianzCustomerAvailableBalanceHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchAllianzCustomerAvailableBalanceQuery>(
      TYPES.SEARCH_ALLIANZ_CUSTOMER_AVAILABLE_BALANCE_QUERY,
    )
    .to(SearchAllianzCustomerAvailableBalanceQuery)
    .inSingletonScope();
}
