import TYPES from '@/modules/shared/types';

// Domain
import {
  EmergencyFundInvestorGoalCalculatorRepository,
} from '../../domain/repositories/emergency-fund-investor-goal-calculator-repository';
import { EmergencyFundInvestorGoalCalculatorDto }
  from '../../domain/dtos/emergency-fund-investor-goal-calculator-dto';
import { EmergencyFundInvestorGoalCalculatorEntity }
  from '../../domain/entities/emergency-fund-investor-goal-calculator-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class EmergencyFundInvestorGoalCalculatorHttpRepository implements
  EmergencyFundInvestorGoalCalculatorRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/emergency-fund-investor-goal-calculator/by-definition/`;

  get_emergency_fund_calculation(emergency_fund_calculation_dto:
    EmergencyFundInvestorGoalCalculatorDto): Promise<EmergencyFundInvestorGoalCalculatorEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint, 'POST', emergency_fund_calculation_dto)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}
