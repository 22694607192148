import TYPES from '../../types';

// Domain
import {
  CustomInvestorGoalRepository,
} from '@/modules/flagship/custom-investor-goal/domain/repositories/custom-investor-goal-repository';
import {
  CustomInvestorGoalStateManager,
} from '@/modules/flagship/custom-investor-goal/domain/state/custom-investor-goal-state-manager';
import {
  CustomInvestorGoalEntity,
} from '@/modules/flagship/custom-investor-goal/domain/entities/custom-investor-goal-entity';
import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class GetCustomInvestorGoalQuery extends Query<Promise<CustomInvestorGoalEntity>> {
  @Inject(TYPES.CUSTOM_INVESTOR_GOAL_REPOSITORY)
  private readonly repository!: CustomInvestorGoalRepository;

  @Inject(TYPES.CUSTOM_INVESTOR_GOAL_STATE_MANAGER)
  private readonly state_manager!: CustomInvestorGoalStateManager;

  internalExecute(loadStateManager = true): Promise<CustomInvestorGoalEntity> {
    return new Promise((resolve, reject) => {
      if (loadStateManager && this.state_manager.state.loaded) {
        resolve(this.state_manager.state.items);
      } else {
        this.repository.get()
          .then((items) => {
            this.state_manager.patch({ items, loaded: true });
            resolve(items);
          })
          .catch(reject);
      }
    });
  }
}
