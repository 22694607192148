import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import PersonalInfoDummyRepository
  from '@/modules/flagship/investor-profile/personal-info/infraestructure/repositories/personal-info-dummy-repository/personal-info-dummy-repository';
import PersonalInfoHttpRepository
  from '@/modules/flagship/investor-profile/personal-info/infraestructure/repositories/personal-info-http-repository';
import PersonalInfoVueStateManager
  from '@/modules/flagship/investor-profile/personal-info/infraestructure/state/personal-info-vue-state-manager';

// Application
import GetPersonalInfoQuery
  from '@/modules/flagship/investor-profile/personal-info/application/queries/get-personal-info-query';

// Domain
import {
  PersonalInfoRepository,
} from '@/modules/flagship/investor-profile/personal-info/domain/repositories/personal-info-repository';
import { PersonalInfoStateManager } from '@/modules/flagship/investor-profile/personal-info/domain/state/personal-info-state-manager';

export default function setupPersonalInfoFlagship(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<PersonalInfoStateManager>(TYPES.PERSONAL_INFO_STATE_MANAGER)
    .to(PersonalInfoVueStateManager)
    .inSingletonScope();

  container
    .bind<PersonalInfoRepository>(
      TYPES.PERSONAL_INFO_REPOSITORY,
    )
    .to(
      withDummies
        ? PersonalInfoDummyRepository
        : PersonalInfoHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetPersonalInfoQuery>(
      TYPES.GET_PERSONAL_INFO_QUERY,
    ).to(GetPersonalInfoQuery)
    .inSingletonScope();
}
