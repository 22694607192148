import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';
import { MatiVerificationRepository } from '../../domain/repositories/mati-verification-repository';
import TYPES from '../../types';

@Injectable()
export default class RequestManualReviewCommand extends Command<void> {
  @Inject(TYPES.ON_BOARDING_MATI_VERIFICATION_REPOSITORY)
  private readonly mati_verification_repository!: MatiVerificationRepository;

  internalExecute() {
    return this.mati_verification_repository.requestManualReview();
  }
}
