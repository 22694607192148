import TYPES from '@/types';
import Vue from 'vue';
import { v4 } from 'uuid';
import { currencyFormat } from '@/vue-app/utils/currency';
import { getGoalIconPath } from '@/vue-app/utils/goal-icon-path';

// Application
import GetInvestmentProductsQuery from '@/modules/flagship/catalogs/investment-products/application/queries/get-investment-products-query';
import GetSearchByCustomerQuery from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-search-by-customer-query';
import GetCustomerInvestorGoalBalanceQuery
  from '@/modules/flagship/customer-investor-goal-balance/application/query/get-customer-investor-goal-balance';
import GetCustomerKuspitPositionQuery from '@/modules/my-investment/kuspit/customer-kuspit-position/application/queries/get-customer-kuspit-position-query';

// Domain
import {
  InvestorGoalScheduledDistributionPocketWithdrawalDto,
} from '@/modules/flagship/investor-goal-scheduled-distribution/sowos-pocket/withdrawal/domain/dto/investor-goal-scheduled-distribution-pocket-withdrawal-dto';
import {
  SearchByCustomerEntity,
} from '@/modules/flagship/investor-goal/search-by-customer/domain/entities/search-by-customer-entity';
import { SearchByCustomerDto } from '@/modules/flagship/investor-goal/search-by-customer/domain/dtos/search-by-customer-dto';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

type ArrayLinkedGoals = {
  investor_goal_id: string;
  name: string;
  label: string;
  active_goal_amount: number;
  icon: string;
  assigned_amount: number;
  alt: string;
  assigned_amount_field: string;
  icon_path: string;
}
export default class WithdrawalGoalsDistributionViewModel {
  @Inject(TYPES.GET_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_investment_products_query!: GetInvestmentProductsQuery;

  @Inject(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetSearchByCustomerQuery;

  @Inject(TYPES.GET_CUSTOMER_INVESTOR_GOAL_BALANCE_QUERY)
  private readonly get_customer_investor_goal_balance_query!: GetCustomerInvestorGoalBalanceQuery;

  @Inject(TYPES.GET_CUSTOMER_KUSPIT_POSITION_QUERY)
  private readonly get_customer_kuspit_position_query!: GetCustomerKuspitPositionQuery;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.kuspit-dashboard.withdrawals.goals-distribution';

  private readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  linked_goals: Array<ArrayLinkedGoals> = [];

  retirement_amount = 0;

  step = 50;

  is_disabled = true;

  is_any_goal_active = false;

  index_unassigned_amount = 0;

  unassigned_balance = 0;

  total_balance = 0;

  payload_to_save_distribution: InvestorGoalScheduledDistributionPocketWithdrawalDto = {
    total_contribution: '0',
    unassigned_balance: '0',
    investor_goals: [],
  };

  private search_by_customer_dto: SearchByCustomerDto = {
    reload: true,
    associated_product_id: '',
    is_active: true,
  };

  private active_goals: Array<SearchByCustomerEntity> = [];

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async () => {
    await this.loadKuspitPosition();
    await this.loadInvestmentProducts();
    await this.loadActiveGoals();
    if (this.is_any_goal_active) {
      await this.loadCustomerInvestorGoalBalance();
    }
    this.setUnassignedAmount();
  }

  getAmountFormatted = (amount: number) => (currencyFormat(amount));

  get retirement_amount_formatted() {
    return currencyFormat(this.retirement_amount);
  }

  getIconMaxWidth = (icon_goal_name: string) => ((icon_goal_name === 'icon-add.svg') ? '40px' : '30px');

  loadInvestmentProducts = async () => {
    try {
      const investment_products = await this.get_investment_products_query.execute();
      Object.entries(investment_products).forEach((item: any) => {
        if (item[1].name === 'sowos_pocket') {
          this.search_by_customer_dto.associated_product_id = item[1].id;
        }
      });
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_investment_products_query'));
    }
  }

  loadActiveGoals = async () => {
    try {
      // eslint-disable-next-line max-len
      this.active_goals = await this.get_search_by_customer_query.execute(this.search_by_customer_dto);
      Object.entries(this.active_goals).forEach((goal: any) => {
        this.is_any_goal_active = true;
        this.linked_goals.push({
          investor_goal_id: goal[1].investor_goal_id,
          // eslint-disable-next-line max-len
          name: (goal[1].custom_investor_goal) ? goal[1].custom_investor_goal.goal_name : goal[1].investment_goal_type.name,
          // eslint-disable-next-line max-len
          label: (goal[1].custom_investor_goal) ? goal[1].custom_investor_goal.goal_name : goal[1].investment_goal_type.label,
          icon: (goal[1].custom_investor_goal) ? goal[1].custom_investor_goal.custom_goal_type.icon_name : 'icon-retirement.svg',
          assigned_amount: 0,
          active_goal_amount: Math.floor(goal[1].active_goal_amount) || 0,
          alt: (goal[1].custom_investor_goal) ? 'alts.custom' : 'alts.emergency_fund',
          assigned_amount_field: '0.00',
          icon_path: getGoalIconPath(
            goal[1].investor_goal_id,
            goal[1].custom_investor_goal?.custom_goal_type?.icon_name || 'icon-retirement.svg',
            goal[1].custom_investor_goal?.updated_at || '',
          ),
        });
      });
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_search_by_customer_query'));
    }
  }

  loadCustomerInvestorGoalBalance = async () => {
    try {
      // eslint-disable-next-line max-len
      const custom_investor_goal_balance = await this.get_customer_investor_goal_balance_query.execute(
        { investment_product_id: this.search_by_customer_dto.associated_product_id },
      );
      if (custom_investor_goal_balance.unassigned_balance) {
        this.unassigned_balance = custom_investor_goal_balance.unassigned_balance;
      }
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_customer_investor_goal_balance'));
      }
    }
  }

  loadKuspitPosition = async () => {
    try {
      const kuspit_account = await this.get_customer_kuspit_position_query.execute();
      if (kuspit_account.has_funds) {
        this.total_balance = Number(kuspit_account.position);
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_kuspit_position'));
    }
  }

  setUnassignedAmount = () => {
    this.linked_goals.push({
      investor_goal_id: '',
      name: 'unassigned',
      active_goal_amount: Math.floor(this.unassigned_balance),
      label: 'Saldo sin asignar',
      icon: 'noun-coins.svg',
      assigned_amount: 0,
      alt: 'alts.unassigned',
      assigned_amount_field: '0.00',
      icon_path: getGoalIconPath('', 'noun-coins.svg', ''),
    });
  }

  setRetirementAmount = (amount: string) => {
    this.payload_to_save_distribution.investor_goals.length = 0;
    const parsed_number_without_characters = parseFloat(amount.replaceAll(/[^0-9.]/g, ''));
    const index = this.linked_goals.findIndex((item) => item.name === 'unassigned');
    this.linked_goals.forEach((item, position) => {
      // eslint-disable-next-line no-param-reassign
      item.assigned_amount = 0;
      // eslint-disable-next-line no-param-reassign
      item.assigned_amount_field = '0.00';
    });
    this.is_disabled = true;
    this.retirement_amount = parsed_number_without_characters;
    this.index_unassigned_amount = index;
  }

  incrementAmount = (index: number) => {
    let current_amount = this.linked_goals[index].assigned_amount;
    current_amount += this.step;
    if (this.linked_goals[index].active_goal_amount >= current_amount) {
      this.linked_goals[index].assigned_amount += this.step;
      this.linked_goals[index].assigned_amount_field = this.linked_goals[index].assigned_amount
        .toFixed(2);
      this.validateTotalAmounts();
    }
  }

  decrementAmount = (index: number) => {
    if (this.linked_goals[index].assigned_amount > 0) {
      this.linked_goals[index].assigned_amount -= this.step;
      if (this.linked_goals[index].assigned_amount < 0) {
        this.linked_goals[index].assigned_amount = 0;
      }
      this.linked_goals[index].assigned_amount_field = this.linked_goals[index].assigned_amount
        .toFixed(2);
      this.validateTotalAmounts();
    }
  }

  validateTotalAmounts = () => {
    const amount_goals = this.linked_goals.map((obj) => (
      obj.assigned_amount
    ));
    const total_amount_goals = amount_goals.reduce(
      (total, currentValue) => total + currentValue, 0,
    );
    this.is_disabled = total_amount_goals !== this.retirement_amount;
  }

  changeAssignedAmountField = (index: number) => {
    const parsed_assigned_amount_field = (this.linked_goals[index].assigned_amount_field)
      ? Number(parseFloat(this.linked_goals[index].assigned_amount_field.replaceAll(/[^\d.-]/g, '')).toFixed(2))
      : 0;
    // eslint-disable-next-line max-len
    if (parsed_assigned_amount_field >= 0 && this.linked_goals[index].active_goal_amount >= parsed_assigned_amount_field) {
      this.linked_goals[index].assigned_amount_field = parsed_assigned_amount_field.toFixed(2);
      this.linked_goals[index].assigned_amount = parsed_assigned_amount_field;
      this.validateTotalAmounts();
    } else {
      this.linked_goals[index].assigned_amount_field = '0';
      this.linked_goals[index].assigned_amount = 0;
    }
  }

  userHasActiveGoals = () => (this.is_any_goal_active)

  nextStep = () => {
    let total_contribution = 0;
    this.linked_goals.forEach((goal) => {
      if (goal.investor_goal_id && goal.assigned_amount > 0) {
        this.payload_to_save_distribution.investor_goals.push(
          {
            id: v4(),
            investor_goal_id: goal.investor_goal_id,
            // TODO - ADD A NEW ENDPOINT TO GET THE FOLLOWING ID WHEN BACKEND FINISH THE ENDPOINT
            investment_product_fund_type_id: 'ebbb2476-4f8d-4eff-a817-cb1c5c3d7a3e',
            amount_to_distribute: `-${goal.assigned_amount.toFixed(2)}`,
            is_deposit: false,
          },
        );
      }
      total_contribution += goal.assigned_amount;
    });
    this.payload_to_save_distribution.unassigned_balance = `-${this
      .linked_goals[this.index_unassigned_amount].assigned_amount.toFixed(2)}`;
    this.payload_to_save_distribution.total_contribution = `-${total_contribution.toFixed(2)}`;
    this.view.$emit('nextStep', this.payload_to_save_distribution);
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }
}
