import TYPES from '../../types';

// Domain
import {
  InvestmentGoalsEntity,
} from '@/modules/flagship/catalogs/investment-goals/domain/entities/investment-goals-entity';
import { InvestmentGoalsRepository } from '@/modules/flagship/catalogs/investment-goals/domain/repositories/investment-goals-repository';
import {
  InvestmentGoalsStateManager,
} from '@/modules/flagship/catalogs/investment-goals/domain/state/investment-goals-state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class InvestmentGoalsQuery extends Query<Promise<Array<InvestmentGoalsEntity>>> {
  @Inject(TYPES.INVESTMENT_GOALS_REPOSITORY)
  private readonly repository!: InvestmentGoalsRepository;

  @Inject(TYPES.INVESTMENT_GOALS_STATE_MANAGER)
  private readonly state_manager!: InvestmentGoalsStateManager;

  internalExecute(): Promise<Array<InvestmentGoalsEntity>> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded) {
        resolve(this.state_manager.state.items);
      } else {
        this.repository.get()
          .then((items) => {
            this.state_manager.patch({ items, loaded: true });
            resolve(items);
          })
          .catch(reject);
      }
    });
  }
}
