import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import InvestmentProductFundTypeDummyRepository
  from './infrastructure/repositories/investment-product-fund-type-dummy-repository/investment-product-fund-type-dummy-repository';
import InvestmentProductFundTypeHttpRepository
  from './infrastructure/repositories/investment-product-fund-type-http-repository';
import InvestmentProductFundTypeVueStateManager
  from './infrastructure/state/investment-product-fund-type-vue-state-manager';

// Application
import GetInvestmentProductFundTypeQuery from './application/queries/get-investment-product-fund-type-query';

// Domain
import {
  InvestmentProductFundTypeRepository,
} from './domain/repositories/investment-product-fund-type-repository';
import {
  InvestmentProductFundTypeStateManager,
} from './domain/state/investment-product-fund-type-state-manager';

export default function setupInvestmentProductFundType(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<InvestmentProductFundTypeStateManager>(TYPES.INVESTMENT_PRODUCT_FUND_TYPE_STATE_MANAGER)
    .to(InvestmentProductFundTypeVueStateManager)
    .inSingletonScope();

  container
    .bind<InvestmentProductFundTypeRepository>(
      TYPES.INVESTMENT_PRODUCT_FUND_TYPE_REPOSITORY,
    ).to(
      withDummies
        ? InvestmentProductFundTypeDummyRepository
        : InvestmentProductFundTypeHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetInvestmentProductFundTypeQuery>(
      TYPES.GET_INVESTMENT_PRODUCT_FUND_TYPE_QUERY,
    ).to(GetInvestmentProductFundTypeQuery)
    .inSingletonScope();
}
