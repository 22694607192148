import fixtures from './fixture.json';

// Domain
import Injectable from '@/modules/shared/domain/di/injectable';
import {
  RetirementGoalMonthlyPaymentCalculationRepository,
} from '@/modules/flagship/retirement-goal-calculations/domain/repositories/retirement-goal-monthly-payment-calculation-repository';
import {
  RetirementGoalMonthlyPaymentCalculationEntity,
} from '@/modules/flagship/retirement-goal-calculations/domain/entities/retirement-goal-monthly-payment-calculation-entity';

@Injectable()
class RetirementGoalMonthlyPaymentCalculationDummyRepository
implements RetirementGoalMonthlyPaymentCalculationRepository {
  calculate(): Promise<RetirementGoalMonthlyPaymentCalculationEntity> {
    return Promise.resolve(fixtures);
  }
}

export default RetirementGoalMonthlyPaymentCalculationDummyRepository;
