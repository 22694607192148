import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import {
  OnBoardingInvitationsStatisticsHttpRepository,
  OnBoardingInvitationsStatisticsDummyRepository,
} from './infrastructure/repository';

// Application
import { GetOnBoardingInvitationsStatisticsQuery } from './application/queries';

// Domain
import { OnBoardingInvitationsStatisticsRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupOnBoardingInvitations(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.ON_BOARDING_INVITATIONS_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<OnBoardingInvitationsStatisticsRepository>(
      TYPES.ON_BOARDING_INVITATIONS_STATISTICS_REPOSITORY,
    )
    .to(
      withDummies
        ? OnBoardingInvitationsStatisticsDummyRepository
        : OnBoardingInvitationsStatisticsHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<GetOnBoardingInvitationsStatisticsQuery>(
      TYPES.GET_ON_BOARDING_INVITATIONS_STATISTICS_QUERY,
    )
    .to(GetOnBoardingInvitationsStatisticsQuery)
    .inSingletonScope();
}
