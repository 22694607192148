import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import {
  AllianzAccountMovementReportHttpRepository,
  AllianzAccountMovementReportDummyRepository,
} from './infrastructure/repository';

// Application
import { SearchAllianzAccountMovementsReportQuery } from './application/queries';

// Domain
import { AllianzAccountMovementReportRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupAllianzAccountMovementsReport(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.ALLIANZ_ACCOUNT_MOVEMENT_REPORT_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<AllianzAccountMovementReportRepository>(TYPES.ALLIANZ_ACCOUNT_MOVEMENT_REPORT_REPOSITORY)
    .to(
      withDummies
        ? AllianzAccountMovementReportDummyRepository
        : AllianzAccountMovementReportHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchAllianzAccountMovementsReportQuery>(
      TYPES.SEARCH_ALLIANZ_ACCOUNT_MOVEMENTS_REPORT_QUERY,
    )
    .to(SearchAllianzAccountMovementsReportQuery)
    .inSingletonScope();
}
