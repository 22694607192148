import TYPES from '../../types';

// Domain
import { InvestorGoalRiskToleranceDto } from '../../domain/dto/investor-goal-risk-tolerance-dto';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class InvestorGoalRiskToleranceDtoService {
  @Inject(TYPES.INVESTOR_GOAL_RISK_TOLERANCE_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  setInvestorGoalRiskToleranceDto(investor_goal_risk_tolerance_dto: InvestorGoalRiskToleranceDto) {
    this.state_manager.state
      .investor_goal_risk_tolerance_dto = { ...investor_goal_risk_tolerance_dto };
  }

  getInvestorGoalRiskToleranceDto(): InvestorGoalRiskToleranceDto {
    return this.state_manager.state.investor_goal_risk_tolerance_dto;
  }
}
