// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { GoalTrackingRepository } from '../../../domain/repositories/goal-tracking-repository';
import { GoalTrackingEntity } from '../../../domain/entities/goal-tracking-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class GoalTrackingDummyRepository implements GoalTrackingRepository {
  get(): Promise<GoalTrackingEntity> {
    return Promise.resolve(fixtures);
  }
}
