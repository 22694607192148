import deleteAccountDialog from './delete-account-dialog.json';
import deleteAccountFullWithdrawal from './delete-account-full-withdrawal.json';
import deleteAccountConfirmation from './delete-account-confirmation.json';
import deleteAccountSummary from './delete-account-summary.json';
import deleteAccountSuccess from './delete-account-success.json';

export default {
  'delete-account': {
    'delete-account-dialog': deleteAccountDialog,
    'delete-account-full-withdrawal': deleteAccountFullWithdrawal,
    'delete-account-confirmation': deleteAccountConfirmation,
    'delete-account-summary': deleteAccountSummary,
    'delete-account-success': deleteAccountSuccess,
  },
};
