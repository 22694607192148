import TYPES from '@/modules/shared/types';

// Domain
import { CustomerDocumentRepository } from '../../domain/repositories/customer-document-repository';
import { MagicLinkEntity } from '../../domain/entities/magic-link-entity';
import { MagicLinkDto } from '../../domain/dtos/magic-link-dto';
import { CustomerDocumentDto } from '@/modules/my-investment/kuspit/customer-document/magic-link/domain/dtos/customer-document-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class CustomerDocumentHttpRepository implements CustomerDocumentRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/kuspit/customer-document/`;

  verify_token(search_by_customer_dto: MagicLinkDto): Promise<MagicLinkEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}magic-link/verify/?token=${search_by_customer_dto.token}`)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }

  upload_proof_of_tax_situation(customer_document_dto: CustomerDocumentDto): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}upload/proof-of-tax-situation/`, 'POST', customer_document_dto)
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json()
              .then(({ message }) => {
                reject(new Error(message));
              });
          }
        })
        .catch(reject);
    });
  }
}
