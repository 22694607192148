import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import { AllianzOperationDatesHttpRepository, AllianzOperationDatesDummyRepository }
  from './infrastructure/repositories';

// Application
import GetAllianzDepositOperationDatesQuery from './application/queries/get-allianz-deposit-operation-dates-query';
import GetAllianzWithdrawalOperationDatesQuery from './application/queries/get-allianz-withdrawal-operation-dates-query';

// Domain
import { AllianzOperationDatesRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupAllianzOperationDates(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<StateManager>(TYPES.ALLIANZ_OPERATION_DATES_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<AllianzOperationDatesRepository>(TYPES.ALLIANZ_OPERATION_DATES_REPOSITORY)
    .to(
      withDummies
        ? AllianzOperationDatesDummyRepository
        : AllianzOperationDatesHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<GetAllianzDepositOperationDatesQuery>(TYPES.GET_ALLIANZ_DEPOSIT_OPERATION_DATES_QUERY)
    .to(GetAllianzDepositOperationDatesQuery)
    .inSingletonScope();

  container
    .bind<GetAllianzWithdrawalOperationDatesQuery>(
      TYPES.GET_ALLIANZ_WITHDRAWALS_OPERATION_DATES_QUERY,
    )
    .to(GetAllianzWithdrawalOperationDatesQuery)
    .inSingletonScope();
}
