import TYPES from '../../types';

// Domain
import { GoalTrackingEntity } from '../../domain/entities/goal-tracking-entity';
import { GoalTrackingStateManager } from '../../domain/state/goal-tracking-state-manager';
import { GoalTrackingRepository } from '../../domain/repositories/goal-tracking-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Query from '@/modules/shared/domain/use-cases/query';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class GetGoalTrackingQuery extends Query<Promise<GoalTrackingEntity>> {
  @Inject(TYPES.GOAL_TRACKING_REPOSITORY)
  private readonly repository!: GoalTrackingRepository;

  @Inject(TYPES.GOAL_TRACKING_STATE_MANAGER)
  private readonly state_manager!: GoalTrackingStateManager;

  internalExecute(investor_goal_id: string): Promise<GoalTrackingEntity> {
    return new Promise((resolve, reject) => {
      this.repository.get(investor_goal_id)
        .then((item) => {
          this.state_manager.patch({ item });
          resolve(item);
        })
        .catch(reject);
    });
  }
}
