import TYPES from '../../types';

// Domain
import {
  RetirementForecastCalculationRepository,
} from '@/modules/flagship/retirement-forecast-calculation/domain/repositories/retirement-forecast-calculation-repository';
import {
  RetirementForecastCalculationDto,
} from '@/modules/flagship/retirement-forecast-calculation/domain/dtos/retirement-forecast-calculation-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UpdateRetirementForecastCalculationCommand
  extends Command<RetirementForecastCalculationDto> {
  @Inject(TYPES.RETIREMENT_FORECAST_CALCULATION_REPOSITORY)
  private readonly repository!: RetirementForecastCalculationRepository;

  internalExecute(retirement_forecast_dto: RetirementForecastCalculationDto): Promise<void> {
    return this.repository.update(retirement_forecast_dto);
  }
}
