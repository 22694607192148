import TYPES from '../../types';

// Domain
import {
  RetirementFundLocationEntity,
} from '@/modules/flagship/catalogs/retirement-fund-location/domain/entities/retirement-fund-location-entity';
import { RetirementFundLocationRepository } from '@/modules/flagship/catalogs/retirement-fund-location/domain/repositories/retirement-fund-location-repository';
import {
  RetirementFundLocationStateManager,
} from '@/modules/flagship/catalogs/retirement-fund-location/domain/state/retirement-fund-location-state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetRetirementFundLocationQuery
  extends Query<Promise<Array<RetirementFundLocationEntity>>> {
  @Inject(TYPES.RETIREMENT_FUND_LOCATION_REPOSITORY)
  private readonly repository!: RetirementFundLocationRepository;

  @Inject(TYPES.RETIREMENT_FUND_LOCATION_STATE_MANAGER)
  private readonly state_manager!: RetirementFundLocationStateManager;

  internalExecute(): Promise<Array<RetirementFundLocationEntity>> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded) {
        resolve(this.state_manager.state.items);
      } else {
        this.repository.get()
          .then((items) => {
            this.state_manager.patch({ items, loaded: true });
            resolve(items);
          })
          .catch(reject);
      }
    });
  }
}
