






























































































































import { Component, Vue } from 'vue-property-decorator';
import NavBarViewModel from '@/vue-app/view-models/components/nav-bar-view-model';

@Component({
  name: 'Navbar',
  components: {
    SignOutDialog: () => import('@/vue-app/components/nav-bar/SignOutDialog.vue'),
    Notifications: () => import('@/vue-app/components/nav-bar/notifications/Notifications.vue'),
  },
})
export default class Navbar extends Vue {
  nav_bar_view_model = Vue.observable(new NavBarViewModel());

  created() {
    this.nav_bar_view_model.addTitleEventListener();
    this.nav_bar_view_model.addScrollListener();
  }

  router(path: string) {
    if (this.$route.path !== path) {
      this.$router.push(path);
    }
  }

  destroyed() {
    this.nav_bar_view_model.removeTitleEventListener();
    this.nav_bar_view_model.removeScrollListener();
  }
}
