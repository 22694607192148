// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { InvestmentProductRepository } from '../../../domain/repository';
import { AvailabilityToHireProductEntity } from '../../../domain/entities/availability-to-hire-product-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class InvestmentProductDummyRepository implements InvestmentProductRepository {
  // eslint-disable-next-line class-methods-use-this
  findAvailabilityToHireProduct(): Promise<AvailabilityToHireProductEntity> {
    return Promise.resolve(fixtures);
  }
}
