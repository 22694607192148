import SHARED_TYPES from '@/modules/shared/types';

// Domain
import { UserPasswordRepository } from '../../domain/repositories';
import { ChangePassword } from '../../domain/dtos';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class UserPasswordRestRepository implements UserPasswordRepository {
  @Inject(SHARED_TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  update(payload: ChangePassword): Promise<void> {
    const endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/user/password/`;

    return new Promise((resolve, reject) => {
      this.http_connector.execute(endpoint, 'PUT', payload)
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json()
              .then(({ message }) => {
                reject(new Error(message));
              });
          }
        })
        .catch(() => reject(new Error('Fetch Error')));
    });
  }
}
