import TYPES from '@/modules/shared/types';

// Domain
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';
import {
  EmergencyFundInvestorGoalRepository,
} from '@/modules/flagship/emergency-fund-investor-goal/domain/repositories/emergency-fund-investor-goal-repository';
import {
  EmergencyFundInvestorGoalEntity,
} from '@/modules/flagship/emergency-fund-investor-goal/domain/entities/emergency-fund-investor-goal-entity';
import {
  EmergencyFundInvestorGoalDto,
} from '@/modules/flagship/emergency-fund-investor-goal/domain/dtos/emergency-fund-investor-goal-dto';
import {
  UpdatePlanEmergencyFundDto,
} from '@/modules/flagship/emergency-fund-investor-goal/domain/dtos/update-plan-emergency-fund-dto';

@Injectable()
class EmergencyFundInvestorGoalHttpRepository implements EmergencyFundInvestorGoalRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/emergency-fund-investor-goal/`;

  get(): Promise<EmergencyFundInvestorGoalEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(JSON.stringify({
                  status_code: response.status,
                  message_error: message,
                }));
              }
            });
        })
        .catch(reject);
    });
  }

  update(emergency_fund_investor_goal_dto: EmergencyFundInvestorGoalDto): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}create-or-patch/`, 'POST', emergency_fund_investor_goal_dto)
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => reject(new Error(message)),
            );
          }
        })
        .catch(reject);
    });
  }

  updatePlan(update_plan_emergency_fund_dto: UpdatePlanEmergencyFundDto): Promise<void> {
    return new Promise((resolve, reject) => {
      const { emergency_fund_investor_goal, ...payload } = update_plan_emergency_fund_dto;
      this.http_connector.execute(`${this.endpoint}${emergency_fund_investor_goal}/investment-plan/`, 'PATCH', payload)
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => reject(new Error(message)),
            );
          }
        })
        .catch(reject);
    });
  }
}

export default EmergencyFundInvestorGoalHttpRepository;
