import TYPES from '../../types';

// Domain
import { StatisticsDto } from '../../domain/dtos/statistics-dto';
import { StatisticsEntity } from '../../domain/entities/statistics-entity';
import {
  OnBoardingInvitationsStatisticsRepository,
} from '../../domain/repository/on-boarding-invitations-statistics-repository';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetOnBoardingInvitationsStatisticsQuery
  extends Query<Promise<StatisticsEntity>> {
  @Inject(TYPES.ON_BOARDING_INVITATIONS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.ON_BOARDING_INVITATIONS_STATISTICS_REPOSITORY)
  private readonly repository!: OnBoardingInvitationsStatisticsRepository;

  internalExecute(statistics_dto: StatisticsDto): Promise<StatisticsEntity> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.loaded && !statistics_dto.reload) {
        resolve(this.state_manager.state.invitations_statistics);
      } else {
        this.repository.execute(statistics_dto)
          .then((invitations_statistics) => {
            this.state_manager.patch({ invitations_statistics, loaded: true });
            resolve(invitations_statistics);
          })
          .catch(reject);
      }
    });
  }
}
