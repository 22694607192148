import fixtures from './fixtures.json';

// Domain
import { FinalInvestmentDateCalculatorDto }
  from '../../../domain/dtos/final-investment-date-calculator-dto';
import { FinalInvestmentDateCalculatorEntity }
  from '../../../domain/entities/final-investment-date-calculator-entity';
import { FinalInvestmentDateCalculatorRepository }
  from '../../../domain/repositories/final-investment-date-calculator-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class FinalInvestmentDateCalculatorDummyRepository implements
  FinalInvestmentDateCalculatorRepository {
  get_final_investment_date(final_investment_date_dto: FinalInvestmentDateCalculatorDto):
    Promise<FinalInvestmentDateCalculatorEntity> {
    return Promise.resolve(fixtures);
  }
}
