import TYPES from '../../types';

// Domain
import { CancelAccountRepository } from '../../domain/repositories';
import { CancelAccount } from '../../domain/dtos/cancel-account';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class CancelAccountCommand extends Command<CancelAccount> {
  @Inject(TYPES.CANCEL_ACCOUNT_REPOSITORY)
  private readonly cancel_account_repository!: CancelAccountRepository;

  internalExecute(payload: CancelAccount): Promise<void> {
    return this.cancel_account_repository.cancel(payload);
  }
}
