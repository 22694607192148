// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { FundingDetailsRepository } from '../../../domain/repository';
import { FundingDetailsDto } from '../../../domain/dtos/funding-details-dto';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class FundingDetailsDummyRepository implements FundingDetailsRepository {
  get(): Promise<FundingDetailsDto> {
    return Promise.resolve(fixtures);
  }
}
