import signUpRoutes from './sign-up';
import loginRoutes from './login';
import recoveryPasswordRoutes from './recovery-password';
import forgotPasswordRoutes from './forgot-password';
import onboardingInvitation from './onboarding-invitation';
import attachedNewAccountStatement from './attached-new-account-statement';
import attachedNewProofOfAddress from './attached-new-proof-of-address';
import documentSuccessfullySent from './document-successfully-sent';
import attachedNewProofOfTaxSituation from './attached-new-proof-of-tax-situation';
import updateIdentityDocument from './update-identity-document';

export default [
  ...signUpRoutes,
  loginRoutes,
  recoveryPasswordRoutes,
  forgotPasswordRoutes,
  onboardingInvitation,
  attachedNewAccountStatement,
  attachedNewProofOfAddress,
  documentSuccessfullySent,
  attachedNewProofOfTaxSituation,
  updateIdentityDocument,
];
