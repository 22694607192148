// Domain
import {
  InvestorProfileOnFlowCompleteRepository,
} from '@/modules/flagship/investor-profile/flow-completed/domain/repositories/investor-profile-on-flow-complete-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class InvestorProfileOnFlowCompleteDummyRepository
implements InvestorProfileOnFlowCompleteRepository {
  update(): Promise<any> {
    return Promise.resolve();
  }
}
