





































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import LoadingDataCard from '@/vue-app/components/home-dashboard/LoadingDataCard.vue';
import RangePicker from '@/vue-app/components/RangePicker.vue';
import MovementsViewModel from '@/vue-app/view-models/components/kuspit-dashboard/movements-view-model';

@Component({
  name: 'Movements',
  components: {
    LoadingDataCard,
    RangePicker,
  },
})
export default class Movements extends Vue {
  movements_view_model = Vue.observable(new MovementsViewModel());

  @Watch('movements_view_model.movement_option_selected')
  async onMovementTypeChange(movement: any) {
    this.movements_view_model.fillData(
      await this.movements_view_model.kuspitMovements,
      this.movements_view_model.dates_selected[0],
      this.movements_view_model.dates_selected[1],
      movement,
    );
  }

  created() {
    this.movements_view_model.initialize();
  }
}
