import { TemplateQuestionnaireEntity } from '../entities/template-questionnaire-entity';
import { FilterDto } from '../dtos/filter-dto';

export default class State {
  current_filter: FilterDto = {
    versionCuestionario: '',
    tipoCuestionario: '',
  };

  items: Array<TemplateQuestionnaireEntity> = [];
}
