import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import VueStateManager from './infrastructure/state/vue-state-manager';
import { OccupationHttpRepository, OccupationDummyRepository } from './infrastructure/repository';

// Application
import { SearchOccupationsQuery } from './application/queries';

// Domain
import { OccupationRepository } from './domain/repository';
import { StateManager } from './domain/state/state-manager';

export default function setupOccupation(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<StateManager>(TYPES.CATALOG_ALLIANZ_OCCUPATION_STATE_MANAGER)
    .to(VueStateManager)
    .inSingletonScope();

  container
    .bind<OccupationRepository>(TYPES.CATALOG_ALLIANZ_OCCUPATION_REPOSITORY)
    .to(
      withDummies
        ? OccupationDummyRepository
        : OccupationHttpRepository,
    )
    .inSingletonScope();

  container
    .bind<SearchOccupationsQuery>(TYPES.SEARCH_CATALOG_ALLIANZ_OCCUPATION_QUERY)
    .to(SearchOccupationsQuery)
    .inSingletonScope();
}
