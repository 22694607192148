import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import {
  CancelAccountRestRepository,
  CancelAccountFakeRepository,
} from './infrastructure/repositories';

// Application
import { CancelAccountCommand } from './application/commands';

// Domain
import { CancelAccountRepository } from './domain/repositories';

export default function setupCancelAccountModule(container: Container) {
  const with_dummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';

  container
    .bind<CancelAccountRepository>(TYPES.CANCEL_ACCOUNT_REPOSITORY)
    .to(
      with_dummies
        ? CancelAccountFakeRepository
        : CancelAccountRestRepository,
    )
    .inSingletonScope();

  container
    .bind<CancelAccountCommand>(TYPES.CANCEL_ACCOUNT_COMMAND)
    .to(CancelAccountCommand)
    .inSingletonScope();
}
