import TYPES from '../../types';

// Domain
import { ActivityEntity } from '../../domain/entities/activity-entity';
import { ActivityRepository } from '../../domain/repository/activity-repository';
import { StateManager } from '../../domain/state/state-manager';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class SearchActivitiesQuery extends Query<Promise<Array<ActivityEntity>>> {
  @Inject(TYPES.CATALOG_KUSPIT_ACTIVITY_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.CATALOG_KUSPIT_ACTIVITY_REPOSITORY)
  private readonly repository!: ActivityRepository;

  internalExecute(job_type_id: string): Promise<Array<ActivityEntity>> {
    return new Promise((resolve, reject) => {
      if (this.state_manager.state.current_job_type_id !== job_type_id) {
        this.repository.execute(job_type_id)
          .then((items) => {
            this.state_manager.patch({
              items,
              current_job_type_id: job_type_id,
            });
            resolve(items);
          })
          .catch(reject);
      } else {
        resolve(this.state_manager.state.items);
      }
    });
  }
}
