import TYPES from '@/modules/shared/types';

// Domain
import {
  IncompleteProfileRepository,
} from '@/modules/flagship/investor-profile/incomplete-profile/domain/repositories/incomplete-profile-repository';
import {
  IncompleteProfileEntity,
} from '@/modules/flagship/investor-profile/incomplete-profile/domain/entities/incomplete-profile-entity';
import {
  IncompleteProfileDto,
} from '@/modules/flagship/investor-profile/incomplete-profile/domain/dtos/incomplete-profile-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class IncompleteProfileHttpRepository implements IncompleteProfileRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/flagship/investor-profile/incomplete-profile/`;

  get(): Promise<IncompleteProfileEntity> {
    return new Promise((resolve, reject) => {
      this.http_connector.execute(this.endpoint)
        .then((response) => {
          response.json()
            .then(({ success, data, error }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(error));
              }
            });
        })
        .catch(reject);
    });
  }

  update(incomplete_profile_dto: IncompleteProfileDto): Promise<void> {
    const { investor_profile_id, ...payload } = incomplete_profile_dto;
    return new Promise((resolve, reject) => {
      this.http_connector.execute(`${this.endpoint}${investor_profile_id!}/`, 'PATCH', payload)
        .then((response) => {
          if (response.ok) {
            resolve();
          } else {
            response.json().then(
              ({ message }) => {
                reject(new Error(message));
              },
            );
          }
        })
        .catch(reject);
    });
  }
}
