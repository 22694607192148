import TYPES from '../../types';

// Domain
import { CustomerWithdrawalDetailsEntity } from '../../domain/entities/customer-withdrawal-details-entity';
import { CustomerWithdrawalDetailsQueryParamsDto } from '../../domain/dtos/customer-withdrawal-details-query-params-dto';
import { GetCustomerWithdrawalDetailsRepository } from '../../domain/repository/get-customer-withdrawal-details-repository';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';

@Injectable()
export default class GetCustomerWithdrawalDetailsQuery
  extends Query<Promise<CustomerWithdrawalDetailsEntity>> {
  @Inject(TYPES.CUSTOMER_WITHDRAWAL_DETAILS_REPOSITORY)
  private readonly repository!: GetCustomerWithdrawalDetailsRepository;

  internalExecute(params: CustomerWithdrawalDetailsQueryParamsDto):
    Promise<CustomerWithdrawalDetailsEntity> {
    return new Promise((resolve, reject) => {
      this.repository.get(params)
        .then((withdrawal_details) => {
          resolve(withdrawal_details);
        })
        .catch(reject);
    });
  }
}
