import i18n from '@/vue-app/plugins/i18n';
import { passwordFormat, requiredRule, RuleResponseType } from '@/vue-app/utils/form-rules';

import TYPES from '@/types';

// Application
import { RecoveryPasswordCommand } from '@/modules/authentication/application/commands';

// Domain
import { Router } from '@/modules/shared/domain/router';
import { RecoveryPassword } from '@/modules/authentication/domain/dtos';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import {
  blacklistValidator,
  containsDigitsLowerCaseAndUpperCaseValidator,
  minLengthValidator, noConsecutiveCharactersValidator, noConsecutiveRepeatedCharactersValidator,
} from '@/modules/register/domain/services/password-validators';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class RecoveryPasswordViewModel {
  @Inject(TYPES.RECOVERY_PASSWORD_COMMAND)
  private readonly recovery_password_command!: RecoveryPasswordCommand;

  @Inject(TYPES.ROUTER)
  private readonly router!: Router;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  private readonly i18n_namespace = 'recovery_password';

  valid_form = false;

  is_loading = false;

  show_success_message = false;

  auth_token = '';

  inputs = {
    password: {
      value: '',
      type: 'password',
      append_icon: 'mdi-eye-off',
      show: false,
    },
    password_confirmation: {
      value: '',
      type: 'password',
      append_icon: 'mdi-eye-off',
      show: false,
    },
  }

  input_rules = {
    password: [requiredRule, passwordFormat],
    password_confirmation: [
      (value: string): RuleResponseType => value === this.inputs.password.value || i18n.t('utils.form-rules.confirm_password_match').toString(),
    ],
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get is_password_min_length_valid() {
    return (this.inputs.password.value) ? minLengthValidator(this.inputs.password.value) : false;
  }

  get one_capital_and_lowercase_letter_one_number() {
    return (this.inputs.password.value)
      ? containsDigitsLowerCaseAndUpperCaseValidator(this.inputs.password.value) : false;
  }

  get password_not_contains_blacklisted_words() {
    return (this.inputs.password.value)
      ? blacklistValidator(this.inputs.password.value, []) : false;
  }

  get password_has_no_consecutive_characters() {
    return (this.inputs.password.value)
      ? noConsecutiveCharactersValidator(this.inputs.password.value) : false;
  }

  get password_has_no_consecutive_repeated_characters() {
    return (this.inputs.password.value)
      ? noConsecutiveRepeatedCharactersValidator(this.inputs.password.value) : false;
  }

  get can_continue() {
    return (
      this.valid_form
      && this.is_password_min_length_valid
      && this.one_capital_and_lowercase_letter_one_number
      && this.password_not_contains_blacklisted_words
      && this.password_has_no_consecutive_characters
      && this.password_has_no_consecutive_repeated_characters
      && !this.is_loading
    );
  }

  showPassword = () => {
    this.inputs.password.show = !this.inputs.password.show;
    this.inputs.password.type = this.inputs.password.show ? 'text' : 'password';
    this.inputs.password.append_icon = this.inputs.password.show ? 'mdi-eye' : 'mdi-eye-off';
  }

  showPasswordConfirmation = () => {
    this.inputs.password_confirmation.show = !this.inputs.password_confirmation.show;
    this.inputs.password_confirmation.type = this.inputs.password_confirmation.show ? 'text' : 'password';
    this.inputs.password_confirmation.append_icon = this.inputs.password_confirmation.show ? 'mdi-eye' : 'mdi-eye-off';
  }

  setToken = (auth_token: string) => {
    this.auth_token = auth_token.toString();
  }

  changePassword = async () => {
    try {
      this.is_loading = true;
      const payload: RecoveryPassword = {
        auth_token: this.auth_token,
        new_password: this.inputs.password.value,
        new_password_confirmation: this.inputs.password_confirmation.value,
      };
      await this.recovery_password_command.execute(payload);
      this.show_success_message = true;
    } catch {
      this.message_notifier.showErrorNotification(
        this.translate('error_on_change_password').toString(),
      );
    } finally {
      this.is_loading = false;
    }
  }
}
