import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';
import { OnBoardingStatusEntity } from '../../domain/entities/on-boarding-status-entity';
import { OnBoardingStatusRepository } from '../../domain/repositories/on-boarding-status-repository';

@Injectable()
export default class GetOnBoardingStatusQuery extends Query<Promise<OnBoardingStatusEntity>> {
  @Inject(TYPES.ON_BOARDING_STATUS_REPOSITORY)
  private readonly repository!: OnBoardingStatusRepository;

  internalExecute(): Promise<OnBoardingStatusEntity> {
    return this.repository.get();
  }
}
