import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import SearchByCustomerDummyRepository
  from './infrastructure/repositories/search-by-customer-dummy-repository/search-by-customer-dummy-repository';
import SearchByCustomerHttpRepository
  from './infrastructure/repositories/search-by-customer-http-repository';
import SearchByCustomerVueStateManager
  from './infrastructure/state/search-by-customer-vue-state-manager';

// Application
import GetSearchByCustomerQuery
  from './application/queries/get-search-by-customer-query';
import GetActiveGoalAmountByFundTypeQuery
  from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-active-goal-amount-by-fund-type-query';

// Domain
import { SearchByCustomerRepository } from './domain/repositories/search-by-customer-repository';
import { SearchByCustomerStateManager } from './domain/state/search-by-customer-state-manager';

export default function setupCustomerInvestorGoalBalance(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<SearchByCustomerStateManager>(TYPES.SEARCH_BY_CUSTOMER_STATE_MANAGER)
    .to(SearchByCustomerVueStateManager)
    .inSingletonScope();

  container
    .bind<SearchByCustomerRepository>(TYPES.SEARCH_BY_CUSTOMER_REPOSITORY)
    .to(
      withDummies
        ? SearchByCustomerDummyRepository : SearchByCustomerHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetSearchByCustomerQuery>(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
    .to(GetSearchByCustomerQuery)
    .inSingletonScope();

  container
    .bind<GetActiveGoalAmountByFundTypeQuery>(TYPES.GET_ACTIVE_GOAL_AMOUNT_BY_FUND_TYPE_QUERY)
    .to(GetActiveGoalAmountByFundTypeQuery)
    .inSingletonScope();
}
