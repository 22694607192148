// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { CustomerKuspitCredentialsRepository } from '../../../domain/repository';
import { CustomerKuspitCredentialsEntity } from '../../../domain/entities/customer-kuspit-credentials-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class CustomerKuspitCredentialsDummyRepository
implements CustomerKuspitCredentialsRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(): Promise<CustomerKuspitCredentialsEntity> {
    return Promise.resolve(fixtures);
  }
}
