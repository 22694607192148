import TYPES from '../../types';

// Domain
import { OnBoardingStepEntity } from '../../domain/entities/on-boarding-step-entity';
import { StateManager } from '../../domain/state/state-manager';
import { UpdateOnBoardingStepRepository } from '../../domain/repositories';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Command from '@/modules/shared/domain/use-cases/command';

@Injectable()
export default class UpdateOnBoardingStepCommand extends Command<OnBoardingStepEntity> {
  @Inject(TYPES.MY_INVESTMENT_ON_BOARDING_STEP_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  private readonly repository!: UpdateOnBoardingStepRepository;

  internalExecute(on_boarding_step: OnBoardingStepEntity): Promise<void> {
    return new Promise((resolve, reject) => {
      const existent_on_boarding_step = this.state_manager.state.created_steps.find(
        (item) => item.current_step === on_boarding_step.current_step,
      );
      if (existent_on_boarding_step) {
        this.repository.execute({
          ...on_boarding_step,
          id: existent_on_boarding_step.id,
        })
          .then(() => {
            this.state_manager.patch(
              { [on_boarding_step.current_step]: on_boarding_step.payload },
            );
            resolve();
          })
          .catch(reject);
      } else {
        reject(new Error('OnBoardingStep to update not found.'));
      }
    });
  }
}
