// Domain
import {
  VoucherExtractedDataEntity,
} from '@/modules/my-investment/allianz/special-contribution/domain/dto/voucher-extracted-data-dto';
import {
  SpecialContributionRepository,
} from '../../../domain/repository/special-contribution-repository';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class SpecialContributionDummyRepository implements
  SpecialContributionRepository {
  notify(): Promise<void> {
    return Promise.resolve();
  }

  uploadVoucher(): Promise<VoucherExtractedDataEntity> {
    return Promise.resolve({ deposit_amount: '100000.00' });
  }
}
