import SHARED_TYPES from '@/modules/shared/types';
import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Subscription from '@/modules/shared/domain/use-cases/subscription';
import { WebSocket } from '@/modules/shared/domain/web-socket';
import { IdentityVerificationStatusEntity }
  from '../../domain/entities/identity-verification-status-entity';
import { StateManager } from '../../domain/state-manager';

@Injectable()
export default class IdentityVerificationStatusSubscription extends Subscription<string> {
  @Inject(SHARED_TYPES.WEB_SOCKET)
  private readonly web_socket!: WebSocket;

  @Inject(TYPES.ON_BOARDING_IDENTITY_VERIFICATION_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  private endpoint = `${process.env.VUE_APP_WEBSOCKET_URL}/app.mati_event_dict.web_socket.1.published/{USER_ID}/`;

  internalExecute(user_id: string) {
    this.endpoint = this.endpoint.replace('{USER_ID}', user_id);
    this.web_socket.connect(this.endpoint, (event) => {
      const status: IdentityVerificationStatusEntity = event.attributes.mati_event_dict;

      if (!this.state_manager.state.status || !this.state_manager.state.status.percent
        || (status.percent && this.state_manager.state.status.percent < status.percent)) {
        this.state_manager.patch({ status });
      }

      if (status.percent === 100) {
        this.web_socket.disconnect(this.endpoint);
      }
    });
  }

  stop() {
    this.web_socket.disconnect(this.endpoint);
  }
}
