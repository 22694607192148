// Infrastructure
import fixtures from './fixtures.json';

// Domain
import { IncomeRepository } from '../../../domain/repository';
import { IncomeEntity } from '../../../domain/entities/income-entity';
import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class IncomeDummyRepository implements IncomeRepository {
  // eslint-disable-next-line class-methods-use-this
  execute(): Promise<Array<IncomeEntity>> {
    return Promise.resolve(fixtures);
  }
}
